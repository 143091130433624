import React from "react";
import {connect} from "react-redux";
import TableSettingModal from "./TableSettingModal";
import DownloadDocModal from "./DownloadDocModal";
import {ModalTypes} from "../../../tools/StaticTypes";
import ChangeDocumentTypeModal from "./ChangeDocumentTypeModal";
import AlertModal from "./AlertModal";
import ChangeAttributes from "./ChangeAttributes";
import InfoModal from "./InfoModal";
import CrudPackagesModal from "./CrudPackagesModal";
import DeleteDocumentModal from "./DeleteDocumentModal";
import ChangeDocumentTypeModalOriginal from "./ChangeDocumentTypeModalOriginal";
import NewDocumentByVersion from "./NewDocumentByVersion";
import ChangeDocumentSections from "./ChangeDocumentSections";
import TransferPackageModal from "./TransferPackageModal";
import ChangeDepartments from "./ChangeDepartments";
import SignaturesInfoModal from "../../additionalDataSection/SignatureInfoModal";
import ChangeVersionDocInPackage from "./ChangeVersionDocInPackage";
import ShareModal from "./ShareModal";
import UpdateFile from "./UpdateFile";
import ModalAttributeTable from "./ModalAttributeTable";
import ShowJsonTreeModal from "./ShowJsonTreeModal";
import ShowJsonObjectModal from "./ShowJsonObjectModal";

// TODO ЕСЛИ МОДАЛКА НЕ ЗАКРЫВАЕТСЯ, ЧИТАЙ ! может не хватает второго пункта!
/*
    TODO КН 21.02.24 addModalData с id!==1 пока работает не для всех модалок (пока что работает там, где оно надо, чтобы точно ничего не сломать)
     Чтобы оно заработало нужно сделать по аналогии с компонентой InfoModal:
     - Прокинуть сверху в компоненту модалки modal={modalData}
        В некоторые компоненты, как можно увидеть, прокидывается не modal={modalData}, а например type={modalData.data}, так сделано чтобы не менять названия пропсов внутри компонент, т.е., если есть желание, можете тоже подстроить их под modal={modalData}
            (!Не забыть проверить использование модалки в файлах помимо Modal.js, напр. как использование InfoModal в App.js)
     - в компоненте модалки, где раньше прятали модалку через this.props.setModalData({}), прячем её через this.props.deleteModalData({modalId: this.props.modal.id})
     - Аналогично заменяем this.props.setModalData({}) в остальных файлах,
        где эта функция относилась к модалке с которой работаете (Можете оставить в остальных файлах this.props.setModalData({}) как было,
            если уверены что ваша модалка в моментах её удаления всегда на первом месте в массиве модалок,
                просто дело в том, что this.props.setModalData({}) удаляет именно первую модалку в массиве)
 */
const modalContentByModalData = (modalData) => {
    switch (modalData.name) {
            case ModalTypes.documents.mainTable.Settings:
                return (<TableSettingModal />);
            case ModalTypes.documents.additionalSection.DownloadDoc:
            return(<DownloadDocModal data={modalData.data}/>);
            case ModalTypes.documents.mainTable.ChangeDocumentType:
            return(<ChangeDocumentTypeModal type={modalData.data}/>);
            case ModalTypes.documents.mainTable.ChangeDocumentTypeOriginal:
            return(<ChangeDocumentTypeModalOriginal modal={modalData}/>);
            case ModalTypes.documents.mainTable.NewDocumentByVersion:
            return(<NewDocumentByVersion modal={modalData}/>);
            case ModalTypes.documents.additionalSection.ChangeDocAttributes:
            return(<ChangeAttributes modal={modalData}/>);
        case ModalTypes.documents.additionalSection.ShowJsonTree:
            return(<ShowJsonTreeModal modal={modalData}/>);
        case ModalTypes.documents.additionalSection.ShowJsonObject:
            return(<ShowJsonObjectModal modal={modalData}/>);
            case ModalTypes.app.alert:
            return(<AlertModal modal={modalData}/>);
            case ModalTypes.app.info:
            return(<InfoModal modal={modalData}/>);
            case ModalTypes.app.signaturesInfo:
            return(<SignaturesInfoModal modal={modalData}/>);
            case ModalTypes.packages.crud:
            return(<CrudPackagesModal modal={modalData}/>);
            case ModalTypes.documents.mainTable.DeleteDocument:
            return(<DeleteDocumentModal modal={modalData}/>);
            case ModalTypes.documents.mainTable.ChangeDocumentSections:
                return(<ChangeDocumentSections />);
            case ModalTypes.documents.mainTable.ChangeDepartments:
            return(<ChangeDepartments modalData={modalData.data}/>);
            case ModalTypes.packages.transfer:
            return (<TransferPackageModal modalData={modalData.data}/>);
            case ModalTypes.packages.ChangeVersionDocInPackage:
            return (<ChangeVersionDocInPackage modal={modalData}/>);
            case ModalTypes.messenger.shareDocs:
            return (<ShareModal modal={modalData}/>)
            case ModalTypes.documents.updatePreviewFile:
                return (<UpdateFile/>)
        case ModalTypes.documents.additionalSection.AttributesTable:
            return (<ModalAttributeTable modal={modalData}/>)
            default: return  null;
    }
}

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.modalsContainerRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        /* TODO КН делаем фокус лишь для разфокуса ранее зафокусенных элементов
            Решил что на каждую модалку делать реф ради этого не стоит, поэтому делаю фокус на контейнере всех модалок.
            Затачиваюсь на prevProps.modal.at(-1), т.к. самая последняя модалка в массиве prevProps.modal отображается поверх всех остальных,
            и т.е. даже если мы решим зачем то заменить с помощью id'шника модалку позади - мы же не должы разфокусивать фокус в модалке, которая поверх всех
         */
        if (prevProps.modal?.at(-1)?.id !== this.props.modal?.at(-1)?.id) {
            // document.activeElement.blur()
            this.modalsContainerRef.current?.focus();
        }
        }

    render() {
        return (
            // div для modalsContainerRef, tabIndex для работы this.modalsContainerRef.current.focus()
            <div ref={this.modalsContainerRef} tabIndex={-1}>
                {this.props.modal.map((modalData) => {
                        if (modalData.id) {
                            //div для key
                            return <div key={modalData.id} style={{display: 'contents'}}>
                                {modalContentByModalData(modalData)}
                            </div>
                        }
                        return null;
                    }
                )}
            </div>
        )
    }
}


const  mapStateToProps = state => {
    return {
        modal : state.globalState.app.modal
    }
}

export default connect(mapStateToProps)(Modal);
