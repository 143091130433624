import {PACKAGES_TABLE_ACTIONS, PACKAGES_TREE_ACTIONS} from "../packageActionsList";
import {store} from "../../../index";
import {Operators as FilerOperatorsEnum, PackagesViewFilters} from "../../../tools/StaticTypes";
import {__} from "../../../tools/HelpFunctions";
import {
    getDocumentsForTreePackages,
    getPackageGroupsForTree, SelectActivePackageDocument,
    SelectActivePackageNode
} from "./package_TreeActionCreator";
import {getAndSetAppliedParams} from './package_InputActionCreator'
import {TreeCombinePackage} from "../../../tools/TreeCombinePackage";
import {EntityFilters, Filter, FiltersType, Sorting} from "../../../tools/Filters";
import {API} from "../../../tools/API_NEW/API";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import {handlerSearchPackages} from "./package_InputActionCreator";
import {DataTypesEnum} from "../../../tools/API_NEW/ServerTypes";


// изменить отображение таблиц в пакетах
export const changePackagesTablesViewMode = ({Table = "main", ViewMode, IsDownload = false, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TABLE_ACTIONS.CHANGE_VIEW_MODE_PACKAGE_TABLES, payload : {
                Table,
                ViewMode,
                IsDownload : IsDownload
            }});
            // следующее действие если есть цепочка
            if(Next) Next.action(Next.params);
        } catch (exception) {
            console.log("Ошибка изменения отображения таблиц пакетов (catch): ", exception);
            if(Error) Error.action(Error.params);
        }
    }
}

// переключить страницу пагинации
export const changePaginationPageInPackages = ({PageNumber, TableType = "main", IsDownload = false, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TABLE_ACTIONS.CHANGE_PAGINATION_PAGE, payload : {
                PageNumber,
                tableType : TableType,
                IsDownload : IsDownload
            }});
            // TODO КН 15.05.24 Мб setViewModePreviewPackages по условию надо выполнять всегда в SelectActivePackageDocument() ниже, когда не прислал ему Id (там идет сброс активного документа но не идет сброс активРоу)
            const viewModePreview = store.getState().packages.rootState.viewModePreview
            if (viewModePreview == "normal") {
                dispatch(setViewModePreviewPackages({
                    ViewMode: "none",
                }));
            }
            dispatch(SelectActivePackageDocument({TableType}));
            // TODO КН 15.05.24 Мб сброс активРоу надо выполнять всегда в SelectActivePackageDocument() выше, когда не прислал ему Id (там идет сброс активного документа но не идет сброс активРоу)
            dispatch({type : PACKAGES_TREE_ACTIONS.SELECT_ACTIVE_ROW, payload : {
                    activeRow : null,
                    tableType : TableType,
                    IsDownload,
                    IsVisibleModal: null,
                }});
            if(Next) Next.action(Next.params);
        } catch (exception) {
            console.log("Ошибка переключения страницы пагинации в пакетах", exception);
            if(Error) Error.action(Error.params);
        }
    }
}

// изменить фильтр предпросмотра в пакетах
// Type -> documents/packages
// TableType -> main/additional
export const changeViewFilter = ({Type, TableType = "main", Next = null, Error = null, lStoreValue = null}) => {
    return async dispatch => {
        try {
            let storeValue;
            if (lStoreValue)
                storeValue = lStoreValue
            else if(TableType === "download") {
                storeValue = store.getState().packages.treeDownload.viewFilter;
            } else if (TableType === "main") {
                storeValue = store.getState().packages.tree.viewFilter
            } else {
                storeValue = store.getState().packages.treeAdditional.viewFilter
            }

            let newValue;
            if(storeValue === PackagesViewFilters.All) {
                if (Type === "packages") {
                    // Если были включены все и нажаты пакеты, то пакеты надо скрыть, поставив "ТолькоДокументы"
                    newValue = PackagesViewFilters.OnlyDocuments
                } else if (Type === "documents") {
                    // Если были включены все и нажаты документы, то документы надо скрыть, поставив "ТолькоПакеты"
                    newValue = PackagesViewFilters.OnlyPackages
                }
            }
            else if(storeValue === PackagesViewFilters.OnlyPackages) {

                if (Type === "packages") {
                    // TODO КН 24.04.24 Надо будет избавиться от прокидывания lStoreValue, которое по сути является костылем, и только при такостыле этот if имеют смысл
                    newValue = PackagesViewFilters.OnlyPackages
                } else if (Type === "documents") {
                    // Если были включены только пакеты и нажаты документы, то документы надо вернуть, поставив "Все"
                    newValue = PackagesViewFilters.All
                }
            }
            else if(storeValue === PackagesViewFilters.OnlyDocuments) {
                if (Type === "packages") {
                    // Если были включены только документы и нажаты пакеты, то пакеты надо вернуть, поставив "Все"
                    newValue = PackagesViewFilters.All
                } else if (Type === "documents") {
                    // TODO КН 24.04.24 Надо будет избавиться от прокидывания lStoreValue, которое по сути является костылем, и только при такостыле этот if имеют смысл
                    newValue = PackagesViewFilters.OnlyDocuments
                }
            }
            // if (newValue) {
                dispatch({
                    type: PACKAGES_TABLE_ACTIONS.CHANGE_VIEW_FILTER, payload: {
                        newValue,
                        tableType: TableType,
                    }
                });
            // }
            if(Next) Next.action(Next.params);
        } catch (exception) {
            console.log("Ошибка переключения фильтрации просмотра в пакетах", exception);
            if(Error) Error.action(Error.params);
        }
    }
}


// изменить способ сортировки: по возрастанию или убыванию
export const changeSortDirection = ({dir, sortField, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TABLE_ACTIONS.CHANGE_SORT_DIRECTION, payload : {dir, sortField}});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// изменения кол-ва отображаемых элементов на странице в пакетах
export const setPaginationPageSizeInPackages = ({Count, TableType = "main", Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TABLE_ACTIONS.CHANGE_COUNT_ITEM_ON_PAGE, payload : {
                    Count : Number.parseInt(Count),
                    tableType : TableType
            }});
            if(Next) Next.action(Next.params);
        } catch (exception) {
            console.log("Ошибка изменения кол-ва отображаемых элементов на странице в пакетах", exception);
            if(Error) Error.action(Error.params);
        }
    }
}

// TODO: переделать в норм вид работает не понятно как вообще
// обновление дерева из полученных данных
export const PackagesPaginationMovePage = (
    {
        PageNumber = 1,
        TableType = "main",
        IsDownload = false,
        fromBreadcrumbs=false,
        FieldSorting,
        Direction,
        Next = null,
        Error = null,
    }) => {
        return async dispatch => {
            try {
                let tree;
                const getCurrentValueOfTree = () => {
                    if(IsDownload || TableType === "download") {
                        return store.getState().packages.treeDownload
                    } else if (TableType === "main") {
                        return store.getState().packages.tree
                    } else if (TableType === "additional") {
                        return store.getState().packages.treeAdditional
                    }
                };

                await dispatch(changePaginationPageInPackages({
                    PageNumber,
                    TableType,
                    IsDownload
                }));


                tree = getCurrentValueOfTree()
                const activeNode = tree.activeNode
                if (!activeNode) {
                    return;
                }
                const PageSize = tree.paginationPageSize;
                const ViewFilter = tree.viewFilter

                let EntityFiltersExt = [];
                const inputs = tree.searchInputs
                if (inputs.NamePack !== "" && inputs.NamePack !== null && inputs.NamePack !== undefined) {
                    if (typeof inputs.NamePack === "object") {
                        let entity = __.setEntityFilters({
                            IdAttributeType: DataTypesEnum.String,
                            Value: inputs.NamePack,
                            Name: "Name",
                        }, true, "DocPackageExt");
                        EntityFiltersExt.push(entity.get())
                    } else {
                        EntityFiltersExt.push(
                            new EntityFilters().setEntityName("DocPackageExt")
                                .add({Name: "Name", Value: inputs.NamePack, Operator: FilerOperatorsEnum.Like})
                                .get()
                        )
                    }
                }
                if (inputs.ModifyDatePack !== "" && inputs.ModifyDatePack !== null && inputs.ModifyDatePack !== undefined) {
                    if (typeof inputs.ModifyDatePack === "object") {
                        let entity = __.setEntityFilters({
                            IdAttributeType: DataTypesEnum.DataTime,
                            Value: inputs.ModifyDatePack,
                            Name: "ModifyDate",
                        }, true, "DocPackageExt");
                        EntityFiltersExt.push(entity.get())
                    } else {
                        EntityFiltersExt.push(new EntityFilters().setEntityName("DocPackageExt")
                            .add({
                                Name: "ModifyDate",
                                Value: inputs.ModifyDatePack,
                                Operator: FilerOperatorsEnum.Like
                            })
                            .get()
                        )
                    }
                }
                if (inputs.RegDatePackAsString !== "" && inputs.RegDatePackAsString !== null && inputs.RegDatePackAsString !== undefined) {
                    if (typeof inputs.RegDatePackAsString === "object") {
                        let entity = __.setEntityFilters({
                            IdAttributeType: DataTypesEnum.DataTime,
                            Value: inputs.RegDatePackAsString,
                            Name: "CreateDate",
                        }, true, "DocPackageExt");
                        EntityFiltersExt.push(entity.get())
                    } else {
                        EntityFiltersExt.push(new EntityFilters().setEntityName("DocPackageExt")
                            .add({Name: "CreateDate", Value: inputs.RegDatePackAsString, Operator: FilerOperatorsEnum.Like})
                            .get()
                        )
                    }
                }
                await dispatch(getPackageGroupsForTree({
                    IdParent: activeNode.Node.Id,
                    TableType,
                    IsDownload,
                    IdPackageType: (IsDownload || TableType === "download") ? 1 : undefined,
                    EntityFiltersExt,
                    FieldSorting,
                    Direction,
                }));

                let lastLoad;
                lastLoad = __.deepCopy(getCurrentValueOfTree().lastLoad);

                let foldersValue;
                if (PageSize * (PageNumber - 1) === 0) {
                    foldersValue = [];
                } else {
                    foldersValue = __.dropArrayLeft(lastLoad.folders, PageSize * (PageNumber - 1))
                }
                lastLoad = {...lastLoad, folders: foldersValue}

                let PageSizeDocs = 0;
                if(lastLoad.folders.length < PageSize) {
                    PageSizeDocs = PageSize - lastLoad.folders.length;
                }

                let lFoldersTotal = lastLoad.foldersTotal;
                // TODO КН 17.05.24 Закомментил, т.к. сейсчас сделано так что выше в ластЛоад сетаются всегда те пакеты, которые по итогу окажутся в активНоде
                // if (getCurrentValueOfTree().isSearch) {
                //     //Задача № 24377
                //     //Если включена фильрация, то берем количество отфильтрованных пакетов, а не lastLoad
                //     lFoldersTotal = activeNode.Children.findIndex(item => item.Type == "PackagesDocument") != -1 ? activeNode.Children.findIndex(item => item.Type == "PackagesDocument") : activeNode.Children.length;
                // }

                let offsetFactor = 0;
                if(PageSizeDocs === PageSize) {
                    offsetFactor = Math.floor(lFoldersTotal % PageSize);
                }

                let docsPage = lFoldersTotal / PageSize >= 1
                    ? PageNumber - Math.floor(lFoldersTotal / PageSize)
                    : PageNumber;

                //TODO aa  алгоритм пагинации Задача № 19697 - Пустые страницы в Пакете
                // let docsOffset = (docsPage - 1) * PageSize - (offsetFactor);
                // let docsOffset = docsPage;
                let docsOffset = (docsPage - 1) * PageSize - (offsetFactor);

                //aa 10.10.2022 алгоритм следующий
                //1. Есть число пакетов
                //2. Оределяем для них номер крайней страницы и сколько на ней занято
                //3. Если на этом номере страницы есть свободное место, то получаем нужное число документов

                let lPageNumForFolder = (lFoldersTotal / PageSize) >= 1
                    // ? (Math.round(lFoldersTotal / PageSize) + Math.floor(lFoldersTotal % PageSize))
                    ? (Math.floor(lFoldersTotal / PageSize) + ((lFoldersTotal % PageSize) > 0 ? 1 : 0))
                    : (lFoldersTotal > 0 ? 1 : 0);

                let lFreeForDoc = 0;
                if ( Math.floor(lFoldersTotal % PageSize) != 0)
                    lFreeForDoc = PageSize - Math.floor(lFoldersTotal % PageSize);
                if (ViewFilter === PackagesViewFilters.OnlyDocuments || lFoldersTotal == 0) {
                    lPageNumForFolder = 1;
                    lFreeForDoc = PageSize;
                }

                if (lPageNumForFolder === PageNumber || (PageNumber == 1 && lPageNumForFolder == 0)) {
                    docsOffset = 0;
                    PageSizeDocs = lFreeForDoc;
                } else {
                    if (PageNumber > lPageNumForFolder) {
                        const lCountPages = PageNumber - lPageNumForFolder;
                        if (lPageNumForFolder == 0)
                            lFreeForDoc = 0;
                        docsOffset = (lFreeForDoc + (lCountPages - 1) * PageSize);
                        PageSizeDocs = PageSize;
                    } else {
                        docsOffset = -1;
                        // PageSizeDocs = 1;
                        //TODO 02.02.2023 обход Ошибка получения документов в пакете (post):
                        //ExceptionName: 'BusinessLogicException', ExceptionTime: '2023-02-02T11:20:16', Message: 'Смещение страницы не может быть отрицательным'
                        PageSizeDocs = 0;
                    }
                }

                //TODO aa 02.02.2023 новый сервер не дает запрос с -1, см комментарий выше
                if(docsOffset < 0) docsOffset = 0;

                await dispatch(getDocumentsForTreePackages({
                    Id: activeNode.Node.Id,
                    TableType,
                    IsDownload,
                    PageSize: PageSizeDocs,
                    PageOffset: docsOffset,
                    /* TODO КН 16.05.24 Вообще пакеты мы хотим хранить в ластЛоаде Неотфильтрованными и неотсортированными.
                        Но документы по итогу хранятся там часто отсортированными пользователем (ну хотя бы точно не отфильтрованными).
                        Думаю возможно можно решить эту проблему если понадобится тем, что в самом конце PackagesPaginationMovePage
                         написать что если !isSearch, то перезапросить данные в ластЛоад  с помощью еще одного getDocumentsForTreePackages,
                          только без Direction и FieldSorting, и снова перезаписать снова три рендер
                          с помощью new TreeCombinePackage(true, TableType, IsDownload).update(activeNode.Node.Id).get() и после
                          await dispatch({type: PACKAGES_TREE_ACTIONS.CREATE}) (самое главное активНоду не трогать)
                     */
                    Direction,
                    FieldSorting,
                }));

                //TODO Задача № 20067. Если уже есть бредкрамсы, то дерево заново не строим, иначе получится ситуация, когда в дочерних узлах нет данных
                tree = getCurrentValueOfTree()
                if (!fromBreadcrumbs) {
                    if (tree?.breadcrumbs?.find(lBreadcrumbs => lBreadcrumbs.PrevId === activeNode.Node.Id)) {
                        // У этого бредкрамса уже есть загруженные дети и он не подлежит перепостроению!
                        fromBreadcrumbs = true;
                    }
                }

                let treeRenderForSaveBreadcrumbs
                if (fromBreadcrumbs) {
                    treeRenderForSaveBreadcrumbs = tree.render
                }
                const newTreeRender = new TreeCombinePackage(true, TableType, IsDownload).update(activeNode.Node.Id).get();
                await dispatch({
                    type: PACKAGES_TREE_ACTIONS.CREATE, payload: {
                        tree: newTreeRender,
                        tableType: TableType,
                        IsDownload : IsDownload
                    }
                });
                // TODO КН 08.05.24 именно здесь сетается из (render в который положились пакеты из lastload) пакеты в activeNode
                // TODO КН 13.05.24 activeNode нужна для handlerSearchPackages, так что да, приходится здесь это делать даже если getCurrentValueOfTree().isSearch в котором она потом делается еще раз
                await dispatch(SelectActivePackageNode({
                    Id: activeNode.Node.Id,
                    TableType,
                    IsDownload,
                    // TODO КН 14.05.24 Флаг needForceRebuildActiveNode рабоатет так: Если нам потребовалось отфильтровать пакеты (сейчас мы фильтруем их в этой ф-ии всегда), значит их нужно обновить в активНоде данными из ластЛоада в дереве с помощью этого force флажка (иначе они останутся прежними)
                    needForceRebuildActiveNode: true,
                    Next: {
                        action: async () => {
                            const resetPackagesInLastLoadAndRender = async () => {
                                await dispatch(getPackageGroupsForTree({
                                    IdParent: activeNode.Node.Id,
                                    TableType,
                                    // TODO КН 16.05.24 FieldSorting и Direction не прокидывать! т.к. мы хотим вернуть ластЛоад и рендер в безфильтренное дефолтное состояние
                                    Next: {
                                        action: () => {
                                            let newTreeRendered
                                            if (treeRenderForSaveBreadcrumbs) {
                                                newTreeRendered = treeRenderForSaveBreadcrumbs
                                            } else {
                                                newTreeRendered = new TreeCombinePackage(true, TableType).update(activeNode.Node.Id).get();
                                            }
                                            store.dispatch({
                                                type: PACKAGES_TREE_ACTIONS.CREATE, payload: {
                                                    tree: newTreeRendered,
                                                    tableType: TableType
                                                }
                                            });
                                            if (Next) {
                                                Next.action(Next.params);
                                            }
                                        }
                                    }
                                }))
                            }
                            if (getCurrentValueOfTree().isSearch) {
                                await dispatch(getDocumentsForTreePackages({
                                    SearchByFilters: true,
                                    Id: activeNode.Node.Id,
                                    TableType,
                                    IsDownload,
                                    PageSize: PageSizeDocs,
                                    PageOffset: docsOffset,
                                    // TODO КН 16.05.24 Здесь мы прокидываем FieldSorting и Direction т.к. мы хотим хранить в temporaryData (туда кладутся новые данные если SearchByFilters: true) отсортированные и отфильтрованные документы
                                    FieldSorting,
                                    Direction,
                                    Next: {
                                        action: () => {
                                            dispatch(handlerSearchPackages({
                                                TableType,
                                                Next: {
                                                    action: () => {
                                                        dispatch(SelectActivePackageNode({
                                                            Id: activeNode.Node.Id,
                                                            TableType,
                                                            Next: {
                                                                action: async () => {
                                                                    dispatch(getAndSetAppliedParams({
                                                                        TableType,
                                                                        Next: {
                                                                            action: async () => {
                                                                                await resetPackagesInLastLoadAndRender()
                                                                            },
                                                                            params: {}
                                                                        }}))
                                                                },
                                                                params: {}
                                                            }
                                                        }))
                                                    },
                                                    params: {}
                                                }
                                            }))
                                        },
                                        params: {}
                                    }
                                }))
                            }
                            else {
                                await resetPackagesInLastLoadAndRender()
                            }
                        },
                        params: {}
                    }
                }));
            } catch (exception) {
                console.log("Ошибка обновления дерева документов (catch) ", exception);
                if (Error) {
                    Error.action(Error.params);
                } // если ошибка колбек
            }
        }
}

export const setSearchNode = (searchNode, IsDownload, TableType) => {
    return async dispatch => {

        if (searchNode && searchNode.Children) {
            searchNode.Children = searchNode.Children.filter(item => item.Type === "PackagesFolder");
            let tree;

            if (IsDownload || TableType === "download") {
                tree = store.getState().packages.treeDownload;
            } else if (TableType === "main") {
                tree = store.getState().packages.tree;
            } else if (TableType === "additional") {
                tree = store.getState().packages.treeAdditional;
            }
            const lastLoadReal = tree.lastLoad

            for (let i = 0; i < lastLoadReal.documents.length; i++) {
                let TreeId = createUniqueIdString(6);
                let Type = "PackagesDocument";
                let Children = [];
                searchNode.Children.push(
                    {
                        TreeId,
                        Type,
                        Children,
                        Node: lastLoadReal.documents[i]
                    }
                )
            }
            dispatch({
                type: PACKAGES_TREE_ACTIONS.SEARCH_NODE, payload: {
                    searchNode: searchNode,
                    tableType: TableType
                }
            });
        }
    }
}

export const setViewModePreviewPackages = ({ViewMode = "none", Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TABLE_ACTIONS.SET_VIEW_MODE_PREVIEW, payload : {ViewMode : ViewMode}});
            if(Next) Next.action(Next.params);
        } catch (exception) {
            console.log("Ошибка изменения кол-ва отображаемых элементов на странице в пакетах", exception);
            if(Error) Error.action(Error.params);
        }
    }
}

export const setPreviewSizePackages = ({Size = "normal", Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let stateSize = store.getState().packages.rootState.viewModePreview_Size;

            let saveSize = Size;
            if(stateSize === "normal") {
                saveSize = Size;
            }
            else if(stateSize === "half") {
                if(Size === "half") saveSize = "normal";
                if(Size === "full") saveSize = Size;
            }
            else if(stateSize === "full") {
                if(Size === "half") saveSize = Size;
                if(Size === "full") saveSize = "normal";
            }


            dispatch({type : PACKAGES_TABLE_ACTIONS.SET_SIZE_PREVIEW, payload : {Size : saveSize}});
            if(Next) Next.action(Next.params);
        } catch (exception) {
            console.log("Ошибка изменения кол-ва отображаемых элементов на странице в пакетах", exception);
            if(Error) Error.action(Error.params);
        }
    }
}


// скопировать данные для дерева документов
export const copyDocumentTreeForPackages = ({Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TABLE_ACTIONS.COPY_DOCUMENT_TREE, payload : {
                tree : __.deepCopy(store.getState().document.tree)
            }});
            // следующее действие если есть цепочка
            if(Next) Next.action(Next.params);
        } catch (exception) {
            console.log("Копирования дерева документов в пакеты (catch): ", exception);
            if(Error) Error.action(Error.params);
        }
    }
}




// сортировки для пагинации
// export const sortingPackagesForPagination = ({TableType = "main", Next = null, Error = null}) => {
//     return async dispatch => {
//         try {
//
//             let activeTreeNode = TableType === "main"
//                 ? __.deepCopy(store.getState().packages.tree.activeNode)
//                 : __.deepCopy(store.getState().packages.treeAdditional.activeNode);
//
//
//             let viewFilter = TableType === "main"
//                 ? store.getState().packages.tree.viewFilter
//                 : store.getState().packages.treeAdditional.viewFilter;
//
//
//
//
//
//             dispatch({type : PACKAGES_TABLE_ACTIONS.SORTING_ACTIVE_NODE_FOR_PAGINATION, payload : {
//                  //   Count : Number.parseInt(Count),
//                     tableType : TableType
//             }});
//             if(Next) Next.action(Next.params);
//         } catch (exception) {
//             if(Error) Error.action(Error.params);
//         }
//     }
// }

export const exportExcel = ({idPackage, isDocuments = false,  Columns = null,  ReportName = "Report", Next = null, Error = null})=>{
        return async dispatch => {
            try {

                let Filial = store.getState().globalState.filial.Active;
                let ValueFilter = new Filter(FiltersType.ENTITY)
                    .addAttribute({Name: "IdPackage", Value: idPackage})
                    .add(
                        new EntityFilters().setEntityName("DocInPackage")
                            .add({Name: "IdFilial", Value: Filial.Id}).get()
                    ).get();
                ValueFilter = JSON.stringify(ValueFilter);

                let filter = new Filter(FiltersType.SIMPLE)
                    .add({Name : "Filter", Value : {
                        TypeName : "DocPackageRestrictFilter",
                        Value : ValueFilter
                    }});


                let sorting = new Sorting()
                    .add({PropertyName: "RegDate", Direction: 2})
                    .add({PropertyName: "RegNumber", Direction: 1})
                    .add({PropertyName: "Id", Direction: 1})
                    .get();

                sorting = JSON.stringify(sorting);

               let ReportColumns = [];
                ReportColumns.push({
                    ColumnName: "Дата",
                    Format: "dd.MM.yyyy",
                    PropertyName: "RegDate"
                });

                ReportColumns.push({
                    ColumnName: "Номер",
                    PropertyName: "RegNumber"
                });

                ReportColumns.push({
                    ColumnName: "Наименование",
                    PropertyName: "Name"
                });

                ReportColumns.push({
                    ColumnName: "Тип документа",
                    PropertyName: "Type.Name"
                });


               let parameters = {
                   EntityName : "DocInPackage",
                   ReportName : ReportName,
                   ReportColumns,
                   Filter : filter.get().Filter,
                   Sorting : {
                       TypeName : "DocSorting",
                       Value : sorting
                   }
               }


                let reportGuid = await API.reports().createEntityReport({parameters});

               if(reportGuid?.errorCode) {
                   return;
               }



            } catch (exception){
                console.log("Ошибка сортировки для пагинации в пакетах", exception);
                 if(Error) Error.action(Error.params);
            }
        }
}

export const makePackageActive=({item={}})=>{
    return async dispatch => {
        try {
            dispatch({type :PACKAGES_TABLE_ACTIONS.GET_PACKAGE_AFTER_RIGHT_CLICK, payload : item});

        } catch (exception) {
            console.log("Ошибка получения активного пакета (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}





