import React from "react";
import {connect} from "react-redux";
import {Dropdown} from "react-bootstrap";
import {
    addModalData,
    setLoaderModalData,
    setModalData,
    setStateLeftAsideMenu
} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {ModalTypes, RootComponentsStateViewModeTypes} from "../../tools/StaticTypes";
import {MyTooltip} from "../overPage/tooltip/MyTooltip";
import {ActionQueue} from "../../store/rootReducer";
import {
    combineMainTableAttributes,
    mainTableDownloadDocCollection, mainTableSearchDoc,
    resetMainTableForUpdate, selectActiveRow
} from "../../store/documents/actionCreators/document_MainTableActionCreator";
import {__} from "../../tools/HelpFunctions";
import {
    collectDataForAdditionalSections_Normal, createPreviewBySelectDocument,
    resetDocumentPreview
} from "../../store/documents/actionCreators/document_PreviewActionCreator";
import {changeDocumentViewMode} from "../../store/documents/actionCreators/document_RootStateActionCreator";
import {movingDocumentsInPackageFromDocuments} from "../../store/packages/actionCreators/package_TransferActionCreator";
import {
    changeDocumentSearchInputs,
    changeViewMode
} from "../../store/documents/actionCreators/document_SearchActionCreator";
import {changeWrap} from "../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import EntityReport from "../../tools/EntityReport";
import {IReportState} from "../../tools/API_NEW/ServerTypes";
import {TreeCombine} from "../../tools/TreeCombine";
import {treeSelectNode} from "../../store/documents/actionCreators/document_TreeActionCreator";
import {store} from "../../index";
import {API} from "../../tools/API_NEW/API";

class MainTableSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            applyFilterTooltipShow: false,
            cancelFilterTooltipShow: false,
            timeFilterTooltipShow: false,
            exportXlsTooltipShow: false,
            fitInContainerSizeTooltipShow: false,
            deleteDocTooltipShow: false,
            updateTableTooltipShow: false,
            copyUrlTooltipShow: false,
            copyUrl1CTooltipShow: false,
            downloadTooltipShow: false,
            search: false,
            isFitInContainerSize: false
        }

        this.applyFilterRef = React.createRef();
        this.cancelFilterRef = React.createRef();
        this.timeFilterRef = React.createRef();
        this.exportXlsRef = React.createRef();
        this.fitInContainerSize = React.createRef();
        this.deleteDocRef = React.createRef();
        this.updateTableRef = React.createRef();
        this.copyUrlRef = React.createRef();
        this.copyUrl1CRef = React.createRef();
        this.downloadRef = React.createRef();

        this.settingsTable = this.settingsTable.bind(this);
        this.changeDocumentType = this.changeDocumentType.bind(this);
        this.changeDepartments = this.changeDepartments.bind(this);
        this.testPackage = this.testPackage.bind(this);
        this.updateTable = this.updateTable.bind(this);
        this.search = this.search.bind(this);
        this.toggleRowsStyle = this.toggleRowsStyle.bind(this);
        this.exportXLS = this.exportXLS.bind(this);
        this.newDocumentByVersion = this.newDocumentByVersion.bind(this);
        this.changeDocumentSections = this.changeDocumentSections.bind(this);
        this.isSection = this.isSection.bind(this);
        this.getDocsAsZipArchive = this.getDocsAsZipArchive.bind(this);

    }


    async search(event) {
        if (event.target.id === "show") {
            const isClose = this.props.asideMenuIsClose && this.props.isSearch;
            if (!(isClose)) {
                this.props.changeViewMode({
                    isSearch: !this.props.isSearch
                });
            }

            if (this.props.asideMenuIsClose) {
                this.props.setStateLeftAsideMenu({isClose: false});
            }
        } else {
            const {activeRow, activeNode} = this.props;
            if (activeRow) {
                if (this.props.searchInputs.length === 0 || this.props.searchInputs.every(item => !item.IsApplied)) {
                    //TODO aa 31.10.2023 Не менять тип предпросмотра, замечание от Четвертиковой
                    // this.props.changeDocumentViewMode({ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal})
                } else {
                    await this.props.ActionQueue({
                        List: [
                            {
                                action: this.props.changeDocumentSearchInputs,
                                params: {Delete: true},
                                name: "changeDocumentSearchInputs"
                            },
                            {action: this.props.changeViewMode, params: {clearFilters: true}, name: "changeViewMode"},
                            {
                                action: this.props.setLoaderModalData, params: {
                                    data: {
                                        content: "Загрузка документов...",
                                        disableButton: true,
                                        fullBackground: true,
                                        gif: "documents",
                                        key: "inputSearchDoc215"
                                    }
                                }, name: "setLoaderModalData"
                            },
                            {
                                action: this.props.mainTableDownloadDocCollection,
                                params: {id: activeRow.Info.Id},
                                name: "mainTableDownloadDocCollection"
                            },
                            {
                                action: this.props.combineMainTableAttributes,
                                params: {ActiveNode: activeNode},
                                name: "combineMainTableAttributes"
                            },
                            {action: this.props.selectActiveRow, params: {RowInfo: activeRow}, name: "selectActiveRow"},

                            //TODO aa 31.10.2023 Не менять тип предпросмотра, замечание от Четвертиковой
                            // {action: this.props.resetDocumentPreview, params: {}, name: "resetDocumentPreview"},
                            {
                                action: this.props.createPreviewBySelectDocument,
                                params: {},
                                name: "createPreviewBySelectDocument"
                            },
                            {
                                action: this.props.collectDataForAdditionalSections_Normal,
                                params: {},
                                name: "collectDataForAdditionalSections_Normal"
                            },
                            //TODO aa 31.10.2023 Не менять тип предпросмотра, замечание от Четвертиковой
                            // {
                            //     action: this.props.changeDocumentViewMode,
                            //     params: {ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal},
                            //     name: "changeDocumentViewMode"
                            // },
                            {
                                action: this.props.setLoaderModalData,
                                params: {keyDeleted: "inputSearchDoc228"},
                                name: "setLoaderModalData"
                            },
                        ],
                        debug: true
                    });
                }
            } else {
                if (this.props.searchInputs.length === 0 || this.props.searchInputs.every(item => !item.IsApplied)) {
                    this.props.changeDocumentViewMode({ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly})
                    this.props.changeViewMode({})
                } else {
                    await this.props.ActionQueue({
                        List: [
                            {
                                action: this.props.setLoaderModalData, params: {
                                    data: {
                                        content: "Загрузка...",
                                        disableButton: true,
                                        fullBackground: true,
                                        gif: "documents",
                                        key: "MainTableSearch108"
                                    }
                                }, name: "setLoaderModalData"
                            },
                            {
                                action: this.props.changeDocumentSearchInputs,
                                params: {Delete: true},
                                name: "changeDocumentSearchInputs"
                            },
                            {action: this.props.changeViewMode, params: {clearFilters: true}, name: "changeViewMode"},
                            {action: this.props.mainTableSearchDoc, params: {}, name: "mainTableSearchDoc"},
                            {
                                action: this.props.combineMainTableAttributes,
                                params: {},
                                name: "combineMainTableAttributes"
                            },
                            {
                                action: this.props.changeDocumentViewMode,
                                params: {ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly},
                                name: "changeDocumentViewMode"
                            },
                            {
                                action: this.props.setLoaderModalData,
                                params: {keyDeleted: "MainTableSearch123"},
                                name: "setLoaderModalData"
                            },
                        ],
                        debug: true
                    });
                }
            }
        }
    }


    // открыть модальное окно настроек столбцов таблицы
    settingsTable() {
        this.props.setModalData({
            name: ModalTypes.documents.mainTable.Settings,
            data: {}
        });
    }


    changeDocumentType() {
        // TODO: определение браузера
        //  console.log(detect.parse(navigator.userAgent));
        this.props.setModalData({
            name: ModalTypes.documents.mainTable.ChangeDocumentTypeOriginal,
            data: {}
        });
    }

    changeDepartments() {
        this.props.setModalData({
            name: ModalTypes.documents.mainTable.ChangeDepartments,
            data: {}
        });
    }

    newDocumentByVersion() {
        this.props.addModalData({
            name: ModalTypes.documents.mainTable.NewDocumentByVersion,
            data: {},
            modalId: 'NewDocumentByVersion',
        });
    }

    testPackage() {
        if (this.props.checkedFields.length > 0 || this.props.activeRow) {
            if (this.props.activeRow?.Info?.IsMarkedForDelete) {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {content: `Ошибка переноса документа: <br/>Документ помечен на удаление`, type: "fail"}
                });
            } else {
                this.props.setModalData({
                    name: ModalTypes.documents.mainTable.ChangeDocumentType,
                    data: {}
                });
            }
        }
    }

    // обновление таблицы документов
    updateTable({withoutModal}) {
        let activeRow = __.deepCopy(this.props.activeRow);
        // this.props.resetMainTableForUpdate({
        //   Next: {
        //       action : () => {
        //           this.props.mainTableDownloadDocCollection({
        //               ActiveNode : this.props.activeNode,
        //               Next : {
        //                   action : () => {
        //                       this.props.combineMainTableAttributes({
        //                           ActiveNode : this.props.activeNode,
        //                           Next : {
        //                               action : () => {
        //                                   this.props.selectActiveRow({
        //                                       RowInfo : activeRow,
        //                                       Next : {
        //                                           action : () => {
        //                                               this.props.resetDocumentPreview({
        //                                                   Next : {
        //                                                       action : () => {
        //                                                           this.props.createPreviewBySelectDocument({
        //                                                               Next : {
        //                                                                   action : () => {
        //                                                                       this.props.mainTableSearchDoc({
        //                                                                           PaginationPageNumber : 1,
        //                                                                           Next : {
        //                                                                               action : () => {
        //                                                                                   this.props.combineMainTableAttributes({
        //                                                                                       Next : {
        //                                                                                           action : () => {
        //                                                                                               this.props.setModalData({
        //                                                                                                   name: ModalTypes.app.info, data: {type : "success", content : `Таблица успешно обновлена`},
        //                                                                                                   Next : {
        //                                                                                                       action : () => {
        //                                                                                                           this.props.collectDataForAdditionalSections_Normal({
        //                                                                                                               Next : {
        //                                                                                                                   action : () => {
        //                                                                                                                       if(this.props.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly) {
        //                                                                                                                           if(activeRow) {
        //                                                                                                                               this.props.changeDocumentViewMode({
        //                                                                                                                                   ViewMode : RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal
        //                                                                                                                               });
        //                                                                                                                           } else {
        //                                                                                                                               this.props.changeDocumentViewMode({
        //                                                                                                                                   ViewMode : RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly
        //                                                                                                                               });
        //                                                                                                                           }
        //                                                                                                                       }
        //                                                                                                                   },
        //                                                                                                                   params : {}
        //                                                                                                               }
        //                                                                                                           })
        //                                                                                                       },
        //                                                                                                       params : {}
        //                                                                                                   }
        //                                                                                               })
        //                                                                                           },
        //                                                                                           params : {}
        //                                                                                       }
        //                                                                                   })
        //                                                                               },
        //                                                                               params : {}
        //                                                                           }
        //                                                                       })
        //                                                                   },
        //                                                                   params : {}
        //                                                               }
        //                                                           })
        //                                                       },
        //                                                       params : {}
        //                                                   }
        //                                               })
        //                                           },
        //                                           params : {}
        //                                       }
        //                                   })
        //                               },
        //                               params : {}
        //                           }
        //                       })
        //                   },
        //                   params : {}
        //               }
        //           })
        //       },
        //       params : {}
        //   }
        // })

        // this.props.setModalData({
        //     name : ModalTypes.app.alert,
        //     data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents"}
        // });
        //TODO Лишний прелоадер, он потом же есть в очереди, зачем?????
        // //Новый прелоадер
        // store.dispatch(setLoaderModalData({
        //     data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents", key : "MainTableSearch310" }
        // }));

        if (activeRow) {
            this.props.ActionQueue({
                List: [
                    //Новый прелоадер
                    {
                        action: this.props.setLoaderModalData,
                        params: {
                            data: {
                                content: "Загрузка...",
                                disableButton: true,
                                fullBackground: false,
                                gif: "documents",
                                key: "MainTableSearch316"
                            }
                        },
                        name: "setLoaderModalData"
                    },
                    {action: this.props.resetMainTableForUpdate, params: {}, name: "resetMainTableForUpdate"},
                    {
                        action: this.props.mainTableDownloadDocCollection,
                        params: {ActiveNode: this.props.activeNode},
                        name: "mainTableDownloadDocCollection"
                    },
                    {
                        action: this.props.combineMainTableAttributes,
                        params: {ActiveNode: this.props.activeNode},
                        name: "combineMainTableAttributes"
                    },
                    {action: this.props.selectActiveRow, params: {IdDoc: activeRow.Info.Id}, name: "selectActiveRow"},
                    // TODO: MS 06.04 — #22858
                    {action: this.props.resetDocumentPreview, params: {}, name: "resetDocumentPreview"},
                    {
                        action: this.props.createPreviewBySelectDocument,
                        params: {},
                        name: "createPreviewBySelectDocument"
                    },
                    // {action: this.props.mainTableSearchDoc, params: {PaginationPageNumber : 1}, name: "mainTableSearchDoc"},
                    // {action: this.props.combineMainTableAttributes, params: {}, name: "combineMainTableAttributes"},
                    // TODO: MS 09.03 - Оставь, рождает баги и лишние закачки с сервака.
                    // withoutModal !== true && {action: this.props.setModalData, params: {name: ModalTypes.app.info, data: {type : "success", content : `Таблица успешно обновлена`}}, name: "setModalData"},
                    // TODO: MS 30.03 — убрал по просьбе Полины
                    //Новый прелоадер
                    {
                        action: this.props.setLoaderModalData,
                        params: {keyDeleted: "MainTableSearch331"},
                        name: "setLoaderModalData"
                    },

                    {
                        action: () => {
                            this.props.collectDataForAdditionalSections_Normal({});
                            if (this.props.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly) {
                                if (activeRow) {
                                    this.props.changeDocumentViewMode({
                                        ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal
                                    });
                                } else {
                                    this.props.changeDocumentViewMode({
                                        ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly
                                    });
                                }
                            }
                        }, params: {}, name: "resetDocumentPreview"
                    },
                    //Новый прелоадер
                    {
                        action: this.props.setLoaderModalData,
                        params: {keyDeleted: "MainTableSearch347"},
                        name: "setLoaderModalData"
                    },
                ],
                debug: false
            });
        } else {
            this.props.ActionQueue({
                List: [
                    //Новый прелоадер
                    {
                        action: this.props.setLoaderModalData,
                        params: {
                            data: {
                                content: "Загрузка...",
                                disableButton: true,
                                fullBackground: false,
                                gif: "documents",
                                key: "MainTableSearch355"
                            }
                        },
                        name: "setLoaderModalData"
                    },
                    {action: this.props.resetMainTableForUpdate, params: {}, name: "resetMainTableForUpdate"},
                    {
                        action: this.props.mainTableDownloadDocCollection,
                        params: {ActiveNode: this.props.activeNode},
                        name: "mainTableDownloadDocCollection"
                    },
                    {
                        action: this.props.combineMainTableAttributes,
                        params: {ActiveNode: this.props.activeNode},
                        name: "combineMainTableAttributes"
                    },
                    // {action: this.props.selectActiveRow , params : { RowInfo : activeRow}, name: "selectActiveRow"},
                    {action: this.props.resetDocumentPreview, params: {}, name: "resetDocumentPreview"},
                    // {action: this.props.createPreviewBySelectDocument , params : {}, name: "createPreviewBySelectDocument"},
                    // {action: this.props.mainTableSearchDoc, params: {PaginationPageNumber : 1}, name: "mainTableSearchDoc"},
                    // {action: this.props.combineMainTableAttributes, params: {}, name: "combineMainTableAttributes"},
                    // TODO: MS 09.03 - Оставь, рождает баги и лишние закачки с сервака.
                    // withoutModal !== true && {action: this.props.setModalData, params: {name: ModalTypes.app.info, data: {type : "success", content : `Таблица успешно обновлена`}}, name: "setModalData"},
                    // TODO: MS 30.03 — убрал по просьбе Полины
                    //Новый прелоадер
                    {
                        action: this.props.setLoaderModalData,
                        params: {keyDeleted: "MainTableSearch368"},
                        name: "setLoaderModalData"
                    },
                    {
                        action: () => {
                            this.props.collectDataForAdditionalSections_Normal({});
                            if (this.props.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly) {
                                if (activeRow) {
                                    this.props.changeDocumentViewMode({
                                        ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal
                                    });
                                } else {
                                    this.props.changeDocumentViewMode({
                                        ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly
                                    });
                                }
                            }
                        }, params: {}, name: "resetDocumentPreview"
                    },
                    {
                        action: this.props.setLoaderModalData,
                        params: {keyDeleted: "MainTableSearch383"},
                        name: "setLoaderModalData"
                    },
                ],
                debug: false
            });
        }
    }

    toggleRowsStyle() {
        this.props.changeWrap({})
    }

    async exportXLS() {
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        //Новый прелоадер
        this.props.setLoaderModalData({
            data: {
                content: "Загрузка отчета...",
                disableButton: true,
                fullBackground: false,
                gif: "documents",
                key: "MainTableSearch"
            }
        });

        try {

            let entityReport = new EntityReport({
                Type: "documents",
                idObject: this.props.activeNode.info.Id,
                Columns: this.props.mainTableHeaders,
                ReportName: __.translit(this.props.activeNode.info.Name)
            });

            let reportGuid = await entityReport.create();

            while (true) {
                let status = await entityReport.getState();
                if (status) {
                    if (status == IReportState.Done) break;
                }
                await sleep(5000);
            }

            let report = await entityReport.getReportFile();

            var link = document.createElement("a");
            link.setAttribute("href", URL.createObjectURL(report.file));
            link.setAttribute("download", report.name);
            link.click();

            //Новый прелоадер
            this.props.setLoaderModalData({keyDeleted: "MainTableSearch475"});
        } catch (e) {
            //Новый прелоадер
            this.props.setLoaderModalData({keyDeleted: "MainTableSearch479"});
        }
    }

    changeDocumentSections() {
        this.props.setModalData({
            name: ModalTypes.documents.mainTable.ChangeDocumentSections,
            data: {}
        });
    }

    async isSection() {
        if (this.props.activeNode) {
            let treeNode = TreeCombine.searchByTreeId({
                    treeId: this.props.activeNode?.parent,
                    isSearchTree: true,
                    tree: this.props.treeRender
                }
            );
            if (!treeNode) {
                if (this.props.activeNode.Id && this.props.treeRender.children) {
                    treeNode = __.findGroup(this.props.treeRender.children, this.props.activeNode.Id);
                    this.props.treeSelectNode({Node: treeNode});
                    if (treeNode.info.Type === 2) {
                        return true
                    }
                    if (treeNode.info.Type === 3) {
                        return true
                    }
                } else {
                    treeNode = __.findGroup(this.props.treeRender.children, this.props.activeNode.info.Id);
                    await this.props.treeSelectNode({Node: treeNode});
                    await this.updateTable({withoutModal: true})
                }
            }
        }
    }

    //---------------
    async getDocsAsZipArchive() {
        if (this.props.checkedFields.length > 0) {
            try {
                this.props.setLoaderModalData({
                    data: {
                        content: "Загрузка отчета...",
                        disableButton: true,
                        fullBackground: false,
                        gif: "documents",
                        key: "MainTableSearch"
                    }
                });

                const zipFile = await API.documents().ExportDocsAsZipArchive({
                    IdDocs: this.props.checkedFields,
                    includeMainFile: true,
                    includeAttached: true,
                    includeSigs: true
                });

                const link = document.createElement("a");
                link.setAttribute("href", URL.createObjectURL(zipFile.file));
                link.setAttribute("download", zipFile.name);
                link.click();

                this.props.setLoaderModalData({keyDeleted: "MainTableSearch540"});
            } catch (e) {
                console.log(e)
            }
        }
    }

    render() {
        let {globalSettings, adminLevel, activeNode} = this.props;
        let lFlagWithDepartments = globalSettings.WithDepartments === "1";
        let lFlagWithSection = globalSettings.WithSection === "1";
        let lFlagCopyUrl1C = globalSettings.WithCopyUrl1C === "1";

        let moveDocInPackStyle = {};
        if (this.props.checkedFields.length === 0 && !this.props.activeRow) {
            moveDocInPackStyle = {
                color: "rgba(63, 66, 84, 0.42)"
            }
        }

        let exportDocsStyle = {};
        if (this.props.checkedFields.length === 0) {
            exportDocsStyle = {
                color: "rgba(63, 66, 84, 0.42)"
            }
        }

        let filterStyle = "icon-color-primary";
        this.props.searchInputs.forEach(item => {
            if (filterStyle === "icon-color-secondary") return
            if (typeof item.Value === "object") {
                filterStyle = item.IsApplied ? "icon-color-secondary" : "icon-color-primary"
            } else if (item.Value !== "") {
                filterStyle = "icon-color-secondary";
            }
        })

        let isSection = this.isSection();
        let isVisible = false;
        if (this.props.activeNode?.info.ModifyAccess && this.props?.adminLevel >= 1) {
            // TODO: MS: 09.03 Рождало баг, при котором падало приложение при отсутствие выбранного activeNode
            isVisible = true;
        }

        let isChangeDocGroup = this.props.docGroup.findIndex(item => item.Type === 2 || item.Type === 3)

        return (


            <div className="card-header border-0 py-2">
                <form className="form d-flex" style={{alignItems: "center", width: 'calc(100%/3)', paddingLeft: '12.5px'}}>
                    {/*<div className="form-group card-toolbar">*/}
                    {/*    <div className="input-icon input-icon-right">*/}
                    {/*        <input type="text" disabled className="form-control" placeholder="Поиск"/>*/}
                    {/*        <span className="svg-icon menu-icon nav-icon"><i className="icon-Search"/></span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {
                        this.props.checkedFields.length > 0 &&
                        <div>Документов выбрано: <b>{this.props.checkedFields.length}</b></div>
                    }
                </form>
                <div className="d-flex flex-wrap flex-grow-1 align-items-center justify-content-end">
                    <div className="btn-toolbar card-toolbar flex-grow-1 d-contents-in-992">
                        {
                            this.state.applyFilterTooltipShow &&
                            <MyTooltip target={this.applyFilterRef}
                                       text={"Показать/скрыть фильтр"}
                                       show={this.state.applyFilterTooltipShow}
                                       placement={"top"} delay={500}
                            />
                        }
                        <span className="btn btn-icon btn-circle btn-sm mx-2" ref={this.applyFilterRef}
                              id="show"
                              onMouseEnter={() => {
                                  this.setState({applyFilterTooltipShow: true})
                              }}
                              onMouseLeave={() => {
                                  this.setState({applyFilterTooltipShow: false})
                              }}
                              onClick={(event) => {
                                  this.search(event)
                              }}>
                            <i id="show" className={`svg-icon icon-Table_edit_7 ${filterStyle}`}/>
                        </span>

                        {
                            this.state.cancelFilterTooltipShow &&
                            <MyTooltip target={this.cancelFilterRef}
                                       text={"Сбросить и закрыть фильтр"}
                                       show={this.state.cancelFilterTooltipShow}
                                       placement={"top"} delay={500}
                            />
                        }
                        <span className="btn btn-icon btn-circle btn-sm" ref={this.cancelFilterRef}
                              id="disabled"
                              onMouseEnter={() => {
                                  this.setState({cancelFilterTooltipShow: true})
                              }}
                              onMouseLeave={() => {
                                  this.setState({cancelFilterTooltipShow: false})
                              }}
                              onClick={(event) => {
                                  this.search(event)
                              }}>
                            <i id="disabled" className="svg-icon icon-Table_edit_8 icon-color-primary"/>
                        </span>

                        {
                            // this.state.timeFilterTooltipShow &&
                            // <MyTooltip target={this.timeFilterRef}
                            //            text={"Фильтр по дате"}
                            //            show={this.state.timeFilterTooltipShow}
                            //            placement={"top"} delay={500}
                            // />
                        }
                        {/*<span className="btn btn-icon btn-circle btn-sm ml-2 mrimp" style={{ marginRight: "auto !important"}}*/}
                        {/*      ref={this.timeFilterRef}*/}
                        {/*      onMouseEnter={()=>{this.setState({timeFilterTooltipShow : true})}}*/}
                        {/*      onMouseLeave={()=>{this.setState({timeFilterTooltipShow : false})}}>*/}
                        {/*    <i className="svg-icon icon-Filter_data"/>*/}
                        {/*</span>*/}
                    </div>

                    <div className="card-toolbar d-contents-in-992">

                        {
                            store.getState().globalState.settings.Content?.WithDownloadFiles == "1" &&
                            <>
                                {
                                    this.state.downloadTooltipShow &&
                                    <MyTooltip target={this.downloadRef}
                                               text={"Скачать файл"}
                                               show={this.state.downloadTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                <button className="btn btn-icon btn-sm ml-2" ref={this.downloadRef}
                                        style={this.props.checkedFields.length > 0 ? {cursor: "pointer"} : {cursor: "default"}}
                                        disabled={this.props.checkedFields.length === 0}
                                        onClick={this.getDocsAsZipArchive}
                                        onMouseEnter={() => {
                                            this.setState({downloadTooltipShow: true})
                                        }}
                                        onMouseLeave={() => {
                                            this.setState({downloadTooltipShow: false})
                                        }}>
                                    <i className={`svg-icon svg-icon-sm ${this.props.checkedFields.length > 0 ? "icon-color-primary" : "disabled"} icon-Doc_view_4`}/>
                                </button>
                            </>
                        }
                        {
                            this.state.copyUrlTooltipShow &&
                            <MyTooltip target={this.copyUrlRef}
                                       text={"Скопировать ссылку"}
                                       show={this.state.copyUrlTooltipShow}
                                       placement={"top"} delay={500}
                            />
                        }
                        <button className="btn btn-icon btn-sm ml-2" ref={this.copyUrlRef}
                                style={store.getState().router?.location?.query.d ? {cursor: "pointer"} : {cursor: "default"}}
                                disabled={!store.getState().router?.location?.query.d}
                              onClick={(event) => {
                                  __.copyUrl();
                                  __.showModalCopyComplete(this.props.setModalData);
                              }

                              }
                              onMouseEnter={() => {
                                  this.setState({copyUrlTooltipShow: true})
                              }}
                              onMouseLeave={() => {
                                  this.setState({copyUrlTooltipShow: false})
                              }}>
                            <i className={`svg-icon svg-icon-sm ${store.getState().router?.location?.query.d ? "text-primary" : "disabled"} icon-Doc_view_14`}/>
                        </button>

                        {lFlagCopyUrl1C &&
                            <>
                                {
                                    this.state.copyUrl1CTooltipShow &&
                                    <MyTooltip target={this.copyUrl1CRef}
                                               text={"Скопировать ссылку для 1С"}
                                               show={this.state.copyUrl1CTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                <button className="btn btn-icon btn-sm ml-2" ref={this.copyUrl1CRef}
                                        style={store.getState().router?.location?.query.d ? {cursor: "pointer"} : {cursor: "default"}}
                                      disabled={!store.getState().router?.location?.query.d}
                                      onClick={(event) => {
                                          __.copyUrl1c();
                                          __.showModalCopyComplete(this.props.setModalData);
                                      }}
                                      onMouseEnter={() => {
                                          this.setState({copyUrl1CTooltipShow: true})
                                      }}
                                      onMouseLeave={() => {
                                          this.setState({copyUrl1CTooltipShow: false})
                                      }}>
                                    {/*TODO aa 31.10.2023 пока не понял кто так поставил, т.к. при активном документе кнопка заблокирована, возможо автор пояснит и поправит как нужно */}
                                    {/*<i className={`svg-icon svg-icon-sm ${store.getState().router?.location?.search?.indexOf("d=") == -1 ? "text-primary" : "disabled"} icon-c_16`}/>*/}
                                    <i className={`svg-icon svg-icon-sm ${store.getState().router?.location?.query.d ? "text-primary" : "disabled"} icon-c_16`}/>
                                </button>
                            </>
                        }

                        {
                            this.state.updateTableTooltipShow &&
                            <MyTooltip target={this.updateTableRef}
                                       text={"Обновить таблицу"}
                                       show={this.state.updateTableTooltipShow}
                                       placement={"top"} delay={500}
                            />
                        }
                        <span className="btn btn-icon btn-sm ml-2" ref={this.updateTableRef}
                              onClick={this.updateTable}
                              onMouseEnter={() => {
                                  this.setState({updateTableTooltipShow: true})
                              }}
                              onMouseLeave={() => {
                                  this.setState({updateTableTooltipShow: false})
                              }}>
                            <i className="svg-icon icon-Reload icon-color-primary"/>
                        </span>

                        {
                            this.state.exportXlsTooltipShow &&
                            <MyTooltip target={this.exportXlsRef}
                                       text={"Экспорт в XLS"}
                                       show={this.state.exportXlsTooltipShow}
                                       placement={"top"} delay={500}
                            />
                        }
                        <span className="btn btn-icon btn-circle btn-sm ml-2" ref={this.exportXlsRef}
                              onClick={this.exportXLS}
                              onMouseEnter={() => {
                                  this.setState({exportXlsTooltipShow: true})
                              }}
                              onMouseLeave={() => {
                                  this.setState({exportXlsTooltipShow: false})
                              }}>
                            <i className="svg-icon icon-color-primary icon-Table_edit_10"/>
                        </span>

                        {
                            this.state.fitInContainerSizeTooltipShow &&
                            <MyTooltip target={this.fitInContainerSize}
                                       text={"Вписать"}
                                       show={this.state.fitInContainerSizeTooltipShow}
                                       placement={"top"} delay={500}
                            />
                        }
                        <span
                            className={`btn btn-icon ${!this.state.isFitInContainerSize ? 'btn-circle' : 'btn-primary'} btn-sm mx-2`}
                            ref={this.fitInContainerSize}
                            onClick={() => {
                                if (!this.state.isFitInContainerSize)
                                    this.props.fitInContainerSize()
                                else
                                    this.props.unFitInContainerSize();
                                this.setState({isFitInContainerSize: !this.state.isFitInContainerSize});
                            }
                            }
                            onMouseEnter={() => {
                                this.setState({fitInContainerSizeTooltipShow: true})
                            }}
                            onMouseLeave={() => {
                                this.setState({fitInContainerSizeTooltipShow: false})
                            }}>
                            <i className={`svg-icon ${this.state.isFitInContainerSize ? '' : 'icon-color-primary'} icon-Doc_view_2`}/>
                        </span>

                        {/*<div className="btn-group py-2 px-2 pr-3" id="f7c9cc2a5b602242c78d66f305d7de8d0ca7d531">*/}
                        {/*    <button type="button" className="btn btn-outline-primary font-weight-bold false" text="Вписать" onClick={this.props.fitInContainerSize}>*/}
                        {/*        <i className="svg-icon icon-Doc_view_2"></i>*/}

                        {/*    </button>*/}
                        {/*</div>*/}

                        <Dropdown>
                            <Dropdown.Toggle className="btn btn-circle btn-outline-success dropdown-toggle">
                                <i className="svg-icon icon-Table_edit_1"/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={this.settingsTable} className="dropdown-item">
                                    Настройка таблицы
                                </Dropdown.Item>
                                <Dropdown.Item href="#" onClick={this.toggleRowsStyle} className="dropdown-item">
                                    Переключить отображение строк <br/>
                                    ({this.props.isWrap ? 'перенести строку' : 'скрыть строку'})
                                </Dropdown.Item>
                                {
                                    isVisible &&
                                    <Dropdown.Item href="#" onClick={this.changeDocumentType} className="dropdown-item"
                                                   disabled={!this.props.activeRow}>
                                        Изменить тип документа
                                    </Dropdown.Item>
                                }
                                {/*TODO вынести в настройку сервера синхронизировать с ТЭК*/}
                                {(lFlagWithDepartments && isVisible) &&
                                    <Dropdown.Item href="#" onClick={this.changeDepartments} className="dropdown-item"
                                                   disabled={!this.props.activeRow}>
                                        Изменить подразделения документа
                                    </Dropdown.Item>
                                }
                                {/* TODO SS ? возможность редактирования и просмотра JSON-объектов*/}
                                {
                                    isVisible &&
                                    <Dropdown.Item href="#" onClick={this.newDocumentByVersion} className="dropdown-item"
                                                   disabled={!this.props.activeRow}>
                                        Создать документ из версии
                                    </Dropdown.Item>
                                }

                                {/*TODO: не показывать "Изменить разделы документа" если нет красных и зеленых папок*/}
                                {(lFlagWithSection && isVisible) && isChangeDocGroup !== -1 &&
                                    <Dropdown.Item href="#" onClick={this.changeDocumentSections} className="dropdown-item"
                                                   disabled={!this.props.activeRow || !isSection}
                                    >
                                        Изменить разделы документа
                                    </Dropdown.Item>
                                }
                                {
                                    this.props.adminLevel >= 1 &&
                                    <Dropdown.Item href="#" onClick={this.testPackage} className="dropdown-item"
                                                   disabled={this.props.checkedFields.length === 0 && !this.props.activeRow}
                                                   style={moveDocInPackStyle}
                                    >
                                        Перенести в пакет
                                    </Dropdown.Item>
                                }
                                {this.props.checkedFields.length > 0 &&
                                    <Dropdown.Item href="#" onClick={this.getDocsAsZipArchive} className="dropdown-item"
                                                   disabled={this.props.checkedFields.length === 0} style={exportDocsStyle}
                                    >
                                        Скачать отмеченные документы
                                    </Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        activeNode: state.document.tree.activeNode,
        activeRow: state.document.mainTable.activeRow,
        adminLevel: state.globalState.user.AdminLevel,
        asideMenuIsClose: state.globalState.app.asideMenuIsClose,
        checkedFields: state.document.mainTable.checkedFields,
        globalSettings: state.globalState.settings.Content,
        isSearch: state.document.search.isSearch,
        isWrap: state.globalState.app.isWrap,
        mainTableHeaders: state.document.mainTable.header,
        searchInputs: state.document.search.searchInputs,
        treeRender: state.document.tree.render,
        viewMode: state.document.rootState.viewMode,
        docGroup: state.document.tree.group,
    }
}

const mapDispatchToProps = {
    ActionQueue,
    changeDocumentSearchInputs,
    changeDocumentViewMode,
    changeViewMode,
    changeWrap,
    collectDataForAdditionalSections_Normal,
    combineMainTableAttributes,
    createPreviewBySelectDocument,
    mainTableDownloadDocCollection,
    mainTableSearchDoc,
    movingDocumentsInPackageFromDocuments,
    resetDocumentPreview,
    resetMainTableForUpdate,
    selectActiveRow,
    setLoaderModalData,
    setModalData,
    addModalData,
    setStateLeftAsideMenu,
    treeSelectNode,
}


export default connect(mapStateToProps, mapDispatchToProps)(MainTableSearch);
