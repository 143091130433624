import React from "react";
import {connect} from "react-redux";
import {combineMainTableAttributesSearch} from "../../store/search/actionCreators/search_table";
import {__} from "../../tools/HelpFunctions";
import {store} from "../../index";
import {
    handlerPackagesIsExtSearch,
    handlerIsSearch,
    handlerSearchPackages,
    updateInputsFieldPackage, updateInputsFieldSortingPackage
} from "../../store/packages/actionCreators/package_InputActionCreator";
import {
    getPackageGroupsForTree,
    SelectActivePackageNode
} from "../../store/packages/actionCreators/package_TreeActionCreator";
import {treeSearchPackage} from "../../store/packages/actionCreators/package_TreeSearchActionCreator";
import {
    setLoaderModalData,
    setModalData,
    setUserModalShow
} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {ModalTypes} from "../../tools/StaticTypes";
import {
    changeSortDirection,
    changeViewFilter,
    PackagesPaginationMovePage, setViewModePreviewPackages
} from "../../store/packages/actionCreators/package_MainTableActionCreator";
import {DataTypesEnum} from "../../tools/API_NEW/ServerTypes";
import {changeDocumentViewMode} from "../../store/documents/actionCreators/document_RootStateActionCreator";
import FiltersString from "./filtersByTypes/FiltersString";
import FiltersDate from "./filtersByTypes/FiltersDate";
import Select from "react-select";

const transformStringToTypeOption = (string) => ({value: string, label: string})

class InputSearchPackage extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            checkboxOnHover: false,
            docsFiltersShow: true,
            packagesFiltersShow: true,
            arrowDopDate: false,

            TypeIds: [],
            limitationTooltipShow: false,

            clearHoverDate: false,
            clearHoverDatePack: false,
            clearHoverNumber: false,
            clearHoverName: false,
            clearHoverNamePack: false,
            clearHoverTypeName: false,
            clearHoverModifyDate: false,
            clearHoverModifyDatePack: false,

            currentFilter: null,
            currentPackFilter: null,
            extFilter: false,
            extPackFilter: false,

            typeOptions: [],
        };
        this.limitation = React.createRef();

        this.RegDateAsStringRef = React.createRef();
        this.RegNumberRef = React.createRef();
        this.NameRef = React.createRef();
        this.TypeNameRef = React.createRef();
        this.ModifyDateRef = React.createRef();

        this.RegDatePackAsStringRef = React.createRef();
        this.NamePackRef = React.createRef();
        this.ModifyDatePackRef = React.createRef();

        this.search = this.search.bind(this);
        this.inputHandler = this.inputHandler.bind(this);
        this.toggleDocsFiltersShow = this.toggleDocsFiltersShow.bind(this);
        this.togglePackagesFiltersShow = this.togglePackagesFiltersShow.bind(this);
        this.docTypesSelectOnChange = this.docTypesSelectOnChange.bind(this);
        this.dopDate = this.dopDate.bind(this);
        this.clearInput = this.clearInput.bind(this);

        this.getCurrentSearchInputs = this.getCurrentSearchInputs.bind(this);
        this.sortByParam = this.sortByParam.bind(this)
        this.changeHandler = this.changeHandler.bind(this)
        this.setIsExtSearch = this.setIsExtSearch.bind(this)
    }

    componentDidMount() {
        let packageTypesFieldName;
        if (this.props.tableRole === "main") {
            packageTypesFieldName = 'treeTypes'
        } else if (this.props.tableRole === "additional") {
            packageTypesFieldName = 'additionalTreeTypes'
        } else {
            packageTypesFieldName = 'downloadTreeTypes'
        }
        const packageTypes = this.props[packageTypesFieldName]
        let newTypeOptions = []
        if (packageTypes) {
            newTypeOptions = packageTypes?.map(type => transformStringToTypeOption(type.Name))
        }
        this.setState({typeOptions: newTypeOptions})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let packageTypesFieldName;

        if (this.props.tableRole === "main") {
            packageTypesFieldName = 'treeTypes'
        } else if (this.props.tableRole === "additional") {
            packageTypesFieldName = 'additionalTreeTypes'
        } else {
            packageTypesFieldName = 'downloadTreeTypes'
        }
        if (
            this.props.tableRole !== prevProps.tableRole
            || this.props[packageTypesFieldName] !== prevProps[packageTypesFieldName]
        ) {
            const packageTypes = this.props[packageTypesFieldName]
            let newTypeOptions = []
            if (packageTypes) {
                newTypeOptions = packageTypes?.map(type => transformStringToTypeOption(type.Name))
            }
            this.setState({typeOptions: newTypeOptions})
        }
    }

    getCurrentSearchInputs() {
        let searchInputs;

        if (this.props.tableRole === "main") {
            const {searchInputsMain} = this.props;
            if (searchInputsMain) {
                searchInputs = searchInputsMain
            }
        } else if (this.props.tableRole === "additional") {
            const {searchInputsAdditional} = this.props;
            if (searchInputsAdditional) {
                searchInputs = searchInputsAdditional
            }

        } else if (this.props.tableRole === "download") {
            const {searchInputsDownload} = this.props;
            if (searchInputsDownload) {
                searchInputs = searchInputsDownload
            }
        }
        return searchInputs;
    }

    dopDate() {
        this.setState({
            arrowDopDate: !this.state.arrowDopDate
        });
    }

    toggleDocsFiltersShow() {
        this.setState({
            docsFiltersShow: !this.state.docsFiltersShow
        });
    }

    togglePackagesFiltersShow() {
        this.setState({
            packagesFiltersShow: !this.state.packagesFiltersShow
        });
    }

    areFieldsEmpty(searchInputs) {
        return Object.values(searchInputs?.RegDateAsString).every(elem => !elem)
            && Object.values(searchInputs?.RegNumber).every(elem => !elem)
            && Object.values(searchInputs?.Name).every(elem => !elem)
            && Object.values(searchInputs?.TypeName).every(elem => !elem)
            && Object.values(searchInputs?.ModifyDate).every(elem => !elem)
            && Object.values(searchInputs?.ModifyDatePack).every(elem => !elem)
            && Object.values(searchInputs?.RegDatePackAsString).every(elem => !elem)
            && Object.values(searchInputs?.NamePack).every(elem => !elem)
    }



    inputHandler(event = null, ref = null, item = null) {
        let searchInputs = this.getCurrentSearchInputs()
        let value = event ? event.target.value : ""
        if (item == null) {
            let nameInput = event ? event.target.name : ref.current.name;
            const {RegNumber, Name, NamePack, TypeName, ModifyDate, ModifyDatePack, RegDateAsString, RegDatePackAsString, AppliedParams} = searchInputs
            if (nameInput === "Number") {
                this.props.updateInputsFieldPackage({
                    TableRole: this.props.tableRole,
                    RegNumber: {ApproximatelyEqual: value, BeginningFrom: '', EndingOn: '', Equal: ''},
                    Name,
                    TypeName,
                    ModifyDate,
                    RegDateAsString,
                    RegDatePackAsString,
                    NamePack,
                    ModifyDatePack,
                    AppliedParams,
                });
            } else if (nameInput === "Name") {
                this.props.updateInputsFieldPackage({
                    TableRole: this.props.tableRole,
                    RegNumber,
                    Name: {ApproximatelyEqual: value, BeginningFrom: '', EndingOn: '', Equal: ''},
                    TypeName,
                    ModifyDate,
                    RegDateAsString,
                    RegDatePackAsString,
                    NamePack,
                    ModifyDatePack,
                    AppliedParams,
                });
            } else if (nameInput === "NamePack") {
                this.props.updateInputsFieldPackage({
                    TableRole: this.props.tableRole,
                    RegNumber,
                    NamePack: {ApproximatelyEqual: value, BeginningFrom: '', EndingOn: '', Equal: ''},
                    TypeName,
                    ModifyDate,
                    RegDateAsString,
                    RegDatePackAsString,
                    Name,
                    ModifyDatePack,
                    AppliedParams,
                });
            } else if (nameInput === "TypeName") {
                this.props.updateInputsFieldPackage({
                    TableRole: this.props.tableRole,
                    RegNumber,
                    Name,
                    TypeName: {In: ""},
                    ModifyDate,
                    RegDateAsString,
                    RegDatePackAsString,
                    NamePack,
                    ModifyDatePack,
                    AppliedParams,
                });
            } else if (nameInput === "ModifyDate") {
                this.props.updateInputsFieldPackage({
                    TableRole: this.props.tableRole,
                    RegNumber,
                    Name,
                    TypeName,
                    ModifyDate: {ApproximatelyEqual: value, OnDate: '', DateStart: '', DateEnd: ''},
                    RegDateAsString,
                    RegDatePackAsString,
                    NamePack,
                    ModifyDatePack,
                    AppliedParams,
                });
            } else if (nameInput === "ModifyDatePack") {
                this.props.updateInputsFieldPackage({
                    TableRole: this.props.tableRole,
                    RegNumber,
                    Name,
                    TypeName,
                    ModifyDatePack: {ApproximatelyEqual: value, OnDate: '', DateStart: '', DateEnd: ''},
                    RegDateAsString,
                    RegDatePackAsString,
                    NamePack,
                    ModifyDate,
                    AppliedParams,
                });
            } else if (nameInput === "Date") {
                this.props.updateInputsFieldPackage({
                    TableRole: this.props.tableRole,
                    RegNumber,
                    Name,
                    TypeName,
                    ModifyDate,
                    RegDateAsString: {ApproximatelyEqual: value, OnDate: '', DateStart: '', DateEnd: ''},
                    RegDatePackAsString,
                    NamePack,
                    ModifyDatePack,
                    AppliedParams,
                });
            } else if (nameInput === "DatePack") {
                this.props.updateInputsFieldPackage({
                    TableRole: this.props.tableRole,
                    RegNumber,
                    Name,
                    TypeName,
                    ModifyDate,
                    RegDateAsString,
                    RegDatePackAsString: {ApproximatelyEqual: value, OnDate: '', DateStart: '', DateEnd: ''},
                    NamePack,
                    ModifyDatePack,
                    AppliedParams,
                });
            }
        } else {
            let ActiveAttributes = __.deepCopy(this.state.ActiveAttributes);
            let index = ActiveAttributes.findIndex(attr => attr.uid === item.uid);
            ActiveAttributes[index].inputValue = value;
            this.setState({ActiveAttributes});
        }
    }

    async search({lFlagReset = null, isClose = null, applyButton = false}) {
        const searchInputs = this.getCurrentSearchInputs()
        if (applyButton && this.areFieldsEmpty(searchInputs)) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content: `Нужно заполнить хотя бы один атрибут`}
            });
            return;
        }

        store.dispatch(setLoaderModalData({
            data: {
                content: "Загрузка документов...",
                disableButton: true,
                fullBackground: false,
                gif: "packages",
                key: "inputSearchPackage285"
            },
            Next: {
                action: async () => {
                    if (applyButton && this.props.tableRole !== "main") {
                        this.props.setUserModalShow({
                            isShow: false
                        });
                    }
                    const viewModePreview = store.getState().packages.rootState.viewModePreview
                    if (!(
                        viewModePreview.includes("two_table")
                        || viewModePreview === "right_document_table"
                    )) {
                        this.props.setViewModePreviewPackages({
                            ViewMode: "none",
                        });
                    }

                    if (lFlagReset) {
                        this.props.updateInputsFieldSortingPackage({
                            TableRole: this.props.tableRole,
                            Next: {
                                action: async () => {
                                    await this.props.updateInputsFieldPackage({
                                        TableRole: this.props.tableRole,
                                        Next: {
                                            action: async () => {
                                                this.props.handlerIsSearch({
                                                    TableType: this.props.tableRole,
                                                    Next: {
                                                        action: async () => {
                                                            await this.props.PackagesPaginationMovePage({
                                                                PageNumber: 1,
                                                                TableType: this.props.tableRole,
                                                                Next: {
                                                                    action: () => {
                                                                        __.deleteDocFromLinkUsingActiveNode(this.props.tableRole);
                                                                        this.props.setLoaderModalData({keyDeleted: "InputSearchPackage345"});
                                                                    },
                                                                    params: {}
                                                                }
                                                            });
                                                        },
                                                        params: {}
                                                    }
                                                });

                                            },
                                            params: {}
                                        }
                                    });
                                },
                                params: {}
                            }
                        })
                    } else {
                        this.props.handlerIsSearch({
                            TableType: this.props.tableRole,
                            IsSearch: true,
                            Next: {
                                action: () => {
                                    // TODO КН 26.04.24 Вообще по хорошему избавиться от этого, но тут сетаются appliedParams; Нужно будет вообще их логику либо подделать либо переделать и завести если на то пошло отдельный actionCreator для этого поля
                                    this.props.updateInputsFieldPackage({
                                        TableRole: this.props.tableRole,
                                        //TODO aa 28.03.2023 ! НЕ УБИРАТЬ иначе ломается подсчет количества доступных страниц после фильтра
                                        IsSearch: true,
                                        RegDateAsString: searchInputs.RegDateAsString ?? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                                        RegDatePackAsString: searchInputs.RegDatePackAsString ?? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                                        RegNumber: searchInputs.RegNumber ?? {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                                        Name: searchInputs.Name ?? {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                                        NamePack: searchInputs.NamePack ?? {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                                        TypeName: searchInputs.TypeName ?? {In: ''},
                                        //TODO Задача № 20069 3. Формат введения - дата. В документах данное поле имеет текстовый формат ввода, т.к. используется фильтрация формата 05.2022 для фильтрации документов за май 2022, данная практика используется по аналогии со старым архивом
                                        // ModifyDate:  __.reformatDate(this.state.ModifyDate),
                                        ModifyDate: searchInputs.ModifyDate ?? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                                        ModifyDatePack: searchInputs.ModifyDatePack ?? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                                        AppliedParams: searchInputs.AppliedParams,
                                        Next: {
                                            action: async () => {
                                                let currentSortedParams, FieldSorting, Direction;
                                                if (this.props.tableRole === "main") {
                                                    currentSortedParams = store.getState().packages.tree.SortedParams
                                                } else if (this.props.tableRole === "additional") {
                                                    currentSortedParams = store.getState().packages.treeAdditional.SortedParams
                                                } else if (this.props.tableRole === "download") {
                                                    currentSortedParams = store.getState().packages.treeDownload.SortedParams
                                                }
                                                for (const [key, value] of Object.entries(currentSortedParams)) {
                                                    if (value) {
                                                        FieldSorting = key
                                                        Direction = value
                                                        break;
                                                    }
                                                }
                                                await this.props.PackagesPaginationMovePage({
                                                    PageNumber: 1,
                                                    TableType: this.props.tableRole,
                                                    FieldSorting,
                                                    Direction,
                                                    Next: {
                                                        action: async () => {
                                                            __.deleteDocFromLinkUsingActiveNode(this.props.tableRole);
                                                            if (isClose) {
                                                                this.props.setUserModalShow({
                                                                    isShow: false,
                                                                    Next: {
                                                                        action: () => {
                                                                            if (this.areFieldsEmpty(searchInputs)) {
                                                                                // TODO КН 20.05.2024 все итак хорошо отрабатывает, но мб лучше поместить над PackagesPaginationMovePage, чтобы он не пустые фильтры отправлял, а впринципе их не отправлял
                                                                                this.props.handlerIsSearch({
                                                                                    TableType: this.props.tableRole,
                                                                                    Next: {
                                                                                        action: () => {
                                                                                            this.props.setLoaderModalData({keyDeleted: "InputSearchPackage421"});
                                                                                        },
                                                                                        params: {}
                                                                                    }
                                                                                });
                                                                            } else {
                                                                                this.props.setLoaderModalData({keyDeleted: "InputSearchPackage421"});
                                                                            }
                                                                        },
                                                                        params: {}
                                                                    }
                                                                });
                                                            } else {
                                                                this.props.setLoaderModalData({keyDeleted: "InputSearchPackage421"});
                                                            }
                                                        },
                                                        params: {}
                                                    }
                                                });
                                            },
                                            params: {}
                                        }
                                    })
                                },
                                params: {}
                            }
                        })
                    }
                },
                params: {}
            }
        }));
    }

    docTypesSelectOnChange(item) {
        let tempArr = new Set();
        for (let i = 0; i < item.length; i++) {
            //this.setState({TypeIds : [item[i].value]});
            tempArr.add(item[i].value);

        }
        tempArr.delete(null);
        this.setState({TypeIds: Array.from(tempArr)}, () => {

        });


    }

    async clearInput(event, ref) {
        await this.inputHandler(null, ref, null)
        // TODO КН 24.04.24 Я думаю надо сделать if (isSearch, иначе зачем это делать)
        this.search({})
    }

    setIsExtSearch(ev) {
        const value = ev.target.checked
        this.props.handlerPackagesIsExtSearch({TableType: this.props.tableRole, IsExtSearch: value})
    }

    setExtFilters(ev, item, input) {
        let current;
        let currentPack;
        switch (item) {
            case "Date":
                current = {
                    TableRole: this.props.tableRole,
                    Attribute: "Дата",
                    IdAttributeType: DataTypesEnum.DataTime,
                    value: input
                }
                break;
            case "DatePack":
                currentPack = {
                    TableRole: this.props.tableRole,
                    Attribute: "Дата пакета",
                    IdAttributeType: DataTypesEnum.DataTime,
                    value: input
                }
                break;
            case "Number":
                current = {
                    TableRole: this.props.tableRole,
                    Attribute: "Номер",
                    IdAttributeType: DataTypesEnum.String,
                    value: input
                }
                break
            case "Name":
                current = {
                    TableRole: this.props.tableRole,
                    Attribute: "Наименование",
                    IdAttributeType: DataTypesEnum.String,
                    value: input
                }
                break
            case "NamePack":
                currentPack = {
                    TableRole: this.props.tableRole,
                    Attribute: "Наименование пакета",
                    IdAttributeType: DataTypesEnum.String,
                    value: input
                }
                break;
            case "TypeName":
                current = {
                    TableRole: this.props.tableRole,
                    Attribute: "Тип",
                    IdAttributeType: DataTypesEnum.String,
                    value: input
                }
                break
            case "ModifyDate":
                current = {
                    TableRole: this.props.tableRole,
                    Attribute: "Последнее изменение",
                    IdAttributeType: DataTypesEnum.DataTime,
                    value: input
                }
                break
            case "ModifyDatePack":
                currentPack = {
                    TableRole: this.props.tableRole,
                    Attribute: "Последнее изменение пакета",
                    IdAttributeType: DataTypesEnum.DataTime,
                    value: input
                }
                break
            default:
                break
        }
        this.setState({currentFilter: current, extFilter: current ? true : false, currentPackFilter: currentPack, extPackFilter: currentPack ? true : false});
    }

    getFilterCount(field) {
        let fillingFieldsCount = 0;
        if (!field) return fillingFieldsCount
        if (typeof field === "object") {
            fillingFieldsCount = Object.values(field).filter(Boolean).length;
        }
        return fillingFieldsCount
    }

    getConfigLine(ref, input) {
        let style = {
            color: 'var(--originaltwo)',
            backgroundColor:'var(--elem-bg)',
            borderColor: '#ffffff',
            cursor:"pointer",
        }
        return (
            <div className="d-flex mt-4">
                <div className="col-lg-6">
                    <a href="#"
                       className={`btn btn-outline-secondary font-weight-bold ${ref === "TypeName" && "invisible"}`}
                       style={style}
                       onClick={(ev) => {
                           if (ref !== "TypeName") this.setExtFilters(ev, ref.current.name, input)
                       }}
                    >
                        Настроить
                    </a>
                </div>

                <div className="col-lg-7"
                     style={{
                         display: 'flex',
                         alignItems: 'center',
                         alignContent: 'center',
                         flexWrap: 'wrap',
                         justifyContent: 'center',
                         flexDirection: 'row'
                     }}>
                    <p style={{marginBottom: 0}}>
                        Применено: {this.getFilterCount(input)}
                    </p>
                </div>
            </div>
        )
    }

    async sortByParam({FieldSorting = "", Direction = 1}) {
        this.props.setLoaderModalData({
            data: {
                content: "Загрузка...",
                disableButton: true,
                fullBackground: true,
                gif: "packages",
                key: "ThMainTable6963"
            }
        });

        this.props.updateInputsFieldSortingPackage({TableRole: this.props.tableRole, SortingParam: FieldSorting, Direction})

        this.props.changeSortDirection({dir: Direction, sortField: FieldSorting})

        let searchInputs = this.getCurrentSearchInputs()

        this.props.updateInputsFieldPackage({
            TableRole: this.props.tableRole,
            RegDateAsString: searchInputs.RegDateAsString ?? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
            RegDatePackAsString: searchInputs.RegDatePackAsString ?? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
            RegNumber: searchInputs.RegNumber ?? {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
            Name: searchInputs.Name ?? {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
            NamePack: searchInputs.NamePack ?? {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
            TypeName: searchInputs.TypeName ?? {In: ''},
            ModifyDate: searchInputs.ModifyDate ?? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
            ModifyDatePack: searchInputs.ModifyDatePack ?? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
            AppliedParams: searchInputs.AppliedParams,
            IsSearch:searchInputs.IsSearch,
            Next: {
                action: async () => {
                    await this.props.PackagesPaginationMovePage({
                        PageNumber: 1,
                        TableType: this.props.tableRole,
                        FieldSorting,
                        Direction,
                        Next: {
                            action: async () => {
                                __.deleteDocFromLinkUsingActiveNode(this.props.tableRole);
                                this.props.setLoaderModalData({keyDeleted: "ThMainTablePackage123"});
                            },
                            params: {}
                        }
                    });
                }
            }
        })
    }


    changeHandler(value) {
        const searchInputs = this.getCurrentSearchInputs()
        const {RegNumber, Name, ModifyDate, RegDateAsString, AppliedParams, RegDatePackAsString, ModifyDatePack, NamePack,} = searchInputs

        let typeValue = []
        for (const type of value) {
            typeValue.push(type.value)
        }
        this.props.updateInputsFieldPackage({
            TableRole: this.props.tableRole,
            RegNumber,
            Name,
            TypeName: {In: typeValue.length ? typeValue : ""},
            ModifyDate,
            RegDateAsString,
            RegDatePackAsString,
            ModifyDatePack,
            NamePack,
            AppliedParams,
        })
    }

    render() {
        const {currentFilter} = this.state;
        const {currentPackFilter} = this.state;

        let searchInputs = this.getCurrentSearchInputs()

        let docsFiltersShow = "";
        let docsFiltersShowArrow = "";
        if (this.state.docsFiltersShow) {
            docsFiltersShow = "show"
            docsFiltersShowArrow = "active"
        }
        let packagesFiltersShow = "";
        let packagesFiltersShowArrow = "";
        if (this.state.packagesFiltersShow) {
            packagesFiltersShow = "show"
            packagesFiltersShowArrow = "active"
        }

        let searchIconNumber = "icon-number_search ";
        if (this.state.clearHoverNumber) { // для отображения красного крестика
            searchIconNumber = "icon-Delete icon-color-red";
        }

        let searchIconName = "icon-name_search ";
        if (this.state.clearHoverName) { // для отображения красного крестика
            searchIconName = "icon-Delete icon-color-red";
        }

        let searchIconNamePack = "icon-name_search ";
        if (this.state.clearHoverNamePack) { // для отображения красного крестика
            searchIconNamePack = "icon-Delete icon-color-red";
        }

        let searchIconTypeName = "icon-Search";
        if (this.state.clearHoverTypeName) { // для отображения красного крестика
            searchIconTypeName = "icon-Delete icon-color-red";
        }

        let searchIconModifyDate = "icon-calendar_search";
        if (this.state.clearHoverModifyDate) { // для отображения красного крестика
            searchIconModifyDate = "icon-Delete icon-color-red";
        }

        let searchIconModifyDatePack = "icon-calendar_search";
        if (this.state.clearHoverModifyDatePack) { // для отображения красного крестика
            searchIconModifyDatePack = "icon-Delete icon-color-red";
        }

        let searchIconDate = "icon-calendar_search";
        if (this.state.clearHoverDate) { // для отображения красного крестика
            searchIconDate = "icon-Delete icon-color-red";
        }

        let searchIconDatePack = "icon-calendar_search";
        if (this.state.clearHoverDatePack) { // для отображения красного крестика
            searchIconDatePack = "icon-Delete icon-color-red";
        }

        let lCaptionVisible = true;
        if (this.props.captionUnvisible)
            lCaptionVisible = false;

        let sortedParams, isExtSearch;

        if (this.props.tableRole == "main") {
            const {searchInputsMain, sortedParamsMain, isExtSearchMain} = this.props;

            searchInputs = searchInputsMain
            sortedParams = sortedParamsMain
            isExtSearch = isExtSearchMain

        } else if (this.props.tableRole == "additional") {
            const {searchInputsAdditional, sortedParamsAdditional, isExtSearchAdditional} = this.props;

            searchInputs = searchInputsAdditional
            sortedParams = sortedParamsAdditional
            isExtSearch = isExtSearchAdditional

        } else if (this.props.tableRole == "download") {
            const {searchInputsDownload, sortedParamsDownload, isExtSearchDownload} = this.props;

            searchInputs = searchInputsDownload
            sortedParams = sortedParamsDownload
            isExtSearch = isExtSearchDownload
        }


        const selectStyles = {
            valueContainer: (base) => ({
                ...base,
                padding: "0 1.3rem"
            }),
            singleValue: (base) => ({
                ...base,
                fontSize: "1.08rem",
                color: "#3F4254"
            }),
            container: (base) => ({
                ...base,
                width: "100px",
                flexGrow: 1
            }),
            control: (base) => ({
                ...base,
                border: "1px solid #E4E6EF",
                borderRadius: "0 4px 4px 0"
            }),

            menuList: (base) => ({
                ...base,
                width: "100%",
                "::-webkit-scrollbar": {
                    width: "5px",
                    height: "5px",
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                },

                "::-webkit-scrollbar-thumb": {
                    borderRadius: "10px",
                    /*background-color: rgb(239 137 137 / 20%);*/
                    backgroundColor: "var(--background-colorBS)",
                },

                "::-webkit-scrollbar-track": {
                    borderRadius: "10px",
                    backgroundColor: "rgba(255, 255, 255, 0.35)",
                }
            }),
            menu: (base) => ({
                ...base,
                width: "220px",
            }),
            placeholder: (base) => ({
                ...base,
                color: "#a9a9a9d1",
                fontSize: "1.08rem",
            })
        }

        let currentTypeValueForSelect;
        if (!searchInputs.TypeName.In) {
            currentTypeValueForSelect = null
        } else {
            currentTypeValueForSelect = searchInputs.TypeName.In.map(typeName=>transformStringToTypeOption(typeName))
        }

        return (
            <div>
                {
                    lCaptionVisible &&
                    <div className="d-flex">
                        <div className="p-2 p-lg-2 my-lg-2">
                            <h2 className="text-color-primary font-weight-bolder">Пакеты</h2>
                        </div>
                    </div>
                }
                <div className="row">
                    <label className="col-9 col-form-label text-color-primary">
                        Расширенные фильтры
                    </label>
                    <div className="col-3 d-flex align-items-center">
                        <span className="switch switch-sm">
                            <label>
                                <input type="checkbox"
                                       name="extSearch"
                                       checked={isExtSearch}
                                       onChange={this.setIsExtSearch}
                                />
                                <span/>
                            </label>
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className={!lCaptionVisible ? 'col-lg-9' : 'col-lg-12'}
                         onClick={() => this.search({
                             applyButton: true,
                             isClose: true
                         })}>
                            <span className="btn btn-primary btn-lg btn-block my-3">
                                Применить
                            </span>
                    </div>
                    {
                        !lCaptionVisible &&
                        <div className="col-lg-3">
                            <span
                                className="btn btn-outline-primary btn-icon btn-lg btn-block my-3"
                                title={"Очистить текущий фильтр"}
                                onClick={() => this.search({lFlagReset: true})}
                            >
                                <i id="disabled"
                                   className="svg-icon icon-Table_edit_8 fsic4-i"/>
                            </span>
                        </div>
                    }
                </div>
                <div className="accordion accordion-light accordion-toggle-arrow">
                    <div className="card">
                        <div className="card-header">
                            <div className={"card-title " + docsFiltersShowArrow} onClick={this.toggleDocsFiltersShow}>
                                Фильтры по документам текущего пакета
                            </div>
                        </div>

                        <div className="py-2 pr-4"
                             style={{overflow: "visible", marginRight: "-5px"}}>
                            <div>
                                <div className={"collapse " + docsFiltersShow}>

                                    {
                                        !this.state.extFilter ?
                                            <div className="card-body">
                                                <div>
                                                    <div className="input-group my-2">
                                                        <div className="input-group-prepend">
                                                            <span
                                                                className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                                                onMouseEnter={() => {
                                                                    this.setState({clearHoverDate: true})
                                                                }}
                                                                onMouseLeave={() => {
                                                                    this.setState({clearHoverDate: false})
                                                                }}
                                                                onClick={(event) => this.clearInput(event, this.RegDateAsStringRef)}>
                                                                <i className={`svg-icon svg-icon-lg icon-color-primary ${searchIconDate}`}
                                                                   id="Date"/>
                                                            </span>
                                                        </div>

                                                        <input type="text"
                                                               className="form-control form-control-lg h-40px br-right-5"
                                                               placeholder="Дата"
                                                               ref={this.RegDateAsStringRef}
                                                               value={searchInputs.RegDateAsString?.ApproximatelyEqual ?? ""}
                                                               onChange={(event) => {
                                                                   this.inputHandler(event)
                                                               }}
                                                               onKeyDown={(event) => {
                                                                   if (event.code === "Enter" || event.code === "NumpadEnter") {
                                                                       this.search({})
                                                                   }
                                                               }}
                                                               name="Date"
                                                        />
                                                        <div className="input-group-prepend">
                                                            <span onClick={(event) => {
                                                                this.sortByParam({FieldSorting: "RegDate", Direction: 2})
                                                            }}
                                                                  className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                                  role="button"
                                                            >
                                                                <i className={`svg-icon svg-icon-lg icon-Filter_up ${sortedParams.RegDate === 2 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                            </span>
                                                            <span onClick={(event) => {
                                                                this.sortByParam({FieldSorting: "RegDate", Direction: 1})
                                                            }}
                                                                  className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                                  role="button"
                                                            >
                                                                <i className={`svg-icon svg-icon-lg icon-Filter_down ${sortedParams.RegDate === 1 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                            </span>
                                                        </div>
                                                        {
                                                            isExtSearch && this.getConfigLine(this.RegDateAsStringRef, searchInputs.RegDateAsString)
                                                        }
                                                    </div>
                                                </div>
                                                <div className="input-group my-4">
                                                    <div className="input-group-prepend">
                                                        <span
                                                            className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                                            onMouseEnter={() => {
                                                                this.setState({clearHoverNumber: true})
                                                            }}
                                                            onMouseLeave={() => {
                                                                this.setState({clearHoverNumber: false})
                                                            }}
                                                            onClick={(event) => this.clearInput(event, this.RegNumberRef)}>
                                                            <i className={"svg-icon svg-icon-lg icon-color-primary " + searchIconNumber}
                                                               id="Number"/>
                                                        </span>
                                                    </div>
                                                    <input type="text"
                                                           className="form-control form-control-lg h-40px br-right-5"
                                                           placeholder="Номер"
                                                           ref={this.RegNumberRef}
                                                           onChange={(event) => {
                                                               this.inputHandler(event)
                                                           }}
                                                           onKeyDown={(event) => {
                                                               if (event.code === "Enter" || event.code === "NumpadEnter") {
                                                                   this.search({})
                                                               }
                                                           }}
                                                           value={searchInputs.RegNumber?.ApproximatelyEqual ?? ""}
                                                           name="Number"
                                                    />
                                                    <div className="input-group-prepend">
                                                        <span onClick={(event) => {
                                                            this.sortByParam({FieldSorting: "RegNumber", Direction: 2})
                                                        }}
                                                              className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                              role="button"
                                                        >
                                                            <i className={`svg-icon svg-icon-lg icon-Filter_up ${sortedParams.RegNumber === 2 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                        </span>
                                                        <span onClick={(event) => {
                                                            this.sortByParam({FieldSorting: "RegNumber", Direction: 1})
                                                        }}
                                                              className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                              role="button"
                                                        >
                                                            <i className={`svg-icon svg-icon-lg icon-Filter_down ${sortedParams.RegNumber === 1 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                        </span>
                                                    </div>
                                                    {
                                                        isExtSearch && this.getConfigLine(this.RegNumberRef, searchInputs.RegNumber)
                                                    }
                                                </div>
                                                <div className="input-group my-4">
                                                    <div className="input-group-prepend">
                                                        <span
                                                            className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                                            onMouseEnter={() => {
                                                                this.setState({clearHoverName: true})
                                                            }}
                                                            onMouseLeave={() => {
                                                                this.setState({clearHoverName: false})
                                                            }}
                                                            onClick={(event) => this.clearInput(event, this.NameRef)}>
                                                            <i className={"svg-icon svg-icon-lg icon-color-primary " + searchIconName}
                                                               id="Name"/>
                                                        </span>
                                                    </div>
                                                    <input type="text"
                                                           className="form-control form-control-lg h-40px br-right-5"
                                                           placeholder="Наименование"
                                                           ref={this.NameRef}
                                                           onChange={(event) => {
                                                               this.inputHandler(event)
                                                           }}
                                                           onKeyDown={(event) => {
                                                               if (event.code === "Enter" || event.code === "NumpadEnter") {
                                                                   this.search({})
                                                               }
                                                           }}
                                                           value={searchInputs.Name?.ApproximatelyEqual ?? ""}
                                                           name="Name"
                                                    />
                                                    <div className="input-group-prepend">
                                                        <span onClick={(event) => {
                                                            this.sortByParam({FieldSorting: "Name", Direction: 2})
                                                        }}
                                                              className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                              role="button"
                                                        >
                                                            <i className={`svg-icon svg-icon-lg icon-Filter_up ${sortedParams.Name === 2 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                        </span>
                                                        <span onClick={(event) => {
                                                            this.sortByParam({FieldSorting: "Name", Direction: 1})
                                                        }}
                                                              className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                              role="button"
                                                        >
                                                            <i className={`svg-icon svg-icon-lg icon-Filter_down ${sortedParams.Name === 1 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                        </span>
                                                    </div>
                                                    {
                                                        isExtSearch && this.getConfigLine(this.NameRef, searchInputs.Name)
                                                    }
                                                </div>
                                                <div className="input-group my-4" >
                                                    <div className="input-group-prepend">
                                                        <span
                                                            className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                                            onMouseEnter={() => {
                                                                this.setState({clearHoverTypeName: true})
                                                            }}
                                                            onMouseLeave={() => {
                                                                this.setState({clearHoverTypeName: false})
                                                            }}
                                                            onClick={() => {
                                                                let searchInputs = this.getCurrentSearchInputs()
                                                                const {RegNumber, Name, ModifyDate, RegDateAsString, AppliedParams} = searchInputs
                                                                this.props.updateInputsFieldPackage({
                                                                    TableRole: this.props.tableRole,
                                                                    RegNumber,
                                                                    Name,
                                                                    TypeName: {In: ""},
                                                                    ModifyDate,
                                                                    RegDateAsString,
                                                                    AppliedParams,
                                                                });
                                                                this.search({})
                                                            }}>
                                                            <i className={`svg-icon svg-icon-lg icon-color-primary ${searchIconTypeName}`}
                                                               id="TypeName"/>
                                                        </span>
                                                    </div>
                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        styles={selectStyles}
                                                        value={currentTypeValueForSelect}
                                                        options={this.state.typeOptions}
                                                        isDisabled={this.state.typeOptions.length === 0}
                                                        isMulti
                                                        placeholder={"Тип"}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => "Отсутствуют типы для фильтрации"}
                                                        onChange={(value) => {this.changeHandler(value)}}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                text: 'var(--hover-primary)',
                                                                primary25: 'var(--light-primary)',
                                                                primary50: 'var(--hover-primary)',
                                                                primary: 'var(--originaltwo)',
                                                            },
                                                        })}
                                                    />
                                                    <div className="input-group-prepend">
                                                        <span onClick={(event) => {
                                                            this.sortByParam({FieldSorting: "TypeName", Direction: 2})
                                                        }}
                                                              className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                              role="button"
                                                        >
                                                            <i className={`svg-icon svg-icon-lg icon-Filter_up ${sortedParams.TypeName === 2 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                        </span>
                                                        <span onClick={(event) => {
                                                            this.sortByParam({FieldSorting: "TypeName", Direction: 1})
                                                        }}
                                                              className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                              role="button"
                                                        >
                                                            <i className={`svg-icon svg-icon-lg icon-Filter_down ${sortedParams.TypeName === 1 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                        </span>
                                                    </div>
                                                    {
                                                        isExtSearch && this.getConfigLine("TypeName", searchInputs.TypeName)
                                                    }
                                                </div>
                                                <div className="input-group my-2">
                                                    <div className="input-group-prepend">
                                                        <span
                                                            className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                                            onMouseEnter={() => {
                                                                this.setState({clearHoverModifyDate: true})
                                                            }}
                                                            onMouseLeave={() => {
                                                                this.setState({clearHoverModifyDate: false})
                                                            }}
                                                            onClick={(event) => this.clearInput(event, this.ModifyDateRef)}>
                                                            <i className={`svg-icon svg-icon-lg icon-color-primary ${searchIconModifyDate}`}
                                                               id="ModifyDate"/>
                                                        </span>
                                                    </div>

                                                    <input type="text"
                                                           ref={this.ModifyDateRef}
                                                           className="form-control form-control-lg h-40px br-right-5"
                                                           placeholder="Последнее изменение"
                                                           value={searchInputs.ModifyDate?.ApproximatelyEqual ?? ""}
                                                           onChange={(event) => {
                                                               this.inputHandler(event)
                                                           }}
                                                           onKeyDown={(event) => {
                                                               if (event.code === "Enter" || event.code === "NumpadEnter") {
                                                                   this.search({})
                                                               }
                                                           }}
                                                           name="ModifyDate"
                                                    />
                                                    <div className="input-group-prepend">
                                                    <span onClick={(event) => {
                                                        this.sortByParam({FieldSorting: "ModifyDate", Direction: 2})
                                                    }}
                                                          className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                          role="button"
                                                    >
                                                        <i className={`svg-icon svg-icon-lg icon-Filter_up ${sortedParams.ModifyDate === 2 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                    </span>
                                                        <span onClick={(event) => {
                                                            this.sortByParam({FieldSorting: "ModifyDate", Direction: 1})
                                                        }}
                                                              className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                              role="button"
                                                        >
                                                        <i className={`svg-icon svg-icon-lg icon-Filter_down ${sortedParams.ModifyDate === 1 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                    </span>
                                                    </div>
                                                    {
                                                        isExtSearch &&
                                                        this.getConfigLine(this.ModifyDateRef, searchInputs.ModifyDate)
                                                    }
                                                </div>
                                            </div>
                                            :
                                            currentFilter &&
                                            <>
                                                {currentFilter.IdAttributeType === DataTypesEnum.String &&
                                                    <FiltersString
                                                        onApply={() => this.setState({extFilter: false})}
                                                        filterInfo={currentFilter} packageSearch/>}
                                                {currentFilter.IdAttributeType === DataTypesEnum.DataTime &&
                                                    <FiltersDate
                                                        onApply={() => this.setState({extFilter: false})}
                                                        filterInfo={currentFilter} packageSearch/>}
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <div className={"card-title " + packagesFiltersShowArrow} onClick={this.togglePackagesFiltersShow}>
                                Фильтры по пакетам текущего пакета
                            </div>
                        </div>

                        <div className="beautiful-scroll-5 py-2 pr-4"
                             style={{maxHeight: "75vh", overflowX: "hidden", marginRight: "-5px"}}>
                            <div>
                                <div className={"collapse " + packagesFiltersShow}>
                                    {
                                        !this.state.extPackFilter ?
                                            <div className="card-body">
                                                <div>
                                                    <div className="input-group my-2">
                                                        <div className="input-group-prepend">
                                                            <span
                                                                className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                                                onMouseEnter={() => {
                                                                    this.setState({clearHoverDatePack: true})
                                                                }}
                                                                onMouseLeave={() => {
                                                                    this.setState({clearHoverDatePack: false})
                                                                }}
                                                                onClick={(event) => this.clearInput(event, this.RegDatePackAsStringRef)}>
                                                                <i className={`svg-icon svg-icon-lg icon-color-primary ${searchIconDatePack}`}
                                                                   id="DatePack"/>
                                                            </span>
                                                        </div>

                                                        <input type="text"
                                                               className="form-control form-control-lg h-40px br-right-5"
                                                               placeholder="Дата"
                                                               ref={this.RegDatePackAsStringRef}
                                                               value={searchInputs.RegDatePackAsString?.ApproximatelyEqual ?? ""}
                                                               onChange={(event) => {
                                                                   this.inputHandler(event)
                                                               }}
                                                               onKeyDown={(event) => {
                                                                   if (event.code === "Enter" || event.code === "NumpadEnter") {
                                                                       this.search({})
                                                                   }
                                                               }}
                                                               name="DatePack"
                                                        />
                                                        <div className="input-group-prepend">
                                                            <span onClick={(event) => {
                                                                this.sortByParam({FieldSorting: "RegDate", Direction: 2})
                                                            }}
                                                                  className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                                  role="button"
                                                            >
                                                                <i className={`svg-icon svg-icon-lg icon-Filter_up ${sortedParams.RegDate === 2 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                            </span>
                                                            <span onClick={(event) => {
                                                                this.sortByParam({FieldSorting: "RegDate", Direction: 1})
                                                            }}
                                                                  className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                                  role="button"
                                                            >
                                                                <i className={`svg-icon svg-icon-lg icon-Filter_down ${sortedParams.RegDate === 1 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                            </span>
                                                        </div>
                                                        {
                                                            isExtSearch && this.getConfigLine(this.RegDatePackAsStringRef, searchInputs.RegDatePackAsString)
                                                        }
                                                    </div>
                                                </div>
                                                <div className="input-group my-4">
                                                    <div className="input-group-prepend">
                                                        <span
                                                            className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                                            onMouseEnter={() => {
                                                                this.setState({clearHoverNamePack: true})
                                                            }}
                                                            onMouseLeave={() => {
                                                                this.setState({clearHoverNamePack: false})
                                                            }}
                                                            onClick={(event) => this.clearInput(event, this.NamePackRef)}>
                                                            <i className={"svg-icon svg-icon-lg icon-color-primary " + searchIconNamePack}
                                                               id="Name"/>
                                                        </span>
                                                    </div>
                                                    <input type="text"
                                                           className="form-control form-control-lg h-40px br-right-5"
                                                           placeholder="Наименование"
                                                           ref={this.NamePackRef}
                                                           onChange={(event) => {
                                                               this.inputHandler(event)
                                                           }}
                                                           onKeyDown={(event) => {
                                                               if (event.code === "Enter" || event.code === "NumpadEnter") {
                                                                   this.search({})
                                                               }
                                                           }}
                                                           value={searchInputs.NamePack?.ApproximatelyEqual ?? ""}
                                                           name="NamePack"
                                                    />
                                                    <div className="input-group-prepend">
                                                        <span onClick={(event) => {
                                                            this.sortByParam({FieldSorting: "Name", Direction: 2})
                                                        }}
                                                              className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                              role="button"
                                                        >
                                                            <i className={`svg-icon svg-icon-lg icon-Filter_up ${sortedParams.Name === 2 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                        </span>
                                                        <span onClick={(event) => {
                                                            this.sortByParam({FieldSorting: "Name", Direction: 1})
                                                        }}
                                                              className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                              role="button"
                                                        >
                                                            <i className={`svg-icon svg-icon-lg icon-Filter_down ${sortedParams.Name === 1 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                        </span>
                                                    </div>
                                                    {
                                                        isExtSearch && this.getConfigLine(this.NamePackRef, searchInputs.NamePack)
                                                    }
                                                </div>
                                                <div className="input-group my-2">
                                                    <div className="input-group-prepend">
                                                        <span
                                                            className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                                            onMouseEnter={() => {
                                                                this.setState({clearHoverModifyDatePack: true})
                                                            }}
                                                            onMouseLeave={() => {
                                                                this.setState({clearHoverModifyDatePack: false})
                                                            }}
                                                            onClick={(event) => this.clearInput(event, this.ModifyDatePackRef)}>
                                                            <i className={`svg-icon svg-icon-lg icon-color-primary ${searchIconModifyDatePack}`}
                                                               id="ModifyDatePack"/>
                                                        </span>
                                                    </div>

                                                    <input type="text"
                                                           ref={this.ModifyDatePackRef}
                                                           className="form-control form-control-lg h-40px br-right-5"
                                                           placeholder="Последнее изменение"
                                                           value={searchInputs.ModifyDatePack?.ApproximatelyEqual ?? ""}
                                                           onChange={(event) => {
                                                               this.inputHandler(event)
                                                           }}
                                                           onKeyDown={(event) => {
                                                               if (event.code === "Enter" || event.code === "NumpadEnter") {
                                                                   this.search({})
                                                               }
                                                           }}
                                                           name="ModifyDatePack"
                                                    />
                                                    <div className="input-group-prepend">
                                                    <span onClick={(event) => {
                                                        this.sortByParam({FieldSorting: "ModifyDate", Direction: 2})
                                                    }}
                                                          className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                          role="button"
                                                    >
                                                        <i className={`svg-icon svg-icon-lg icon-Filter_up ${sortedParams.ModifyDate === 2 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                    </span>
                                                        <span onClick={(event) => {
                                                            this.sortByParam({FieldSorting: "ModifyDate", Direction: 1})
                                                        }}
                                                              className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                              role="button"
                                                        >
                                                        <i className={`svg-icon svg-icon-lg icon-Filter_down ${sortedParams.ModifyDate === 1 ? "icon-color-primary" : "icon-color-gray"}`}/>
                                                    </span>
                                                    </div>
                                                    {
                                                        isExtSearch &&
                                                        this.getConfigLine(this.ModifyDatePackRef, searchInputs.ModifyDatePack)
                                                    }
                                                </div>
                                            </div>
                                            :
                                            currentPackFilter &&
                                            <>
                                                {currentPackFilter.IdAttributeType === DataTypesEnum.String &&
                                                    <FiltersString
                                                        onApply={() => this.setState({extPackFilter: false})}
                                                        filterInfo={currentPackFilter} packageSearch/>}
                                                {currentPackFilter.IdAttributeType === DataTypesEnum.DataTime &&
                                                    <FiltersDate
                                                        onApply={() => this.setState({extPackFilter: false})}
                                                        filterInfo={currentPackFilter} packageSearch/>}
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeNode: state.packages.tree.activeNode,
        activeNodeAdditional: state.packages.treeAdditional.activeNode,
        activeNodeDownload: state.packages.treeDownload.activeNode,

        changeViewModeSearch: state.document.rootState.viewModeSearch,

        treeTypes: state.packages.tree.lastLoad.types,
        treeActiveNodeId: state.packages.tree.activeNode?.Node.Id,
        additionalTreeTypes: state.packages.treeAdditional.lastLoad.types,
        additionalTreeActiveNodeId: state.packages.treeAdditional.activeNode?.Node.Id,
        downloadTreeTypes: state.packages.treeDownload.lastLoad.types,
        downloadTreeActiveNodeId: state.packages.treeDownload.activeNode?.Node.Id,

        searchInputsMain: state.packages.tree.searchInputs,
        searchInputsAdditional: state.packages.treeAdditional.searchInputs,
        searchInputsDownload: state.packages.treeDownload.searchInputs,

        sortedParamsMain: state.packages.tree.SortedParams,
        sortedParamsAdditional: state.packages.treeAdditional.SortedParams,
        sortedParamsDownload: state.packages.treeDownload.SortedParams,

        isExtSearchMain: state.packages.tree.isExtSearch,
        isExtSearchAdditional: state.packages.treeAdditional.isExtSearch,
        isExtSearchDownload: state.packages.treeDownload.isExtSearch,
    }
}

const mapDispatchToProps = {
    changeViewFilter,
    changeDocumentViewMode,
    changeSortDirection,
    combineMainTableAttributesSearch,
    handlerSearchPackages,
    handlerIsSearch,
    PackagesPaginationMovePage,
    getPackageGroupsForTree,
    SelectActivePackageNode,
    setModalData,
    setLoaderModalData,
    setUserModalShow,
    setViewModePreviewPackages,
    treeSearchPackage,
    updateInputsFieldPackage,
    updateInputsFieldSortingPackage,
    handlerPackagesIsExtSearch
}

export default connect(mapStateToProps, mapDispatchToProps)(InputSearchPackage);
