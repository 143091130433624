import React from "react";
import {connect} from "react-redux";
import AttributeTableCellByInputType, {DataTypesList} from "./AttributeTableCellByInputType";

class AttributeTableBody extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };

    }



    render() {
        const {rows, attribute, IsRedactorMode, deleteRow, headers} = this.props;
        if(!rows) return null;

        return (
            <tbody>
            {rows.length === 0 && <tr style={{position: "relative"}}><td style={{position: "absolute", top: "50%", border: 0,  padding: "1em", textAlign: "center"}}>Нет данных для отображения</td></tr>}
            {
                rows.length > 0 && rows.map((row, rowIndex) => {
                    return (
                        <tr key={row.id}>
                            {rows.length > 0 && IsRedactorMode && <td style={{width: '50px'}}>
                                <span onClick={() => deleteRow(row.id)}><i className="svg-icon svg-icon-sm icon-Delete icon-color-red"/></span>
                            </td>}
                            {
                                row.cells?.map((item, index) => {
                                    const headerCurrent = headers.find(header => header.id === item.idHeader);
                                    if(!headerCurrent.isShow && !IsRedactorMode) return null;
                                    if(IsRedactorMode) {
                                        return (
                                            <AttributeTableCellByInputType key={item.idHeader} rowsCount={rows.length} cell={item} rowId={row.id} attribute={attribute} editHandler={(value) => this.props.editHandler(value, item, row.id)} openNestedTable={() => this.props.openTable(item.headers, item.rows)}  />
                                        )
                                    } else if(item.type === DataTypesList.JSON) {
                                        return (
                                            <td key={item.idHeader}>
                                                <span onClick={() => this.props.openTable(item.headers, item.rows)}>
                                                    <i className="svg-icon svg-icon-sm icon-more"/>
                                                </span>
                                            </td>
                                        )

                                    } else if(item.type === "checkbox") {
                                        return (
                                            <td key={item.idHeader}>
                                                <input type={item.type} checked={item.value} disabled/>
                                            </td>
                                        )
                                    } else {
                                        return (
                                            <td key={item.idHeader}>
                                                <div className={'d-flex align-items-center justify-content-center'}>
                                                    <span className="overflow-hidden text-overflow-ellipsis" style={{
                                                        display: "block",
                                                        textWrap: 'nowrap',
                                                    }}>
                                                        {item.renderValue}
                                                    </span>
                                                    {item.renderValue.length > 90 && <span onClick={() => this.props.showLongRenderValue(item.renderValue)}>
                                                            <i className="svg-icon svg-icon-sm icon-more"/>
                                                         </span>}
                                                </div>
                                            </td>
                                        )
                                    }
                                })
                            }
                        </tr>
                    )
                })
            }

            </tbody>
        );

    }
}


const  mapStateToProps = state => {
    return {
    }
}

const  mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(AttributeTableBody);
