import React from "react";
import {connect} from "react-redux";
import {TableFilterHasValueEnum} from "../../../tools/StaticTypes";
import {changeDocumentSearchInputs} from "../../../store/documents/actionCreators/document_SearchActionCreator";
import {changeDocumentViewMode} from "../../../store/documents/actionCreators/document_RootStateActionCreator";
import {
    combineMainTableAttributes,
    mainTableSearchDoc
} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {ActionQueue} from "../../../store/rootReducer";
import {
    changeDocumentSearchInputs_packagesDocumentRight
} from "../../../store/packages/actionCreators/package_DocumentRightActionCreator";
import {updateInputsFieldPackage} from "../../../store/packages/actionCreators/package_InputActionCreator";

// type StateType = {
//     HasValue: number;
//     ApproximatelyEqual: string;
//     BeginningFrom: string;
//     EndingOn: string;
//     Equal: string;
// }
//
// type OwnPropsType = {
//     rootLink: RootLinkEnum
// }
//
// type PropsFromRedux = ConnectedProps<typeof connector>;
// type PropsType = OwnPropsType & PropsFromRedux;

class FiltersString extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            HasValue: TableFilterHasValueEnum.None,
            ApproximatelyEqual: "",
            BeginningFrom: "",
            EndingOn: "",
            Equal: "",

            onClearBeginningFromHover: false,
            onClearEndingOnHover: false,
            onClearApproximatelyEqualHover: false,
            onClearEqualHover: false
        };

        this.CheckConflictedFields = this.CheckConflictedFields.bind(this);
        this.ApplyFilter = this.ApplyFilter.bind(this);
    }

    componentDidMount() {
        const Value = this.props.filterInfo?.Value ?? this.props.filterInfo?.value
        if(Value) {
            this.setState({
                ApproximatelyEqual: Value.ApproximatelyEqual ? Value.ApproximatelyEqual : "",
                BeginningFrom: Value.BeginningFrom ?? "",
                EndingOn: Value.EndingOn ?? "",
                Equal: Value.Equal ?? ""
            });
        }
    }

    getCurrentSearchInputs() {
        let searchInputs;
        if (this.props.filterInfo.TableRole === "main") {
            const {searchInputsMain} = this.props;
            if (searchInputsMain) {
                searchInputs = searchInputsMain
            }
        } else if (this.props.filterInfo.TableRole === "additional") {
            const {searchInputsAdditional} = this.props;
            if (searchInputsAdditional) {
                searchInputs = searchInputsAdditional
            }

        } else if (this.props.filterInfo.TableRole === "download") {
            const {searchInputsDownload} = this.props;
            if (searchInputsDownload) {
                searchInputs = searchInputsDownload
            }

        }
        return searchInputs;
    }

    CheckConflictedFields(inputName) {
        if(inputName === "Equal") {
            if(this.state.Equal !== "" || this.state.Equal.length > 0) {
                if(this.state.ApproximatelyEqual) {
                    this.setState({ApproximatelyEqual: ""});
                }
                if(this.state.BeginningFrom) {
                    this.setState({BeginningFrom: ""});
                }
                if(this.state.EndingOn) {
                    this.setState({EndingOn: ""});
                }
            }
        }
        else {
            if(this.state.Equal !== "" || this.state.Equal.length > 0) {
                if(this.state.ApproximatelyEqual) {
                    this.setState({Equal: ""});
                }
                if(this.state.BeginningFrom) {
                    this.setState({Equal: ""});
                }
                if(this.state.EndingOn) {
                    this.setState({Equal: ""});
                }
            }
        }
    }

    async ApplyFilter(isClear = false) {
        const filterData = {
            ApproximatelyEqual: isClear ? "" : this.state.ApproximatelyEqual,
            BeginningFrom: isClear ? "" : this.state.BeginningFrom,
            EndingOn: isClear ? "" : this.state.EndingOn,
            Equal: isClear ? "" : this.state.Equal,
        }
        if (this.props.documentSearchRight) {
            this.props.changeDocumentSearchInputs_packagesDocumentRight({
                ...this.props.filterInfo,
                Value : filterData
            });
        } else if (this.props.packageSearch) {
            if (this.props.filterInfo.Attribute === "Наименование") {
            this.props.updateInputsFieldPackage({
                ...this.getCurrentSearchInputs(),
                TableRole: this.props.filterInfo.TableRole,
                Name : filterData
            });
            } else if (this.props.filterInfo.Attribute === "Тип"){
                this.props.updateInputsFieldPackage({
                    ...this.getCurrentSearchInputs(),
                    TableRole: this.props.filterInfo.TableRole,
                    TypeName : filterData
                });
            } else if (this.props.filterInfo.Attribute === "Номер"){
                this.props.updateInputsFieldPackage({
                    ...this.getCurrentSearchInputs(),
                    TableRole: this.props.filterInfo.TableRole,
                    RegNumber : filterData
                });
            }
        } else {
            this.props.changeDocumentSearchInputs({
                ...this.props.filterInfo,
                Value : filterData
            });
        }
        if(isClear) {
            this.setState({
                ApproximatelyEqual: "",
                BeginningFrom: "",
                EndingOn: "",
                Equal: ""
            });
            return
        }

        if (this.props.onApply) this.props.onApply()
    }

    render() {
        const { filterInfo } = this.props;
        const filterName = typeof filterInfo.Attribute === "string"
            ? filterInfo.Attribute
            : filterInfo.Attribute.AttributeName.Name

        return (
            <>
                <h5 className="text-dark my-3">Фильтр (<b>{filterName}</b>)</h5>

                <div className="form-group row" style={{marginBottom: '1rem'}}>
                        <p className="col-lg-12" style={{marginBottom: 0, color: '#e1e1e1'}}>Содержит</p>
                        <div className="col-lg-12 input-group h-40px my-4">
                            <div className="input-group-prepend">
                                <span className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                      style={{borderRadius: '0.42rem 0rem 0rem 0.42rem'}}
                                      onMouseEnter={() => {this.setState({ onClearApproximatelyEqualHover : true})}}
                                      onMouseLeave={() => {this.setState({ onClearApproximatelyEqualHover : false})}}
                                      onClick={() => {if(this.state.onClearApproximatelyEqualHover) { this.setState({ApproximatelyEqual: ""}) }}}>
                                <i className={`svg-icon svg-icon-lg ${this.state.onClearApproximatelyEqualHover ? "icon-Delete icon-color-red" : "icon-color-primary icon-like_16"}`}/>
                            </span>
                            </div>
                            <input type="text" className="form-control form-control-lg h-40px br-right-5"
                                   style={{borderRadius: '0rem 0.42rem 0.42rem 0rem'}}
                                   onKeyDown={(event) => {
                                       if(event.code === "Enter" || event.code === "NumpadEnter") {
                                           this.ApplyFilter();
                                       }
                                   }}
                                   name={"ApproximatelyEqual"}
                                   onChange={(event)=>{
                                       this.setState({ApproximatelyEqual: event.target.value}, () => {
                                           this.CheckConflictedFields("ApproximatelyEqual");
                                       });
                                   }}
                                   placeholder="Задайте значение" value={this.state.ApproximatelyEqual}
                            />
                        </div>
                    </div>

                <div className="form-group row" style={{marginBottom: '1rem'}}>
                    <p className="col-lg-12" style={{marginBottom: 0, color: '#e1e1e1'}}>Начинается с...</p>
                    <div className="col-lg-12 input-group h-40px my-4">
                        <div className="input-group-prepend">
                            <span className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                  style={{borderRadius: '0.42rem 0rem 0rem 0.42rem'}}
                                  onMouseEnter={() => {this.setState({ onClearBeginningFromHover : true})}}
                                  onMouseLeave={() => {this.setState({ onClearBeginningFromHover : false})}}
                                  onClick={() => {if(this.state.onClearBeginningFromHover) { this.setState({BeginningFrom: ""}) }}}>
                            <i className={`svg-icon svg-icon-lg ${this.state.onClearBeginningFromHover ? "icon-Delete icon-color-red" : "icon-color-primary icon-endsWith_16-1"}`}/>
                        </span>
                        </div>
                        <input type="text" className="form-control form-control-lg h-40px br-right-5"
                               style={{borderRadius: '0rem 0.42rem 0.42rem 0rem'}}
                               name={"BeginningFrom"}
                               onKeyDown={(event) => {
                                   if(event.code === "Enter" || event.code === "NumpadEnter") {
                                       this.ApplyFilter();
                                   }
                               }}
                               onChange={(event)=>{
                                   this.setState({BeginningFrom: event.target.value}, () => {
                                       this.CheckConflictedFields("BeginningFrom");
                                       // this.onInputHandler();
                                   });
                               }}
                               placeholder="Задайте значение" value={this.state.BeginningFrom}
                        />
                    </div>
                </div>

                <div className="form-group row" style={{marginBottom: '1rem'}}>
                    <p className="col-lg-12" style={{marginBottom: 0, color: '#e1e1e1'}}>Заканчивается на...</p>
                    <div className="col-lg-12 input-group h-40px my-4">
                        <div className="input-group-prepend">
                            <span className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                  style={{borderRadius: '0.42rem 0rem 0rem 0.42rem'}}
                                  onMouseEnter={() => {this.setState({ onClearEndingOnHover : true})}}
                                  onMouseLeave={() => {this.setState({ onClearEndingOnHover : false})}}
                                  onClick={() => {if(this.state.onClearEndingOnHover) { this.setState({EndingOn: ""}) }}}>
                                <i className={`svg-icon svg-icon-lg ${this.state.onClearEndingOnHover ? "icon-Delete icon-color-red" : "icon-color-primary icon-endsWith_16"}`}/>
                            </span>
                        </div>
                        <input type="text" className="form-control form-control-lg h-40px br-right-5"
                               style={{borderRadius: '0rem 0.42rem 0.42rem 0rem'}}
                               name={"EndingOn"}
                               onKeyDown={(event)=>{
                                   if(event.code === "Enter" || event.code === "NumpadEnter") {
                                       this.ApplyFilter();
                                   }
                               }}
                               onChange={(event)=>{
                                   this.setState({EndingOn: event.target.value}, ()=>{
                                       this.CheckConflictedFields("EndingOn");
                                   });
                               }}
                               placeholder="Задайте значение" value={this.state.EndingOn}
                        />
                    </div>
                </div>

                <div className="form-group row" style={{marginBottom: '1rem'}}>
                    <p className="col-lg-12" style={{marginBottom: 0, color: '#e1e1e1'}}>Равно</p>
                    <div className="col-lg-12 input-group h-40px my-4">
                        <div className="input-group-prepend">
                            <span className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg"
                                  style={{borderRadius: '0.42rem 0rem 0rem 0.42rem'}}
                                  onMouseEnter={() => {this.setState({ onClearEqualHover : true})}}
                                  onMouseLeave={() => {this.setState({ onClearEqualHover : false})}}
                                  onClick={() => {if(this.state.onClearEqualHover) { this.setState({Equal: ""}) }}}>
                                <i className={`svg-icon svg-icon-lg ${this.state.onClearEqualHover ? "icon-Delete icon-color-red" : "icon-color-primary icon-equals_16"}`}/>
                            </span>
                        </div>
                        <input type="text" className="form-control form-control-lg h-40px br-right-5"
                               style={{borderRadius: '0rem 0.42rem 0.42rem 0rem'}}
                               name={"Equal"}
                               onKeyDown={(event) => {
                                   if(event.code === "Enter" || event.code === "NumpadEnter") {
                                       this.ApplyFilter();
                                   }
                               }}
                               onChange={(event) => {
                                   this.setState({Equal: event.target.value}, () => {
                                       this.CheckConflictedFields("Equal");
                                   })
                               }}
                               placeholder="Задайте значение" value={this.state.Equal}
                        />
                    </div>
                </div>

                <div className="row align-items-center" style={{paddingRight: '12.5px', paddingLeft: '12.5px'}}>
                    <div className="d-flex mr-2 mb-2" onClick={() => {this.ApplyFilter()}}>
                        <a href="#" className="btn btn-primary font-weight-bold">Применить</a>
                    </div>
                    <div className="d-flex mr-2 mb-2"
                         onClick={() => {if (this.props.onApply) this.props.onApply()}}>
                        <a href="#" className="btn btn-outline-secondary font-weight-bold">Отменить</a>
                    </div>
                    <div className="d-flex mb-2" onClick={() => {this.ApplyFilter(true)}}>
                        <span className="btn btn-icon btn-lg">
                            <i className="svg-icon icon-Table_edit_8 icon-color-primary text-success" />
                        </span>
                    </div>
                </div>

                {/*<p className="mb-3">Наличие значения</p>*/}
                {/*<div className="input-icon h-40px my-2">*/}
                {/*    <select name="test"  className="form-select" value={this.state.HasValue}*/}
                {/*            onChange={(event)=>{*/}
                {/*                this.setState({HasValue: Number.parseInt(event.target.value)}, () => {*/}
                {/*                    this.onInputHandler();*/}
                {/*                });*/}
                {/*            }}>*/}
                {/*        <option value={0}>Не выбрано</option>*/}
                {/*        <option value={1}>Нет значения</option>*/}
                {/*        <option value={2}>Есть значение</option>*/}
                {/*    </select>*/}
                {/*</div>*/}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        searchInputsMain: state.packages.tree.searchInputs,
        searchInputsAdditional: state.packages.treeAdditional.searchInputs,
        searchInputsDownload: state.packages.treeDownload.searchInputs,
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    changeDocumentViewMode,
    changeDocumentSearchInputs,
    changeDocumentSearchInputs_packagesDocumentRight,
    combineMainTableAttributes,
    mainTableSearchDoc,
    updateInputsFieldPackage,
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(FiltersString);
