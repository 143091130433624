import React from "react";

/*TODO КН 01.12.23 над компонентой указываете width, или overflow-hidden если ограничение по width находится выше*/
class FileNameWithEllipsis extends React.Component {
    render() {
        const fileNameArray = this.props.fileName.split('.');
        const name = fileNameArray[0] || '';
        const ext = fileNameArray.length > 1 && fileNameArray.at(-1)
        return (
            <div className='d-flex overflow-hidden text-nowrap' title={this.props.noTitle ? undefined : this.props.fileName}>
                <span className="overflow-hidden text-overflow-ellipsis">{name}.</span>
                {ext && <span>{ext}</span>}
            </div>
        )
    }
}

export default FileNameWithEllipsis;
