import React from "react";
import {connect} from "react-redux";
import {ModalTypes} from "../../../tools/StaticTypes";
import {setLoaderModalData, setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {MySelectNew} from "../../tools/MySelect/MySelectNew";
import {API} from "../../../tools/API_NEW/API";
import {
    breadcrumbsAdd, copyMainTreeToAdditional,
    PackageAddNodesTree, SelectActivePackageDocument,
    SelectActivePackageNode
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {setViewModePreviewPackages} from "../../../store/packages/actionCreators/package_MainTableActionCreator";
import {Routing} from "../../../tools/routing/Routing";
import {history, store} from "../../../index";


const createSelectStyles = {
    input: (css, state) => ({
        ...css,
        width: "100%",
        "> div": {
            width: "100%"
        },
        input: {
            width: `100% !important`,
            textAlign: "left"
        }
    })
}

class CrudPackagesModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            createPackagesList : [],
            singlePackName: ""
        };



        this.modalName = ModalTypes.packages.crud;

        this.hide = this.hide.bind(this);
        this.hideOutSide = this.hideOutSide.bind(this);
        this.onClickHandler = this.onClickHandler.bind(this);
        this.createNewPackages = this.createNewPackages.bind(this);
        this.deletePackage = this.deletePackage.bind(this);
        this.updateNamePackage = this.updateNamePackage.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);
        this.refreshPackages = this.refreshPackages.bind(this);

        this.newName = React.createRef();
    }

    // скрывать по кнопке
    hide() {

        this.props.setModalData({});
    }

    hideOutSide() { // скрывать при клике вне окна
        if(!this.state.contentOnHover) {

            this.props.setModalData({});
        }
    }


    async refreshPackages({Name = null}) {
        const {modal: {data: {tableRole, activeNode, type}}, activeNodeMain, activeNodeAdditional} = this.props;

        Name = type === "updateNamePackage"
            ? Name
            //TODO aa 20.03.2023
            // : activeNodeMain.Node.Name;
            : activeNode.Node.Name;


        //TODO aa 20.03.2023
        // let node = activeNode.Node;
        let node = activeNodeMain.Node;
        let nodeAdditional = activeNodeAdditional.Node;

        const isDeleteAdditional = type === "deletePackage" && tableRole === "additional";
        if(isDeleteAdditional) {
            node = activeNodeMain.Node;
            nodeAdditional = activeNode.Node;
        }

        //TODO aa 20.03.2023 иначе ошибки при перерисовке крошек в случае удаления пакета из основной таблицы
        const isDeleteMain = type === "deletePackage" && tableRole === "main";
        if(isDeleteMain) {
            node = activeNode.Node;
            nodeAdditional = activeNodeAdditional.Node;
        }

        try {
            // this.props.setModalData({
            //     name : ModalTypes.app.alert,
            //     data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "packages"}
            // });
            //Новый прелоадер
            store.dispatch(setLoaderModalData({
                data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "packages", key : "CrudPackagesModal105"}
            }));

            const IdParent = node.IdParent ? node.IdParent : node.Id;
            await this.props.PackageAddNodesTree({ Id: IdParent, TableType: "main" });
            await this.props.PackageAddNodesTree({ Id: node.Id, TableType: "main" });
            await this.props.SelectActivePackageNode({ Id: node.Id, TableType: "main" });
            await this.props.breadcrumbsAdd({
                NodeId: node.Id,
                Name: isDeleteMain ? Name : null,//!isDeleteAdditional ? Name : null,
                Refresh: !isDeleteMain, //!isDeleteAdditional,
                ParentId: IdParent,
                TableType: "main"
            });

            const AdditionalIdParent = nodeAdditional.IdParent ? nodeAdditional.IdParent : nodeAdditional.Id;

            //TODO зачем ???
            // await this.props.copyMainTreeToAdditional({});
            await this.props.PackageAddNodesTree({ Id: AdditionalIdParent, TableType: "additional" });
            await this.props.PackageAddNodesTree({ Id: nodeAdditional.Id, TableType: "additional" });
            await this.props.SelectActivePackageNode({ Id: nodeAdditional.Id, TableType: "additional" });
            await this.props.breadcrumbsAdd({
                NodeId: nodeAdditional.Id,
                Name: isDeleteAdditional ? Name : null,
                Refresh: !isDeleteAdditional,
                ParentId: AdditionalIdParent,
                TableType: "additional",
            });

            if(type === "deletePackage") {
                let query = this.props.router.location.query;
                let newQuery = query.hasOwnProperty('p2') ? {p: node.Id, p2: nodeAdditional.Id} : {p: node.Id};
                let queryLink = "";
                queryLink = Routing.CreateRef({
                    oldQuery : query,
                    newQuery : newQuery,
                    excludedKeys : ["d", "d2", "dwd"]
                });

                let link = `${this.props.router.location.pathname}${queryLink}`;
                history.replace(link);
            }

            //TODO aa перенес в finally
            // //Новый прелоадер
            // store.dispatch(setLoaderModalData({
            //     keyDeleted : "CrudPackagesModal151"
            // }));
            // this.props.setModalData({});
        } catch (e) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content: `Ошибка! <br/> ${e}`}
            });

        }
        finally {
            //Новый прелоадер
            store.dispatch(setLoaderModalData({
                keyDeleted : "CrudPackagesModal151"
            }));
        }

    }

    onClickHandler() {
        let modalType = this.props.modal.data.type;

        if(modalType === "createNewPackages") {
            this.createNewPackages();
        }
        else if (modalType === "deletePackage"){
            this.deletePackage();
        }
        else if (modalType === "updateNamePackage"){
            this.updateNamePackage();
        }
        else if (modalType === "deleteDocument"){
            this.deleteDocument();
        }
    }

    async deleteDocument() {
        if (this.props.modal.data.activeDocument?.Node.IsAuto) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content: `Удаление автораспределенного документа из пакета невозможно`}
            });
            return;
        }
            let collection = []
            if (this.props.modal.data.activeDocument) {
                collection.push({Id: this.props.modal.data.activeDocument.Node.IdPackageDocRef})
            } else if (this.props.modal.data.collection){
                collection = this.props.modal.data.collection
            }

            let result = await API.packages().deleteCollection({
                collection
            });

            if (result?.errorCode) {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {type: "fail", content: `Документ не был удален<br/>${result.message.Message}`}
                });
                return;
            }

            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "success", content: `Документ успешно удален`},
                Next : {
                    action : ()=>{
                        this.refreshPackages({});


                        if(this.props.viewModePreview === "two_table_none" || this.props.viewModePreview === "two_table") {
                            this.props.SelectActivePackageDocument({TableType : this.props.modal.data.tableRole});
                        }
                        else if(this.props.viewModePreview === "normal"){
                            this.props.setViewModePreviewPackages({
                                ViewMode : "none"
                            });
                        }
                        else if(this.props.viewModePreview) {
                            this.props.setViewModePreviewPackages({
                                ViewMode : this.props.viewModePreview
                            });
                        }


                        this.props.SelectActivePackageDocument({TableType : "main"});
                        this.props.SelectActivePackageDocument({TableType : "additional"});
                        this.props.SelectActivePackageDocument({TableType : "download"});


                        let query = this.props.router.location.query;
                        let queryLink = "";
                        queryLink = Routing.CreateRef({
                            oldQuery : query,
                            newQuery : {},
                            excludedKeys : ["d", "d2", "dwd"]
                        });

                        let link = `${this.props.router.location.pathname}${queryLink}`;
                        history.push(link);
                    },
                    params : {}
                }
            });
    }

    async updateNamePackage(){
        let Id= this.props.modal.data.activeNode.Node.Id;
        let newName = this.newName.current.value;
        let IdParent = this.props.modal.data.activeNode.Node.IdParent;
        let IdFilial = this.props.modal.data.activeNode.Node.IdFilial;

        if(newName.length==0){
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type : "fail", content : `Пакет не был переименован.<br/>Заполните новое имя пакета`}
            });
            return;
        }

        let result = await API.packages().updateNamePackage({
            entity:{
                Id:Id,
                Name:newName,
                IdParent:IdParent,
                IdFilial:IdFilial,
            }
        });

        if(result?.errorCode) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type : "fail", content : `Пакет не был переименован<br/>${result.message.Message}`}
            });
            return;
        }

        this.props.setModalData({
            name: ModalTypes.app.info,
            data: {type : "success", content : `Пакет успешно переименован`},
            Next : {
                action : ()=>{
                    this.refreshPackages({Name : newName});
                },
                params : {}
            }
        });
    }

    async deletePackage(){

        let id = this.props.modal.data.idParent.Node.Id;

        if(this.props.modal.data.idParent.Children.length > 0){
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type : "fail", content : `Пакет не был удален.<br/>В пакете не должно быть документов и пакетов`}
            });
            return;
        }

        let result = await API.packages().deletePackage({
            id:id
        });

        if(result?.errorCode) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type : "fail", content : `Пакет не был удален<br/>${result.message.Message}`}
            });
            return;
        }

        this.props.setModalData({
            name: ModalTypes.app.info,
            data: {type : "success", content : `Пакет успешно удален`},
            Next : {action : ()=>{  this.refreshPackages({})}, params : {}}
        });
    }

    async createNewPackages() {
        let idParent = this.props.modal.data.idParent;


        // if(this.state.createPackagesList.length <= 0) {
        //     this.props.setModalData({
        //         name: ModalTypes.app.info,
        //         data: {type : "fail", content : `Пакеты не были созданы.<br/>Необходимо добавить хотя бы один`}
        //     });
        //     return;
        // }


        let result;
        if(idParent == 2) {
            result = await API.packages().createNewOnePackage({
               entity : {
                   Name : this.state.createPackagesList[0].Name,
                   IdFilial : this.props.FilialId,
                   IdParent : idParent,
                   CreateDate : null,
                   ModifyDate : null,
                   Type : 2
               }
            });
        }
        else {
            result = await API.packages().createNewPackages({
                childPackages : this.state.createPackagesList,
                idRootPackage : idParent,
                setLastVersion : "true"
            });
        }



        if(result?.errorCode) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type : "fail", content : `Пакеты не были созданы<br/>${result.message.Message}`}
            });
            return;
        }

        this.props.setModalData({
            name: ModalTypes.app.info,
            data: {type : "success", content : `Пакеты успешно созданы`},
            Next : {action : ()=>{  this.refreshPackages({})}, params : {}}
        });
    }

    render() {
        let modalType = this.props.modal.data.type;


        let DisableButton = false;
        if(modalType === "createNewPackages") {
            if(this.state.createPackagesList.length <= 0 && this.state.singlePackName == "") {
                DisableButton = true;
            }
        }

            let modalStyle = {
            display : "block",
            backgroundColor: "#061c3e66",
        };

        return (
            <div className="modal fade show" style={modalStyle} onClick={this.hideOutSide}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                    <div className="modal-content"
                         onMouseEnter={() => {this.setState({ contentOnHover : true})}}
                         onMouseLeave={() => {this.setState({ contentOnHover : false})}}>
                        {
                            modalType === "createNewPackages" &&
                            <>
                                <div className="modal-header">
                                    {
                                        this.props.modal.data.root
                                            ? <h5 className="modal-title">Создать новый пакет в корневом пакете</h5>
                                            : this.props.modal.data.inLevel
                                                ? <h5 className="modal-title">Создать новый пакет на уровне с текущим</h5>
                                                : <h5 className="modal-title">Создать новый пакет в текущем</h5>
                                    }
                                </div>
                                <div className="modal-body" style={{}}>
                                    {/*TODO: ms: бред, зачем тут 200px ????*/}
                                    <p style={{textAlign : "center"}}>
                                        Для сохранения имени пакета нажмите <b>"Enter"</b> или <b>"Tab"</b> или нажмите <b>"Подтвердить"</b>,
                                        чтобы создать 1 пакет с набранным именем
                                    </p>
                                    {
                                        this.props.modal.data.idParent == 2 &&
                                        <p style={{textAlign : "center"}}>В корневом пакете за один раз возможно создать только 1 новый пакет</p>
                                    }
                                    <div className="col-lg-12 row my-3">
                                        <MySelectNew returnFunc={(list)=>{
                                            let result = list.map((item, index)=>{
                                                return {Name : item};
                                            })
                                            this.setState({createPackagesList : result})}
                                        } setSinglePackName={(val) => {
                                            if(this.state.createPackagesList.length <= 0) {
                                                this.setState({singlePackName: val})
                                            } else this.setState({singlePackName: ""})
                                        }}
                                                     placeholder={"Имена Пакетов"}
                                         isMulti={this.props.modal.data.idParent != 2}
                                            // стили для отображение контекстного меню браузера как текстового поля (копировать/вставить и т.д.)
                                            styles={createSelectStyles}
                                        />
                                    </div>
                                </div>
                            </>

                        }
                        {
                            modalType === "deletePackage" &&
                            <>
                                <div className="modal-header">
                                    <h5 className="modal-title">Удалить текущий пакет?</h5>
                                </div>
                                {/*<div className="modal-body" style={{height: "200px"}}>*/}
                                {/*    <div className="col-lg-12 row my-3">*/}
                                {/*        <MySelectNew returnFunc={(list)=>{*/}
                                {/*            let result = list.map((item, index)=>{*/}
                                {/*                return {Name : item};*/}
                                {/*            })*/}
                                {/*            this.setState({createPackagesList : result})}*/}
                                {/*        } placeholder={"Имена Пакетов"}/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </>

                        }
                        {
                            modalType === "updateNamePackage" &&
                            <>
                                <div className="modal-header">
                                <h5 className="modal-title">Переименовать текущий пакет</h5>
                                </div>
                                    <div className="form-group px-5 py-2">
                                        <label>Новое имя пакета
                                            <span className="text-danger"> *</span></label>
                                        <input type="text" ref={this.newName} defaultValue={this.props.modal.data.activeNode.Node.Name} className="form-control" placeholder="Введите новое имя пакета"/>
                                    </div>
                            </>

                        }
                        {
                            modalType === "deleteDocument" &&
                            <>
                                <div className="modal-header">
                                    <h5 className="modal-title">Исключить выбранный документ из пакета?</h5>
                                </div>
                            </>

                        }
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary font-weight-bold"
                                    disabled={DisableButton}
                                    onClick={this.onClickHandler}
                                    onMouseDown={() => {
                                        if (this.state.createPackagesList.length <= 0)
                                            this.setState({createPackagesList: [{Name: this.state.singlePackName}]})
                                    }}
                            >
                                Подтвердить
                            </button>
                            <button type="button" className="btn btn-light font-weight-bold" onClick={this.hide}>
                                Отменить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const  mapStateToProps = state => {
    return {
        activeNodeMain: state.packages.tree.activeNode,
        activeNodeAdditional : state.packages.treeAdditional.activeNode,
        FilialId : state.globalState.filial.Active.Id,
        router : state.router,
        viewModePreview : state.packages.rootState.viewModePreview,
    }
}

const  mapDispatchToProps = {
    setModalData,
    PackageAddNodesTree,
    SelectActivePackageNode,
    breadcrumbsAdd,
    copyMainTreeToAdditional,
    setViewModePreviewPackages,
    SelectActivePackageDocument
}

export default connect(mapStateToProps, mapDispatchToProps)(CrudPackagesModal);
