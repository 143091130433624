const LanguageStrings = {
    Version: {
        // Client: "1.1.26", //16.03.2023
        // Client: "1.1.27", //17.03.2023
        // Client: "1.1.28", //20.03.2023 test
        // Client: "1.1.28", //21.03.2023
        // Client: "1.1.28,05", //22.03.2023
        // Client: "1.1.28,1", //22.03.2023
        // Client: "1.1.29", //22.03.2023
        // Client: "1.1.30", //23.03.2023
        // Client: "1.1.30,05", //24.03.2023
        // Client: "1.1.30,07", //24.03.2023
        // Client: "1.1.31", //27.03.2023
        //Client: "1.1.32", //28.03.2023
        // Client: "1.1.33", //30.03.2023
        // Client: "1.1.34", //31.03.2023
        // Client: "1.1.35", //03.04.2023
        // Client: "1.1.36", //04.04.2023
        //Client: "1.1.37", //07.04.2023
        // Client: "1.1.38", //11.04.2023
        // Client: "1.1.38,05", //12.04.2023
        // Client: "1.1.39",//13.04.2023
        // Client: "1.1.40", //13.04.2023
        // Client: "1.1.41", //18.04.2023
        // Client: "1.1.42", //19.04.2023
        // Client: "1.1.43", //21.04.2023
        // Client: "1.1.44", //25.04.2023
        // Client: "1.1.45", //25.04.2023
        // Client: "1.1.46", //28.04.2023
        // Client: "1.1.47", //02.05.2023
        // Client: "1.1.48", //03.05.2023
        // Client: "1.1.49", //05.05.2023
        // Client: "1.1.50", //12.05.2023
        // Client: "1.1.51", //15.05.2023
        // Client: "1.1.52", //22.05.2023
        // Client: "1.1.53", //26.05.2023
        // Client: "1.1.54", //05.06.2023
        // Client: "1.1.55", //08.06.2023
        // Client: "1.1.56", //20.06.2023
        // Client: "1.1.57", //20.06.2023
        // Client: "1.1.58", //29.06.2023
        // Client: "1.1.59", //11.07.2023
        // Client: "1.1.60", //20.07.2023
        // Client: "1.1.62", //03.08.2023
        // Client: "1.1.62.5", //08.08.2023
        // Client: "1.1.62.7", //08.08.2023
        // Client: "2.0.0", //21.09.2023
        // Client: "2.0.1", //10.11.2023
        // Client: "2.0.2", //10.11.2023
        // Client: "2.0.3", //29.11.2023
        // Client: "2.0.4", //01.12.2023
        // Client: "2.0.5", //04.12.2023
        // Client: "2.0.6", //26.12.2023
        // Client: "2.0.7", //22.01.2024
        // Client: "2.0.8", //29.01.2024
        // Client: "2.0.9", //30.01.2024
        // Client: "2.0.10", //07.02.2024
        // Client: "2.0.11", //09.02.2024
        // Client: "2.0.11.05", //09.02.2024
        // Client: "2.0.11.06", //09.02.2024
        // Client: "2.0.11.07", //12.02.2024
        // Client: "2.0.11.08", //29.01.2024
        // TODO КН 03.04 ======= revisionBuilds-start =======
        // Client: "2.0.11.09", //14.02.2024
        // Client: "2.0.11.10", //15.02.2024
        // Client: "2.0.11.11", //15.02.2024
        // Client: "2.0.11.12", //16.02.2024
        // Client: "2.0.11.13", //16.02.2024
        // Client: "2.0.11.14", //19.02.2024
        // Client: "2.0.11.15", //19.02.2024
        // Client: "2.0.11.16", //20.02.2024
        // Client: "2.0.11.17", //27.02.2024
        // Client: "2.0.11.18", //28.02.2024
        // Client: "2.0.11.19", //29.02.2024
        // Client: "2.0.11.20", //01.03.2024
        // Client: "2.0.11.21", //04.03.2024
        // Client: "2.0.11.22", //04.03.2024
        // Client: "2.0.11.23", //05.03.2024
        // Client: "2.0.11.24", //07.03.2024
        // Client: "2.0.11.25", //11.03.2024
        // Client: "2.0.11.26", //12.03.2024
        // Client: "2.0.11.27", //13.03.2024
        // Client: "2.0.11.28", //14.03.2024
        // Client: "2.0.11.29", //15.03.2024
        // Client: "2.0.11.30", //15.03.2024
        // Client: "2.0.11.31", //19.03.2024
        // Client: "2.0.11.32", //26.03.2024
        // Client: "2.0.11.33", //03.04.2024
        // Client: "2.0.11.34", //03.04.2024
        Client: "2.0.11.35", //20.05.2024
    },
};

export default LanguageStrings;
