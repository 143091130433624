import React, { Component } from 'react';
import Select, { components } from 'react-select';
import stylePagination from "../../../media/styles/pagination.module.css";
import Pagination from "../../mainTable/pagination/Pagination";
import { apiRequest } from "../../../tools/API/apiRequest";
import { apiUrl } from "../../../tools/API/apiUrl";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import { addModalData } from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import { ModalTypes } from "../../../tools/StaticTypes";
import { store } from "../../../index";
import {API} from "../../../tools/API_NEW/API";

const createSelectStyles = {
    input: (css, state) => ({
        ...css,
        width: "100%",
        "> div": {
            width: "100%"
        },
        input: {
            width: `100% !important`,
            textAlign: "left"
        }
    })
}

class SearchableDictionariesSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            debounceAnchor: null,
            options: [],
            currentPage: 1,
            totalItems: 0
        };
    }

    handlePageChange = async (pageNumber, itemsPerPage, fields) => {
        this.setState({ currentPage: pageNumber });
        await this.loadOptions(pageNumber, itemsPerPage, fields);
        this.props.onChange();
    };

    loadOptions = async (pageNumber, itemsPerPage, fields, selectedValue) => {
        try {
            const lData = await this.getDictionariesData(pageNumber, itemsPerPage, fields, selectedValue);
            this.setState({ totalItems: lData?.TotalRecordsCount });
            const newOptions = lData?.Records.map((item) => ({
                value: item.DictionaryValue.Id,
                label: JSON.parse(item?.DictionaryValue?.Value)[fields.AttributeName.DictionaryFieldName],
                searchLabel: item?.FieldSearchHighlights?.["ValueAsObject." + fields.AttributeName.DictionaryFieldName] ?
                    item?.FieldSearchHighlights?.["ValueAsObject." + fields.AttributeName.DictionaryFieldName][0] :
                    JSON.parse(item?.DictionaryValue?.Value)[fields.AttributeName.DictionaryFieldName]
            }));
            this.setState({ options: newOptions });
        } catch (error) {
            console.error('Error loading options:', error);
        }
    };
    getDictionariesData = async (pageNumber, itemsPerPage, fields, selectedValue) => {
        let dictionariesData = null;
        try {
            dictionariesData = await API.search().searchByParamsInDictionaries({
                searchParams: {
                    DictionaryId: fields.AttributeName.IdDictionaries,
                    FieldSearchParams: [{
                        FieldName: "ValueAsObject." + fields.AttributeName.DictionaryFieldName,
                        FieldValue: selectedValue ?? (!Array.isArray(fields.inputValue?.label) ? fields.inputValue?.label : null)
                    }],
                    PagingFilter: {
                        PageSize: itemsPerPage,
                        PageOffset: pageNumber - 1
                    }
                }
            })

            if(dictionariesData.errorCode) {
                const message = dictionariesData?.message ?? 'Неизвестная ошибка';
                store.dispatch(addModalData({
                    name: ModalTypes.app.info,
                    data: {
                        content: message,
                        disableButton: false,
                        type: "fail"
                    }
                }));
            }

        } catch {
            // error Обработан в errorCallback
        }
        return dictionariesData;
    }

    componentDidMount() {
        this.loadOptions(this.state.currentPage, 5, this.props.fields);
    }

    render() {
        const { options, currentPage, totalItems } = this.state;
        const { fields, onChange } = this.props;

        return (
            <div>
                <Select
                    styles={createSelectStyles}
                    className="text-primary font-weight-bold mr-2 border-0 bg-light-success w-100"
                    closeMenuOnSelect={true}
                    menuPlacement="top"
                    placeholder="Выберите значения внешнего справочника"
                    noOptionsMessage={() => "Нет данных для выбора"}
                    value={fields.value}
                    isMulti={fields.IsMultipleAllowed}
                    onChange={(selectedValue) => {
                        onChange(selectedValue, fields);
                        this.loadOptions(currentPage, 5, fields, selectedValue.label);
                    }}
                    options={options}
                    components={{
                        Option: (props) => (
                            <components.Option {...props}>
                                <>
                                    <div {...props.innerProps}>
                                        {parse(sanitizeHtml(props.data.searchLabel, {allowedAttributes: {span: ["class", "className"]}}))}
                                    </div>
                                </>
                            </components.Option>
                        ),
                        MenuList: (props) => (
                            <div>
                                <components.MenuList {...props}>{props.children}</components.MenuList>
                                <Pagination
                                    activePage={currentPage}
                                    itemsCountPerPage={5}
                                    totalItemsCount={totalItems}
                                    pageRangeDisplayed={2}
                                    onChange={(pageNumber) => this.handlePageChange(pageNumber, 5, fields)}
                                    itemClass={stylePagination.item}
                                    linkClass="btn btn-icon btn-sm btn-light-success mr-2 my-1 pagination-btn"
                                    activeLinkClass="btn btn-icon btn-sm btn-light-success active mr-2 my-1 pagination-btn"
                                />
                            </div>
                        )
                    }}
                    onInputChange={(newValue, actionMeta) => {
                        if (actionMeta.action === 'input-change') {
                            if (this.state.debounceAnchor) {
                                clearTimeout(this.state.debounceAnchor);
                            }
                            this.setState({
                                debounceAnchor: setTimeout(async () => {
                                    await this.loadOptions(currentPage, 5, fields, newValue);
                                }, 800)
                            });
                        }
                        if (actionMeta.action === 'input-blur' || actionMeta.action === 'menu-close') {
                            this.loadOptions(currentPage, 5, fields);
                        }
                    }}
                    filterOption={option => option}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            text: 'var(--hover-primary)',
                            primary25: 'var(--hover-primary)',
                            primary50: 'var(--hover-primary)',
                            primary: 'var(--originaltwo)',
                            neutral0: 'var(--elem-bg)',
                            neutral20: 'var(--originaltwo)',
                            neutral30: 'var(--text-primary)',
                            neutral40: 'var(--text-primary)',
                            neutral50: 'var(--text-primary)',
                            neutral80: 'var(--text-primary)',
                        },
                    })}
                />
            </div>
        );
    }
}

export default SearchableDictionariesSelect;
