// import {UserSettingsNames} from "../store/settings/settingsActionCreator";

import {
    AdminLevelEnum,
    DataTypes,
    ModalTypes,
    Operators as FilerOperatorsEnum,
    TableFilterHasValueEnum,
    UserSettingsNames
} from "./StaticTypes";
import {history, store} from "../index";
import {TreeCombine} from "./TreeCombine";
import {cloneDeep, drop, dropRight} from 'lodash';
import {EntityFilters} from "./API_NEW/ApiFilter";
import {DataTypesEnum} from "./API_NEW/ServerTypes";
import {apiRequestNew} from "./API/apiRequest";
import {apiUrl} from "./API/apiUrl";
import {
    addModalData,
    setLoaderModalData,
    setModalData
} from "../store/globalState/actionCreators/globalState_AppActionCreator";
import {API} from "./API_NEW/API";
import {DOCUMENT_VERIFY} from "../store/documents/documentActionsList";
import {Filter, FiltersType} from "./Filters";
import {setVersionIdForLocate} from "../store/masterApp/actionCreator/masterAppActionCreator";
import {Routing} from "./routing/Routing";

export class HelpFunctions {
    // проверка таблицы -> имеется ли для нее пользовательские настойки
    static CheckUserSettingForTable({userSettings = null, IdDocGroup = null, TypeId = null, IdFilial = null}) {

        if(userSettings == null)  userSettings = store.getState().globalState.userSettings;
        if(IdDocGroup == null)   IdDocGroup = TreeCombine.searchByTreeId({treeId : store.getState().document.tree.activeNode.parent}).info.Id;
        if(TypeId == null)   TypeId = store.getState().document.tree.activeNode.info.Id;
        if(IdFilial == null)  IdFilial = store.getState().globalState.filial.Active.Id;

        let UserSettingsTable = {
            isFind : false,
            data : null
        };

        let findUserSettings = HelpFunctions.FilterLoadSettings({
            name : UserSettingsNames.MAIN_TABLE_CONFIG
        });

        if(findUserSettings) {
            let foundTable = findUserSettings.Value
                .find(Value =>
                    Number.parseInt(Value.info.IdDocGroup) === Number.parseInt(IdDocGroup) &&
                    Number.parseInt(Value.info.TypeId)     === Number.parseInt(TypeId) &&
                    Number.parseInt(Value.info.IdFilial)   === Number.parseInt(IdFilial)
                );

            return foundTable
                ? {isFind : true, data : foundTable}
                : __.deepCopy(UserSettingsTable)
        }
        else return __.deepCopy(UserSettingsTable);
    }

    // проверка таблицы -> имеется ли для нее пользовательские настойки
    static CheckUserSettingForTable_packagesRightDocuments({userSettings = null, IdDocGroup = null, TypeId = null, IdFilial = null}) {

        if(userSettings == null)  userSettings = store.getState().globalState.userSettings;
        if(IdDocGroup == null)   IdDocGroup = TreeCombine.searchByTreeId_packagesRightDocuments({treeId : store.getState().packages.treeForDocuments.activeNode.parent}).info.Id;
        if(TypeId == null)   TypeId = store.getState().packages.treeForDocuments.activeNode.info.Id;
        if(IdFilial == null)  IdFilial = store.getState().globalState.filial.Active.Id;

        let UserSettingsTable = {
            isFind : false,
            data : null
        };

        let findUserSettings = HelpFunctions.FilterLoadSettings({
            name : UserSettingsNames.MAIN_TABLE_CONFIG
        });

        if(findUserSettings) {
            let foundTable = findUserSettings.Value
                .find(Value =>
                    Number.parseInt(Value.info.IdDocGroup) === Number.parseInt(IdDocGroup) &&
                    Number.parseInt(Value.info.TypeId)     === Number.parseInt(TypeId) &&
                    Number.parseInt(Value.info.IdFilial)   === Number.parseInt(IdFilial)
                );

            return foundTable
                ? {isFind : true, data : foundTable}
                : __.deepCopy(UserSettingsTable)
        }
        else return __.deepCopy(UserSettingsTable);
    }

    // поиск пользовательской настройки по имени
    static FilterLoadSettings({name})  {
        let userSettings = __.deepCopy(store.getState().globalState.userSettings)
            .find(userSettings => userSettings.Name === name);
        return userSettings ? userSettings : false;
    }

    // является ли переменная строкой
    static isString(val) {
        return (typeof val === "string" || val instanceof String);
    }

    static isValidURL(value) {
        try {
            new URL(value);
            return true;
        } catch (error) {
            return false;
        }
    }

    static isJSON(jsonString) {
        try {
            JSON.parse(jsonString);
            return jsonString;
        } catch (error) {
            return null;
        }
    }

    // функция обрезает текст до нужной длины
    static cutLongString(text, maxCountLitters, dotes = false) {
        // для раздела поиска: приходит строка после парсера с тегами
        if(typeof text !== "string") {
            text = text[0]
        }

        if(text && text.length > maxCountLitters) {
            let cutText = text.substr(0, maxCountLitters);
            if(dotes) {
                return cutText + "...";
            } else {
                return  cutText;
            }
        } else {
            return text;
        }
    }


    // преобразование размеров файла
    // si -> двоичная или десятичная система
    static humanFileSize(bytes, si=false, dp=1) {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10**dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


        return bytes.toFixed(dp) + ' ' + units[u];
    }

    // переставляет body таблицы по ее заголовкам
    static sortTableBodyByHeaders = ({ColumnDefinition, BodyDefinition}) => {
        let NewBodyDefinition = [];

        for(let k = 0; k < BodyDefinition.length; k++) {

            NewBodyDefinition.push({
                tr : [],
                Version : BodyDefinition[k].Version,
                Type : BodyDefinition[k].Type,
                AllAttributes : BodyDefinition[k]?.AllAttributes,
                Info : BodyDefinition[k].Info
            });


            for(let i = 0; i < ColumnDefinition.length; i++) { // сопоставляем тело с заголовками
                for(let j = 0; j < BodyDefinition[k].tr.length; j++) {
                    if(ColumnDefinition[i].Name === BodyDefinition[k].tr[j].Name) {
                        if(BodyDefinition[k].tr[j].IsConstant) {
                            NewBodyDefinition[k].tr.push({...BodyDefinition[k].tr[j], ...{UserVisible: ColumnDefinition[i].UserVisible}});
                            break;
                        } else  if(ColumnDefinition[i].Value === BodyDefinition[k].tr[j].ColumnName) {
                            NewBodyDefinition[k].tr.push({...BodyDefinition[k].tr[j], ...{UserVisible: ColumnDefinition[i].UserVisible}});
                            break;
                        }
                    }
                }
            }
        }
        return NewBodyDefinition;
    }

    static async blobToJSONString(blob) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                resolve(JSON.stringify(reader.result));
            };
        });
    };

    static async blobFromJSONString(fileAsJSON) {
        const parsed = JSON.parse(fileAsJSON);
        const fileBlob = await fetch(parsed).then(res => res.blob());
        return fileBlob;
    };

    static onClickFile(fileBlob, fileName) {
        const link = document.createElement("a");
        link.setAttribute("href", URL.createObjectURL(fileBlob));
        link.setAttribute("download", fileName );
        link.click();
    }

    static getFileExtensionByName(fileName) {
        const fileNameArray = fileName.split('.');
        return fileNameArray.length > 1 ? fileNameArray.at(-1) : null;
    }
}

// вспомогательный класс
export class __ {
    static async CheckDocumentExistOrAccess(docId,IdFilial, noNeedErrorModal , noNeedDeleteModal) {
        if (docId) {
            //Задача № 22235
            let docRefInfo = null;
            const verifyDocs = store.getState().document.verifyDocs
            //Если документ уже верифицирован, то не делаем запрос
            if (verifyDocs && verifyDocs[docId]) {
                docRefInfo = verifyDocs[docId][0];
            } else {
                if(!IdFilial){
                    IdFilial = store.getState().globalState.filial.Active.Id
                }
                docRefInfo = await API.search().verifyDocClientLinkIds({
                    linkIds: {
                        IdFilial: IdFilial,
                        IdDoc: docId,
                    }
                });
                if (docRefInfo?.errorCode) {
                    let errorMessage = null;
                    if (docRefInfo.message.ExceptionName === "DataAccessException") {
                        errorMessage = docRefInfo.message.Message
                        !noNeedErrorModal && store.dispatch(addModalData({
                            name: ModalTypes.app.alert,
                            data: {
                                content: errorMessage,
                                disableButton: false
                            }
                        }));
                    } else {
                        errorMessage = docRefInfo.message.Message
                        !noNeedErrorModal && store.dispatch(addModalData({
                            name: ModalTypes.app.info,
                            data: {
                                content: errorMessage,
                                disableButton: false,
                                type: "fail"
                            }
                        }));
                    }
                    if (!noNeedDeleteModal) { //TODO КН 25.01.24 не стал трогать чужую логику, по факту нужно просто переместить удаление модалки отсюда в функцию выше, где вызывается эта хелпФанк
                        //Новый прелоадер
                        store.dispatch(setLoaderModalData({keyDeleted : "routing1825"}));
                    }
                    return {errorMessage: errorMessage}; // TODO КН 04.03.24 Ошибка уже здесь обрабатывается, просто для определенных компонент нужен текст этой ошибки
                } else {
                    // сохраняем в редакс
                    store.dispatch({
                        type: DOCUMENT_VERIFY.UPDATE_VERIFY_DOCS, payload: {
                            id: docId,
                            data: docRefInfo
                        }
                    });
                }
            }
            return docRefInfo;
        } else return null;
    }
    static createUniqueIdString(len = 6) { // рандомное id строка
        const shuffle = (array) => {
            array.sort(() => Math.random() - 0.5);
            return array.join("");
        }
        let _abc = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let abc = _abc + "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        abc = shuffle(Array.from(abc));
        let rs = "";
        while (rs.length < len) {
            rs += abc[Math.floor(Math.random() * abc.length)];
        }
        return rs;
    }

    static hashCode(str) {
        let hash = 0;
        if (str.length === 0) {
            return hash;
        }
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash; // Преобразование в 32-битное целое число
        }
        return hash;
    }

    static unsecuredCopyToClipboard(text) {
        const textArea = document.createElement("textarea");
        textArea.value = text;

        // Сохраняем текущее положение экрана
        const scrollY = window.scrollY;

        // Перемещаем элемент на верхнюю часть страницы
        textArea.style.position = 'absolute';
        textArea.style.top = '0';
        textArea.style.left = '-9999px';
        document.body.appendChild(textArea);

        // Выполняем копирование
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');
        } catch (err) {
            console.error('Unable to copy to clipboard', err);
        }

        // Удаляем элемент
        document.body.removeChild(textArea);

        // Возвращаем положение экрана на место
        window.scrollTo(0, scrollY);
    }

    static copyUrl1c(lDoc = null, lPack = null, TableRole = null) {
        let d = "d="
        let p = "p="
        let t = "t="
        let g = "g="
        let indexMod = 2

        if (TableRole === "additional") {
            d = "d2="
            p = "p2="
            t = "t2="
            g = "g2="
            indexMod = 3
        }
        if (TableRole === "download") {
            d = "dwd="
            p = "dwp="
            indexMod = 4
        }
        let link = ""
        if (store.getState().router.location.pathname)
            if (store.getState().router.location.pathname.indexOf('/') != -1)
                link = store.getState().router.location.pathname.slice(store.getState().router.location.pathname.indexOf('/')+1, store.getState().router.location.pathname.length)
            else
                link = store.getState().router.location.pathname;
        link += store.getState().router.location.search;


        if (link.indexOf('search') != -1 && lDoc)
        {
            link = "documents?d="+lDoc
        }

        if (link.indexOf("packages") == -1)
            lPack = null;

        if ((lDoc || lPack) && link.indexOf('search') == -1)
        {
                    let newLink = "";
                    // if (lDoc)
                    // if (this.props.contextMenu.data?.tr?.Info?.Id || (this.props.contextMenu.data?.item?.Type == "PackagesDocument" && this.props.contextMenu.data?.item?.Node?.Id))
                    //  if (link.indexOf(`?${d}`) != -1) {
                    //      if (link.indexOf("&", link.indexOf(`?${d}`) + indexMod + 1) != -1)
                    //          newLink = link.slice(0, link.indexOf(`?${d}`)) + `?${d}` + lDoc + link.slice(link.indexOf("&", link.indexOf(`?${d}`) + indexMod + 1), link.length)
                    //      else
                    //          newLink = link.slice(0, link.indexOf(`?${d}`)) + `?${d}` + lDoc
                    //  } else if (link.indexOf(`&${d}`) != -1) {
                    //      if (link.indexOf("&", link.indexOf(`&${d}`) + indexMod + 1) != -1)
                    //          newLink = link.slice(0, link.indexOf(`&${d}`)) + `&${d}` + lDoc + link.slice(link.indexOf("&", link.indexOf(`&${d}`) + indexMod + 1), link.length)
                    //      else {
                    //          newLink = link.slice(0, link.indexOf(`&${d}`)) + `&${d}` + lDoc
                    //      }
                    //  } else
                    //      newLink = link + "&d=" + lDoc;
                    //
                    // if (lPack) {
                    //         if (newLink.indexOf("&") != -1)
                    //             newLink += "&p=" + lPack
                    //         else
                    //             newLink += "?p=" + lPack;
                    // }

                    //TODO старый формат
                    let oldLink = "";
                    if (link.indexOf('documents') != -1)
                        oldLink = "#d;"
                    else if (link.indexOf('packages') != -1)
                        oldLink = "#p;";

                    if (store.getState().globalState.filial?.Active?.Id) {
                        oldLink += "f=" + store.getState().globalState.filial?.Active?.Id + ";";
                    }

                    newLink = link
                    if (newLink.indexOf(p) != -1) {

                        if (newLink.indexOf("&", link.indexOf(p)) != -1)
                            oldLink += "pc=" + newLink.slice(newLink.indexOf(p) + indexMod, newLink.indexOf("&", newLink.indexOf(p))) + ";"
                        else
                            oldLink += "pc=" + newLink.slice(newLink.indexOf(p) + indexMod, newLink.length) + ";"
                    }

                    if (newLink.indexOf('g=') != -1) {

                        if (newLink.indexOf("&", newLink.indexOf('g=')) != -1)
                            oldLink += "g=" + newLink.slice(newLink.indexOf('g=') + 2, newLink.indexOf("&", newLink.indexOf('g='))) + ";"
                        else
                            oldLink += "g=" + newLink.slice(newLink.indexOf('g=') + 2, newLink.length) + ";"
                    }

                    if (newLink.indexOf('t=') != -1) {

                        if (newLink.indexOf("&", newLink.indexOf('t=')) != -1)
                            oldLink += "t=" + newLink.slice(newLink.indexOf('t=') + 2, newLink.indexOf("&", newLink.indexOf('t='))) + ";"
                        else
                            oldLink += "t=" + newLink.slice(newLink.indexOf('t=') + 2, newLink.length) + ";"
                    }

                    if (newLink.indexOf(d) != -1) {

                        if (newLink.indexOf("&", newLink.indexOf(d)) != -1)
                            oldLink += "r=" + newLink.slice(newLink.indexOf(d) + indexMod, newLink.indexOf("&", newLink.indexOf(d))) + ";"
                        else
                            oldLink += "r=" + newLink.slice(newLink.indexOf(d) + indexMod, newLink.length) + ";"
                    }

                    if (oldLink.slice(oldLink.length - 1, oldLink.length) == ";")
                        oldLink = oldLink.slice(0, -1);

                    //navigator.clipboard.writeText(newLink);

                    __.unsecuredCopyToClipboard(oldLink);
                    // __.unsecuredCopyToClipboard(newLink);
        }
        else {
            //TODO старый формат
            let newLink = "";
            if (link.indexOf('documents') != -1)
                newLink = "#d;"
            else if (link.indexOf('packages') != -1)
                newLink = "#p;";

            if (store.getState().globalState.filial?.Active?.Id) {
                newLink += "f=" + store.getState().globalState.filial?.Active?.Id + ";";
            }

            if (link.indexOf('p=') != -1) {

                if (link.indexOf("&", link.indexOf('p=')) != -1)
                    newLink += "pc=" + link.slice(link.indexOf('p=') + 2, link.indexOf("&", link.indexOf('p='))) + ";"
                else
                    newLink += "pc=" + link.slice(link.indexOf('p=') + 2, link.length) + ";"
            }
            if (link.indexOf('g=') != -1) {

                if (link.indexOf("&", link.indexOf('g=')) != -1)
                    newLink += "g=" + link.slice(link.indexOf('g=') + 2, link.indexOf("&", link.indexOf('g='))) + ";"
                else
                    newLink += "g=" + link.slice(link.indexOf('g=') + 2, link.length) + ";"
            }

            if (link.indexOf('t=') != -1) {

                if (link.indexOf("&", link.indexOf('t=')) != -1)
                    newLink += "t=" + link.slice(link.indexOf('t=') + 2, link.indexOf("&", link.indexOf('t='))) + ";"
                else
                    newLink += "t=" + link.slice(link.indexOf('t=') + 2, link.length) + ";"
            }

            if (link.indexOf('d=') != -1) {

                if (link.indexOf("&", link.indexOf('d=')) != -1)
                    newLink += "r=" + link.slice(link.indexOf('d=') + 2, link.indexOf("&", link.indexOf('d='))) + ";"
                else
                    newLink += "r=" + link.slice(link.indexOf('d=') + 2, link.length) + ";"
            }

            if (newLink.slice(newLink.length - 1, newLink.length) == ";")
                newLink = newLink.slice(0, -1);

            __.unsecuredCopyToClipboard(newLink)
            // navigator.clipboard.writeText(link)
        }
    }

    static copyUrl(lDoc = null, lPack = null, isDownload = false) {
        let link = window.location.href;
        let newLink = '';

        if (link.indexOf("search") != -1 && lDoc) {
            newLink = link.split('/search')[0]
            newLink += "/#d;"
            if (store.getState().globalState.filial?.Active?.Id) {
                newLink += "f=" + store.getState().globalState.filial?.Active?.Id + ";";
            }
            newLink += "r=" + lDoc
            __.unsecuredCopyToClipboard(newLink)
        } else if (lDoc && !lPack) {
            if (link.indexOf("?d=") != -1) {
                if (link.indexOf("&", link.indexOf("?d=") + 3) != -1)
                    newLink = link.slice(0, link.indexOf("?d=")) + "?d=" + lDoc + link.slice(link.indexOf("&", link.indexOf("?d=") + 3), link.length)
                else
                    newLink = link.slice(0, link.indexOf("?d=")) + "?d=" + lDoc

            } else if (link.indexOf("&d=") != -1) {
                if (link.indexOf("&", link.indexOf("&d=") + 3) != -1)
                    newLink = link.slice(0, link.indexOf("&d=")) + "&d=" + lDoc + link.slice(link.indexOf("&", link.indexOf("&d=") + 3), link.length)
                else {
                    newLink = link.slice(0, link.indexOf("&d=")) + "&d=" + lDoc
                }
            } else
                newLink = link + "&d=" + lDoc;

            __.unsecuredCopyToClipboard(newLink);
            // navigator.clipboard.writeText(newLink)
        } else if (link.indexOf("/packages") != -1 && lPack) {
            const lUrl = newLink = link.split('/packages')[0];
            const rootPackageId = store.getState().packages.tree?.render.Node.Id;
            newLink = lUrl + `/packages?p=${isDownload ? rootPackageId : lPack}`;
            if(isDownload) newLink += "&dwp=" + lPack;
            if(lDoc) newLink += `&${isDownload ? 'dwd' : 'd'}=` + lDoc;

            __.unsecuredCopyToClipboard(newLink);
        } else {
            __.unsecuredCopyToClipboard(link);
        }

        // if(isSearch){
            // if (link.indexOf('search') != -1 && lDoc)
            // {
            //     newLink =link.split('/search')[0]
            //     newLink += "/#d;"
            //     if (store.getState().globalState.filial?.Active?.Id)
            //     {
            //         newLink += "f="+store.getState().globalState.filial?.Active?.Id+";";
            //     }
            //     newLink += "r="+lDoc
            //     __.unsecuredCopyToClipboard(newLink)
            // }
        // }else{
        //     __.unsecuredCopyToClipboard(link)
        //     // navigator.clipboard.writeText(link)
        // }

    }

    static showModalCopyComplete(setModalData) {
        setModalData({
            name: ModalTypes.app.alert,
            data: {content: `Скопировано в буфер обмена`, disableButton: true}
        });
        setTimeout(() => {setModalData({})}, 500);
    }


    static dropArrayLeft(array, count) {
        return drop(array, count);
    }

    static dropArrayRight(array, count) {
        return dropRight(array, count);
    }

    static deepCopyJSON(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    static deepCopy(obj) {
        return cloneDeep(obj);
    }

    //Функция замены элемента в массиве по индексу
    static replaceElementInArray(array, index, newValue) {
        // Создаем копию исходного массива
        const newArray = [...array];

        // Изменяем значение элемента в скопированном массиве по указанному индексу
        newArray[index] = newValue;

        // Возвращаем измененный массив
        return newArray;
    }

    // получаем тип input в зависимости от типа данных
    static inputTypeByDataTypeId(DataTypesId) {
        switch (DataTypesId) {
            case DataTypes.Integer: return "number";
            case DataTypes.Float: return "number";
            case DataTypes.Boolean: return "checkbox";
            case DataTypes.DataTime: return "date";
            case DataTypes.RowBlob: return "file";
            case DataTypes.SearchBlob: return "text";
            case DataTypes.String: return "text";
            case DataTypes.DocRef: return "text";
            case DataTypes.DocVersionRef: return "text";
            case DataTypes.JsonObject: return "text";
            default: return "text";
        }
    }

    // проверяем атрибут на уникальность
    static isUniqueAttribute(IdAttributeName) {
        let storeAttrTypes = __.deepCopy(store.getState().document.tree.docs);

        let unityAttributes = [];
        for(let i = 0; i < storeAttrTypes.length; i++) {
            unityAttributes = unityAttributes.concat(storeAttrTypes[i].TypeAttributes);
        }

        let findAttribute = unityAttributes.find(attr => attr.AttributeName.Id === IdAttributeName);
        if(findAttribute) {
            return  findAttribute.IsUnique;
        } else {
            // если не найден, то скорее всего он константный
            return true;
        }
    }

    static checkAttributesInput() {

    }


    // присылаем строку-число
    // в ответ приходит int или float
    static checkOnFloatOrIntType(checkNumber) {
        if(Number.isInteger(Number(checkNumber))) {
            return "int";
        } else {
            return "float";
        }
    }

    static toInt(number) {
        return Number.parseInt(number);
    }

    static toFloat(number, afterDot = 2) {
        return Number.parseFloat(number).toFixed(afterDot);
    }

    static translit(word){
        var answer = '';
        var converter = {
            'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
            'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
            'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
            'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
            'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
            'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
            'э': 'e',    'ю': 'yu',   'я': 'ya',

            'А': 'A',    'Б': 'B',    'В': 'V',    'Г': 'G',    'Д': 'D',
            'Е': 'E',    'Ё': 'E',    'Ж': 'Zh',   'З': 'Z',    'И': 'I',
            'Й': 'Y',    'К': 'K',    'Л': 'L',    'М': 'M',    'Н': 'N',
            'О': 'O',    'П': 'P',    'Р': 'R',    'С': 'S',    'Т': 'T',
            'У': 'U',    'Ф': 'F',    'Х': 'H',    'Ц': 'C',    'Ч': 'Ch',
            'Ш': 'Sh',   'Щ': 'Sch',  'Ь': '',     'Ы': 'Y',    'Ъ': '',
            'Э': 'E',    'Ю': 'Yu',   'Я': 'Ya'
        };

        for (var i = 0; i < word.length; ++i ) {
            if (converter[word[i]] == undefined){
                answer += word[i];
            } else {
                answer += converter[word[i]];
            }
        }

        return answer;
    }
    static dateToRussia(Value){

            Value = Value.split('T')[0];
            let  year= Value.split('-')[0];
            let month= Value.split('-')[1];
            let day=Value.split('-')[2];
            Value=day+'.'+month+'.'+year;
        return Value;
    }

    // функция для перевода русского слова в Unicode-escape-последовательность
    // Эта функция принимает входное русское слово и возвращает его в виде Unicode-escape-последовательности. Можно использовать эту функцию для конвертации русских слов или фраз в формат, который можно безопасно использовать для обращения к данным с русскими символами в различных контекстах, таких как Elasticsearch
    static convertToUnicodeEscape(word){
        let unicodeEscape = '';
        for (let i = 0; i < word.length; i++) {
            unicodeEscape += '\\u' + word.charCodeAt(i).toString(16);
        }
        return unicodeEscape;
        // // Пример использования
        // let russianWord = 'Привет';
        // let unicodeEscapedWord = convertToUnicodeEscape(russianWord);
        // console.log(unicodeEscapedWord); // Output: "\u041f\u0440\u0438\u0432\u0435\u0442"
    }

    static containsRussianLetters(word) {
        return /[а-яА-ЯЁё]/.test(word);
        // Пример использования
        // let word1 = 'Hello'; // Английское слово
        // console.log(containsRussianLetters(word1)); // Output: false
        //
        // let word2 = 'Привет'; // Русское слово
        // console.log(containsRussianLetters(word2)); // Output: true
    }


    static dateToServer(Value,isEndDay,withoutT){
        if (Value === null) return
        else {
            let year = Value.split('.')[2];
            let month = Value.split('.')[1];
            let day = Value.split('.')[0];
            Value = year + '-' + month + '-' + day;
            if (!withoutT) {
                if (isEndDay) {
                    return Value + 'T23:59:59'
                } else {
                    return Value + 'T00:00:00'
                }
            }
            return Value;
        }
    }
    static reformatDate(dateStr){
        if (!dateStr)
            return "";
        var dArr = dateStr.split("-");  // ex input: "2022-01-18"
        return dArr[2]+ "." +dArr[1]+ "." +dArr[0]; //ex output: "18.01.2022"  .substring(2) если нужно 18.01.22
    }

    static setEntityFilters = (Attributes, fromPackages = false, lEntityName = null) => { //TODO тип
        let entityName = fromPackages
            ? "DocInPackage"
            : Attributes.isConstant
                ? "DocExtended"
                : "DocAttribute"

        if (lEntityName)
           entityName = lEntityName;
        let type = Attributes.IdAttributeType;
        let ValueAttribute = Attributes.Value;
        let NameAttribute = Attributes.Name === "PropertyName" ? "AttributeValue" : Attributes.Name;
        let entity = new EntityFilters().setEntityName(entityName);

        //условие для поиска из фильтров и из строки поиска
        let approxValue = typeof ValueAttribute === "string" ? ValueAttribute : ValueAttribute.ApproximatelyEqual;
        if(type === DataTypesEnum.String) {
            if(ValueAttribute.Equal) {
                entity.add({
                    Name: NameAttribute,
                    Value: ValueAttribute.Equal,
                    Operator: FilerOperatorsEnum.Equal
                });
            } else if(ValueAttribute.In) {
                entity.add({
                    Name: NameAttribute,
                    Value: ValueAttribute.In,
                    Operator: FilerOperatorsEnum.In
                });
            }
            else if(approxValue) {
                entity.add({
                    Name: NameAttribute,
                    Value: approxValue,
                    Operator: FilerOperatorsEnum.Like
                });
            } else {
                if(ValueAttribute.BeginningFrom) {
                    entity.add({
                        Name: NameAttribute,
                        Value: ValueAttribute.BeginningFrom,
                        Operator: FilerOperatorsEnum.StartsWith
                    });
                }
                if(ValueAttribute.EndingOn) {
                    entity.add({
                        Name: NameAttribute,
                        Value: ValueAttribute.EndingOn,
                        Operator: FilerOperatorsEnum.EndsWith
                    });
                }
            }
            if(ValueAttribute.HasValue === TableFilterHasValueEnum.NotEmpty) {
                entity.add({
                    Name: NameAttribute,
                    Operator: FilerOperatorsEnum.NotNull
                });
            } else if(ValueAttribute.HasValue === TableFilterHasValueEnum.Empty) {
                entity.add({
                    Name: NameAttribute,
                    Operator: FilerOperatorsEnum.IsNull
                });
            }
        }
        else if (type === DataTypesEnum.Integer || type === DataTypesEnum.Float) {
            if (ValueAttribute.Equal) {
                entity.add({
                    Name: NameAttribute,
                    Value: ValueAttribute.Equal,
                    Operator: FilerOperatorsEnum.Equal
                });
            } else if (ValueAttribute.MoreOrEqual && !ValueAttribute.LessOrEqual) {
                entity.add({
                    Name: NameAttribute,
                    Value: ValueAttribute.MoreOrEqual,
                    Operator: FilerOperatorsEnum.GreaterOrEqual
                });
            } else if (!ValueAttribute.MoreOrEqual && ValueAttribute.LessOrEqual) {
                entity.add({
                    Name: NameAttribute,
                    Value: ValueAttribute.LessOrEqual,
                    Operator: FilerOperatorsEnum.LessOrEqual
                });
            } else if (ValueAttribute.MoreOrEqual && ValueAttribute.LessOrEqual) {
                entity.add({
                    Name: NameAttribute,
                    Value: ValueAttribute.MoreOrEqual,
                    Operator: FilerOperatorsEnum.GreaterOrEqual
                }).add({
                    Name: NameAttribute,
                    Value: ValueAttribute.LessOrEqual,
                    Operator: FilerOperatorsEnum.LessOrEqual
                });
            }
            if(ValueAttribute.HasValue === TableFilterHasValueEnum.NotEmpty) {
                entity.add({
                    Name: NameAttribute,
                    Operator: FilerOperatorsEnum.NotNull
                });
            } else if(ValueAttribute.HasValue === TableFilterHasValueEnum.Empty) {
                entity.add({
                    Name: NameAttribute,
                    Operator: FilerOperatorsEnum.IsNull
                });
            }
        }
        else if(type === DataTypesEnum.Boolean) {
            if (ValueAttribute.IsChecked === true) {
                entity.add({
                    Name: NameAttribute,
                    Value: "true",
                    Operator: FilerOperatorsEnum.Equal
                });
            } else if (ValueAttribute.IsChecked === false) {
                entity.add({
                    Name: NameAttribute,
                    Value: "false",
                    Operator: FilerOperatorsEnum.Equal
                });
            }

            if(ValueAttribute.HasValue === TableFilterHasValueEnum.NotEmpty) {
                entity.add({
                    Name: NameAttribute,
                    Operator: FilerOperatorsEnum.NotNull
                });
            } else if(ValueAttribute.HasValue === TableFilterHasValueEnum.Empty) {
                entity.add({
                    Name: NameAttribute,
                    Operator: FilerOperatorsEnum.IsNull
                });
            }
        }
        else if(type === DataTypesEnum.DataTime) {
            if(ValueAttribute.OnDate) {
                if (fromPackages) {
                    entity.add({
                        Name: NameAttribute,
                        Value: ValueAttribute.OnDate.split("-").reverse().join("."),
                        Operator: FilerOperatorsEnum.Like
                    })
                } else {
                entity.add({
                    Name: NameAttribute,
                    Value: ValueAttribute.OnDate + "T00:00:00",
                    Operator: FilerOperatorsEnum.GreaterOrEqual
                }).add({
                    Name: NameAttribute,
                    Value: ValueAttribute.OnDate + "T23:59:59",
                    Operator: FilerOperatorsEnum.Less
                });
                }
            } else if(ValueAttribute.ApproximatelyEqual) {
                entity.add({
                    Name: NameAttribute,
                    Value: ValueAttribute.ApproximatelyEqual,
                    Operator: FilerOperatorsEnum.Like
                });
            } else {
                if(ValueAttribute.DateStart) {
                    entity.add({
                        Name: NameAttribute,
                        Value: ValueAttribute.DateStart + "T00:00:00",
                        Operator: FilerOperatorsEnum.GreaterOrEqual
                    });
                }
                if(ValueAttribute.DateEnd) {
                    entity.add({
                        Name: NameAttribute,
                        Value: ValueAttribute.DateEnd + "T00:00:00",
                        Operator: FilerOperatorsEnum.LessOrEqual
                    });
                }
            }

            if(ValueAttribute.HasValue === TableFilterHasValueEnum.NotEmpty) {
                entity.add({
                    Name: NameAttribute,
                    Operator: FilerOperatorsEnum.NotNull
                });
            } else if(ValueAttribute.HasValue === TableFilterHasValueEnum.Empty) {
                entity.add({
                    Name: NameAttribute,
                    Operator: FilerOperatorsEnum.IsNull
                });
            }
        }
        return entity;
    }

    // найти группу в многоуровневом дереве документов
    static findGroup = (List, Id, isParent, isGetGroup) => {
        //todo нужно тестить переходы из пакетов в документы
        // if(isGetGroup) {
        //     if(List.filter(item => item.type === "FOLDER").length > 0) {List = List.filter(item => item.type === "FOLDER");}
        //     else List = List.filter(item => item.type === "STRUCTURE");
        // }
        for (let i = 0; i < List.length; i++) {
            if(isParent && List[i].treeId == Id) return List[i]
            else if (List[i].info.Id == Id && List[i].type ==="FOLDER") return List[i]
            else {
                if (List[i].children && List[i].children.length > 0) {
                    const foundItem = this.findGroup(List[i].children, Id, isParent, isGetGroup);
                    if (foundItem && foundItem.type === "FOLDER") return foundItem
                }
            }
        }
    }

    static findStruct = (List, Id, isParent, isGetGroup) => {
        if(isGetGroup) {
            if(List.filter(item => item.type === "STRUCTURE").length > 0) {List = List.filter(item => item.type === "STRUCTURE");}
        }
        for (let i = 0; i < List.length; i++) {
            if(isParent && List[i].treeId == Id) return List[i]
            else if (List[i].info.Id == Id && List[i].type ==="STRUCTURE") return List[i]
            else {
                if (List[i].children && List[i].children.length > 0) {
                    const foundItem = this.findStruct(List[i].children, Id, isParent, isGetGroup);
                    if (foundItem && foundItem.type === "STRUCTURE") return foundItem
                }
            }
        }
    }
    // для открытия папок вложенной ноды дерева (документы с элементами структуры групп)
    static findParents = (List, IdParent, ids) => {
        let foundItem = this.findStruct(List, IdParent, true);
        if(foundItem) {
            ids.push(foundItem.info?.Id);
            if(foundItem.info.StructureNode) this.findParents(List, foundItem.parent, ids);
        }
    }

    //
    static setPreviewFiles = () => {

    }

    static buildDocLink = async (doc, filial) => {

        //Задача № 22235
        let vFlagVerify = false;
        let docRefInfo = null;
        //Если документ уже верифицирован, то не делаем запрос
        if (store.getState().document.verifyDocs && store.getState().document.verifyDocs[doc]) {
            vFlagVerify = true;
            docRefInfo = store.getState().document.verifyDocs[doc][0];
        }

        if (!vFlagVerify) {
            docRefInfo = await API.search().verifyDocClientLinkIds({
                linkIds: {
                    IdDoc: doc,
                    IdFilial: filial
                }
            });
            // сохраняем в редакс
            store.dispatch({
                type: DOCUMENT_VERIFY.UPDATE_VERIFY_DOCS, payload: {
                    id: doc.IdDoc,
                    data: docRefInfo
                }
            });
        }

        // let docRefInfo = await API.search().verifyDocClientLinkIds({
        //     linkIds: {
        //         IdDoc: item.IdDoc,
        //         IdFilial: item.IdFilial ?? item.filial
        //     }
        // });

        if (docRefInfo && docRefInfo.errorCode) {
            if (docRefInfo.message) {
                if (docRefInfo.message.Message) {
                    store.dispatch(addModalData({
                        name: ModalTypes.app.info,
                        data: {
                            content: docRefInfo.message.Message,
                            disableButton: false,
                            type: "fail"
                        }
                    }));
                    return;
                }
            }
        } else {
            let link = `/documents`;
            let postfix = "";
            if (docRefInfo.IdDocGroup)
                postfix += `?g=${docRefInfo.IdDocGroup}`;
            if (docRefInfo.IdDocType)
                if (postfix != "")
                    postfix += `&t=${docRefInfo.IdDocType}`
                else
                    postfix += `?t=${docRefInfo.IdDocType}`;
            if (docRefInfo.IdDoc)
                if (postfix != "")
                    postfix += `&d=${docRefInfo.IdDoc}`;
                else
                    postfix += `?d=${docRefInfo.IdDoc}`;
            link += postfix;
            if (filial !== store.getState().globalState.filial.Active.Id) {
                link = link.concat(`&f=${filial}`);
            }
            history.replace(link);
        }
    }

    // @param {number} docVersionId
    static goToDocumentsByDocVersionId = async ({docVersionId, successAdditionalLogic, failAdditionalLogic}) => {
        const versionData = await API.documents().getVersionData({id: docVersionId});
        if (!versionData?.errorCode) {
            const docId = versionData.IdDoc
            const docRefInfo = await this.CheckDocumentExistOrAccess(docId, false, true);
            if (docRefInfo && !docRefInfo?.errorMessage) {
                store.dispatch(setVersionIdForLocate(docVersionId));
                this.goToDocumentsByDocRefInfo({
                    docRefInfo,
                    successAdditionalLogic,
                    failAdditionalLogic: () => {
                        store.dispatch(setVersionIdForLocate(null));
                        failAdditionalLogic && failAdditionalLogic()
                    },
                })
            }
        } else {
            this.props.addModalData({
                name: ModalTypes.app.info,
                data: {
                    content: versionData.message.Message,
                    disableButton: false,
                    type: "fail"
                }
            })
            failAdditionalLogic && failAdditionalLogic()
        }
    }

    static goToDocumentsByDocId = async ({docId,IdFilial, successAdditionalLogic, failAdditionalLogic}) => {
        const docRefInfo = await this.CheckDocumentExistOrAccess(docId,IdFilial, false, true);
        if (docRefInfo && !docRefInfo?.errorMessage) {
            this.goToDocumentsByDocRefInfo({
                docRefInfo,
                successAdditionalLogic,
                failAdditionalLogic,
            })
        } else {
            failAdditionalLogic && failAdditionalLogic()
        }
    }

    static goToDocumentsByDocRefInfo = ({docRefInfo, successAdditionalLogic, failAdditionalLogic}) => {
        const globalState = store.getState().globalState;
        const docFilialIdIsActive = docRefInfo.IdFilial === globalState.filial.Active.Id
        const _buildAndRunLink = () => {
            const link = __.getDocLinkByDocRefInfo({
                docRefInfo: docRefInfo,
            })
            history.replace(link, true);
            successAdditionalLogic && successAdditionalLogic()
        }
        if (!docFilialIdIsActive) {
            const filialCaption = globalState.settings.Content?.FilialCaption
            const filialName = globalState.filial.All.find(filial => filial.Id === docRefInfo.IdFilial)?.Name;
            const message = `Данный документ был открыт в другом ${filialCaption ?? 'филиале'}${filialName ? ` <b>${filialName}</b>` : '' }.<br />Сменить ${filialCaption ?? 'филиал'} и перейти к документу?`
            store.dispatch(addModalData({
                name: ModalTypes.app.info,
                data: {
                    type: "question",
                    content: message,
                    disableButton: false,
                    fullBackground: false,
                    onClickContinue: () => {
                        _buildAndRunLink()
                    },
                    onClickHide: () => {
                        failAdditionalLogic && failAdditionalLogic()
                    }
                }
            }))
        } else {
            _buildAndRunLink()
        }
    }

    /*
    @params: {Arr?: { Name: string, Value: any }[], Custom?: { ValueName: string, LabelName: string, Arr: any[] }}
    return : SelectComponentItem[]
     */
    static ArrayToSelectData(Arr, Custom) {
        let result = [];

        if (Arr) {
            for (let i = 0; i < Arr.length; i++) {
                result.push({
                    label: Arr[i].Name,
                    value: Arr[i].Value,
                    isDisabled: false
                })
            }
        } else if (Custom) {
            for (let i = 0; i < Custom.Arr.length; i++) {
                result.push({
                    label: Custom.Arr[i][Custom.LabelName],
                    value: Custom.Arr[i][Custom.ValueName],
                    isDisabled: false
                })
            }
        }
        else throw new Error("Arr или Custom обязательны");
        return result;
    }

    /*
        @params (Enum:any): {Name:string, Value:any}[]
     */
    static EnumToArray(Enum) {
        let result = [];
        for (let i = 0; i < Object.keys(Enum).length; i++) {
            let key = Enum[i];
            let value = Enum[key];
            if(!key || !value) continue;
            result.push({
                Name: key,
                Value: value
            });
        }

        return result;
    }

    static isAttributeValuesEquals(firstValue, secondValue) {
        const firstValueIsArray = Array.isArray(firstValue);
        const secondValueIsArray = Array.isArray(secondValue);
        if (firstValueIsArray && firstValue.length === 1 && !secondValueIsArray) {
            return JSON.stringify(firstValue[0]) === JSON.stringify(secondValue)
        } else if (secondValueIsArray && secondValue.length === 1 && !firstValueIsArray) {
            return JSON.stringify(firstValue) === JSON.stringify(secondValue[0])
        } else {
            return JSON.stringify(firstValue) === JSON.stringify(secondValue)
        }
    }

    // TODO 13.02.2024 КН for not const regDate but for default DataTypes.DataTime
    static getCorrectDate(stringDataTime) {
        const firstRegex = /\d{4}-\d{2}-\d{2}/;
        const secondRegex = /\d{2}.\d{2}.\d{4}/;
        if (firstRegex.test(stringDataTime)) {
            return __.dateToRussia(stringDataTime);
        } else if (secondRegex.test(stringDataTime)) {
            return stringDataTime;
        } else {
            return null;
        }
    }

    static convertAttributesArrayToUnique(array, valueFieldName = 'Value') {
            if (!Array.isArray(array)) {
                return [];
            }
            const result = [];
            const map = new Map(); // key-idAttributeName, value-index

            for (const item of array) {
                const idAttributeName = item?.IdAttributeName || item.AttributeName.Id
                if (idAttributeName) {
                    if (map.has(idAttributeName)) {
                        const index = map.get(idAttributeName);
                        /* TODO КН 10.04.24 решил, что делать по старому способу (пушить значение в поле
                            если его значение уже было массивом или подменять на массив если не было массивом)
                            плохо, т.к. это работало с учетом на то, что поле придет примитивное с сервера, т.е
                            если заменят на обьект, то старый способ изменит пришедший в convertAttributesArrayToUnique() обьект
                         */
                        const covertToArray = (value) => {
                            return Array.isArray(value) ? value : [value]
                        }
                        const valueAsArray = covertToArray(result[index][valueFieldName])
                        const idAsArray = covertToArray(result[index].Id)
                        const idDictionaryValueAsArray = covertToArray(result[index].IdDictionaryValue)
                        const shortDescriptionAsArray = covertToArray(result[index].ShortDescription)
                        result[index] = {
                            /* TODO КН 12.04.24 Если здесь решите добавить или удалить какое то поле массивное,
                                то не забывайте внести изменения в местах где редактируете value
                                (т.е. на текущий момент это надо делать в NewDocumentByVersion.js и ChangeDocumentTypeModalOriginal.js
                                 в методе changeAttributeValue(), и в document_PreviewActionCreator'e в тханке updateAttributesRedactorData())
                             */
                            ...result[index],
                            [valueFieldName]: [...valueAsArray, item[valueFieldName]],
                            Id: [...idAsArray, item.Id],
                            IdDictionaryValue: [...idDictionaryValueAsArray, item.IdDictionaryValue],
                            ShortDescription: [...shortDescriptionAsArray, item.ShortDescription],
                        }
                    } else {
                        result.push(item);
                        map.set(idAttributeName, result.length - 1);
                    }
                } else {
                    result.push(item);
                }
            }
            return result;
    }

    static async getDocLinkByDocVersionId({docVersionId}) {
        let link = null, docId = null;
        const versionData = await API.documents().getVersionData({id: docVersionId});
        if (!versionData?.errorCode) {
            docId = versionData.IdDoc
        } else {
            this.props.addModalData({
                name: ModalTypes.app.info,
                data: {
                    content: versionData.message.Message,
                    disableButton: false,
                    type: "fail"
                }
            })
        }
        if (docId) {
            link = this.getDocLinkByDocId({
                docId,
                docVersionId,
            })
        }
        return link
    }

    // @param {number} docId
    static async getDocLinkByDocId({docId, docVersionId}) {
        let link = null;
        const docRefInfo = await this.CheckDocumentExistOrAccess(docId, false, true);
        if (docRefInfo && !docRefInfo?.errorMessage) {
            link = this.getDocLinkByDocRefInfo({
                docRefInfo: docRefInfo,
                versionIdValue: docVersionId,
            })
        }
        return link
    }

    static getDocLinkByDocRefInfo({docRefInfo, versionIdValue = undefined}) {
        const docFilialIdIsActive = docRefInfo.IdFilial === store.getState().globalState.filial.Active.Id
        let link = `/documents`;
        let postfix = "";
        if (docRefInfo.IdDocGroup) {
            postfix += `?g=${docRefInfo.IdDocGroup}`;
        }
        if (docRefInfo.IdDocType) {
            if (postfix != "") {
                postfix += `&t=${docRefInfo.IdDocType}`
            } else {
                postfix += `?t=${docRefInfo.IdDocType}`;
            }
        }
        if (docRefInfo.IdDoc) {
            if (postfix != "") {
                postfix += `&d=${docRefInfo.IdDoc}`;
            } else {
                postfix += `?d=${docRefInfo.IdDoc}`;
            }
        }
        if (versionIdValue) {
            if (postfix != "") {
                postfix += `&v=${versionIdValue}`;
            } else {
                postfix += `?v=${versionIdValue}`;
            }
        }
        if (!docFilialIdIsActive) {
            postfix += `&f=${docRefInfo.IdFilial}`;
        }
        link += postfix;
        return link;
    }

    static getCorrectValueForMultiply(value) {
        if ((value || value === '' || value === 0) && !Array.isArray(value)) {
            return [value]
        }
        return value
    }

    /* TODO КН 08.04.24 Пришли к выводу, что needAllAttributesOnType для текущей версии документа должен быть всегда true для текущей версии д-та, как и в редактор моде, так и в обычном режиме отображения;
        false должен быть, только когда запросили атрибуты старой версии, т.к. отображение старой версии это как бы "снимок" прошлой версии документа.
        Прим.: Мы учитываем тот факт, что удаленные с типа атрибуты, даже если мы запросили их по старой версии на которой они когда то были,
         не придут с сервера, т.к. сервер так решил */
    /* TODO КН 09.04.24 Возращаемые этим методом данные имеют поля, ссылаемые на сторовские обьекты.
         Я не хочу делать deepCopy на ретюрне. Думаю, если вы берете и заменяете в возвратившихся из этого метода данных
         какой нибудь обьект через '=' - то вы что-то делаете не так */
    /*
    * Получение из пришедших с сервера данных об атрибутах - данных нужной структуры, с, при необходимости, заполнением обьектов непришедших атрибутов, которые есть на типе, пустыми значениями.
    * TODO КН 09.04.24 Стоит учитывать что константные атрибуты удалятся, на данный момент мы их заполняем вручную до вызова этой ф-ии
    * @param requestAttributesData - данные атрибутов из запроса // TODO КН 09.04.24 Нужна структура которая приходит напр. из запроса 'DocAttributesForView/Load'
     */
    static transformAttributesData({attributesData, docTypeId, needAllAttributesOnType, newValueFieldName = 'AttributeValue'}) {
        const newAttributesData = []
        const typeAttributes = store.getState().document.tree.docs.find(docType => docType.Id === docTypeId)?.TypeAttributes
        if (typeAttributes && typeAttributes.length) {
            let sortedTypeAttributes = typeAttributes.slice().sort((a, b) => parseFloat(a.FieldOrder) - parseFloat(b.FieldOrder));
            for (let i = 0; i < sortedTypeAttributes.length; i++) {
                const attrData = attributesData?.filter(attr => attr.AttributeName.Id == sortedTypeAttributes[i].AttributeName.Id)
                // TODO КН 10.04.24 Определение userVisible скопировал из (старого) заполнения атрибутами таблицы в document_MainTableActionCreator
                let userVisible = sortedTypeAttributes[i].IsVisibleInRegistry
                if (sortedTypeAttributes[i].IsInvisible && store.getState().globalState.user.AdminLevel < AdminLevelEnum.Verifier) {
                    userVisible = false;
                }
                // TODO КН 10.04.24 Еще, если будет желание, надо изщбавиться от полей, которые используют AttributeName, т.к. он в любом случае полностью прокидывается соотв. полем
                /*
                TODO КН 10.04.24 Также если захотите добавить еще какое нибудь поле - подумайте,
                 мб стоит это поле обнулять при редактировании атрибута gперед отправкой.
                 Условно был ShortDescription, вы поменяли у DocRef id, не надо отправлять в запросе старый ShortDescription
                 */

                /*
                 todo tp 03.05.24 добавлено новое поле JsonSchema - для атрибутов типа json(таблица),для отображения заранее заданных заголовков
                 */

                if (attrData && attrData.length) {
                    // Для хранения id в редаксе, данные берем без массива, а явно списком
                    attrData.forEach(el => {
                        newAttributesData.push({
                            Name: "PropertyName",
                            [newValueFieldName]: el.AttributeValue,
                            IdDictionaryValue: el.IdDictionaryValue,
                            Id: el.Id,
                            ShortDescription: el.ShortDescription,
                            IdAttributeName: sortedTypeAttributes[i].AttributeName.Id,
                            IdAttributeType: sortedTypeAttributes[i].AttributeName.IdAttributeType,
                            AttributeName: sortedTypeAttributes[i].AttributeName,
                            ColumnName: sortedTypeAttributes[i].AttributeName.Name,
                            IsRequired: sortedTypeAttributes[i].IsRequired,
                            IsConstant: false,
                            IsMultipleAllowed: sortedTypeAttributes[i].IsMultipleAllowed,
                            UserVisible: userVisible,
                            JsonSchema: sortedTypeAttributes[i].JsonSchema,
                        });
                    })
                } else if (needAllAttributesOnType) {
                    newAttributesData.push({
                        Name: "PropertyName",
                        [newValueFieldName]: null,
                        IdDictionaryValue: null,
                        Id: undefined,
                        ShortDescription: null,
                        IdAttributeName: sortedTypeAttributes[i].AttributeName.Id,
                        IdAttributeType: sortedTypeAttributes[i].AttributeName.IdAttributeType,
                        AttributeName: sortedTypeAttributes[i].AttributeName,
                        ColumnName: sortedTypeAttributes[i].AttributeName.Name,
                        IsRequired: sortedTypeAttributes[i].IsRequired,
                        IsConstant: false,
                        IsMultipleAllowed: sortedTypeAttributes[i].IsMultipleAllowed,
                        UserVisible: userVisible,
                        JsonSchema: sortedTypeAttributes[i].JsonSchema,
                    });
                }
            }
        }

        return newAttributesData;
    }

    /*
    * Исключение атрибутов, которые в рассматриваемых типах документов отличаются значениями поля IsMultipleAllowed (Если атрибута у одного из типов нет - не исключаем)
    * @param attributes - итерируемые атрибуты
    * @param {number} firstTypeId - первый тип докмента
    * @param {number} secondTypeId - второй тип документа
     */
    static excludeAttributesDifferInMultiplyAllowed({attributes, firstTypeId, secondTypeId}) {
        const treeDocs = store.getState().document.tree.docs
        const oldTypeAttributes = treeDocs.find(lDocType => lDocType.Id === firstTypeId)?.TypeAttributes
        const newTypeAttributes = treeDocs.find(lDocType => lDocType.Id === secondTypeId)?.TypeAttributes
        if (oldTypeAttributes && newTypeAttributes) {
            return attributes.filter(attribute => {
                const oldIsMultipleAllowedValue = oldTypeAttributes.find(oldAttrType => oldAttrType.AttributeName.Id === attribute.AttributeName.Id)?.IsMultipleAllowed
                const newDocumentTypeAttributes = newTypeAttributes.find(newAttrType => newAttrType.AttributeName.Id === attribute.AttributeName.Id)?.IsMultipleAllowed
                return newDocumentTypeAttributes === undefined || oldIsMultipleAllowedValue === undefined
                    || (oldIsMultipleAllowedValue === newDocumentTypeAttributes)
            })
        } else {
            return []
        }
    }

    /*
     Заменить элемент в массиве используя неглубокую копию; Если индекс не существует или равен -1, то элемент прибавляется в конец
     */
    static replaceArrayElementByIndex = (array, index, newEl) => {
        if (index && index !== -1) {
            return [
                ...array.slice(0, index),
                newEl,
                ...array.slice(index + 1)
            ]
        } else {
            return [
                ...array,
                newEl
            ]
        }
    }

    static deleteDocFromLinkUsingActiveNode = (TableType) => {
        let query = store.getState().router.location.query;
        let queryLink = "";
        if(TableType === "main") {
            const activeNodeId = store.getState().packages.tree.activeNode.Node.Id
            queryLink = Routing.CreateRef({
                oldQuery : query,
                newQuery : {p :  activeNodeId},
                excludedKeys : ["d"]
            });
        } else if(TableType === "additional") {
            const activeNodeId = store.getState().packages.treeAdditional.activeNode.Node.Id
            queryLink = Routing.CreateRef({
                oldQuery : query,
                newQuery : {p2 : activeNodeId},
                excludedKeys : ["d2"]
            });
        } else if(TableType === "download") {
            const activeNodeId = store.getState().packages.treeDownload.activeNode.Node.Id
            queryLink = Routing.CreateRef({
                oldQuery : query,
                newQuery : {dwp :  activeNodeId},
                excludedKeys : ["dwd"]
            });
        }

        let link = `${store.getState().router.location.pathname}${queryLink}`;
        history.push(link);
    }
}


export async function uploadFilesForDoc(files, idDoc)  {
    for (const item of files) {
        let data = new FormData();
        data.append("description", item.description);
        data.append("upload", item.file, item.name);

        let response = await new apiRequestNew({
            action : apiUrl.DOCUMENTS.LOAD_FILE_FOR_CURRENT_DOC,
            endUrl : idDoc,
            headers : null,
            params : data
        }).isFileUpload(true).execute();

        if(!response.ok) {
            store.dispatch(setModalData({
                name : ModalTypes.app.alert,
                data : {content : "Во время изменения документа произошла ошибка: Загрузка новых файлов", disableButton : false}
            }));
            return;
        }
    }
}

export async function fetchVersionOptions(docId) {
    const data =  await API.documents().getAllVersions({
        filter : new Filter(FiltersType.ENTITY).add(
            new EntityFilters().setEntityName("DocVersion").add({
                Name: "IdDoc",
                Value: docId.toString()
            }).get()
        ).get(),
        sorting : null,
    });
    let lVersionOptions = [];
    if (data.errorCode) {
        throw new Error(data.message.Message)
    } else {
        for (let i in data.Records)
        {
            lVersionOptions.push({ value: data.Records[i].Id, label: "Версия "+data.Records[i].Version+" от "+__.dateToRussia(data.Records[i].VersionTime) })
        }
        return lVersionOptions;
    }
}

export const validateAttributeJSONByTable = (json) => {
    var Validator = require('jsonschema').Validator;
    var v = new Validator();

    var headerSchema = {
        "id": "/Header",
        "type": "object",
        "properties": {
            "renderValue": {"type": "string"},
            "value": {"type": "string"},
            "type": {"type": "string"},
            "id": {"type": "integer"},
            "order": {"type": "integer"},
            "isShow": {"type": "boolean"},
        },
        "required": ["renderValue", "value", "type", "id", "order", "isShow"]
    };

    var rowSchema = {
        "id": "/Row",
        "type": "object",
        "properties": {
            "renderValue": { "oneOf": [
                    {"type": "boolean"},
                    {"type": "integer"},
                    {"type": "string"}
                ]},
            "value": { "oneOf": [
                    {"type": "boolean"},
                    {"type": "integer"},
                    {"type": "string"}
                ]},
            "type": {"type": "string"},
            "idHeader": {"type": "integer"},
        },
        "required": ["renderValue", "value", "type", "idHeader"]
    };

    var schema = {
        "id": "/JsonTable",
        "type": "object",
        "properties": {
            "type": {"type": "string"},
            "shortDescription": {"type": "string"},
            "headers": {
                "type": "array",
                "items": {"$ref": "/Header"}
            },
            "rows": {
                "type": "array",
                "items": {
                    "properties": {
                        "id": {"type": "string"},
                        "cells": {
                            "type": "array",
                            "items": {"$ref": "/Row"}
                        }
                    },
                    "required": ["id", "cells"]
                }
            }
        },
        "required": ["type", "headers", "rows"]
    };

    v.addSchema(headerSchema, '/Header');
    v.addSchema(rowSchema, '/Row');
    const validResult = v.validate(JSON.parse(json), schema);

    return validResult;
}

export const validateAttributeJSONByTree = (jsonObject, callback) => {
    var Validator = require('jsonschema').Validator;
    var v = new Validator();

    const child = {
        "id": "/Child",
        "type": "object",
        "items": {
            "properties": {
                "renderValue": {
                    "oneOf": [
                        {"type": "boolean"},
                        {"type": "integer"},
                        {"type": "string"}
                    ]
                },
                "value": {"type": "string"},
                "type": {"type": "string"},
                "id": {"type": "integer"},
                "children": {
                    "type": "array",
                    "items": {"$ref": "/Child"}
                },
            },
            "required": ["renderValue", "value", "type", "id"]
        },

    };
    const schema = {
        "id": "/JsonTable",
        "type": "object",
        "properties": {
            "type": {"type": "string"},
            "shortDescription": {"type": "string"},
            "root": {"$ref": "/Child"}
        },
    }

    v.addSchema(child, '/Child');
    const validResult = v.validate(JSON.parse(jsonObject), schema);

    return validResult
}
