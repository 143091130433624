import React from "react";
import {connect} from "react-redux";
import "moment/locale/ru";
import {createUniqueIdString} from "../../tools/CreateUniqueId";
import {
    ModalTypes,
} from "../../tools/StaticTypes";
import {API} from "../../tools/API_NEW/API";
import {history, store} from "../../index";

import {setLoaderModalData, setModalData} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {apiRequest} from "../../tools/API/apiRequest";
import {apiUrl} from "../../tools/API/apiUrl";
import {EntityFilters, Filter, FiltersType, Sorting} from "../../tools/Filters";
import {__} from "../../tools/HelpFunctions";
import {DOCUMENT_VERIFY} from "../../store/documents/documentActionsList";


class HistoryDocuments extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            historyDocs: [],
            RecordsDoc: []
        };

        this.goToDocuments = this.goToDocuments.bind(this);

    }


    async componentDidMount() {
        let documentHistory = this.props.userSettings.find((userSetting => userSetting.Name === "documentConfig"));
        let allDocs = [];

        for (let i = 0; i < this.props.allFilials.length; i++) {
            let testArrDoc = [];

            if (documentHistory) {
                for (let j = 0; j < documentHistory.Value.length; j++) {
                    if (documentHistory.Value[j].filial === this.props.allFilials[i].Id) {
                        testArrDoc.push(documentHistory.Value[j].IdDoc)
                    }
                }
            }
            if (testArrDoc.length > 0) {
                let requestDoc = await new apiRequest(apiUrl.DOCUMENTS.DOC_COLLECTION, {
                    filter: new Filter(FiltersType.ENTITY)
                        .add(
                            new EntityFilters().setEntityName("DocExtended")
                                .add({Name: "Id", Value: testArrDoc, Operator: 8})
                                .add({Name: "IdFilial", Value: this.props.allFilials[i].Id, Operator: 8})
                                .get()
                        ).get(),
                    sorting: new Sorting()
                        .addAttribute({Name: "IdAttributeName", Value: "-1"})
                        .add({PropertyName: "RegDate", Direction: 2})
                        .add({PropertyName: "RegNumber", Direction: 1})
                        .add({PropertyName: "Id", Direction: 1})
                        .get()
                })
                await requestDoc.execute((data) => {
                    data.Records.map(item => allDocs.push(item));
                });
            }

            if (allDocs.length > 0) {
                this.setState({RecordsDoc: allDocs});
            }
        }
    }

    async buildLink(item) {

        //Задача № 22235
        let vFlagVerify = false;
        let docRefInfo = null;
        //Если документ уже верифицирован, то не делаем запрос
        if (this.props.verifyDocs)
        {
            if (this.props.verifyDocs[item.IdDoc]) {
                vFlagVerify = true;
                docRefInfo = this.props.verifyDocs[item.IdDoc][0];
            }
        }

        if (!vFlagVerify) {
            docRefInfo = await API.search().verifyDocClientLinkIds({
                linkIds: {
                    IdDoc: item.IdDoc,
                    IdFilial: item.IdFilial ?? item.filial
                }
            });
            // сохраняем в редакс
            store.dispatch({
                type: DOCUMENT_VERIFY.UPDATE_VERIFY_DOCS, payload: {
                    id: item.IdDoc,
                    data: docRefInfo
                }
            });
        }

        // let docRefInfo = await API.search().verifyDocClientLinkIds({
        //     linkIds: {
        //         IdDoc: item.IdDoc,
        //         IdFilial: item.IdFilial ?? item.filial
        //     }
        // });

        if (docRefInfo.errorCode) {
            if (docRefInfo.message) {
                if (docRefInfo.message.Message) {
                    store.dispatch(setModalData({
                        name: ModalTypes.app.info,
                        data: {
                            content: docRefInfo.message.Message,
                            disableButton: false,
                            type: "fail"
                        }
                    }));
                    return;
                }
            }
        }
        else {
            let link = `/documents`;
            let postfix = "";
            if (docRefInfo.IdDocGroup)
                postfix += `?g=${docRefInfo.IdDocGroup}`;
            if (docRefInfo.IdDocType)
                if (postfix != "")
                    postfix += `&t=${docRefInfo.IdDocType}`
                else
                    postfix += `?t=${docRefInfo.IdDocType}`;
            if (docRefInfo.IdDoc)
                if (postfix != "")
                    postfix += `&d=${docRefInfo.IdDoc}`;
                else
                    postfix += `?d=${docRefInfo.IdDoc}`;
            link += postfix;
            if (item.filial !== this.props.activeFilialId) {
                link = link.concat(`&f=${item.filial}`);
            }
            history.replace(link);
        }
    }

    async goToDocuments(item) {
        //Задача № 22235
        // store.dispatch(setModalData({
        //     name : ModalTypes.app.alert,
        //     data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "documents"}
        // }));

        //Если прелоадер завис!

        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "documents", key : "HistoryDocuments168" }
        }));

        if(item.filial != this.props.activeFilialId) {
            let filialName = this.props.allFilials.find(filial => filial.Id == item.filial).Name;
            // todo tp № 23171
            store.dispatch(setLoaderModalData({
                keyDeleted : "HistoryDocuments167"
            }));
            this.props.setModalData({
                name : ModalTypes.app.info,
                data : {
                    type: "question",
                    content : `Данный документ был открыт в другом ${store.getState().globalState.settings.Content?.FilialCaption ?? 'филиале'}: <b>${filialName}</b> <br />Сменить ${store.getState().globalState.settings.Content?.FilialCaption ?? 'филиал'} и перейти к документу?`,
                    disableButton : false,
                    fullBackground : false,
                    onClickContinue: async () => {await this.buildLink(item)}}
            });
            return;
        }

        await this.buildLink(item);

        //Не закрывался прелоадер
        store.dispatch(setLoaderModalData({
            keyDeleted : "HistoryDocuments180"
        }));
    }



    render() {
        let documentHistory = __.deepCopy(this.props.userSettings.find((userSetting => userSetting.Name === "documentConfig")));

        if (documentHistory) {
            documentHistory.Value.map(item => {
                const buf = this.state.RecordsDoc.find(doc => doc.Id === item.IdDoc);
                if(buf) {
                    item.Name = buf.Name;
                    //Задача № 25048
                    if (buf.ShortDescription)
                        item.Name += " " + buf.ShortDescription;
                }
                return item
            })
        }

        return (

            <div className="row col-lg-12">
                {
                    documentHistory?.Value &&
                    <div className="col-lg-12">
                        <div className="card card-custom gutter-b">


                            <div className="card-header">
                                <div className="card-title">
                                    <h3 className="card-label">Последние документы</h3>
                                </div>
                            </div>


                            <div className="card-body">

                                <div className="row">
                                    {
                                        documentHistory?.Value &&
                                        documentHistory.Value.map((item, index) => {

                                            let iconColorStyle = {};
                                            if(item.filial != this.props.activeFilialId) {
                                                iconColorStyle = {
                                                    opacity : "50%"
                                                }
                                            }

                                            return (
                                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6"
                                                     title={item.Name}
                                                     key={createUniqueIdString(6)}>
                                                    <div className="packet packet-custom packet-stretch pb-4">
                                                        <span className="link" onClick={() => {this.goToDocuments(item)}}>
                                                            <div className="packet-body text-center pt-1">
                                                                <div className="my-2">
                                                                    <div className="symbol">
                                                                        <span className="svg-icon menu-icon icon-xl" style={iconColorStyle}>
                                                                            <i className={`icon-Documents icon-color-primary`}
                                                                               style={{fontSize: "4rem"}}/>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="my-1">
                                                                    <p className="text-dark font-weight-bold">{item.Name}</p>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        })

                                    }
                                </div>


                            </div>
                        </div>
                    </div>
                }



            </div>

        );
    }
}


const mapStateToProps = state => {
    return {
        activeFilialId: state.globalState.filial.Active.Id,
        allFilials : state.globalState.filial.All,
        userSettings: state.globalState.userSettings,
        verifyDocs: state.document.verifyDocs,
    }
}

const mapDispatchToProps = {
    setModalData
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryDocuments);
