import React from "react";
import {connect} from "react-redux";
import {attributesSectionsTypes} from "../../../tools/StaticTypes";
import {toggleAdditionalSectionsOpen_Cards} from "../../../store/documents/actionCreators/document_PreviewActionCreator";
import {__} from "../../../tools/HelpFunctions";
import {toggleAdditionalSectionsOpenSearch} from "../../../store/search/actionCreators/search_PreviewActionCreator";
import parse from "html-react-parser";
import {searchByParams, updateInputsFieldSearch} from "../../../store/search/actionCreators/search_Inputs";
import {combineMainTableAttributesSearch} from "../../../store/search/actionCreators/search_table";
import sanitizeHtml from "sanitize-html";
import {DataTypesEnum} from "../../../tools/API_NEW/ServerTypes";
import {setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import DocRef from "../docRefsComponents/DocRef";
import DocVersionRef from "../docRefsComponents/DocVersionRef";
import MultipleDocRef from "../docRefsComponents/MultipleDocRef";
import MultipleDocVersionRef from "../docRefsComponents/MultipleDocVersionRef";
import JsonObjectFragment from "../../root/JsonObjectFragment";

const getIdAttributeTypeByItem = (item) => {
    return item.IdAttributeType || item.AttributeName.IdAttributeType
}

class AttributesSectionSearch extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            calendarShow : false,
            calendarItem : null,
            calendarIsHover : false,
        };

        this.sectionName = attributesSectionsTypes.attributesSection;

        this.toggle = this.toggle.bind(this);
        this.search = this.search.bind(this);

        this.renderJsonObjectFragment = this.renderJsonObjectFragment.bind(this);
    }
    // открытье\закрытие раздела
    toggle() {
        if(this.props.viewMode === "normal") {
            this.props.toggleAdditionalSectionsOpenSearch({
                Name : this.sectionName
            });
        } else if (this.props.viewMode === "card") {
            this.props.toggleAdditionalSectionsOpen_Cards({
                Name : this.sectionName
            });
        }
    }

    search(value) {
        //TODO SS временно
        if (Array.isArray(value)){
            value = value.map(el=>`"${el.split('"').join('').split("'").join('')}"`).join(',')
        } else if (typeof value !== 'string') {
            return;
        } else {
            value = `"${value.split('"').join('').split("'").join('')}"`
        }

        let searchValue = parse(sanitizeHtml(value));
        let searchValueResult="";
        for(let i = 0; i < searchValue.length; i++) {
            if((typeof searchValue[i]) === "string"){
                searchValueResult = searchValueResult + searchValue[i];
            }else {
                searchValueResult = searchValueResult + searchValue[i].props.children
            }
        }

        const idSearch = __.createUniqueIdString(6);

        this.props.updateInputsFieldSearch({
            AttributesText : searchValueResult,
            TypeIds: [],
            Id: idSearch,
            Next : {
                action : () => {
                    this.props.searchByParams({
                        Id: idSearch,
                        Next:{
                            action : () => {
                                this.props.combineMainTableAttributesSearch({})
                            },
                            params : {}
                        }
                    })
                },
                params : {}
            }
        });
    }

    renderJsonObjectFragment = (item, rowId, IsMultipleAllowed) => {
        let lInputValue = item.Value
        if (typeof lInputValue === 'string') {
            const regex = /( |<([^>]+)>)/ig;
            lInputValue = lInputValue.replace(regex, "");
        }
        if (rowId) {
            if (item.Value && item.Value.length > 0 && (rowId - 1) >= 0 && (rowId - 1) < item.Value.length) {
                lInputValue = item.Value[rowId - 1];
            } else {
                lInputValue = null;
            }
        }
        return <JsonObjectFragment lInputValue={lInputValue}
                                   lAllInputValue={item.Value}
                                   isRedactorMode={false}
                                   rowId={rowId}
                                   item={item}
                                   IsMultipleAllowed
                                   // inputHandler={(value, item) => this.editHandler(IsMultipleAllowed ? {value} : value, item, IsMultipleAllowed)}
        />
    }

    render() {
        const {activeTab, searchResults} = this.props;
        const activeResult = searchResults.find(res => res.Id === activeTab.Id)
        const additionalSectionsData = activeResult?.additionalSections;
        const sectionData = additionalSectionsData.state[this.sectionName];
        const isReady = additionalSectionsData.isReady;

        let cardTitleClass = "card-title";
        let cardCollapseClass = "collapse show";
        if(!sectionData.isOpen) {
            cardTitleClass = "card-title collapsed";
            cardCollapseClass = "collapse";
        }
        const attributes = __.convertAttributesArrayToUnique(sectionData.attributes, 'Value')

        return (
            <div className="card">
                <div className="card-header" onClick={this.toggle}>
                    <div className={cardTitleClass}>
                        Атрибуты документа
                    </div>
                </div>
                <div className={cardCollapseClass}>
                    <div className="card-body p-0">
                        <table className="table table-bordered table-striped m-0">
                            <thead>
                            <tr>
                                <th className="col-lg-4">Атрибут</th>
                                <th className="col-lg-7">Значение</th>
                                {!this.props.redactorMode && <th className="col-lg-1">Найти</th>}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                isReady &&
                                attributes.map((item, index) => {
                                    const IsMultipleAllowed = item.IsMultipleAllowed
                                    const valueForSanitize = (item.Value === "null") ? null : item.Value;
                                    let secondTdContent= parse(sanitizeHtml(valueForSanitize, {allowedAttributes: {span: ["class", "className"]}}));
                                    const attributeType = getIdAttributeTypeByItem(item)
                                    if (attributeType === DataTypesEnum.JsonObject){
                                        const correctValue = !IsMultipleAllowed ? item.Value : __.getCorrectValueForMultiply(item.Value)
                                        secondTdContent = (IsMultipleAllowed && correctValue) ?
                                            (
                                                < div
                                                    className="d-flex flex-column beautiful-scroll-10"
                                                    style={{
                                                            maxHeight: '5rem',
                                                            paddingRight: correctValue.length > 2 ? '0.75rem' : 0
                                                    }}
                                                >
                                                    {correctValue.map((lValue, lIndex) => {
                                                        return (
                                                            <div
                                                                className={`${lIndex !== correctValue.length - 1 ? 'mb-3' : ''}`}>
                                                                {this.renderJsonObjectFragment({
                                                                    ...item,
                                                                    Value: lValue
                                                                }, null, IsMultipleAllowed)}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            ) : this.renderJsonObjectFragment(item, null, IsMultipleAllowed)
                                    }
                                    if (attributeType === DataTypesEnum.DocRef) {
                                        secondTdContent = IsMultipleAllowed ?
                                            <MultipleDocRef
                                                item={item}
                                                valueFieldName={'Value'}
                                                viewMode={this.props.viewMode}
                                                isRedactorMode={false}
                                            />
                                            : <DocRef
                                                viewMode={this.props.viewMode}
                                                docIdValue={item.Value}
                                                shortDescription={item.ShortDescription}
                                                isRedactorMode={false}
                                            />
                                    }
                                    if (attributeType === DataTypesEnum.DocVersionRef) {
                                        secondTdContent = IsMultipleAllowed ?
                                            <MultipleDocVersionRef
                                                item={item}
                                                valueFieldName={'Value'}
                                                viewMode={this.props.viewMode}
                                                isRedactorMode={false}
                                            />
                                            : <DocVersionRef
                                                viewMode={this.props.viewMode}
                                                versionIdValue={item.Value}
                                                shortDescription={item.ShortDescription}
                                                isRedactorMode={false}
                                            />
                                    }
                                    return (
                                        <tr key={item.ColumnName}>
                                            <td>{item.ColumnName}</td>
                                            {/*
                                        ///TODO если использовать sanitizeHtml то не будет подсветки но если приложение будет падать вернуть sanitizeHtml Задача № 23042
                                        <td>{parse(sanitizeHtml(value))}</td>
                                        */}
                                            <td>{secondTdContent}</td>
                                            {/*<td>{parse(value)}</td>*/}
                                            <td>
                                                {
                                                    (
                                                        item.Value !== "null"
                                                        && item.Value !== null
                                                        && item.Value !== ''
                                                        && attributeType !== DataTypesEnum.JsonObject
                                                        && attributeType !== DataTypesEnum.DocRef
                                                        && attributeType !== DataTypesEnum.DocVersionRef
                                                    ) &&
                                                    <span
                                                        className="btn btn-icon btn-outline-success btn-circle btn-xs"
                                                        onClick={() => {
                                                            this.search(item.Value)
                                                        }}
                                                    >
                                                <i className="svg-icon icon-Search"/>
                                            </span>
                                                }
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        searchResults: state.search.searchState.searchResults,
        activeTab: state.search.rootState.activeTab
    }
}

const  mapDispatchToProps = {
    combineMainTableAttributesSearch,
    searchByParams,
    toggleAdditionalSectionsOpenSearch,
    toggleAdditionalSectionsOpen_Cards,
    updateInputsFieldSearch,
    setModalData,
}

export default connect(mapStateToProps, mapDispatchToProps)(AttributesSectionSearch);
