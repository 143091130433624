import React from "react";
import {connect} from "react-redux";
import {contextMenuTypes, ModalTypes, RootComponentsStateViewModeTypes} from "../../../tools/StaticTypes";
import {
    setContextMenuData, setLoaderModalData, setModalData,
    setStateLeftAsideMenu
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {
    createDocumentCards,
} from "../../../store/documents/actionCreators/document_PreviewActionCreator";
import {changeDocumentSearchInputs, changeDocumentSearchInputsContextMenu, changeViewMode} from "../../../store/documents/actionCreators/document_SearchActionCreator";
import {combineMainTableAttributes, mainTableSearchDoc} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {changeDocumentViewMode} from "../../../store/documents/actionCreators/document_RootStateActionCreator";
import {store} from "../../../index";

class ContextMenuMainTableTh extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            scrollY : window.scrollY, // TODO: пока оставил позицию окна при контексте но если не будем юзать -  удалить
            scrollX : window.scrollX,
            isHover : false
        };

        this.name = contextMenuTypes.MainTableTR;


        this.copy = this.copy.bind(this);
        this.addDocCard = this.addDocCard.bind(this);
        this.search = this.search.bind(this);
    }

    componentDidMount() {
        document.onscroll = (event) => { // скрытие контекстного меню при скроле документа
            if(this.props.contextMenu.name === contextMenuTypes.MainTableTH) {
                this.props.setContextMenuData({});
            }
        };
        document.onclick = (event) => {
            if(!this.state.isHover) {
                this.props.setContextMenuData({});
            }
        };
    }

    // отписываемся от событий при удалении элемента
    componentWillUnmount() {
        document.onscroll = null;
        document.onclick = null;
    }

    copy() {
        let copytext = document.createElement('input');
        copytext.value = this.props.contextMenu.data.item.Value;
        document.body.appendChild(copytext);
        copytext.select();
        document.execCommand('copy');
        document.body.removeChild(copytext);

        this.props.setModalData({
            name : ModalTypes.app.alert,
            data : {content: `Содержимое скопировано`, disableButton: true}
        });

        setTimeout(() => {
            this.props.setModalData({});
            // //Новый прелоадер
            // this.props.setLoaderModalData({keyDeleted: "ContextMainTableTh69"});
        }, 500);

        this.props.setContextMenuData({});
    }



    addDocCard() { // TODO: ломается на правой кнопку при редактировании
        this.props.createDocumentCards({
            IdDoc : this.props.contextMenu.data.tr.Version.IdDoc,
            IdVersion : this.props.contextMenu.data.tr.Version.Id,
            isRightClick : true
        });
        this.props.setContextMenuData({});
    }

    async search(event) {
        // this.props.setModalData({
        //     name : ModalTypes.app.alert,
        //     data : {content : "Загрузка документов...", disableButton : true, fullBackground : false, gif : "documents"}
        // })
        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка документов...", disableButton : true, fullBackground : false, gif : "documents", key : "ContextMainTableTh94" }
        }));

        this.props.changeDocumentViewMode({
            ViewMode : RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly
        });

        let Direction="";
        if (event.target.className.indexOf("up") !== -1) {
            Direction = "icon-Filter_up";
        } else {
            Direction = "icon-Filter_down";
        }
        await this.props.mainTableSearchDoc({
            FieldSorting: this.props.contextMenu.data.th,
            Direction: Direction,
            Next: {
                action: () => {
                    this.props.changeDocumentSearchInputs({
                        TH: true,
                        Direction: Direction,
                        Attribute: this.props.contextMenu.data.th,
                        Value: "",
                        isConstant: this.props.contextMenu.data.th.IsConstant,
                        Next: {
                            action: () => {
                                this.props.combineMainTableAttributes({
                                    Next :{
                                        action: () => {
                                            this.props.setContextMenuData({Next :{
                                                    action: () => {
                                                        // this.props.setModalData({});
                                                        //Новый прелоадер
                                                        this.props.setLoaderModalData({keyDeleted: "ContextMainTableTh122"});
                                                    }
                                                }});
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
            }
        });
        //TODO вернуть таймаут, в случае некорректной работы
        // setTimeout(async ()=>{
        // await this.props.mainTableSearchDoc({
        //     FieldSorting: this.props.contextMenu.data.th,
        //     Direction: Direction
        // });
        //
        // await this.props.changeDocumentSearchInputs({
        //     TH: true,
        //     Direction: Direction,
        //     Attribute: this.props.contextMenu.data.th,
        //     Value: "",
        //     isConstant: this.props.contextMenu.data.th.IsConstant
        // });
        //
        // await this.props.combineMainTableAttributes({});
        //}, 500);
        // this.props.setContextMenuData({});
    }


    render() {

        let contextStyle = {
            position : "fixed",
            top :  this.props.contextMenu.position.y,
            left :  this.props.contextMenu.position.x
        };


        return (
            <nav id="context-menu" className="context-menu" style={contextStyle} onContextMenu={(event) => {event.preventDefault()}}
                 onScroll={()=>{ this.props.setContextMenuData({})}}
                 onMouseEnter={(event) => {this.setState({isHover : true})}}
                 onMouseLeave={(event) => {this.setState({isHover : false})}}>
                <ul className="context-menu__items">
                    <li className="context-menu__item" onClick={(event) => {this.search(event)}} style={{cursor : "pointer"}}>
                        <span className="context-menu__link up">
                            <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-3">
                                <i className="svg-icon svg-icon-lg icon-color-gray icon-Filter_up"> </i>
                            </span>
                            Сортировка А-Я
                        </span>
                    </li>
                    <li className="context-menu__item" onClick={(event) => {this.search(event)}} style={{cursor : "pointer"}}>
                        <span className="context-menu__link down">
                            <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-3">
                                <i className="svg-icon svg-icon-lg icon-color-gray icon-Filter_down"> </i>
                            </span>
                            Сортировка Я-А
                        </span>
                    </li>
                </ul>
            </nav>
        );
    }
}

const  mapStateToProps = state => {
    return {
        contextMenu :  state.globalState.app.contextMenu,
    }
}

const  mapDispatchToProps = {
    setContextMenuData,
    changeDocumentSearchInputsContextMenu,
    createDocumentCards,
    mainTableSearchDoc,
    combineMainTableAttributes,
    changeViewMode,
    changeDocumentSearchInputs,
    setStateLeftAsideMenu,
    changeDocumentViewMode,
    setModalData,
    setLoaderModalData
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenuMainTableTh);
export {ContextMenuMainTableTh}

