import React from "react";
import {__, HelpFunctions} from "../../../tools/HelpFunctions";
import {connect} from "react-redux";
import {setVersionIdForLocate} from "../../../store/masterApp/actionCreator/masterAppActionCreator";
import {contextMenuTypes, DataTypes} from "../../../tools/StaticTypes";
import {
    setContextMenuData,
    setStateLeftAsideMenu
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";

class Preloader extends React.Component {
    render () {
        return(
            <div className={"d-flex align-items-center justify-content-center"}
             style={{height: '2.5rem'}}
        >
            <div className="spinner-border icon-color-primary" style={{
                width: '1.25rem',
                height: '1.25rem',
            }} role="status"/>
        </div>
        )
    }
}

const getRefIconColorCN = (refIsWork, hasError) => {
    if (hasError) {
        if (refIsWork) {
            return 'icon-color-red'
        } else {
            return 'icon-color-red opacity-60'
        }
    } else if (!refIsWork) {
        return 'icon-color-grey'
    } else {
        return 'icon-color-primary'
    }
}

/*
    // TODO КН 01.02.24 Для компоненты указан React.memo() с помощью React.PureComponent!
    // TODO КН 01.02.24 param* - обязательный атрибут
    @param {string} viewMode
    @param* {any} docIdValue
    @param(*forNotRedactorMode) {any} shortDescription - shortDescription атрибута (то что используется в качестве строки для отображения в неРедактор моде)
    @param* {boolean} isRedactorMode
    @param {boolean} hardDisabledEditing - disable редактирования при isRedactorMode
    @param(*forRedactorMode) setCurrentDocIdValue(docIdValue) - функция из родятеля которая сетает versionValue куда ему нужно
 */
class DocRef extends React.PureComponent  {
    constructor(props) {
        super(props)

        this.state = {
            docIdValue: this.props.docIdValue,
            docRefInfo: null, // В редактор моде влюбом случае делаем проверку док-та CheckDocumentExistOrAccess, и чтобы потом при клике на кнопку перехода к документу не запрашивать эту инфу еще раз,храним её в стейте

            docDataIsLoading: false,

            inputDocIdDebounceAnchor: null,
            errorMessages: [],
        }

        this.changeDocIdInput = this.changeDocIdInput.bind(this)
        this.getDocRefInfo = this.getDocRefInfo.bind(this)
        this.updateDocDataByDocIdValue = this.updateDocDataByDocIdValue.bind(this)
        this.goToDocument = this.goToDocument.bind(this)
        this.onContextMenuHandler = this.onContextMenuHandler.bind(this)
        this.handleErrorMessagesField = this.handleErrorMessagesField.bind(this)
    }

    async componentDidMount() {
        if (this.props.isRedactorMode) {
            this.setState({docIdValue: this.props.docIdValue})
            await this.updateDocDataByDocIdValue({
                docIdValue: this.props.docIdValue,
                isMounting: true,
                isRefreshByNoShortDescription: false
            })
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        // Если редактор мод был выключен и стал включен
        if (!prevProps.isRedactorMode && this.props.isRedactorMode) {
            if (this.state.docIdValue !== this.props.docIdValue) {
                this.setState({docIdValue: this.props.docIdValue})
            }
            await this.updateDocDataByDocIdValue({
                docIdValue: this.props.docIdValue,
                isMounting: false,
                isRefreshByNoShortDescription: false
            })
        } else if (
            prevState.inputDocIdDebounceAnchor === this.state.inputDocIdDebounceAnchor
            && this.state.docIdValue?.toString() === prevState.docIdValue?.toString()
            && this.props.docIdValue?.toString() !== this.state.docIdValue?.toString()
        ) {
            this.setState({docIdValue: this.props.docIdValue})
            if (this.props.isRedactorMode) {
                await this.updateDocDataByDocIdValue({
                    docIdValue: this.props.docIdValue,
                    isMounting: false,
                    isRefreshByNoShortDescription: false
                })
            } else {
                this.setState({docRefInfo: null})
            }
        }
    }

    /*
        @param* {'add' | 'remove'} action
        @param* {any} errorId
        @param {string} errorMessage
     */
    handleErrorMessagesField(action, errorId, errorMessage = '') {
        const currentErrorMessages = this.state.errorMessages
        let newErrorMessagesValue;
        if (action === 'add') {
            const errorObj = {
                id: errorId,
                message: errorMessage,
            }
            const foundIndex = currentErrorMessages.findIndex(el=>el.id === errorId)
            if (foundIndex !== -1) {
                newErrorMessagesValue = [
                    ...currentErrorMessages.slice(0, foundIndex),
                    errorObj,
                    ...currentErrorMessages.slice(foundIndex+1),
                ]
            } else {
                newErrorMessagesValue = [...currentErrorMessages, errorObj]
            }
        } else if (action === 'remove') {
            const foundIndex = currentErrorMessages.findIndex(el=>el.id === errorId)
            if (foundIndex !== -1) {
                newErrorMessagesValue = [
                    ...currentErrorMessages.slice(0, foundIndex),
                    ...currentErrorMessages.slice(foundIndex+1),
                ]
            }
        }
        newErrorMessagesValue && this.setState({
            errorMessages: newErrorMessagesValue,
        })
    }

    async changeDocIdInput(event) {
        let docIdValue = event.target.value;
        if (docIdValue.indexOf("d=") != -1) {
            if (HelpFunctions.isValidURL(docIdValue)) {
                const url = new URL(docIdValue);
                docIdValue = url.searchParams.get("d");
            }
        }
        this.setState({docIdValue: docIdValue}, ()=> {
            this.props.setCurrentDocIdValue && this.props.setCurrentDocIdValue(docIdValue)
            clearTimeout(this.state.inputDocIdDebounceAnchor)
            const timeout = setTimeout( async () => {
                await this.updateDocDataByDocIdValue({
                    docIdValue: docIdValue,
                    isMounting: false,
                    isRefreshByNoShortDescription: false
                });
                this.setState({inputDocIdDebounceAnchor: null});
            }, 800)
            this.setState({inputDocIdDebounceAnchor: timeout})
        })
    }

    // TODO КН 08.04.24 Меняя что то здесь, нужно менять и в DocVersionRef в аналогичной ф-ии
    // TODO КН 08.04.24 Выделил в функцию чтобы было по аналогии с DocVersionRef
    async getDocRefInfo(docIdValue, needErrorModal) {
        let docRefInfo = null;
        this.setState({docDataIsLoading: true})
        if (docIdValue !== null && !(isNaN(docIdValue))) {
            this.handleErrorMessagesField('remove', 'docRefInfoRequest')
            docRefInfo = await __.CheckDocumentExistOrAccess(Number(docIdValue), !needErrorModal, true)
            if (docRefInfo?.errorMessage) {
                this.handleErrorMessagesField('add', 'docRefInfoRequest', docRefInfo.errorMessage)
                docRefInfo = null;
            }
        }
        this.setState({docDataIsLoading: false})
        return docRefInfo;
    }

    // TODO КН 14.03.24 Зачем нужен флаг isMounting: нужно сделать так, чтобы модалки с ошбиками серверными выводились только при непосредствееннно редактировании. Т.е. при входе в редактор мод, нужно при ошибках делать иконку красной с тайтлом ошибки, а вот если ты уже начал изменять поле то уже высвечивать модалку соотв. ; Заточился этим флагом на то, что при переходе в режим редактирования компонент вмонтируется заново
    async updateDocDataByDocIdValue({docIdValue, isMounting, isRefreshByNoShortDescription}) {
        let newDocRefInfo = await this.getDocRefInfo(docIdValue, this.props.isRedactorMode ? !isMounting : false)
        if (newDocRefInfo && isRefreshByNoShortDescription) {
            newDocRefInfo = null
            this.handleErrorMessagesField('add', 'docRefInfoRequest', 'Неизвестная ошибка')
        }
        this.setState({docRefInfo: newDocRefInfo})
    }

    // Для перехода нужен docId {number} ИЛИ, если есть, уже готовый docRefInfo
    async goToDocument({docId = null, docRefInfo = null, isRedactorMod}) {
        let confirm = true;
        if (isRedactorMod) {
            confirm = window.confirm("Возможно имеются несохраненные данные. Продолжить?")
        }
        if (confirm) {
            const viewMode = this.props?.viewMode
            const successAdditionalLogic = () => {
                if (viewMode === "card") {
                    this.props.setStateLeftAsideMenu({
                        isClose: false,
                        rootName: "DocumentApp"
                    })
                }
            }
            if (docRefInfo) {
                __.goToDocumentsByDocRefInfo({
                    docRefInfo,
                    successAdditionalLogic
                })
            } else {
                await __.goToDocumentsByDocId({
                    docId,
                    successAdditionalLogic,
                })
            }
        }
    }

    // Для перехода нужен docId {number} ИЛИ, если есть, уже готовый docRefInfo
    onContextMenuHandler({event, docId, docRefInfo}) {
        this.props.setContextMenuData({
            name : contextMenuTypes.DocRef,
            data : {
                docId: docId,
                docRefInfo: docRefInfo,
            },
            position : {
                x : event.clientX,
                y : event.clientY
            }
        });
    }

    render() {
        const {docIdValue, docRefInfo, errorMessages, docDataIsLoading} = this.state;
        let refIsWork, iconColorCN;
        let error = errorMessages.length === 0 ? null : errorMessages.map(el=>el.message).join('; ')
        if (this.props.isRedactorMode) {
            refIsWork = !this.state.inputDocIdDebounceAnchor && docIdValue !== null && !isNaN(docIdValue) && docRefInfo;
            iconColorCN = getRefIconColorCN(refIsWork, error)
            return (
                <div className="input-icon">
                    <input className="form-control"
                           type={__.inputTypeByDataTypeId(DataTypes.DocRef)}
                           value={docIdValue ?? ''}
                           onChange={this.changeDocIdInput}
                           disabled={this.props.hardDisabledEditing || docDataIsLoading}
                    />
                    {docDataIsLoading ?
                        <span className={`svg-icon svg-icon-lg`}>
                            <Preloader/>
                        </span>
                        : <span
                            className={`svg-icon svg-icon-lg ${(refIsWork) ? 'cursor-pointer' : ''}`}
                            onClick={()=> {refIsWork && this.goToDocument({docRefInfo: docRefInfo, isRedactorMod: true})}}
                            title={'Перейти в реестр'}
                        >
                            <i title={error} className={`svg-icon icon-left_window ${iconColorCN}`}/>
                        </span>
                    }
                </div>
            )
        } else {
            if (docDataIsLoading) {
                return <Preloader/>
            }
            refIsWork = docIdValue !== null && !isNaN(docIdValue);
            // Если error нет, но нет shortDescription - отобразить ошибку
            if (!error) {
                error = (refIsWork && !this.props.shortDescription) ? 'noShortDescriptionError' : null
            }
            iconColorCN = getRefIconColorCN(refIsWork, error)
            const renderString = this.props.shortDescription || docIdValue || ''
            return (
                <div className={'d-flex align-items-center justify-content-center flex-grow-1'}>
                    <div className={`${refIsWork ? 'cursor-pointer ' : ''}d-flex align-items-center justify-content-center flex-grow-1`}
                         style={{
                             backgroundColor: 'var(--light)',
                             borderRadius: '0.42rem', padding: '0 1rem', borderWidth: 0, height: '2.5rem'
                         }}
                         title={renderString}
                         onClick={() =>
                             refIsWork && this.goToDocument({
                                 docId: Number(docIdValue),
                                 // TODO КН 08.04.24 Думаю может быть такое, что docRefInfo лежит в стейте (напр. после выхода из редактор мода, если получится проаапдейтить эту компоненту, а не удалить и создать новую)
                                 docRefInfo: docRefInfo,
                                 isRedactorMod: false
                             })
                         }
                         onContextMenu={(event) => {
                             event.preventDefault();
                             event.stopPropagation();
                             refIsWork && this.onContextMenuHandler({
                                 event,
                                 // TODO КН 08.04.24 Думаю может быть такое, что docRefInfo лежит в стейте (напр. после выхода из редактор мода, если получится проаапдейтить эту компоненту, а не удалить и создать новую)
                                 docRefInfo: docRefInfo,
                                 docId: Number(docIdValue)
                             })
                         }}
                    >
                        <i
                            title={error === 'noShortDescriptionError' ? null : error}
                            className={`icon-Documents ${iconColorCN}`}
                            style={{fontSize: '1.5rem'}}
                        />
                        {renderString &&
                            <span
                                className="ml-3 overflow-hidden text-overflow-ellipsis"
                                style={{
                                    maxWidth: '15rem',
                                    textWrap: 'nowrap',
                                }}
                            >
                            {renderString}
                            </span>
                        }
                    </div>
                    {error &&
                        <i
                            title={'Повторить запрос'}
                            onClick={() => this.updateDocDataByDocIdValue({
                                docIdValue: this.props.docIdValue,
                                isMounting: false,
                                isRefreshByNoShortDescription: error === 'noShortDescriptionError'
                            })}
                            className={`icon-refresh_16 icon-color-primary ml-3 cursor-pointer`}
                            style={{fontSize: '1.5rem'}}
                        />
                    }
                </div>
            )
        }
    }
}

const mapDispatchToProps = {
    setVersionIdForLocate,
    setStateLeftAsideMenu,
    setContextMenuData,
}

export default connect(null, mapDispatchToProps)(DocRef)
