import React from "react";
import {connect} from "react-redux";
import Iframe from 'react-iframe'
import {Dropdown} from "react-bootstrap";
import {HelpFunctions} from "../../tools/HelpFunctions";
import {
    changeVersionDocumentCards,
    createRedactorData,
} from "../../store/documents/actionCreators/document_PreviewActionCreator";
import {selectViewActiveFileForPreview, setFilesContent} from "../../store/search/actionCreators/search_PreviewActionCreator";
import {
    ModalTypes,
    RootComponentsStateViewModeTypes as rootStateType,
    RootComponentsStateViewModeTypes
} from "../../tools/StaticTypes";
import {MyTooltip} from "../overPage/tooltip/MyTooltip";
import {
    setLoaderModalData,
    setModalData,
    setStateLeftAsideMenu
} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {selectActiveFileForPreviewSearch, selectActiveFileForPreviewSearch_Card} from "../../store/search/actionCreators/search_PreviewActionCreator";
import {changeSearchViewMode} from "../../store/search/actionCreators/search_RootStateActionCreator";
import parse from "html-react-parser";
import {API} from "../../tools/API_NEW/API";
import {history, store} from "../../index";
import {createUniqueIdString} from "../../tools/CreateUniqueId";
import FilesListDropdown from "./FilesListDropdown";
import {DOCUMENT_VERIFY} from "../../store/documents/documentActionsList";
import {changeDocumentSearchInputs} from "../../store/documents/actionCreators/document_SearchActionCreator";
import {docsToggleShowAsideMenu} from "../../store/documents/actionCreators/document_RootStateActionCreator";
import FileDownloadComponent from "../../tools/FileDownloadComponent";

class DocPreviewSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            backToTableTooltipShow : false,
            addNewCardTooltipShow : false,
            selectFileTooltipShow : false,
            halfScreenTooltipShow : false,
            fullScreenTooltipShow : false,
            openInNewTabTooltipShow : false,
            redactorModeTooltipShow : false,
            uploadFileTooltipShow : false,
            uploadFileAttachmentTooltipShow : false,
            goToDocumentsTooltipShow : false,
            infoTooltipShow: false,
            showPreviewShow: false,
            hidePreviewShow: false,
        };

        this.backToTableRef = React.createRef();
        this.addNewCardRef = React.createRef();
        this.selectFileRef = React.createRef();
        this.halfScreenRef = React.createRef();
        this.fullScreenRef = React.createRef();
        this.openInNewTabRef = React.createRef();
        this.redactorModeRef = React.createRef();
        this.uploadFileRef = React.createRef();
        this.uploadFileAttachmentRef = React.createRef();
        this.goToDocumentsRef = React.createRef();
        this.showPreviewRef = React.createRef();
        this.hidePreviewRef = React.createRef();
        this.infoRef = React.createRef();

        this.selectActivePreviewFile = this.selectActivePreviewFile.bind(this);
        this.toggleSizePreview = this.toggleSizePreview.bind(this);
        this.backToTable = this.backToTable.bind(this);
        this.toggleRedactorMode = this.toggleRedactorMode.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.uploadFileAttachment = this.uploadFileAttachment.bind(this);
        this.goToDocuments = this.goToDocuments.bind(this);
        this.selectViewActiveFile = this.selectViewActiveFile.bind(this);
    }

    // меняем активный файл предпросмотра
    selectActivePreviewFile(item,index) {
        const {rootState, activeTab, searchResults} = this.props;

        const activeRow = searchResults.find(res => res.Id === activeTab.Id)?.mainTable.activeRow;
        const cardsList = searchResults.find(res => res.Id === activeTab.Id)?.cards;
        const viewMode = rootState.tabs.find(tab => tab.Id === activeTab.Id)?.viewMode;

        this.props.setFilesContent({document: item.isAttach ? item : activeRow.Info});
        if(this.props.viewMode === "normal") {
            this.props.selectActiveFileForPreviewSearch({
                Type: item.type,
                Index : index
            });
        } else if(this.props.viewMode === "card") {
            this.props.selectActiveFileForPreviewSearch_Card({
                CardId : cardsList.activeCardId,
                ActiveFileIndex : index
            });
        }
    }

    // переключение между обычным режимом и режимом редактирования
    toggleRedactorMode() {
        const {rootState, activeTab, viewMode} = this.props;

        if (viewMode === RootComponentsStateViewModeTypes.SearchApp.PreviewCards_RedactorMode ||
            viewMode === RootComponentsStateViewModeTypes.SearchApp.PreviewCards_RedactorMode_HalfScreen ||
            viewMode === RootComponentsStateViewModeTypes.SearchApp.PreviewCards_RedactorMode_FullScreen) {

            this.props.changeSearchViewMode({
                ViewMode : RootComponentsStateViewModeTypes.SearchApp.PreviewCards,
                Next : {
                    action : () => {
                        this.props.createRedactorData({
                            CardId : null
                        });
                    },
                    params : {}
                }
            });
        } else {

            // меняем версию на последнюю
            // после копируем данные для редактирования
            // и меняем режим отображения на редактирование
            this.props.changeVersionDocumentCards({
                VersionIndex : 0,
                CardId : this.props.cards.activeCardId,
                isRedactorMode: true,
                Next : {
                    action : ()=> {
                        this.props.createRedactorData({
                            CardId : this.props.cards.activeCardId,
                            Next : {
                                action : ()=>{
                                    this.props.changeSearchViewMode({
                                        ViewMode : RootComponentsStateViewModeTypes.SearchApp.PreviewCards_RedactorMode
                                    });
                                },
                                params : {}

                            }
                        });
                    },
                    params : {}
                }
            });

        }
    }

    // изменяем размер предпросмотра
    // TODO: доделать для режима редактирования
    toggleSizePreview(size) {
        const {rootState, activeTab} = this.props;
        const viewModeSearch = rootState.tabs.find(tab => tab.Id === activeTab.Id)?.viewMode;

        if (viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewCards ||
            viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewCards_HalfScreen ||
            viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewCards_FullScreen) {
            if(size === "fullScreen") {
                if (this.props.viewMode === RootComponentsStateViewModeTypes.SearchApp.PreviewCards_FullScreen) {
                    this.props.changeSearchViewMode({
                        ViewMode : RootComponentsStateViewModeTypes.SearchApp.PreviewCards
                    });
                } else {
                    this.props.changeSearchViewMode({
                        ViewMode : RootComponentsStateViewModeTypes.SearchApp.PreviewCards_FullScreen
                    });
                }
            } else if(size === "halfScreen") {
                if (viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewCards ||
                    viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewCards_FullScreen) {
                    this.props.changeSearchViewMode({
                        ViewMode : RootComponentsStateViewModeTypes.SearchApp.PreviewCards_HalfScreen
                    });
                } else {
                    this.props.changeSearchViewMode({
                        ViewMode : RootComponentsStateViewModeTypes.SearchApp.PreviewCards
                    });
                }
            }
        } else if (viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewCards_RedactorMode ||
            viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewCards_RedactorMode_HalfScreen ||
            viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewCards_RedactorMode_FullScreen) {
            if(size === "fullScreen") {
                if (viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewCards_RedactorMode_FullScreen) {
                    this.props.changeSearchViewMode({
                        ViewMode : RootComponentsStateViewModeTypes.SearchApp.PreviewCards_RedactorMode
                    });
                } else {
                    this.props.changeSearchViewMode({
                        ViewMode : RootComponentsStateViewModeTypes.SearchApp.PreviewCards_RedactorMode_FullScreen
                    });
                }
            } else if(size === "halfScreen") {
                if (viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewCards_RedactorMode ||
                    viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewCards_RedactorMode_FullScreen) {
                    this.props.changeSearchViewMode({
                        ViewMode : RootComponentsStateViewModeTypes.SearchApp.PreviewCards_RedactorMode_HalfScreen
                    });
                } else {
                    this.props.changeSearchViewMode({
                        ViewMode : RootComponentsStateViewModeTypes.SearchApp.PreviewCards_RedactorMode
                    });
                }
            }
        } else {
            if(size === "fullScreen") {
                if(viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewNormal ||
                    viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewNormal_PreviewHalfScreen) {
                    this.props.changeSearchViewMode({
                        ViewMode : RootComponentsStateViewModeTypes.SearchApp.PreviewNormal_PreviewFullScreen
                    });
                } else {
                    this.props.changeSearchViewMode({
                        ViewMode : RootComponentsStateViewModeTypes.SearchApp.PreviewNormal
                    });
                }
            } else if(size === "halfScreen") {
                if(viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewNormal ||
                    viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewNormal_PreviewFullScreen) {
                    this.props.changeSearchViewMode({
                        ViewMode : RootComponentsStateViewModeTypes.SearchApp.PreviewNormal_PreviewHalfScreen
                    });
                } else if(viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewNormal_PreviewHalfScreen) {
                    this.props.changeSearchViewMode({
                        ViewMode : RootComponentsStateViewModeTypes.SearchApp.PreviewNormal
                    });
                } else {
                    this.props.changeSearchViewMode({
                        ViewMode : RootComponentsStateViewModeTypes.SearchApp.PreviewNormal
                    });
                }
            }  else if (size === "hide"){
                this.props.changeSearchViewMode({
                    ViewMode : RootComponentsStateViewModeTypes.SearchApp.PreviewNormal_Hide
                });
            }
        }
    }

    // возвращаемся в реестр документов (к таблице)
    backToTable() {
        const {activeTab, searchResults} = this.props;
        const activeRow = searchResults.find(res => res.Id === activeTab.Id)?.mainTable.activeRow;
        let viewMode = activeRow == null
            ? RootComponentsStateViewModeTypes.SearchApp.MainTableOnly
            : RootComponentsStateViewModeTypes.SearchApp.PreviewNormal;

        this.props.changeSearchViewMode({
            ViewMode : viewMode,

            Next : {
                action: () => {
                    this.props.selectActiveDocumentCard({});
                    this.props.setStateLeftAsideMenu({
                        isClose : false
                    });
                },
                params : {}
            }
        });
    }

    // прикрепление основного файла
    uploadFile() {
        // this.props.setModalData({
        //     name : ModalTypes.documents.additionalSection.DownloadDoc,
        //     data : {
        //         type : "main"
        //     }
        // });
    }

    // прикрепление дополнительных файлов
    uploadFileAttachment() {
        this.props.setModalData({
            name : ModalTypes.documents.additionalSection.DownloadDoc,
            data : {
                type : "attachment"
            }
        });



    }

    async goToDocuments(isCard = false) {
        const {activeTab, searchResults} = this.props;
        const activeRow = searchResults.find(res => res.Id === activeTab.Id)?.mainTable.activeRow;

        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "documents", key : "SearchPreview316" }
        }));
        //Задача № 22235
        let vFlagVerify = false;
        let docRefInfo = null;
        //Если документ уже верифицирован, то не делаем запрос
        if (this.props.verifyDocs)
        {
            if (this.props.verifyDocs[activeRow.info.Id]) {
                vFlagVerify = true;
                docRefInfo = this.props.verifyDocs[activeRow.info.Id][0];
            }
        }

        if (!vFlagVerify) {
            docRefInfo = await API.search().verifyDocClientLinkIds({
                linkIds: {
                    IdDoc: activeRow.info.Id,
                    IdFilial: this.props.activeFilialId
                }
            });
            // сохраняем в редакс
            store.dispatch({
                type: DOCUMENT_VERIFY.UPDATE_VERIFY_DOCS, payload: {
                    id: activeRow.info.Id,
                    data: docRefInfo
                }
            });
        }

        // let docRefInfo = await API.search().verifyDocClientLinkIds({
        //     linkIds : {
        //         IdDoc : this.props.activeRow.info.Id,
        //         IdFilial : this.props.activeFilialId
        //     }
        // });

        const searchInputs = store.getState().document.search.searchInputs
        for (const item of searchInputs) {
            store.dispatch(changeDocumentSearchInputs({
                ...item,
                IsApplied: false
            }))
        }

        if(docRefInfo.errorCode) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content: `Невозможно перейти в карточку документа <br/> ${docRefInfo.message.Message}`}
            });
            //TODO незакрывался прелоадер, если при переходе возникала ошибка
            this.props.setLoaderModalData({keyDeleted: "SearchPreview358"});
            return;
        }

        let link = `/documents?g=${docRefInfo.IdDocGroup}&t=${docRefInfo.IdDocType}&d=${docRefInfo.IdDoc}`;

        if(isCard) {
            link += `&c=${docRefInfo.IdDoc}`;
        }

        store.dispatch(docsToggleShowAsideMenu({isOpen: false}))
        store.dispatch(setLoaderModalData({keyDeleted : "SearchPreview368"}));
        history.replace(link, true);
        history.replace(link, true);
    }

    selectViewActiveFile(type) {
        this.props.selectViewActiveFileForPreview({View: type});
    }

    render() {
        const {rootState, activeTab, searchResults} = this.props;
        const viewModeSearch = rootState.tabs.find(tab => tab.Id === activeTab.Id)?.viewMode;
        const activeRow = searchResults.find(res => res.Id === activeTab.Id)?.mainTable.activeRow;

        // let isRedactorMode = false;
        // if (this.props.rootState.viewMode === RootComponentsStateViewModeTypes.SearchApp.PreviewCards_RedactorMode ||
        //     this.props.rootState.viewMode === RootComponentsStateViewModeTypes.SearchApp.PreviewCards_RedactorMode_HalfScreen ||
        //     this.props.rootState.viewMode === RootComponentsStateViewModeTypes.SearchApp.PreviewCards_RedactorMode_FullScreen) {
        //     isRedactorMode = true;
        // }


        let preview;
        let documentName = "";

        // обычный режим отображения
        if(this.props.viewMode === "normal") {
            preview = searchResults.find(res => res.Id === activeTab.Id)?.preview;
            // preview = this.props.preview;
            documentName = activeRow ? `${activeRow.info.Name} ${activeRow.info.ShortDescription ?? ""}`  : "";
        }

        // режим если отображается карточка документа
        else if(this.props.viewMode === "card") {
            const cardsList = searchResults.find(res => res.Id === activeTab.Id)?.cards;
            const card = cardsList.collection.find(card => card.Id === cardsList.activeCardId);
            preview = card.preview;
            documentName = `${card.name} ${card.ShortDescription ?? ""}`;
        }


        // let body = null;
        // if(preview.isReady) {
        //     if(preview.files[preview.activeFileIndex].type === "image") {
        //         body = (
        //             <div className="card-body beautiful-scroll-10" style={{}}>
        //                 <img src={preview.files[preview.activeFileIndex].link} alt="" style={{width : "100%"}} />
        //             </div>
        //         );
        //     }
        //     else {
        //         body = (
        //             <div className="" style={{width : "100%", height : "100%"}}>
        //                 <Iframe url={preview.files[preview.activeFileIndex].link}
        //                         width="100%"
        //                         height="100%"
        //                         id="test"
        //                         display="initial"
        //                         position="relative"/>
        //             </div>
        //         );
        //     }
        // }
        let body = <div className="card-body"/>;
        let activeLink = "";
        if (preview.isReady) {
                if(preview.activeView === "preview" ) {
                    activeLink = preview.files[preview.activeFileIndex].previewLink;
                    body = (
                        <div className="card-body beautiful-scroll-10" style={{}}>
                            <img src={activeLink} alt="" style={{
                                boxShadow: "0 0 15px -5px grey",
                                width: "100%"}}/>
                        </div>
                    );
                } else {
                    activeLink = preview.files[preview.activeFileIndex].originalLink;
                const lFileName = preview.files[preview.activeFileIndex]['name'];
                const lLastFour = lFileName.substring(lFileName.length - 4).toLowerCase();
                if (lLastFour == '.pdf') {
                    body = (
                        <div className="card-body beautiful-scroll-10" style={{width: "100%", height: "100%"}}>
                            <Iframe url={activeLink}
                                    width="100%"
                                    height="100%"
                                    id="test"
                                    display="initial"
                                    position="relative"/>
                        </div>
                    );
                } else {
                    body = (
                        <div className="card-body beautiful-scroll-10" style={{width: "100%", height: "100%"}}>
                            <FileDownloadComponent fileUrl={activeLink} fileName={lFileName} fileSize={
                                preview.files[preview.activeFileIndex].size === undefined ? "—" : `${(preview.files[preview.activeFileIndex].size / 1048576).toFixed(2)} Мбайт`
                            }/>
                        </div>
                    );
                }
            }
        }

        const isAttachFiles = preview.files.filter(file => file.isAttach).length > 0;

        let backToTableColor = this.state.backToTableTooltipShow
            ? "svg-icon icon-Delete icon-color-red"
            : "svg-icon icon-Delete icon-color-primary";


        let buttonActiveStyleHalf = {};
        let buttonActiveStyleFull = {};
        let arrowButtonActiveStyleHalf = {};
        let arrowButtonActiveStyleFull = {};

        if(viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewCards_HalfScreen
            || viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewNormal_PreviewHalfScreen
            || viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewCards_RedactorMode_HalfScreen) {
            buttonActiveStyleHalf = {

                backgroundColor: "var(--originaltwo)",
                borderColor: "var(--originaltwo)"
            };
            arrowButtonActiveStyleHalf = {
                color: "white"
            }
        }

        if(viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewNormal_PreviewFullScreen
            || viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewCards_RedactorMode_FullScreen
            || viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewCards_FullScreen) {
            buttonActiveStyleFull = {
                backgroundColor: "var(--originaltwo)",
                borderColor: "var(--originaltwo)"
            };
            arrowButtonActiveStyleFull = {
                color: "white"
            }
        }

        const isPreview = [rootStateType.SearchApp.PreviewNormal,
            rootStateType.SearchApp.PreviewNormal_PreviewHalfScreen,
            rootStateType.SearchApp.PreviewNormal_PreviewFullScreen].includes(viewModeSearch);

        return (
            viewModeSearch === RootComponentsStateViewModeTypes.SearchApp.PreviewNormal_Hide ?
            <>
                <div className="card-body preview-hide col align-items-center" style={{flexBasis: 'auto'}}>
                    <div className="row" style={{ justifyContent: 'center'}}>
                        {
                            this.state.showPreviewShow &&
                            <MyTooltip target={this.showPreviewRef}
                                       text={"Раскрыть предпросмотр документа"}
                                       show={this.state.showPreviewShow}
                                       placement={"top"} delay={500}
                            />
                        }
                        <button type="button" className="btn btn-outline p-0" onClick={()=>{this.toggleSizePreview("halfScreen")}}
                                onMouseEnter={()=>{this.setState({showPreviewShow : true})}}
                                onMouseLeave={()=>{this.setState({showPreviewShow : false})}}
                                ref={this.showPreviewRef}>
                                <span className="btn btn-icon btn-sm">
                                    <i style={{fontSize: '2rem'}} className="svg-icon icon-window_out_right icon-color-primary text-success" />
                                </span>
                        </button>
                        <div className="separator separator-solid mb-2 mt-2 hidden-in-1200" style={{minWidth: '3vw'}} />
                        {
                            this.props.viewMode === "normal" &&
                            <div className="d-flex align-items-center justify-content-center flex-wrap">
                                {/*{*/}
                                {/*    this.state.infoTooltipShow &&*/}
                                {/*    <MyTooltip target={this.infoRef}*/}
                                {/*               text={"Для просмотра файлов иных форматов, кроме PDF, необходимо скачать файл"}*/}
                                {/*               show={this.state.infoTooltipShow}*/}
                                {/*               placement={"left"} delay={500}*/}
                                {/*    />*/}
                                {/*}*/}
                                {/*<span className="btn btn-icon btn-circle btn-sm icon-info-decor"*/}
                                {/*      ref={this.infoRef}*/}
                                {/*      onMouseEnter={()=>{this.setState({infoTooltipShow : true})}}*/}
                                {/*      onMouseLeave={()=>{this.setState({infoTooltipShow : false})}}>*/}
                                {/*    <i className="svg-icon icon-Info"/>*/}
                                {/*</span>*/}
                                {
                                    this.state.addNewCardTooltipShow &&
                                    <MyTooltip target={this.addNewCardRef}
                                               text={"Карточка документа"}
                                               show={this.state.addNewCardTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                <span className="btn btn-icon btn-sm"
                                      ref={this.addNewCardRef}
                                      onClick={()=>{this.goToDocuments(true)}}
                                      onMouseEnter={()=>{this.setState({addNewCardTooltipShow : true})}}
                                      onMouseLeave={()=>{this.setState({addNewCardTooltipShow : false})}}>
                                    <i className="svg-icon icon-Table_edit_3 icon-color-primary"/>
                                </span>
                                <div className="separator separator-solid mb-2 mt-2 hidden-in-1200" style={{minWidth: '3vw'}} />
                                {
                                    this.state.goToDocumentsTooltipShow &&
                                    <MyTooltip target={this.goToDocumentsRef}
                                               text={"Перейти в реестр"}
                                               show={this.state.goToDocumentsTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                <span className="btn btn-icon btn-sm"
                                      ref={this.goToDocumentsRef}
                                      onClick={()=>{this.goToDocuments()}}
                                      onMouseEnter={()=>{this.setState({goToDocumentsTooltipShow : true})}}
                                      onMouseLeave={()=>{this.setState({goToDocumentsTooltipShow : false})}}>
                                    <i style={{fontSize: '2rem'}} className="svg-icon icon-left_window icon-color-primary"/>
                                </span>
                            </div>
                        }
                    </div>
                </div>
            </>
                :
                <>
                    <div className="card-header p-0 beautiful-scroll-5" style={{overflowX: 'auto', overflowY: 'hidden', justifyContent: 'flex-start'}}>
                        {
                            this.state.hidePreviewShow &&
                            <MyTooltip target={this.hidePreviewRef}
                                       text={"Скрыть предпросмотр документа"}
                                       show={this.state.hidePreviewShow}
                                       placement={"top"} delay={500}
                            />
                        }
                        {isPreview &&
                            <button type="button" className="btn btn-outline p-0" onClick={()=>{this.toggleSizePreview("hide")}}
                                    onMouseEnter={()=>{this.setState({hidePreviewShow : true})}}
                                    onMouseLeave={()=>{this.setState({hidePreviewShow : false})}}
                                    ref={this.hidePreviewRef}>
                                <span className="btn btn-icon btn-sm">
                                    <i style={{fontSize: '2rem'}} className="svg-icon icon-window_out_left icon-color-primary text-success" />
                                </span>
                            </button>
                        }

                        <div className="card-title">
                                {
                                    isAttachFiles ?
                                        <FilesListDropdown docName={documentName}
                                                           preview={preview}
                                                           selectActivePreviewFile={this.selectActivePreviewFile}
                                        />
                                        :
                                        <h4 className="card-label text-weight-bolder text-color-primary"
                                            style={{whiteSpace: "nowrap"}}
                                            title={documentName.replace('<span class=\'searchResultHighlight\'>', "").replace('</span>', "")}>
                                            {HelpFunctions.cutLongString(parse(documentName), 15, true)}
                                        </h4>
                                }
                            </div>

                        {
                            this.props.viewMode === "card" &&
                            <div className="d-flex align-items-center">
                                {
                                    (this.state.redactorModeTooltipShow && !this.props.redactorMode) &&
                                    <MyTooltip target={this.redactorModeRef}
                                               text={"Режим редактирования"}
                                               show={this.state.redactorModeTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                {
                                    !this.props.redactorMode &&
                                    <div onClick={this.toggleRedactorMode} ref={this.redactorModeRef}
                                         onMouseEnter={()=>{this.setState({redactorModeTooltipShow : true})}}
                                         onMouseLeave={()=>{this.setState({redactorModeTooltipShow : false})}}>
                                    <span className="btn btn-icon btn-sm">
                                        <i className="svg-icon icon-Table_edit_1 icon-color-primary"/>
                                    </span>
                                    </div>
                                }

                                {
                                    (this.state.redactorModeTooltipShow && this.props.redactorMode) &&
                                    <MyTooltip target={this.redactorModeRef}
                                               text={"Сохранить изменения"}
                                               show={this.state.redactorModeTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }

                                {
                                    // this.state.uploadFileTooltipShow &&
                                    // <MyTooltip target={this.uploadFileRef}
                                    //            text={"Загрузить новый файл предпросмотра (pdf)"}
                                    //            show={this.state.uploadFileTooltipShow}
                                    //            placement={"top"} delay={500}
                                    // />
                                }
                                {
                                    this.state.uploadFileAttachmentTooltipShow &&
                                    <MyTooltip target={this.uploadFileAttachmentRef}
                                               text={"Загрузить новый файл"}
                                               show={this.state.uploadFileAttachmentTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                {
                                    this.props.redactorMode &&
                                    <React.Fragment>
                                        {/*<div onClick={this.uploadFile} ref={this.uploadFileRef}*/}
                                        {/*     onMouseEnter={() => {this.setState({uploadFileTooltipShow: true}) }}*/}
                                        {/*     onMouseLeave={() => {this.setState({uploadFileTooltipShow: false})}}>*/}
                                        {/*   <span className="btn btn-icon btn-sm">*/}
                                        {/*       <i className="svg-icon icon-Upload_document icon-color-primary"/>*/}
                                        {/*   </span>*/}
                                        {/*</div>*/}
                                        <div onClick={this.uploadFileAttachment} ref={this.uploadFileAttachmentRef}
                                             onMouseEnter={() => {this.setState({uploadFileAttachmentTooltipShow: true}) }}
                                             onMouseLeave={() => {this.setState({uploadFileAttachmentTooltipShow: false})}}>
                                        <span className="btn btn-icon btn-sm">
                                            <i className="svg-icon icon-Upload_document icon-color-primary"/>
                                        </span>
                                        </div>
                                    </React.Fragment>
                                }

                                {/*{*/}
                                {/*    this.state.infoTooltipShow &&*/}
                                {/*    <MyTooltip target={this.infoRef}*/}
                                {/*               text={"Для просмотра файлов иных форматов, кроме PDF, необходимо скачать файл"}*/}
                                {/*               show={this.state.infoTooltipShow}*/}
                                {/*               placement={"left"} delay={500}*/}
                                {/*    />*/}
                                {/*}*/}
                                {/*<span className="btn btn-icon btn-circle btn-sm icon-info-decor"*/}
                                {/*      ref={this.infoRef}*/}
                                {/*      onMouseEnter={()=>{this.setState({infoTooltipShow : true})}}*/}
                                {/*      onMouseLeave={()=>{this.setState({infoTooltipShow : false})}}>*/}
                                {/*    <i className="svg-icon icon-Info"/>*/}
                                {/*</span>*/}
                                {
                                    (this.state.backToTableTooltipShow &&  !this.props.redactorMode) &&
                                    <MyTooltip target={this.backToTableRef}
                                               text={"Вернуться в реестр"}
                                               show={this.state.backToTableTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                {
                                    !this.props.redactorMode &&
                                    <span className="btn btn-icon btn-sm" ref={this.backToTableRef}
                                          onClick={this.backToTable}
                                          onMouseEnter={()=>{this.setState({backToTableTooltipShow : true})}}
                                          onMouseLeave={()=>{this.setState({backToTableTooltipShow : false})}}>
                                <i className={backToTableColor}/>
                            </span>
                                }

                                {
                                    (this.state.backToTableTooltipShow &&  this.props.redactorMode) &&
                                    <MyTooltip target={this.backToTableRef}
                                               text={"Отменить редактирование"}
                                               show={this.state.backToTableTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                {
                                    this.props.redactorMode &&
                                    <span className="btn btn-icon btn-sm" ref={this.backToTableRef}
                                          onClick={this.toggleRedactorMode}
                                          onMouseEnter={()=>{this.setState({backToTableTooltipShow : true})}}
                                          onMouseLeave={()=>{this.setState({backToTableTooltipShow : false})}}>
                                <i className={backToTableColor}/>
                            </span>
                                }
                            </div>
                        }
                        {
                            this.props.viewMode === "normal" &&
                            <div className="d-flex align-items-center">
                                {/*{*/}
                                {/*    this.state.infoTooltipShow &&*/}
                                {/*    <MyTooltip target={this.infoRef}*/}
                                {/*               text={"Для просмотра файлов иных форматов, кроме PDF, необходимо скачать файл"}*/}
                                {/*               show={this.state.infoTooltipShow}*/}
                                {/*               placement={"left"} delay={500}*/}
                                {/*    />*/}
                                {/*}*/}
                                {/*<span className="btn btn-icon btn-circle btn-sm icon-info-decor"*/}
                                {/*      ref={this.infoRef}*/}
                                {/*      onMouseEnter={()=>{this.setState({infoTooltipShow : true})}}*/}
                                {/*      onMouseLeave={()=>{this.setState({infoTooltipShow : false})}}>*/}
                                {/*    <i className="svg-icon icon-Info"/>*/}
                                {/*</span>*/}
                                {
                                    this.state.goToDocumentsTooltipShow &&
                                    <MyTooltip target={this.goToDocumentsRef}
                                               text={"Перейти в реестр"}
                                               show={this.state.goToDocumentsTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                <span className="btn btn-icon btn-sm"
                                      ref={this.goToDocumentsRef}
                                      onClick={()=>{this.goToDocuments()}}
                                      onMouseEnter={()=>{this.setState({goToDocumentsTooltipShow : true})}}
                                      onMouseLeave={()=>{this.setState({goToDocumentsTooltipShow : false})}}>
                                <i className="svg-icon icon-left_window icon-color-primary"/>
                            </span>

                                {
                                    this.state.addNewCardTooltipShow &&
                                    <MyTooltip target={this.addNewCardRef}
                                               text={"Карточка документа"}
                                               show={this.state.addNewCardTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                <span className="btn btn-icon btn-sm"
                                      ref={this.addNewCardRef}
                                      onClick={()=>{this.goToDocuments(true)}}
                                      onMouseEnter={()=>{this.setState({addNewCardTooltipShow : true})}}
                                      onMouseLeave={()=>{this.setState({addNewCardTooltipShow : false})}}>
                                <i className="svg-icon icon-Table_edit_3 icon-color-primary"/>
                            </span>
                            </div>
                        }
                    </div>
                    <div style={{marginTop: "10px", color: "darkGrey", wordBreak: "break-word"}}>Для просмотра файлов иных форматов, кроме PDF, необходимо скачать файл</div>
                    {body}
                    <div className="card-footer">
                        <div className="btn-toolbar-preview card-toolbar">

                            {
                                this.state.selectFileTooltipShow &&
                                <MyTooltip target={this.selectFileRef}
                                           text={"Выбрать файл предпросмотра"}
                                           show={this.state.selectFileTooltipShow}
                                           placement={"top"} delay={500}
                                />
                            }
                            <Dropdown>
                                <Dropdown.Toggle className="btn btn-circle btn-outline-success dropdown-toggle" ref={this.selectFileRef}
                                                 onMouseEnter={()=>{this.setState({selectFileTooltipShow : true})}}
                                                 onMouseLeave={()=>{this.setState({selectFileTooltipShow : false})}}>
                                    <i className="svg-icon icon-Doc_view_12"/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item key={createUniqueIdString(3)}
                                                   className={`dropdown-item ${preview.activeView === "original" ? "active" : ""}`}
                                                   onClick={() => this.selectViewActiveFile("original")}>Оригинал
                                    </Dropdown.Item>
                                    <Dropdown.Item key={createUniqueIdString(3)}
                                                   className={`dropdown-item ${preview.activeView === "preview" ? "active" : ""}`}
                                                   onClick={() => this.selectViewActiveFile("preview")}>Предпросмотр
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            {
                                this.state.halfScreenTooltipShow &&
                                <MyTooltip target={this.halfScreenRef}
                                           text={"Увеличенный предпросмотр"}
                                           show={this.state.halfScreenTooltipShow}
                                           placement={"top"} delay={500}
                                />
                            }
                            <span className="btn btn-icon btn-outline-success btn-circle btn-sm hidden-in-1200" ref={this.halfScreenRef}
                                  style={buttonActiveStyleHalf}
                                  onMouseEnter={()=>{this.setState({halfScreenTooltipShow : true})}}
                                  onMouseLeave={()=>{this.setState({halfScreenTooltipShow : false})}}
                                  onClick={()=>{this.toggleSizePreview("halfScreen")}}>
                                <i className="svg-icon icon-Doc_view_2" style={arrowButtonActiveStyleHalf}/>
                            </span>

                            {
                                this.state.fullScreenTooltipShow &&
                                <MyTooltip target={this.fullScreenRef}
                                           text={"Полноэкранный предпросмотр"}
                                           show={this.state.fullScreenTooltipShow}
                                           placement={"top"} delay={500}
                                />
                            }
                            <span className="btn btn-icon btn-outline-success btn-circle btn-sm hidden-in-1200" ref={this.fullScreenRef}
                                  style={buttonActiveStyleFull}
                                  onMouseEnter={()=>{this.setState({fullScreenTooltipShow : true})}}
                                  onMouseLeave={()=>{this.setState({fullScreenTooltipShow : false})}}
                                  onClick={()=>{this.toggleSizePreview("fullScreen")}}>
                            <i className="svg-icon icon-Doc_view_3" style={arrowButtonActiveStyleFull}/>
                        </span>

                            {
                                this.state.openInNewTabTooltipShow &&
                                <MyTooltip target={this.openInNewTabRef}
                                           text={"Открыть в новой вкладке"}
                                           show={this.state.openInNewTabTooltipShow}
                                           placement={"top"} delay={500}
                                />
                            }
                            <a href={preview.isReady ? activeLink : "#"} ref={this.openInNewTabRef}
                               onMouseEnter={()=>{this.setState({openInNewTabTooltipShow : true})}}
                               onMouseLeave={()=>{this.setState({openInNewTabTooltipShow : false})}}
                               target="_blank"
                               rel="noreferrer"
                               className="btn btn-icon btn-outline-success btn-circle btn-sm notFocus">
                                <i className="svg-icon icon-Doc_view_5"/>
                            </a>
                        </div>
                    </div>
                </>
        );

    }
}


const  mapStateToProps = state => {
    return {
        rootState : state.search.rootState,

        cards : state.document.cards,
        activeFilialId : state.globalState.filial.Active.Id,

        verifyDocs: state.document.verifyDocs,

        activeTab: state.search.rootState.activeTab,
        searchResults: state.search.searchState.searchResults,
        searchInputsList: state.search.searchState.searchInputsList
    }
}

const  mapDispatchToProps = {
    docsToggleShowAsideMenu,
    selectActiveFileForPreviewSearch,//
    changeSearchViewMode,//
    selectActiveFileForPreviewSearch_Card,//
    createRedactorData,
    setModalData,
    setLoaderModalData,
    setStateLeftAsideMenu,
    changeVersionDocumentCards,
    setFilesContent,
    selectViewActiveFileForPreview
}

export default connect(mapStateToProps,mapDispatchToProps)(DocPreviewSearch);
