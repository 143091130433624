import React from "react";
import {connect} from "react-redux";
import {ModalTypes, RootComponentsStateViewModeTypes} from "../../../tools/StaticTypes";
import {setLoaderModalData, setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {API} from "../../../tools/API_NEW/API";
import {ActionQueue} from "../../../store/rootReducer";
import {
    collectDataForAdditionalSections_Normal,
    createPreviewBySelectDocument,
    resetDocumentPreview
} from "../../../store/documents/actionCreators/document_PreviewActionCreator";
import {
    combineMainTableAttributes,
    mainTableDownloadDocCollection, mainTableSearchDoc,
    resetMainTableForUpdate, selectActiveRow, setPaginationPageNumber
} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {changeDocumentViewMode} from "../../../store/documents/actionCreators/document_RootStateActionCreator";
import {__} from "../../../tools/HelpFunctions";
import {store} from "../../../index";


class DeleteDocumentModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };

        this.modalName = ModalTypes.documents.mainTable.DeleteDocument;

        this.hide = this.hide.bind(this);
        this.hideOutSide = this.hideOutSide.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);
    }

    // скрывать по кнопке
    hide() {

        this.props.setModalData({});
    }

    hideOutSide() { // скрывать при клике вне окна
        if(!this.state.contentOnHover) {

            this.props.setModalData({});
        }
    }

    async deleteDocument() {

        let IdDoc = this.props.modal.data.IdDoc;
        let isMark = this.props.modal.data.isMark;
        let tableRole = this.props.modal.data.tableRole



        let result = await API.documents().markForDelete({
            id : IdDoc,
            isMarked : !isMark
        });


        if (result?.errorCode) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content: `Действие не было выполнено: <br/>${result.message.Message}`}
            });
            return;
        }

        // let query = store.getState().router.location.query;
        // let queryLink = ""
        // if(isMark) {
        //     queryLink = queryLink = Routing.CreateRef({
        //         oldQuery : query,
        //         newQuery : query,
        //         excludedKeys : []
        //     });
        // }
        // else {
        //     queryLink = Routing.CreateRef({
        //         oldQuery : query,
        //         newQuery : query,
        //         //TODO aa Задача № 21974, чтобы не переходило на первую страницу
        //         // excludedKeys : ["d", "d2"]
        //         excludedKeys : []
        //     });
        // }

        // history.replace(queryLink)

        //TODO SS версия для ГЭТ, чтобы оставаться на той же самой странице при удалении/восстановлении документа
        let activeRow = __.deepCopy(this.props.activeRow);
        const { pageNumber } = this.props;
        await this.props.mainTableDownloadDocCollection({ActiveNode : this.props.activeNode});
        await this.props.combineMainTableAttributes({ActiveNode : this.props.activeNode});
        await this.props.selectActiveRow({IdDoc: activeRow.Info.Id});
        await this.props.resetDocumentPreview({});
        await this.props.createPreviewBySelectDocument({});
        await this.props.collectDataForAdditionalSections_Normal({});
        await this.props.changeDocumentViewMode({
            ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal
        });
        await this.props.setPaginationPageNumber({PaginationPageNumber: pageNumber});
        if(this.props.searchInputs?.find(item => item.IsApplied)) {
            store.dispatch(setLoaderModalData({
                data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "documents", key : "DeleteDocumentModal109" }
            }));
            await this.props.mainTableSearchDoc({
                Next: {
                    action: async () => {
                        await this.props.combineMainTableAttributes({ActiveNode : this.props.activeNode});
                        this.props.setLoaderModalData({keyDeleted: "DeleteDocumentModal114"});
                    }
                }
            });
        }

        this.props.setModalData({
            name: ModalTypes.app.info,
            data: {
                type: "success",
                content: `Документ успешно ${isMark ? 'восстановлен' : 'удален'}`
            }
        });
        // else {
        //     // TODO Задача № 21974 чтоб не переходило на 1-ю страницу
        //     // this.props.changeDocumentViewMode({ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly})
        //     this.props.setModalData({
        //         name: ModalTypes.app.info,
        //         data: {
        //             type: "success",
        //             content: `Документ успешно удален`
        //         }
        //     });
        // }



        // let IdPackageDocRef = this.props.modal.data.activeDocument.Node.IdPackageDocRef;
        //
        // let result = await API.packages().deleteCollection({
        //     collection:[{Id:IdPackageDocRef}]
        // });
        //
        // if (result?.errorCode) {
        //     this.props.setModalData({
        //         name: ModalTypes.app.info,
        //         data: {type: "fail", content: `Документ не был удален<br/>${result.message.Message}`}
        //     });
        //     return;
        // }
        //
        // this.props.setModalData({
        //     name: ModalTypes.app.info,
        //     data: {type: "success", content: `Документ успешно удален`}
        // });
    }


    render() {

        let Name = this.props.modal.data.Name;
        let isMark = this.props.modal.data.isMark;

        let HeaderText = "Удаление документа";
        if(isMark) {
            HeaderText = "Восстановление документа";
        }

        let modalStyle = {
            display : "block",
            backgroundColor: "#061c3e66",
        };

        return (
            <div className="modal fade show" style={modalStyle} onClick={this.hideOutSide}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                    <div className="modal-content"
                         onMouseEnter={() => {this.setState({ contentOnHover : true})}}
                         onMouseLeave={() => {this.setState({ contentOnHover : false})}}>

                        <div className="modal-header">
                            <h5 className="modal-title" title={HeaderText}>{HeaderText}</h5>
                        </div>
                        <div className="modal-body" style={{height: "200px"}}>
                            <div className="col-lg-12 row my-3">
                                <div>
                                    Имя выбранного документа : <b>{Name}</b>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary font-weight-bold"
                                    onClick={this.deleteDocument}>
                                Подтвердить
                            </button>
                            <button type="button" className="btn btn-light font-weight-bold" onClick={this.hide}>
                                Отменить
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        );

    }
}


const  mapStateToProps = state => {
    return {
        activeNode : state.document.tree.activeNode,
        activeRow : state.document.mainTable.activeRow,
        pageNumber: state.document.mainTable.paginationPageNumber,
        searchInputs : state.document.search.searchInputs,
    }
}

const  mapDispatchToProps = {
    setModalData,
    ActionQueue,
    resetMainTableForUpdate,
    mainTableDownloadDocCollection,
    combineMainTableAttributes,
    selectActiveRow,
    resetDocumentPreview,
    createPreviewBySelectDocument,
    collectDataForAdditionalSections_Normal,
    changeDocumentViewMode,
    setPaginationPageNumber,
    mainTableSearchDoc,
    setLoaderModalData
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDocumentModal);
