import React from 'react';
import {HelpFunctions} from "../../tools/HelpFunctions";

class ImagePreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            imgSrc: ''
        }
        this.loading = this.loading.bind(this)
    }

    componentDidMount() {
        this.loading()
    }

    componentDidUpdate(prevProps) {
        if (!(this.props.fileObj.fileName === prevProps.fileObj.fileName && this.props.fileObj.fileType === prevProps.fileObj.fileType)) {
            this.loading()
        }
    }

    async loading() {
        const loadingStart = +new Date();
        this.setState({imgSrc: '', isLoading: true})

        const reader = new FileReader();
        reader.onloadend = () => {
            const loadingEnd = +new Date() - loadingStart;
            if (loadingEnd > 50) {
                setTimeout(() => {
                    this.setState({imgSrc: reader.result, isLoading: false})
                }, 200 - loadingEnd)
            }
            else {
                this.setState({imgSrc: reader.result, isLoading: false})
            }
        };

        const fileBlob = await HelpFunctions.blobFromJSONString(this.props.fileObj.fileAsJSON);
        /* TODO КН 01.02.23 Если сверху допустим есть кнопка скачать, а эта компонента была вызвана и уже распарсила картинку,
          то кнопке скачать уже не надо распарсивать её еще раз. Поэтому в логике кнопки скачать есть условие, что если уже существует blob,
          который прокидываем наверх через this.props.setFileBlob(), то распарсивать файл не нужно
        */
        this.props.setFileBlob && this.props.setFileBlob(fileBlob)
        reader.readAsDataURL(fileBlob);
    }

    render() {
        if (this.state.isLoading) {
            const spinnerSize = this.props?.spinnerSize || '3rem'
            return <div className="spinner-border" style={{width: spinnerSize, height: spinnerSize, color:"var(--originaltwo)", margin: 'auto'}} role="status" />
        }
        return <img className='d-flex' style={{maxWidth: '100%', maxHeight: '100%', margin: 'auto'}} src={this.state.imgSrc} alt={this.props.fileObj.fileName}/>
    }
}
export default ImagePreview;
