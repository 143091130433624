import React from "react";
import {connect} from "react-redux";
import {createUniqueIdString} from "../../tools/CreateUniqueId";
import "moment/locale/ru";
import {
    addRowToChecked,
    deleteRowFromChecked, selectActiveRow
} from "../../store/documents/actionCreators/document_MainTableActionCreator";
import {contextMenuTypes, RootComponentsStateViewModeTypes} from "../../tools/StaticTypes";
import {
    setContextMenuData,
    setLoaderModalData
} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {changeDocumentViewMode} from "../../store/documents/actionCreators/document_RootStateActionCreator";
import {
    collectDataForAdditionalSections_Normal,
    createPreviewBySelectDocument,
    resetDocumentPreview
} from "../../store/documents/actionCreators/document_PreviewActionCreator";
import moment from "moment";
import {history} from "../../index";
import {ActionQueue} from "../../store/rootReducer";
import AttributeWithDictionaries from "../additionalDataSection/docRefsComponents/AttributeWithDictionaries";
import {DataTypesEnum} from "../../tools/API_NEW/ServerTypes";
import MultipleDocRef from "../additionalDataSection/docRefsComponents/MultipleDocRef";
import DocRef from "../additionalDataSection/docRefsComponents/DocRef";
import MultipleDocVersionRef from "../additionalDataSection/docRefsComponents/MultipleDocVersionRef";
import DocVersionRef from "../additionalDataSection/docRefsComponents/DocVersionRef";
import JsonObjectFragment from "../root/JsonObjectFragment";
import {__} from "../../tools/HelpFunctions";
import sanitizeHtml from "sanitize-html";
import parse from "html-react-parser";

const getCorrectValueForMultiply = (value) => {
    if (value && !Array.isArray(value)) {
        return [value]
    }
    return value
}

const renderJsonObjectFragment = (item, rowId, IsMultipleAllowed) => {
    let lInputValue = item.Value
    if (rowId) {
        if (item.Value && item.Value.length > 0 && (rowId - 1) >= 0 && (rowId - 1) < item.Value.length) {
            lInputValue = item.Value[rowId - 1];
        } else {
            lInputValue = null;
        }
    }
    return <JsonObjectFragment lInputValue={lInputValue}
                               lAllInputValue={item.Value}
                               isRedactorMode={false}
                               rowId={rowId}
                               item={item}
                               IsMultipleAllowed={IsMultipleAllowed}
    />
}

const getAttributeFragment = (item, viewMode, superStyle) => {
    let fragment =
        <span style={superStyle} title={(typeof item.Value === 'string' || !isNaN(item.Value)) ? item.Value : null} className=" text-primary font-weight-normal d-block font-size-lg ">
            {parse(sanitizeHtml(item.Value))}
        </span>
    ;
    // TODO КН 26.02.24 Не уверен что это толковое условие
    if (!item.IsConstant) {
        const IsMultipleAllowed = item.IsMultipleAllowed
        const attributeType = item.AttributeName.IdAttributeType
        if (item.AttributeName.IdAttributeType === DataTypesEnum.Boolean) {
            fragment = (item.Value == 1 ?
                <td>
                <span className="btn btn-icon  btn-xs">
                    <i className="svg-icon icon-color-primary icon-checked"/>
                </span>
                </td>
                :
                <td>
                    <input type={"checkbox"} disabled/>
                </td>)
        } else if (item.AttributeName.IdDictionaries) {
            fragment =
                <AttributeWithDictionaries attributeValue={item.Value}/>
        } else if (attributeType === DataTypesEnum.JsonObject) {
            const correctValue = !IsMultipleAllowed ? item.Value : getCorrectValueForMultiply(item.Value)
            fragment = (IsMultipleAllowed && correctValue) ?
                (
                    < div
                        className="d-flex flex-column beautiful-scroll-10"
                        style={
                            {
                                maxHeight: '5rem',
                                paddingRight:
                                    correctValue.length > 2 ? '0.75rem' : 0
                            }
                        }
                    >
                        {correctValue.map((lValue, lIndex) => {
                            return (
                                <div
                                    className={`${lIndex !== correctValue.length - 1 ? 'mb-3' : ''}`}>
                                    {renderJsonObjectFragment({...item, Value: lValue}, null, IsMultipleAllowed)}
                                </div>
                            )

                        })
                        }
                    </div>
                ) : renderJsonObjectFragment(item, null, IsMultipleAllowed)
        } else if (attributeType === DataTypesEnum.DocRef) {
            fragment = IsMultipleAllowed ?
                <MultipleDocRef
                    item={item}
                    valueFieldName={'Value'}
                    viewMode={viewMode}
                    isRedactorMode={false}
                />
                : <DocRef
                    viewMode={viewMode}
                    docIdValue={item.Value}
                    shortDescription={item.ShortDescription}
                    isRedactorMode={false}
                />
        } else if (attributeType === DataTypesEnum.DocVersionRef) {
            fragment = IsMultipleAllowed ?
                <MultipleDocVersionRef
                    item={item}
                    valueFieldName={'Value'}
                    viewMode={viewMode}
                    isRedactorMode={false}
                />
                : <DocVersionRef viewMode={viewMode}
                                 versionIdValue={item.Value}
                                 shortDescription={item.ShortDescription}
                                 isRedactorMode={false}
                />
        }
    }
    return fragment;
}

class TrMainTable extends React.PureComponent  {
    constructor(props) {
        super(props);

        this.state = {
            checkboxOnHover : false
        };

        this.checkboxOnChange = this.checkboxOnChange.bind(this);
        this.checkboxOnHover = this.checkboxOnHover.bind(this);
        this.checkboxOffHover = this.checkboxOffHover.bind(this);
        this.rightClick = this.rightClick.bind(this);
        this.selectDocument = this.selectDocument.bind(this);
        this.linkTo = this.linkTo.bind(this);
        this.rowRef = React.createRef();
    }
    componentDidMount() {
        if (this.rowRef.current) {
            //TODO aa 08.11.2022 Задача № 20348 БАГ. Пролистывание при смене страницы в Пакете
            if (this.props.lIndex == 0)
               this.props.setRef(this.rowRef)

            if (        // определяем активная строка или нет
                this.props.activeRow && this.props.activeRow.Version.IdDoc === this.props.data.Version.IdDoc
            ) {
                this.props.setRef(this.rowRef)
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.rowRef.current) {
            if (        // определяем активная строка или нет
                this.props.activeRow && this.props.activeRow.Version.IdDoc === this.props.data.Version.IdDoc
            ) {
                this.props.setRef(this.rowRef)
            }
        }
    }

    checkboxOnChange() {
        let {data, checkedFields} = this.props;
        if(data.Info.IsMarkedForDelete) return;

        if(new Set(checkedFields).has(data.Version.IdDoc)) {
            this.props.deleteRowFromChecked({
                IdDoc : data.Version.IdDoc
            });
        } else {
            this.props.addRowToChecked({
                IdDoc : data.Version.IdDoc
            });
        }
    }

    checkboxOnHover() {
        this.setState({
            checkboxOnHover : true
        });
    }

    checkboxOffHover() {
        this.setState({
            checkboxOnHover : false
        });
    }

    // обработка правого клика по строке, ячейке
    rightClick(event, item) {
        this.props.setContextMenuData({
            name : contextMenuTypes.MainTableTR,
            data : {
                item : item,
                tr : this.props.data
            },
            position : {
                x : event.clientX,
                y : event.clientY}
        });
        event.preventDefault();
    }

    // загрузка выбранного документа;
    // Обработка onClick по строке;
    selectDocument() {
        this.props.ActionQueue({
            List : [
                {action : this.props.setLoaderModalData, params : {data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents", key : "TrMainTable91" }}, name : "setLoaderModalData"},
                {action : this.props.selectActiveRow, params : {RowInfo : this.props.data}, name : "selectActiveRow"},
                //TODO aa 31.10.2023 Не менять тип предпросмотра, замечание от Четвертиковой
                // {action : this.props.resetDocumentPreview, params : {}, name : "resetDocumentPreview"},
                {action : this.props.createPreviewBySelectDocument, params : {}, name : "createPreviewBySelectDocument"},
                {action : this.props.collectDataForAdditionalSections_Normal, params : {}, name : "collectDataForAdditionalSections_Normal"},
                {action : this.props.setLoaderModalData, params : {keyDeleted : "TrMainTable117"}, name : "setLoaderModalData"},
            ],
            debug : false,
        });

        if(this.props.viewMode === RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly) {
            this.props.changeDocumentViewMode({
                ViewMode : RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal,
            });
        }
    }

    linkTo(link) {
        if(!this.state.checkboxOnHover) {
            history.push(link);
        } else {
            this.checkboxOnChange();
        }
    }


    render() {

        let {
            data,
            checkedFields
        } = this.props;
        // поиск того где находится версия
        let Version = data.tr.find(tr => tr.Name === "Version.Version").Value;

        // проверяем есть ли в отмеченных документах
        let isChecked = new Set(checkedFields).has(data.Version.IdDoc);// && (data.Info.IsMarkedForDelete!==true));

        let checkboxContent;
        if((isChecked || this.state.checkboxOnHover)
            && !data.Info.IsMarkedForDelete)
        {
            checkboxContent = (
                <td className="py-6" key={createUniqueIdString(6)} onMouseLeave={this.checkboxOffHover} onMouseEnter={this.checkboxOnHover}>
                    <label className="checkbox checkbox-lg checkbox-inline mb-1" >
                        <input type="checkbox" checked={isChecked} onChange={this.checkboxOnChange}/>
                        {/*<span className={this.props.tableRole === "main" && isChecked ? "main-table-delete" : "row-checked"}/>*/}
                        <span className="row-checked" />
                    </label>
                </td>
            );
        }
        else {
            let icon = "icon-Documents icolor";
            if(data.Info.IsMarkedForDelete) {
                icon = "icon-Doc_view_9 icon-color-red";
            }

            checkboxContent = (
                <td className="pl-0" key={createUniqueIdString(6)}  onMouseEnter={this.checkboxOnHover}>
                    <span className="btn btn-icon btn-hover-text-primary btn-lg mb-1 position-relative">
                        <span className="svg-icon svg-icon-xxl">
                            <i className={`svg-icon ${icon}`}/>
                        </span>
                        <span className={`label label-sm label-light-success label-rounded font-weight-bolder position-absolute top-0 left-0 mt-1 mr-1 ${Version > 1 && "label-colored"}`}>
                            {Version}
                        </span>
                    </span>
                </td>
            );
        }

        let superStyle = {lineHeight: "20px"};
        if(this.props.isWrap) {
            superStyle = {
                overflow : "hidden",
                textOverflow: "ellipsis",
                whiteSpace : "nowrap",
            }
        }


        let trStyle = { // чтобы не дергалось при наддвернее на чекбокс
            lineHeight : "min(55px)"
        };
        let timeStyle = {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        };

        // определяем активная строка или нет
        let trClass = this.props.activeRow && this.props.activeRow.Version.IdDoc === this.props.data.Version.IdDoc
            ? "tablez2 tr-active noneFocus"
            : "tablez2 noneFocus";

        const lIndex = this.props.data.Version.IdDoc;

        let link = `${this.props.router.location.pathname}${this.props.router.location.search.split("&d")[0]}&d=${data.Version.IdDoc}`;
        return (
            <tr ref={this.rowRef}
                tabIndex={lIndex}
                onMouseLeave={this.checkboxOffHover}
                className={trClass}
                onClick={()=>{this.linkTo(link)}}
                style={trStyle}>
                {checkboxContent}
                {
                    data.tr.map((item, index) => {

                        if(item.Name === "Version.Version" || !item.UserVisible) {
                            return null;
                        }
                        else if(item.Name === "RegDate" || item.Name === "Version.ModifyTime") {
                            let timeMoment = new moment(item.Value);
                            const timeString = timeMoment.format("L")
                            return(
                                <td key={__.hashCode(item.Name) + '' + index}
                                    onClick={(event) => {this.selectDocument(event)}}
                                    onContextMenu={(event) => {this.rightClick(event, item)}}>
                                    <span title={timeString} className="text-primary font-weight-normal d-block font-size-lg" style={timeStyle}>
                                        <time>{timeString}</time>
                                    </span>
                                </td>
                            );
                        }
                        else {
                            return(
                                <td key={__.hashCode(item.Name) + '' + index}
                                    onClick={(event) => {this.selectDocument(event)}}
                                    onContextMenu={(event) => {this.rightClick(event, item)}}
                                >
                                    {getAttributeFragment(item, this.props.viewMode, superStyle)}
                                </td>
                            );
                        }

                    })
                }
            </tr>
        );
    }
}

const  mapStateToProps = state => {
    return {
        checkedFields : state.document.mainTable.checkedFields,
        activeRow : state.document.mainTable.activeRow,
        adminLevel : state.globalState.user.AdminLevel,

        viewMode : state.document.rootState.viewMode,
        router : state.router,
        isWrap:state.globalState.app.isWrap,
    }
}

const  mapDispatchToProps = {
    setLoaderModalData,
    ActionQueue,
    addRowToChecked,
    deleteRowFromChecked,
    setContextMenuData,
    selectActiveRow,
    changeDocumentViewMode,
    createPreviewBySelectDocument,
    resetDocumentPreview,
    collectDataForAdditionalSections_Normal,
}

export default connect(mapStateToProps,mapDispatchToProps)(TrMainTable);
