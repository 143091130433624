import React from "react";
import {connect} from "react-redux";
import {ModalTypes} from "../../../tools/StaticTypes";
import {setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import DropFileComponent from "../../tools/DropFileComponent/DropFileComponent";
import {
    createPreviewBySelectDocument,
    updateDocFile
} from "../../../store/documents/actionCreators/document_PreviewActionCreator";
import {DocFilePart} from "../../../tools/API_NEW/ServerTypes";

class UpdateFile extends React.Component{
    constructor(props){
        super(props)
        this.state={
            contentOnHover : false,
            OriginalFile:null,
            showOriginalFilesTooltipShow:false,
            closeOnHover:false,
            OtherFiles:[],
            OriginalFileError: false,
            PreviewFile:null,
            filePart:this.props.preview.activeView,
        }
        this.hide = this.hide.bind(this);
        this.hideOutSide = this.hideOutSide.bind(this);
        this.showOriginalFilesRef=React.createRef();
        this.originalFileRef = React.createRef();
        this.previewFileRef=React.createRef();
        this.confirmChanges=this.confirmChanges.bind(this);
        this.send=this.send.bind(this)
    }

    hide() {
        this.props.setModalData({});
    }

    hideOutSide() { // скрывать при клике вне окна
        if(!this.state.contentOnHover) {

            this.props.setModalData({});
        }
    }
   async confirmChanges(){
        // проверка файлов
       this.props.setModalData({
           name: ModalTypes.app.alert,
           data: {
               content: "Загрузка документа...",
               disableButton: true,
               fullBackground: false,
               gif: "documents"
           }
       });

       if(this.state.filePart.toLowerCase()==='original'){
           // заполнение файлов
           if (this.state.OriginalFile == null) {
               this.props.setModalData({
                   name: ModalTypes.app.info,
                   data: {
                       type: "fail",
                       content: `Ошибка заполнения: <br/>Необходимо выбрать оригинальный файл документа (.pdf)`
                   }
               });
               this.setState({OriginalFileError: true});
               return;
           }
           // размер файла
           if (this.state.OriginalFile.size > 1024 * 1024 * 250) {
               this.props.setModalData({
                   name: ModalTypes.app.info,
                   data: {
                       type: "fail",
                       content: `Ошибка заполнения: <br/>Максимальный размер оригинального файла документа не более 250мб`
                   }
               });
               this.setState({OriginalFileError: true});
               return;
           }
           // расширение файла
           if (this.state.OriginalFile.name.split('.').pop() !== "pdf") {
               this.props.setModalData({
                   name: ModalTypes.app.info,
                   data: {
                       type: "fail",
                       content: `Ошибка заполнения: <br/>Оригинальный файл документа должен иметь расширение .pdf`
                   }
               });
               this.setState({OriginalFileError: true});
               return;
           }
       }

       if(this.state.filePart.toLowerCase()==='preview'){
           if (this.state.PreviewFile == null) {
               this.props.setModalData({
                   name: ModalTypes.app.info,
                   data: {
                       type: "fail",
                       content: `Ошибка заполнения: <br/>Необходимо выбрать оригинальный файл документа (.pdf)`
                   }
               });
               this.setState({OriginalFileError: true});
               return;
           }
           if (this.state.PreviewFile)
               if (this.state.PreviewFile.name.split('.').pop() !== "png") {
                   this.props.setModalData({
                       name: ModalTypes.app.info,
                       data: {type: "fail", content: `Ошибка заполнения: <br/>Файл документа должен иметь расширение .png`}
                   });
                   return;
               }

           for (let i = 0; i < this.state.OtherFiles.length; i++) {
               if (this.state.PreviewFile.size > 1024 * 1024 * 250) {
                   this.props.setModalData({
                       name: ModalTypes.app.info,
                       data: {
                           type: "fail",
                           content: `Ошибка заполнения: <br/>Максимальный размер файла "${this.state.OtherFiles[i].name}" не может превышать 250мб`
                       }
                   });
                   return;
               }
           }
       }
        await this.send()
    }

   async send(){
        let part=''
        let file=''

        if(this.state.filePart.toLowerCase()==='original'){
            part=DocFilePart.File
            file=this.state.OriginalFile
        }else if(this.state.filePart.toLowerCase()==='preview'){
            part=DocFilePart.Preview
            file=this.state.PreviewFile
        }
        this.props.updateDocFile({
            IdDocFile:this.props.activeFile.id,
            IdDoc:this.props.activeRow.IdDoc,
            File:file,
            Part:part,
        })
    }

    render(){
        let modalStyle = {
            display : "block",
            backgroundColor: "#061c3e66",
        };
        let containerStyle={
            display:'flex',
            width:'100%',
            height:'100%',
        }
        let close={
            cursor:'pointer',
            color:"var(--originaltwo)"
        }
        if(!this.state.closeOnHover){
            close={}
        }
        return(
            <div className="modal fade show" style={modalStyle} onClick={this.hideOutSide}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered"
                     style={containerStyle}
                     onMouseEnter={() => {this.setState({ contentOnHover : true})}}
                     onMouseLeave={() => {this.setState({ contentOnHover : false})}}>
                    <div className="modal-content" style={{borderRadius:"25px",height:'58%',}}>
                        <div className="modal-header">
                            <h5 className="modal-title">Обновление файла</h5>
                            <div
                                onMouseEnter={()=>this.setState({closeOnHover:true})}
                                onMouseLeave={()=>this.setState({closeOnHover:false})}>
                                <i className="bi bi-x-lg" onClick={this.hide} style={close}></i>
                            </div>
                        </div>
                        <div className="modal-body" style={{display:'flex',flexDirection:'column'}}>
                            <div className="discription" style={{display:'flex',flexDirection:'column',marginBottom:20}}>
                                <label className="discription_title" style={{}}>Название:</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder={this.props.activeFile.name}
                                    disabled
                                    style={{flexGrow:1,}}
                                />
                            </div>
                            <div className="newFile" style={{display:'flex',flexDirection:'column',}}>
                                {/*<div className="newFile_title" style={{}}>Файл:</div>*/}
                                <div style={{display:'flex',flexDirection:'column'}}>
                                    { this.state.filePart.toLowerCase()==="original" &&
                                        <div>
                                    <p className="my-4"><b>Оригинал (PDF)<sup
                                        style={{color: "#dc3545"}}>*</sup></b>
                                    </p>
                                        <DropFileComponent elemRef={this.originalFileRef}
                                                           name={"original"}
                                                           accept={"application/pdf"}
                                                           multiple={false}
                                                           setFiles={(file) => this.setState({OriginalFile: file})}
                                                           value={this.state.OriginalFile}
                                                           style={{}}
                                        />
                                        </div>
                                    }
                                    {
                                        this.state.filePart.toLowerCase()==="preview" &&
                                        <div>
                                            <p className="my-2 text-nowrap"><b>Файл предпросмотра
                                                (PNG)</b>
                                            </p>
                                            <DropFileComponent elemRef={this.previewFileRef}
                                                               name={"preview"}
                                                               accept={"image/png"}
                                                               multiple={false}
                                                               setFiles={(file) => this.setState({PreviewFile: file})}
                                                               value={this.state.PreviewFile}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer" style={{display:'flex',justifyContent:'flex-end'}}>
                            <div className="btn btn-secondary" data-dismiss="modal" onClick={this.hide}>Отмена</div>
                            <div className="btn btn-primary" style={{alignSelf:'stretch'}} onClick={this.confirmChanges}>Заменить</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const  mapStateToProps = state => {
    return {
        activeFile:state.document.preview.activeFile,
        activeRow:state.document.mainTable.activeRow.Version,
        preview: state.document.preview,
    }
}

const  mapDispatchToProps = {
    setModalData,
    updateDocFile,
    createPreviewBySelectDocument,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateFile);
