import Select from "react-select";
import React from "react";
import {connect} from "react-redux";
import {
    setPaginationPageSizeInPackages
} from "../store/packages/actionCreators/package_MainTableActionCreator";
import {setModalData} from "../store/globalState/actionCreators/globalState_AppActionCreator";

class PaginationSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageOptions: [
                { value: '10', label: '10', isCurrent: false },
                { value: '15', label: '15', isCurrent: false },
                { value: '20', label: '20', isCurrent: false },
                { value: '25', label: '25', isCurrent: true},
                { value: '30', label: '30', isCurrent: false },
                { value: '40', label: '40', isCurrent: false },
                { value: '50', label: '50', isCurrent: false },
                { value: '100', label: '100', isCurrent: false }
            ]
        };
    }

    render() {
        return (
            <div className="d-flex align-items-center">
                <Select
                    className="pagination-select text-primary font-weight-bold mr-4 border-0 bg-light-success wid75"
                    closeMenuOnSelect={true}
                    key={this.props.activePage}
                    value={this.state.pageOptions.find(item => item.value == this.props.paginationPageSize)}
                    placeholder={this.props.paginationPageSize}
                    menuPlacement="top"
                    onChange={(item)=>{
                        let pageOptions = this.state.pageOptions
                        pageOptions.map(item => item.isCurrent = false)
                        item.isCurrent = true
                        this.setState({pageOptions: pageOptions})
                        this.props.change(item.value)
                    }}
                    styles={{
                        // TODO КН 15.05.24 Чинит баг, когда при выборе отображения 100 элементов в поле отображается 1.., т.к. цифра не помещается
                        valueContainer: (provided, state) => ({
                            ...provided,
                            paddingLeft: '0',
                            paddingRight: '0',
                            justifyContent: 'center',
                        })
                    }}
                    options={this.state.pageOptions}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            text: 'var(--hover-primary)',
                            primary25: 'var(--hover-primary)',
                            primary50: 'var(--hover-primary)',
                            primary: 'var(--originaltwo)',
                            neutral0: 'var(--elem-bg)',
                            neutral20: 'var(--originaltwo)',
                            neutral30: 'var(--text-primary)',
                            neutral40: 'var(--text-primary)',
                            neutral50: 'var(--text-primary)',
                            neutral80: 'var(--text-primary)',
                        },
                    })}
                />
                {this.props.infoText}
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
    }
}

const  mapDispatchToProps = {
    setPaginationPageSizeInPackages,
    setModalData
}

export default connect(mapStateToProps, mapDispatchToProps)(PaginationSelect);
