import React from "react";
import {connect} from "react-redux";
import {Dropdown} from "react-bootstrap";
import {setLoaderModalData, setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {ModalTypes, RootComponentsStateViewModeTypes} from "../../../tools/StaticTypes";
import {
    PackageCreateTree
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {MyTooltip} from "../../overPage/tooltip/MyTooltip";
import {ActionQueue} from "../../../store/rootReducer";
import {
    combineMainTableAttributes,
    mainTableDownloadDocCollection,
    resetMainTableForUpdate, selectActiveRow
} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {__} from "../../../tools/HelpFunctions";
import {
    collectDataForAdditionalSections_Normal, createPreviewBySelectDocument,
    resetDocumentPreview
} from "../../../store/documents/actionCreators/document_PreviewActionCreator";
import {changeDocumentViewMode} from "../../../store/documents/actionCreators/document_RootStateActionCreator";
import {verifyDocClientLinkIds} from "../../../store/search/actionCreators/search_table";
import {movingDocumentsInPackageFromSearch} from "../../../store/packages/actionCreators/package_TransferActionCreator";
import EntityReport from "../../../tools/EntityReport";
import {IReportState} from "../../../tools/API_NEW/ServerTypes";
import {store} from "../../../index";
import {SEARCH_TABLE_ACTIONS} from "../../../store/search/searchActionsList";
import {changeWrap} from "../../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import {API} from "../../../tools/API_NEW/API";

class MainTableMenuSearch extends React.Component {
    constructor(props) {
        super(props);

        this.lFlagCopyUrl1C = this.props.globalSettings?.WithCopyUrl1C === "1";

        this.state = {
            applyFilterTooltipShow: false,
            cancelFilterTooltipShow: false,
            timeFilterTooltipShow: false,
            exportXlsTooltipShow: false,
            deleteDocTooltipShow: false,
            downloadTooltipShow: false,
            updateTableTooltipShow: false,
            fitInContainerSizeTooltipShow: false,
        }
        this.fitInContainerSize = React.createRef();
        this.exportXlsRef = React.createRef();

        this.copyUrlRef = React.createRef();
        this.copyUrl1CRef = React.createRef();
        this.downloadRef = React.createRef();

        this.moveToPackage = this.moveToPackage.bind(this);
        this.toggleRowsStyle = this.toggleRowsStyle.bind(this);
        this.exportXLS = this.exportXLS.bind(this);
        this.getDocsAsZipArchive = this.getDocsAsZipArchive.bind(this);
    }


    async exportXLS() {
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        // this.props.setModalData({
        //     name : ModalTypes.app.alert,
        //     data : {content : "Загрузка отчета...", disableButton : true, fullBackground : false}
        // });
        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data: {
                content: "Загрузка отчета...",
                disableButton: true,
                fullBackground: false,
                gif: "documents",
                key: "MainTableSearch67"
            }
        }));

        try {
            let reportType = "search";

            let entityReport = new EntityReport({
                Type: reportType,
                idObject: "",
                Columns: [],
                ReportName: "Report"
            });

            let reportGuid = await entityReport.create();

            while (true) {
                let status = await entityReport.getState();
                if (status == IReportState.Done) break;
                await sleep(5000);
            }

            let report = await entityReport.getReportFile();

            var link = document.createElement("a");
            link.setAttribute("href", URL.createObjectURL(report.file));
            link.setAttribute("download", report.name);
            link.click();
            // this.props.setModalData({});
            //Новый прелоадер
            this.props.setLoaderModalData({keyDeleted: "MainTableMenuSearch91"});
        } catch (e) {
            // this.props.setModalData({});
            //Новый прелоадер
            this.props.setLoaderModalData({keyDeleted: "MainTableMenuSearch53"});
        }
    }

    // открыть модальное окно настроек столбцов таблицы
    settingsTable() {
        this.props.setModalData({
            name: ModalTypes.documents.mainTable.Settings,
            data: {}
        });
    }

    moveToPackage() {
        const {activeTab, searchResults} = this.props;
        const checkedFields = searchResults.find(item => item.Id === activeTab.Id)?.mainTable.checkedFields;
        const activeRowSearch = searchResults.find(item => item.Id === activeTab.Id)?.mainTable.activeRow;

        if ((checkedFields && checkedFields.length > 0) || activeRowSearch) {
            this.props.setModalData({
                name: ModalTypes.documents.mainTable.ChangeDocumentType,
                data: {type: "search"}
            });
        }
        this.props.movingDocumentsInPackageFromSearch({});
    }

    // обновление таблицы документов
    updateTable() {
        let activeRow = __.deepCopy(this.props.activeRow);

        this.props.ActionQueue({
            List: [
                {action: this.props.resetMainTableForUpdate, params: {}, name: "resetMainTableForUpdate"},
                {
                    action: this.props.mainTableDownloadDocCollection,
                    params: {ActiveNode: this.props.activeNode},
                    name: "mainTableDownloadDocCollection"
                },
                {
                    action: this.props.combineMainTableAttributes,
                    params: {ActiveNode: this.props.activeNode},
                    name: "combineMainTableAttributes"
                },
                {action: this.props.selectActiveRow, params: {RowInfo: activeRow}, name: "selectActiveRow"},
                {action: this.props.resetDocumentPreview, params: {}, name: "resetDocumentPreview"},
                {action: this.props.createPreviewBySelectDocument, params: {}, name: "createPreviewBySelectDocument"},
                {
                    action: () => {
                        this.props.collectDataForAdditionalSections_Normal({});
                        const viewMode = this.props.tabs.find(tab => tab.Id === this.props.activeTab.Id)?.viewMode;
                        if (viewMode === RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly) {
                            if (activeRow) {
                                this.props.changeDocumentViewMode({
                                    ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal
                                });
                            } else {
                                this.props.changeDocumentViewMode({
                                    ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly
                                });
                            }
                        }
                    }, params: {}, name: "resetDocumentPreview"
                },
            ],
            debug: false
        });
    }

    toggleRowsStyle() {
        this.props.changeWrap({})
    }

    async getDocsAsZipArchive() {
        const {activeTab, searchResults} = this.props;
        const checkedFields = searchResults.find(item => item.Id === activeTab.Id)?.mainTable.checkedFields;
        if (checkedFields.length > 0) {
            try {
                this.props.setLoaderModalData({
                    data: {
                        content: "Загрузка файлов...",
                        disableButton: true,
                        fullBackground: false,
                        gif: "search",
                        key: "MainTableSearch"
                    }
                });

                const zipFile = await API.documents().ExportDocsAsZipArchive({
                    IdDocs: checkedFields,
                    includeMainFile: true,
                    includeAttached: true,
                    includeSigs: true
                });

                const link = document.createElement("a");
                link.setAttribute("href", URL.createObjectURL(zipFile.file));
                link.setAttribute("download", zipFile.name);
                link.click();

                this.props.setLoaderModalData({keyDeleted: "MainTableSearch540"});
            } catch (e) {
                console.log(e)
            }
        }
    }

    render() {
        const {activeTab, searchResults} = this.props;
        const checkedFields = searchResults.find(item => item.Id === activeTab.Id)?.mainTable.checkedFields;
        const activeRowSearch = searchResults.find(item => item.Id === activeTab.Id)?.mainTable.activeRow;
        const isFitInContainerSize = searchResults.find(item => item.Id === activeTab.Id)?.mainTable.settings.isFitInContainerSize;
        let moveToPackagesStyle = {};
        if ((!checkedFields || checkedFields.length <= 0) && !activeRowSearch) {
            moveToPackagesStyle = {
                color: "rgba(63, 66, 84, 0.42)"
            }
        }

        return (
            <div className="card-header border-0 py-2 justify-content-between">
                {checkedFields.length > 0 ?
                    <div>Выбрано файлов: {checkedFields.length}</div>
                    : <div/>
                }

                <div className="card-toolbar">
                    {
                        store.getState().globalState.settings.Content?.WithDownloadFiles == "1" &&
                        <>
                            {
                                this.state.downloadTooltipShow &&
                                <MyTooltip target={this.downloadRef}
                                           text={"Скачать файл"}
                                           show={this.state.downloadTooltipShow}
                                           placement={"top"} delay={500}
                                />
                            }
                            <button className="btn btn-icon btn-sm ml-2" ref={this.downloadRef}
                                    style={checkedFields.length > 0 ? {cursor: "pointer"} : {cursor: "default"}}
                                    disabled={checkedFields.length === 0}
                                    onClick={this.getDocsAsZipArchive}
                                    onMouseEnter={() => {
                                        this.setState({downloadTooltipShow: true})
                                    }}
                                    onMouseLeave={() => {
                                        this.setState({downloadTooltipShow: false})
                                    }}>
                                <i className={`svg-icon svg-icon-sm ${checkedFields.length > 0 ? "icon-color-primary" : "disabled"} icon-Doc_view_4`}/>
                            </button>
                        </>
                    }

                    {
                        this.state.copyUrlTooltipShow &&
                        <MyTooltip target={this.copyUrlRef}
                                   text={"Скопировать ссылку"}
                                   show={this.state.copyUrlTooltipShow}
                                   placement={"top"} delay={500}
                        />
                    }
                    <button className={`btn btn-icon btn-sm ml-2 `} ref={this.copyUrlRef}
                            style={activeRowSearch?.info?.Id ? {cursor: "pointer"} : {cursor: "default"}}
                            disabled={!activeRowSearch?.info?.Id}
                            onClick={(event) => {
                                __.copyUrl(activeRowSearch?.info?.Id);
                                __.showModalCopyComplete(this.props.setModalData);
                            }
                            }
                            onMouseEnter={() => {
                                this.setState({copyUrlTooltipShow: true})
                            }}
                            onMouseLeave={() => {
                                this.setState({copyUrlTooltipShow: false})
                            }}>
                        <i className={`svg-icon svg-icon-sm ${activeRowSearch?.info?.Id ? "text-primary" : "disabled"} icon-Doc_view_14`}/>
                    </button>
                    {this.lFlagCopyUrl1C &&
                        <>
                            {
                                this.state.copyUrl1CTooltipShow &&
                                <MyTooltip target={this.copyUrl1CRef}
                                           text={"Скопировать ссылку для 1С"}
                                           show={this.state.copyUrl1CTooltipShow}
                                           placement={"top"} delay={500}
                                />
                            }
                            <span className="btn btn-icon btn-sm ml-2" ref={this.copyUrl1CRef}
                                  disabled={!activeRowSearch?.info?.Id}
                                  onClick={(event) => {
                                      __.copyUrl1c(activeRowSearch?.info?.Id);
                                      __.showModalCopyComplete(this.props.setModalData);
                                  }}
                                  onMouseEnter={() => {
                                      this.setState({copyUrl1CTooltipShow: true})
                                  }}
                                  onMouseLeave={() => {
                                      this.setState({copyUrl1CTooltipShow: false})
                                  }}>
                            <i className={`svg-icon svg-icon-sm ${activeRowSearch?.info?.Id ? "text-primary" : "disabled"} icon-c_16`}/>
                    </span>
                        </>
                    }

                    {
                        this.state.exportXlsTooltipShow &&
                        <MyTooltip target={this.exportXlsRef}
                                   text={"Экспорт в XLS"}
                                   show={this.state.exportXlsTooltipShow}
                                   placement={"top"} delay={500}
                        />
                    }

                    {
                        this.state.fitInContainerSizeTooltipShow &&
                        <MyTooltip target={this.fitInContainerSize}
                                   text={"Вписать"}
                                   show={this.state.fitInContainerSizeTooltipShow}
                                   placement={"top"} delay={500}
                        />
                    }

                    <span className={`btn btn-icon ${!isFitInContainerSize ? 'btn-circle' : 'btn-primary'} btn-sm mx-2`}
                          ref={this.fitInContainerSize}
                          onClick={() => {
                              if (!isFitInContainerSize)
                                  this.props.fitInContainerSize()
                              else
                                  this.props.unFitInContainerSize();
                              store.dispatch({
                                  type: SEARCH_TABLE_ACTIONS.IS_FIT_IN_WIDTH,
                                  payload: !isFitInContainerSize
                              })
                          }
                          }
                          onMouseEnter={() => {
                              this.setState({fitInContainerSizeTooltipShow: true})
                          }}
                          onMouseLeave={() => {
                              this.setState({fitInContainerSizeTooltipShow: false})
                          }}>
                        <i className={`svg-icon ${isFitInContainerSize ? '' : 'icon-color-primary'} icon-Doc_view_2`}/>
                    </span>

                    <span className="btn btn-icon btn-circle  btn-sm" ref={this.exportXlsRef}
                          style={{marginRight: "20px"}}
                          onMouseEnter={() => {
                              this.setState({exportXlsTooltipShow: true})
                          }}
                          onMouseLeave={() => {
                              this.setState({exportXlsTooltipShow: false})
                          }}
                          onClick={this.exportXLS}
                    >
                        <i className="svg-icon icon-color-primary icon-Table_edit_10"/>
                    </span>

                    <Dropdown>
                        <Dropdown.Toggle className="btn btn-circle btn-outline-success dropdown-toggle">
                            <i className="svg-icon icon-Table_edit_1"/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#" onClick={() => {
                                this.toggleRowsStyle()
                            }} className="dropdown-item">
                                Переключить отображение строк
                            </Dropdown.Item>
                            {
                                this.props.adminLevel >= 1 &&
                                <Dropdown.Item href="#" onClick={this.moveToPackage} className="dropdown-item"
                                               style={moveToPackagesStyle}>
                                    Перенести в пакет
                                </Dropdown.Item>
                            }
                        </Dropdown.Menu>
                    </Dropdown>


                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        activeNode: state.document.tree.activeNode,
        activeRow: state.document.mainTable.activeRow,
        adminLevel: state.globalState.user.AdminLevel,
        globalSettings: state.globalState.settings.Content,
        searchResults: state.search.searchState.searchResults,
        activeTab: state.search.rootState.activeTab,
        tabs: state.search.rootState.tabs,
    }
}

const mapDispatchToProps = {
    ActionQueue,
    changeDocumentViewMode,
    changeWrap,
    collectDataForAdditionalSections_Normal,
    combineMainTableAttributes,
    createPreviewBySelectDocument,
    mainTableDownloadDocCollection,
    movingDocumentsInPackageFromSearch,
    PackageCreateTree,
    resetDocumentPreview,
    resetMainTableForUpdate,
    selectActiveRow,
    setLoaderModalData,
    setModalData,
    verifyDocClientLinkIds,
}


export default connect(mapStateToProps, mapDispatchToProps)(MainTableMenuSearch);
