import produce from "immer";
import {
    PACKAGE_ROOT_STATE_ACTIONS, PACKAGE_SEARCH,
    PACKAGES_TABLE_ACTIONS,
    PACKAGES_TREE_ACTIONS,
} from "./packageActionsList";
import {
    PackagesPreviewType,
    PackagesViewFilters,
    RootComponentsStateViewModeTypes,
    TableTypesEnum
} from "../../tools/StaticTypes";
import {__} from "../../tools/API_NEW/HelpFunctions";
import {TreeTypes} from "../../tools/TreeCombine";
// import {
//     DOCUMENT_PREVIEW_ACTIONS,
//     DOCUMENT_SEARCH_ACTIONS,
//     MAIN_TABLE_ACTIONS,
//     TREE_ACTIONS
// } from "../documents/documentActionsList";


const treeForDocuments = {
    // проверка на загрузку данных
    // для предотвращения повторной загрузки
    // при смене корневого активного компонента
    isLoaded : false,
    // необходим для переключение между
    // обычным деревом и деревом с результатом поиска
    isSearch : false,
    // полученные типы документов
    docs : [],
    // полученные группы (папки) документов
    group : [],
    // готовое для отображения дерево
    render : {
        parent : null,
        type : TreeTypes.ROOT,
        info : {},
        children : []
    },
    // готовое для отображения дерево во время поиска
    search : {
        parent : null,
        type : TreeTypes.ROOT,
        info : {},
        children : []
    },

    // текст поиска в дереве документов
    searchText : "",
    // выбранный узел дерева
    activeNode : null,
};

const tree = {

    // данные с последнего обращения к серверу
    lastLoad : {
        // PackagesDocument
        documents : [],
        // PackagesFolder
        folders : [],
        // PackagesTypes
        types : [],

        documentsForTypes : [],

        documentsTotal : 0,
        foldersTotal : 0,
        documentsOffset : 0
    },

    // режим отображения в таблице (показать все, только пакеты, только документы)
    viewFilter : PackagesViewFilters.All,

    // открытые пакеты, нужно для синхронизации дерева и других компонентов
    openedFolders : [],
    // хлебные крошки, расположение по порядку индексов массива
    // содержит крошки для отображения и быстрого перехода
    breadcrumbs : [],

    // активна ли стрелка вперед
    breadcrumbsArrowNext : false,
    // активна ли стрелка назад
    breadcrumbsArrowPrev : false,

    // номер активной страницы
    paginationPageNumber : 1,
    // кол-во документов отображаемых в таблице
    paginationPageSize : 25,
    // кол-во кнопок отображаемых при пагинации (с цифрами)
    paginationButtonsCount : 3,

    // собранное дерево class PackageTree
    render : null,

    // Активный узел дерева PackageTreeItem
    activeNode : null,
    activeDocument : null,
    activeRow: null,
    activePreviewType: PackagesPreviewType.Preview,

    searchNode : [],

    isExtSearch: false,

    SortedParams: {RegDate: 0, Name: 0, RegNumber: 0, TypeName: 0, ModifyDate: 0,},
    searchInputs : {
        TableRole : "main",
        IsSearch: false,
        IdFilial : null,
        SearchText : null,
        AttributesText : null,
        FullText : null,
        IsSearchInAttachments : null,
        IsSearchInVersions : null,
        IsSearchInMarkedForDelete : null,
        TypeIds : null,
        RegDateAsString : {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
        RegPeriodStart : null,
        RegPeriodFinish : null,
        Name : {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
        RegNumber : {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
        TypeName : {In: ''},
        ModifyDate : {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
        AppliedParams: {RegDateAsString: false, Name: false, RegNumber: false, TypeName: false, ModifyDate: false,},
    }
};

const treeAdditional = {
    // данные с последнего обращения к серверу
    lastLoad : {
        // PackagesDocument
        documents : [],
        // PackagesFolder
        folders : [],
        // PackagesTypes
        types : [],

        documentsForTypes : [],

        documentsTotal : 0,
        foldersTotal : 0,
        documentsOffset : 0
    },

    // режим отображения в таблице (показать все, только пакеты, только документы)
    viewFilter : PackagesViewFilters.All,

    // открытые пакеты, нужно для синхронизации дерева и других компонентов
    openedFolders : [],
    // хлебные крошки, расположение по порядку индексов массива
    // содержит крошки для отображения и быстрого перехода
    breadcrumbs : [],

    // активна ли стрелка вперед
    breadcrumbsArrowNext : false,
    // активна ли стрелка назад
    breadcrumbsArrowPrev : false,

    // номер активной страницы
    paginationPageNumber : 1,
    // кол-во документов отображаемых в таблице
    paginationPageSize : 25,
    // кол-во кнопок отображаемых при пагинации (с цифрами)
    paginationButtonsCount : 3,

    // собранное дерево class PackageTree
    render : null,

    // Активный узел дерева PackageTreeItem
    activeNode : null,
    activeDocument : null,
    activeRow: null,

    searchNodes : [],

    isExtSearch: false,

    searchInputs : {
        TableRole : "additional",
        IsSearch: false,
        IdFilial : null,
        SearchText : null,
        AttributesText : null,
        FullText : null,
        IsSearchInAttachments : null,
        IsSearchInVersions : null,
        IsSearchInMarkedForDelete : null,
        TypeIds : null,
        RegDateAsString : {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
        RegPeriodStart : null,
        RegPeriodFinish : null,
        Name : {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
        RegNumber : {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
        TypeName : {In: ''},
        ModifyDate : {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
        AppliedParams: {RegDateAsString: false, Name: false, RegNumber: false, TypeName: false, ModifyDate: false,},
    }
};

const treeDownload = {


    // данные с последнего обращения к серверу
    lastLoad : {
        // PackagesDocument
        documents : [],
        // PackagesFolder
        folders : [],
        // PackagesTypes
        types : [],

        documentsForTypes : [],

        documentsTotal : 0,
        foldersTotal : 0,
        documentsOffset : 0
    },

    // режим отображения в таблице (показать все, только пакеты, только документы)
    viewFilter : PackagesViewFilters.All,

    // открытые пакеты, нужно для синхронизации дерева и других компонентов
    openedFolders : [],
    // хлебные крошки, расположение по порядку индексов массива
    // содержит крошки для отображения и быстрого перехода
    breadcrumbs : [],

    // активна ли стрелка вперед
    breadcrumbsArrowNext : false,
    // активна ли стрелка назад
    breadcrumbsArrowPrev : false,

    // номер активной страницы
    paginationPageNumber : 1,
    // кол-во документов отображаемых в таблице
    paginationPageSize : 25,
    // кол-во кнопок отображаемых при пагинации (с цифрами)
    paginationButtonsCount : 3,

    // собранное дерево class PackageTree
    render : null,
    search: null,

    // Активный узел дерева PackageTreeItem
    activeNode : null,
    activeDocument : null,
    IsVisibleModal : null,
    activeRow: null,


    isSearch: false,
    searchNodes : [],
    // TODO № 21556
    searchNode : [],

    isExtSearch: false,

    SortedParams: {RegDate: 0, Name: 0, RegNumber: 0, TypeName: 0, ModifyDate: 0,},

    searchInputs : {
        TableRole : "download",
        IsSearch: false,
        IdFilial : null,
        SearchText : null,
        AttributesText : null,
        FullText : null,
        IsSearchInAttachments : null,
        IsSearchInVersions : null,
        IsSearchInMarkedForDelete : null,
        TypeIds : null,
        RegDateAsString : {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
        RegPeriodStart : null,
        RegPeriodFinish : null,
        Name : {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
        RegNumber : {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
        TypeName : {In: ''},
        ModifyDate : {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
        AppliedParams: {RegDateAsString: false, Name: false, RegNumber: false, TypeName: false, ModifyDate: false,},
    }
};


const mainTable = {

    sortDirection : false,
    sortField : "",

    // загруженные для отображения документы
    collection : {
        docs : [],
        totalInServer : 0,
    },
    // собранное тело таблицы
    body : null,
    // собранные заголовки таблицы
    header : null,
    // отмеченные документы в таблице
    // использовать как Set : методы -> add, has, delete, size
    checkedFields : [],
    checkedFieldsMainTable : [],
    // номер страницы в пагинации
    paginationPageNumber : 1,
    // кол-во документов отображаемых в таблице
    paginationPageSize : 25,
    // кол-во кнопок отображаемых при пагинации (с цифрами)
    paginationButtonsCount : 3,
    // существующие для таблицы настройки
    settings : {
        // размер таблицы от размера столбцов
        width : "100%",
        // режим отображения текста
        textViewMode : {
            header : "oneLine", // oneLine or multiLine
            body : "oneLine" // oneLine or multiLine
        },
        // скрытые столбцы таблицы
        hideColumns : [],
    },
    // примененные к таблице фильтры
    filters : [],
    // выбранная в таблице строка (документ)
    activeRow : null,
    //инфо о пакете, выбранным правой кнопкой мыши
    rightClickPackage : {},
};

// состояние всего корневого компонента
const rootState = {
    // режим вида отображения компонента
    // какие компоненты в каком виде будут отображены
    viewMode : RootComponentsStateViewModeTypes.PackagesApp.Tree,
    viewModeDownload : RootComponentsStateViewModeTypes.PackagesApp.Tree_Download,
    // вид для основной таблицы
    viewModeMainTable : RootComponentsStateViewModeTypes.PackagesApp.Table_bigCards,
    // вид для дополнительной таблицы
    viewModeAdditionalTable : RootComponentsStateViewModeTypes.PackagesApp.Table_bigCards,
    viewModeDownloadTable : RootComponentsStateViewModeTypes.PackagesApp.Table_bigCards,

    viewModePreview : "welcome", // "normal", two_table || right_document_table || welcome
    viewModePreview_Size : "normal", // "half", "full", || left, right, both
    // переключение видимости asideMenu
    isOpenAsideMenu: true
};

const treeSearchQuery = [
    {TableType: TableTypesEnum.Main, Search: ""},
    {TableType: TableTypesEnum.Modal, Search: ""},
]

const treeSearch = {
    folders: [],
    foldersTotal:0,
    tree: null,
    openedFolders: []
}
const treeSearchAdditional = {
    folders: [],
    foldersTotal: 0,
    tree: null,
    openedFolders: []
}
const treeSearchModal = {
    folders: [],
    foldersTotal: 0,
    tree: null,
    openedFolders: [],
}

const treeSearchDownload = {
    folders:[],
    foldersTotal:0
}

// временные данные
// нужны для предотвращения
// повторных запросов к серверу
const temporaryData = {

};

const search = {
    SortedParams: {RegDate: 0, Name: 0, RegNumber: 0, TypeName: 0, ModifyDate: 0,},
    searchInputs : {
        TableRole : null,
        IdFilial : null,
        SearchText : null,
        AttributesText : null,
        FullText : null,
        IsSearchInAttachments : null,
        IsSearchInVersions : null,
        IsSearchInMarkedForDelete : null,
        TypeIds : null,
        RegDateAsString : {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
        RegPeriodStart : null,
        RegPeriodFinish : null,
        Name : {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
        RegNumber : {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
        TypeName : {In: ''},
        ModifyDate : {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
        AppliedParams: {RegDateAsString: false, Name: false, RegNumber: false, TypeName: false, ModifyDate: false},
    },
    isSearch : false,
};

const rightTableSearchDoc = {
    searchInputs : [],
    isSearch : false,
}

const initialState = {
    tree,
    treeAdditional,
    mainTable,
    rootState,
    temporaryData,
    treeSearchQuery,
    treeSearch,
    treeSearchAdditional,
    treeSearchModal,
    search,
    rightTableSearchDoc,
    treeForDocuments,
    treeDownload,
    treeSearchDownload
};

export const packagesReducer = (state = initialState, action) => {
    return produce(state, newState => {
        // tree
        //получение документов в пакете
        if(action.type === PACKAGES_TREE_ACTIONS.GET_DOC_IN_PAC) {
            if(action.payload.IsDownload || action.payload.tableType === "download") {
                newState.treeDownload.lastLoad.documents = action.payload.documents;
                newState.treeDownload.lastLoad.documentsTotal = action.payload.totalRecordsCount;
                newState.treeDownload.lastLoad.documentsOffset = action.payload.documentsOffset;
            } else if(action.payload.tableType === "main") {
                newState.tree.lastLoad.documents = action.payload.documents;
                newState.tree.lastLoad.documentsTotal = action.payload.totalRecordsCount;
                newState.tree.lastLoad.documentsOffset = action.payload.documentsOffset;
            } else if (action.payload.tableType === "additional") {
                newState.treeAdditional.lastLoad.documents = action.payload.documents;
                newState.treeAdditional.lastLoad.documentsTotal = action.payload.totalRecordsCount;
                newState.treeAdditional.lastLoad.documentsOffset = action.payload.documentsOffset;
            }
        }
        // получение пакетов(папок)
        else if(action.type === PACKAGES_TREE_ACTIONS.GET_GROUP) {
            if(action.payload.IsDownload || action.payload.tableType === "download") {
                newState.treeDownload.lastLoad.folders = action.payload.folders;
                newState.treeDownload.lastLoad.foldersTotal = action.payload.totalRecordsCount;
            }
            else {
                if(action.payload.tableType === "main") {
                    newState.tree.lastLoad.folders = action.payload.folders;
                    newState.tree.lastLoad.foldersTotal = action.payload.totalRecordsCount;
                }
                else {
                    newState.treeAdditional.lastLoad.folders = action.payload.folders;
                    newState.treeAdditional.lastLoad.foldersTotal = action.payload.totalRecordsCount;
                }
            }

        }
        //получение типов документов в пакете
        else if(action.type === PACKAGES_TREE_ACTIONS.GET_TYPE_DOC) {
            if(action.payload.IsDownload || action.payload.tableType === "download") {
                newState.treeDownload.lastLoad.types = action.payload.types;
            }
            else {
                if(action.payload.tableType === "main")
                    newState.tree.lastLoad.types = action.payload.types;
                else newState.treeAdditional.lastLoad.types = action.payload.types;
            }

        }
        //создание дерева пакетов для отображения
        else if(action.type === PACKAGES_TREE_ACTIONS.CREATE) {
            if(action.payload?.IsDownload || action.payload.tableType === "download") {
                newState.treeDownload.render = action.payload.tree;
            }
            else {
                if(action.payload.tableType === "main")
                    newState.tree.render = action.payload.tree;
                else newState.treeAdditional.render = action.payload.tree;
            }
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.SEARCH_TOGGLE_DOWNLOAD_SEARCH) {
            newState.treeDownload.isSearch = action.payload;
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.SELECT_NODE) {
            if(action.payload.IsDownload || action.payload.tableType === "download") {
                newState.treeDownload.activeNode = action.payload.activeNode;
            }
            else {
                if(action.payload.tableType === "main")
                    newState.tree.activeNode = action.payload.activeNode;
                else {
                    newState.treeAdditional.activeNode = action.payload.activeNode;
                }
            }
        }

        else if(action.type === PACKAGES_TREE_ACTIONS.SELECT_DOCUMENT) {
            if(action.payload.IsDownload  || action.payload.tableType === "download") {
                newState.treeDownload.activeDocument = action.payload.activeDocument;

                newState.treeDownload.IsVisibleModal = action.payload.IsVisibleModal;
            }
            else {
                if(action.payload.tableType === TableTypesEnum.Main) {
                    newState.tree.activeDocument = action.payload.activeDocument;
                    newState.tree.IsVisibleModal = action.payload.IsVisibleModal;
                } else if(action.payload.tableType === TableTypesEnum.DocumentsRight) {
                    newState.mainTable.activeDocument = action.payload.activeDocument;
                } else { newState.treeAdditional.activeDocument = action.payload.activeDocument;
                   newState.treeAdditional.IsVisibleModal = action.payload.IsVisibleModal;
                }
            }
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.UPDATE_SIGNATURE) {
            //Ищем файл и заменяем у него подпись
            let lFile = newState.tree.activeDocument?.additionalSections?.filesSection?.files?.Records.find(item => item.Id === (action.payload.RowInfo.Id ? action.payload.RowInfo.IdDocFile : action.payload.RowInfo.idDocFile));

            if (lFile)
                if (action.payload.RowInfo.Id) {
                    for (let i in lFile.signatures) {
                        if (lFile.signatures[i].Id == action.payload.RowInfo.Id) {
                            lFile.signatures[i] = action.payload.lSignature;
                            break;
                        }
                    }
                } else {
                    lFile.signatures = action.payload.lSignature.Records;
                }


            lFile = newState.treeAdditional?.activeDocument?.additionalSections?.filesSection?.files?.Records.find(item => item.Id === (action.payload.RowInfo.Id ? action.payload.RowInfo.IdDocFile : action.payload.RowInfo.idDocFile));
            if (lFile)
                if (action.payload.RowInfo.Id) {
                    for (let i in lFile.signatures) {
                        if (lFile.signatures[i].Id == action.payload.RowInfo.Id) {
                            lFile.signatures[i] = action.payload.lSignature;
                            break;
                        }
                    }
                } else {
                    lFile.signatures = action.payload.lSignature.Records;
                }
            lFile = newState.treeDownload?.activeDocument?.additionalSections?.filesSection?.files?.Records.find(item => item.Id === (action.payload.RowInfo.Id ? action.payload.RowInfo.IdDocFile : action.payload.RowInfo.idDocFile));
            if (lFile)
                if (action.payload.RowInfo.Id) {
                    for (let i in lFile.signatures) {
                        if (lFile.signatures[i].Id == action.payload.RowInfo.Id) {
                            lFile.signatures[i] = action.payload.lSignature;
                            break;
                        }
                    }
                } else {
                    lFile.signatures = action.payload.lSignature.Records;
                }

            lFile = newState.mainTable?.activeDocument?.additionalSections?.filesSection?.files?.Records.find(item => item.Id === (action.payload.RowInfo.Id ? action.payload.RowInfo.IdDocFile : action.payload.RowInfo.idDocFile));
            if (lFile)
                if (action.payload.RowInfo.Id) {
                    for (let i in lFile.signatures) {
                        if (lFile.signatures[i].Id == action.payload.RowInfo.Id) {
                            lFile.signatures[i] = action.payload.lSignature;
                            break;
                        }
                    }
                } else {
                    lFile.signatures = action.payload.lSignature.Records;
                }

        }
        else if(action.type === PACKAGES_TREE_ACTIONS.DELETE_SIGNATURE) {
            //Ищем файл и удаляем у него подпись
            const lFile = newState.tree.activeDocument?.additionalSections?.filesSection?.files?.Records.find(item => item.Id === action.payload.IdDocFile);

            if (lFile) {
                lFile.signatures = lFile.signatures.filter(item => item.Id !== action.payload.Id)
            }
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.SET_ROW_FILE_FOR_SHOW_SIGNATURE) {
            //Ищем файл и ставим у него признак просмотра подписи
            let lFile = newState.tree?.activeDocument?.additionalSections?.filesSection?.files?.Records.find(item => item.Id === action.payload.Id);
            if (lFile)
                lFile.showSignatures = !lFile.showSignatures;
            lFile = newState.treeAdditional?.activeDocument?.additionalSections?.filesSection?.files?.Records.find(item => item.Id === action.payload.Id);

            if (lFile)
                lFile.showSignatures = !lFile.showSignatures
            lFile = newState.treeDownload?.activeDocument?.additionalSections?.filesSection?.files?.Records.find(item => item.Id === action.payload.Id);
            if (lFile)
                lFile.showSignatures = !lFile.showSignatures
            lFile = newState.mainTable?.activeDocument?.additionalSections?.filesSection?.files?.Records.find(item => item.Id === action.payload.Id);
            if (lFile)
                lFile.showSignatures = !lFile.showSignatures
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.SELECT_ACTIVE_ROW) {
            if(action.payload.IsDownload  || action.payload.tableType === "download") {
                newState.treeDownload.activeRow = action.payload.activeRow;

                newState.treeDownload.IsVisibleModal = action.payload.IsVisibleModal;
            }
            else {
                if(action.payload.tableType === "main") {
                    newState.tree.activeRow = action.payload.activeRow;
                    newState.tree.IsVisibleModal = action.payload.IsVisibleModal;
                }
                else { newState.treeAdditional.activeRow = action.payload.activeRow;
                    newState.treeAdditional.IsVisibleModal = action.payload.IsVisibleModal;
                }
            }
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.SET_FILE_CONTENT) {
            if(action.payload.IsDownload  || action.payload.tableType === "download") {
                newState.treeDownload.activeDocument.additionalSections.contentSection.content = action.payload;
            }
            else {
                if(action.payload.tableType === "main") {
                    newState.tree.activeDocument.additionalSections.contentSection.content = action.payload;
                }
                else {
                    newState.treeAdditional.activeDocument.additionalSections.contentSection.content = action.payload;
                }
            }
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.SET_DEPARTMENTS_DATA) {
            if(action.payload.IsDownload  || action.payload.tableType === TableTypesEnum.Download) {
                newState.treeDownload.activeDocument.additionalSections.departmentsSection = action.payload.departmentsSection;
            }
            else {
                if(action.payload.tableType === TableTypesEnum.Main) {
                    newState.tree.activeDocument.additionalSections.departmentsSection = action.payload.departmentsSection;
                }
                else if (action.payload.tableType === TableTypesEnum.DocumentsRight) {
                    newState.mainTable.activeDocument.additionalSections.departmentsSection = action.payload.departmentsSection;
                }
                else {
                    newState.treeAdditional.activeDocument.additionalSections.departmentsSection = action.payload.departmentsSection;
                }
            }
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.SELECT_VIEW_FOR_ACTIVE_FILE) {
            if(action.payload.IsDownload  || action.payload.tableType === "download") {
                newState.treeDownload.activeDocument.preview.activeView =
                    action.payload.resetView
                        ? PackagesPreviewType.Preview
                        : action.payload.view;
            }
            else {
                if(action.payload.tableType === "main") {
                    newState.tree.activePreviewType =
                        action.payload.resetView
                            ? PackagesPreviewType.Preview
                            : action.payload.view;
                    // newState.tree.activeDocument.preview.activeView = action.payload.view;
                }
                else {
                    newState.treeAdditional.activeDocument.preview.activeView =
                        action.payload.resetView
                            ? PackagesPreviewType.Preview
                            : action.payload.view;
                }
            }
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.TOGGLE_FOLDERS) {
            if(action.payload.IsDownload  || action.payload.tableType === "download") {
                newState.treeDownload.openedFolders = action.payload.openedFolders;
            }
            else {
                if(action.payload.tableType === "main")
                    newState.tree.openedFolders = action.payload.openedFolders;
                else newState.treeAdditional.openedFolders = action.payload.openedFolders;
            }
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.BREADCRUMBS_ADD) {
            if(action.payload.IsDownload || action.payload.tableType === "download") {
                newState.treeDownload.breadcrumbs = action.payload.breadcrumbsStore;
                newState.treeDownload.breadcrumbsArrowPrev = action.payload.breadcrumbsStore.length > 1;
            }
            else {
                if(action.payload.tableType === "main") {
                    newState.tree.breadcrumbs = action.payload.breadcrumbsStore;
                    newState.tree.breadcrumbsArrowPrev = action.payload.breadcrumbsStore.length > 1;
                } else {
                    newState.treeAdditional.breadcrumbs = action.payload.breadcrumbsStore;
                    newState.treeAdditional.breadcrumbsArrowPrev = action.payload.breadcrumbsStore.length > 1;
                }
            }
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.BREADCRUMBS_FIND_AND_ADD) {
            if(action.payload.IsDownload || action.payload.tableType === "download") {
                newState.treeDownload.breadcrumbs = action.payload.breadcrumbsStore;
                newState.treeDownload.breadcrumbsArrowPrev = action.payload.breadcrumbsStore.length > 1;
                newState.treeDownload.breadcrumbsArrowNext = false;
            }
            else {
                if(action.payload.tableType === "main") {
                    newState.tree.breadcrumbs = action.payload.breadcrumbsStore;
                    newState.tree.breadcrumbsArrowPrev = action.payload.breadcrumbsStore.length > 1;
                    newState.tree.breadcrumbsArrowNext = false;
                } else {
                    newState.treeAdditional.breadcrumbs = action.payload.breadcrumbsStore;
                    newState.treeAdditional.breadcrumbsArrowPrev = action.payload.breadcrumbsStore.length > 1;
                    newState.treeAdditional.breadcrumbsArrowNext = false;
                }
            }
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.BREADCRUMBS_MOVE_PREV) {
            if(action.payload.IsDownload || action.payload.tableType === "download") {
                newState.treeDownload.breadcrumbs = action.payload.breadcrumbs;
                newState.treeDownload.breadcrumbsArrowPrev = action.payload.breadcrumbsArrowPrev;
                newState.treeDownload.breadcrumbsArrowNext = true;
            }
            else {
                if(action.payload.tableType === "main") {
                    newState.tree.breadcrumbs = action.payload.breadcrumbs;
                    newState.tree.breadcrumbsArrowPrev = action.payload.breadcrumbsArrowPrev;
                    newState.tree.breadcrumbsArrowNext = true;
                } else {
                    newState.treeAdditional.breadcrumbs = action.payload.breadcrumbs;
                    newState.treeAdditional.breadcrumbsArrowPrev = action.payload.breadcrumbsArrowPrev;
                    newState.treeAdditional.breadcrumbsArrowNext = true;
                }
            }
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.BREADCRUMBS_MOVE_NEXT) {
            if(action.payload.IsDownload  || action.payload.tableType === "download") {
                newState.treeDownload.breadcrumbs = action.payload.breadcrumbs;
                newState.treeDownload.breadcrumbsArrowNext = action.payload.breadcrumbsArrowNext;
                newState.treeDownload.breadcrumbsArrowPrev = true;
            }
            else {
                if(action.payload.tableType === "main") {
                    newState.tree.breadcrumbs = action.payload.breadcrumbs;
                    newState.tree.breadcrumbsArrowNext = action.payload.breadcrumbsArrowNext;
                    newState.tree.breadcrumbsArrowPrev = true;
                } else {
                    newState.treeAdditional.breadcrumbs = action.payload.breadcrumbs;
                    newState.treeAdditional.breadcrumbsArrowNext = action.payload.breadcrumbsArrowNext;
                    newState.treeAdditional.breadcrumbsArrowPrev = true;
                }
            }
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.BREADCRUMBS_MOVE_SELECT) {
            if(action.payload.IsDownload || action.payload.tableType === "download") {
                newState.treeDownload.breadcrumbs = action.payload.breadcrumbs;
                newState.treeDownload.breadcrumbsArrowPrev = action.payload.breadcrumbsArrowPrev;
                newState.treeDownload.breadcrumbsArrowNext = action.payload.breadcrumbsArrowNext;
            }
            else {
                if(action.payload.tableType === "main") {
                    newState.tree.breadcrumbs = action.payload.breadcrumbs;
                    newState.tree.breadcrumbsArrowPrev = action.payload.breadcrumbsArrowPrev;
                    newState.tree.breadcrumbsArrowNext = action.payload.breadcrumbsArrowNext;
                } else {
                    newState.treeAdditional.breadcrumbs = action.payload.breadcrumbs;
                    newState.treeAdditional.breadcrumbsArrowPrev = action.payload.breadcrumbsArrowPrev;
                    newState.treeAdditional.breadcrumbsArrowNext = action.payload.breadcrumbsArrowNext;
                }
            }
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.BREADCRUMBS_SET_PAGE_NUMBER) {
            if(action.payload.IsDownload || action.payload.tableType === "download") {
                newState.treeDownload.breadcrumbs = action.payload.breadcrumbs;
                newState.treeDownload.breadcrumbsArrowPrev = action.payload.breadcrumbsArrowPrev;
                newState.treeDownload.breadcrumbsArrowNext = action.payload.breadcrumbsArrowNext;
            }
            else {
                if(action.payload.tableType === "main") {
                    newState.tree.breadcrumbs = action.payload.breadcrumbs;
                    newState.tree.breadcrumbsArrowPrev = action.payload.breadcrumbsArrowPrev;
                    newState.tree.breadcrumbsArrowNext = action.payload.breadcrumbsArrowNext;
                } else {
                    newState.treeAdditional.breadcrumbs = action.payload.breadcrumbs;
                    newState.treeAdditional.breadcrumbsArrowPrev = action.payload.breadcrumbsArrowPrev;
                    newState.treeAdditional.breadcrumbsArrowNext = action.payload.breadcrumbsArrowNext;
                }
            }
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.CHANGE_VIEW_MODE_PACKAGE_TABLES) {
            if(action.payload.IsDownload  || action.payload.tableType === "download" ||  action.payload.Table === "download" ) {
                newState.rootState.viewModeDownloadTable = action.payload.ViewMode;
            }
            else {
                if(action.payload.Table === "main") {
                    newState.rootState.viewModeMainTable = action.payload.ViewMode;
                } else {
                    newState.rootState.viewModeAdditionalTable = action.payload.ViewMode;
                }
            }

        }
        else if(action.type === PACKAGES_TREE_ACTIONS.COPY_TO_ADDITIONAL_TREE) {
            newState.treeAdditional = action.payload;
            newState.treeAdditional.searchInputs.TableRole = "additional"
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.SET_TREE_SEARCH_QUERY) {
            newState.treeSearchQuery = action.payload;
        }

        //table
        else if(action.type === PACKAGES_TABLE_ACTIONS.GET_PACKAGE_AFTER_RIGHT_CLICK){
            newState.mainTable.rightClickPackage=action.payload;
        }
        else if(action.type ===  PACKAGES_TABLE_ACTIONS.CHANGE_PAGINATION_PAGE) {
            if(action.payload.IsDownload  || action.payload.tableType === "download") {
                newState.treeDownload.paginationPageNumber = action.payload.PageNumber;
            }
            else {
                if(action.payload.tableType === "main")
                    newState.tree.paginationPageNumber = action.payload.PageNumber;
                else newState.treeAdditional.paginationPageNumber = action.payload.PageNumber;
            }

        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.CHANGE_VIEW_FILTER) {
            if(action.payload.IsDownload  || action.payload.tableType === "download") {
                newState.treeDownload.viewFilter = action.payload.newValue;
            }
            else {
                if(action.payload.tableType === "main")
                    newState.tree.viewFilter = action.payload.newValue;
                else newState.treeAdditional.viewFilter = action.payload.newValue;
            }
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.CHANGE_SORT_DIRECTION) {
            newState.mainTable.sortDirection = action.payload.dir;
            newState.mainTable.sortField = action.payload.sortField;
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.CHANGE_COUNT_ITEM_ON_PAGE) {
            if(action.payload.tableType === "main")
                newState.tree.paginationPageSize = action.payload.Count
            else
                if(action.payload.tableType === "additional")
                    newState.treeAdditional.paginationPageSize = action.payload.Count
                //TODO Баг Смена кол-ва записей на странице в доп таблице
                else {
                    newState.treeDownload.paginationPageSize = action.payload.Count;
                }

        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.SELECT_ACTIVE_PREVIEW_FILE) {
            if(action.payload.tableType === "main") {
                let preview = __.deepCopy(state.tree.activeDocument.preview);
                preview.activeFileIndex =  action.payload.index;
                preview.typeFile =  action.payload.Type;
                newState.tree.activeDocument.preview = preview;
            }
            else {
                let preview = __.deepCopy(state.treeAdditional.activeDocument.preview);
                preview.activeFileIndex =  action.payload.index;
                newState.treeAdditional.activeDocument.preview = preview;
            }
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.SET_VIEW_MODE_PREVIEW) {
            newState.rootState.viewModePreview = action.payload.ViewMode;
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.SET_SIZE_PREVIEW) {
            newState.rootState.viewModePreview_Size = action.payload.Size;
        }
        else if(action.type === PACKAGE_SEARCH.UPDATE_INPUTS_FIELD){
            if (action.payload.TableRole === "main") {
                newState.tree.searchInputs = action.payload;
            } else if (action.payload.TableRole === "additional") {
                newState.treeAdditional.searchInputs = action.payload;
            } else if (action.payload.TableRole === "download") {
                newState.treeDownload.searchInputs = action.payload;
            }
        }
        else if(action.type === PACKAGE_SEARCH.UPDATE_INPUTS_FIELD_SORTING){
            if (action.payload.TableRole === "main") {
                newState.tree.SortedParams = action.payload.SortedParams;
            } else if (action.payload.TableRole === "additional") {
                newState.treeAdditional.SortedParams = action.payload.SortedParams;
            } else if (action.payload.TableRole === "download") {
                newState.treeDownload.SortedParams = action.payload.SortedParams;
            }
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.GET_DOC_IN_PAC_SEARCH) {
            newState.temporaryData = {
                documents: action.payload.documents,
                totalRecordsCount: action.payload.totalRecordsCount
            }
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.SEARCH_NODE) {
            if(action.payload.tableType === "main"){
                newState.tree.searchNode = action.payload.searchNode;
                newState.tree.isSearch = true;
            } else if(action.payload.tableType === "additional"){
                newState.treeAdditional.searchNode = action.payload.searchNode;
                newState.treeAdditional.isSearch = true;
            } else {
                // TODO КН 02.05.24 по хорошему считаю что здесь должно быть  if(action.payload.tableType === "download"), но сюда может придти еще 'modal', поэтому пока не трогаю
                newState.treeDownload.searchNode = action.payload.searchNode;
                newState.treeDownload.isSearch = true;
            }
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.IS_SEARCH) {
            if(action.payload.tableType === "main") {
                newState.tree.isSearch = action.payload.IsSearch;
            } else if(action.payload.tableType === "additional") {
                newState.treeAdditional.isSearch = action.payload.IsSearch;
            } else if (action.payload.tableType === "download") {
                newState.treeDownload.isSearch = action.payload.IsSearch;
            }
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.IS_EXT_SEARCH) {
            if(action.payload.tableType === "main") {
                newState.tree.isExtSearch = action.payload.IsExtSearch;
            } else if(action.payload.tableType === "additional") {
                newState.treeAdditional.isExtSearch = action.payload.IsExtSearch;
            } else if (action.payload.tableType === "download") {
                newState.treeDownload.isExtSearch = action.payload.IsExtSearch;
            }
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.GET_GROUP_SEARCH) {
            if(action.payload.IsDownload || action.payload.tableType === TableTypesEnum.Download) {
                newState.treeSearchDownload.folders = action.payload.folders;
                newState.treeSearchDownload.foldersTotal = action.payload.totalRecordsCount;
            }
            else {
                if(action.payload.tableType === TableTypesEnum.Main) {
                    newState.treeSearch.folders = action.payload.folders;
                    newState.treeSearch.foldersTotal = action.payload.totalRecordsCount;
                }
                if (action.payload.tableType === TableTypesEnum.Modal) {
                    newState.treeSearchModal.folders = action.payload.folders;
                    newState.treeSearchModal.foldersTotal = action.payload.totalRecordsCount;
                }
                else {
                    newState.treeSearchAdditional.folders = action.payload.folders;
                    newState.treeSearchAdditional.foldersTotal = action.payload.totalRecordsCount;
                }
            }
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.CREATE_SEARCH_TREE) {
            if(action.payload.IsDownload  || action.payload.tableType === TableTypesEnum.Download) {
                newState.treeSearchDownload.tree = action.payload.tree;
            }
            else {
                if(action.payload.tableType === TableTypesEnum.Main) {
                    newState.treeSearch.tree = action.payload.tree;
                    if(action.payload.openedFolders) {
                        newState.treeSearch.openedFolders = action.payload.openedFolders;
                    }
                } else if (action.payload.tableType === TableTypesEnum.Modal) {
                    newState.treeSearchModal.tree = action.payload.tree;
                    if(action.payload.openedFolders) {
                        newState.treeSearchModal.openedFolders = action.payload.openedFolders;
                    }
                } else {
                    newState.treeSearchAdditional.tree = action.payload.tree;
                    if(action.payload.openedFolders) {
                        newState.treeSearchAdditional.openedFolders = action.payload.openedFolders;
                    }
                }
            }
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.PATH_TO_NODE) {
                newState.temporaryData = action.payload.Nodes;
        }
        else if(action.type === PACKAGES_TREE_ACTIONS.SELECT_ACTIVE_NODE) {
            if (action.payload.tableType === TableTypesEnum.Main) {
                newState.treeSearch.activeNode = action.payload.activeNode
            } else if (action.payload.tableType === TableTypesEnum.Modal) {
                newState.treeSearchModal.activeNode = action.payload.activeNode
            } else {
                newState.treeSearchAdditional.activeNode = action.payload.activeNode
            }
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.COPY_DOCUMENT_TREE) {
            newState.treeForDocuments = action.payload.tree;
        }

        // rootState
        else if(action.type === PACKAGE_ROOT_STATE_ACTIONS.CHANGE_VIEW_MODE) {
            newState.rootState.viewMode = action.payload;
        }
        else if(action.type === PACKAGE_ROOT_STATE_ACTIONS.CHANGE_VIEW_MODE_DOWNLOAD) {
            newState.rootState.viewModeDownload = action.payload;
        }
        else if(action.type === PACKAGE_ROOT_STATE_ACTIONS.TOGGLE_SHOW_ASIDE_MENU) {
            newState.rootState.isOpenAsideMenu = action.payload;
        }

        // document right
        else if(action.type === PACKAGES_TABLE_ACTIONS.SEARCH_DOCUMENT_RIGHT) {
            newState.treeForDocuments.search = action.payload;
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.SEARCH_TOGGLE_DOCUMENT_RIGHT) {
            newState.treeForDocuments.isSearch = action.payload;
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.SELECT_NODE_DOCUMENT_RIGHT) {
            newState.treeForDocuments.activeNode = action.payload;
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.DOWNLOAD_DOC_COLLECTION_DOCUMENT_RIGHT) {
            newState.mainTable.collection = action.payload;
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.COMBINE_ATTRIBUTE_DOCUMENT_RIGHT) {
            newState.mainTable.header = action.payload.Headers;
            newState.mainTable.body = action.payload.Body;
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.SET_PAGINATION_PAGE_NUMBER_DOCUMENT_RIGHT) {
            newState.mainTable.paginationPageNumber = action.payload;
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.RESET_FOR_UPDATE_DOCUMENT_RIGHT) {
            newState.mainTable = {...newState.mainTable, ...{
                    settings : action.payload.settings,
                    checkedFields : [],
                    paginationPageNumber : 1,
                    activeRow : null
                }};
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.CHECK_ALL_ROWS_ON_PAGE_DOCUMENT_RIGHT) {
            newState.mainTable.checkedFields = action.payload;
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.CHECK_ALL_ROWS_ON_PAGE_MAIN_TABLE) {
            newState.mainTable.checkedFieldsMainTable = action.payload;
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.CHANGE_INPUTS_DOCUMENT_RIGHT) {
            newState.rightTableSearchDoc.searchInputs = action.payload;
            newState.rightTableSearchDoc.isSearch = true;
        }

        else if(action.type === PACKAGES_TABLE_ACTIONS.ADD_ROW_TO_CHECKED_DOCUMENT_RIGHT) {
            newState.mainTable.checkedFields = action.payload;
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.ADD_ROW_TO_CHECKED_MAIN_TABLE) {
            newState.mainTable.checkedFieldsMainTable = action.payload;
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.DELETE_ROW_FROM_CHECKED_DOCUMENT_RIGHT) {
            newState.mainTable.checkedFields = action.payload;
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.DELETE_ROW_FROM_CHECKED_MAIN_TABLE) {
            newState.mainTable.checkedFieldsMainTable = action.payload;
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.SELECT_ACTIVE_ROW_DOCUMENT_RIGHT) {
            newState.mainTable.activeRow = action.payload;
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.SET_PAGINATION_ON_PAGE_SIZE_DOCUMENT_RIGHT) {
            newState.mainTable.paginationPageSize = action.payload;
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.GET_DOCS_DOCUMENT_RIGHT) {
            newState.treeForDocuments.docs = action.payload;
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.GET_GROUP_DOCUMENT_RIGHT) {
            newState.treeForDocuments.group = action.payload;
        }
        else if(action.type === PACKAGES_TABLE_ACTIONS.CREATE_DOCUMENT_RIGHT) {
            newState.treeForDocuments.render = action.payload;
            newState.treeForDocuments.isLoaded = true;
        }


        else if(action.type === PACKAGES_TREE_ACTIONS.SELECT_DOCUMENT_RIGHT) {
            newState.mainTable.activeDocument = action.payload.activeDocument;
            newState.mainTable.IsVisibleModal = action.payload.IsVisibleModal;
        }
    });
}

