import React from "react";
import {connect} from "react-redux";
import {
    treeSearch_packagesDocumentRight,
    treeSearchToggle_packagesDocumentRight
} from "../../../../store/packages/actionCreators/package_DocumentRightActionCreator";


class TreeSearchInPackagesRight extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // TreeSearch : "", // текст для поиска
            clearHover : false,
        };

       this.SearchSubmit = this.SearchSubmit.bind(this);
       this.inputOnChange = this.inputOnChange.bind(this);
       this.startSearch = this.startSearch.bind(this);
       this.clearInput = this.clearInput.bind(this);
    }

    SearchSubmit(event) {
        event.preventDefault();
        this.startSearch(this.props.value);
    }

    inputOnChange(event) {
        this.startSearch(event.target.value);
        this.props.changeValue(event.target.value)
    }

    startSearch(SearchText) {
        if(SearchText.length <= 1) {
            if(this.props.tree.isSearch) {
                this.props.treeSearchToggle_packagesDocumentRight({State : false});
            }
        }
        else {
            this.props.treeSearch_packagesDocumentRight({
                SearchText : SearchText,
                tree_render: this.props.tree.render,

                Next : {
                    action : () => {
                        if(!this.props.tree.isSearch) {
                            this.props.treeSearchToggle_packagesDocumentRight({State : true});
                        }
                    },
                    params : {}
                }
            });
        }
    }

    // сбрасываем поле ввода
    clearInput() {
        this.startSearch("");
        this.props.changeValue("")
    }

    render() {

        let searchIcon = "icon-Search ";
        if(this.state.clearHover) { // для отображения красного крестика
            searchIcon = "icon-Delete icon-color-red";
        }


        return(
           <div style={{position: "sticky", top: -2, backgroundColor: "#ffffff", zIndex: "1200", paddingBottom: 5}}>
                <div className="input-icon h-40px">
                    <form onSubmit={this.SearchSubmit}>
                       <input type="text"
                              className="form-control form-control-lg form-control-solid h-40px"
                              placeholder="Поиск"
                              value={this.props.value}
                              onChange={this.inputOnChange}/>
                    </form>
                   <span>
                        <span className="svg-icon svg-icon-lg" style={{cursor : "pointer"}}
                            onMouseEnter={()=>{this.setState({clearHover : true})}}
                            onMouseLeave={()=>{this.setState({clearHover : false})}}
                            onClick={this.clearInput}>
                            <i className={"svg-icon svg-icon-lg " + searchIcon}/>
                        </span>
                    </span>
                </div>
           </div>
        );
    }
}


const  mapStateToProps = state => {
    return {
        tree : state.packages.treeForDocuments,
    }
}

const  mapDispatchToProps = {
    treeSearch_packagesDocumentRight,
    treeSearchToggle_packagesDocumentRight
}


export default connect(mapStateToProps, mapDispatchToProps)(TreeSearchInPackagesRight);
