import React from "react";
import {connect} from "react-redux";
import {createUniqueIdString} from "../../tools/CreateUniqueId";
import TreeDocsDocument from "./TreeDocsDocument";
import {
    toggleFoldersForTree,
    toggleStructuresForTree
} from "../../store/documents/actionCreators/document_TreeActionCreator";
import {SettingsValueEnum} from "../../tools/StaticTypes";
import {store} from "../../index";

class TreeDocsFolder extends React.Component {
    constructor(props) {
        super(props);

        if(this.props.tree_isSearch) {
            this.state = {
                IsOpen: this.props.data.info.searchType === "folderOpen",  // открыта ли папка при поиске
            }
        }
        else {
            let props = this.props.prevContext
                ? this.props.prevContext
                : this.props;
            this.state = {
                IsOpen: props.activeNode && this.props.data.treeId === props.activeNode.parent,  // открыта ли папка
            }
            this.ref = React.createRef();
        }


        this.switchFolderState = this.switchFolderState.bind(this);
    }

    // открытие или закрытие папки
    switchFolderState(ev, item) {
        let props = this.props.prevContext
            ? this.props.prevContext
            : this.props;
        // this.setState({
        //     IsOpen : !this.state.IsOpen,
        // });

        // изменено на редакс для управления состоянием через кнопки
        if(item.type === "FOLDER"){
            props.toggleFoldersForTree({IdFolder: item.info.Id});
        }else if(item.type === "STRUCTURE"){
            props.toggleStructuresForTree({IdFolder: item.info.Id});

        }
    }
    componentDidMount() {
        let props = this.props.prevContext
            ? this.props.prevContext
            : this.props;
        //console.log("this.props.data?.treeId",this.props.data?.treeId)
        //console.log("this.props.activeNode?.treeId",this.props.activeNode)
            // if (this.props.data?.treeId === this.props.activeNode?.treeId) {
            //     console.log("test")
            //     this.setState({
            //         IsOpen: true,
            //     });
            // }
            if (props.activeNode && props.activeNode.parent === this.props.data.treeId) {
                this.setState({
                    IsOpen: true,
                });
            }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // let props = this.props.prevContext
        //     ? this.props.prevContext
        //     : this.props;

        const openedFolders = store.getState().document.tree.openedFolders;

        //TODO SS изменено, чтобы работало с многоуровневой вложенностью

        // if(prevProps.openedFolders !== openedFolders
            // && openedFolders.length === 1
        // ) {
            const conditionForFocus = openedFolders.at(-1) === this.props.data.info.Id
                // && !props.activeNode
            if(conditionForFocus) {
                this.ref?.current.focus();
            }
        // }
    }

    render() {
        let props = this.props.prevContext
            ? this.props.prevContext
            : this.props;

        let data = this.props.data; // значение текущего узла
        let depth = this.props.depth; // текущая глубина
        const globalSettings = props.globalSettings;

        // icon-Menu_pack - закрытая обычная папка
        // icon-Open_folder - открытая обычная папка
        // icon-Open_folder_metka - открытая папка с точкой
        // icon-Pack_metka - закрытая папка с точкой
        // icon-Open_folder_close - открытая папка с замком
        // icon-Open_folder_bookmark -открытая папка с закладкой
        // icon-Pack_bookmark - закрытая папка с закладкой
        // icon-Pack_close - закрытая папка с замком

        if(data.children.length > 0) { // не рисовать пустые папки


            let folderIcon = "";
            let folderColor = "";
            let arrowColor = "icon-color-gray";
            let textColor = "";
            let arrowDirection = "";

            let isOpen = (data.type ==="FOLDER") ? props.openedFolders.includes(data.info.Id) : false;
            let isOpenStructures = (data.type ==="STRUCTURE") ? props.openedStructures.includes(data.info.Id) : false;
            if(isOpen || isOpenStructures) { // папка открыта

                arrowDirection = "arrow-bottom"; // стрелка вниз

                // если выбран документ внутри папки
                if(props.activeNode && props.activeNode.parent === this.props.data.treeId) {
                   if(data.info.Type === 1) { // обычная папка
                       folderIcon = "icon-Open_folder"; // какая иконка папки
                       folderColor = "icon-color-primary-light"; // какой цвет у папки
                       textColor = "text-color-primary-light"; // какой цвет текста
                   } else if(data.info.Type === 2) { // зеленая папка
                       folderIcon = "icon-Open_folder_metka";  // какая иконка папки
                       folderColor = "icon-color-secondary-active"; // какой цвет у папки
                       textColor = "text-color-secondary-active"; // какой цвет текста
                   }
                   else if(data.info.Type === 3) { // красная папка
                       folderIcon = "icon-Open_folder_metka";  // какая иконка папки
                       folderColor = "icon-color-danger"; // какой цвет у папки
                       textColor = "text-color-danger"; // какой цвет текста
                   }
                } else {   // если НЕ выбран документ внутри папки
                    if(data.info.Type === 1) { // обычная папка
                        folderIcon = "icon-Open_folder"; // какая иконка папки
                        folderColor = "icon-color-gray"; // какой цвет у папки
            //            textColor = "text-color-gray"; // какой цвет текста
                    } else if(data.info.Type === 2) { // зеленая папка
                        folderIcon = "icon-Open_folder_metka";  // какая иконка папки
                        folderColor = "icon-color-secondary"; // какой цвет у папки
                        textColor = "text-color-secondary"; // какой цвет текста
                    }
                    else if(data.info.Type === 3) { // красная папка
                        folderIcon = "icon-Open_folder_metka";  // какая иконка папки
                        folderColor = "icon-color-red"; // какой цвет у папки
                        textColor = "text-color-red"; // какой цвет текста
                    }
                }
            } else  { // папка закрыта

                arrowDirection = "arrow-right"; // стрелка вправо

                // если выбран документ внутри папки
                if(props.activeNode && props.activeNode.parent === this.props.data.treeId) {
                    if (data.info.Type === 1) { // обычная папка
                        folderIcon = "icon-Menu_pack"; // какая иконка папки
                        folderColor = "icon-color-primary"; // какой цвет у папки
                        textColor = "text-color-primary"; // какой цвет текста
                    } else if (data.info.Type === 2) { // зеленая папка
                        folderIcon = "icon-Pack_metka"; // какая иконка папки
                        folderColor = "icon-color-secondary-active"; // какой цвет у папки
                        textColor = "text-color-secondary-active";
                    } else if (data.info.Type === 3) { // красная папка
                        folderIcon = "icon-Pack_metka"; // какая иконка папки
                        folderColor = "icon-color-danger"; // какой цвет у папки
                        textColor = "text-color-danger"; // какой цвет текста
                    }
                } else { // если НЕ выбран документ внутри папки
                    if(data.info.Type === 1) { // обычная папка
                        folderIcon = "icon-Menu_pack"; // какая иконка папки
                        folderColor = "icon-color-gray"; // какой цвет у папки
              //          textColor = "text-color-gray"; // какой цвет текста
                    } else if(data.info.Type === 2) { // зеленая папка
                        folderIcon = "icon-Pack_metka"; // какая иконка папки
                        folderColor = "icon-color-secondary"; // какой цвет у папки
                        textColor = "text-color-secondary"; // какой цвет текста
                    }
                    else if(data.info.Type === 3) { // красная папка
                        folderIcon = "icon-Pack_metka"; // какая иконка папки
                        folderColor = "icon-color-red"; // какой цвет у папки
                        textColor = "text-color-red"; // какой цвет текста
                    }
                }
            }

            if(data.info?.UiGroup) {
                folderIcon = "icon-Sub";
            }

            // собираем стили
            let iconStyle = `${folderIcon} ${folderColor}`;
            let folderStyle = `menu-link menu-item ${folderColor}`;
            let arrowStyle = `arrow_m ${arrowDirection} ${arrowColor} icon-Arrows_1`;
            let textStyle = `menu-text ${textColor}`;

            let folderIsEmpty = false;
            for (let i = 0; i < data.children.length; i++) {
                if(data.children[i].info.ContainsDocs) {
                    folderIsEmpty =  true;
                }
            }

            let folderActiveStyle = {};
            let lFlagHighlight = true;

            if (globalSettings.NotHighlightContainsDocs)
                if (globalSettings.NotHighlightContainsDocs === SettingsValueEnum.True)
                    lFlagHighlight = false;

            //TODO подсветка типов без документов
            if (lFlagHighlight)
                if(!folderIsEmpty && !data.info.UiGroup) {
                    folderActiveStyle = {
                        color: "rgba(63, 66, 84, 0.42)"
                    };
                }

            let subItemStyle = data.type === "FOLDER" ?
                                                props.openedFolders.includes(data.info.Id)
                                                    ? {}
                                                    : {display: "none"}
                                                : (props.openedStructures.includes(data.info.Id) ? {} : {display: "none"})
            return(
                <li className={folderStyle}>
                    <span className="menu-link menu-toggle" onClick={(ev) => this.switchFolderState(ev, data)}>
                        <span className="svg-icon menu-icon">
                            <i className={iconStyle} />
                        </span>
                        <span ref={this.ref} tabIndex={data.info.Id} className={`${textStyle} noneFocus`} style={folderActiveStyle}>{data.info.Name}</span>
                        <i className={arrowStyle}/>
                    </span>
                    <div className="menu-submenu">
                        <ul className="menu-subnav" style={subItemStyle}>
                            {
                                data.children.map((item, index) => {
                                     //console.log('item.info.UiGroup', data,  item)
                                     // console.log('item.info.UiGroup', data.info.UiGroup)
                                    if(data.info.UiGroup) {
                                        // console.log('item.info.UiGroup', data,  item)
                                        return <TreeDocsFolder
                                            key={`${item.info.Name} | ${createUniqueIdString(3)}`}
                                            data={item}
                                            depth={depth+1}
                                            prevContext={this.props.prevContext ? this.props.prevContext : this.props}
                                        />;
                                    } else {
                                        return <TreeDocsDocument
                                            key={`${item.info.Name} ${createUniqueIdString(3)}`}
                                            data={item}
                                            groupId={data.info.Id}
                                            treeId={data.treeId}
                                            depth={depth + 1}/>
                                    }
                                })
                            }
                        </ul>
                    </div>
                </li>
            );
        }
        else return null;
    }

}


const  mapStateToProps = state => {
    return {
        activeNode : state.document.tree.activeNode, // активный элемент дерева
        tree_isSearch : state.document.tree.isSearch, // обычный режим или режим поиска
        openedFolders: state.document.tree.openedFolders,
        globalSettings: state.globalState.settings.Content,
        openedStructures: state.document.tree.openedStructures
    }
}

const  mapDispatchToProps = {
    toggleFoldersForTree,
    toggleStructuresForTree
}

export default connect(mapStateToProps, mapDispatchToProps)(TreeDocsFolder);
