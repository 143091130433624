import React from 'react';
import "../../media/styles/messenger.css";
import {
    deleteMsgUserSettings,
    updateUserSettings
} from "../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import {connect} from "react-redux";
import {makeReceiverActive} from "../../store/messenger/actionCreators/messenger_ActionCreator";
import {setReadMsgs} from "../../store/messenger/actionCreators/messenger_ActionCreator"
import {HelpFunctions} from "../../tools/HelpFunctions";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import Avatar from "react-avatar";

class MessageCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showContextMenu: false,
            // difference:0,
        }
        this.toggleActiveReceiver = this.toggleActiveReceiver.bind(this)
        this.rightClick = this.rightClick.bind(this)
        this.deleteChat = this.deleteChat.bind(this)
        this.getFormattedTime = this.getFormattedTime.bind(this)
    }


    toggleActiveReceiver() {
        this.props.makeReceiverActive({
            User: {
                Id: this.props.messengerItem.Colleague.Id,
                Name: this.props.messengerItem.Colleague.Name
            },
            Next: {
                action: () => {
                    this.props.setReadMsgs({
                        User: this.props.messengerItem.Colleague,
                        MsgAmount: this.props.messengerItem.MsgAmount,
                    })
                }
            }
        })
    }

    rightClick() {
        this.setState({showContextMenu: !this.state.showContextMenu})
    }

    deleteChat() {
        let nameArr = this.props.messengerItem.Colleague.Name.toUpperCase().split(' ')
        let name = 'messenger' + nameArr.join('_')

        this.props.deleteMsgUserSettings({
            Data: {
                SettingName: name,
            },
        })

    }

    getFormattedTime(item) {
        const formatItem = (item) => {
            return item < 10 ? "0" + item : item
        }

        let formattedDate = "";
        let itemDate = new Date(item.Date)

        let hours = itemDate.getHours()
        hours = formatItem(hours)
        let minutes = itemDate.getMinutes()
        minutes = formatItem(minutes)

        formattedDate += `${hours}:${minutes}`
        return formattedDate
    }

    render() {
        // let messengers = [];
        //
        // for (const setting of this.props.userSettings) {
        //     if (setting.Name.includes('messenger'))
        //         messengers.push(setting)
        // }

        return (
            <div style={{display: "flex", flexDirection: 'row'}}
                 onClick={this.toggleActiveReceiver}
            >
                <div className={`d-flex msgCard align-items-center`}>
                    <div className="d-flex position-relative mr-6">
                        <span className="symbol-label font-size-h5 font-weight-bold">
                            <Avatar name={this.props.messengerItem?.Colleague.Name} size="40" color={"var(--originaltwo)"} fgColor={"var(--text-welcome)"} className="rounded"/>
                        </span>
                        <div className={`newMsgNumber ${this.props.difference > 0 ? '' : 'hide'} rounded`}>
                            {this.props.difference > 99 ? '99+' : this.props.difference}
                        </div>
                    </div>
                    <div className="d-flex flex-column msgCard__content mr-6">
                        <div className="pt-1">
                            <p className="fw-bold mb-0 text-nowrap overflow-hidden text-overflow-ellipsis">
                                {HelpFunctions.cutLongString(this.props.messengerItem.Colleague.Name, 23, true)}
                            </p>
                        </div>
                        <div className="pt-1">
                            <p className="mb-0 text-nowrap overflow-hidden text-overflow-ellipsis">
                                {HelpFunctions.cutLongString(sanitizeHtml(this.props.messengerItem.Message.at(-1).Text, {allowedTags: []}), 25, true)}
                            </p>
                        </div>
                    </div>
                    <div className="msgCard__newMsg">
                        <p className={`small mb-1 ${this.props.difference > 0 ? 'newMsg' : ''} pt-1`}>
                            {this.getFormattedTime(this.props.messengerItem.Message.at(-1))}
                        </p>
                    </div>
                    <div className="contextMenu-msgCard cursor-pointer pt-1" onClick={this.rightClick}>
                        <i className="bi bi-three-dots-vertical" />
                    </div>
                </div>
                <div className='deleteChat'
                     style={{display: this.state.showContextMenu ? 'flex' : 'none'}}
                     onClick={this.deleteChat}
                >
                    Удалить
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        activeReceiver: state.messenger.message.activeReceiver,
        userSettings: state.globalState.userSettings,
        msgAmount: state.messenger.message.readMsgs,
    }
}

const mapDispatchToProps = {
    updateUserSettings,
    makeReceiverActive,
    setReadMsgs,
    deleteMsgUserSettings,
}
export default connect(mapStateToProps, mapDispatchToProps)(MessageCard);
