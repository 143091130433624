import React from 'react';
import "../../media/styles/messenger.css";
import {updateUserSettings} from "../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import {connect} from "react-redux";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import {makeReceiverActive} from "../../store/messenger/actionCreators/messenger_ActionCreator";


const sbSelect = {
    menuList: (base) => ({
        ...base,

        "::-webkit-scrollbar": {
            width: "5px",
            height: "5px",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
        },

        "::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            backgroundColor: "var(--background-colorBS)",
        },

        "::-webkit-scrollbar-track": {
            borderRadius: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.35)",
        }
    })
}

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            clearHover: false,
            receivers: this.props.users,
            activeReceiver: null,
        }

        this.inputOnChange = this.inputOnChange.bind(this);
        this.searchUsers = this.searchUsers.bind(this);
        this.chooseReceiver = this.chooseReceiver.bind(this);
    }

    async searchUsers(value) {
        let users = this.state.receivers;
        let arr = users.filter(user => user.Name.includes(value))
        return arr.map(item => ({label: item.Name, value: item.Id}))
    }

    async chooseReceiver(user) {
        this.props.makeReceiverActive({
            User: {
                Id: user.value,
                Name: user.label
            },
        })
        this.setState({activeReceiver: user})
    }


    inputOnChange(event) {
        this.setState({searchText: event.target.value})
        this.state.receivers
            .filter(user => user.Name.includes(this.state.searchText))
            .map((item, index) =>
                ({
                    label: item,
                    value: index
                })
            )
    }

    render() {
        return (
            <div className={"my-4"}>
                <AsyncSelect
                    styles={sbSelect}
                    title="Выберите получателя"
                    loadOptions={this.searchUsers}
                    onChange={(item) => {
                        this.chooseReceiver(item)
                    }}
                    menuPosition="fixed"
                    cacheOptions
                    defaultOptions={true}
                    placeholder="Введите имя получателя"
                    noOptionsMessage={() => {
                        return "Не найдено получателя для выбора"
                    }}
                    loadingMessage={() => {
                        return "Загрузка..."
                    }}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            text: 'var(--hover-primary)',
                            primary25: 'var(--light-primary)',
                            primary50: 'var(--hover-primary)',
                            primary: 'var(--originaltwo)',
                        },
                    })}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.globalState.AllUsers.Records,
    }
}

const mapDispatchToProps = {
    updateUserSettings,
    makeReceiverActive,
}
export default connect(mapStateToProps, mapDispatchToProps)(Search);
