import React from "react";
import {
    addModalData,
    deleteModalData, setLoaderModalData,
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {
    combineMainTableAttributes,
    mainTableDownloadDocCollection
} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {connect} from "react-redux";
import {
    deleteSectionsForTree,
    getSectionsForTree, saveSectionsForTree
} from "../../../store/documents/actionCreators/document_TreeActionCreator";
import {__} from "../../../tools/HelpFunctions";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {ModalTypes, Operators} from "../../../tools/StaticTypes";
import {apiRequest} from "../../../tools/API/apiRequest";
import {apiUrl} from "../../../tools/API/apiUrl";
import {store} from "../../../index";
import {EntityFilters, Filter, FiltersType} from "../../../tools/Filters";
import {MySelectNew} from "../../tools/MySelect/MySelectNew";
import {API} from "../../../tools/API_NEW/API";
import {DataTypesEnum} from "../../../tools/API_NEW/ServerTypes";
import DocRef from "../../additionalDataSection/docRefsComponents/DocRef";
import MultipleDocRef from "../../additionalDataSection/docRefsComponents/MultipleDocRef";
import MultipleDocVersionRef from "../../additionalDataSection/docRefsComponents/MultipleDocVersionRef";
import DocVersionRef from "../../additionalDataSection/docRefsComponents/DocVersionRef";
import AttributeWithDictionaries from "../../additionalDataSection/docRefsComponents/AttributeWithDictionaries";
import MultipleAttributeTable from "../../tools/MultipleAttributeTable/MultipleAttributeTable";
import JsonObjectFragment from "../../root/JsonObjectFragment";


const animatedComponents = makeAnimated();

const createSelectStyles = {
    input: (css, state) => ({
        ...css,
        width: "100%",
        "> div": {
            width: "100%"
        },
        input: {
            width: `100% !important`,
            textAlign: "left"
        }
    })
}

const sbSelect = {
    menuList: (base) => ({
        ...base,

        "::-webkit-scrollbar": {
            width: "5px",
            height: "5px",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
        },

        "::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            /*background-color: rgb(239 137 137 / 20%);*/
            backgroundColor: "var(--background-colorBS)",
        },

        "::-webkit-scrollbar-track": {
            borderRadius: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.35)",
        }
    })
}

class NewDocumentByVersion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            LoadGroups: store.getState().document.tree.group,
            SelectedGroups: null,
            selectedNewDocTypeOption: null,
            sortingFoldersName: this.props.allFolders
                .filter(group => group.TypeAttributes.length > 0)
                .map(group => ({
                    Attributes: group.TypeAttributes,
                    label: group.Name,
                    value: group.Id,
                })),
            newDocument: {
                unifiedDocAttributes: [],
                docHeader: {
                    Id: this.props.activeDocument.Info.Id,
                    IdFilial: this.props.activeDocument.Info.IdFilial,
                    Name: this.props.activeDocument.Info.Name,
                    RegDate: this.props.activeDocument.Info.RegDate,
                    RegNumber: this.props.activeDocument.Info.RegNumber,
                    Type: {
                        Id: this.props.activeDocument.Info.Type.Id,
                        Name: this.props.activeDocument.Info.Type.Name
                    }
                },
                idSourceVersion: {
                    label: `${this.props.versionList[0].Version} от ${__.dateToRussia(this.props.versionList[0].VersionTime)}`,
                    value: {idVersion: this.props.versionList[0].Id, idDoc: this.props.versionList[0].IdDoc}
                },
                comment: '',
            },
            oldDocument: {
                Info: this.props.activeDocument.Info,
                unifiedDocAttributes: __.convertAttributesArrayToUnique(
                    __.transformAttributesData({
                        attributesData: this.props.activeDocument.Info.Attributes,
                        docTypeId: this.props.activeDocument.Type.Id,
                        newValueFieldName: 'AttributeValue',
                        needAllAttributesOnType: true
                    }),
                    'AttributeValue'
                )
            },
            targetDataType: {
                TypeAttributes: []
            },
            countEmptyRequiredFields: 0,
            externalDictionariesValues: null,
        };

        this.hide = this.hide.bind(this);
        this.changeName = this.changeName.bind(this);
        this.changeRegNumber = this.changeRegNumber.bind(this);
        this.changeRegDate = this.changeRegDate.bind(this);
        this.changeTargetDataType = this.changeTargetDataType.bind(this);
        this.changeComment = this.changeComment.bind(this);
        this.setTargetDataType = this.setTargetDataType.bind(this);
        this.checkRequired = this.checkRequired.bind(this);
        this.changeAttributeValue = this.changeAttributeValue.bind(this);
        this.postNewDocumentByVersion = this.postNewDocumentByVersion.bind(this);
        this.changeVersion = this.changeVersion.bind(this);

        this.renderJsonObjectFragment = this.renderJsonObjectFragment.bind(this);
        this.deleteMultiplyRowHandler = this.deleteMultiplyRowHandler.bind(this);
        this.addMultiplyRowHandler = this.addMultiplyRowHandler.bind(this);
    }

    async componentDidMount() {
        await this.setTargetDataType(this.props.activeDocument.Info.Type)
    }

    async setTargetDataType(folder) {
        const folderId = folder?.value ?? folder.Id
        let findFolder = this.props.allFolders.find(item => item.Id === folderId)
        const attributesWithExternalDictionaries = findFolder.TypeAttributes.filter(item => item.AttributeName.IdDictionaries).map(item => item.AttributeName.IdDictionaries)
        if (attributesWithExternalDictionaries.length > 0) {
            let externalDictionariesValues
            store.dispatch(setLoaderModalData({
                data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents", key : "NewDocumentByVersion154" }
            }));
            externalDictionariesValues = await API.dictionary().getDictionaryValues({
                filter: new Filter(FiltersType.ENTITY).add(
                    new EntityFilters().setEntityName("DictionaryValue").add({
                        Name: "IdDictionaries",
                        Value: attributesWithExternalDictionaries,
                        Operator: Operators.In
                    }).get()
                ),
                sorting: null,
            });
            store.dispatch(setLoaderModalData({keyDeleted: "NewDocumentByVersion166"}))
            if (externalDictionariesValues.errorCode) {
                this.props.addModalData({
                    name: ModalTypes.app.info,
                    data: {
                        content: externalDictionariesValues.message.Message,
                        disableButton: false,
                        type: "fail"
                    }
                })
                return;
            }
            this.setState({
                externalDictionariesValues,
            })
        }
        const adjustedOldAttributes = __.excludeAttributesDifferInMultiplyAllowed({
            attributes: this.state.oldDocument.Info.Attributes,
            firstTypeId: this.state.oldDocument.Info.Type.Id,
            secondTypeId: findFolder.Id,
        })
        let unifiedDocAttributes = __.convertAttributesArrayToUnique(
            __.transformAttributesData({
                attributesData: adjustedOldAttributes,
                docTypeId: findFolder.Id,
                newValueFieldName: 'AttributeValue',
                needAllAttributesOnType: true
            }),
            'AttributeValue'
        )
        const newValueOfNewDocument = {
            ...this.state.newDocument,
            unifiedDocAttributes: unifiedDocAttributes,
            docHeader: {
                ...this.state.newDocument.docHeader,
                Type: {
                    Id: findFolder.Id,
                    Name: findFolder.Name
                },
            }
        }
        await this.setState({newDocument: newValueOfNewDocument})
        await this.setState({targetDataType: findFolder})
        await this.checkRequired()
    }

    checkRequired() {
        const emptyRequiredFields = this.state.newDocument.unifiedDocAttributes.filter(el=> el.IsRequired && !el.AttributeValue)
        this.setState({countEmptyRequiredFields: emptyRequiredFields.length})
    }

    async changeVersion(value) {
        const {idDoc, idVersion} = value.value;
        let request_headersByVersion = new apiRequest(apiUrl.DOCUMENTS.GET_HEADERS_FOR_CURRENT_DOC, {
            filter : new Filter(FiltersType.ENTITY)
                .add(
                    new EntityFilters().setEntityName("DocHeader")
                        .add({Name: "IdDoc", Value: idDoc})
                        .add({Name: "Version.Id", Value: idVersion})
                        .get()
                ).get(),
            sorting : null
        });
        const IsOldServer = store.getState().globalState.settings.Content?.IsOldServer === "1";
        let request_attributes;
        if(IsOldServer){
             request_attributes = new apiRequest(apiUrl.DOCUMENTS.GET_ATTRIBUTES_FOR_VIEW_FOR_CURRENT_DOC_OLD, {
                filter: new Filter(FiltersType.ENTITY)
                    .add(
                        new EntityFilters().setEntityName("DocAttribute")
                            .add({Name: "IdDocVersion", Value: idVersion})
                            .get()
                    ).get(),
                sorting: null
            });
        }else{
            request_attributes = new apiRequest(apiUrl.DOCUMENTS.GET_ATTRIBUTES_FOR_VIEW_FOR_CURRENT_DOC, {
                filter: new Filter(FiltersType.ENTITY)
                    .add(
                        new EntityFilters().setEntityName("DocAttributeForView")
                            .add({Name: "IdDocVersion", Value: idVersion})
                            .get()
                    ).get(),
                sorting: null
            });
        }

        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents", key : "NewDocumentByVersion236" }
        }));
        const closeLoader = () => store.dispatch(setLoaderModalData({keyDeleted: "NewDocumentByVersion240"}));
        try {
            await request_headersByVersion.execute(async (data_headersByVersion) => {
                await request_attributes.execute((data) => {
                    const newAttributes = data.Records
                    const isLastVersion = data_headersByVersion.Records[0].IsCurrent
                    const unifiedOldAttributes = __.convertAttributesArrayToUnique(__.transformAttributesData({
                        attributesData: newAttributes,
                        docTypeId: this.state.oldDocument.Info.Type.Id,
                        newValueFieldName: 'AttributeValue',
                        needAllAttributesOnType: isLastVersion,
                    }), 'AttributeValue');

                    const adjustedNewAttributes = __.excludeAttributesDifferInMultiplyAllowed({
                        attributes: newAttributes,
                        firstTypeId: this.state.oldDocument.Info.Type.Id,
                        secondTypeId: this.state.newDocument.docHeader.Type.Id,
                    })
                    const unifiedNewAttributes = __.convertAttributesArrayToUnique(__.transformAttributesData({
                        attributesData: adjustedNewAttributes,
                        docTypeId: this.state.newDocument.docHeader.Type.Id,
                        newValueFieldName: 'AttributeValue',
                        needAllAttributesOnType: true,
                    }), 'AttributeValue')
                    this.setState({
                        oldDocument: {
                            Info: {
                                ...data_headersByVersion.Records[0],
                                Attributes: newAttributes,
                            },
                            unifiedDocAttributes: unifiedOldAttributes
                        },
                        newDocument: {
                            ...this.state.newDocument,
                            unifiedDocAttributes: unifiedNewAttributes,
                            idSourceVersion: value
                        }
                    }, () => {
                        this.setTargetDataType(data_headersByVersion.Records[0].Type);
                        this.setState({selectedNewDocTypeOption: null});
                        closeLoader()
                    })
                })
            })
        } catch (error) {
            closeLoader()
            this.props.addModalData({
                name: ModalTypes.app.info,
                data: {
                    content: error.responseJSON.Message,
                    disableButton: false,
                    type: "fail"
                }
            })
        }
    }

    changeName(value) {
        const newValueOfNewDocument = {
            ...this.state.newDocument,
            docHeader: {
                ...this.state.newDocument.docHeader,
                Name: value,
            }
        }
        this.setState({newDocument: newValueOfNewDocument})
    }

    changeRegNumber(value) {
        const newValueOfNewDocument = {
            ...this.state.newDocument,
            docHeader: {
                ...this.state.newDocument.docHeader,
                RegNumber: value,
            }
        }
        this.setState({newDocument: newValueOfNewDocument})
    }

    changeRegDate(value) {
        const newValueOfNewDocument = {
            ...this.state.newDocument,
            docHeader: {
                ...this.state.newDocument.docHeader,
                RegDate: value,
            }
        }
        this.setState({newDocument: newValueOfNewDocument})
    }

    async changeTargetDataType(value) {
        await this.setTargetDataType(value)
    }

    changeComment(value) {
        const newValueOfNewDocument = {
            ...this.state.newDocument,
            comment: value
        }
        this.setState({newDocument: newValueOfNewDocument})
    }

    changeAttributeValue({id, value, isRequired, isDictionary}) {
        this.setState({
            newDocument: {
                ...this.state.newDocument,
                unifiedDocAttributes: this.state.newDocument.unifiedDocAttributes.map((item) => {
                    if (item.AttributeName.Id === id) {
                        let newAttributeValue, newIdDictionaryValue, newIdValue
                        if (isDictionary) {
                            newAttributeValue = value.value
                            newIdDictionaryValue = value.idDictionaryValue
                        } else {
                            newAttributeValue = value
                            newIdDictionaryValue = undefined
                        }
                        if (Array.isArray(newAttributeValue)) {
                            const oldIdAsArray = Array.isArray(item.Id) ? item.Id : [item.Id]
                            newIdValue = newAttributeValue.map((v, index) => oldIdAsArray[index])
                        } else {
                            newIdValue = Array.isArray(item.Id) ? item.Id[0] : item.Id // TODO КН на всякий сделал обработчик isArray(item.Id), но по факту не должен он быть массивом когда пришел value не array
                        }
                        /* TODO КН 12.04.24 - Вообще по хорошему еще ShortDescription который в item лежит сетать,
                            но впринципе он нигде в этой компонете пока не используется, поэтому пока можно не делать
                         */
                        return {
                            ...item,
                            Id: newIdValue,
                            AttributeValue: newAttributeValue,
                            IdDictionaryValue: newIdDictionaryValue,
                        }
                    } else return item;
                })
            }
        }, ()=> {
            if (isRequired) {
                this.checkRequired()
            }
        });
    }

    async postNewDocumentByVersion() {
        const emptyMultiAttr = this.state.newDocument.unifiedDocAttributes.find(attrData=> Array.isArray(attrData.AttributeValue) && attrData.AttributeValue.some(el => !el || el === ''))
        if (emptyMultiAttr) {
            this.props.addModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content: `Не заполнено поле множественного атрибута "${emptyMultiAttr.AttributeName.Name}"` }
            })
        } else {
            const docAttributes = [];
            this.state.newDocument.unifiedDocAttributes.forEach(attr => {
                if (Array.isArray(attr.AttributeValue)) {
                    if (attr.AttributeValue.length) {
                        attr.AttributeValue.forEach((value, index) => {
                            let idDictionaryValueForAttr
                            if (attr.AttributeName.IdDictionaries) {
                                const idDictionaryValueAsArray = Array.isArray(attr?.IdDictionaryValue) ? attr?.IdDictionaryValue : [attr?.IdDictionaryValue]
                                idDictionaryValueForAttr = idDictionaryValueAsArray[index];
                            } else {
                                idDictionaryValueForAttr = null
                            }
                            docAttributes.push({
                                Id: attr.Id[index],
                                IdDictionaryValue: idDictionaryValueForAttr ?? null,
                                AttributeName: attr.AttributeName,
                                AttributeValue: value,
                            })
                        })
                    } else {
                        docAttributes.push({
                            Id: undefined,
                            IdDictionaryValue: null,
                            AttributeName: attr.AttributeName,
                            AttributeValue: null,
                        })
                    }
                } else {
                    const isEmptyString = attr.AttributeValue === ''
                    docAttributes.push({
                        Id: attr.Id,
                        IdDictionaryValue: isEmptyString ? null : attr?.IdDictionaryValue,
                        AttributeName: attr.AttributeName,
                        AttributeValue: isEmptyString ? null : attr.AttributeValue,
                    });
                }
            })

            let request = new apiRequest(apiUrl.DOCUMENTS.DOCS_NEW_BY_VER, {
                docAttributes: docAttributes,
                docHeader: {
                    IdFilial: this.state.newDocument.docHeader.IdFilial,
                    RegNumber: this.state.newDocument.docHeader.RegNumber,
                    Name: this.state.newDocument.docHeader.Name,
                    Type: this.state.newDocument.docHeader.Type,
                    RegDate: this.state.newDocument.docHeader.RegDate
                },
                idSourceVersion: this.state.newDocument.idSourceVersion.value.idVersion,
                comment: this.state.newDocument.comment,
            })
            store.dispatch(setLoaderModalData({
                data: {content: "Загрузка...", disableButton: true, fullBackground: false, gif: "documents", key: "NewDocumentByVersion440"}
            }));
            const closeLoader = () => store.dispatch(setLoaderModalData({keyDeleted: "NewDocumentByVersion442"}));
            try {
                await request.execute(async (res) => {
                    if (this.state.SelectedGroups) {
                        let requestSaveCollection = new apiRequest(apiUrl.DOCUMENTS.SAVE_DOC_TREE_SECTION, {
                            collection: [
                                {
                                    Id: 0,
                                    IdDoc: res.IdDoc,
                                    IdDocGroup: this.state.SelectedGroups.value
                                }
                            ]
                        })
                        await requestSaveCollection.execute(() => {})
                    }
                    this.hide()
                    closeLoader()
                    await this.props.addModalData({
                        name: ModalTypes.app.info,
                        data: {type: "success", content: `Документ из версии успешно создан!`}
                    });
                })
            } catch (e) {
                closeLoader()
                this.props.addModalData({
                    name: ModalTypes.app.info,
                    data: {type: "fail", content: e.responseJSON.Message}
                });
            }
        }
    }

    hide() {
        this.props.deleteModalData({
            modalId: this.props.modal.id
        });
    }

    deleteMultiplyRowHandler(lIndex, item) {
        const value = Array.isArray(item.AttributeValue) ? item.AttributeValue : [item.AttributeValue]
        const newValue = [...value.slice(0, lIndex), ...value.slice(lIndex + 1, value.length)];
        this.changeAttributeValue({
            id: item.AttributeName.Id,
            value: newValue,
            isRequired: item.IsRequired
        })
    }

    addMultiplyRowHandler(lIndex, item) {
        const currentValue =  Array.isArray(item.AttributeValue) ? item.AttributeValue : [item.AttributeValue]
        let newValue;
        if (currentValue && currentValue.length > 0) {
            if (!((lIndex - 1 >= 0) && (lIndex - 1 < currentValue.length))) {
                const lExtensibleInputValue = __.deepCopy(currentValue);
                lExtensibleInputValue.splice(lIndex - 1, 0, null);
                newValue = lExtensibleInputValue
            }
        } else {
            let updatedData = [];
            updatedData[lIndex - 1] = null;
            newValue=updatedData
        }
        newValue && this.changeAttributeValue({
            id: item.AttributeName.Id,
            value: newValue,
            isRequired: item.IsRequired
        })
    }
    renderJsonObjectFragment = (item, rowId, IsMultipleAllowed, redactorMode, hardDisabledEditing) => {
        let lInputValue = item?.AttributeValue
        if (rowId) {
            if (item?.AttributeValue && item.AttributeValue.length > 0 && (rowId - 1) >= 0 && (rowId - 1) < item.AttributeValue.length) {
                lInputValue = item.AttributeValue[rowId - 1];
            } else {
                lInputValue = null;
            }
        }
        return <JsonObjectFragment lInputValue={lInputValue}
                                   lAllInputValue={item?.AttributeValue}
                                   isRedactorMode={redactorMode}
                                   rowId={rowId}
                                   item={item}
                                   IsMultipleAllowed
                                   hardDisabledEditing={hardDisabledEditing}
                                   inputHandler={(value, item) => {
                                       this.changeAttributeValue({
                                           id: item.AttributeName.Id,
                                           value: IsMultipleAllowed ? value : value.value,
                                           isRequired: item.IsRequired
                                       })
                                   }}
        />
    }


    render() {
        if (!this.state.oldDocument) return null;
        const sbStyle = {
            menuList: (base) => ({
                ...base,

                "::-webkit-scrollbar": {
                    width: "5px",
                    height: "5px",
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                },

                "::-webkit-scrollbar-thumb": {
                    borderRadius: "10px",
                    /*background-color: rgb(239 137 137 / 20%);*/
                    backgroundColor: "var(--background-colorBS)",
                },

                "::-webkit-scrollbar-track": {
                    borderRadius: "10px",
                    backgroundColor: "rgba(255, 255, 255, 0.35)",
                }
            })
        }

        let modalStyle = {
            display: "block",
            backgroundColor: "#061c3e66",
        };

        const defaultType = {
            label: this.state.oldDocument.Info.Type.Name,
            value: this.state.oldDocument.Info.Type.Id
        }

        let mismatchAttributes = []

        if (this.state.targetDataType) {
            mismatchAttributes = this.state?.targetDataType?.TypeAttributes.map(item => item.AttributeName.Name)
        }

        const isDisabled = this.state.countEmptyRequiredFields || !this.state.newDocument.comment.length

        const versions = this.props.versionList.map((item) => {
            return {
                label: `${item.Version} от ${__.dateToRussia(item.VersionTime)}`,
                value: {idVersion: item.Id, idDoc: item.IdDoc}
            }
        })

        let groupsOptions = [];
        groupsOptions.push({
            value: null,
            label: "Не выбрано",
            isDisabled: true,
            groupTypes: []
        });
        for (let i = 0; i < this.state.LoadGroups.length; i++) {
            groupsOptions.push({
                value: this.state.LoadGroups[i].Id,
                label: this.state.LoadGroups[i].Name,
                isDisabled: false,
                groupTypes: this.state.LoadGroups[i].GroupTypeRefs,
            });
        }
        groupsOptions = groupsOptions.filter(item => item.groupTypes.find(type => type.IdDocType === this.state.newDocument.docHeader.Type.Id))

        const getAttributeComponent = ({attributeData, disabled}) => {
            const isRequired = attributeData.IsRequired
            const isMultipleAllowed = attributeData.IsMultipleAllowed
            const attributeType = attributeData.AttributeName.IdAttributeType
            if (attributeData.AttributeName.IdDictionaries) {
                return (
                    <AttributeWithDictionaries
                        hardDisabledEditing={disabled}
                        attributeValue={attributeData.AttributeValue}
                        idDictionaryValue={attributeData.IdDictionaryValue}
                        idDictionaries={attributeData.AttributeName.IdDictionaries}
                        dictionaryFieldName={attributeData.AttributeName.DictionaryFieldName}
                        externalDictionariesValues={this.state.externalDictionariesValues}
                        isRedactorMode={true}
                        isMultiply={isMultipleAllowed}
                        setNewValues={(newValues) => {
                            this.changeAttributeValue({
                                id: attributeData.AttributeName.Id,
                                value: newValues,
                                isRequired: isRequired,
                                isDictionary: true
                            })
                        }}
                    />
                )
            } else if (attributeType === DataTypesEnum.Integer) {
                if (isMultipleAllowed) {
                    return (
                        <MySelectNew
                            hardDisabledEditing={disabled}
                            returnFunc={(list) => {
                                this.changeAttributeValue({
                                    id: attributeData.AttributeName.Id,
                                    value: list.slice(),
                                    isRequired: isRequired
                                })
                            }}
                            dataList={__.getCorrectValueForMultiply(attributeData.AttributeValue)}
                            type={attributeType}
                            placeholder={`${attributeData.AttributeName.Name}...`}
                            isMulti={true}
                            // стили для отображения контекстного меню браузера как текстового поля (копировать/вставить и т.д.)
                            styles={createSelectStyles}
                            className={''}
                        />
                    )
                } else {
                    return (
                        <input
                            className="form-control"
                            value={attributeData.AttributeValue || ''}
                            type={'number'}
                            step={'any'}
                            disabled={disabled}
                            onChange={(e) => this.changeAttributeValue({
                                id: attributeData.AttributeName.Id,
                                value: e.target.value,
                                isRequired: isRequired
                            })}/>

                    )
                }
            } else if (attributeType === DataTypesEnum.Float) {
                if (isMultipleAllowed) {
                    return (
                        <MySelectNew
                            hardDisabledEditing={disabled}
                            returnFunc={(list) => {
                                this.changeAttributeValue({
                                    id: attributeData.AttributeName.Id,
                                    value: list.slice(),
                                    isRequired: isRequired
                                })
                            }
                            }
                            dataList={__.getCorrectValueForMultiply(attributeData.AttributeValue)}
                            type={attributeType}
                            placeholder={`${attributeData.AttributeName.Name}...`}
                            isMulti={true}
                            // стили для отображения контекстного меню браузера как текстового поля (копировать/вставить и т.д.)
                            styles={createSelectStyles}
                            className={''}
                        />
                    )
                } else {
                    return (
                        <input
                            className="form-control"
                            value={attributeData.AttributeValue || ''}
                            type={'number'}
                            step={'any'}
                            disabled={disabled}
                            onChange={(e) => this.changeAttributeValue({
                                id: attributeData.AttributeName.Id,
                                value: e.target.value,
                                isRequired: isRequired
                            })}/>

                    )
                }
            } else if (attributeType === DataTypesEnum.String) {
                if (isMultipleAllowed) {
                    return (
                        <MySelectNew
                            hardDisabledEditing={disabled}
                            returnFunc={(list) => {
                                this.changeAttributeValue({
                                    id: attributeData.AttributeName.Id,
                                    value: list.slice(),
                                    isRequired: isRequired
                                })
                            }}
                            type={attributeType}
                            placeholder={`${attributeData.AttributeName.Name}...`}
                            dataList={__.getCorrectValueForMultiply(attributeData.AttributeValue)}
                            isMulti={true}
                            styles={createSelectStyles}
                            className={''}
                        />
                    )
                } else {
                    return (
                        <input
                            className="form-control"
                            value={attributeData.AttributeValue || ''}
                            disabled={disabled}
                            onChange={(e) => {
                                this.changeAttributeValue({
                                    id: attributeData.AttributeName.Id,
                                    value: e.target.value,
                                    isRequired: isRequired
                                })
                            }}/>

                    )
                }
            } else if (attributeType === DataTypesEnum.Boolean) {
                // TODO Не представляю как может случиться if (isMultipleAllowed) {}
                return (
                    <input
                        className="form-control"
                        value={attributeData.AttributeValue || ''}
                        type={'checkbox'}
                        disabled={disabled}
                        onChange={(e) => this.changeAttributeValue({
                            id: attributeData.AttributeName.Id,
                            value: e.target.value,
                            isRequired: isRequired
                        })}
                    />
                )
            } else if (attributeType === DataTypesEnum.DataTime) {
                if (isMultipleAllowed) {
                    return (
                        <MySelectNew
                            hardDisabledEditing={disabled}
                            returnFunc={(list) => {
                                this.changeAttributeValue({
                                    id: attributeData.AttributeName.Id,
                                    value: list.slice(),
                                    isRequired: isRequired
                                })
                            }}
                            type={attributeType}
                            placeholder={`${attributeData.AttributeName.Name}...`}
                            dataList={__.getCorrectValueForMultiply(attributeData.AttributeValue)}
                            isMulti={true}
                            styles={createSelectStyles}
                            className={''}
                            duplicateCallback={()=> {
                                this.props.addModalData({
                                    name: ModalTypes.app.alert,
                                    data: {
                                        content: "Новое значение не уникально для атрибута!",
                                        disableButton: false
                                    }
                                })
                            }}
                        />
                    )
                } else {
                    return (
                        <input
                            className="form-control"
                            type={'date'}
                            min="1900-01-01"
                            max="3000-12-31"
                            value={(attributeData.AttributeValue && __.dateToServer(attributeData.AttributeValue, false, true)) || ''}
                            disabled={disabled}
                            onChange={(e) => {
                                this.changeAttributeValue({
                                    id: attributeData.AttributeName.Id,
                                    value: __.getCorrectDate(e.target.value),
                                    isRequired: isRequired
                                })
                            }}/>
                    )
                }
            } else if (attributeType === DataTypesEnum.DocRef) {
                if (isMultipleAllowed) {
                    return (
                        <MultipleDocRef
                            hardDisabledEditing={disabled}
                            item={attributeData}
                            valueFieldName={'AttributeValue'}
                            isRedactorMode={true}
                            setNewItemValue={newValueOfItem => {
                                this.changeAttributeValue({
                                    id: attributeData.AttributeName.Id,
                                    value: newValueOfItem,
                                    isRequired: isRequired
                                })
                            }}
                            duplicateCallback={()=> {
                                this.props.addModalData({
                                    name: ModalTypes.app.alert,
                                    data: {
                                        content: "Новое значение не уникально для атрибута!",
                                        disableButton: false,
                                    }
                                })
                            }}
                        />
                    )
                } else {
                    return (
                        <DocRef
                            docIdValue={attributeData.AttributeValue}
                            shortDescription={attributeData.ShortDescription}
                            isRedactorMode={true}
                            hardDisabledEditing={disabled}
                            setCurrentDocIdValue={(value)=>{
                                this.changeAttributeValue({
                                    id: attributeData.AttributeName.Id,
                                    value: value,
                                    isRequired: isRequired
                                })}
                            }
                        />
                    )
                }
            } else if (attributeType === DataTypesEnum.DocVersionRef) {
                if (isMultipleAllowed) {
                    return (
                        <MultipleDocVersionRef
                            item={attributeData}
                            valueFieldName={'AttributeValue'}
                            isRedactorMode={true}
                            hardDisabledEditing={disabled}
                            setNewItemValue={newValueOfItem => {
                                this.changeAttributeValue({
                                    id: attributeData.AttributeName.Id,
                                    value: newValueOfItem,
                                    isRequired: isRequired
                                })
                            }}
                            duplicateCallback={()=> {
                                this.props.addModalData({
                                    name: ModalTypes.app.alert,
                                    data: {
                                        content: "Новое значение не уникально для атрибута!",
                                        disableButton: false
                                    }
                                })
                            }}
                        />
                    )
                } else {
                    return (
                        <DocVersionRef
                            hardDisabledEditing={disabled}
                            versionIdValue={attributeData.AttributeValue}
                            shortDescription={attributeData.ShortDescription}
                            isRedactorMode={true}
                            setCurrentVersionValue={(value)=> {
                                this.changeAttributeValue({
                                    id: attributeData.AttributeName.Id,
                                    value: value,
                                    isRequired: isRequired
                                })
                            }
                        }
                        />
                    )
                }
            } else if (attributeType === DataTypesEnum.JsonObject) {
                if (isMultipleAllowed) {
                    return (
                        <MultipleAttributeTable
                            hardDisabledEditing={disabled}
                            deleteRow={(lIndex) => this.deleteMultiplyRowHandler(lIndex, attributeData)}
                            addRow={(lIndex) => this.addMultiplyRowHandler(lIndex, attributeData)}
                            item={{
                                ...attributeData,
                                Value: __.getCorrectValueForMultiply(attributeData.AttributeValue) || []
                            }}
                            contents={(item, rowId) => [{
                                content: this.renderJsonObjectFragment(item, rowId, true, true, disabled),
                                style: {width: "100%"}
                            }]}
                        />
                    )
                } else {
                    return (
                        this.renderJsonObjectFragment(attributeData, null, false, true, disabled)
                    )
                }
            } else {
                // DataTypesEnum.RowBlob и DataTypesEnum.SearchBlob - вроде @deprecated
                return null
            }
        }

        return (
            <div className="modal fade show" style={modalStyle} onClick={this.hide}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" style={{minWidth: '80vw'}}>
                    <div className="modal-content"
                         onClick={(e) => e.stopPropagation()}
                    >
                        <div className="modal-header" style={{padding: "1rem 1rem", borderBottom: "none"}}>
                            <h5 className="modal-title text-success font-weight-bolder ">
                                Создание документа из версии
                            </h5>
                        </div>
                        <div className="modal-body beautiful-scroll-10"
                             style={{height: "40vw", padding: "0.8rem 1.75rem"}}>
                            <div className="col">
                                <div className="row">
                                    <div className="col-sm">
                                        <div className="row">
                                            <div className="col">
                                                <label>Версия документа: </label>
                                                <Select
                                                    style={{padding: "0"}}
                                                    value={this.state.newDocument.idSourceVersion}
                                                    onChange={async (item) => await this.changeVersion(item)}
                                                    options={versions}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: 'var(--hover-primary)',
                                                            primary25: 'var(--light-primary)',
                                                            primary50: 'var(--hover-primary)',
                                                            primary: 'var(--originaltwo)',
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col">
                                                <label>Дата: </label>
                                                <input type={"date"} className="form-control" value={this.state.oldDocument.Info.RegDate.split('T')[0]} disabled/>
                                            </div>
                                        </div>
                                        <label>Номер: </label>
                                        <input className="form-control" type={"text"}
                                               value={this.state.oldDocument.Info.RegNumber} disabled/>
                                        <label>Наименование: </label>
                                        <input className="form-control" type={"text"}
                                               value={this.state.oldDocument.Info.Name} disabled/>
                                        <label>Текущий тип документа: </label>
                                        <input className="form-control" type={"text"}
                                               value={this.state.oldDocument.Info.Type.Name} disabled/>
                                    </div>
                                    <div className="col-sm">
                                        <label className="font-weight-bolder">* Дата: </label>
                                        <input type="date" className="form-control"
                                               min="1900-01-01"
                                               max="3000-12-31"
                                               value={this.state.newDocument.docHeader.RegDate?.split('T')[0]}
                                               onChange={(e) => {
                                                   let newRegDateValue = e.target.value
                                                   const todayDate = new Date()
                                                   if (todayDate < new Date(newRegDateValue)) {
                                                       newRegDateValue = todayDate.toLocaleDateString().split('.').reverse().join('-')
                                                   }
                                                   newRegDateValue = newRegDateValue + "T00:00:00"
                                                   this.changeRegDate(newRegDateValue)
                                               }}
                                        />
                                        <label className="font-weight-bolder">* Номер: </label>
                                        <input
                                            style={{}}
                                            className="form-control"
                                            type={"text"}
                                            onChange={(e) => this.changeRegNumber(e.target.value)}
                                            value={this.state.newDocument.docHeader.RegNumber}
                                        />
                                        <label className="font-weight-bolder">* Наименование: </label>
                                        <input className="form-control" type={"text"}
                                               onChange={(e) => this.changeName(e.target.value)}
                                               value={this.state.newDocument.docHeader.Name}/>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div style={{width: '47%'}}>
                                                {this.state.newDocument.docHeader.Type && <>
                                                    <label className="font-weight-bolder">* Новый тип
                                                        документа: </label>
                                                    <Select
                                                        styles={sbStyle}
                                                        defaultValue={defaultType}
                                                        value={this.state.selectedNewDocTypeOption ?? defaultType}
                                                        components={animatedComponents}
                                                        onChange={(item) => {
                                                            this.changeTargetDataType(item);
                                                            this.setState({selectedNewDocTypeOption: item});
                                                        }}
                                                        options={this.state.sortingFoldersName}
                                                        placeholder="Выберите папку"
                                                        title="Из папки"
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                text: 'var(--hover-primary)',
                                                                primary25: 'var(--light-primary)',
                                                                primary50: 'var(--hover-primary)',
                                                                primary: 'var(--originaltwo)',
                                                            },
                                                        })}
                                                    />
                                                </>}
                                            </div>
                                            <div style={{width: '47%'}}>
                                                <label className="font-weight-bolder">Раздел документа: </label>
                                                <Select
                                                    styles={sbSelect}
                                                    placeholder="Выберите раздел"
                                                    title="Выберите раздел документа"
                                                    closeMenuOnSelect={true}
                                                    components={animatedComponents}
                                                    onChange={(value) => {
                                                        this.setState({SelectedGroups: value})
                                                    }}
                                                    options={groupsOptions}
                                                    noOptionsMessage={() => "Нет доступных разделов"}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: 'var(--hover-primary)',
                                                            primary25: 'var(--light-primary)',
                                                            primary50: 'var(--hover-primary)',
                                                            primary: 'var(--originaltwo)',
                                                        },
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-lg-6">
                                        <p>
                                            <span className="label label-inline label-light-success font-weight-bold">
                                                Текущие
                                            </span>
                                            Атрибуты реестра “{this.state.oldDocument.Info.Type.Name}”
                                        </p>
                                        <table
                                            className="table table-hover table-striped table-borderless table-vertical-center mb-0">
                                            <thead className="thead-light">
                                            <tr className="text-uppercase">
                                                <th style={{borderRadius: "1em 0 0 0"}}><p
                                                    className="text-success m-0 font-weight-normal">Атрибут</p></th>
                                                <th style={{borderRadius: "0 1em  0 0"}}><p
                                                    className="text-success m-0 font-weight-normal">Значение</p>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody style={{fontSize: "0.75em"}}>
                                            {
                                                this.state.oldDocument.unifiedDocAttributes.map((attributeData) => {
                                                    let isMismatch = false;
                                                    if (this.state.targetDataType) {
                                                        isMismatch = mismatchAttributes.find(attribute => attribute === attributeData.AttributeName.Name)
                                                    }
                                                    return (
                                                        <tr
                                                            style={this.state.targetDataType && !isMismatch ?
                                                                {backgroundColor: '#F58B8B'}
                                                                : this.state.targetFolder ?
                                                                    {backgroundColor: 'limegreen'}
                                                                    : null}
                                                            key={attributeData.AttributeName.Id}
                                                        >
                                                            <td>{attributeData.AttributeName.Name}</td>
                                                            <td>
                                                                {getAttributeComponent({
                                                                    attributeData,
                                                                    disabled: true
                                                                })}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-lg-6">
                                        <p>
                                            <span className="label label-inline label-light-primary font-weight-bold">
                                                Новые
                                            </span>
                                            Атрибуты реестра “{this.state.targetDataType.Name}”
                                        </p>
                                        <table
                                            className="table table-hover table-striped table-borderless table-vertical-center mb-0">
                                            <thead className="thead-light">
                                            <tr className="text-uppercase">
                                                <th style={{borderRadius: "1em 0 0 0"}}><p
                                                    className="text-success m-0 font-weight-normal">Атрибут</p></th>
                                                <th style={{borderRadius: "0 1em  0 0"}}><p
                                                    className="text-success m-0 font-weight-normal">Значение</p>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody style={{fontSize: "0.75em"}}>
                                            {this.state.newDocument.unifiedDocAttributes.map((attributeData) => {
                                                // get styles
                                                let withValue
                                                if (attributeData.IsMultipleAllowed) {
                                                    if (!Array.isArray(attributeData.AttributeValue)) {
                                                        withValue = attributeData.AttributeValue
                                                    } else {
                                                        withValue = attributeData.AttributeValue.length > 0
                                                    }
                                                } else {
                                                    withValue = attributeData.AttributeValue
                                                }
                                                let style = null
                                                if (!withValue && attributeData.IsRequired) {
                                                    style = {backgroundColor: '#F0F2B0'}
                                                } else if (withValue) {
                                                    style = {backgroundColor: '#B0F2B7'}
                                                }

                                                return (
                                                    <tr style={style}
                                                        key={attributeData.AttributeName.Id}
                                                    >
                                                        <td style={attributeData.IsRequired ? {fontWeight: '700'} : null}>{attributeData.AttributeName.Name}</td>
                                                        <td>
                                                            {getAttributeComponent({
                                                                attributeData,

                                                                disabled: false
                                                            })}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="form-group mb-4">
                                    <div className="d-flex flex-column">
                                        <label className="font-weight-bolder">* Комментарий к новому документу:</label>
                                        <textarea
                                            style={{resize: "none"}}
                                            name="comment"
                                            id="1"
                                            cols="30"
                                            rows="10"
                                            onChange={e => this.changeComment(e.target.value)}
                                            value={this.state.newDocument.comment}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer" style={{padding: "1rem 1rem", borderTop: "none"}}>
                            <button
                                type="button"
                                disabled={isDisabled}
                                style={{cursor: isDisabled ? 'default' : 'pointer'}}
                                className="btn btn-primary font-weight-bolder"
                                onClick={this.postNewDocumentByVersion}
                            >
                                Создать новый документ из версии
                            </button>
                            <button
                                type="button"
                                className="btn btn-light font-weight-bold"
                                onClick={this.hide}>
                                Отменить действие
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        activeNode: state.document.tree.activeNode,
        activeDocument: state.document.mainTable.activeRow,
        allFolders: state.document.tree.docs,
        router: state.router,
        versionList: state.document.additionalSections.state.versionSection.versions
    }
}

const mapDispatchToProps = {
    addModalData,
    deleteModalData,
    combineMainTableAttributes,
    mainTableDownloadDocCollection,
    getSectionsForTree,
    deleteSectionsForTree,
    saveSectionsForTree
}

export default connect(mapStateToProps, mapDispatchToProps)(NewDocumentByVersion);
