import React from "react";
import {connect} from "react-redux";
import LeftMenu from "../static/LeftMenu";
import TopMenu from "../static/TopMenu";
import {
    createTree, getDocGroupStructureNodes,
    getDocumentGroupsForTree,
    getDocumentsForTree, renderStructureTree, treeSelectNode, toggleIsLoading
} from "../../store/documents/actionCreators/document_TreeActionCreator";
import {ActionQueue} from "../../store/rootReducer";
import TreeDocs from "../tree/TreeDocs";
import TreeSearch from "../tree/TreeSearch";
import {AdminLevelEnum, RootComponentsStateViewModeTypes, UserSettingsNames} from "../../tools/StaticTypes";
import MainTable from "../mainTable/MainTable";
import DocPreview from "../preview/DocPreview";
import AttributesSection from "../additionalDataSection/AttributesSection";
import ContentSection from "../additionalDataSection/ContentSection";
import FilesSection from "../additionalDataSection/FilesSection";
import VersionSection from "../additionalDataSection/VersionSection";
import DepartmentsSection from "../additionalDataSection/DepartmentsSection";
import {
    setLoaderModalData,
    setStateLeftAsideMenu
} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import DocumentPackagesSection from "../additionalDataSection/DocumentPackagesSection";
import DocumentsWelcome from "../static/DocumentsWelcome";
import InputSearchDoc from "../inputSearch/inputSearchDoc";
import {history, store} from "../../index";
import MobileMenu from "../static/MobileMenu";
import {createRedactorData} from "../../store/documents/actionCreators/document_PreviewActionCreator";
import {changeDocumentViewMode} from "../../store/documents/actionCreators/document_RootStateActionCreator";
import {
    changeViewMode
} from "../../store/documents/actionCreators/document_SearchActionCreator";
import {setModalData} from "../../store/globalState/actionCreators/globalState_AppActionCreator";

class DocumentApp extends React.Component {
    constructor(props) {
        super(props);

        this.lFlagWithDepartments = false;
        let {globalSettings} = this.props;
        if (globalSettings)
            if (globalSettings.WithDepartments)
                if (globalSettings.WithDepartments === "1" && this.props.adminLevel > AdminLevelEnum.CommonUser)
                    this.lFlagWithDepartments = true


        this.state = {
            redactorMode: false
        };

        this.viewRender = this.viewRender.bind(this);
        this.viewRender_None = this.viewRender_None.bind(this);
        this.viewRender_MainTableOnly = this.viewRender_MainTableOnly.bind(this);
        this.viewRender_PreviewNormal = this.viewRender_PreviewNormal.bind(this);
        this.viewRender_PreviewCards = this.viewRender_PreviewCards.bind(this);
        this.viewRenderPreviewNormal_PreviewFullScreen = this.viewRenderPreviewNormal_PreviewFullScreen.bind(this);
        this.viewRenderPreviewNormal_PreviewHalfScreen = this.viewRenderPreviewNormal_PreviewHalfScreen.bind(this);
        this.viewRender_PreviewCards_HalfScreen = this.viewRender_PreviewCards_HalfScreen.bind(this);
        this.viewRender_PreviewCards_FullScreen = this.viewRender_PreviewCards_FullScreen.bind(this);
        this.viewRender_PreviewCards_RedactorMode = this.viewRender_PreviewCards_RedactorMode.bind(this);
        this.viewRender_PreviewCards_RedactorMode_HalfScreen = this.viewRender_PreviewCards_RedactorMode_HalfScreen.bind(this);
        this.viewRender_PreviewCards_RedactorMode_FullScreen = this.viewRender_PreviewCards_RedactorMode_FullScreen.bind(this);
        this.viewRender_Search = this.viewRender_Search.bind(this);

        this.topMenu = React.createRef();
    }

    componentDidMount() {
        // this.props.setStateLeftAsideMenu({
        //     isClose : false
        // });

        if (store.getState().document.search.isSearch || store.getState().document.rootState.isOpenAsideMenu){
            this.props.setStateLeftAsideMenu({isClose: false})
        }

        if(!this.props.tree.isLoaded) { // проверить загружены ли данные, чтобы предотвратить повторную загрузку
            this.props.ActionQueue({ // загружаем необходимые для работы приложения данные
                List : [
                    //Новый прелоадер
                    {action : this.props.setLoaderModalData, params : {
                            data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "documents", key : "DocumentApp69" }}, name : "setLoaderModalData"},
                    {action : this.props.getDocumentsForTree, params : {filial : this.props.filial}, name : "getDocumentsForTree"},
                    {action : this.props.getDocumentGroupsForTree, params : {filial : this.props.filial}, name : "getDocumentGroupsForTree"},
                    // {action : this.props.createTree, params : {}, name : "createTree"},
                    {action: this.props.getDocGroupStructureNodes, params: {filial: this.props.filial}, name: "getDocGroupStructureNodes"},
                    {action: this.props.renderStructureTree, params : {}, name : "renderStructureTree"},
                    //Новый прелоадер
                    {action : this.props.setLoaderModalData, params : {keyDeleted: "DocumentApp76"}, name : "setLoaderModalData"},
                    // не убирать finish, нужен для роутинга
                    {action : () => {store.dispatch({type : "finish"})}, params : {}, name : "finish"},
                ],
                debug : true,
            });
        }
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    // }

    // выбор режима отображения
    viewRender() {
        let rootState = this.props.rootState;
        let rootStateType = RootComponentsStateViewModeTypes;

        if(rootState.viewMode === rootStateType.DocumentsApp.None ) {
            return this.viewRender_None();
        }
        else if(rootState.viewMode === rootStateType.DocumentsApp.MainTableOnly ) {
            return this.viewRender_MainTableOnly();
        }

        // предпросмотр
        else if(rootState.viewMode === rootStateType.DocumentsApp.PreviewNormal ) {
            return this.viewRender_PreviewNormal();
        }
        else if(rootState.viewMode === rootStateType.DocumentsApp.PreviewNormal_PreviewHalfScreen ) {
            return this.viewRenderPreviewNormal_PreviewHalfScreen();
        }
        else if(rootState.viewMode === rootStateType.DocumentsApp.PreviewNormal_PreviewFullScreen ) {
            return this.viewRenderPreviewNormal_PreviewFullScreen();
        }
        else if(rootState.viewMode === rootStateType.DocumentsApp.PreviewNormal_Hide ) {
            return this.viewRenderPreviewNormal_Hide();
        }

        // карточки документов
        else if(rootState.viewMode === rootStateType.DocumentsApp.PreviewCards ) {
            return this.viewRender_PreviewCards();
        }
        else if(rootState.viewMode === rootStateType.DocumentsApp.PreviewCards_HalfScreen ) {
            return this.viewRender_PreviewCards_HalfScreen();
        }
        else if(rootState.viewMode === rootStateType.DocumentsApp.PreviewCards_FullScreen ) {
            return this.viewRender_PreviewCards_FullScreen();
        }
        else if(rootState.viewMode === rootStateType.DocumentsApp.PreviewCards_RedactorMode ) {
            return this.viewRender_PreviewCards_RedactorMode();
        }
        else if(rootState.viewMode === rootStateType.DocumentsApp.PreviewCards_RedactorMode_HalfScreen) {
            return this.viewRender_PreviewCards_RedactorMode_HalfScreen();
        }
        else if(rootState.viewMode === rootStateType.DocumentsApp.PreviewCards_RedactorMode_FullScreen) {
            return this.viewRender_PreviewCards_RedactorMode_FullScreen();
        }
        //поиск
        else if(rootState.viewMode === rootStateType.DocumentsApp.Search) {
            return this.viewRender_Search();
        }
    }

    viewRender_None() {
        return <DocumentsWelcome/>;
    }

    // отображение только таблицы
    viewRender_MainTableOnly() {
        return (
            <div className="row">
                <div className="card card-custom container-solid col-xl-12 ignoreHeight-in-1200" style={{height : "85vh"}}>
                    <MainTable/>
                </div>
            </div>
        );
    }

    // отображение обычно превью с атрибутами внизу
    viewRender_PreviewNormal() {
        if (this.state.redactorMode)
        this.setState({redactorMode: false})
        return (
            <>
                <div className="row" style={{marginRight: '-25.5px'}}>
                    <div className="card card-custom container-solid col-xl-9 ignoreHeight-in-1200" style={{height : "85vh"}}>
                        <MainTable/>
                    </div>
                    <div className="card card-custom container-solid col-xl-3 ignoreHeight-in-1200" style={{height : "85vh"}}>
                        <DocPreview viewMode={"normal"} redactorMode={false}/>
                    </div>
                </div>
                <div className="row pt-1" style={{marginRight: '-25.5px'}}>
                    <div className="card card-custom container-solid col-xl-7">
                        <div className="accordion accordion-solid accordion-toggle-plus py-4">
                            <AttributesSection viewMode={"normal"} redactorMode={false}/>
                            <ContentSection viewMode={"normal"} redactorMode={false}/>
                            <FilesSection viewMode={"normal"} redactorMode={false}/>
                        </div>
                    </div>
                    <div className="card card-custom container-solid col-xl-5">
                        <div className="accordion accordion-solid accordion-toggle-plus py-4">
                            <VersionSection viewMode={"normal"}/>
                            <DocumentPackagesSection viewMode={"normal"} redactorMode={false}/>
                            {this.lFlagWithDepartments && <DepartmentsSection viewMode={"normal"} />}
                        </div>
                    </div>
                </div>
                {/*TODO: MS 25.04 — СМЕРЖИТЬ*/}
                {/*{*/}
                {/*    this.props.isLoading && <AlertModal/>*/}
                {/*}*/}
            </>
        );
    }

    viewRenderPreviewNormal_Hide() {
       /// let link = `${this.props.router.location.pathname}${this.props.router.location.search.split("&c")[0]}`;
        if (this.state.redactorMode)
        this.setState({redactorMode: false})

        return (
            <>
                <div className="row" style={{marginRight: '-25.5px'}}>
                    <div className="card card-custom container-solid col-xl-11 ignoreHeight-in-1200" style={{height : "85vh"}}>
                        <MainTable/>
                    </div>
                    <div className="card card-custom container-solid col-xl-1 align-items-center ignoreHeight-in-1200" style={{height : "85vh"}}>
                        <DocPreview viewMode={"normal"} redactorMode={false} />
                    </div>
                </div>
                <div className="row pt-1" style={{marginRight: '-25.5px'}}>
                    <div className="card card-custom container-solid col-xl-8">
                        <div className="accordion accordion-solid accordion-toggle-plus py-4">
                            <AttributesSection viewMode={"normal"} redactorMode={false}/>
                            <ContentSection viewMode={"normal"} redactorMode={false}/>
                            <FilesSection viewMode={"normal"} redactorMode={false}/>
                        </div>
                    </div>
                    <div className="card card-custom container-solid col-xl-4">
                        <div className="accordion accordion-solid accordion-toggle-plus py-4">
                            <VersionSection viewMode={"normal"}/>
                            <DocumentPackagesSection viewMode={"normal"} redactorMode={false}/>
                            {this.lFlagWithDepartments && <DepartmentsSection viewMode={"normal"} />}
                        </div>
                    </div>
                </div>
            </>
        );
    }
    // отображение окно предпросмотра в увеличенном виде
    viewRenderPreviewNormal_PreviewHalfScreen() {
        if (this.state.redactorMode)
        this.setState({redactorMode: false})
        return (
            <>
                <div className="row" style={{marginRight: '-25.5px'}}>
                    <div className="card card-custom container-solid col-xl-6 ignoreHeight-in-1200" style={{height : "85vh"}}>
                        <MainTable/>
                    </div>
                    <div className="card card-custom container-solid col-xl-6 ignoreHeight-in-1200" style={{height : "85vh"}}>
                        <DocPreview viewMode={"normal"} redactorMode={false}/>
                    </div>
                </div>
                <div className="row pt-1" style={{marginRight: '-25.5px'}}>
                    <div className="card card-custom container-solid col-xl-8">
                        <div className="accordion accordion-solid accordion-toggle-plus py-4">
                            <AttributesSection viewMode={"normal"} redactorMode={false}/>
                            <ContentSection viewMode={"normal"} redactorMode={false}/>
                            <FilesSection viewMode={"normal"} redactorMode={false}/>
                        </div>
                    </div>
                    <div className="card card-custom container-solid col-xl-4">
                        <div className="accordion accordion-solid accordion-toggle-plus py-4">
                            <VersionSection viewMode={"normal"} redactorMode={false}/>
                            <DocumentPackagesSection viewMode={"normal"} redactorMode={false}/>
                            {this.lFlagWithDepartments && <DepartmentsSection viewMode={"normal"} />}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    // отображение окно предпросмотра развернутое на полный экран
    viewRenderPreviewNormal_PreviewFullScreen() {
        if (this.state.redactorMode)
        this.setState({redactorMode: false})
        return (
            <>
                <div className="row" style={{marginRight: '-25.5px'}}>
                    <div className="card card-custom container-solid col-xl-9" style={{display : "none"}}>
                        <MainTable/>
                    </div>
                    <div className="card card-custom container-solid col-xl-12 ignoreHeight-in-1200" style={{height : "85vh"}}>
                        <DocPreview viewMode={"normal"} redactorMode={false}/>
                    </div>
                </div>
                <div className="row pt-1" style={{marginRight: '-25.5px'}}>
                    <div className="card card-custom container-solid col-xl-8">
                        <div className="accordion accordion-solid accordion-toggle-plus py-4">
                            <AttributesSection viewMode={"normal"} redactorMode={false}/>
                            <ContentSection viewMode={"normal"} redactorMode={false}/>
                            <FilesSection viewMode={"normal"} redactorMode={false}/>
                        </div>
                    </div>
                    <div className="card card-custom container-solid col-xl-4">
                        <div className="accordion accordion-solid accordion-toggle-plus py-4">
                            <VersionSection viewMode={"normal"} redactorMode={false}/>
                            <DocumentPackagesSection viewMode={"normal"} redactorMode={false}/>
                            {this.lFlagWithDepartments && <DepartmentsSection viewMode={"normal"} />}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    // отображение в виде карточки документов
    viewRender_PreviewCards() {
        if (this.state.redactorMode)
        this.setState({redactorMode: false})
        return(
            <>
                <div className="row" style={{marginRight: '-25.5px'}}>
                    <div className="card card-custom container-solid col-xl-9" style={{display : "none"}}>
                        <MainTable/>
                    </div>
                    <div className="card card-custom container-solid col-xl-6 ignoreHeight-in-1200" style={{height : "90vh"}}>
                        <DocPreview viewMode={"card"} redactorMode={false}/>
                    </div>
                    <div className="card card-custom container-solid col py-4 ignoreHeight-in-1200" style={{height : "90vh"}}>
                        <div className="accordion accordion-solid accordion-toggle-plus beautiful-scroll-5">
                            <AttributesSection viewMode={"card"} redactorMode={false}/>
                            {/*<DocTypeSection viewMode={"card"} redactorMode={false}/>*/}
                            <ContentSection viewMode={"card"} redactorMode={false}/>
                            <FilesSection viewMode={"card"} redactorMode={false}/>
                            <VersionSection viewMode={"card"} redactorMode={false}/>
                            <DocumentPackagesSection viewMode={"card"} redactorMode={false}/>
                            {this.lFlagWithDepartments && <DepartmentsSection viewMode={"card"} />}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    // карточка документа (увеличенный размер)
    viewRender_PreviewCards_HalfScreen() {
        if (this.state.redactorMode)
        this.setState({redactorMode: false})
        return(
            <>
                <div className="row" style={{marginRight: '-25.5px'}}>
                    <div className="card card-custom container-solid col-xl-9" style={{display : "none"}}>
                        <MainTable/>
                    </div>
                    <div className="card card-custom container-solid col-xl-8 ignoreHeight-in-1200" style={{height : "90vh"}}>
                        <DocPreview viewMode={"card"} redactorMode={false}/>
                    </div>
                    <div className="card card-custom container-solid col py-4 ignoreHeight-in-1200" style={{height : "90vh"}}>
                        <div className="accordion accordion-solid accordion-toggle-plus beautiful-scroll-5">
                            <AttributesSection viewMode={"card"} redactorMode={false}/>
                            {/*<DocTypeSection viewMode={"card"} redactorMode={false}/>*/}
                            <ContentSection viewMode={"card"} redactorMode={false}/>
                            <FilesSection viewMode={"card"} redactorMode={false}/>
                            <VersionSection viewMode={"card"} redactorMode={false}/>
                            <DocumentPackagesSection viewMode={"card"} redactorMode={false}/>
                            {this.lFlagWithDepartments && <DepartmentsSection viewMode={"card"} />}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    // карточка документа (на полный экран)
    viewRender_PreviewCards_FullScreen() {
        if (this.state.redactorMode)
        this.setState({redactorMode: false})
        return(
            <>
                <div className="row" style={{marginRight: '-25.5px'}}>
                    <div className="card card-custom container-solid col-xl-9" style={{display : "none"}}>
                        <MainTable/>
                    </div>
                    <div className="card card-custom container-solid col-xl-12 ignoreHeight-in-1200" style={{height : "90vh"}}>
                        <DocPreview viewMode={"card"} redactorMode={false}/>
                    </div>
                    <div className="card card-custom container-solid col">
                        <div className="accordion accordion-solid accordion-toggle-plus py-4">
                            <AttributesSection viewMode={"card"} redactorMode={false}/>
                            {/*<DocTypeSection viewMode={"card"} redactorMode={false}/>*/}
                            <ContentSection viewMode={"card"} redactorMode={false}/>
                            <FilesSection viewMode={"card"} redactorMode={false}/>
                            <VersionSection viewMode={"card"} redactorMode={false}/>
                            <DocumentPackagesSection viewMode={"card"} redactorMode={false}/>
                            {this.lFlagWithDepartments && <DepartmentsSection viewMode={"card"} />}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    // режим редактирования документа
    viewRender_PreviewCards_RedactorMode() {
        if (!this.state.redactorMode)
        this.setState({redactorMode: true})
        return(
            <>
                <div className="row" style={{marginRight: '-25.5px'}}>
                    <div className="card card-custom container-solid col-xl-9" style={{display : "none"}}>
                        <MainTable/>
                    </div>
                    <div className="card card-custom container-solid col-xl-6 ignoreHeight-in-1200" style={{height : "90vh"}}>
                        <DocPreview viewMode={"card"} redactorMode={true}/>
                    </div>
                    <div className="card card-custom container-solid col py-4 ignoreHeight-in-1200" style={{height : "90vh"}}>
                        <div className="accordion accordion-solid accordion-toggle-plus beautiful-scroll-5">
                            <AttributesSection viewMode={"card"} redactorMode={true}/>
                            {/*<DocTypeSection viewMode={"card"} redactorMode={true}/>*/}
                            <ContentSection viewMode={"card"} redactorMode={true}/>
                            <FilesSection viewMode={"card"} redactorMode={true}/>
                            <VersionSection viewMode={"card"} redactorMode={true}/>
                            <DocumentPackagesSection viewMode={"card"} redactorMode={true}/>
                            {this.lFlagWithDepartments && <DepartmentsSection viewMode={"card"} redactorMode={true}/>}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    // режим редактирования документа (увеличенный предпросмотр)
    viewRender_PreviewCards_RedactorMode_HalfScreen() {
        if (!this.state.redactorMode)
        this.setState({redactorMode: true})
        return(
            <>
                <div className="row" style={{marginRight: '-25.5px'}}>
                    <div className="card card-custom container-solid col-xl-9" style={{display : "none"}}>
                        <MainTable/>
                    </div>
                    <div className="card card-custom container-solid col-xl-8 ignoreHeight-in-1200" style={{height : "90vh"}}>
                        <DocPreview viewMode={"card"} redactorMode={true}/>
                    </div>
                    <div className="card card-custom container-solid col py-4 ignoreHeight-in-1200" style={{height : "90vh"}}>
                        <div className="accordion accordion-solid accordion-toggle-plus beautiful-scroll-5">
                            <AttributesSection viewMode={"card"} redactorMode={true}/>
                            {/*<DocTypeSection viewMode={"card"} redactorMode={true}/>*/}
                            <ContentSection viewMode={"card"} redactorMode={true}/>
                            <FilesSection viewMode={"card"} redactorMode={true}/>
                            <VersionSection viewMode={"card"} redactorMode={true}/>
                            <DocumentPackagesSection viewMode={"card"} redactorMode={true}/>
                            {this.lFlagWithDepartments && <DepartmentsSection viewMode={"card"} redactorMode={true}/>}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    // режим редактирования документа (предпросмотр в полный экран)
    viewRender_PreviewCards_RedactorMode_FullScreen() {
        if (!this.state.redactorMode)
        this.setState({redactorMode: true})
        return(
            <>
                <div className="row" style={{marginRight: '-25.5px'}}>
                    <div className="card card-custom container-solid col-xl-9" style={{display : "none"}}>
                        <MainTable/>
                    </div>
                    <div className="card card-custom container-solid col-xl-12 ignoreHeight-in-1200" style={{height : "90vh"}}>
                        <DocPreview viewMode={"card"} redactorMode={true}/>
                    </div>
                    <div className="card card-custom container-solid col">
                        <div className="accordion accordion-solid accordion-toggle-plus py-4">
                            <AttributesSection viewMode={"card"} redactorMode={true}/>
                            {/*<DocTypeSection viewMode={"card"} redactorMode={true}/>*/}
                            <ContentSection viewMode={"card"} redactorMode={true}/>
                            <FilesSection viewMode={"card"} redactorMode={true}/>
                            <VersionSection viewMode={"card"} redactorMode={true}/>
                            <DocumentPackagesSection viewMode={"card"} redactorMode={true}/>
                            {this.lFlagWithDepartments && <DepartmentsSection viewMode={"card"} redactorMode={true}/>}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    // режим поиска
    viewRender_Search() {
        if (this.state.redactorMode)
            this.setState({redactorMode: false})
        //
        return (
            <>
                <div className="row" style={{marginRight: '-25.5px'}}>
                    <div className="card card-custom container-solid col-xl-9 ignoreHeight-in-1200" style={{height : "85vh"}}>
                        <MainTable/>
                    </div>
                    <div className="card card-custom container-solid col-xl-3 ignoreHeight-in-1200" style={{height : "85vh"}}>
                        <DocPreview viewMode={"normal"} redactorMode={false}/>
                    </div>
                </div>
                <div className="row pt-1" style={{marginRight: '-25.5px'}}>
                    <div className="card card-custom container-solid col-xl-8">
                        <div className="accordion accordion-solid accordion-toggle-plus py-4">
                            <AttributesSection viewMode={"normal"} redactorMode={false}/>
                            <ContentSection viewMode={"normal"} redactorMode={false}/>
                            <FilesSection viewMode={"normal"} redactorMode={false}/>
                        </div>
                    </div>
                    <div className="card card-custom container-solid col-xl-4">
                        <div className="accordion accordion-solid accordion-toggle-plus py-4">
                            <VersionSection viewMode={"normal"}/>
                            <DocumentPackagesSection viewMode={"normal"} redactorMode={false}/>
                            {this.lFlagWithDepartments && <DepartmentsSection viewMode={"normal"} redactorMode={false}/>}
                        </div>
                    </div>
                </div>
            </>
        );
    }


    render() {
        const { asideMenuIsClose, rootState: { isOpenAsideMenu }, isIncreaseScope } = this.props;
        // содержание страницы
        let content = this.viewRender();

        // пока костыль. Чтобы не было огромного отступа от верхнего меню
        ///////////////////////
        let rootState = this.props.rootState;
        let rootStateType = RootComponentsStateViewModeTypes;
        let paddingTopMenu = {};
        if (rootState.viewMode !== rootStateType.DocumentsApp.PreviewCards ||
            rootState.viewMode !== rootStateType.DocumentsApp.PreviewCards_HalfScreen ||
            rootState.viewMode !== rootStateType.DocumentsApp.PreviewCards_FullScreen) {
            paddingTopMenu = {
                paddingBottom : "0px"
            };
        }

        let isFiltersShow = this.props.isSearch;


        ///////////////////////


        let viewMode = "normal";
        if (rootState.viewMode === rootStateType.DocumentsApp.PreviewCards ||
            rootState.viewMode === rootStateType.DocumentsApp.PreviewCards_HalfScreen ||
            rootState.viewMode === rootStateType.DocumentsApp.PreviewCards_FullScreen) {
            viewMode = "card";
        }
        else if (rootState.viewMode === rootStateType.DocumentsApp.PreviewCards_RedactorMode ||
            rootState.viewMode === rootStateType.DocumentsApp.PreviewCards_RedactorMode_HalfScreen ||
            rootState.viewMode === rootStateType.DocumentsApp.PreviewCards_RedactorMode_FullScreen) {
            viewMode = "redactor";
        }


        let topMenuStyle = {};
        if(viewMode === "card" || viewMode === "redactor") {
            topMenuStyle = {
                position : "sticky",
                zIndex : "10",
                width : "100%"
            };
        }

        let isShowAsideMenu = isFiltersShow ? !asideMenuIsClose : isOpenAsideMenu;

        let asideMobileStyle = "";
        if(isShowAsideMenu) {
            asideMobileStyle = "aside-on";
        }

        return (
            <div>
                <div className={`header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled aside-left-menu ${!isShowAsideMenu ? 'aside-minimize' : ''}`}>

                    <MobileMenu rootName={"DocumentApp"} isSearch={isFiltersShow} />

                    <div className="d-flex flex-column flex-root">
                        <div className={`aside aside-left d-flex aside-fixed ${asideMobileStyle}`}>
                            <LeftMenu rootName="DocumentApp" asideMenuOnHover={isIncreaseScope ? this.state.asideMenuOnHover : false} isSearch={isFiltersShow} />
                            {
                                isShowAsideMenu &&
                                <div className="aside-secondary d-flex flex-grow-1">
                                    <div className={`aside-workspace ${isIncreaseScope ? "aside-workspace-p" : ""} scroll scroll-push`}
                                         onMouseEnter={()=>{this.setState({asideMenuOnHover : true})}}
                                         onMouseLeave={()=>{this.setState({asideMenuOnHover : false})}}>
                                        <div className="tab-content">
                                            <div className="tab-pane p-3 px-lg-7 py-lg-5 fade show active no-visible-scroll">
                                                {
                                                    isFiltersShow ?
                                                        <div className="d-flex">
                                                            <div className="p-2 p-lg-2 my-1 my-lg-2">
                                                                <h3 className="text-color-primary font-weight-bolder"
                                                                    title={"Главная"}
                                                                    style={{cursor : "pointer"}}
                                                                    onClick={handleTitleClick}
                                                                >
                                                                    Документы
                                                                </h3>

                                                                {/*<span>Основная директория</span>*/}

                                                                {/*<span className="text-muted mt-3 font-weight-bold font-size-sm">Основная директория</span>*/}
                                                            </div>
                                                        </div>
                                                        :
                                                        <TreeSearch/>
                                                }
                                                <div className={`aside-menu-wrapper px-2 py-2 beautiful-scroll-5 scrollbar-gutter-stable`}>
                                                    {/*// TODO: BUG FIX MS #21543*/}
                                                    <div className="aside-menu">
                                                        {isFiltersShow ? <InputSearchDoc/>
                                                            : this.props.tree.isLoaded && <TreeDocs/>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="d-flex flex-column flex-row-fluid wrapper" style={topMenuStyle} ref={this.topMenu}>
                            <div className="content d-flex flex-column flex-column-fluid" style={paddingTopMenu}>
                                <TopMenu rootName="DocumentApp" viewMode={viewMode} redactorMode={this.state.redactorMode}/>
                            </div>
                            <div className="d-flex flex-column flex-column-fluid px-4">
                                <div className="container-new">
                                    {content}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const handleTitleClick = () => {
    let isContinue = true;
    if(store.getState().document.cards.redactorData) {
        isContinue = window.confirm("Возможно имеются несохраненные данные. Продолжить?");
    }

    if(!isContinue) {
        return false;
    }

    store.dispatch(changeDocumentViewMode({
        ViewMode : RootComponentsStateViewModeTypes.DocumentsApp.None,
        Next : {
            action : ()=>{
                store.dispatch(changeViewMode({
                    isSearch : false
                }));
                window.onbeforeunload = null;
                window.addEventListener("popstate", null);

                history.push("/documents");
                store.dispatch(createRedactorData({
                    CardId : null
                }));
            },
            params : {}
        }
    }));
}

const  mapStateToProps = state => {

    return {
        rootState : state.document.rootState,
        filial : state.globalState.filial.Active,
        tree : state.document.tree,
        isLoading: state.document.tree.isLoading,
        adminLevel : state.globalState.user.AdminLevel,
        globalSettings: state.globalState.settings.Content,
        asideMenuIsClose : state.globalState.app.asideMenuIsClose,
        isSearch: state.document.search.isSearch,
        redactorData : state.document.cards.redactorData,
        isIncreaseScope: state.globalState.userSettings.find(item => item.Name === UserSettingsNames.GENERAL_CONFIG)?.Value?.documentIncreaseScope,
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    getDocumentsForTree,
    toggleIsLoading,
    getDocumentGroupsForTree,
    createTree,
    setStateLeftAsideMenu,
    createRedactorData,
    changeDocumentViewMode,
    changeViewMode,
    renderStructureTree,
    getDocGroupStructureNodes,
    treeSelectNode,
    setModalData,
    setLoaderModalData
}


export default connect(mapStateToProps, mapDispatchToProps)(DocumentApp);
