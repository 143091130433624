import React from "react";
import Select from "react-select";
import {__, HelpFunctions} from "../../tools/HelpFunctions";
import {ModalTypes} from "../../tools/StaticTypes";
import {connect} from "react-redux";
import {addModalData} from "../../store/globalState/actionCreators/globalState_AppActionCreator";

class JsonObjectFragment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentJsonOption: (HelpFunctions.isJSON(props.lInputValue) && JSON.parse(props.lInputValue)?.type === 'table') ? 'Table' : 'Tree',
        };
    }

    showJsonTree(item, content, redactorMode, callback) {
        this.setState({currentJson: item})
        this.props.addModalData({
            name: ModalTypes.documents.additionalSection.ShowJsonTree,
            data: {content, redactorMode, callback}
        });
    }
    showJsonObject(item, content, redactorMode, callback) {
        this.setState({currentJson: item})
        this.props.addModalData({
            name: ModalTypes.documents.additionalSection.ShowJsonObject,
            data: {content, redactorMode, callback}
        });
    }

    modalAttributeTable(item, inputValue, jsonSchema, isRedactorMode, callback) {
        this.props.addModalData({
            name: ModalTypes.documents.additionalSection.AttributesTable,
            data: {
                Attribute: {
                    Value: inputValue,
                    Name: item.AttributeName.Name,
                    IsConstant: item.IsConstant,
                    IdAttributeName: item.IdAttributeName,
                    JsonSchema: jsonSchema
                },
                IsRedactorMode: isRedactorMode,
                //TODO aa 12.02.2024 почему редактор только для мастера? Нужен ли этот признак вовсе???????
                // fromMaster: true,
                callback
            }
        });
    }

    render() {
        const {
            lAllInputValue,
            rowId,
            item,
            hardDisabledEditing, //disable редактирования при isRedactorMode
            // lInputValue,
            inputHandler,
            isRedactorMode
        } = this.props;

        const options = [

            {
                label: 'Дерево',
                value: 'Tree',
            },
            {
                label: 'Таблица',
                value: 'Table',
            },
            {
                label: 'Произвольный объект',
                value: 'Raw',
            },
        ];

        let  jsonValue = null;
        const lInputValue = this.props.lInputValue?.value ? this.props.lInputValue?.value : this.props.lInputValue
        let shortDescription = ''
        if( lInputValue && HelpFunctions.isJSON(lInputValue)){
            jsonValue  = JSON.parse(lInputValue);
            if (jsonValue.type === 'table') {
                if (jsonValue?.shortDescription) {
                    shortDescription = jsonValue?.shortDescription
                } else if (jsonValue.renderValue) {
                    shortDescription = `Объект: ${jsonValue.renderValue}`
                } else if (jsonValue.headers && jsonValue.headers.length > 0) {
                    shortDescription =`Объект: ${jsonValue.headers[0].renderValue}`
                } else {
                    shortDescription =`Объект не задан`
                }
            } else if (jsonValue.type === 'tree') {
                if (jsonValue?.shortDescription) {
                    shortDescription = jsonValue.shortDescription
                } else if (jsonValue.root?.renderValue.toString()) {
                    let renderValue = jsonValue.root?.renderValue;
                    if (jsonValue.root?.type === 'boolean') {
                        renderValue = renderValue ? 'Да' : 'Нет'
                    }
                    shortDescription = `Объект: ${renderValue}`
                } else {
                    shortDescription =`Объект не задан`
                }
            } else {
                if (jsonValue?.shortDescription) {
                    shortDescription = jsonValue.shortDescription
                } else {
                    shortDescription = 'JSON-объект'
                }
            }
        }


        const updateJsonObjectData = (item, rowId, lValue) => {
            const allInputValue = lAllInputValue ?? item.inputValue;

            if (allInputValue && allInputValue.length > 0) {
                const updatedData = __.deepCopy(allInputValue);
                if (!((rowId - 1 >= 0) && (rowId - 1 < allInputValue.length))) {
                    updatedData.splice(rowId - 1, 0, lValue);
                    inputHandler(updatedData, item);
                } else {
                    updatedData[rowId - 1] = lValue;
                    inputHandler(updatedData, item);
                }
            } else {
                const updatedData = [];
                updatedData[rowId - 1] = lValue;
                inputHandler(updatedData, item);
            }
        }
        const handleJsonOption = (item, lInputValue, rowId) => {
            const obj = HelpFunctions.isJSON(this.props.lInputValue) && JSON.parse(this.props.lInputValue)

            if ((obj && obj?.type === 'tree') || (this.state.currentJsonOption === 'Tree' && !hasJsonSchemaTable)) {
                this.showJsonTree(item, lInputValue, true, (value) => {
                    if (!rowId) {
                        inputHandler({value}, item);
                    } else {
                        updateJsonObjectData(item, rowId, value);
                    }
                });
            } else if ((!obj && hasJsonSchemaTable) || (obj && obj?.type === 'table') || this.state.currentJsonOption === 'Table') {
                this.modalAttributeTable(item, lInputValue, hasJsonSchemaTable && item.JsonSchema, this.props.isRedactorMode, (value) => {
                    if (!rowId) {
                        inputHandler({value}, item);
                    } else {
                        updateJsonObjectData(item, rowId, value);
                    }
                });
            } else {
                this.showJsonObject(item, lInputValue, true, (value) => {
                    if (!rowId) {
                        inputHandler({value}, item);
                    } else {
                        updateJsonObjectData(item, rowId, value);
                    }
                });
            }
        }
        const IsJsonTypeTable = HelpFunctions.isJSON(lInputValue) && JSON.parse(lInputValue)?.type === 'table'
        const IsJsonTypeTree = HelpFunctions.isJSON(lInputValue) && JSON.parse(lInputValue)?.type === 'tree'
        const hasJsonSchemaTable = HelpFunctions.isJSON(item.JsonSchema) && JSON.parse(item.JsonSchema)?.type === 'table'

        if (!isRedactorMode) {
            return (
                <>
                    {IsJsonTypeTable || IsJsonTypeTree || hasJsonSchemaTable ?
                        <>
                            {(IsJsonTypeTable || hasJsonSchemaTable) &&
                                <>
                            <span className={'d-flex justify-content-center align-items-center cursor-pointer'}
                                  onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      this.modalAttributeTable(item, lInputValue, hasJsonSchemaTable && item.JsonSchema, this.props.isRedactorMode)
                                  }}
                            >
                                {shortDescription &&
                                    <span className={'mr-2'}>
                                        {shortDescription}
                                    </span>
                                }
                                <i className="svg-icon svg-icon-sm icon-more"/>
                            </span>
                                </>
                            }

                            {IsJsonTypeTree &&
                                <span className={'d-flex justify-content-center align-items-center cursor-pointer'}
                                      onClick={(event) => {
                                          event.preventDefault();
                                          event.stopPropagation();
                                          this.showJsonTree(item, lInputValue, this.props.redactorMode, (value) => {
                                              this.props.updateAttributesRedactorData({
                                                  name: item.IdAttributeName,
                                                  value, //TODO КН 07.02.24 тут надо будет думать чтобы сетался весь массив вальюшек когда мультиплай а не отдельно каждый
                                                  isConstant: item.isConstant ?? false, //TODO КН 07.02.24 Стояло просто false, на всякий приписал, если лишнее можно убрать
                                              });
                                          });
                                      }}
                                >
                                     {shortDescription &&
                                         <span className={'mr-2'}>
                                            {shortDescription}
                                        </span>
                                     }
                                    <i className="svg-icon svg-icon-sm icon-more"/>
                                </span>
                            }
                        </>
                        :
                            <span className={'d-flex justify-content-center align-items-center cursor-pointer'}
                                  onClick={() => {
                                      this.showJsonObject(item, lInputValue, this.props.redactorMode, (value) => {
                                          this.props.updateAttributesRedactorData({
                                              name: item.IdAttributeName,
                                              value, //TODO КН 07.02.24 тут надо будет думать чтобы сетался весь массив вальюшек когда мультиплай а не отдельно каждый
                                              isConstant: item.isConstant ?? false, //TODO КН 07.02.24 Стояло просто false, на всякий приписал, если лишнее можно убрать
                                          });
                                      });
                                  }}
                            >
                                {shortDescription &&
                                    <span className={'mr-2'}>
                                        {shortDescription}
                                    </span>
                                }
                                <i className="svg-icon svg-icon-sm icon-more"/>
                            </span>
                    }
                </>


            )
        } else {
            return (
                <div className={"d-flex align-items-center"}>
                    {(!lInputValue && !hasJsonSchemaTable) ? (
                        <>
                            <Select
                                isDisabled={hardDisabledEditing}
                                className="text-primary font-weight-bold mr-2 border-0 bg-light-success w-100"
                                closeMenuOnSelect
                                menuPlacement="top"
                                defaultValue={options[0]}
                                onChange={(selectedValue) => {
                                    if (!rowId) {
                                        if (this.state.currentJsonOption !== selectedValue.value) {
                                            inputHandler("", item);
                                            this.setState({currentJsonOption: selectedValue.value});
                                        }
                                    } else {
                                        if (
                                            this.state.currentJsonOption !==
                                            selectedValue.value
                                        ) {
                                            updateJsonObjectData(item, rowId, "");
                                            this.setState({
                                                currentJsonOption: selectedValue.value,
                                            });
                                        }
                                    }
                                }}
                                options={options}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        text: "var(--hover-primary)",
                                        primary25: "var(--hover-primary)",
                                        primary50: "var(--hover-primary)",
                                        primary: "var(--originaltwo)",
                                        neutral0: "var(--elem-bg)",
                                        neutral20: "var(--originaltwo)",
                                        neutral30: "var(--text-primary)",
                                        neutral40: "var(--text-primary)",
                                        neutral50: "var(--text-primary)",
                                        neutral80: "var(--text-primary)",
                                    },
                                })}
                            />

                            <span
                                className="btn btn-icon btn-sm"
                                style={{cursor: !hardDisabledEditing ? 'pointer' : 'default'}}
                                onClick={() => !hardDisabledEditing && handleJsonOption(item, lInputValue, rowId)}
                                title={"Создать JSON-объект"}
                            >
                                <i className={`svg-icon svg-icon-xs mr-1 ml-1 icon-Plus ${!hardDisabledEditing ? 'text-success' : ''}`}></i>
                            </span>
                        </>
                    ) : (
                        <>
                            <div className="clearable-input w-100">
                                <input
                                    className="form-control form-control-lg h-40px clearable"
                                    type="text"
                                    value={shortDescription}
                                    disabled
                                />
                                <span
                                    className="edit-input"
                                    onClick={() => {
                                        if (!hardDisabledEditing) {
                                            handleJsonOption(item, lInputValue, rowId);
                                        } else {
                                            // TODO КН 26.02.24 Если hardDisabledEditing, значит redactorMode должен быть отключен
                                            if (IsJsonTypeTable || hasJsonSchemaTable) {
                                                this.modalAttributeTable(item, lInputValue,hasJsonSchemaTable && item.JsonSchema, false)
                                            } else if (IsJsonTypeTree) {
                                                this.showJsonTree(item, lInputValue, false, (value) => {
                                                    this.props.updateAttributesRedactorData({
                                                        name: item.IdAttributeName,
                                                        value, //TODO КН 07.02.24 тут надо будет думать чтобы сетался весь массив вальюшек когда мультиплай а не отдельно каждый
                                                        isConstant: item.isConstant ?? false, //TODO КН 07.02.24 Стояло просто false, на всякий приписал, если лишнее можно убрать
                                                    });
                                                });
                                            } else  {
                                                this.showJsonObject(item, lInputValue, false, (value) => {
                                                    this.props.updateAttributesRedactorData({
                                                        name: item.IdAttributeName,
                                                        value, //TODO КН 07.02.24 тут надо будет думать чтобы сетался весь массив вальюшек когда мультиплай а не отдельно каждый
                                                        isConstant: item.isConstant ?? false, //TODO КН 07.02.24 Стояло просто false, на всякий приписал, если лишнее можно убрать
                                                    });
                                                });
                                            }
                                        }
                                    }}
                                >
                                &#9998;
                                </span>
                            </div>
                            {!rowId && (
                                <span
                                    className="btn btn-icon btn-sm"
                                    style={{cursor: !hardDisabledEditing ? 'pointer' : 'default'}}
                                    onClick={() => !hardDisabledEditing && inputHandler("", item)}
                                >
                                <i className={`svg-icon svg-icon-sm icon-Table_edit_12 ${!hardDisabledEditing ? 'icon-color-red' : ''} fsic2`}/>
                            </span>
                            )}
                        </>
                    )}
                </div>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        router: state.router, // маршрутизатор
    }
}

const mapDispatchToProps = {
    addModalData,
}

export default connect(mapStateToProps, mapDispatchToProps)(JsonObjectFragment);
