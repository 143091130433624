import React from "react";
import {connect} from "react-redux";
import {contextMenuTypes} from "../../../tools/StaticTypes";
import {
    setContextMenuData,
    setUserModalShow
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {changeDocumentSearchInputsContextMenu} from "../../../store/documents/actionCreators/document_SearchActionCreator";
import {
    changeDocumentSearchInputs_packagesDocumentRight,
    combineMainTableAttributes_packagesDocumentRight,
    mainTableSearchDoc_packagesDocumentRight
} from "../../../store/packages/actionCreators/package_DocumentRightActionCreator";

class ContextMenuMainTableThPackagesDocumentsRight extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            scrollY : window.scrollY, // TODO: пока оставил позицию окна при контексте но если не будем юзать -  удалить
            scrollX : window.scrollX,
            isHover : false
        };

        this.name = contextMenuTypes.MainTableTR;

        this.search = this.search.bind(this);


        document.onscroll = (event) => { // скрытие контекстного меню при скроле документа
            if(this.props.contextMenu.name === contextMenuTypes.MainTableTR) {
                this.props.setContextMenuData({});
            }
        };
        document.onclick = (event) => {
            if(!this.state.isHover) {
                this.props.setContextMenuData({});
            }
        };
    }

    // отписываемся от событий при удалении элемента
    componentWillUnmount() {
        document.onscroll = null;
        document.onclick = null;
    }





    search(event) {
        let Direction="";
        if (event.target.className.indexOf("up") !== -1) {
            Direction = "icon-Filter_up";
        } else {
            Direction = "icon-Filter_down";
        }

        this.props.setUserModalShow({
            isShow : true,
            Type : "treeDocumentsSearch",
            Next:{
                action: () => {
                    setTimeout(()=>{ // TODO: исправить этот кастыль

                                    this.props.mainTableSearchDoc_packagesDocumentRight({
                                        FieldSorting: this.props.contextMenu.data.th,
                                        Direction: Direction,
                                        Next: {
                                            action: () => {
                                                this.props.changeDocumentSearchInputs_packagesDocumentRight({
                                                    TH:true,
                                                    Direction: Direction,
                                                    Attribute: this.props.contextMenu.data.th,
                                                    Value: "",
                                                    isConstant: this.props.contextMenu.data.th.IsConstant,
                                                    Next: {
                                                        action: () => {
                                                            this.props.combineMainTableAttributes_packagesDocumentRight({})
                                                        },
                                                        params : {}
                                                    }
                                                })
                                            },
                                            params: {}
                                        }
                                    });


                    }, 500);
                },
                params : {}

            }
        })




        this.props.setContextMenuData({});
    }


    render() {

        let contextStyle = {
            position : "fixed",
            top :  this.props.contextMenu.position.y,
            left :  this.props.contextMenu.position.x
        };


        return (
            <nav id="context-menu" className="context-menu" style={contextStyle} onContextMenu={(event) => {event.preventDefault()}}
                 onMouseEnter={(event) => {this.setState({isHover : true})}}
                 onMouseLeave={(event) => {this.setState({isHover : false})}}>
                <ul className="context-menu__items">
                    <li className="context-menu__item" onClick={(event) => {this.search(event)}} style={{cursor : "pointer"}}>
                        <span className="context-menu__link up">
                            <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-3">
                                <i className="svg-icon svg-icon-lg icon-color-gray icon-Filter_up"> </i>
                            </span>
                            Сортировка А-Я
                        </span>
                    </li>
                    <li className="context-menu__item" onClick={(event) => {this.search(event)}} style={{cursor : "pointer"}}>
                        <span className="context-menu__link down">
                            <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-3">
                                <i className="svg-icon svg-icon-lg icon-color-gray icon-Filter_down"> </i>
                            </span>
                            Сортировка Я-А
                        </span>
                    </li>
                </ul>
            </nav>
        );
    }
}

const  mapStateToProps = state => {
    return {
        contextMenu :  state.globalState.app.contextMenu,
    }
}

const  mapDispatchToProps = {
    setContextMenuData,
    changeDocumentSearchInputsContextMenu,
    mainTableSearchDoc_packagesDocumentRight,
    combineMainTableAttributes_packagesDocumentRight,
    setUserModalShow,
    changeDocumentSearchInputs_packagesDocumentRight
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenuMainTableThPackagesDocumentsRight);
