
import {DOCUMENT_PREVIEW_ACTIONS, DOCUMENT_TEMPORARY_DATA} from "../documentActionsList";
import {store} from "../../../index";
import {__} from "../../../tools/HelpFunctions";
import {addToEndUrl, apiUrl} from "../../../tools/API/apiUrl";
import {apiRequest, apiRequestNew} from "../../../tools/API/apiRequest";
import {APP_ACTIONS, AUTH_ACTIONS} from "../../globalState/globalStateActionsList";
import {EntityFilters, Filter, FiltersType, Sorting} from "../../../tools/Filters";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import {TreeCombine} from "../../../tools/TreeCombine";
import {
    setLoaderModalData,
    setModalData,
    setStateLeftAsideMenu
} from "../../globalState/actionCreators/globalState_AppActionCreator";
import {
    AdminLevelEnum,
    ModalTypes,
    Operators as FilerOperatorsEnum,
    RootComponentsStateViewModeTypes, SortDirection as SortDirectionEnum
} from "../../../tools/StaticTypes";
import {changeDocumentViewMode} from "./document_RootStateActionCreator";
import {history} from "../../../index";
import {changeViewMode} from "./document_SearchActionCreator";
import {API, baseUrl} from "../../../tools/API_NEW/API";
import {DataTypesEnum, DocFilePart} from "../../../tools/API_NEW/ServerTypes";
import {PACKAGES_TREE_ACTIONS} from "../../packages/packageActionsList";
import {SEARCH_PREVIEW_ACTIONS} from "../../search/searchActionsList";


export const setSignatures = (data, lSignatures) => {
    return data.map((item) => ({
        ...item,
        signatures: lSignatures
    }));
}

// собираем данные для отображения в предпросмотре
export const createPreviewBySelectDocument = ({Document = null, VersionIndex = null, Node = null, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            if(Document == null) {
                Document = __.deepCopy(store.getState().document.mainTable.activeRow);
            }
            if(Node == null) {
                Node = __.deepCopy(store.getState().document.tree.activeNode);
            }

            let Version = Document?.Version;
            // если версии уже были загружены
            // при первой загрузки использовать VersionIndex нельзя
            if(VersionIndex != null) {
                Version = store.getState().document.additionalSections.state.versionSection.versions[VersionIndex];
            }
            let files = [];

            // // основной файл предпросмотра изображение
            // files.push({
            //     name : "Предпросмотр",
            //     link : addToEndUrl({
            //         url : apiUrl.DOCUMENTS.DOWNLOAD_PREVIEW.url,
            //         collection : [
            //             Version.IdDoc,
            //             Version.Id
            //         ]
            //     }),
            //     type : "image",
            //     description : "",
            //     size : null,
            //     idVersion: Version.Id
            // });
            if(Version){
                // получаем файлы
                let request = new apiRequest(apiUrl.DOCUMENTS.GET_BY_VERSION, {
                    idDoc: Version?.IdDoc,
                    idVersion :  Version.Id
                });

                // //TODO электронная подпись на перспективу отказаться от цикла и делать один запрос по документу и версии
                // let lSignatures = await API.signatures().load({
                //     filter : new Filter(FiltersType.ENTITY).add(
                //         new EntityFilters().setEntityName("DocFileSignature")
                //         // add({
                //         //     Name : "IdDocFile",
                //         //     Value : files[i].Id
                //         // })
                //             .add({Name: "IdDoc", Value: Version.IdDoc})
                //             .add({Name: "IdDocVersion", Value: Version.Id})
                //             .get()
                //     ).get(),
                //     sorting : null,
                // });

                request.execute(async function (data) {
                        let loadFiles = data.Records;

                        // сохраняем во временные файлы
                        if (loadFiles.length === 0) {
                            dispatch({
                                type: APP_ACTIONS.SET_MODAL_DATA, payload: {
                                    name: ModalTypes.app.info,
                                    data: {
                                        content: `Ошибка открытия: файл поврежден`, type: "fail"
                                    }
                                }
                            });
                            dispatch({
                                type:APP_ACTIONS.SET_LOADER_MODAL_DATA, payload: {
                                    keyDeleted: "search_PreviewActionCreator87"
                                }
                            })
                            return false
                        }
                        dispatch({
                            type: DOCUMENT_TEMPORARY_DATA.SAVE, payload: {
                                name: "DocumentFiles",
                                data: loadFiles
                            }
                        });


                        let loadFiles1 = __.deepCopy(loadFiles);

                        //TODO Задача № 22235 для быстродействия
                        // if (loadFiles1)
                        //     for (let i = 0; i < loadFiles1.length; i++) {
                        //         //TODO электронная подпись Запрос
                        //         let lSignatures = await API.signatures().load({
                        //             filter: new Filter(FiltersType.ENTITY).add(
                        //                 new EntityFilters().setEntityName("DocFileSignature").add({
                        //                     Name: "IdDocFile",
                        //                     Value: loadFiles1[i].Id
                        //                 }).get()
                        //             ).get(),
                        //             sorting: null,
                        //         });
                        //
                        //         if (lSignatures)
                        //             loadFiles1[i].signatures = lSignatures ? lSignatures.Records : null;
                        //     }

                        loadFiles = loadFiles1;
                        // id атрибутов
                        // 1 - содержание документа
                        // 2 - файл предпросмотра
                        // 3 - файл для печати
                        // 4 - прикрепленные файлы

                        // основной файл предпросмотра pdf
                        let previewPdf = loadFiles.find(file => file.AttributeName.Id === 2);
                        if (previewPdf) {
                            files.push({
                                id: previewPdf.Id,
                                name: previewPdf.Name,
                                originalLink: addToEndUrl({
                                    url: apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                                    collection: [
                                        previewPdf.Id,
                                        Version.Id,
                                        "false" // для просмотра (true - для загрузки)
                                    ]
                                }),
                                previewLink: addToEndUrl({
                                    url: apiUrl.DOCUMENTS.DOWNLOAD_PREVIEW.url,
                                    collection: [
                                        Version?.IdDoc,
                                        Version.Id
                                    ]
                                }),
                                //type: "pdf",
                                description: "Оригинал",
                                size: previewPdf.Size,
                                signatures: previewPdf.signatures,
                                idVersion: Version.Id
                            });
                        }



                        // прикрепленные файлы
                        let otherFiles = loadFiles.filter(file => file.AttributeName.Id === 4);
                        if (otherFiles) {
                            for (let i = 0; i < otherFiles.length; i++) {
                                if (otherFiles[i].Name.toLowerCase().indexOf(".pdf") > 0) {
                                    files.push({
                                        id: otherFiles[i].Id,
                                        name: otherFiles[i].Name,
                                        previewLink: `${baseUrl}${apiUrl.DOCUMENTS.DOWNLOAD_FILE.url}?id=${otherFiles[i].Id}&part=1&isAttachment=${false}`,
                                        originalLink: `${baseUrl}${apiUrl.DOCUMENTS.DOWNLOAD_FILE.url}?id=${otherFiles[i].Id}&part=0&isAttachment=${false}`,
                                        //type: "pdf",
                                        type: "image",
                                        description: otherFiles[i].Description,
                                        size: otherFiles[i].Size,
                                        isAttach: true,
                                        signatures: previewPdf.signatures,
                                    });
                                }
                            }
                        }

                        dispatch({type: DOCUMENT_PREVIEW_ACTIONS.CREATE_BY_NEW_OPEN_DOC, payload: files});
                        if (Next) {
                            Next.action(Next.params);
                        } // следующее действие если есть цепочка
                    },
                    function(data) {
                        if(data.status === 401) {
                            dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                        }
                        console.log("Ошибка получения файлов для предпросмотра (post): ", data);
                    });
            }else{
                if (Next) {
                    Next.action(Next.params);
                } // следующее действие если есть цепочка
            }

        } catch (exception) {
            console.log("Ошибка сбора данных для предпросмотра (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const setFilesContent = ({document = null, isCard = false, isRedactorMode = false}) => {
    return async dispatch => {
        try {
            if(document) {
                let content;
                let cardId = "";
                if(isCard || isRedactorMode) cardId = store.getState().document.cards.activeCardId;

                if(document.isAttach) {
                    content = await API.documents().getCurrentFileText({id: document.id, part: DocFilePart.Text, isAttachment: false});
                } else {
                    content = await API.documents().getContentForCurrentFile({idDoc: document.Id, idVersion: document.Version.Id})
                }

                dispatch({type: DOCUMENT_PREVIEW_ACTIONS.SET_FILE_CONTENT, payload: {Content : content, IsCard: isCard, activeCardId: cardId, IsRedactorMode: isRedactorMode}});

            }
        } catch (exception) {
            console.log("Ошибка сбора данных для предпросмотра (catch): ", exception);
            if (Error) {
                Error.action(Error.params);
            } // если ошибка колбек
        }
    }
}

// сброс данных предпросмотра документа
export const resetDocumentPreview = ({Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let document = __.deepCopy(store.getState().document.mainTable.activeRow);
            let typeFile = store.getState().document.preview.typeFile;
            let fixingPreview = store.getState().document.preview.fixingPreview;
            let activeFileIndex=store.getState().document.preview.activeFileIndex;
            let activeIdDoc=store.getState().document.mainTable.activeRow?.Version?.IdDoc;
            let idDocPreview=store.getState().document.preview.idDocPreview ?? null;

            //если перещелкивание по файлам внутри одного докуиента
            if(activeIdDoc===idDocPreview){
                await dispatch({type : DOCUMENT_PREVIEW_ACTIONS.RESET, payload :  {idDocPreview:activeIdDoc}});
                await dispatch({type:DOCUMENT_PREVIEW_ACTIONS.SET_ACTIVE_FILE_INDEX, payload:{activeFileIndex: activeFileIndex}})
            }else{//переключились на другой документ
                await dispatch({type : DOCUMENT_PREVIEW_ACTIONS.RESET, payload : {idDocPreview:activeIdDoc}});
                await dispatch({type:DOCUMENT_PREVIEW_ACTIONS.SET_ACTIVE_FILE_INDEX, payload:{activeFileIndex: 0,}})
            }

            if(typeFile){
                await dispatch({type : DOCUMENT_PREVIEW_ACTIONS.TYPE_File, payload : typeFile});
            }
            if(fixingPreview){
                dispatch({type : DOCUMENT_PREVIEW_ACTIONS.SET_SIZE_PREVIEW, payload : fixingPreview});
            }
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка сброса данных предпросмотра (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// выбор активного (показанного) файла для предпросмотра
// Index - номер файла в коллекции файлов предпросмотра
export const selectActiveFileForPreview = ({Type="image",Index = 0,File=null, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : DOCUMENT_PREVIEW_ACTIONS.SELECT_ACTIVE_FILE, payload : {Type, Index}});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка сброса данных предпросмотра (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const selectViewActiveFileForPreview = ({View = null, isCard = false, isRedactorMode = false}) => {
    return async dispatch => {
        try {
            let cardId = "";
            if(isCard) cardId = store.getState().document.cards.activeCardId;

            dispatch({type: DOCUMENT_PREVIEW_ACTIONS.SELECT_VIEW_FOR_ACTIVE_FILE, payload : {
                    view: View,
                    IsCard: isCard,
                    activeCardId: cardId,
                    isRedactorMode: isRedactorMode
                }});
        } catch (exception) {
            console.log("Ошибка выбора данных предпросмотра (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const setSizePreview = ({fixingPreview='hedn', Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : DOCUMENT_PREVIEW_ACTIONS.SET_SIZE_PREVIEW, payload : fixingPreview});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка сброса данных предпросмотра (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// открытие или закрытие дополнительных разделов
// Name - название раздела
// Status - true/false открыт или закрыт раздел
export const toggleAdditionalSectionsOpen = ({Name, Status = null,Next = null, Error = null}) => {
    return async dispatch => {
        try {
            if(Status == null) {
                Status = !store.getState().document.additionalSections.state[Name].isOpen;
            }
            dispatch({type : DOCUMENT_PREVIEW_ACTIONS.TOGGLE_ADDITIONAL_SECTIONS_OPEN, payload : {
                    name : Name,
                    isOpen : Status,
                }});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка переключения isOpen дополнительных разделов (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// сбор данных для отображения в дополнительных разделах
// при обычном просмотре (при просмотре карточки документов другая функция: _Cards)
// VersionIndex -> активная версия
export const collectDataForAdditionalSections_Normal = ({TableRole = "main", VersionIndex = null, Next = null, Error = null}) => {
    return async (dispatch) => {
        try {
            let documentInfo = store.getState().document.mainTable.activeRow;
            if (!documentInfo) {

                let tree;
                if(TableRole === "main")  tree = store.getState().packages.tree;
                else if(TableRole === "additional")  tree = store.getState().packages.treeAdditional;
                else if(TableRole === "download")  tree = store.getState().packages.treeDownload;

                documentInfo = tree.activeDocument?.Node
                if (!documentInfo) return
            }

            let Version = documentInfo.Version;
            if(VersionIndex != null) {
                Version = store.getState().document.additionalSections.state.versionSection.versions[VersionIndex];
            }

            let result = {
                // attributesSection : documentInfo.AllAttributes, // TODO КН 25.03.24 лишнее создание поля, которое если и будет использовано, то будет переприсвоено
                contentSection : "",
                filesSection : [],
                versionSection : [],
                documentPackagesSection : [],
                docTypeSection : documentInfo.Type,
                activeVersionIndex : VersionIndex ? VersionIndex : 0,
                departmentsSection: []
            };

            let files = store.getState().document.temporaryData.DocumentFiles;

            files = files?.filter(file => file.AttributeName.Id === 4 || file.AttributeName.Id === 3);
            if(files) {
                for(let i = 0; i < files.length; i++) {
                    // TODO Задача № 22235 для быстродействия
                    // //TODO электронная подпись Запрос
                    // let lSignatures = await API.signatures().load({
                    //     filter : new Filter(FiltersType.ENTITY).add(
                    //         new EntityFilters().setEntityName("DocFileSignature").add({
                    //             Name : "IdDocFile",
                    //             Value : files[i].Id
                    //         }).get()
                    //     ).get(),
                    //     sorting : null,
                    // });

                    result.filesSection.push({
                        id : files[i].Id,
                        name : files[i].Name,
                        link : addToEndUrl({
                            url : apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                            collection : [
                                files[i].Id,
                                Version.Id,
                                "true" // false - для просмотра (true - для загрузки)
                            ]
                        }),
                        type : files[i].Name.toLowerCase().indexOf(".pdf") > 0 ? "pdf" : "image",
                        description : files[i].Description,
                        size : files[i].Size,
                        // signatures: lSignatures ? lSignatures.Records : null,
                    });
                }
            }


            let request_content = new apiRequest(apiUrl.DOCUMENTS.GET_CONTENT_FOR_CURRENT_DOC, {
                idDoc :  Version?.IdDoc,
                idVersion : Version.Id
            });

            let request_versions = new apiRequest(apiUrl.DOCUMENTS.GET_ALL_VERSIONS, {
                filter : new Filter(FiltersType.ENTITY)
                    .add(
                        new EntityFilters().setEntityName("DocVersion")
                            .add({Name: "IdDoc", Value: documentInfo.Version?.IdDoc})
                            .get()
                    ).get(),
                sorting : null
            });

            // newFeature departmentsRef
            let request_departments_refs = new apiRequest(apiUrl.DOCUMENTS.GET_DEPARTMENTS_REFS, {
                filter : new Filter(FiltersType.ENTITY)
                    .add(
                        new EntityFilters().setEntityName("DocDepartmentRef")
                            .add({Name: "IdDoc", Value: documentInfo.Version.IdDoc})
                            .get()
                    ).get(),
                sorting : null
            });

            let request_packages = new apiRequest(apiUrl.PACKAGE.LOAD, {
                filter : new Filter(FiltersType.ENTITY)
                    .add(
                        new EntityFilters().setEntityName("PackageDocRef")
                            .add({Name: "IdDoc", Value: Version?.IdDoc})
                            .addAttribute({Name : "ReferenceProperty", Value : "IdPackage"})
                            .addAttribute({Name : "RootEntityProperty", Value : "Id"})
                            .get()
                    ).add(new EntityFilters().setEntityName("DocPackage").get()).get(),
                sorting : null
            });

            request_content.execute(function (data) {
                    result.contentSection = data === ""
                        ? "Содержание отсутствует"
                        : data;

                    request_versions.execute(function (data) {
                            result.versionSection = data.Records.reverse();

                            result.versionSection.map(lVersion => {
                                let request_headersByVersion = new apiRequest(apiUrl.DOCUMENTS.GET_HEADERS_FOR_CURRENT_DOC, {
                                    filter: new Filter(FiltersType.ENTITY)
                                        .add(
                                            new EntityFilters().setEntityName("DocHeader")
                                                .add({Name: "IdDoc", Value: lVersion.IdDoc})
                                                .add({Name: "Version.Id", Value: lVersion.Id})
                                                .get()
                                        ).get(),
                                    sorting: null
                                });
                                request_headersByVersion.execute((data_headersByVersion) => {

                                        lVersion.Type = data_headersByVersion.Records[0].Type
                                    }
                                )
                            })

                            //newFeature departmentsRef
                            request_departments_refs.execute(function (data) {
                                    result.departmentsSection = data.Records.reverse();

                                    request_packages.execute(function (data) {
                                            result.documentPackagesSection = data.Records;

                                            // if(VersionIndex != null) { // TODO: выяснить что нахрен за if и для чего он нужен

                                            //TODO этот if нужен для перезапроса атрибутов и срабатывает когда мы кликаем на версию, подгружает атрибуты версии на которую мы кликнули, при этом теряется сортировка в соответствии с админкой
                                            // вышел на это обяснение при решении Задача № 21814 Предпросмотр документа в пакете - инструменты просмотра
                                            const IsOldServer = store.getState().globalState.settings.Content?.IsOldServer === "1";
                                            let request_attributes;
                                            if(IsOldServer){
                                                 request_attributes = new apiRequest(apiUrl.DOCUMENTS.GET_ATTRIBUTES_FOR_VIEW_FOR_CURRENT_DOC_OLD, {
                                                    filter : new Filter(FiltersType.ENTITY)
                                                        .add(
                                                            new EntityFilters().setEntityName("DocAttribute")
                                                                .add({Name: "IdDocVersion", Value: Version.Id})
                                                                .get()
                                                        ).get(),
                                                    sorting : null
                                                });
                                            }else{
                                                request_attributes = new apiRequest(apiUrl.DOCUMENTS.GET_ATTRIBUTES_FOR_VIEW_FOR_CURRENT_DOC, {
                                                    filter : new Filter(FiltersType.ENTITY)
                                                        .add(
                                                            new EntityFilters().setEntityName("DocAttributeForView")
                                                                .add({Name: "IdDocVersion", Value: Version.Id})
                                                                .get()
                                                        ).get(),
                                                    sorting : null
                                                });
                                            }


                                            let request_headersByVersion = new apiRequest(apiUrl.DOCUMENTS.GET_HEADERS_FOR_CURRENT_DOC, {
                                                filter : new Filter(FiltersType.ENTITY)
                                                    .add(
                                                        new EntityFilters().setEntityName("DocHeader")
                                                            .add({Name: "IdDoc", Value: Version?.IdDoc})
                                                            .add({Name: "Version.Id", Value: Version.Id})
                                                            .get()
                                                    ).get(),
                                                sorting : null
                                            });
                                            request_headersByVersion.execute(function (data_headersByVersion) {
                                                    request_attributes.execute(function (data) {
                                                            // TODO КН 10.04.24 Думаю можно точно брать какое то из полей, а не пользоваться ИЛИ
                                                            const docTypeId = data_headersByVersion?.Records[0]?.Type?.Id ?? documentInfo.Type.Id
                                                            const isLastVersion = documentInfo.Version.Id === Version.Id
                                                            result.attributesSection = docTypeId ? __.transformAttributesData({
                                                                attributesData: data.Records,
                                                                docTypeId: docTypeId,
                                                                needAllAttributesOnType: isLastVersion,
                                                                newValueFieldName: 'Value',
                                                            }) : [];
                                                            dispatch({type : DOCUMENT_PREVIEW_ACTIONS.COLLECT_DATA_FOR_ADDITIONAL_SECTIONS_NORMAL, payload : result});
                                                            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
                                                        },
                                                        function (data) {
                                                            if(data.status === 401) {
                                                                dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                                                            }
                                                            dispatch(setLoaderModalData({keyDeleted: "document_PreviewActionCreator526"}))
                                                            dispatch(setModalData({
                                                                name: ModalTypes.app.info,
                                                                data: {type: "fail", content : `Ошибка получения атрибутов документа по версии: ${data.responseJSON.Message}`}
                                                            }))
                                                            console.log("Ошибка получения атрибутов документа по версии (post): ", data);
                                                        });
                                                },
                                                function (data) {
                                                    if(data.status === 401) {
                                                        dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                                                    }
                                                    dispatch(setLoaderModalData({keyDeleted: "document_PreviewActionCreator595"}))
                                                    dispatch(setModalData({
                                                        name: ModalTypes.app.info,
                                                        data: {type: "fail", content : `Ошибка получения заголовков документа по версии: ${data.responseJSON.Message}`}
                                                    }))
                                                    console.log("Ошибка получения заголовков документа по версии (post): ", data);
                                                });
                                        },
                                        function (data) {
                                            if(data.status === 401) {
                                                dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                                            }
                                            dispatch(setLoaderModalData({keyDeleted: "document_PreviewActionCreator537"}))
                                            dispatch(setModalData({
                                                name: ModalTypes.app.info,
                                                data: {type: "fail", content : `Ошибка получения пакетов документа: ${data.responseJSON.Message}`}
                                            }))
                                            console.log("Ошибка получения пакетов документа (post): ", data);
                                        });
                                },
                                function (data) {
                                    if(data.status === 401) {
                                        dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                                    }
                                    dispatch(setLoaderModalData({keyDeleted: "document_PreviewActionCreator544"}))
                                    dispatch(setModalData({
                                        name: ModalTypes.app.info,
                                        data: {type: "fail", content : `Ошибка получения пакетов документа: ${data.responseJSON.Message}`}
                                    }))
                                    console.log("Ошибка получения пакетов документа (post): ", data);
                                });
                        },
                        function (data) {
                            if(data.status === 401) {
                                dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                            }
                            dispatch(setLoaderModalData({keyDeleted: "document_PreviewActionCreator551"}))
                            dispatch(setModalData({
                                name: ModalTypes.app.info,
                                data: {type: "fail", content : `Ошибка получения версий документа: ${data.responseJSON.Message}`}
                            }))
                            console.log("Ошибка получения версий документа (post): ", data);
                        });
                },
                function (data) {
                    if(data.status === 401) {
                        dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                    }
                    dispatch(setLoaderModalData({keyDeleted: "document_PreviewActionCreator558"}))
                    dispatch(setModalData({
                        name: ModalTypes.app.info,
                        data: {type: "fail", content : `Ошибка получения содержания документа: ${data.responseJSON.Message}`}
                    }))
                    console.log("Ошибка получения содержания документа (post): ", data);
                });
        } catch (exception) {
            dispatch(setLoaderModalData({keyDeleted: "document_PreviewActionCreator561"}))
            dispatch(setModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content : `Ошибка сбора данных для дополнительного раздела: ${exception}`}
            }))
            console.log("Ошибка сбора данных для дополнительного раздела (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// собираем данные для отображения в предпросмотре в режиме карточки документа
// TODO: потом переписать эту функцию в нормальный вид
export const createDocumentCards = ({
                                        IdDoc,
                                        /* TODO Кн 09.04.24 Если вдруг когда нибудь понадобится передавать сюда не последнюю версию,
                                            то обратите внимание на присвоение значения card.additionalSections.attributesSection.attributes
                                            внутри этой функции, возможно понадобится заменить значение флажка isLastVersion */
                                        IdVersion,
                                        isRightClick = false,
                                        packInfo = null,
                                        IsRedactorMode = false,
                                        isUpdate = false,
                                        Next = null,
                                        Error = null
                                    }) => {
    return async dispatch => {
        try {
            let foundCardIndex = store.getState().document.cards.collection.findIndex(card => card.IdDoc === IdDoc);

            if (!IsRedactorMode) {
                if (foundCardIndex !== -1) {
                    dispatch(selectActiveDocumentCard({
                        CardId: store.getState().document.cards.collection[foundCardIndex].Id,
                        Next: {
                            action: () => {
                                // dispatch(createRedactorData({
                                //     CardId : store.getState().document.cards.collection[foundCardIndex].Id,
                                // }));
                                dispatch(changeViewMode({
                                    isSearch: false
                                }));
                            },
                            params: {}
                        }
                    }));
                    if (isRightClick) {
                        dispatch(changeDocumentViewMode({
                            ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards
                        }));
                        dispatch(setStateLeftAsideMenu({rootName: "DocumentApp", isClose: true}));
                        let link = `${store.getState().router.location.pathname}${store.getState().router.location.search.split("&d")[0]}&d=${IdDoc}&c=${IdDoc}`;
                        history.push(link);
                    }
                    if (Next) Next.action(Next.params);
                    return;
                }
            }


            // Node.parent.info.Id -> это IdDocGroup
            // Node.info.Id -> это PropertyName: "Type.Id"
            let IdFilial
            let IdDocGroup
            let DocGroupName
            let TypeId
            let TypeName
            if (!packInfo) {
                IdFilial = store.getState().globalState.filial.Active.Id;
                IdDocGroup = TreeCombine.searchByTreeId({
                    treeId: store.getState().document.tree.activeNode.parent
                }).info.Id;
                // имя папки
                DocGroupName = TreeCombine.searchByTreeId({
                    treeId: store.getState().document.tree.activeNode.parent
                }).info.Name;
                TypeId = store.getState().document.tree.activeNode.info.Id;
                // имя документа в папке
                TypeName = store.getState().document.tree.activeNode.info.Name;
            } else {
                IdFilial = store.getState().globalState.filial.Active.Id;
                IdDocGroup = packInfo.IdDocGroup
                DocGroupName = packInfo.DocGroupName
                TypeId = packInfo.Node.Type.Id;
                // имя документа в папке
                TypeName = packInfo.Node.Type.Name;
            }
            let card = {
                Id: createUniqueIdString(6),
                IdDoc,
                IdFilial,
                IdDocGroup,
                TypeId,
                DocGroupName,
                TypeName,
                IdLastVersion: IdVersion,
                IdActiveVersion: IdVersion,
                name: "",
                ShortDescription: "",
                number: "",
                preview: {
                    isReady: false,
                    files: [],
                    activeFileIndex: 0,
                    activeView: "preview"
                },
                additionalSections: {
                    isReady: false,
                    attributesSection: {
                        isOpen: true,
                        attributes: []
                    },
                    documentPackagesSection: {
                        isOpen: false,
                        packages: []
                    },
                    versionSection: {
                        isOpen: true,
                        versions: [],
                        activeVersionIndex: 0,
                    },
                    contentSection: {
                        isOpen: false,
                        content: "Содержание отсутствует"
                    },
                    filesSection: {
                        isOpen: isUpdate,
                        files: []
                    },
                    departmentsSection: {
                        isOpen: false,
                        content: []
                    },
                    docTypeSection: {
                        isOpen: false,
                        docType: []
                    }
                }
            };

            // __START__ файлы предпросмотра
            let files = [];
            // основной файл предпросмотра изображение
            // files.push({
            //     name : "Предпросмотр",
            //     link : addToEndUrl({
            //         url : apiUrl.DOCUMENTS.DOWNLOAD_PREVIEW.url,
            //         collection : [
            //             IdDoc,
            //             IdVersion
            //         ]
            //     }),
            //     type : "image",
            //     description : "",
            //     size : null
            // });

            // получаем файлы
            let request_files = await new apiRequest(apiUrl.DOCUMENTS.GET_BY_VERSION, {
                idDoc: IdDoc,
                idVersion: IdVersion
            });

            request_files.execute(async function (data) {
                    let loadFiles = data.Records;

                    // id атрибутов
                    // 1 - содержание документа
                    // 2 - файл предпросмотра
                    // 3 - файл для печати
                    // 4 - прикрепленные файлы


                    //TODO 22.03.2023 оптимизация
                    // if (loadFiles)
                    //     for (let i = 0; i < loadFiles.length; i++) {
                    //         //TODO электронная подпись Запрос
                    //         let lSignatures = await API.signatures().load({
                    //             filter: new Filter(FiltersType.ENTITY).add(
                    //                 new EntityFilters().setEntityName("DocFileSignature").add({
                    //                     Name: "IdDocFile",
                    //                     Value: loadFiles[i].Id
                    //                 }).get()
                    //             ).get(),
                    //             sorting: null,
                    //         });
                    //
                    //         if (lSignatures)
                    //             loadFiles[i].signatures = lSignatures ? lSignatures.Records : null;
                    //     }

                    // основной файл предпросмотра pdf
                    let previewPdf = loadFiles.find(file => file.AttributeName.Id === 2);

                    // if (previewPdf) {
                    //     files.push({
                    //         id: previewPdf.Id,
                    //         name: previewPdf.Name,
                    //         link: addToEndUrl({
                    //             url: apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                    //             collection: [
                    //                 previewPdf.Id,
                    //                 IdVersion,
                    //                 "false" // для просмотра (true - для загрузки)
                    //             ]
                    //         }),
                    //         type: "pdf",
                    //         description: "Оригинал",
                    //         size: previewPdf.Size,
                    //         AttributeName: previewPdf.AttributeName,
                    //         signatures: previewPdf.signatures
                    //     });
                    // }

                    if (previewPdf) {
                        files.push({
                            id: previewPdf.Id,
                            name: previewPdf.Name,
                            originalLink: addToEndUrl({
                                url: apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                                collection: [
                                    previewPdf.Id,
                                    IdVersion,
                                    "false" // для просмотра (true - для загрузки)
                                ]
                            }),
                            previewLink: addToEndUrl({
                                url: apiUrl.DOCUMENTS.DOWNLOAD_PREVIEW.url,
                                collection: [
                                    IdDoc,
                                    IdVersion
                                ]
                            }),
                            //type: "pdf",
                            description: "Оригинал",
                            size: previewPdf.Size,
                            signatures: previewPdf.signatures,
                            idVersion: IdVersion
                        });
                    }


                    // прикрепленные файлы
                    let otherFiles = loadFiles.filter(file => file.AttributeName.Id === 4);
                    if (otherFiles) {
                        for (let i = 0; i < otherFiles.length; i++) {
                            if (otherFiles[i].Name.toLowerCase().indexOf(".pdf") > 0) {
                                files.push({
                                    id: otherFiles[i].Id,
                                    name: otherFiles[i].Name,
                                    previewLink: `${baseUrl}${apiUrl.DOCUMENTS.DOWNLOAD_FILE.url}?id=${otherFiles[i].Id}&part=1&isAttachment=${false}`,
                                    originalLink: `${baseUrl}${apiUrl.DOCUMENTS.DOWNLOAD_FILE.url}?id=${otherFiles[i].Id}&part=0&isAttachment=${false}`,
                                    // link: addToEndUrl({
                                    //     url: apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                                    //     collection: [
                                    //         otherFiles[i].Id,
                                    //         IdVersion,
                                    //         "false" // false - для просмотра (true - для загрузки)
                                    //     ]
                                    // }),
                                    // type: "pdf",
                                    description: otherFiles[i].Description,
                                    size: otherFiles[i].Size,
                                    AttributeName: otherFiles[i].AttributeName,
                                    signatures: otherFiles[i].signatures,
                                    isAttach: true
                                });
                            }
                        }
                    }


                    card.preview.files = files;
                    card.preview.isReady = true;
                    // __END__ файлы предпросмотра

                    // __START__ файлы дополнительной секции
                    files = loadFiles.filter(file => file.AttributeName.Id === 4 || file.AttributeName.Id === 3);
                    if (files) {
                        for (let i = 0; i < files.length; i++) {
                            card.additionalSections.filesSection.files.push({
                                id: files[i].Id,
                                name: files[i].Name,
                                link: addToEndUrl({
                                    url: apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                                    collection: [
                                        files[i].Id,
                                        IdVersion,
                                        "true" // false - для просмотра (true - для загрузки)
                                    ]
                                }),
                                type: files[i].Name.toLowerCase().indexOf(".pdf") > 0 ? "pdf" : "image",
                                description: files[i].Description,
                                size: files[i].Size,
                                AttributeName: files[i].AttributeName,
                                signatures: files[i].signatures
                            });
                        }
                    }
                    // __END__ файлы дополнительной секции

                    // __START__ доп секция content
                    let request_content = await new apiRequest(apiUrl.DOCUMENTS.GET_CONTENT_FOR_CURRENT_DOC, {
                        idDoc: IdDoc,
                        idVersion: IdVersion
                    });

                    request_content.execute(async function (data) {
                            card.additionalSections.contentSection.content = data === ""
                                ? "Содержание отсутствует"
                                : data;
                            // __END__ доп секция content

                            // __START__ доп секция versions
                            let request_versions = await new apiRequest(apiUrl.DOCUMENTS.GET_ALL_VERSIONS, {
                                filter: new Filter(FiltersType.ENTITY)
                                    .add(
                                        new EntityFilters().setEntityName("DocVersion")
                                            .add({Name: "IdDoc", Value: IdDoc})
                                            .get()
                                    ).get(),
                                sorting: null
                            });

                            request_versions.execute(function (data) {
                                    card.additionalSections.versionSection.versions = data.Records.reverse();
                                    card.additionalSections.versionSection.versions.map(lVersion => {
                                        let request_headersByVersion = new apiRequest(apiUrl.DOCUMENTS.GET_HEADERS_FOR_CURRENT_DOC, {
                                            filter: new Filter(FiltersType.ENTITY)
                                                .add(
                                                    new EntityFilters().setEntityName("DocHeader")
                                                        .add({Name: "IdDoc", Value: lVersion.IdDoc})
                                                        .add({Name: "Version.Id", Value: lVersion.Id})
                                                        .get()
                                                ).get(),
                                            sorting: null
                                        });
                                        request_headersByVersion.execute((data_headersByVersion) => {

                                                lVersion.Type = data_headersByVersion.Records[0].Type
                                            }
                                        )
                                    })
                                    // __END__ доп секция versions

                                    // __START__ доп секция packages
                                    let request_packages = new apiRequest(apiUrl.PACKAGE.LOAD, {
                                        filter: new Filter(FiltersType.ENTITY)
                                            .add(
                                                new EntityFilters().setEntityName("PackageDocRef")
                                                    .add({Name: "IdDoc", Value: IdDoc})
                                                    .addAttribute({Name: "ReferenceProperty", Value: "IdPackage"})
                                                    .addAttribute({Name: "RootEntityProperty", Value: "Id"})
                                                    .get()
                                            ).add(new EntityFilters().setEntityName("DocPackage").get()).get(),
                                        sorting: null
                                    });

                                    request_packages.execute(async function (data) {
                                            card.additionalSections.documentPackagesSection.packages = data.Records;
                                            // __END__ доп секция packages

                                            //__START__ Доп секция departments
                                            let request_departments_refs = new apiRequest(apiUrl.DOCUMENTS.GET_DEPARTMENTS_REFS, {
                                                filter: new Filter(FiltersType.ENTITY)
                                                    .add(
                                                        new EntityFilters().setEntityName("DocDepartmentRef")
                                                            .add({Name: "IdDoc", Value: IdDoc})
                                                            .get()
                                                    ).get(),
                                                sorting: null
                                            });

                                            request_departments_refs.execute(async function (data) {
                                                    card.additionalSections.departmentsSection.content = data.Records;


                                                    // __START__ под секция атрибуты
                                                    let request_attributes = await new apiRequest(apiUrl.DOCUMENTS.DOC_COLLECTION, {
                                                        filter: new Filter(FiltersType.ENTITY)
                                                            .addAttribute({Name: "IdDocGroup", Value: IdDocGroup})
                                                            .addAttribute({
                                                                Name: "PagingFilter",
                                                                Value: {PageSize: 1, PageOffset: 0}
                                                            })
                                                            .add(
                                                                new EntityFilters().setEntityName("DocExtended")
                                                                    // todo aa ????
                                                                    // .add({Name: "Type.Id", Value: TypeId})
                                                                    .add({Name: "IdFilial", Value: IdFilial})
                                                                    .add({Name: "Id", Value: IdDoc})
                                                                    // todo TP № 22807
                                                                    // .add({Name: "Version.Id", Value: IdVersion /*VersionId*/})
                                                                    .get()
                                                            ).get(),
                                                        sorting: new Sorting()
                                                            .addAttribute({Name: "IdAttributeName", Value: "-1"})
                                                            .add({PropertyName: "RegDate", Direction: 2})
                                                            .add({PropertyName: "RegNumber", Direction: 1})
                                                            .add({PropertyName: "Id", Direction: 1})
                                                            .get()
                                                    });

                                                    request_attributes.execute(async function (data) {
                                                            let loadDoc = data.Records[0];
                                                            card.additionalSections.attributesSection.attributes.push({
                                                                Name: "PropertyName",
                                                                Value: loadDoc.Name,
                                                                IdAttributeName: null,
                                                                AttributeName: "Name",
                                                                ColumnName: "Наименование",
                                                                IsConstant: true
                                                            });

                                                            card.additionalSections.attributesSection.attributes.push({
                                                                Name: "PropertyName",
                                                                Value: loadDoc.RegNumber,
                                                                IdAttributeName: null,
                                                                AttributeName: "RegNumber",
                                                                ColumnName: "Номер",
                                                                IsConstant: true
                                                            });

                                                            card.additionalSections.attributesSection.attributes.push({
                                                                Name: "PropertyName",
                                                                Value: loadDoc.RegDate,
                                                                IdAttributeName: null,
                                                                AttributeName: "RegDate",
                                                                ColumnName: "Дата",
                                                                IsConstant: true
                                                            });

                                                            card.additionalSections.attributesSection.attributes.push({
                                                                Name: "PropertyName",
                                                                Value: loadDoc.Version.ModifyTime,
                                                                IdAttributeName: null,
                                                                AttributeName: "Version.ModifyTime",
                                                                ColumnName: "Последнее изменение",
                                                                IsConstant: true
                                                            });

                                                            // todo TP № 22807
                                                            // атрибуты для конкретной версии документа
                                                            const IsOldServer = store.getState().globalState.settings.Content?.IsOldServer === "1";
                                                            let attributesByVersion;
                                                            if(IsOldServer) {
                                                                 attributesByVersion = await API.documents().getCurrentDocAttributesOld({
                                                                    filter: new Filter(FiltersType.ENTITY)
                                                                        .add(
                                                                            new EntityFilters().setEntityName("DocAttribute")
                                                                                .add({Name: "IdDocVersion", Value: IdVersion})
                                                                                .get()
                                                                        ).get(),
                                                                    sorting: null
                                                                });
                                                            }else{
                                                                 attributesByVersion = await API.documents().getCurrentDocAttributes({
                                                                    filter: new Filter(FiltersType.ENTITY)
                                                                        .add(
                                                                            new EntityFilters().setEntityName("DocAttributeForView")
                                                                                .add({Name: "IdDocVersion", Value: IdVersion})
                                                                                .get()
                                                                        ).get(),
                                                                    sorting: null
                                                                });
                                                            }

                                                            //
                                                            dispatch({
                                                                type: DOCUMENT_TEMPORARY_DATA.SAVE, payload: {
                                                                    name: "attributesByVersion",
                                                                    data: attributesByVersion.Records
                                                                }
                                                            });
                                                            const docTypeId = loadDoc.Type.Id
                                                            const isLastVersion = true // TODO КН 09.04.24 В эту тханку всегда приходит последняя idVersion
                                                            const additionalAttributes = docTypeId ? __.transformAttributesData({
                                                                attributesData: attributesByVersion.Records,
                                                                docTypeId: docTypeId,
                                                                needAllAttributesOnType: isLastVersion,
                                                                newValueFieldName: 'Value'
                                                            }) : [];
                                                            card.additionalSections.attributesSection.attributes = card.additionalSections.attributesSection.attributes.concat(additionalAttributes)

                                                            // __END__ под секция атрибуты

                                                            //тип документа
                                                            card.additionalSections.docTypeSection = {
                                                                TypeId: loadDoc.Type.Id,
                                                                TypeName: loadDoc.Type.Name
                                                            };

                                                            card.name = loadDoc.Name;
                                                            card.ShortDescription = loadDoc.ShortDescription;
                                                            card.number = loadDoc.RegNumber;
                                                            card.IdVersion = loadDoc.Version.Id;
                                                            card.additionalSections.isReady = true;
                                                            card.TypeId = loadDoc.Type.Id;
                                                            card.TypeName = loadDoc.Type.Name;

                                                            // //TODO:24034-Дублирование карточки документа 14.08-Arina
                                                            // let cardCollection=__.deepCopy(store.getState().document.cards.collection)
                                                            // let idDocIsIncluded=cardCollection.findIndex(item=>item.IdDoc===card.IdDoc)
                                                            //
                                                            // if(idDocIsIncluded===-1){
                                                            await dispatch({
                                                                type: DOCUMENT_PREVIEW_ACTIONS.CREATE_DOC_CARDS,
                                                                payload: card
                                                            });
                                                            // }

                                                            if (Next) {
                                                                Next.action(Next.params);
                                                            } // следующее действие если есть цепочка
                                                            // }
                                                        },
                                                        function (data) {
                                                            if (data.status === 401) {
                                                                dispatch({type: AUTH_ACTIONS.IS_AUTH, payload: false});
                                                            }
                                                            console.log("Ошибка получения атрибутов документа по версии (post): ", data);
                                                        });
                                                },
                                                function (data) {
                                                    if (data.status === 401) {
                                                        dispatch({type: AUTH_ACTIONS.IS_AUTH, payload: false});
                                                    }
                                                    console.log("Ошибка получения пакетов документа (post): ", data);
                                                });
                                        },
                                        function (data) {
                                            if (data.status === 401) {
                                                dispatch({type: AUTH_ACTIONS.IS_AUTH, payload: false});
                                            }
                                            console.log("Ошибка получения пакетов документа (post): ", data);
                                        });
                                },
                                function (data) {
                                    if (data.status === 401) {
                                        dispatch({type: AUTH_ACTIONS.IS_AUTH, payload: false});
                                    }
                                    console.log("Ошибка получения версий документа (post): ", data);
                                });
                        },
                        function (data) {
                            if (data.status === 401) {
                                dispatch({type: AUTH_ACTIONS.IS_AUTH, payload: false});
                            }
                            console.log("Ошибка получения содержания документа (post): ", data);
                        });
                },
                function (data) {
                    if (data.status === 401) {
                        dispatch({type: AUTH_ACTIONS.IS_AUTH, payload: false});
                    }
                    console.log("Ошибка получения файлов для предпросмотра (post): ", data);
                });
        } catch (exception) {
            console.log("Ошибка сбора данных для карточки документа (catch): ", exception);
            if (Error) {
                Error.action(Error.params);
            } // если ошибка колбек
        }
    }
}

// VersionIndex -> index выбранной активной версии
export const changeVersionDocumentCards = ({CardId, VersionIndex, Next = null, NewVersionItem = null, Error = null}) => {
    return async dispatch => {
        try {
            let card = __.deepCopy(store.getState().document.cards.collection.find(card => card.Id === CardId));

            let IdFilial = card.IdFilial;
            let IdDocGroup = card.IdDocGroup;
            // let TypeId = card.TypeId;
            let IdDoc = card?.IdDoc;
            let IdActiveVersion = card.additionalSections.versionSection.versions[VersionIndex].Id;

            card.IdActiveVersion = IdActiveVersion;
            card.additionalSections.versionSection.activeVersionIndex = VersionIndex;

            let request_docInfo = new apiRequest(apiUrl.DOCUMENTS.DOC_COLLECTION, {
                filter: new Filter(FiltersType.ENTITY)
                    .addAttribute({Name: "IdDocGroup", Value: IdDocGroup})
                    .addAttribute({Name: "PagingFilter", Value: {PageSize: 1, PageOffset: 0}})
                    .add(
                        new EntityFilters().setEntityName("DocExtended")
                            .add({Name: "IdFilial", Value: IdFilial})
                            .add({Name: "Id", Value: IdDoc})
                            // todo tp закомментированы т.к. не меняется версия в карточке,
                            //  если перейти из документа с более низкой версией или когда тип документа был изменен
                            // .add({Name: "Type.Id", Value: TypeId})
                            // .add({Name: "Version.Id", Value: card.IdLastVersion})
                            .get()
                    ).get(),
                sorting: new Sorting()
                    .addAttribute({Name: "IdAttributeName", Value: "-1"})
                    .add({PropertyName: "RegDate", Direction: 2})
                    .add({PropertyName: "RegNumber", Direction: 1})
                    .add({PropertyName: "Id", Direction: 1})
                    .get()
            });
            const IsOldServer = store.getState().globalState.settings.Content?.IsOldServer === "1";
            let request_attributesByVersion;
            if(IsOldServer) {
                 request_attributesByVersion = new apiRequest(apiUrl.DOCUMENTS.GET_ATTRIBUTES_FOR_VIEW_FOR_CURRENT_DOC_OLD, {
                    filter : new Filter(FiltersType.ENTITY)
                        .add(
                            new EntityFilters().setEntityName("DocAttribute")
                                .add({Name: "IdDocVersion", Value: IdActiveVersion})
                                .get()
                        ).get(),
                    sorting : null
                });
            }else{
                request_attributesByVersion = new apiRequest(apiUrl.DOCUMENTS.GET_ATTRIBUTES_FOR_VIEW_FOR_CURRENT_DOC, {
                    filter : new Filter(FiltersType.ENTITY)
                        .add(
                            new EntityFilters().setEntityName("DocAttributeForView")
                                .add({Name: "IdDocVersion", Value: IdActiveVersion})
                                .get()
                        ).get(),
                    sorting : null
                });
            }


            let request_headersByVersion = new apiRequest(apiUrl.DOCUMENTS.GET_HEADERS_FOR_CURRENT_DOC, {
                filter : new Filter(FiltersType.ENTITY)
                    .add(
                        new EntityFilters().setEntityName("DocHeader")
                            .add({Name: "IdDoc", Value: IdDoc})
                            .add({Name: "Version.Id", Value: IdActiveVersion})
                            .get()
                    ).get(),
                sorting : null
            });

            // let request_content = new apiRequest(apiUrl.DOCUMENTS.GET_CONTENT_FOR_CURRENT_DOC, {
            //     idDoc :  IdDoc,
            //     idVersion : IdActiveVersion
            // });

            //const activeFile = card.preview.files.find((file, index) => index === card.preview.activeFileIndex);
            let activeFile = card.preview.files[0];
            let content = "";
            if (activeFile.isAttach) {
                content = await API.documents().getCurrentFileText({
                    id: activeFile.id,
                    part: DocFilePart.Text,
                    isAttachment: false
                });
            } else {
                activeFile = store.getState().document.mainTable.activeRow.Info;
                content = await API.documents().getContentForCurrentFile({
                    idDoc: activeFile.Id,
                    // idVersion: activeFile.Version.Id
                    idVersion: NewVersionItem?.Id ?? activeFile.Version.Id
                })
            }

            let request_files = new apiRequest(apiUrl.DOCUMENTS.GET_BY_VERSION, {
                idDoc: IdDoc,
                idVersion :  IdActiveVersion
            });

            request_docInfo.execute(function (data_docInfo) {
                    data_docInfo = data_docInfo.Records[0];

                    request_headersByVersion.execute(function (data_headersByVersion) {
                            request_attributesByVersion.execute(function (data_attributesByVersion) {
                                    // __START__ секция атрибутов документа
                                    let attributes = [];

                                    attributes.push({
                                        Name : "PropertyName",
                                        Value : data_docInfo.Name,
                                        IdAttributeName : null,
                                        AttributeName : "Name",
                                        ColumnName : "Наименование",
                                        IsConstant : true
                                    });

                                    attributes.push({
                                        Name : "PropertyName",
                                        Value : data_docInfo.RegNumber,
                                        IdAttributeName : null,
                                        AttributeName : "RegNumber",
                                        ColumnName : "Номер",
                                        IsConstant : true
                                    });

                                    attributes.push({
                                        Name : "PropertyName",
                                        Value : data_docInfo.RegDate,
                                        IdAttributeName : null,
                                        AttributeName : "RegDate",
                                        ColumnName : "Дата",
                                        IsConstant : true
                                    });

                                    attributes.push({
                                        Name : "PropertyName",
                                        Value : card.additionalSections.versionSection.versions[VersionIndex].ModifyTime,
                                        IdAttributeName : null,
                                        AttributeName : "Version.ModifyTime",
                                        ColumnName : "Последнее изменение",
                                        IsConstant : true
                                    });
                                    // TODO КН 10.04.24 Думаю можно точно брать какое то из полей, а не пользоваться ИЛИ
                                    const docTypeId = data_headersByVersion?.Records[0]?.Type?.Id ?? data_docInfo.Type.Id
                                    const isLastVersion = IdActiveVersion === card.additionalSections.versionSection.versions[0].Id
                                    const additionalAttributes = docTypeId ? __.transformAttributesData({
                                        attributesData: data_attributesByVersion.Records,
                                        docTypeId: docTypeId,
                                        needAllAttributesOnType: isLastVersion,
                                        newValueFieldName: 'Value'
                                    }) : [];
                                    attributes = attributes.concat(additionalAttributes)

                                    card.additionalSections.attributesSection.attributes = attributes;
                                    // __END__ секция атрибутов документа

                                    // __START__ секция содержимого
                                    card.additionalSections.contentSection.content = content;
                                    // request_content.execute(function (data_content) {
                                    //         card.additionalSections.contentSection.content = data_content;
                                    // __END__ секция содержимого


                                    request_files.execute(async function (data_files) {
                                            data_files = data_files.Records;

                                            // __START__ файлы предпросмотра
                                            card.preview.files = [];
                                            card.preview.activeFileIndex = 0;
                                            card.preview.activeView = "preview";
                                            // card.preview.files.push({
                                            //     name: "Предпросмотр",
                                            //     link: addToEndUrl({
                                            //         url: apiUrl.DOCUMENTS.DOWNLOAD_PREVIEW.url,
                                            //         collection: [
                                            //             IdDoc,
                                            //             IdActiveVersion
                                            //         ]
                                            //     }),
                                            //     type: "image",
                                            //     description: "",
                                            //     size: null
                                            // });

                                            let loadFiles1 = __.deepCopy(data_files);

                                            if (loadFiles1)
                                                for (let i = 0; i < loadFiles1.length; i++) {
                                                    //TODO электронная подпись Запрос
                                                    let lSignatures = await API.signatures().load({
                                                        filter: new Filter(FiltersType.ENTITY).add(
                                                            new EntityFilters().setEntityName("DocFileSignature").add({
                                                                Name: "IdDocFile",
                                                                Value: loadFiles1[i].Id
                                                            }).get()
                                                        ).get(),
                                                        sorting: null,
                                                    });

                                                    if (lSignatures)
                                                        loadFiles1[i].signatures = lSignatures ? lSignatures.Records : null;
                                                }

                                            data_files = loadFiles1;

                                            let previewPdf = data_files.find(file => file.AttributeName.Id === 2);
                                            if (previewPdf) {
                                                card.preview.files.push({
                                                    id: previewPdf.Id,
                                                    name: previewPdf.Name,
                                                    originalLink: addToEndUrl({
                                                        url: apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                                                        collection: [
                                                            previewPdf.Id,
                                                            IdActiveVersion,
                                                            "false" // для просмотра (true - для загрузки)
                                                        ]
                                                    }),
                                                    previewLink: addToEndUrl({
                                                        url: apiUrl.DOCUMENTS.DOWNLOAD_PREVIEW.url,
                                                        collection: [
                                                            IdDoc,
                                                            IdActiveVersion
                                                        ]
                                                    }),
                                                    // type: "pdf",
                                                    description: "Оригинал",
                                                    size: previewPdf.Size,
                                                    AttributeName: previewPdf.AttributeName,
                                                    signatures: previewPdf.signatures,
                                                });
                                            }

                                            // прикрепленные файлы
                                            let otherFiles = data_files.filter(file => file.AttributeName.Id === 4);


                                            if (otherFiles) {
                                                for (let i = 0; i < otherFiles.length; i++) {
                                                    if (otherFiles[i].Name.toLowerCase().indexOf(".pdf") > 0) {
                                                        card.preview.files.push({
                                                            id: otherFiles[i].Id,
                                                            name: otherFiles[i].Name,
                                                            previewLink: `${baseUrl}${apiUrl.DOCUMENTS.DOWNLOAD_FILE.url}?id=${otherFiles[i].Id}&part=1&isAttachment=${false}`,
                                                            originalLink: `${baseUrl}${apiUrl.DOCUMENTS.DOWNLOAD_FILE.url}?id=${otherFiles[i].Id}&part=0&isAttachment=${false}`,
                                                            type: "image",
                                                            description: otherFiles[i].Description,
                                                            size: otherFiles[i].Size,
                                                            AttributeName: otherFiles[i].AttributeName,
                                                            signatures: otherFiles[i].signatures,
                                                            isAttach: true,
                                                        });
                                                    }
                                                }
                                            }
                                            // __END__ файлы предпросмотра


                                            // __START__ файлы дополнительной секции
                                            card.additionalSections.filesSection.files = [];
                                            let files = data_files.filter(file => file.AttributeName.Id === 4 || file.AttributeName.Id === 3);
                                            if (files) {
                                                for (let i = 0; i < files.length; i++) {
                                                    card.additionalSections.filesSection.files.push({
                                                        id: files[i].Id,
                                                        name: files[i].Name,
                                                        link: addToEndUrl({
                                                            url: apiUrl.DOCUMENTS.DOWNLOAD_FILE.url,
                                                            collection: [
                                                                files[i].Id,
                                                                IdActiveVersion,
                                                                "true" // false - для просмотра (true - для загрузки)
                                                            ]
                                                        }),
                                                        type: files[i].Name.toLowerCase().indexOf(".pdf") > 0 ? "pdf" : "image",
                                                        description: files[i].Description,
                                                        size: files[i].Size,
                                                        AttributeName: files[i].AttributeName,
                                                        signatures: files[i].signatures,
                                                    });
                                                }
                                            }
                                            // __END__ файлы дополнительной секции

                                            dispatch({type: DOCUMENT_PREVIEW_ACTIONS.UPDATE_DOC_CARDS, payload: card});
                                            if (Next) {
                                                Next.action(Next.params);
                                            }
                                        },
                                        function (data) {
                                            if(data.status === 401) {
                                                dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                                            }
                                            console.log("Ошибка получения файлов карточки документа (post): ", data);
                                        });
                                    // },
                                    // function (data) {
                                    //     if(data.status === 401) {
                                    //         dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                                    //     }
                                    //     console.log("Ошибка получения содержимого карточки документа (post): ", data);
                                    // });
                                },
                                function (data) {
                                    if(data.status === 401) {
                                        dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                                    }
                                    console.log("Ошибка получения атрибутов карточки документа (post): ", data);
                                });
                        },
                        function (data) {
                            if (data.status === 401) {
                                dispatch({type: AUTH_ACTIONS.IS_AUTH, payload: false});
                            }
                            console.log("Ошибка получения информации о документе - карточка документа (post): ", data);
                        });
                },
                function (data) {
                    if(data.status === 401) {
                        dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                    }
                    console.log("Ошибка получения информации о документе - карточка документа (post): ", data);
                });

        } catch (exception) {
            console.log("Ошибка изменения версии карточки документа (catch): ", exception);
            if (Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}



// выбираем активную карточку документа
// CardId - если = null, то сбрасываем активную карточку документа
export const selectActiveDocumentCard = ({CardId = null, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            await dispatch({type : DOCUMENT_PREVIEW_ACTIONS.SELECT_ACTIVE_DOC_CARDS, payload : CardId});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка выбора активной карточки документа (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


export const selectActiveFileForPreview_Card = ({Type="image",CardId, ActiveFileIndex, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : DOCUMENT_PREVIEW_ACTIONS.SELECT_ACTIVE_PREVIEW_FILE_FOR_CARDS, payload : {
                    CardId,
                    ActiveFileIndex,
                    Type,
                }});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка выбора активной карточки документа (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// открытие или закрытие дополнительных разделов для карточек документа
// Name - название раздела
// Status - true/false открыт или закрыт раздел
export const toggleAdditionalSectionsOpen_Cards = ({Name, Status = null,Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let activeCardId = store.getState().document.cards.activeCardId;

            if(Status == null) {
                Status = !store.getState().document.cards.collection
                    .find(card => card.Id === activeCardId).additionalSections[Name].isOpen;
            }
            dispatch({type : DOCUMENT_PREVIEW_ACTIONS.TOGGLE_ADDITIONAL_SECTIONS_OPEN_CARDS, payload : {
                    CardId : activeCardId,
                    name : Name,
                    isOpen : Status,
                }});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка переключения isOpen дополнительных разделов (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


// закрываем карточку документа
// если CardId = null, то закрываем все карточки
export const deleteDocumentCard = ({CardId = null,Next = null, Error = null}) => {
    return async dispatch => {
        try {
            if(CardId) {
                let activeCardId = store.getState().document.cards.activeCardId;
                let savedCards = store.getState().document.cards.collection.filter(card => card.Id !== CardId);
                let newActiveCardId = activeCardId === CardId ? null : activeCardId;
                dispatch({type : DOCUMENT_PREVIEW_ACTIONS.DELETE_DOC_CARDS, payload : {
                        cards : savedCards,
                        activeCardId : newActiveCardId
                    }});

            } else {
                dispatch({type : DOCUMENT_PREVIEW_ACTIONS.DELETE_DOC_CARDS, payload : {
                        cards : [],
                        activeCardId : null,
                    }});
            }

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка переключения isOpen дополнительных разделов (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// TODO: позже разнести этот файл на несколько

// создаем данные для редактирования
// если Card = null то удаляем данные
export const createRedactorData = ({CardId = null, Next = null, Error = null}) => {
    return async  (dispatch) => {
        try {
            if(CardId) {
                let card = __.deepCopy(store.getState().document.cards.collection.find(card => card.Id === CardId));
                card.additionalSections.filesSection.newFiles = [];
                card.additionalSections.filesSection.deleteFiles = [];
                card.additionalSections.filesSection.idMainFile = null;
                if (card.additionalSections.attributesSection.attributes.find(attribute => attribute.AttributeName.IdDictionaries)) {
                    //aa 19.03.2024 добавлен предварительный фильтр только нужных значений справочников
                    let arrDictionariesIsd = card.additionalSections.attributesSection.attributes.filter(attribute => attribute.AttributeName.IdDictionaries).map(item => item.AttributeName.IdDictionaries.toString())
                    const externalDictionariesValues = await API.dictionary().getDictionaryValues({filter:
                            {
                                EntityFilters: [
                                    {
                                        EntityName: "DictionaryValue",
                                        PropertyFilters: [
                                            {
                                                PropertyName: "IdDictionaries",
                                                Operator: 8,
                                                FilterDataAsStringList: arrDictionariesIsd
                                            }
                                        ]
                                    }
                                ]
                            }
                        , sorting: null});
                    const externalDictionary = await API.dictionary().getExternalDictionaries({filter: null, sorting: null});

                    dispatch({
                        type: DOCUMENT_TEMPORARY_DATA.SAVE, payload: {
                            name: "ExternalDictionariesValues",
                            data: externalDictionariesValues
                        }
                    });
                    dispatch({
                        type: DOCUMENT_TEMPORARY_DATA.SAVE, payload: {
                            name: "ExternalDictionaryExternalDictionary",
                            data: externalDictionary
                        }
                    });
                }
                dispatch({type : DOCUMENT_PREVIEW_ACTIONS.CREATE_REDACTOR_DATA, payload : card});
            } else {
                if (store.getState().document.mainTable.closeOpenedTabs) {
                    deleteDocumentCard({})
                }
                dispatch({type : DOCUMENT_PREVIEW_ACTIONS.CREATE_REDACTOR_DATA, payload : null});
            }
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка копирования данных карты в данные для редактирования (catch): ", exception);
            if(Error) {Error.action(Error.params);}
        }
    }
}

// обновляем значение атрибутов в режиме редактирования
// isConstant - константный ли атрибут
// value - новое значение
// name - имя для поиска. Если isConstant = true, то ищем по AttributeName.
// Если isConstant = false, то ищем по IdAttributeName
export const updateAttributesRedactorData = ({item, newValue, newIdDictionaryValue, Next = null, Error = null}) => {
    return async (dispatch) => {
        try {
            const redactorData = store.getState().document.cards.redactorData;
            if (redactorData) {
                const nameFieldName = item.IsConstant ? 'AttributeName' : 'IdAttributeName';
                const name = item[nameFieldName];
                const oldAttributes = redactorData.additionalSections.attributesSection.attributes
                const foundIndex = oldAttributes.findIndex(attribute => attribute[nameFieldName]?.toString() === name.toString());
                if (foundIndex !== -1) {
                    let newAttributes;
                    if (!item.IsMultipleAllowed) {
                        newAttributes = [
                            ...oldAttributes.slice(0, foundIndex),
                            {
                                ...item,
                                Value: newValue,
                                IdDictionaryValue: newIdDictionaryValue ?? item.IdDictionaryValue,
                                ShortDescription: null,
                            },
                            ...oldAttributes.slice(foundIndex + 1),
                        ]
                    } else {
                        let newAttributesOnId = [];
                        const newValueIsEmpty = newValue === null || (Array.isArray(newValue) && newValue.length === 0)
                        if (newValueIsEmpty) {
                            newAttributesOnId = [{
                                ...item,
                                Id: undefined,
                                Value: null,
                                IdDictionaryValue: null,
                                ShortDescription: null,
                            }]
                        } else {
                            newAttributesOnId = newValue.map((el, index) => {
                                const oldIdAsArray = Array.isArray(item.Id) ? item.Id : [item.Id]
                                let idDictionaryValue;
                                if (newIdDictionaryValue) {
                                    idDictionaryValue = newIdDictionaryValue[index]
                                } else {
                                    const oldIdDictionaryValueAsArray = Array.isArray(item.IdDictionaryValue) ? item.IdDictionaryValue : [item.IdDictionaryValue]
                                    idDictionaryValue = oldIdDictionaryValueAsArray[index] || null
                                }
                                return {
                                    ...item,
                                    Id: oldIdAsArray[index],
                                    Value: el,
                                    IdDictionaryValue: idDictionaryValue,
                                    ShortDescription: null,
                                }
                            })
                        }
                        const filteredAttributes = oldAttributes.filter(attribute => attribute[nameFieldName]?.toString() !== name.toString())
                        newAttributes = [
                            ...filteredAttributes.slice(0, foundIndex),
                            ...newAttributesOnId,
                            /* TODO КН 18.03.24 foundIndex, а не foundIndex+1, т.к. мы уже отфильтровали атрибуты,
                                и нам нужно элемент не заменять как выше в "if (!item.IsMultipleAllowed)", а просто вставить
                             */
                            ...filteredAttributes.slice(foundIndex),
                        ]
                    }
                    dispatch({type : DOCUMENT_PREVIEW_ACTIONS.UPDATE_ATTRIBUTES_REDACTOR_DATA, payload : newAttributes});
                } else {
                    throw ('Атрибут не найден')
                }
            } else {
                throw ('Необходимые данные не найдены')
            }
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка изменения атрибутов в режиме редактирования (catch): ", exception);
            if(Error) {Error.action(Error.params);}
        }
    }
}

// обновляем значение содержимого в режиме редактирования
export const updateContentRedactorData = ({value, Next = null, Error = null}) => {
    return async  (dispatch) => {
        try {
            let redactorData = store.getState().document.cards.redactorData;
            if(redactorData) {
                await dispatch({type : DOCUMENT_PREVIEW_ACTIONS.UPDATE_CONTENT_REDACTOR_DATA, payload : value});
            }
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка изменения содержимого в режиме редактирования (catch): ", exception);
            if(Error) {Error.action(Error.params);}
        }
    }
}

// обновляем значение типа документа в режиме редактирования
export const updateDocTypeRedactorData = ({value, Next = null, Error = null}) => {
    return async  (dispatch) => {
        try {
            let redactorData = store.getState().document.cards.redactorData;
            if(redactorData) {
                await dispatch({type : DOCUMENT_PREVIEW_ACTIONS.UPDATE_DOC_TYPE_REDACTOR_DATA, payload : value});
            }
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка изменения содержимого в режиме редактирования (catch): ", exception);
            if(Error) {Error.action(Error.params);}
        }
    }
}


// обновляем значение данных о файлах в режиме редактирования
export const updateFilesNameOrDescriptionRedactorData = ({idFile, type, value, Next = null, Error = null}) => {
    return async  (dispatch) => {
        try {
            let files = __.deepCopy(store.getState().document.cards.redactorData.additionalSections.filesSection.files);

            let index = files.findIndex(file => file.id === idFile);

            if(type === "name") {
                files[index].name = value;
            }
            else if(type === "description") {
                files[index].description = value;
            }

            await dispatch({type : DOCUMENT_PREVIEW_ACTIONS.UPDATE_FILES_NAME_OR_DESCRIPTION, payload : files});
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка изменения данных о файлов в режиме редактирования (catch): ", exception);
            if(Error) {Error.action(Error.params);}
        }
    }
}

export const selectActiveRow_filesSection = ({RowInfo ,Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type: DOCUMENT_PREVIEW_ACTIONS.SELECT_ACTIVE_ROW_FILE, payload: RowInfo});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const deleteSignature = ({RowInfo ,Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type: DOCUMENT_PREVIEW_ACTIONS.DELETE_SIGNATURE, payload: RowInfo});
            dispatch({type: PACKAGES_TREE_ACTIONS.DELETE_SIGNATURE, payload: RowInfo});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const updateSignature = ({RowInfo ,Next = null, Error = null}) => {
    return async dispatch => {
        try {
            //TODO электронная подпись Запрос
            let lSignatures;
            if  (RowInfo.Id)
                lSignatures = await API.signatures().getById({
                    id: RowInfo.Id
                })
            else {
                //TODO электронная подпись Запрос
                lSignatures = await API.signatures().load({
                    filter: new Filter(FiltersType.ENTITY).add(
                        new EntityFilters().setEntityName("DocFileSignature").add({
                            Name: "IdDocFile",
                            Value: RowInfo.idDocFile
                        }).get()
                    ).get(),
                    sorting: null,
                });
            }
            dispatch({type: DOCUMENT_PREVIEW_ACTIONS.UPDATE_SIGNATURE, payload: {RowInfo: RowInfo, lSignature: lSignatures}});
            dispatch({type: PACKAGES_TREE_ACTIONS.UPDATE_SIGNATURE, payload: {RowInfo: RowInfo, lSignature: lSignatures}});
            dispatch({type: SEARCH_PREVIEW_ACTIONS.UPDATE_SIGNATURE, payload: {RowInfo: RowInfo, lSignature: lSignatures}});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const showSignatureForFile = ({RowInfo ,Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type: DOCUMENT_PREVIEW_ACTIONS.SET_ROW_FILE_FOR_SHOW_SIGNATURE, payload: RowInfo});
            dispatch({type: PACKAGES_TREE_ACTIONS.SET_ROW_FILE_FOR_SHOW_SIGNATURE, payload: RowInfo});
            dispatch({type: SEARCH_PREVIEW_ACTIONS.SET_ROW_FILE_FOR_SHOW_SIGNATURE, payload: RowInfo});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаления строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// добавление новый файлов к документу в режиме редактирования
// files = [{file, name, description}]
export const addNewFilesRedactorData = ({type, newFiles, Next = null, Error = null}) => {
    return async  (dispatch) => {
        try {
            let files = [];
            if(store.getState().document.cards.redactorData !== null) {
                files = [...store.getState().document.cards.redactorData.additionalSections.filesSection.newFiles]
            }

            if(type === "main") {
                let index = files.findIndex(file => file.type === "main");

                if(index !== -1) {
                    files[index] = {
                        name : newFiles[0].name,
                        description : newFiles[0].description,
                        file : newFiles[0].file,
                        type : "main"
                    };
                } else {
                    files.push({
                        name : newFiles[0].name,
                        description : newFiles[0].description,
                        file : newFiles[0].file,
                        type : "main"
                    });
                }
            } else if(type === "attachment") {
                files = files.filter(file => file.type !== "attachment");
                for(let i = 0; i < newFiles.length; i++) {
                    files.push({
                        name :  newFiles[i].name,
                        description :  newFiles[i].description,
                        file : newFiles[i].file,
                        type : "attachment",
                        id : createUniqueIdString(6)
                    });
                }
            }

            await dispatch({type : DOCUMENT_PREVIEW_ACTIONS.UPDATE_FILES_ADD_NEW, payload : files});
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка добавления файлов в режиме редактирования (catch): ", exception);
            if(Error) {Error.action(Error.params);}
        }
    }
}


// удаление файлов прикрепленных к документу
// при отправке id который уже имеется в массиве
// он будет оттуда удален
export const deleteFilesRedactorData = ({fileInfo, Next = null, Error = null}) => {
    return async  (dispatch) => {
        try {
            let files = [...store.getState().document.cards.redactorData.additionalSections.filesSection.deleteFiles];

            let index = files.findIndex(file => file.id === fileInfo.id);

            if(index === -1) {
                files.push(fileInfo);
            } else {
                files = files.filter(file => file.id !== fileInfo.id);
            }

            await dispatch({type : DOCUMENT_PREVIEW_ACTIONS.UPDATE_FILES_DELETE, payload : files});
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка добавления файлов в режиме редактирования (catch): ", exception);
            if(Error) {Error.action(Error.params);}
        }
    }
}

// удалить новые прикрепленные файлы
export const deleteNewAttachmentFilesRedactorData = ({fileInfo, Next = null, Error = null}) => {
    return async  (dispatch) => {
        try {
            let files = [...store.getState().document.cards.redactorData.additionalSections.filesSection.newFiles];

            files = files.filter(file => file.id !== fileInfo.id);

            await dispatch({type : DOCUMENT_PREVIEW_ACTIONS.UPDATE_FILES_DELETE_NEW_ATTACHMENT, payload : files});
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка удаления новых прикрепленных файлов в режиме редактирования (catch): ", exception);
            if(Error) {Error.action(Error.params);}
        }
    }
}


// выбрать файл основным в режиме редактирования
export const SetMainFileRedactorData = ({fileId, isUpdated = false, Next = null, Error = null}) => {
    return async  (dispatch) => {
        try {
            await dispatch({type : DOCUMENT_PREVIEW_ACTIONS.SET_MAIN_FILE, payload : {
                    id : fileId,
                    isUpdated
                }});
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка выбора файла основным в режиме редактирования (catch): ", exception);
            if(Error) {Error.action(Error.params);}
        }
    }
}

//обновить файл документа
export const updateDocFile=({IdDocFile,IdDoc,File,Part,Next=null,Error=null})=>{
    return async (dispatch)=>{
        try{
            // //обновление файла документа
            let activeFile=store.getState().document.preview.activeFile;
            let data = new FormData();

            data.append("description", `${activeFile.description}`);
            data.append("upload", File, File.name);

            let responseDocFileUpdate=await API.documents().updateFileOriginal({id:IdDocFile,part:Part,FormData:data})

            if(responseDocFileUpdate===true){
                //загрузка расширенных документов архива по фильтру
                const body = {
                    filter: new Filter(FiltersType.ENTITY)
                        .add(new EntityFilters().setEntityName("DocExtended")
                            .add({Name: "Id", Operator: FilerOperatorsEnum.Equal, Value: IdDoc})
                            .get()
                        )
                        .addAttribute({Name: "PagingFilter", Value: {PageOffset: 0, PageSize: 25}}),
                    sorting: new Sorting().add({PropertyName: "Id", Direction: SortDirectionEnum.Desc})
                }
                const docInfo = await API.documents().getDocsExtended(body);

                await dispatch({type: DOCUMENT_PREVIEW_ACTIONS.CREATE_BY_NEW_OPEN_DOC, payload: docInfo.Records});
                if(docInfo){
                    //загрузка файла документа
                    const lFilesLoad = await API.documents().getFiles({
                        filter: new Filter(FiltersType.ENTITY)
                            .add(
                                new EntityFilters().setEntityName("DocFile")
                                    .add({Name: "IdDoc", Value: IdDoc})
                                    .get()
                            ).get(),
                        sorting: null
                    });

                    if(lFilesLoad){
                        //скачка preview файла документа
                        let response=await API.documents().downloadDocFile({id:IdDocFile, part:DocFilePart.Preview, isAttachment:false})

                        if(response.status===200 && Part===DocFilePart.File){
                            //скачка файла документа
                            let responseDownloadOrgnlFile=await API.documents().downloadDocFile({id:IdDocFile, part:DocFilePart.File, isAttachment:false})
                            if(responseDownloadOrgnlFile.status===200){
                                store.dispatch(setModalData({}))
                                store.dispatch(createPreviewBySelectDocument({}))
                            }
                        }
                        if(response.status===200 && Part===DocFilePart.Preview){
                            let version=store.getState().document.mainTable.activeRow.Version

                            await store.dispatch(createDocumentCards({IdDoc:version.IdDoc,IdVersion:version.Id}))
                            await store.dispatch(createPreviewBySelectDocument({}))

                            store.dispatch(setModalData({}))
                        }
                    }
                }
            }
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка проверки данных в режиме редактирования (catch): ", exception);
            if(Error) {Error.action(Error.params);}
        }
    }
}


function findDuplicates(arr, withIndex) {
    let uniqueElements = new Set();
    let duplicates = [];

    for (let i = 0; i < arr.length; i++) {
        let element =  arr[i];

        if (uniqueElements.has(element)) {
            const duplicateElement =  withIndex ? {element: element, index: i} : element
            duplicates.push(duplicateElement)
        } else {
            uniqueElements.add(element);
        }
    }
    return duplicates;
}
function checkIdDocAttributeForDelete(Id, arr) {
    if (!arr.find(item => item.Id === Id)) {
        return Id;
    }
    return undefined;
}


// отправка на сервер данных для изменения текущей версии документа
export const editDocCurrentVersion = ({CardInfo, editData, Next = null, Error = null}) => {
    return async (dispatch) => {
        try {

            dispatch(setModalData({
                name : ModalTypes.app.alert,
                data : {content : "Обновление...", disableButton : true}
            }));

            const errorAlert = (errorText) => {
                dispatch(setModalData({
                    name : ModalTypes.app.alert,
                    data : {content : "Во время изменения документа произошла ошибка: " + errorText, disableButton : false}
                }));
            }

            let response;
            let isUpdatedOnServer = false;


            let Type;
            let isUpdateDoctype = false;
            if(editData.docType.old.TypeId !== editData.docType.new.TypeId || editData.docType.isUpdated) {
                isUpdateDoctype = true;
                Type = {
                    Id : editData.docType.new.TypeId,
                    Name : editData.docType.new.TypeName
                }
            } else {
                Type = {
                    Id : CardInfo.TypeId,
                    Name : CardInfo.TypeName
                }
            }


            // обновление аттрибутов __START__
            let docHeader = {
                Id : CardInfo?.IdDoc.toString(),
                IdFilial : CardInfo.IdFilial.toString(),
                Name : editData.attributes.find(attr => attr.new.AttributeName === "Name").new.Value,
                RegDate : editData.attributes.find(attr => attr.new.AttributeName === "RegDate").new.Value,
                RegNumber : editData.attributes.find(attr => attr.new.AttributeName === "RegNumber").new.Value,
                Type : Type,
            };

            let docAttributes = [];
            let docAttributesForDelete = [];
            let isUpdatedAttributes = false;
            const attributesByVersion = store.getState().document.temporaryData.attributesByVersion;
            /* TODO КН 15.04.24 Сейчас вроде как корректные id уже приходят и не нам не надо по старым шастать
                и заменять у новых (условно если было два, удалили второй, а потом добавили,
                мы берем старый айдишник второго. Так делать не надо. Сейчас здесь второму в такому случае придет Undefined
                 его и надо оставить, а старый удалить (сделал такой вывод делая компоененты NewDocumentByVersion И ChangeDocumentTypeModalOrgiinal))
             */
            for(let i = 0; i < editData.attributes.length; i++) {
                if (
                    editData.attributes[i].isUpdated
                    || !__.isAttributeValuesEquals(editData.attributes[i].old.Value, editData.attributes[i].new.Value)
                    || !__.isAttributeValuesEquals(editData.attributes[i].old.IdDictionaryValue, editData.attributes[i].new.IdDictionaryValue)
                ) {
                    isUpdatedAttributes = true;
                }
                if(editData.attributes[i].new.IsConstant) continue;
                // //TODO aa 12.12.2023 Убрано условие, т.к. этот дефис из 1С и не могут модифицировать документ || editData.attributes[i].new.Value === "-"
                // if ((editData.attributes[i].new.Value === null) && editData.attributes[i].new.IdAttributeType !== DataTypesEnum.DataTime ) {
                //     docAttributes.push({
                //         AttributeName : editData.attributes[i].new.AttributeName,
                //         AttributeValue : null,
                //         Id: !editData.attributes[i].new.IsConstant ?
                //             attributesByVersion.find(attribute => attribute.AttributeName.Id === editData.attributes[i].new.AttributeName.Id)?.Id
                //             : undefined
                //     })
                // } else {
                // TODO ЕСЛИ ЕСТЬ СЛОВАРИ И ЭТО МУЛЬТИ (мб уже не отрабытывает, т.к. вроде не обьтединяем в один атрибут несколько)
                if (
                    editData.attributes[i]?.new.AttributeName?.IdDictionaries
                    && editData.attributes[i].new?.IsMultipleAllowed
                ) {
                    if(isUpdatedAttributes) {
                        const oldValueIsArray = Array.isArray(editData.attributes[i].old.Value)
                        if (oldValueIsArray) {
                            const lOldValueDuplicates = findDuplicates(editData.attributes[i].old.Value, true);
                            lOldValueDuplicates.forEach(lOldValueDuplicate => {
                                const lAttributesDocValues = attributesByVersion.filter(attribute =>
                                    attribute.AttributeName.Id === editData.attributes[i].old.AttributeName.Id
                                    && __.isAttributeValuesEquals(lOldValueDuplicate, attribute.AttributeValue)
                                )
                                for (let lAttr in lAttributesDocValues) {
                                    if (!docAttributesForDelete.find(item => lAttributesDocValues[lAttr]?.Id === item.Id)) {
                                        docAttributesForDelete.push({
                                            AttributeName: editData.attributes[i].old.AttributeName,
                                            AttributeValue: lOldValueDuplicate.element,
                                            IdDictionaryValue: editData.attributes[i].old.IdDictionaryValue[lOldValueDuplicate.index],
                                            Id: !editData.attributes[i].old.IsConstant ?
                                                lAttributesDocValues[lAttr]?.Id
                                                : undefined
                                        });
                                    }
                                }
                            })
                        }
                        const oldValueAsArray = oldValueIsArray ? editData.attributes[i].old.Value : [editData.attributes[i].old.Value]
                        const oldIdDictionaryValueAsArray = Array.isArray(editData.attributes[i].old.IdDictionaryValue) ? editData.attributes[i].old.IdDictionaryValue : [editData.attributes[i].old.IdDictionaryValue]
                        for (let lValue in oldValueAsArray) {
                            const newValue = editData.attributes[i].new.Value
                            const isDelete = Array.isArray(newValue) ?
                                !(editData.attributes[i].new.Value.some(el => (__.isAttributeValuesEquals(oldValueAsArray[lValue], el))))
                                : !__.isAttributeValuesEquals(oldValueAsArray[lValue], newValue)
                            if (isDelete) {
                                const lAttributesDocValues = attributesByVersion.filter(attribute =>
                                    attribute.AttributeName.Id === editData.attributes[i].old.AttributeName.Id
                                    && __.isAttributeValuesEquals(oldValueAsArray[lValue], attribute.AttributeValue)
                                )
                                for (let lAttr in lAttributesDocValues) {
                                    if (!docAttributesForDelete.find(item => lAttributesDocValues[lAttr]?.Id === item.Id)) {
                                        docAttributesForDelete.push({
                                            AttributeName: editData.attributes[i].old.AttributeName,
                                            AttributeValue: oldValueAsArray[lValue],
                                            IdDictionaryValue: oldIdDictionaryValueAsArray[lValue],
                                            Id: !editData.attributes[i].old.IsConstant ?
                                                lAttributesDocValues[lAttr]?.Id
                                                : undefined
                                        });
                                    }
                                }
                            }
                        }
                    }
                    const newValueAsArray = Array.isArray(editData.attributes[i].new.Value) ? editData.attributes[i].new.Value : [editData.attributes[i].new.Value]
                    const newIdDictionaryValueAsArray = Array.isArray(editData.attributes[i].new.IdDictionaryValue) ? editData.attributes[i].new.IdDictionaryValue : [editData.attributes[i].new.IdDictionaryValue]
                    for (let lValue in newValueAsArray) {
                        docAttributes.push({
                            AttributeName: editData.attributes[i].new.AttributeName,
                            AttributeValue: newValueAsArray[lValue],
                            IdDictionaryValue:  newIdDictionaryValueAsArray[lValue],
                            //TODO aa 29.01.2024 ищем по id атрибута и значению, т.к. в state.document.cards.redactorDataCollected отсутствуют id атрибута документа
                            Id: !editData.attributes[i].new.IsConstant ?
                                (checkIdDocAttributeForDelete(
                                    attributesByVersion.find(attribute =>
                                        attribute.AttributeName.Id === editData.attributes[i].new.AttributeName.Id
                                        && __.isAttributeValuesEquals(attribute.AttributeValue, newValueAsArray[lValue])
                                    )?.Id,
                                    docAttributesForDelete
                                ))
                                : undefined
                        });
                    }
                }
                // TODO ЕСЛИ ПРОСТО МУЛЬТИ
                else if (editData.attributes[i].new.IsMultipleAllowed) {
                    if(isUpdatedAttributes) {
                        const oldValueIsArray = Array.isArray(editData.attributes[i].old.Value)
                        if (oldValueIsArray) {
                            const lOldValueDuplicates = findDuplicates(editData.attributes[i].old.Value);
                            lOldValueDuplicates.map(lOldValueDuplicate => {
                                const lAttributesDocValues = attributesByVersion.filter(attribute => attribute.AttributeName.Id === editData.attributes[i].old.AttributeName.Id && (__.isAttributeValuesEquals(lOldValueDuplicate, attribute.AttributeValue)))
                                for (let lAttr in lAttributesDocValues) {
                                    if (!docAttributesForDelete.find(item => lAttributesDocValues[lAttr]?.Id === item.Id)) {
                                        docAttributesForDelete.push({
                                            AttributeName: editData.attributes[i].old.AttributeName,
                                            AttributeValue: lOldValueDuplicate,
                                            /*
                                             TODO КН 12.03.24 Тут на самом делел логика построена не очень. IdDictionaryValue === null, потому что если бы
                                                если атрибут мульти и он со словарями то отработало бы условие верхнее, а иначе кроме Null ничего быть ПО ТЕОРИИ не может
                                             */
                                            IdDictionaryValue: null,
                                            Id: !editData.attributes[i].old.IsConstant ?
                                                lAttributesDocValues[lAttr]?.Id
                                                : undefined,
                                        });
                                    }
                                }
                            })
                        }
                        const oldValueAsArray = Array.isArray(editData.attributes[i].old.Value) ? editData.attributes[i].old.Value : [editData.attributes[i].old.Value]
                        for (let lValue in oldValueAsArray) {
                            const newValue = editData.attributes[i].new.Value
                            const isDelete = Array.isArray(newValue) ?
                                !(editData.attributes[i].new.Value.some(el => (__.isAttributeValuesEquals(oldValueAsArray[lValue], el))))
                                : !__.isAttributeValuesEquals(oldValueAsArray[lValue], newValue)
                            if (isDelete) {
                                const lAttributesDocValues = attributesByVersion.filter(attribute => attribute.AttributeName.Id === editData.attributes[i].old.AttributeName.Id && __.isAttributeValuesEquals(oldValueAsArray[lValue], attribute.AttributeValue))
                                for (let lAttr in lAttributesDocValues) {
                                    if (!docAttributesForDelete.find(item => lAttributesDocValues[lAttr]?.Id === item.Id)) {
                                        docAttributesForDelete.push({
                                            AttributeName: editData.attributes[i].old.AttributeName,
                                            AttributeValue: oldValueAsArray[lValue],
                                            /*
                                             TODO КН 12.03.24 Тут на самом делел логика построена не очень. IdDictionaryValue === null, потому что если бы
                                                если атрибут мульти и он со словарями то отработало бы условие верхнее, а иначе кроме Null ничего быть ПО ТЕОРИИ не может
                                             */
                                            IdDictionaryValue: null,
                                            Id: !editData.attributes[i].old.IsConstant ?
                                                lAttributesDocValues[lAttr]?.Id
                                                : undefined,
                                        });
                                    }
                                }
                            }
                        }
                    }
                    const newValueAsArray = Array.isArray(editData.attributes[i].new.Value) ? editData.attributes[i].new.Value : [editData.attributes[i].new.Value]
                    for (let lValue in newValueAsArray) {
                        docAttributes.push({
                            AttributeName: editData.attributes[i].new.AttributeName,
                            AttributeValue: newValueAsArray[lValue],
                            /*
                               TODO КН 12.03.24 Тут на самом делел логика построена не очень. IdDictionaryValue === null, потому что если бы
                                если атрибут мульти и он со словарями то отработало бы условие верхнее, а иначе кроме Null ничего быть ПО ТЕОРИИ не может
                             */
                            IdDictionaryValue: null,
                            // TODO aa 29.01.2024 ищем по id атрибута и значению, т.к. в state.document.cards.redactorDataCollected отсутствуют id атрибута документа
                            Id: !editData.attributes[i].new.IsConstant ?
                                checkIdDocAttributeForDelete(attributesByVersion.find(attribute =>
                                    attribute.AttributeName.Id === editData.attributes[i].new.AttributeName.Id
                                    && __.isAttributeValuesEquals(attribute.AttributeValue, newValueAsArray[lValue])
                                )?.Id, docAttributesForDelete)
                                : undefined,
                        });
                    }
                } else {
                    if (editData.attributes[i]?.new.AttributeName?.IdDictionaries) {
                        docAttributes.push({
                            AttributeName: editData.attributes[i].new.AttributeName,
                            AttributeValue: editData.attributes[i].new.Value,
                            IdDictionaryValue: editData.attributes[i].new.IdDictionaryValue, // Array.isArray(idDictionaryValue) ? idDictionaryValue[0] : idDictionaryValue
                            Id: !editData.attributes[i].new.IsConstant ?
                                attributesByVersion.find(attribute => attribute.AttributeName.Id === editData.attributes[i].new.AttributeName.Id)?.Id
                                : undefined
                        });
                    } else {
                        docAttributes.push({
                            AttributeName: editData.attributes[i].new.AttributeName,
                            AttributeValue: editData.attributes[i].new.Value,
                            IdDictionaryValue: editData.attributes[i].new.IdDictionaryValue,
                            Id: !editData.attributes[i].new.IsConstant
                                ? editData.attributes[i].new?.Id
                                : undefined
                        });
                        if (
                            !editData.attributes[i].new.IsConstant
                            && Array.isArray(editData.attributes[i].old.Value)
                            && Array.isArray(editData.attributes[i].old.Id)
                        ) {
                            // Если старые значения были массивом, то помечаем на удаление все id кроме нового
                            for (let valueIndex in editData.attributes[i].old.Value) {
                                if (editData.attributes[i].old.Id[valueIndex] != editData.attributes[i].new?.Id) {
                                    docAttributesForDelete.push({
                                        AttributeName: editData.attributes[i].old.AttributeName,
                                        AttributeValue: editData.attributes[i].old.Value[valueIndex],
                                        IdDictionaryValue: editData.attributes[i].old.IdDictionaryValue[valueIndex],
                                        Id: editData.attributes[i].old.Id[valueIndex],
                                    });
                                }
                            }
                        }
                    }
                }
            }

            if(isUpdatedAttributes || isUpdateDoctype) {
                for (const docAttribute of docAttributes) {
                    if (docAttribute.AttributeName.IdAttributeType === DataTypesEnum.DataTime) {
                        const lAttributeOrigin = editData.attributes.find(lItem => lItem.old?.IdAttributeName === docAttribute.AttributeName.Id);
                        if (lAttributeOrigin) {
                            if (!__.isAttributeValuesEquals(lAttributeOrigin.old.Value, lAttributeOrigin.new.Value)) {
                                if (Array.isArray(docAttribute.AttributeValue)) {
                                    docAttribute.AttributeValue = docAttribute.AttributeValue.map(el => __.getCorrectDate(el))
                                } else {
                                    docAttribute.AttributeValue = __.getCorrectDate(docAttribute.AttributeValue);
                                }
                            }
                        }
                    }
                    /*
                    TODO КН 19.02.24 Думаю, делать это перед запросом, а не в логике компонент, адеекватно.
                     Например, затирая дефолтный текстовый инпут, мы что, не можем засетать пустую строку?
                     Если, когда приходит пустая строка, сетать там null - как будто лишняя логика, ради одного этого запроса (да и переделывать много:))
                     */
                    if (docAttribute.AttributeValue === '') {
                        docAttribute.AttributeValue = null;
                    }
                }
                docAttributesForDelete = docAttributesForDelete.length < 1 ? null : docAttributesForDelete
                const IsOldServer = store.getState().globalState.settings.Content?.IsOldServer === "1";
                if (IsOldServer) {
                    response = await API.documents().updateDocAttributes({docHeader, docAttributes, docAttributesForDelete})
                }else {
                    response = await API.documents().updateDocAttributesEXT({docHeader, docAttributes, docAttributesForDelete})
                }
                if(response?.errorCode === 400) {
                    errorAlert(response.message.Message);
                    return;
                }
                isUpdatedOnServer = true;
            }
            // обновление аттрибутов __END__


            // загрузка новых файлов
            for(let i = 0; i < editData.files.newFiles.length; i++) {

                let data = new FormData();
                data.append("description", editData.files.newFiles[i].description);
                data.append("upload", editData.files.newFiles[i].file, editData.files.newFiles[i].name);

                let response = await new apiRequestNew({
                    action : apiUrl.DOCUMENTS.LOAD_FILE_FOR_CURRENT_DOC,
                    endUrl : CardInfo?.IdDoc,
                    headers : null,
                    params : data
                }).isFileUpload(true).execute();

                if(!response.ok) {
                    errorAlert("Загрузка новых файлов");
                    return;
                }
                isUpdatedOnServer = true;
            }


            // изменяем имена и описания файлов
            for(let i = 0; i < editData.files.files.length; i++) {
                let condition = editData.files.files[i].old.name !== editData.files.files[i].new.name  ||
                    editData.files.files[i].old.description !== editData.files.files[i].new.description ;

                if(condition) {
                    response = await new apiRequestNew({
                        action : apiUrl.DOCUMENTS.RENAME_FILE,
                        headers : null,
                        params : {
                            id : editData.files.files[i].new.id,
                            newDescription :  editData.files.files[i].new.description,
                            newName :  editData.files.files[i].new.name,
                        }
                    }).execute();


                    if(!response.ok) {

                        errorAlert("Редактирование файлов");
                        return;
                    }
                    isUpdatedOnServer = true;
                }
            }


            if(editData.files.idMainFile !== editData.files.idMainFileOriginal) {
                // выбор основного файла
                response = await new apiRequestNew({
                    action : apiUrl.DOCUMENTS.SET_MAIN_FILE,
                    headers : null,
                    params : {
                        id : editData.files.idMainFile
                    }
                }).execute();

                if(!response.ok) {

                    errorAlert("Выбор основного файла");
                    return;
                }

                // пересоздаем предпросмотр
                response = await new apiRequestNew({
                    action : apiUrl.DOCUMENTS.RESET_PREVIEW_FILE,
                    headers : null,
                    params : {
                        id : editData.files.idMainFile,
                        isAvailable : true
                    }
                }).execute();

                if(!response.ok) {

                    errorAlert("Обновление предпросмотра");
                    return;
                }
                isUpdatedOnServer = true;


                // обновление содержания // TODO: убрать костыль с общим содержанием файлов
                let isUpdatedContent = editData.content.new !== editData.content.old;
                if(isUpdatedContent) {
                    response = await new apiRequestNew({
                        action : apiUrl.DOCUMENTS.UPDATE_DOC_TEXT,
                        headers : null,
                        params : {
                            id : editData.files.idMainFile,
                            text : editData.content.new
                        }
                    }).execute();

                    if(!response.ok) {
                        console.log(response, await response.json());
                        errorAlert("Обновление содержания");
                        return;
                    }
                    isUpdatedOnServer = true;
                }

            } else {
                // обновление содержания
                let isUpdatedContent = editData.content.new !== editData.content.old;
                if(isUpdatedContent) {
                    response = await new apiRequestNew({
                        action : apiUrl.DOCUMENTS.UPDATE_DOC_TEXT,
                        headers : null,
                        params : {
                            id : editData.files.files[0].new.id,
                            text : editData.content.new
                        }
                    }).execute();

                    if(!response.ok) {
                        console.log(response, await response.json());
                        errorAlert("Обновление содержания");
                        return;
                    }
                    isUpdatedOnServer = true;
                }
            }



            // удаление файлов
            for(let i = 0; i < editData.files.deleteFiles.length; i++) {
                response = await new apiRequestNew({
                    action : apiUrl.DOCUMENTS.DELETE_FILE,
                    headers : null,
                    params : {
                        id : editData.files.deleteFiles[i].id
                    }
                }).execute();

                if(!response.ok) {
                    console.log(response, await response.json());
                    errorAlert("Удаление файлов");
                    return;
                }
                isUpdatedOnServer = true;
            }


            if(!isUpdatedOnServer) {
                dispatch(setModalData({
                    name : ModalTypes.app.alert,
                    data : {content : "Нет данных для обновления", disableButton : false}
                }));
                if(Next) {Next.action(Next.params);}
            } else {
                dispatch( setModalData({
                    name: ModalTypes.app.info,
                    data: {type: "success", content: `Документ был успешно обновлен!`}
                }));

                dispatch(changeDocumentViewMode({
                    ViewMode : RootComponentsStateViewModeTypes.DocumentsApp.PreviewCards
                }));

                dispatch(createDocumentCards({
                    IdDoc : CardInfo?.IdDoc,
                    IdVersion : CardInfo.IdLastVersion,
                    IsRedactorMode : true,

                    Next : {
                        action : () => {
                            dispatch(selectActiveDocumentCard({
                                CardId : store.getState().document.cards.collection.at(-1).Id,

                                Next : {
                                    action : () => {
                                        dispatch(deleteDocumentCard({
                                            CardId : CardInfo.Id,

                                            Next : {
                                                action : () => {
                                                    dispatch(createRedactorData({CardId : store.getState().document.cards.collection.at(-1).Id}));
                                                    if(Next) {Next.action(Next.params);}
                                                },
                                                params : {}
                                            }
                                        }));
                                    },
                                    params : {}
                                }
                            }));
                        },
                        params : {}
                    }
                }));
            }
        } catch (exception) {
            console.log("Ошибка обновления версии документа в режиме редактирования (catch): ", exception);
            if(Error) {Error.action(Error.params);}
        }
    }
}
