import React from "react";
import Select from "react-select";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import {__} from "../../../tools/HelpFunctions";
import SearchableDictionariesSelect from "../../tools/MultipleAttributeTable/SearchableDictionariesSelect";

// TODO КН 19.02.24 Нужно доработать документирование (доподписать описание)
/*
    // TODO КН 19.02.24 Для компоненты указан React.memo() с помощью React.PureComponent!
    // TODO param* - обязательный атрибут
    @param attributeValue*
    @param idDictionaryValue*
    @param idDictionaries(*forRedactorMode) - (напр. item.AttributeName.IdDictionaries)
    @param dictionaryFieldName(*forRedactorMode) - (напр. item.AttributeName.DictionaryFieldName)
    @param externalDictionariesValues(*forRedactorMode) - (напр. значение из стора state.document.temporaryData.ExternalDictionariesValues)
    @param {boolean} isRedactorMode
    @param {boolean} isMultiply
    @param {boolean} hardDisabledEditing - флаг для случаев, когда нужно отобразить редакторМод view, но выключить возможность редактирования
    @param setNewValues(newValue => void) - функция из родятеля которая сетает новые значения value и idDictionaryValue куда ему нужно
 */
class AttributeWithDictionaries extends React.PureComponent {
    constructor(props) {
        super(props);

        this.onChangeSelect = this.onChangeSelect.bind(this)
    }

    onChangeSelect(selectedValue) {
        this.props.setNewValues && this.props.setNewValues({
            value: this.props.isMultiply ? selectedValue.map(el => el.label) : selectedValue.label,
            idDictionaryValue: this.props.isMultiply ? selectedValue.map(el => el.value) : selectedValue.value
        })
    }

    render() {
        const {attributeValue, idDictionaryValue, idDictionaries, dictionaryFieldName, externalDictionariesValues, isRedactorMode, isMultiply, hardDisabledEditing} = this.props

        if (isRedactorMode) {
            let valueOfSelect;
            if (isMultiply) {
                const correctIdDictionaryValue = __.getCorrectValueForMultiply(idDictionaryValue)
                if (correctIdDictionaryValue && correctIdDictionaryValue.length > 0) {
                    const correctAttributeValue = __.getCorrectValueForMultiply(attributeValue)
                    valueOfSelect = correctIdDictionaryValue.map((el, index) => ({
                        value: el,
                        label: correctAttributeValue[index]
                    }))
                } else {
                    valueOfSelect = []
                }
            } else {
                valueOfSelect ={
                    value: idDictionaryValue,
                    label: attributeValue
                }
            }

            return (
                <SearchableDictionariesSelect
                    fields={{inputValue: {label: attributeValue}, value: valueOfSelect, IsMultipleAllowed: isMultiply, AttributeName: {DictionaryFieldName: dictionaryFieldName, IdDictionaries: idDictionaries}}}
                    onChange={this.onChangeSelect}
                />
            );
            // const optionsOfSelect = !externalDictionariesValues ? [] : externalDictionariesValues
            //     .filter(value => value.IdDictionaries === idDictionaries).map(value => {
            //         const parsedValue = JSON.parse(value.Value);
            //         return {
            //             value: value.Id,
            //             label: parsedValue[dictionaryFieldName]
            //         }
            //     })
            //
            // let valueOfSelect;
            // if (isMultiply) {
            //     const correctIdDictionaryValue = __.getCorrectValueForMultiply(idDictionaryValue)
            //     if (correctIdDictionaryValue && correctIdDictionaryValue.length > 0) {
            //         const correctAttributeValue = __.getCorrectValueForMultiply(attributeValue)
            //         valueOfSelect = correctIdDictionaryValue.map((el, index) => ({
            //             value: el,
            //             label: correctAttributeValue[index]
            //         }))
            //     } else {
            //         valueOfSelect = []
            //     }
            // } else {
            //     valueOfSelect ={
            //         value: idDictionaryValue,
            //         label: attributeValue
            //     }
            // }
            //
            // return (
            //     <Select
            //         isMulti={isMultiply}
            //         className="text-primary font-weight-bold mr-4 border-0 bg-light-success "
            //         closeMenuOnSelect={true}
            //         menuPlacement="top"
            //         placeholder="Выберите значения внешнего справочника"
            //         value={valueOfSelect}
            //         onChange={this.onChangeSelect}
            //         options={optionsOfSelect}
            //         isDisabled={hardDisabledEditing}
            //         theme={(theme) => ({
            //             ...theme,
            //             colors: {
            //                 ...theme.colors,
            //                 text: 'var(--hover-primary)',
            //                 primary25: 'var(--hover-primary)',
            //                 primary50: 'var(--hover-primary)',
            //                 primary: 'var(--originaltwo)',
            //                 neutral0: 'var(--elem-bg)',
            //                 neutral20: 'var(--originaltwo)',
            //                 neutral30: 'var(--text-primary)',
            //                 neutral40: 'var(--text-primary)',
            //                 neutral50: 'var(--text-primary)',
            //                 neutral80: 'var(--text-primary)',
            //             },
            //         })}
            //     />
            // );
        } else {
            return parse(sanitizeHtml(attributeValue))
        }
    }
}

export default AttributeWithDictionaries
