


// типы пользовательских настроек
export const UserSettingsNames = {
    // настройки столбцов основной таблицы
    MAIN_TABLE_CONFIG : "mainTableConfig",
    //история поиска
    SEARCH_CONFIG : "searchConfig",
    //история документов
    DOCUMENT_CONFIG : "documentConfig",
    //смена филиала
    FILIAL_CONFIG : "filialConfig",
    // история пакетов
    PACKAGE_CONFIG : "packageConfig",
    // Вид пакетов main
    PACKAGE_TYPE_CONFIG : "packageTypeConfig",
    // Вид пакетов additional
    PACKAGE_TYPE_CONFIG_ADDITIONAL : "packageTypeConfigAdditional",
    //смена схемы
    THEME_CONFIG : "themeConfig",
    // общие настройки
    GENERAL_CONFIG: "generalConfig",
    //избранные документы
    FAVORITE_DOC_CONFIG:"favoriteDocConfig",
    //избранные пакеты
    FAVORITE_PACKAGE_CONFIG:"favoritePackageConfig",
    //избранный поисковый запрос
    FAVORITE_SEARCH_CONFIG:"favoriteSearchConfig",
    //отправленные сообщенния
    MESSENGER: 'messenger',

};

// типы действий над пользовательскими настройками
export const UserSettingsActionsTypes = {
    // обновить или создать новую
    Update : "update",
    // удалить одну запись
    DeleteOne : "deleteItem",
    // удалить все записи выбранного типа (имени)
    DeleteAll : "deleteSetting",
    // переключение увеличения области просмотра дерева
    ToggleIncreaseScope: "toggleIncreaseScope"
}

// типы вызываемых контекстных меню
export const contextMenuTypes = {
    // контекстное меню для строки и ячейки таблицы
    MainTableTR : "MainTableTR",
    MainTableTH : "MainTableTH",
    MainTablePackageTH : "MainTablePackageTH",
    MainTableTR_PackagesDocumentRight : "MainTableTR_PackagesDocumentRight",
    MainTableTH_PackagesDocumentRight : "MainTableTH_PackagesDocumentRight",
    TreePackages: "TreePackages",
    SearchTabs: "SearchTabs",
    DocRef: 'DocRef',
};

// типы дополнительных секций
export const attributesSectionsTypes = {
    // раздел со всеми атрибутами документа
    attributesSection : "attributesSection",
    // раздел с пакетами в которых содержится документ
    documentPackagesSection : "documentPackagesSection",
    // раздел с версиями документа
    versionSection : "versionSection",
    // // раздел "подразделения"
    departmentsSection : "departmentsSection",
    // раздел с содержимым документа
    contentSection : "contentSection",
    // раздел с файлами прикрепленными к документу
    filesSection : "filesSection",
    //раздел с типом документа
    docTypeSection : "docTypeSection",
};

// типы состояний корневых элементов
export const  RootComponentsStateViewModeTypes = {
    // домашняя страница
    HomeApp : {
        // ничего не показано
        None : "HomeApp | NONE",
        // окно приветствия
        Welcome : "HomeApp | WELCOME",
    },
    // страница работы с документами
    DocumentsApp : {
        // ничего не показано (первый вход -> welcome)
        None : "DocumentsApp | NONE",
        // только таблица с выбором документов
        MainTableOnly : "DocumentsApp | MAIN_TABLE_ONLY",
        // обычный предпросмотр
        PreviewNormal : "DocumentsApp | PREVIEW_NORMAL",
        // скрыть окно предпросмотра
        PreviewNormal_Hide : "DocumentsApp | PREVIEW_NORMAL | HIDE",
        // окно предпросмотра развернутое на полный экран
        PreviewNormal_PreviewFullScreen : "DocumentsApp | PREVIEW_NORMAL | PREVIEW_FULL_SCREEN",
        // окно предпросмотра в увеличенном виде
        PreviewNormal_PreviewHalfScreen : "DocumentsApp | PREVIEW_NORMAL | PREVIEW_HALF_SCREEN",
        // предпросмотр в формате карточки документа
        PreviewCards : "DocumentsApp | PREVIEW_CARDS | PREVIEW_NORMAL",
        // предпросмотр в формате карточки документа (предпросмотр в увеличенном виде)
        PreviewCards_HalfScreen : "DocumentsApp | PREVIEW_CARDS | PREVIEW_HALF_SCREEN",
        // предпросмотр в формате карточки документа (окно предпросмотра развернутое на полный экран)
        PreviewCards_FullScreen : "DocumentsApp | PREVIEW_CARDS | PREVIEW_FULL_SCREEN",
        // режим редактирования документа
        PreviewCards_RedactorMode : "DocumentsApp | PREVIEW_CARDS | REDACTOR_MODE",
        // режим редактирования документа (увеличенный предпросмотр)
        PreviewCards_RedactorMode_HalfScreen : "DocumentsApp | PREVIEW_CARDS | REDACTOR_MODE_HALF_SCREEN",
        // режим редактирования документа (предпросмотр в полный экран)
        PreviewCards_RedactorMode_FullScreen : "DocumentsApp | PREVIEW_CARDS | REDACTOR_MODE_FULL_SCREEN",
        //режим поиска
        Search : "DocumentsApp | SEARCH"
    },
    SearchApp : {
        // ничего не показано
        None : "SearchApp | NONE",
        // только таблица с выбором документов
        MainTableOnly : "SearchApp | MAIN_TABLE_ONLY",
        // обычный предпросмотр
        PreviewNormal : "SearchApp | PREVIEW_NORMAL",
        // скрыть окно предпросмотра
        PreviewNormal_Hide : "SearchApp | PREVIEW_NORMAL | HIDE",
        // окно предпросмотра развернутое на полный экран
        PreviewNormal_PreviewFullScreen : "SearchApp | PREVIEW_NORMAL | PREVIEW_FULL_SCREEN",
        // окно предпросмотра в увеличенном виде
        PreviewNormal_PreviewHalfScreen : "SearchApp | PREVIEW_NORMAL | PREVIEW_HALF_SCREEN",
        // предпросмотр в формате карточки документа
        PreviewCards : "SearchApp | PREVIEW_CARDS | PREVIEW_NORMAL",
        // предпросмотр в формате карточки документа (предпросмотр в увеличенном виде)
        PreviewCards_HalfScreen : "SearchApp | PREVIEW_CARDS | PREVIEW_HALF_SCREEN",
        // предпросмотр в формате карточки документа (окно предпросмотра развернутое на полный экран)
        PreviewCards_FullScreen : "SearchApp | PREVIEW_CARDS | PREVIEW_FULL_SCREEN",
        // режим редактирования документа
        PreviewCards_RedactorMode : "SearchApp | PREVIEW_CARDS | REDACTOR_MODE",
        // режим редактирования документа (увеличенный предпросмотр)
        PreviewCards_RedactorMode_HalfScreen : "SearchApp | PREVIEW_CARDS | REDACTOR_MODE_HALF_SCREEN",
        // режим редактирования документа (предпросмотр в полный экран)
        PreviewCards_RedactorMode_FullScreen : "SearchApp | PREVIEW_CARDS | REDACTOR_MODE_FULL_SCREEN",
    },
    // страница работы с пакетами
    PackagesApp : {
        // ничего не показано (первый вход -> welcome)
        None : "PackagesApp | NONE",
        // обычный режим с деревом
        Tree : "PackagesApp | Tree",
        // обычный режим с деревом
        Tree_Download : "PackagesApp | TREE_DOWNLOAD",
        // // для основной таблицы большие значки
        // MainTable_bigCards : "PackagesApp | MAIN_TABLE | bigCards",
        // // для основной таблицы средние значки
        // MainTable_middleCards : "PackagesApp | MAIN_TABLE | middleCards",
        // // для основной таблицы - вид таблица
        // MainTable_table : "PackagesApp | MAIN_TABLE | table",
        //
        // // для дополнительной таблицы большие значки
        // AdditionalTable_bigCards : "PackagesApp | ADDITIONAL_TABLE | bigCards",
        // // для дополнительной таблицы средние значки
        // AdditionalTable_middleCards : "PackagesApp | ADDITIONAL_TABLE | middleCards",
        // // для дополнительной таблицы - вид таблица
        // AdditionalTable_table : "PackagesApp | ADDITIONAL_TABLE | table",


        // режим отображения в пакетах - большие значки
        Table_bigCards : "PackagesApp | TABLE | bigCards",
        // режим отображения в пакетах - нормальные значки
        Table_middleCards : "PackagesApp | TABLE | middleCards",
        // режим отображения в пакетах - таблица
        Table_table : "PackagesApp | TABLE | table",

        // режимы отображения с одной таблицей и одним предпросмотром
        // обычный предпросмотр
        Table_previewNormal :  "PackagesApp | TABLE | previewNormal",
        // увеличенный предпросмотр
        Table_previewHalfScreen :  "PackagesApp | TABLE | previewHalfScreen",
        // предпросмотр  на всю ширину
        Table_previewFullScreen :  "PackagesApp | TABLE | Table_previewFullScreen",

        // поиск в пакетах
        Search : "PackagesApp | SEARCH",
        // поиск в пакетах по правой таблице
        Search_Right : "PackagesApp | SEARCH | RIGHT",
        Tree_Search : "PackagesApp | SEARCH | TREE_SEARCH",
        Tree_Search_Download : "PackagesApp | SEARCH | TREE_SEARCH_DOWNLOAD"
    }
};

// типы (имена) модальных окон
// для их удобного вызова
export const ModalTypes = {
    // относятся ко всему приложению
    app : {
        // уведомления для пользователя
        alert : "MODAL|app|Alert",
        info : "MODAL|app|Info",
        signaturesInfo : "MODAL|app|SignaturesInfo",
    },
    // модальные окна относящиеся к разделу документов
    documents : {
        // модальные окна относящиеся к таблице документов
        mainTable : {
            // модельное окно настройки столбцов таблицы документов
            Settings : "MODAL|documents|mainTable|Settings",
            ChangeDocumentType : "MODAL|documents|mainTable|ChangeDocumentType",
            NewDocumentByVersion: "MODAL|documents|mainTable|NewDocumentByVersion",
            ChangeDocumentSections : "MODAL|documents|mainTable|ChangeDocumentSections",
            ChangeDocumentTypeOriginal: "MODAL|documents|mainTable|ChangeDocumentTypeOriginal",
            ChangeDepartments: "MODAL|documents|mainTable|ChangeDepartments",
            DeleteDocument : "MODAL|documents|mainTable|DeleteDocument",
        },
        //модальные окна относящиеся к нижней таблице
        additionalSection : {
            //модельное окно загрузки документов
            DownloadDoc : "MODAL|documents|additionalSection|DownloadDoc",
            // модальное окно демонстрации измененных атрибутов и выбора варианта изменения документа
            ChangeDocAttributes : "MODAL|documents|additionalSection|ChangeDocAttributes",
            // модальное окно для отображения атрибута типа JSON-object
            AttributesTable: "MODAL|documents|additionalSection| AttributesTable",
            // модальное окно демонстрации дерева JSON-объектов
            ShowJsonTree : "MODAL|documents|additionalSection|ShowJsonTree",
            // модальное окно демонстрации JSON-объектов без типа
            ShowJsonObject : "MODAL|documents|additionalSection|ShowJsonObject",
        },
        updatePreviewFile:"MODAL|documents|updatePreviewFile",
    },
    packages : {
        crud : "MODAL|packages|crud",
        transfer: "MODAL | packages | transferPackage",
        ChangeVersionDocInPackage: "MODAL | packages | changeVersionDocInPackage"

    },
    //модальное окно относящееся к сообщениям
    messenger:{
        shareDocs:"MODAL| messenger | shareDocs",
        sendMsgLoader:"MODAL | messenger | sendMsgLoader"
    }
};

// типы пакетов
export const PackagesTreeTypes = {
    // Неизвестный тип
    Unknown : 0,
    // Пакет загрузок
    Upload : 1,
    // Пакет филиала
    Filial : 2,
    // Общий пакет
    Shared : 3,
}

// типы данных на сервере
export const DataTypes = {
    Integer : 1,
    Float : 2,
    String : 3,
    Boolean : 4,
    DataTime : 5,
    RowBlob : 6,
    SearchBlob : 7,
    DocRef : 8,
    DocVersionRef : 9,
    JsonObject : 10,
}

// размер страницы для печати
export const PrintFileFormat = {
    A4 : 1,
    A3 : 2,
    A2 : 3,
    A1 : 4,
    A0 : 5,
    A5 : 6,
    A6 : 7,
};



export const Operators = {
    /// <summary>
    /// Равно
    /// </summary>
    Equal : 0,

    /// <summary>
    /// Не равно
    /// </summary>
    NotEqual : 1,

    /// <summary>
    /// Подобно
    /// </summary>
    Like : 2,

    /// <summary>
    /// Не подобно
    /// </summary>
    NotLike : 3,

    /// <summary>
    /// Меньше или равно
    /// </summary>
    LessOrEqual : 4,

    /// <summary>
    /// Больше или равно
    /// </summary>
    GreaterOrEqual : 5,

    /// <summary>
    /// Меньше
    /// </summary>
    Less : 6,

    /// <summary>
    /// Больше
    /// </summary>
    Greater : 7,

    /// <summary>
    /// Включение
    /// </summary>
    In : 8,

    /// <summary>
    /// Исключение
    /// </summary>
    NotIn : 9,

    /// <summary>
    /// Пустое значение
    /// </summary>
    IsNull : 10,

    /// <summary>
    /// Не пустое значение
    /// </summary>
    NotNull : 11,

    /// <summary>
    /// Начинается с
    /// </summary>
    StartsWith : 12,

    /// <summary>
    /// Не начинается с
    /// </summary>
    NotStartsWith : 13,

    /// <summary>
    /// Заканчивается
    /// </summary>
    EndsWith : 14,

    /// <summary>
    /// Не заканчивается
    /// </summary>
    NotEndsWith : 15
}

export const SortDirection={
    /// <summary>
    /// Нет сортировки
    /// </summary>
    None : 0,

    /// <summary>
    /// По возрастанию
    /// </summary>
    Asc : 1,

    /// <summary>
    /// По убыванию
    /// </summary>
    Desc : 2
}

// перечисление типов фильтрации на наличие значения
export const TableFilterHasValueEnum =  {
    None: 0, // не задано
    Empty: 1, // нет значения
    NotEmpty: 2   // есть значение
}


// фильтры отображения пакетов
export const  PackagesViewFilters = {
    All : "All",
    OnlyPackages : "OnlyPackages",
    OnlyDocuments : "OnlyDocuments",
}
// Тип предпросмотра файла
export const  PackagesPreviewType = {
    Preview : "Preview",
    Original : "Original",
}

export const HtmlTags = [
    "!DOCTYPE",
    "a",
    "abbr",
    "address",
    "area",
    "article",
    "aside",
    "audio",
    "b",
    "base",
    "bdi",
    "bdo",
    "blockquote",
    "body",
    "br",
    "button",
    "canvas",
    "caption",
    "cite",
    "code",
    "col",
    "colgroup",
    "data",
    "datalist",
    "dd",
    "del",
    "details",
    "dfn",
    "dialog",
    "div",
    "dl",
    "dt",
    "em",
    "embed",
    "fieldset",
    "figcaption",
    "figure",
    "footer",
    "form",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "head",
    "header",
    "html",
    "i",
    "iframe",
    "img",
    "input",
    "ins",
    "kbd",
    "label",
    "legend",
    "li",
    "link",
    "main",
    "map",
    "mark",
    "meta",
    "meter",
    "nav",
    "noscript",
    "object",
    "ol",
    "optgroup",
    "option",
    "output",
    "p",
    "param",
    "picture",
    "pre",
    "progress",
    "q",
    "ruby",
    "rb",
    "rt",
    "rtc",
    "rp",
    "s",
    "samp",
    "script",
    "section",
    "select",
    "small",
    "source",
    "span",
    "strong",
    "style",
    "sub",
    "summary",
    "sup",
    "table",
    "tbody",
    "td",
    "template",
    "textarea",
    "tfoot",
    "th",
    "thead",
    "time",
    "title",
    "tr",
    "track",
    "u",
    "ul",
    "var",
    "video",
    "wbr",
];

// Уровень прав пользователей
export const AdminLevelEnum = {
    // Обычный пользователь
    CommonUser: 0,
    // Верификатор
    Verifier: 1,
    // Администратор
    Administrator: 2,
    // Системный администратор
    Sysadmin: 3
}

export const TableTypesEnum = {
    Main: "main",
    Additional: "additional",
    Download: "download",
    Modal: "modal",
    DocumentsRight: "documentsRight",
}

export const ThemeNamesEnum = {
    RedTheme: "red_theme",
    YellowTheme: "yellow_theme",
    LightBlueTheme: "lightBlue_theme",
    DarkBlueTheme: "darkBlue_theme",
    AzureTheme: "azure_theme",
}

// enum для Boolean значений свойств глобальных настроек
export const SettingsValueEnum = {
    False: "0",
    True: "1",
}

export const ContextMenuActionsSearchEnum = {
    ADD_TAB: "addTab",
    CLOSE_TAB: "closeTab",
    CLOSE_OTHER_TAB: "closeOtherTab",
    CLOSE_ALL_TAB: "closeAllTab",
}
export const MessageTypesEnum={
    General:"general",
    Important:'important',
}


