import React from "react";
import {connect} from "react-redux";
import {ActionQueue} from "../../../store/rootReducer";
import {
    combineMainTableAttributesSearch,
    setPaginationPageNumberSearch,
    setPaginationPageSizeSearch
} from "../../../store/search/actionCreators/search_table";
import {searchByParams} from "../../../store/search/actionCreators/search_Inputs";
import {setLoaderModalData, setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import PaginationSelect from "../../PaginationSelect";
import {store} from "../../../index";
import {RootComponentsStateViewModeTypes} from "../../../tools/StaticTypes";


class PaginationCounterSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.change = this.change.bind(this);
    }

    change(value) {
        let activeRowIndex = this.props.searchResults.mainTable.body.findIndex(item => item.info.Id === this.props.searchResults.mainTable.activeRow?.info.Id)
        if (activeRowIndex < 0) activeRowIndex = 0

        let offset = ((this.props.activePage - 1) * this.props.paginationPageSize) + activeRowIndex
        const newPage = Math.floor(offset / value) + 1
        this.props.ActionQueue({
            List : [
                {action : this.props.setLoaderModalData,
                    params : {data : {
                        content : "Загрузка...",
                        disableButton : true,
                        fullBackground : false,
                        gif : "documents",
                        key : "PaginationCounterSearch33"
                    }},
                    name : "setLoaderModalData"},
                {action : this.props.setPaginationPageSizeSearch, params : {PaginationPageSize : value}, name : "setPaginationPageSizeSearch"},
                {action : this.props.setPaginationPageNumberSearch, params : {PaginationPageNumber : newPage}, name : "setPaginationPageNumberSearch"},
                {action : this.props.searchByParams, params : {PaginationPageNumber: newPage, Id: this.props.activeTab.Id}, name : "searchByParams"},
                {action : this.props.combineMainTableAttributesSearch, params : {}, name : "combineMainTableAttributesSearch"},
                {action : this.props.setLoaderModalData, params : {keyDeleted: "PaginationCounterSearch46"}, name : "setLoaderModalData"},
            ],
            debug : false,
        });
    }

    render() {
        let {activePage, paginationPageSize, totalItemsCount} = this.props;

        let from = ((activePage - 1) * paginationPageSize) + 1;
        let to = ((Number.parseInt((activePage - 1) ) * paginationPageSize)) + Number.parseInt(paginationPageSize);

        if(to > totalItemsCount) to = totalItemsCount;

        let infoText = "...";
        if(totalItemsCount) {
            if(totalItemsCount <= 0) {
                infoText = (<span className="text-muted">Документов не обнаружено.</span>);
            }
            else {
                infoText = (<span className="text-muted">Показано {from} - {to} из {totalItemsCount}</span>);
            }
        }

        return (
            <PaginationSelect activePage={activePage}
                              infoText={infoText}
                              paginationPageSize={paginationPageSize}
                              change={this.change}
            />
        );
    }
}

const  mapStateToProps = state => {
    const tableIndex =
        state.search.searchState.searchResults
            .findIndex(item => item.Id === state.search.rootState.activeTab.Id);

    return {
        activeTab: state.search.rootState.activeTab,
        searchResults: state.search.searchState.searchResults[tableIndex],
        paginationPageSize : state.search.searchState.searchResults[tableIndex].mainTable.paginationPageSize
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    combineMainTableAttributesSearch,
    searchByParams,
    setLoaderModalData,
    setModalData,
    setPaginationPageSizeSearch,
    setPaginationPageNumberSearch,
}

export default connect(mapStateToProps,mapDispatchToProps)(PaginationCounterSearch);
