import {MESSENGER_ACTION, MESSENGER_ROOT_STATE_ACTIONS} from "./messengerActionList";
import produce from "immer";
import {RootComponentsStateViewModeTypes} from "../../tools/StaticTypes";

// состояние всего корневого компонента
const rootState = {
    // режим вида отображения компонента
    // какие компоненты в каком виде будут отображены
    viewMode: RootComponentsStateViewModeTypes.DocumentsApp.None,
    // открыто/закрыто asideMenu
    isOpenAsideMenu: true,
};

const message = {
    activeReceiver: null,
    myBuddies: [],
    readMsgs: null,
    notReadMsgNumber: 0,
}

const initialState = {
    rootState,
    message,
}

export const messengerReducer = (state = initialState, action) => {
    return produce(state, newState => {
        // rootState
        if (action.type === MESSENGER_ROOT_STATE_ACTIONS.TOGGLE_SHOW_ASIDE_MENU) {
            newState.rootState.isOpenAsideMenu = action.payload;
        }
        //message
        else if (action.type === MESSENGER_ACTION.MAKE_RECEIVER_ACTIVE) {
            // state.message.activeReceiver.splice(0,state.message.activeReceiver.length)
            newState.message.activeReceiver = action.payload
        } else if (action.type === MESSENGER_ACTION.ADD_TO_BUDDIES) {
            newState.message.myBuddies.push(action.payload.item)
        } else if (action.type === MESSENGER_ACTION.READ_MESSAGE) {

        } else if (action.type === MESSENGER_ACTION.SET_READ_MSGS) {
            if (!state.message.readMsgs) newState.message.readMsgs = []
            let userId = state.message.readMsgs && Array.from(state.message.readMsgs)?.map(item => item.User.Id)

            if (!state.message.readMsgs) {
                newState.message.readMsgs = action.payload
            } else if (userId?.includes(action.payload.User.Id)) {
                for (let i = 0; i < newState.message.readMsgs.length; i++) {
                    if (newState.message.readMsgs[i].User.Id === action.payload.User.Id)
                        newState.message.readMsgs[i].MsgAmount = action.payload.MsgAmount
                }
            } else {
                // newState.message.readMsgs.push(action.payload)
                newState.message.readMsgs = action.payload
            }
        } else if (action.type === MESSENGER_ACTION.SET_NUMBER_NEW_MSG) {
            newState.message.notReadMsgNumber = action.payload.Number
        }
    })
}
