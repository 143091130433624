import React from "react";
import {connect} from "react-redux";
import {ModalTypes} from "../../../tools/StaticTypes";
import {setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {HelpFunctions} from "../../../tools/HelpFunctions";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import {
    collectDataForAdditionalSections_Normal,
    createPreviewBySelectDocument,
    createRedactorData,
    editDocCurrentVersion, resetDocumentPreview
} from "../../../store/documents/actionCreators/document_PreviewActionCreator";
import {
    combineMainTableAttributes,
    mainTableDownloadDocCollection, mainTableSearchDoc,
    selectActiveRow
} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {
    breadcrumbsAdd, breadcrumbsMoveSelect,
    PackageAddNodesTree, SelectActivePackageDocument,
    SelectActivePackageNode, togglePackagesFolders
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {searchByParams} from "../../../store/search/actionCreators/search_Inputs";
import {
    combineMainTableAttributesSearch,
    selectActiveRowSearch
} from "../../../store/search/actionCreators/search_table";
import {
    collectDataForAdditionalSectionsSearch_Normal,
    createPreviewBySelectDocumentSearch,
    resetDocumentPreviewSearch
} from "../../../store/search/actionCreators/search_PreviewActionCreator";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import {store} from "../../../index";

class ChangeAttributes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contentOnHover : false,
        };

        this.modalName = ModalTypes.documents.mainTable.ChangeDocAttributes;


        this.hideOutSide = this.hideOutSide.bind(this);
        this.hide = this.hide.bind(this);
        this.updateCurrentDocVersion = this.updateCurrentDocVersion.bind(this);
        this.createNewDocVersion = this.createNewDocVersion.bind(this);
        this.updatePackageTable = this.updatePackageTable.bind(this);
        this.updateDocTable = this.updateDocTable.bind(this);
    }

    // скрывать при клике вне окна
    hideOutSide() {
        if(!this.state.contentOnHover) {

            this.props.setModalData({});
        }
    }

    // скрывать по кнопке
    hide() {

        this.props.setModalData({});
    }

    async updateDocTable() {
        const { activeRow, activeNode } = this.props;

        // обновление таблицы документов
        //TODO aa 13.12.2023 убрал, т.к. загрузка без учета фильтров это некорректно
        if (!store.getState().document.search.isSearch)
          await this.props.mainTableDownloadDocCollection({ActiveNode: activeNode});
        //TODO aa 13.12.2023, после обновления отфильтрованной таблицы фильтр слетает, нужно было добавить скрипт для фильтров
        await this.props.mainTableSearchDoc({PaginationPageNumber: 1})
        await this.props.combineMainTableAttributes({ActiveNode: activeNode})
        await this.props.selectActiveRow({IdDoc: activeRow.Info.Id});
        await this.props.resetDocumentPreview({});
        await this.props.createPreviewBySelectDocument({});
        await this.props.collectDataForAdditionalSections_Normal({});
    }

    // обновление таблицы пакетов
    async updatePackageTable(IdDoc) {
        const {  activeRowPack, activeNodePack, activeRowAdditional, activeRowDownload } = this.props;
        let tableType, activeDoc, isDownLoad = false;
        if(activeRowPack === IdDoc) {
            tableType = "main";
            activeDoc = activeRowPack;
        } else if(IdDoc === activeRowAdditional) {
            tableType = "additional";
            activeDoc = activeRowAdditional;
        } else if(IdDoc === activeRowDownload) {
            tableType = "download";
            isDownLoad = true;
            activeDoc = activeRowDownload;
        }

        await this.props.PackageAddNodesTree({
            Id : activeNodePack.Node.Id,
            TableType : tableType,
            IsDownload : isDownLoad
        });
        await this.props.SelectActivePackageNode({
            Id : activeNodePack.Node.Id,
            TableType : tableType,
            IsDownload : isDownLoad
        });
        await this.props.togglePackagesFolders({
            IdFolder : activeNodePack.Node.Id,
            TableType : tableType,
            IsDownload : isDownLoad,
        });

        await this.props.breadcrumbsAdd({
            NodeId : activeNodePack.Node.Id,
            Name : activeNodePack.Node.Name,
            ParentId : activeNodePack.Node.IdParent,
            TableType : tableType,
            IsDownload : isDownLoad,
        });
        await this.props.breadcrumbsMoveSelect({
            NodeId: activeNodePack.Node.Id,
            TableType : tableType,
            IsDownload : isDownLoad,
        });
        await this.props.SelectActivePackageDocument({Id: activeDoc, TableType: tableType});
    }

    // обновление таблицы поиска
    async updateSearchTable() {
        const {  activeRowSearch } = this.props;
        await this.props.searchByParams({});
        await this.props.combineMainTableAttributesSearch({});
        await this.props.selectActiveRowSearch({ RowInfo : activeRowSearch});
        await this.props.resetDocumentPreviewSearch({});
        await this.props.createPreviewBySelectDocumentSearch({});
        await this.props.collectDataForAdditionalSectionsSearch_Normal({});
    }

    async updateCurrentDocVersion(cardData) {
        const {  activeRowPack, activeRow, activeRowSearch, activeRowAdditional, activeRowDownload } = this.props;
        await this.props.editDocCurrentVersion({
            CardInfo: this.props.cards.collection.find(card => card.Id === this.props.cards.activeCardId),
            editData: this.props.newData,
            Next: {
                action: () => {
                    this.props.modal.data.redactorDataOff();
                    this.props.createRedactorData({CardId: null});
                },
                params: {}
            }
        });

        //TODO aa 13.12.2023, после обновления отфильтрованной таблицы фильтр слетает, нужно добавить скрипт для фильтров
        if(activeRow?.Info.Id === cardData.IdDoc) {
           await this.updateDocTable();
        }

        if(activeRowPack === cardData.IdDoc ||
            activeRowAdditional === cardData.IdDoc ||
            activeRowDownload === cardData.IdDoc) {
            await this.updatePackageTable(cardData.IdDoc);
        }

        if(activeRowSearch?.info.Id === cardData.IdDoc) {
            await this.updateSearchTable();
        }

        // this.props.setModalData({
        //     name : ModalTypes.app.alert,
        //     data : {content : "Документ был успешно обновлен!", disableButton : false}
        // });
}

    createNewDocVersion() {
    //    alert("Создание новой версии");
    }

    render() {

        let newData = this.props.newData;
        let cardData = this.props.cards.collection
            .find(card => card.Id === this.props.cards.activeCardId);


        let modalStyle = {
            display : "block",
            backgroundColor: "rgba(6,28,62,0.4)",
        };

        return (
            <div className="modal fade show" style={modalStyle} onClick={this.hideOutSide}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
                    <div className="modal-content"
                         onMouseEnter={()=>{this.setState({contentOnHover : true})}}
                         onMouseLeave={()=>{this.setState({contentOnHover : false})}}>

                        <div className="modal-header" style={{padding: "1rem 1rem", borderBottom: "none"}}>
                            <h5 className="modal-title text-success font-weight-bolder ">
                                Подтвердите изменение Документа "{HelpFunctions.cutLongString(cardData.name, 50, true)}"
                            </h5>
                        </div>
                        <div className="modal-body beautiful-scroll-10" style={{height: "30vw", padding:" 0.8rem 1.75rem"}}>

                            <div className="row">


                                <div className="col-lg-6">
                                    <p><span className="label label-inline label-light-success font-weight-bold">Текущие Атрибуты</span></p>
                                    <div className="table-responsive">
                                        <table
                                            className="table table-hover table-striped table-borderless table-vertical-center">
                                            <thead className="thead-light">
                                            <tr className="text-uppercase">
                                                <th style={{borderRadius: "1em 0 0 0"}}><p
                                                    className="text-success m-0 font-weight-normal">Название</p></th>
                                                <th style={{borderRadius: "0 1em  0 0"}}><p
                                                    className="text-success m-0 font-weight-normal">Значение</p></th>
                                            </tr>
                                            </thead>
                                            <tbody style={{fontSize: "0.75em"}}>
                                            {
                                                newData.attributes.map((item, index)=>{

                                                    let lValue = item.old.Value;
                                                    if(item.old.AttributeName === "RegDate" || item.old.AttributeName === "Version.ModifyTime") {
                                                        lValue = item.old.Value.split("T")[0].split("-").reverse().join(".");
                                                    } else if (item.old.AttributeName.IdDictionaries) {
                                                        if (item.isUpdated) {
                                                            lValue = item.old.Value;
                                                        } else {
                                                            lValue = item.Value
                                                        }
                                                    }

                                                    return (
                                                        <tr key={createUniqueIdString(6)}>
                                                            <td>{item.old.ColumnName}</td>
                                                            <td>{parse(sanitizeHtml(lValue))}</td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>

                                    <p><span className="label label-inline label-light-success font-weight-bold">Текущий тип документа</span></p>
                                    <input type="text" disabled={true}
                                           className="form-control form-control-lg h-40px mb-4"
                                           value={newData.docType.old.TypeName}
                                    />

                                    <p><span className="label label-inline label-light-success font-weight-bold">Текущее Описание</span></p>
                                    <textarea className="form-control form-control-solid beautiful-scroll-10" disabled={true}
                                              rows="4" spellCheck="false" defaultValue={newData.content.old}/>

                                    <p className="pt-4">
                                        <span className="label label-inline label-light-success font-weight-bold pt-2">
                                            Текущие Файлы
                                        </span>
                                    </p>
                                    <div className="table-responsive">
                                        <table
                                            className="table table-hover table-striped table-borderless table-vertical-center">
                                            <thead className="thead-light">
                                            <tr className="text-uppercase">
                                                <th style={{borderRadius: "1em 0 0 0"}}><p
                                                    className="text-success m-0 font-weight-normal">Наименование</p></th>
                                                <th style={{borderRadius: "0 0em  0 0"}}><p
                                                    className="text-success m-0 font-weight-normal">Описание</p></th>
                                                <th style={{borderRadius: "0 0em  0 0"}}><p
                                                    className="text-success m-0 font-weight-normal">Размер</p></th>
                                            </tr>
                                            </thead>
                                            <tbody style={{fontSize: "0.75em"}}>
                                            {
                                                newData.files.files.map((item, index)=>{
                                                    return(
                                                        <tr key={createUniqueIdString(6)}>
                                                            {
                                                                item.old.AttributeName.Id === 3 &&
                                                                <td style={{display: 'flex', alignItems: 'center'}}>
                                                                    <span className="btn btn-icon btn-xs"
                                                                          // style={{ position: "absolute", left: "5px",  bottom: "5px"}}
                                                                    >
                                                                        <i className="svg-icon icon-Doc_view_10 icon-color-secondary"/>
                                                                    </span>
                                                                    {item.old.name}
                                                                </td>
                                                            }
                                                            {
                                                                item.old.AttributeName.Id !== 3 &&
                                                                <td>{item.old.name}</td>
                                                            }
                                                            <td>{item.old.description}</td>
                                                            <td>{HelpFunctions.humanFileSize(item.old.size, true)}</td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>


                                </div>

                                <div className="col-lg-6">
                                    <p>
                                        <span className="label label-inline label-light-primary font-weight-bold">
                                            Новые Атрибуты
                                        </span>
                                    </p>
                                    <div className="table-responsive">
                                        <table
                                            className="table table-hover table-striped table-borderless table-vertical-center">
                                            <thead className="thead-light">
                                            <tr className="text-uppercase">
                                                <th style={{borderRadius: "1em 0 0 0"}}><p
                                                    className="text-success m-0 font-weight-normal">Атрибут</p></th>
                                                <th style={{borderRadius: "0 1em  0 0"}}><p
                                                    className="text-success m-0 font-weight-normal">Значение</p></th>
                                            </tr>
                                            </thead>
                                            <tbody style={{fontSize : "0.75em"}}>
                                            {
                                                newData.attributes.map((item, index)=>{

                                                    let lValue = item.new.Value;
                                                    if(item.new.AttributeName === "RegDate" || item.new.AttributeName === "Version.ModifyTime") {
                                                        lValue = item.new.Value.split("T")[0].split("-").reverse().join(".");
                                                    } else if (item.new.AttributeName.IdDictionaries) {
                                                        if (item.isUpdated) {
                                                            lValue = item.new.Value;
                                                        } else {
                                                            lValue = item.Value
                                                        }
                                                    }

                                                        if(item.isUpdated) {
                                                            return (
                                                                <tr key={createUniqueIdString(6)} style={{backgroundColor: "rgba(255,210,122,0.33)"}}>
                                                                    <td>{item.new.ColumnName}</td>
                                                                    <td>{parse(sanitizeHtml(lValue))}</td>
                                                                </tr>
                                                            );
                                                        } else {
                                                            return (
                                                                <tr key={createUniqueIdString(6)}>
                                                                    <td>{item.new.ColumnName}</td>
                                                                    <td>{parse(sanitizeHtml(lValue))}</td>
                                                                </tr>
                                                            );
                                                        }
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>


                                    <p><span className="label label-inline label-light-success font-weight-bold">Новый тип документа</span></p>
                                    <input type="text" disabled={true}
                                           style={newData.docType.isUpdated ? {backgroundColor: "rgba(255,210,122,0.33)"} : {}}
                                           className="form-control form-control-lg h-40px mb-4"
                                           value={newData.docType.new.TypeName}
                                    />


                                    <p>
                                        <span className="label label-inline label-light-primary font-weight-bold">
                                            Новое Описание
                                        </span>
                                    </p>
                                    {
                                        newData.content.isUpdated &&
                                        <textarea className="form-control form-control-solid beautiful-scroll-10"
                                                  style={{backgroundColor: "rgba(255,210,122,0.33)"}}
                                                  defaultValue={newData.content.new}
                                                  rows="4" spellCheck="false"/>
                                    }
                                    {
                                        !newData.content.isUpdated &&
                                        <textarea className="form-control form-control-solid beautiful-scroll-10"
                                                  disabled={true}
                                                  defaultValue={newData.content.new}
                                                  rows="4" spellCheck="false"/>
                                    }

                                    <p className="pt-4">
                                        <span className="label label-inline label-light-primary font-weight-bold">
                                            Новые Файлы
                                        </span>
                                    </p>
                                    <div className="table-responsive">
                                        <table
                                            className="table table-hover table-striped table-borderless table-vertical-center">
                                            <thead className="thead-light">
                                            <tr className="text-uppercase">
                                                <th style={{borderRadius: "1em 0 0 0"}}>
                                                    <p className="text-success m-0 font-weight-normal">Наименование</p>
                                                </th>
                                                <th style={{borderRadius: "0 0em  0 0"}}>
                                                    <p className="text-success m-0 font-weight-normal">Описание</p>
                                                </th>
                                                <th style={{borderRadius: "0 0em  0 0"}}>
                                                    <p className="text-success m-0 font-weight-normal">Размер</p>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody style={{fontSize: "0.75em"}}>
                                            {
                                                newData.files.files.map((item, index)=>{

                                                    let isDeleted = false;
                                                    if(newData.files.deleteFiles.find(file => file.id === item.new.id)) {
                                                        isDeleted = true;
                                                    }

                                                    if(isDeleted) {
                                                        return(
                                                            <tr key={createUniqueIdString(6)} style={{backgroundColor: "rgba(252,205,210,0.33)"}}>
                                                                <td>{item.new.name}</td>
                                                                <td>{item.new.description}</td>
                                                                <td>{HelpFunctions.humanFileSize(item.old.size, true)}</td>
                                                            </tr>
                                                        );
                                                    }
                                                    else if(item.isUpdated) {
                                                       return(
                                                            <tr key={createUniqueIdString(6)} style={{backgroundColor: "rgba(255,210,122,0.33)"}}>
                                                                {
                                                                    item.new.id === newData.files.idMainFile &&
                                                                    <td style={{display: 'flex', alignItems: 'center'}}>

                                                                        <span className="btn btn-icon btn-xs"
                                                                              // style={{ position: "absolute", left: "5px",  bottom: "5px"}}
                                                                            >
                                                                            <i className="svg-icon icon-Doc_view_10 icon-color-secondary"/>
                                                                        </span>
                                                                        {item.new.name}
                                                                    </td>
                                                                }
                                                                {
                                                                    item.new.id !== newData.files.idMainFile &&
                                                                    <td>{item.new.name}</td>
                                                                }
                                                                <td>{item.new.description}</td>
                                                                <td>{HelpFunctions.humanFileSize(item.new.size, true)}</td>
                                                            </tr>
                                                       );
                                                   } else {
                                                       return(
                                                           <tr key={createUniqueIdString(6)}>
                                                               {
                                                                   item.new.id === newData.files.idMainFile &&
                                                                   <td style={{display: 'flex', alignItems: 'center'}}>

                                                                       <span className="btn btn-icon btn-xs"
                                                                             // style={{ position: "absolute", left: "5px",  bottom: "5px"}}
                                                                           >
                                                                        <i className="svg-icon icon-Doc_view_10 icon-color-secondary"/>
                                                                    </span>
                                                                       {item.new.name}
                                                                   </td>
                                                               }
                                                               {
                                                                   item.new.id !== newData.files.idMainFile &&
                                                                   <td>{item.new.name}</td>
                                                               }
                                                               <td>{item.new.description}</td>
                                                               <td>{HelpFunctions.humanFileSize(item.new.size, true)}</td>
                                                           </tr>
                                                       );
                                                   }
                                                })
                                            }
                                            {
                                                newData.files.newFiles.map((item, index)=>{
                                                    return(
                                                        <tr key={item.id} style={{backgroundColor: "rgba(0,189,151,0.33)"}}>
                                                            <td>{item.name}</td>
                                                            <td>{item.description}</td>
                                                            <td>{HelpFunctions.humanFileSize(item.file.size, true)}</td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>


                                </div>
                            </div>

                        </div>
                        <div className="modal-footer" style={{padding: "1rem 1rem", borderTop: "none"}}>

                           <div>
                               <button type="button" className="btn btn-primary font-weight-bolder"
                                       style={{marginRight : "15px"}}
                                       onClick={() => this.updateCurrentDocVersion(cardData)}>
                                   Изменить текущую версию
                               </button>
                               {/*<button type="button" disabled={true} className="btn btn-primary font-weight-bolder"*/}
                               {/*        onClick={this.createNewDocVersion}>*/}
                               {/*    Создать новую версию*/}
                               {/*</button>*/}
                           </div>
                            <button type="button" className="btn btn-light font-weight-bold" onClick={this.hide}>
                                Отменить действие
                            </button>

                        </div>
                    </div>
                </div>
            </div>

    );
    }
}


const  mapStateToProps = state => {
    return {
        newData: state.document.cards.redactorDataCollected,
        cards: state.document.cards,

        activeNode: state.document.tree.activeNode,
        activeNodePack : state.packages.tree.activeNode,


        activeRow: state.document.mainTable.activeRow,
        activeRowPack: state.packages.tree.activeRow,
        activeRowAdditional : state.packages.treeAdditional.activeRow,
        activeRowDownload : state.packages.treeDownload.activeRow,
        activeRowSearch: state.search.mainTable.activeRow,

    }
}

const  mapDispatchToProps = {
    setModalData,
    editDocCurrentVersion,
    createRedactorData,
    mainTableDownloadDocCollection,
    combineMainTableAttributes,
    selectActiveRow,
    resetDocumentPreview,
    createPreviewBySelectDocument,
    collectDataForAdditionalSections_Normal,

    PackageAddNodesTree,
    SelectActivePackageNode,
    togglePackagesFolders,
    breadcrumbsAdd,
    breadcrumbsMoveSelect,
    SelectActivePackageDocument,

    searchByParams,
    combineMainTableAttributesSearch,
    selectActiveRowSearch,
    resetDocumentPreviewSearch,
    createPreviewBySelectDocumentSearch,
    collectDataForAdditionalSectionsSearch_Normal,
    mainTableSearchDoc
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeAttributes);
