import React from "react";
import {connect} from "react-redux";
import parse from "html-react-parser";
import {RootComponentsStateViewModeTypes} from "../../../tools/StaticTypes";
import {history} from "../../../index";
import {searchByParams, updateInputsFieldSearch} from "../../../store/search/actionCreators/search_Inputs";
import {combineMainTableAttributesSearch} from "../../../store/search/actionCreators/search_table";
import {changeSearchViewMode} from "../../../store/search/actionCreators/search_RootStateActionCreator";
import sanitizeHtml from "sanitize-html";
import {__} from "../../../tools/HelpFunctions";
import {DataTypesEnum} from "../../../tools/API_NEW/ServerTypes";
import {setVersionIdForLocate} from "../../../store/masterApp/actionCreator/masterAppActionCreator";
import {setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import DocRef from "../docRefsComponents/DocRef";
import DocVersionRef from "../docRefsComponents/DocVersionRef";
import MultipleDocVersionRef from "../docRefsComponents/MultipleDocVersionRef";
import MultipleDocRef from "../docRefsComponents/MultipleDocRef";
import JsonObjectFragment from "../../root/JsonObjectFragment";

const getIdAttributeTypeByItem = (item) => {
    return item.IdAttributeType || item.AttributeName.IdAttributeType
}

class AttributesSectionPackages extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            isOpen : true,
        };

        this.toggle = this.toggle.bind(this);
        this.search = this.search.bind(this);

        this.renderJsonObjectFragment = this.renderJsonObjectFragment.bind(this);
    }

    // открытье\закрытие раздела
    toggle() {
        this.setState({
            isOpen : !this.state.isOpen
        });
    }

    search(value) {
        const idSearch = __.createUniqueIdString(6);
        //TODO SS временно
        if (Array.isArray(value)){
            value = value.map(el=>`"${el.split('"').join('').split("'").join('')}"`).join(',')
        } else if (typeof value !== 'string') {
            return;
        } else {
            value = `"${value.split('"').join('').split("'").join('')}"`
        }

        this.props.updateInputsFieldSearch({
            AttributesText : value,
            TypeIds: [],
            Id: idSearch,
            Next : {
                action : () => {
                    this.props.searchByParams({
                        Id: idSearch,
                        Next:{
                            action : () => {
                                this.props.combineMainTableAttributesSearch({
                                    Next : {
                                        action : () => {
                                            this.props.changeSearchViewMode({
                                                ViewMode : RootComponentsStateViewModeTypes.SearchApp.MainTableOnly
                                            });
                                            history.replace("/search");
                                        },
                                        params : {}
                                    }
                                })
                            },
                            params : {}
                        }
                    })
                },
                params : {}
            }
        });
    }

    renderJsonObjectFragment(item, rowId, IsMultipleAllowed) {
        let lInputValue = item.Value ?? item.AttributeValue
        if (rowId) {
            if (item.Value && item.Value.length > 0 && (rowId - 1) >= 0 && (rowId - 1) < item.Value.length) {
                lInputValue = item.Value[rowId - 1];
            } else {
                lInputValue = null;
            }
        }
        return <JsonObjectFragment lInputValue={lInputValue}
                                   lAllInputValue={item.Value}
                                   isRedactorMode={false}
                                   rowId={rowId}
                                   item={item}
                                   IsMultipleAllowed
                                    // inputHandler={(value, item) => this.editHandler(IsMultipleAllowed ? {value} : value, item, IsMultipleAllowed)}
        />
    }

    render() {
        if(!this.props.activeDocument) return null;
        let isReady = this.props.activeDocument.additionalSections.isReady;
        let sectionData = this.props.activeDocument.additionalSections.attributesSection.attributes.Records;
        let cardTitleClass = "card-title";
        let cardCollapseClass = "collapse show beautiful-scroll-10";
        if(!this.state.isOpen) {
            cardTitleClass = "card-title collapsed";
            cardCollapseClass = "collapse";
        }

        sectionData = __.convertAttributesArrayToUnique(sectionData, 'AttributeValue')

        return (
            <div className="card">
                <div className="card-header" onClick={this.toggle}>
                    <div className={cardTitleClass}>
                        Атрибуты документа
                    </div>
                </div>
                <div className={cardCollapseClass}>
                    <div className="card-body p-0">


                        <table className="table table-bordered table-striped m-0">
                            <thead>
                            <tr>
                                <th className="col-lg-4">Атрибут</th>
                                <th className="col-lg-7">Значение</th>
                                <th className="col-lg-1">Найти</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                isReady &&
                                sectionData.map((item, index) => {
                                    const IsMultipleAllowed = item.IsMultipleAllowed
                                    const valueForSanitize = (item.AttributeValue === "null") ? null : item.AttributeValue;
                                    let secondTdContent= parse(sanitizeHtml(valueForSanitize));
                                    const attributeType = getIdAttributeTypeByItem(item)
                                    if (attributeType === DataTypesEnum.JsonObject){
                                        const correctValue = !IsMultipleAllowed ? item.AttributeValue : __.getCorrectValueForMultiply(item.AttributeValue)
                                        secondTdContent = (IsMultipleAllowed && correctValue) ?
                                            <div className="d-flex flex-column beautiful-scroll-10"
                                                 style={{
                                                     maxHeight: '5rem',
                                                     paddingRight: correctValue.length > 2 ? '0.75rem' : 0
                                                 }}
                                            >
                                                {correctValue.map((el, lIndex) =>
                                                    <div className={`${lIndex !== correctValue.length-1 ? 'mb-3' : ''}`}>
                                                        {this.renderJsonObjectFragment({
                                                            ...item,
                                                            Value: el
                                                        }, null, IsMultipleAllowed)}
                                                    </div>
                                                )}
                                            </div>
                                            :  this.renderJsonObjectFragment({...item, Value: item.AttributeValue}, null, IsMultipleAllowed)
                                    } else if (attributeType === DataTypesEnum.DocRef) {
                                        secondTdContent = IsMultipleAllowed ?
                                            <MultipleDocRef
                                                item={item}
                                                valueFieldName={'AttributeValue'}
                                                isRedactorMode={false}
                                            />
                                            : <DocRef
                                                docIdValue={item.AttributeValue}
                                                shortDescription={item.ShortDescription}
                                                isRedactorMode={false}
                                            />
                                    } else if (attributeType === DataTypesEnum.DocVersionRef) {
                                        secondTdContent = IsMultipleAllowed ?
                                            <MultipleDocVersionRef
                                                item={item}
                                                valueFieldName={'AttributeValue'}
                                                isRedactorMode={false}
                                            />
                                            : <DocVersionRef
                                                versionIdValue={item.AttributeValue}
                                                shortDescription={item.ShortDescription}
                                                isRedactorMode={false}
                                            />
                                    }
                                    return (
                                        <tr key={item.Id}>
                                            <td>{item.AttributeName.Name}</td>
                                            <td>{secondTdContent}</td>
                                            <td>
                                                {
                                                    (
                                                        item.AttributeValue !== "null"
                                                        && item.AttributeValue !== null
                                                        && item.AttributeValue !== ''
                                                        && attributeType !== DataTypesEnum.JsonObject
                                                        && attributeType !== DataTypesEnum.DocRef
                                                        && attributeType !== DataTypesEnum.DocVersionRef
                                                    ) &&
                                                    <span className="btn btn-icon btn-outline-success btn-circle btn-xs"
                                                          onClick={()=>{this.search(item.AttributeValue)}}>
                                                        <i className="svg-icon icon-Search"/>
                                                    </span>
                                                }
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        activeDocument : state.packages.tree.activeDocument
    }
}

const  mapDispatchToProps = {
    changeSearchViewMode,
    combineMainTableAttributesSearch,
    searchByParams,
    updateInputsFieldSearch,
    setVersionIdForLocate,
    setModalData
}

export default connect(mapStateToProps, mapDispatchToProps)(AttributesSectionPackages);
