import React from "react";
import {__} from "../../../tools/HelpFunctions";
import MultipleAttributeTable from "../../tools/MultipleAttributeTable/MultipleAttributeTable";
import DocRef from "./DocRef";

/*
    // TODO КН 15.02.24 Для компоненты указан React.memo() с помощью React.PureComponent!
    // TODO param* - обязательный атрибут
    @param item - данные атрибута
    @param {string} valueFieldName Напр. 'Value' || 'inputValue' || 'AttributeValue'
    @param {string} viewMode
    @param* {boolean} isRedactorMode
    @param {boolean} hardDisabledEditing - disable редактирования при isRedactorMode
    @param(*forRedactorMode) setNewItemValue(newValueOfItem=>void)
    @param(*forRedactorMode) duplicateCallback(()=>void) - функция, которая должна быть вызвана когда нашелся дубликат
 */
class MultipleDocRef extends React.PureComponent  {
    constructor(props) {
        super(props);
        this.deleteMultiplyRowHandler = this.deleteMultiplyRowHandler.bind(this);
        this.addMultiplyRowHandler = this.addMultiplyRowHandler.bind(this);
    }

    deleteMultiplyRowHandler(lIndex, item) {
        const value = Array.isArray(item?.Value) ? item?.Value : [item?.Value]
        const newValue = [...value.slice(0, lIndex), ...value.slice(lIndex + 1, value.length)];
        newValue && this.props.setNewItemValue && this.props.setNewItemValue(newValue)
    }

    addMultiplyRowHandler(lIndex, item) {
        // TODO КН 07.02.24 нужно именно Null сетать изза внутренностей компонент <DocVersionRef> (а именно из-за того что внутри него пустая строка иногда сетается)
        // TODO КН 15.02.24 Мб раз выделил в отдельную компоненту MultipleDocRef, то можно и не null, но лучше наверно, чтобы вездле было одинаково
        let newValue;
        const currentValue = Array.isArray(item?.Value) ? item?.Value : [item?.Value]
        if (currentValue && currentValue.length > 0) {
            if (!((lIndex - 1 >= 0) && (lIndex - 1 < currentValue.length))) {
                const copy = __.deepCopy(currentValue)
                copy.splice(lIndex - 1, 0, null);
                newValue = copy
            }
        } else {
            newValue = [null]
        }
        newValue && this.props.setNewItemValue && this.props.setNewItemValue(newValue)
    }

    render() {
        const {item, isRedactorMode, viewMode, valueFieldName} = this.props
        const originalValue = item?.[valueFieldName]
        const correctValue = __.getCorrectValueForMultiply(originalValue)
        if (isRedactorMode) {
            const adjustedItem = {...item, Value: correctValue}
            return (
                <MultipleAttributeTable
                    hardDisabledEditing={this.props.hardDisabledEditing}
                    deleteRow={(lIndex) => this.deleteMultiplyRowHandler(lIndex, adjustedItem)}
                    addRow={(lIndex) => this.addMultiplyRowHandler(lIndex, adjustedItem)}
                    item={adjustedItem}
                    contents={(lItem, rowId) => {
                        let lValue = lItem?.Value;
                        let lShortDescription = lItem?.ShortDescription
                        if (rowId) {
                            lValue = lItem?.Value?.[rowId - 1];
                            lShortDescription = lItem?.ShortDescription?.[rowId - 1]
                        }
                        return [
                            {
                                style: {width: '100%'},
                                content: (
                                    <DocRef
                                        hardDisabledEditing={this.props.hardDisabledEditing}
                                        viewMode={viewMode}
                                        docIdValue={lValue}
                                        isRedactorMode={true}
                                        shortDescription={lShortDescription}
                                        setCurrentDocIdValue={(value)=> {
                                            const newElementIsDuplicate = (value !== '' && value !== lItem?.Value?.[rowId]) && lItem?.Value?.some(el => el?.toString() === value?.toString())
                                            let newEl = newElementIsDuplicate ? '' : value?.toString()
                                            const newValue = [...(lItem?.Value ?? []).slice(0, rowId - 1), newEl, ...(lItem?.Value ?? []).slice(rowId)]
                                            this.props.setNewItemValue && this.props.setNewItemValue(newValue)
                                            newElementIsDuplicate && this.props.duplicateCallback && this.props.duplicateCallback()
                                        }}
                                    />
                                ),
                            },
                        ];
                    }}
                />
            )
        } else  {
            if (correctValue) {
                return (
                    <div className="d-flex flex-column beautiful-scroll-10"
                         style={{
                             maxHeight: '5rem',
                             paddingRight: correctValue.length > 1 ? '0.75rem' : 0
                         }}
                    >
                        {correctValue.map((el, index) =>
                            <div className={`${index !== correctValue.length - 1 ? 'mb-3' : ''}`}>
                                <DocRef
                                    viewMode={viewMode}
                                    docIdValue={el}
                                    shortDescription={Array.isArray(item.ShortDescription) ? item.ShortDescription[index]: item.ShortDescription}
                                    isRedactorMode={false}
                                />
                            </div>
                        )}
                    </div>
                )
            } else {
                return (
                    <DocRef
                        viewMode={viewMode}
                        shortDescription={Array.isArray(item.ShortDescription) ? item.ShortDescription[0]: item.ShortDescription}
                        docIdValue={correctValue}
                        isRedactorMode={false}
                    />
                )
            }
        }
    }
}

export default MultipleDocRef
