import React from "react";
import {connect} from "react-redux";
import MainTableSearchPackage from "./MainTableSearchPackage";

import {
    breadcrumbsAdd, breadcrumbsMoveSelect, breadcrumbsSetPage,
    PackageAddNodesTree, SelectActivePackageDocument,
    SelectActivePackageNode, togglePackagesFolders
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import PackagesTableItem from "../../mainTablePackages/PackagesTableItem";
import {
    ModalTypes,
    PackagesViewFilters,
    RootComponentsStateViewModeTypes, TableTypesEnum,
    UserSettingsActionsTypes,
    UserSettingsNames
} from "../../../tools/StaticTypes";
import Pagination from "../pagination/Pagination";
import stylePagination from "../../../media/styles/pagination.module.css";
import {
    PackagesPaginationMovePage, setViewModePreviewPackages
} from "../../../store/packages/actionCreators/package_MainTableActionCreator";
import PaginationCounterPackages from "./PaginationCounterPackages";
import * as ReactDnD from 'react-drag-and-drop';
import {
    movingDocumentsInPackageFromPackages,
    movingPackageInPackage
} from "../../../store/packages/actionCreators/package_TransferActionCreator";
import TheadPackage from "./TheadPackage";
import TbodyPackage from "./TbodyPackage";
import {history, store} from "../../../index";
import {Routing} from "../../../tools/routing/Routing";
import {updateUserSettings} from "../../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import {setLoaderModalData, setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {__} from "../../../tools/HelpFunctions";
import {setDefaultWidthOfColumn} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {
    handlerSearchPackages
} from "../../../store/packages/actionCreators/package_InputActionCreator";
import {
    collectDataForAdditionalSections_Normal
} from "../../../store/documents/actionCreators/document_PreviewActionCreator";


class MainTablePackage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lData: [],
            lHeader: [],
            prevCells: [],
            completeSlice: [],
            completeSliceAdd: []
        };
        this.tableWidthRef = React.createRef();
        //TODO Чтобы ничего не перерендеривалось
        this.memoRef = React.createRef();

        this.selectFolder = this.selectFolder.bind(this);
        this.view_bigCards = this.view_bigCards.bind(this);
        this.view_middleCards = this.view_middleCards.bind(this);
        this.view_table = this.view_table.bind(this);
        this.view_render = this.view_render.bind(this);
        this.changePaginationNumber = this.changePaginationNumber.bind(this);
        this.documentOnDrop = this.documentOnDrop.bind(this);
        this.fitInContainerSize = this.fitInContainerSize.bind(this);
        this.refreshTable = this.refreshTable.bind(this);
        this.unFitInContainerSize = this.unFitInContainerSize.bind(this);
        this.setPageInBreadcrumbs = this.setPageInBreadcrumbs.bind(this);
        this.setWidth = this.setWidth.bind(this);
    }

    setWidth(itemSize, lItem) {
        let Cells
        if (this.state.prevCells.length == 0) {
            this.setState({prevCells: __.deepCopy(this.state.lHeader)});
            Cells = __.deepCopy( this.state.lHeader);
        } else {
             Cells = __.deepCopy( this.state.prevCells);
        }

        let lObjCell =  (Cells.find(item => item.Value === lItem.Value))
        if (lObjCell)
            lObjCell.Size = itemSize;
        //TODO использован реф для того, чтобы не перерисовывались дочки, т.к. если делать через стейт, то дочки перерисовываются, это и задержки и ошибки при ресайзе.
        // this.setState({prevCells: Cells});
        this.memoRef = Cells;
    }

    // async getEntityFiltersExt(){
    //     const lEntityExt = [];
    //     const Inputs = store.getState().packages.search.searchInputs; // TODO КН 26.04.24 Если понадобится раскомментить надо брать Inputs в зависимоти от tableRole из других полей редакса
    //     if (Inputs.NamePack !== "" && Inputs.NamePack !== null) {
    //         if (typeof Inputs.Name === "object") {
    //             let entity = __.setEntityFilters({
    //                 IdAttributeType: DataTypesEnum.String,
    //                 Value: Inputs.NamePack,
    //                 Name: "Name",
    //             }, true, "DocPackageExt");
    //             lEntityExt.push(entity.get())
    //         } else {
    //             lEntityExt.push(
    //                 new EntityFilters().setEntityName("DocPackageExt")
    //                     .add({Name: "Name", Value: Inputs.Name, Operator: FilerOperatorsEnum.Like})
    //                     .get()
    //             )
    //         }
    //     }

    //     if (Inputs.ModifyDatePack !== "" && Inputs.ModifyDatePack !== null) {
    //         if (typeof Inputs.ModifyDatePack === "object") {
    //             let entity = __.setEntityFilters({
    //                 IdAttributeType: DataTypesEnum.DataTime,
    //                 Value: Inputs.ModifyDatePack,
    //                 Name: "ModifyDate",
    //             }, true, "DocPackageExt");
    //             lEntityExt.push(entity.get())
    //         } else {
    //             lEntityExt.push(new EntityFilters().setEntityName("DocPackageExt")
    //                 .add({
    //                     Name: "ModifyDate",
    //                     Value: Inputs.ModifyDatePack,
    //                     Operator: FilerOperatorsEnum.Like
    //                 })
    //                 .get()
    //             )
    //         }
    //     }
    //     if (Inputs.RegDatePackAsString !== "" && Inputs.RegDatePackAsString !== null) {
    //         if (typeof Inputs.RegDatePackAsString === "object") {
    //             let entity = __.setEntityFilters({
    //                 IdAttributeType: DataTypesEnum.DataTime,
    //                 Value: Inputs.RegDatePackAsString,
    //                 Name: "CreateDate",
    //             }, true, "DocPackageExt");
    //             lEntityExt.push(entity.get())
    //         } else {
    //             lEntityExt.push(new EntityFilters().setEntityName("DocPackageExt")
    //                 .add({Name: "CreateDate", Value: Inputs.RegDatePackAsString, Operator: FilerOperatorsEnum.Like})
    //                 .get()
    //             )
    //         }
    //     }
    //     return lEntityExt;
    //
    //     // тестовые данные
    //     // return [ {Name: "Name", Value: "11"},
    //     //          {Name: "CreateDate", Operator: 2, Value: "02.02.2023"},
    //     //          {Name: "ModifyDate", Operator: 2, Value: "02.02.2023"} ]
    // }

    unFitInContainerSize() {
        if (this.state.prevCells && this.state.prevCells.length != 0)
        {
            let Cells = __.deepCopy( this.state.prevCells);
            let lHeader = (Item) => __.deepCopy(Item);
            this.setState({lHeader: lHeader(Cells)});
        }
    }

    fitInContainerSize() {
        let TableWidth = this.tableWidthRef?.current?.offsetWidth - 100;
        let Cells = __.deepCopy(this.state.lHeader);
        if (this.memoRef && this.memoRef.length)
            this.setState({prevCells: this.memoRef})
        else
            this.setState({prevCells: Cells});
        let RowWidth = 0;
        if (Cells && TableWidth) {
            for (let i = 1; i < Cells.length; i++) {
                // if (Cells[i].UserVisible) RowWidth += (Cells[i].SizeDefault ? Cells[i].SizeDefault : (Cells[i].Value.length * 13) + 70);
               RowWidth += (Cells[i].SizeDefault ? Cells[i].SizeDefault : (Cells[i].Value.length * 13) + 70);
            }

            for (let i = 1; i < Cells.length; i++) {
                // if (Cells[i].UserVisible) {
                    let size = (TableWidth / 100) * ((Cells[i].SizeDefault ? Cells[i].SizeDefault : (Cells[i].Value.length * 13) + 70) / RowWidth * 100);
                    Cells[i].Size = size < 100 ? 100 : size;
                // }
            }
            let lHeader = (Item) => __.deepCopy(Item);
            this.setState({lHeader: lHeader(Cells)});
         }
    }

    refreshTable(TableType, lActiveNode) {
        if (!lActiveNode)
            lActiveNode = this.props.activeNode.Node;
        this.props.PackageAddNodesTree({
            Id: lActiveNode.Id,
            TableType: TableType,
            Next: {
                action: () => {
                    this.props.SelectActivePackageNode({
                        Id: lActiveNode.Id,
                        TableType: TableType,
                    });

                    this.props.breadcrumbsAdd({
                        NodeId: lActiveNode.Id,
                        Name: lActiveNode.Name,
                        ParentId: lActiveNode.IdParent,
                        TableType: TableType,
                    });
                },
                params: {}
            }
        });
    }

    async documentOnDrop(item) {
        if (this.props.adminLevel < 1) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content: `Ошибка наполнения. <br/> Недостаточно прав для выполнения действия`}
            });
            return;
        }


        if (item.document) {
            const doc = JSON.parse(item.document);
            if (doc.IsMarkedForDelete) {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {content: `Ошибка переноса документа: <br/>Документ помечен на удаление`, type: "fail"}
                });
                return;
            }

            this.props.movingDocumentsInPackageFromPackages({
                IdDoc: doc.IdDoc,
                IdPackage: this.props.activeNode.Node.Id,

                Next: {
                    action: () => {
                        this.refreshTable("main")
                    },
                    params: {}
                }
            });
        } else if (item.documents) {
            let documents = JSON.parse(item.documents);
            for (let i = 0; i < documents.length; i++) {
                this.props.movingDocumentsInPackageFromPackages({
                    IdDoc: documents[i],
                    IdPackage: this.props.activeNode.Node.Id,
                    Next: {
                        action: () => {
                            this.refreshTable("main");
                        },
                        params: {}
                    }
                });
            }
        } else if (item.package) {
            const lPack = JSON.parse(item.package);

            await this.props.movingPackageInPackage({

                PackageItem: lPack.Node,
                IdParent: this.props.activeNode.Node.Id,
            });
            await this.refreshTable("main");
            await this.refreshTable("additional", this.props.activeNodeAdditional.Node);
        }
    }

    selectFolder(item,role) {
        let IsDownload = this.props.tableRole === "download";
        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка пакетов...", disableButton : true, fullBackground : false, gif : "packages", key: "MainTablePackage217"}
        }))
        if(item.Type === "PackagesFolder") {
            this.props.PackageAddNodesTree({
                Id : item.Node.Id,
                TableType : this.props.tableRole,
                IsDownload,
                Next : {
                    action : async () => {
                        this.props.SelectActivePackageNode({
                            Id: item.Node.Id,
                            TableType: this.props.tableRole,
                            IsDownload,
                            Next: {
                                action: () => {
                                    this.props.togglePackagesFolders({
                                        IdFolder: item.Node.Id,
                                        TableType: this.props.tableRole,
                                        IsDownload,
                                        Next: {
                                            action: () => {
                                                this.props.breadcrumbsAdd({
                                                    NodeId: item.Node.Id,
                                                    Name: item.Node.Name,
                                                    ParentId: item.Node.IdParent,
                                                    TableType: this.props.tableRole,
                                                    IsDownload,
                                                    Next: {
                                                        action: async () => {
                                                            await this.props.breadcrumbsMoveSelect({
                                                                NodeId: item.Node.Id,
                                                                TableType: this.props.tableRole,
                                                                IsDownload,
                                                                Next: {
                                                                    action: () => {
                                                                        let newViewModeValue = this.props.viewModePreview
                                                                        if (this.props.tableRole == "main") {
                                                                            if (this.props.viewModePreview == "none" || this.props.viewModePreview == "normal") {
                                                                                newViewModeValue = "none"
                                                                            } else {
                                                                                newViewModeValue = this.props.viewModePreview
                                                                            }
                                                                        } else if (this.props.tableRole == "download") {
                                                                            if (this.props.viewModePreview == "two_table_download") {
                                                                                newViewModeValue = "two_table_download"
                                                                            } else {
                                                                                newViewModeValue = "none"
                                                                            }
                                                                        }

                                                                        this.props.setViewModePreviewPackages({
                                                                            ViewMode: newViewModeValue,
                                                                            Next: {
                                                                                action: () => {
                                                                                    this.props.SelectActivePackageDocument({
                                                                                        TableType: this.props.tableRole,
                                                                                        Next: {
                                                                                            action: () => {
                                                                                                let query = this.props.router.location.query;
                                                                                                let queryLink = "";
                                                                                                if (this.props.tableRole === "main") {
                                                                                                    queryLink = Routing.CreateRef({
                                                                                                        oldQuery: query,
                                                                                                        newQuery: {p: item.Node.Id},
                                                                                                        excludedKeys: ["d"]
                                                                                                    });
                                                                                                } else if (this.props.tableRole === "additional") {
                                                                                                    queryLink = Routing.CreateRef({
                                                                                                        oldQuery: query,
                                                                                                        newQuery: {p2: item.Node.Id},
                                                                                                        excludedKeys: ["d2"]
                                                                                                    });
                                                                                                } else if (this.props.tableRole === "download") {
                                                                                                    queryLink = Routing.CreateRef({
                                                                                                        oldQuery: query,
                                                                                                        newQuery: {dwp: item.Node.Id},
                                                                                                        excludedKeys: ["dwd"]
                                                                                                    });
                                                                                                }

                                                                                                let link = `${this.props.router.location.pathname}${queryLink}`;
                                                                                                history.push(link);
                                                                                                store.dispatch(setLoaderModalData({keyDeleted: "MainTablePackage298"}));

                                                                                            },
                                                                                            params: {}
                                                                                        }
                                                                                    });
                                                                                },
                                                                                params: {}
                                                                            }
                                                                        });
                                                                    },
                                                                    params: {}
                                                                }
                                                            });
                                                        },
                                                        params: {}
                                                    }
                                                });
                                            },
                                            params: {}
                                        }
                                    });
                                },
                                params: {}
                            }
                        });
                    },
                    params : {}
                }
            });
        }
        else  if(item.Type === "PackagesDocument") {
            this.props.SelectActivePackageDocument({
                Id : item.Node.Id,
                TableType : this.props.tableRole,
                IsDownload,
                Next : {
                    action : ()=> {
                        let activeNode;
                        if(this.props.tableRole === "main")  activeNode = this.props.activeNode;
                        else if(this.props.tableRole === "additional")  activeNode = this.props.activeNodeAdditional;
                        else if(this.props.tableRole === "download")  activeNode = this.props.activeNodeDownload;

                        this.props.collectDataForAdditionalSections_Normal({TableRole: this.props.tableRole})

                        let roleTable = role ?? this.props.tableRole;

                        if(roleTable !== "download") {
                            this.props.setLoaderModalData({keyDeleted: "MainTablePackage326"});
                            this.props.updateUserSettings({
                                Name: UserSettingsNames.PACKAGE_CONFIG,
                                Action: UserSettingsActionsTypes.Update,
                                Data: {
                                    Id: activeNode.Node.Id,
                                    Name: activeNode.Node.Name
                                }
                            })
                        }else{
                            this.props.setLoaderModalData({keyDeleted: "MainTablePackage338"});
                        }

                    },
                    params : {}
                }
            });
            if(this.props.tableRole === "main") {
                if(this.props.viewModePreview === "two_table_none") {

                }
                else if(this.props.viewModePreview === "none") {
                    this.props.setViewModePreviewPackages({
                        ViewMode : "normal"
                    });
                }
            }

            let query = this.props.router.location.query;
            let queryLink = "";

            if(this.props.tableRole === "main") {
                queryLink = Routing.CreateRef({
                    oldQuery : query,
                    newQuery : {d : item.Node.Id},
                    excludedKeys : []
                });
            }
            else if(this.props.tableRole === "additional") {
                queryLink = Routing.CreateRef({
                    oldQuery : query,
                    newQuery : {d2 : item.Node.Id},
                    excludedKeys : []
                });
            }
            else if(this.props.tableRole === "download") {
                queryLink = Routing.CreateRef({
                    oldQuery : query,
                    newQuery : {dwd : item.Node.Id},
                    excludedKeys : []
                });
            }

            let link = `${this.props.router.location.pathname}${queryLink}`;
            history.push(link);
        }
    }

    view_render(isReady, viewFilter) {
        let viewMode;
        if(this.props.tableRole === "main") viewMode = this.props.viewModeMainTable;
        else if(this.props.tableRole === "additional") viewMode = this.props.viewModeAdditionalTable;
        else if(this.props.tableRole === "download") viewMode = this.props.viewModeDownloadTable;


        if(viewMode === RootComponentsStateViewModeTypes.PackagesApp.Table_table) {
            return this.view_table(isReady, viewFilter);
        }
        else if(viewMode === RootComponentsStateViewModeTypes.PackagesApp.Table_middleCards) {
            return this.view_middleCards(isReady, viewFilter);
        }
        else if(viewMode === RootComponentsStateViewModeTypes.PackagesApp.Table_bigCards) {
            return this.view_bigCards(isReady, viewFilter);
        }

        else return null;
    }

    view_bigCards(isReady, viewFilter) {
        let activeNode;
        let pagination;

        if(this.props.tableRole === "main") {
            activeNode = this.props.activeNode;
            pagination = this.props.treePagination;
        }
        else if(this.props.tableRole === "additional") {
            activeNode = this.props.activeNodeAdditional;
            pagination = this.props.treeAdditionalPagination;
        }
        else if(this.props.tableRole === "download") {
            activeNode = this.props.activeNodeDownload;
            pagination = this.props.treeDownloadPagination;
        }

        let completeSlice;
        if (!activeNode) {
            return null;
        }
        if(activeNode.Children?.length <= pagination.paginationPageSize && pagination.paginationPageNumber === 1) {
            completeSlice = activeNode.Children;
        }
        else {

            // Обрезаем массив по пагинации, чтобы слева отображались все пакеты, а центральной части только по размеру страницы
            // let leftSlice = __.dropArrayLeft(activeNode.Children, pagination.paginationPageSize * (pagination.paginationPageNumber - 1));
            // completeSlice = __.dropArrayRight(leftSlice, leftSlice.length - pagination.paginationPageSize);


            //TODO aa новый алгоритм пагинации
            //убираем лишние пакеты
            const lCountPack = activeNode?.Children.map(e => e.Type).indexOf('PackagesDocument');

            if (viewFilter === PackagesViewFilters.OnlyDocuments) {
                completeSlice = activeNode?.Children.filter(item => item.Type =="PackagesDocument");
            } else if (lCountPack != -1 && pagination.paginationPageNumber != 1) {
                completeSlice = __.dropArrayLeft(activeNode?.Children, activeNode?.Children.length - pagination.paginationPageSize);
                const lPageNumForFolder = (lCountPack) / pagination.paginationPageSize >= 1
                    ? Math.round((lCountPack) / pagination.paginationPageSize) + Math.floor((lCountPack) % pagination.paginationPageSize)
                    : 0;
                if (lPageNumForFolder < pagination.paginationPageNumber)
                    completeSlice = activeNode?.Children.filter(item => item.Type =="PackagesDocument")
                else if(lPageNumForFolder == pagination.paginationPageNumber)
                    completeSlice = ((lCountPack % pagination.paginationPageSize)>0?__.dropArrayLeft(activeNode.Children?.filter(item => item.Type != "PackagesDocument"), activeNode.Children?.filter(item => item.Type != "PackagesDocument").length - (lCountPack % pagination.paginationPageSize)) : "").concat(
                        activeNode.Children?.filter(item => item.Type =="PackagesDocument").slice(0, pagination.paginationPageSize - (lCountPack % pagination.paginationPageSize)))
            } else {
                let leftSlice = __.dropArrayLeft(activeNode?.Children, pagination.paginationPageSize * (pagination.paginationPageNumber - 1));
                completeSlice = __.dropArrayRight(leftSlice, leftSlice.length - pagination.paginationPageSize);
            }

        }

        if (this.props.tableRole === TableTypesEnum.Main) {
            if (JSON.stringify(this.state.completeSlice) !== JSON.stringify(completeSlice)) {
                this.setState({completeSlice})
            }
        }else if (JSON.stringify(this.state.completeSliceAdd) !== JSON.stringify(completeSlice)) {
            this.setState({completeSliceAdd: completeSlice})
        }

        if(isReady) {
            return (
                completeSlice.map((item, index) => {
                    if (
                        viewFilter === PackagesViewFilters.All
                        || (viewFilter === PackagesViewFilters.OnlyPackages && item.Type === "PackagesFolder")
                        || (viewFilter === PackagesViewFilters.OnlyDocuments && item.Type === "PackagesDocument")
                    ) {
                        return (
                            <PackagesTableItem
                                key={item.TreeId}
                                item={item}
                                viewMode={"bigCards"}
                                tableRole={this.props.tableRole}
                                index={index}
                                selectFolder={this.selectFolder}
                            />
                        )
                    } else {
                        return null;
                    }
                })
            );
        } else {
            return null;
        }
    }

    view_middleCards(isReady, viewFilter) {
        let activeNode;
        let pagination;

        if(this.props.tableRole === "main") {
            activeNode = this.props.activeNode;
            pagination = this.props.treePagination;
        }
        else if(this.props.tableRole === "additional") {
            activeNode = this.props.activeNodeAdditional;
            pagination = this.props.treeAdditionalPagination;
        }
        else if(this.props.tableRole === "download") {
            activeNode = this.props.activeNodeDownload;
            pagination = this.props.treeDownloadPagination;
        }

        let completeSlice;
        if (activeNode) {
            if (activeNode.Children.length <= pagination.paginationPageSize && pagination.paginationPageNumber === 1) {
                completeSlice = activeNode.Children;
            } else {
                // Обрезаем массив по пагинации, чтобы слева отображались все пакеты, а центральной части только по размеру страницы
                // let leftSlice = __.dropArrayLeft(activeNode.Children, pagination.paginationPageSize * (pagination.paginationPageNumber - 1));
                // completeSlice = __.dropArrayRight(leftSlice, leftSlice.length - pagination.paginationPageSize);

                //TODO aa новый алгоритм пагинации
                //убираем лишние пакеты
                const lCountPack = activeNode.Children.map(e => e.Type).indexOf('PackagesDocument');
                if (viewFilter === PackagesViewFilters.OnlyDocuments) {
                    completeSlice = activeNode.Children.filter(item => item.Type == "PackagesDocument");
                } else if (lCountPack != -1 && pagination.paginationPageNumber != 1) {
                    completeSlice = __.dropArrayLeft(activeNode.Children, activeNode.Children.length - pagination.paginationPageSize);
                    const lPageNumForFolder = (lCountPack) / pagination.paginationPageSize >= 1
                        ? Math.round((lCountPack) / pagination.paginationPageSize) + Math.floor((lCountPack) % pagination.paginationPageSize)
                        : 0;

                    if (lPageNumForFolder < pagination.paginationPageNumber)
                        completeSlice = activeNode.Children.filter(item => item.Type == "PackagesDocument")
                    else if (lPageNumForFolder == pagination.paginationPageNumber)
                        completeSlice = ((lCountPack % pagination.paginationPageSize) > 0 ? __.dropArrayLeft(activeNode.Children?.filter(item => item.Type != "PackagesDocument"), activeNode.Children?.filter(item => item.Type != "PackagesDocument").length - (lCountPack % pagination.paginationPageSize)) : "").concat(
                            activeNode.Children?.filter(item => item.Type == "PackagesDocument").slice(0, pagination.paginationPageSize - (lCountPack % pagination.paginationPageSize)))
                } else {
                    let leftSlice = __.dropArrayLeft(activeNode.Children, pagination.paginationPageSize * (pagination.paginationPageNumber - 1));
                    completeSlice = __.dropArrayRight(leftSlice, leftSlice.length - pagination.paginationPageSize);
                }
            }
        }

        if (this.props.tableRole === TableTypesEnum.Main) {
            if (JSON.stringify(this.state.completeSlice) !== JSON.stringify(completeSlice)) {
                this.setState({completeSlice})
            }
        }else if (JSON.stringify(this.state.completeSliceAdd) !== JSON.stringify(completeSlice)) {
                this.setState({completeSliceAdd: completeSlice})
        }

        if(isReady) {
            return (
                completeSlice.map((item, index)=> {
                    if(
                        viewFilter === PackagesViewFilters.All
                        || (viewFilter === PackagesViewFilters.OnlyPackages && item.Type === "PackagesFolder")
                        || (viewFilter === PackagesViewFilters.OnlyDocuments && item.Type === "PackagesDocument")
                    ) {
                        return (
                            <PackagesTableItem
                                key={item.TreeId}
                                item={item}
                                viewMode={"middleCards"}
                                tableRole={this.props.tableRole}
                                index={index}
                                selectFolder={this.selectFolder}
                            />
                        )
                    } else {
                        return null;
                    }
                })
            );
        }
        else return null;
    }

    view_table(isReady, viewFilter) {
        let activeNode;
        let pagination;

        if(this.props.tableRole === "main") {
            activeNode = this.props.activeNode;
            pagination = this.props.treePagination;
        }
        else if(this.props.tableRole === "additional") {
            activeNode = this.props.activeNodeAdditional;
            pagination = this.props.treeAdditionalPagination;
        }
        else if(this.props.tableRole === "download") {
            activeNode = this.props.activeNodeDownload;
            pagination = this.props.treeDownloadPagination;
        }

        if (!activeNode)
            return;

        let completeSlice;
        if(activeNode?.Children.length <= pagination.paginationPageSize && pagination.paginationPageNumber == 1) {
            // if (this.props.isRealSearch) completeSlice = this.props.temporaryData.documents
            // else
                completeSlice = activeNode.Children;
        }
        else {
            //TODO алгоритм пагинации
            // completeSlice = activeNode.Children;

            // Обрезаем массив по пагинации, чтобы слева отображались все пакеты, а центральной части только по размеру страницы
            // let leftSlice = __.dropArrayLeft(activeNode.Children, pagination.paginationPageSize * (pagination.paginationPageNumber - 1));
            // completeSlice = __.dropArrayRight(leftSlice, leftSlice.length - pagination.paginationPageSize);

            //TODO aa новый алгоритм пагинации
            //убираем лишние пакеты
            const lCountPack = activeNode?.Children.map(e => e.Type).indexOf('PackagesDocument');

            if (viewFilter === PackagesViewFilters.OnlyDocuments) {
                completeSlice = activeNode?.Children.filter(item => item.Type =="PackagesDocument");
            } else if (lCountPack != -1 && pagination.paginationPageNumber != 1) {
                completeSlice = __.dropArrayLeft(activeNode.Children, activeNode.Children?.length - pagination.paginationPageSize);
                const lPageNumForFolder = ((lCountPack / pagination.paginationPageSize) >= 1)
                    ? (Math.floor((lCountPack) / pagination.paginationPageSize) + ((lCountPack % pagination.paginationPageSize)>0?1:0))
                    :  (lCountPack > 0 ? 1 : 0);

                if (lPageNumForFolder < pagination.paginationPageNumber)
                    completeSlice = activeNode.Children?.filter(item => item.Type =="PackagesDocument")
                else if(lPageNumForFolder == pagination.paginationPageNumber)
                    completeSlice = ((lCountPack % pagination.paginationPageSize)>0?__.dropArrayLeft(activeNode.Children?.filter(item => item.Type != "PackagesDocument"), activeNode.Children?.filter(item => item.Type != "PackagesDocument").length - (lCountPack % pagination.paginationPageSize)) : "").concat(
                                activeNode.Children?.filter(item => item.Type =="PackagesDocument").slice(0, pagination.paginationPageSize - (lCountPack % pagination.paginationPageSize)))

            } else {
                let leftSlice = __.dropArrayLeft(activeNode.Children, pagination.paginationPageSize * (pagination.paginationPageNumber - 1));
                completeSlice = __.dropArrayRight(leftSlice, leftSlice.length - pagination.paginationPageSize);
            }
        }

        if (this.props.tableRole === TableTypesEnum.Main) {
            if (JSON.stringify(this.state.completeSlice) !== JSON.stringify(completeSlice)) {
                this.setState({completeSlice})
            }
        }else if (JSON.stringify(this.state.completeSliceAdd) !== JSON.stringify(completeSlice)) {
            this.setState({completeSliceAdd: completeSlice})
        }

        let HeaderColumns = [];
        HeaderColumns.push({Name: "Version.Version", Value: "Версия"});
        HeaderColumns.push({Name: "RegDate", Value: "Дата"});
        HeaderColumns.push({Name: "RegNumber", Value: "Номер"});
        HeaderColumns.push({Name: "Name", Value: "Наименование"});
        HeaderColumns.push({Name: "TypeName", Value: "Тип"});
        HeaderColumns.push({Name: "Version.ModifyTime", Value: "Последнее изменение"});

        let BodyColumns = [];
        for(let i = 0; i < completeSlice.length; i++) {
            let BodyColumnsTR = [];
            let item = completeSlice[i].Node;
            if (completeSlice[i].Type === "PackagesDocument") {
                BodyColumnsTR.push({Name: "Version.Version", Value: item.Version.Version});
                BodyColumnsTR.push({Name: "RegDate", Value: item.RegDate});
                BodyColumnsTR.push({Name: "RegNumber", Value: item.RegNumber});
                BodyColumnsTR.push({Name: "Name", Value: item.Name});
                BodyColumnsTR.push({Name: "TypeName", Value: item.Type.Name});
                BodyColumnsTR.push({Name: "Version.ModifyTime", Value: item.Version.ModifyTime});
            } else {
                BodyColumnsTR.push({Name: "Version.Version", Value: null});
                BodyColumnsTR.push({Name: "RegDate", Value: item.CreateDate});
                BodyColumnsTR.push({Name: "RegNumber", Value: null});
                BodyColumnsTR.push({Name: "Name", Value: item.Name});
                BodyColumnsTR.push({Name: "TypeName", Value: "Пакет"});
                BodyColumnsTR.push({Name: "Version.ModifyTime", Value: item.ModifyDate});

            }
            BodyColumnsTR.push({Name: "Item", Value: completeSlice[i]});
            BodyColumns.push(BodyColumnsTR);
        }

        //Оптимизация
        if (JSON.stringify(BodyColumns) != JSON.stringify(this.state.lData))
          this.setState({lData: BodyColumns})
        if (JSON.stringify(HeaderColumns) != JSON.stringify(this.state.lHeader))
            if (this.state.lHeader.length == 0)
              this.setState({lHeader: HeaderColumns})

        if (isReady) {
            return (
                <div className={""}>
                    <table className={"table table-head-custom table-striped table-hover table-vertical-center"}
                           style={{tableLayout: "fixed"}}>
                        <TheadPackage
                            setWidth = {(itemSize, item) => this.setWidth(itemSize, item)}
                            HeaderColumns={this.state.lHeader}
                            tableRole={this.props.tableRole}
                            body={completeSlice}
                        />
                        <TbodyPackage
                            data={this.state.lData}
                            viewFilter={viewFilter} tableRole={this.props.tableRole} selectFolder={this.selectFolder}/>
                    </table>
                </div>
            )
        } else {
            return null
        }
    }

    setPageInBreadcrumbs({Next = null, Error = null}) {
        let activeNode;
        if(this.props.tableRole === "main")  activeNode = this.props.activeNode;
        else if(this.props.tableRole === "additional")  activeNode = this.props.activeNodeAdditional;
        else if(this.props.tableRole === "download")  activeNode = this.props.activeNodeDownload;

        this.props.breadcrumbsSetPage({
            TableType: this.props.tableRole,
            IsDownload: this.props.tableRole === "download",
            NodeId: activeNode.Node.Id,
            Next,
            Error,
        });
    }

    changePaginationNumber(PageNumber) {
        this.props.setLoaderModalData({
            data: {
                content: "Загрузка...",
                disableButton: true,
                fullBackground: true,
                gif: "packages",
                key: "MainTablePackage823"
            },
                Next: {
                action: async () => {
                    let currentSortedParams, FieldSorting, Direction;
                    if (this.props.tableRole === "main") {
                        currentSortedParams = store.getState().packages.tree.SortedParams
                    } else if (this.props.tableRole === "additional") {
                        currentSortedParams = store.getState().packages.treeAdditional.SortedParams
                    } else if (this.props.tableRole === "download") {
                        currentSortedParams = store.getState().packages.treeDownload.SortedParams
                    }
                    for (const [key, value] of Object.entries(currentSortedParams)) {
                        if (value) {
                            FieldSorting = key
                            Direction = value
                            break;
                        }
                    }
                    await this.props.PackagesPaginationMovePage({
                        PageNumber: PageNumber,
                        TableType: this.props.tableRole,
                        IsDownload: this.props.tableRole === "download",
                        FieldSorting,
                        Direction,
                        Next: {
                            action: () => {
                                __.deleteDocFromLinkUsingActiveNode(this.props.tableRole);
                                this.setPageInBreadcrumbs({
                                    Next: {
                                        action: () => {
                                            this.props.setLoaderModalData({keyDeleted: "MainTablePackage872"})
                                        },
                                        params: {}
                                    }
                                });
                            }
                        },
                    })
                }
            }
        });
    }

    render() {
        if (!this.props.activeNode) return null
        let activeNode;
        if(this.props.tableRole === "main")  activeNode = this.props.activeNode;
        else if(this.props.tableRole === "additional")  activeNode = this.props.activeNodeAdditional;
        else if(this.props.tableRole === "download")  activeNode = this.props.activeNodeDownload;

        let viewFilter;
        if(this.props.tableRole === "main") viewFilter = this.props.viewFilter;
        else if(this.props.tableRole === "additional") viewFilter = this.props.viewFilterAdditional;
        else if(this.props.tableRole === "download") viewFilter = this.props.viewFilterDownload;

        let pagination;

        if(this.props.tableRole === "main") {
            this.props.isRealSearch ?
                pagination = {
                    paginationPageNumber : this.props.treePagination.paginationPageNumber,
                    paginationPageSize : this.props.treePagination.paginationPageSize,
                    paginationButtonsCount : Math.ceil(this.props.temporaryData.totalRecordsCount/this.props.treePagination.paginationPageSize) <= 3
                        ? Math.ceil(this.props.temporaryData.totalRecordsCount/this.props.treePagination.paginationPageSize)
                        : 3,

                    documentsTotal : this.props.temporaryData.totalRecordsCount,
                    // Задача № 24377 Фильтрация по наименованию пакета внутри пакета
                    // foldersTotal : 0,
                    foldersTotal : this.props.activeNode.Children.findIndex(item => item.Type == "PackagesDocument") != -1 ? this.props.activeNode.Children.findIndex(item => item.Type == "PackagesDocument") : this.props.activeNode.Children.length,
                } :
            pagination = {
                paginationPageNumber : this.props.treePagination.paginationPageNumber,
                paginationPageSize : this.props.treePagination.paginationPageSize,
                paginationButtonsCount : this.props.treePagination.paginationButtonsCount,

                documentsTotal : this.props.treeLastLoad.documentsTotal,
                foldersTotal : this.props.treeLastLoad.foldersTotal,
            }
        } else if (this.props.tableRole === "additional") {
            let documentsTotal = this.props.treeAdditionalLastLoad.documentsTotal
            store.getState().packages.treeAdditional.searchInputs.IsSearch ?
                pagination = {
                    paginationPageNumber : this.props.treeAdditionalPagination.paginationPageNumber,
                    paginationPageSize : this.props.treeAdditionalPagination.paginationPageSize,
                    paginationButtonsCount : Math.ceil(this.props.temporaryData.totalRecordsCount/this.props.treeAdditionalPagination.paginationPageSize) <= 3
                        ? Math.ceil(this.props.temporaryData.totalRecordsCount/this.props.treeAdditionalPagination.paginationPageSize)
                        : 3,

                    documentsTotal : this.props.temporaryData.totalRecordsCount,
                    // Задача № 24377 Фильтрация по наименованию пакета внутри пакета
                    // foldersTotal : 0,
                    foldersTotal : this.props.activeNodeAdditional.Children.findIndex(item => item.Type == "PackagesDocument") != -1 ? this.props.activeNodeAdditional.Children.findIndex(item => item.Type == "PackagesDocument") : this.props.activeNodeAdditional.Children.length,
                } :
            pagination = {
                paginationPageNumber : this.props.treeAdditionalPagination.paginationPageNumber,
                paginationPageSize : this.props.treeAdditionalPagination.paginationPageSize,
                paginationButtonsCount : this.props.treeAdditionalPagination.paginationButtonsCount,

                documentsTotal : documentsTotal,
                foldersTotal : this.props.treeAdditionalLastLoad.foldersTotal,
            }
        } else if (this.props.tableRole === "download") {
            let documentsTotal = this.props.treeDownloadLastLoad.documentsTotal
            store.getState().packages.treeDownload.searchInputs.IsSearch ?
                pagination = {
                    paginationPageNumber : this.props.treeDownloadPagination.paginationPageNumber,
                    paginationPageSize : this.props.treeDownloadPagination.paginationPageSize,
                    paginationButtonsCount : Math.ceil(this.props.temporaryData.totalRecordsCount/this.props.treeDownloadPagination.paginationPageSize) <= 3
                        ? Math.ceil(this.props.temporaryData.totalRecordsCount/this.props.treePagination.paginationPageSize)
                        : 3,

                    documentsTotal : this.props.temporaryData.totalRecordsCount,
                    foldersTotal : 0,
                } :
            pagination = {
                paginationPageNumber : this.props.treeDownloadPagination.paginationPageNumber,
                paginationPageSize : this.props.treeDownloadPagination.paginationPageSize,
                paginationButtonsCount : this.props.treeDownloadPagination.paginationButtonsCount,

                documentsTotal : documentsTotal,
                foldersTotal : this.props.treeDownloadLastLoad.foldersTotal,
            }
        }
        let isReady = false;
        if (activeNode) {
            if (activeNode.Children?.length > 0) {
                isReady = true;
            }
        }

        let content = this.view_render(isReady, viewFilter);

        let totalItemsCount;
        if(viewFilter === PackagesViewFilters.All) {
            totalItemsCount = pagination.documentsTotal + pagination.foldersTotal;
        }
        else if(viewFilter === PackagesViewFilters.OnlyPackages) {
            totalItemsCount = pagination.foldersTotal;
        }
        else if(viewFilter === PackagesViewFilters.OnlyDocuments) {
            totalItemsCount = pagination.documentsTotal;
        }

        let isEmpty = false;
        if(Math.ceil(totalItemsCount / Number.parseInt(pagination.paginationPageSize))){
            isEmpty=true
        }

        let containerScrollStyle = this.props.viewModeMainTable === RootComponentsStateViewModeTypes.PackagesApp.Table_table
            ? "auto"
            : "scroll";

        const pagesString = !isEmpty ? `Нет данных для отображения` : `Cтраница ${pagination.paginationPageNumber} из ${Math.ceil(totalItemsCount / Number.parseInt(pagination.paginationPageSize))}`
        return (

            <>
                <MainTableSearchPackage
                    fitInContainerSize={this.fitInContainerSize}
                    unFitInContainerSize={this.unFitInContainerSize}
                    viewMode={this.props.viewMode}
                    tableRole={this.props.tableRole}
                    body={this.props.tableRole === TableTypesEnum.Main ? this.state.completeSlice : this.state.completeSliceAdd}
                />
                {
                    this.props.tableRole === "main" &&
                    <ReactDnD.Droppable
                        className={"card-body py-0 beautiful-scroll-10 table-responsive"}
                        style={{height: "60vh", overflowY: containerScrollStyle}}
                        types={['document', 'documents', 'package']}
                        onDrop={this.documentOnDrop}
                    >
                        <div className="row" style={{}} ref={this.tableWidthRef}>
                            {content}
                        </div>
                    </ReactDnD.Droppable>
                }
                {
                    this.props.tableRole !== "main" &&
                    <div className={"card-body py-0 beautiful-scroll-10 table-responsive"} style={{height: "60vh", overflowY: containerScrollStyle}}>
                        <div className="row" style={{}} ref={this.tableWidthRef}>
                            {content}
                        </div>
                    </div>
                }

                <div className="card-footer py-0">
                    <div className="d-flex justify-content-between align-items-center flex-wrap pt-3 pb-3">
                        {
                            isReady &&
                            <>
                                <Pagination
                                    activePage={pagination.paginationPageNumber} // активная страница
                                    itemsCountPerPage={Number.parseInt(pagination.paginationPageSize)} // элементов на странице
                                    totalItemsCount={totalItemsCount} // всего элементов
                                    pageRangeDisplayed={this.props.viewModePreview === "none" ? pagination.paginationButtonsCount : 1} // сколько кнопок отображать
                                    onChange={(pageNumber) => this.changePaginationNumber(pageNumber, pagination)}  // обработчик переключения
                                    itemClass={stylePagination.item} // стили item-ов
                                    linkClass={"btn btn-icon btn-sm btn-light-success mr-2 my-1 pagination-btn"} // стиль для ссылок
                                    activeLinkClass={"btn btn-icon btn-sm btn-light-success active mr-2 my-1 pagination-btn"} // стиль для активных ссылок
                                />
                                <span
                                    className={`text-muted mx-2`}
                                    style={{overflow: 'hidden', textOverflow: 'ellipsis', textWrap: 'nowrap'}}
                                    title={pagesString}
                                >
                                    {pagesString}
                                    </span>
                                <PaginationCounterPackages
                                    totalItemsCount={totalItemsCount} // всего элементов
                                    activePage={pagination.paginationPageNumber} // активная страница
                         //           paginationPageSize={pagination.paginationPageSize} // элементов на странице
                                    tableRole={this.props.tableRole} // тип таблицы
                                    isViewModeNone={this.props.viewModePreview === "none"}
                                    setPageInBreadcrumbs={() => this.setPageInBreadcrumbs({})} // запись страницы в хлебные крошки
                                />
                            </>
                        }
                    </div>

                </div>
            </>
        );
    }
}


const  mapStateToProps = state => {
    return {
        activeDocument : state.packages.tree.activeDocument,
        activeDocumentAdditional : state.packages.treeAdditional.activeDocument,
        activeDocumentDownload : state.packages.treeDownload.activeDocument,
        activeNode : state.packages.tree.activeNode,
        activeNodeAdditional : state.packages.treeAdditional.activeNode,
        activeNodeDownload : state.packages.treeDownload.activeNode,

        adminLevel : state.globalState.user.AdminLevel,
        header : state.packages.tree.header,
        isRealSearch: state.packages.tree.searchInputs.IsSearch,
        router : state.router,
        temporaryData: state.packages.temporaryData,

        treeLastLoad : state.packages.tree.lastLoad,
        treeAdditionalLastLoad : state.packages.treeAdditional.lastLoad,
        treeDownloadLastLoad : state.packages.treeDownload.lastLoad,

        treePagination: {
            paginationPageSize: state.packages.tree.paginationPageSize,
            paginationPageNumber: state.packages.tree.paginationPageNumber,
            paginationButtonsCount: state.packages.tree.paginationButtonsCount,
        },
        treeAdditionalPagination: {
            paginationPageSize: state.packages.treeAdditional.paginationPageSize,
            paginationPageNumber: state.packages.treeAdditional.paginationPageNumber,
            paginationButtonsCount: state.packages.treeAdditional.paginationButtonsCount,
        },
        treeDownloadPagination: {
            paginationPageSize: state.packages.treeDownload.paginationPageSize,
            paginationPageNumber: state.packages.treeDownload.paginationPageNumber,
            paginationButtonsCount: state.packages.treeAdditional.paginationButtonsCount,
        },

        viewFilter : state.packages.tree.viewFilter,
        viewFilterAdditional : state.packages.treeAdditional.viewFilter,
        viewFilterDownload : state.packages.treeDownload.viewFilter,
        viewModeApp : state.packages.rootState.viewMode,
        viewModeMainTable : state.packages.rootState.viewModeMainTable,
        viewModeAdditionalTable : state.packages.rootState.viewModeAdditionalTable,
        viewModeDownloadTable : state.packages.rootState.viewModeDownloadTable,
        viewModePreview : state.packages.rootState.viewModePreview,
    }
}


const  mapDispatchToProps = {
    breadcrumbsAdd,
    breadcrumbsMoveSelect,
    breadcrumbsSetPage,
    collectDataForAdditionalSections_Normal,
    handlerSearchPackages,
    movingDocumentsInPackageFromPackages,
    movingPackageInPackage,
    PackageAddNodesTree,
    PackagesPaginationMovePage,
    SelectActivePackageDocument,
    SelectActivePackageNode,
    setViewModePreviewPackages,
    setDefaultWidthOfColumn,
    setLoaderModalData,
    setModalData,
    togglePackagesFolders,
    updateUserSettings,
}

export default connect(mapStateToProps,mapDispatchToProps)(MainTablePackage);




