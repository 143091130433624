import produce from "immer";
import {DEPARTMENT_SET} from "./departmentActionList";

const departments={
    departmentCollection:[
        {
            IdDoc: null,
            IdDepartment: null,
        }
    ]
}
const InitialState={
    departments,
}
export const departmentReducer=(state=InitialState,action)=>{
    return produce(state,newState=>{
        if(action.type===DEPARTMENT_SET.SET_DEPARTMENT_COLLECTION){
            newState.departments.departmentCollection=action.payload;
        }
    })
}
