import React from "react";
import {connect} from "react-redux";
import LeftMenu from "../static/LeftMenu";
import TopMenu from "../static/TopMenu";
import {
    setModalData,
    setStateLeftAsideMenu
} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {apiRequest, apiRequestNew} from "../../tools/API/apiRequest";
import {apiUrl} from "../../tools/API/apiUrl";
import {EntityFilters, Filter, FiltersType, Sorting} from "../../tools/Filters";
import AsyncSelect from 'react-select/async';
import {createUniqueIdString} from "../../tools/CreateUniqueId";
import {__, HelpFunctions} from "../../tools/HelpFunctions";
import {DataTypes, ModalTypes, Operators, PrintFileFormat} from "../../tools/StaticTypes";
import {API} from "../../tools/API_NEW/API";
import {encode} from "windows-1251/windows-1251";
import {DataTypesEnum} from "../../tools/API_NEW/ServerTypes";
import {MyTooltip} from "../overPage/tooltip/MyTooltip";
import Select from "react-select";
import {treeSearchPackage} from "../../store/packages/actionCreators/package_TreeSearchActionCreator";
import Collapse from "react-bootstrap/Collapse";
import {store} from "../../index";
import {DOCUMENT_PREVIEW_ACTIONS, TREE_ACTIONS} from "../../store/documents/documentActionsList";
import makeAnimated from "react-select/animated";
import "../../media/styles/css_dp/icon.css";
import FilesModal from "../overPage/modal/FilesModal";
import DropFileComponent from "../tools/DropFileComponent/DropFileComponent";
import MobileMenu from "../static/MobileMenu";
import {setDepartmentCollection} from "../../store/department/actionCreator/department_GetSet";
import {SaveOrUpdateAndDeleteByFilter} from "../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import {MySelectNew} from "../tools/MySelect/MySelectNew";

import {
    setActiveAttributes,
    setActiveIdFilial,
    setActiveType,
    setComment,
    setContentText,
    setDisableBtnToDocument,
    setDocName,
    setDocNameError,
    setLoadTypes,
    setLoadGroups,
    setOriginalFile,
    setOtherFiles,
    setPreviewFile,
    setRegDate,
    setRegNumber,
    setSelectedGroups,
    setShowBanner,
    setTypeValue,
    setVersionIdForLocate,
} from "../../store/masterApp/actionCreator/masterAppActionCreator";
import MultipleAttributeTable from "../tools/MultipleAttributeTable/MultipleAttributeTable";
import JsonObjectFragment from "./JsonObjectFragment";
import SearchableDictionariesSelect from "../tools/MultipleAttributeTable/SearchableDictionariesSelect";

const animatedComponents = makeAnimated();

const createSelectStyles = {
    input: (css, state) => ({
        ...css,
        width: "100%",
        "> div": {
            width: "100%"
        },
        input: {
            width: `100% !important`,
            textAlign: "left"
        }
    })
}

const sbVersionSelect = {
    indicatorSeparator: (provided, state) => ({
        ...provided,
        backgroundColor: null,
    }),
    menuList: (base) => ({
        ...base,

        "::-webkit-scrollbar": {
            width: "5px",
            height: "5px",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
        },

        "::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            /*background-color: rgb(239 137 137 / 20%);*/
            backgroundColor: "var(--background-colorBS)",
        },

        "::-webkit-scrollbar-track": {
            borderRadius: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.35)",
        }
    })
}

const sbSelect = {
    menuList: (base) => ({
        ...base,

        "::-webkit-scrollbar": {
            width: "5px",
            height: "5px",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
        },

        "::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            /*background-color: rgb(239 137 137 / 20%);*/
            backgroundColor: "var(--background-colorBS)",
        },

        "::-webkit-scrollbar-track": {
            borderRadius: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.35)",
        }
    })
}

async function fetchVersionOptions(docId) {
    if (!docId)
        return;
    try {
        const data = await API.documents().getAllVersions({
            filter: new Filter(FiltersType.ENTITY).add(
                new EntityFilters().setEntityName("DocVersion").add({
                    Name: "IdDoc",
                    Value: docId
                }).get()
            ).get(),
            sorting: null,
        });

        let lVersionOptions = [];

        for (let i in data.Records) {
            lVersionOptions.push({
                value: data.Records[i].Id,
                label: "Версия " + data.Records[i].Version + " от " + __.dateToRussia(data.Records[i].VersionTime)
            })
        }

        return lVersionOptions;
    } catch (error) {
        console.error('Error fetching options:', error);
        return [];
    }
}

class MasterApp extends React.Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            AdditionalFileForPrinting: false,

            AttributeError: {Name: "", IsError: false},

            //method of the component to set the state after these actions are completed
            filesUpdated: false,

            ContentFile: null,
            contentTooltipShow: false,

            deleteOtherFilesTooltipShow: false,

            DocNameError: false,
            DocNameClear: false,

            FileForPrint: null,

            isCheck: false,

            LoadFilial: this.props?.filials,

            LoadTypesIsLoading: false,

            OriginalFileError: false,

            previewInfoTooltipShow: false,

            RegDateError: false,

            RegNumberClear: false,
            RegNumberError: false,

            SelectedPackages: [],

            showOriginalFilesList: false,
            showOriginalFilesTooltipShow: false,
            showOtherFilesList: false,
            showOtherFilesTooltipShow: false,

            TypeId: null,
            TypeName: null,
            saveNewComplete: {},

            externalDictionariesValues: null,
            externalDictionary: null,
            goToVersionTooltipShow: [],
            goToDocTooltipShow: [],
        };

        this.state = this.defaultState;


        this.contentRef = React.createRef();
        this.originalFileRef = React.createRef();
        this.otherFilesRef = React.createRef();
        this.previewFileRef = React.createRef();
        this.previewInfoRef = React.createRef();
        this.showOriginalFilesRef = React.createRef();
        this.showOtherFilesRef = React.createRef();
        this.goToDocumentRef = React.createRef();
        this.goToVersionRef = React.createRef();

        this.checkInput = this.checkInput.bind(this);
        this.docTypesSelectOnChange_loadAttributes = this.docTypesSelectOnChange_loadAttributes.bind(this);
        this.filialSelectOnChange_loadTypes = this.filialSelectOnChange_loadTypes.bind(this);
        this.inputHandler = this.inputHandler.bind(this);
        this.loadPackages = this.loadPackages.bind(this);
        this.showOriginalFilesList = this.showOriginalFilesList.bind(this);
        this.showOtherFilesList = this.showOtherFilesList.bind(this);
        this.changeDepartments = this.changeDepartments.bind(this);
        this.uploadDoc = this.uploadDoc.bind(this);
        this.goToDocument = this.goToDocument.bind(this);
        this.goToVersion = this.goToVersion.bind(this);
        this.getContentForVersion = this.getContentForVersion.bind(this);
        this.getContentForDoc = this.getContentForDoc.bind(this);

        this.renderAttributeName = this.renderAttributeName.bind(this);
        this.renderInput = this.renderInput.bind(this);
        this.renderDateFragment = this.renderDateFragment.bind(this);
        this.renderJsonObjectFragment = this.renderJsonObjectFragment.bind(this);
        this.renderConstRequiredSection = this.renderConstRequiredSection.bind(this);
        this.renderExternalDictionaryItem = this.renderExternalDictionaryItem.bind(this);
        this.renderDefaultInputField = this.renderDefaultInputField.bind(this);
        this.renderAttributeInput = this.renderAttributeInput.bind(this);
        this.addMultipleAllowAttributeRow = this.addMultipleAllowAttributeRow.bind(this);
    }

    componentDidMount() {
        this.props.setStateLeftAsideMenu({
            isClose: true
        });
    }

    addMultipleAllowAttributeRow(item, lIndex) {
        if (item.inputValue && item.inputValue.length > 0) {
            if (!((lIndex - 1 >= 0) && (lIndex - 1 < item.inputValue.length))) {
                const lExtensibleInputValue = __.deepCopy(item.inputValue);
                lExtensibleInputValue.splice(lIndex - 1, 0, null);
                this.inputHandler(lExtensibleInputValue, item);
            }
        } else {
            let updatedData = [];
            updatedData[lIndex - 1] = null;
            this.inputHandler(updatedData, item);
        }
    }

    renderConstRequiredSection() {
        const {DocName, RegNumber, RegDate} = this.props;
        const {DocNameError, RegNumberError} = this.state;
        let DocNameIcon = "icon-name_search";
        if (this.state.DocNameClear) { // для отображения красного крестика
            DocNameIcon = "icon-Delete icon-color-red";
        }
        let RegNumberIcon = "icon-name_search";
        if (this.state.RegNumberClear) { // для отображения красного крестика
            RegNumberIcon = "icon-Delete icon-color-red";
        }

        return (
            <>
                <h5 className="mb-2">Обязательные атрибуты</h5>
                <p className="mb-2">
                    <b>Наименование</b>
                    <sup style={{color: "#dc3545"}}>*</sup>
                </p>
                <div className="input-icon">
                    <input
                        className="form-control"
                        type="text"
                        name="name"
                        value={DocName}
                        style={DocNameError ? {borderColor: "var(--danger)"} : {}}
                        onChange={(event) => {
                            this.inputHandler(event);
                        }}
                        placeholder="Наименование документа"
                    />
                    <span>
                        <span
                            className="svg-icon svg-icon-lg"
                            onMouseEnter={() => {
                                this.setState({DocNameClear: true});
                            }}
                            onMouseLeave={() => {
                                this.setState({DocNameClear: false});
                            }}
                            onClick={(event) => this.clearInput("name")}
                        >
                          <i
                              className={`svg-icon svg-icon-lg icon-color-primary ${DocNameIcon}`}
                          />
                        </span>
                      </span>
                </div>

                <div className={"d-flex"} style={{gap: "2rem"}}>
                    <div className={"w-50"}>
                        <p className="mb-2 mt-2">
                            <b>Номер</b>
                            <sup style={{color: "#dc3545"}}>*</sup>
                        </p>
                        <div className="input-icon">
                            <input
                                className="form-control"
                                type="text"
                                name="number"
                                value={RegNumber}
                                style={RegNumberError ? {borderColor: "var(--danger)"} : {}}
                                onChange={(event) => {
                                    this.inputHandler(event);
                                }}
                                placeholder="Номер документа"
                            />
                            <span>
                                <span
                                    className="svg-icon svg-icon-lg"
                                    onMouseEnter={() => {
                                        this.setState({RegNumberClear: true});
                                    }}
                                    onMouseLeave={() => {
                                        this.setState({RegNumberClear: false});
                                    }}
                                    onClick={(event) => this.clearInput("number")}
                                >
                                  <i
                                      className={`svg-icon svg-icon-lg icon-color-primary ${RegNumberIcon}`}
                                  />
                                </span>
                          </span>
                        </div>
                    </div>

                    <div className={"w-50 position-relative"}>
                        {this.renderAttributeName({
                            AttributeName: {
                                Name: "Дата",
                                IdAttributeType: 5,
                            },
                            inputValue: RegDate,
                            uid: __.hashCode("RegDate"),
                            IsRequired: true,
                        }, "mb-2 mt-2")}
                        {this.renderDateFragment(undefined, {
                            AttributeName: {
                                Name: "Дата",
                                IdAttributeType: 5,
                            },
                            inputValue: RegDate,
                            uid: __.hashCode("RegDate"),
                            IsRequired: true,
                        }, "mb-2 mt-2", "date")}
                    </div>

                    <div className="separator separator-dashed my-8"/>
                </div>
            </>
        );
    }

    renderAttributeName(lItem, className) {
        return (
            <p className={className ?? "mb-2"}>
                {lItem.IsRequired ?
                    <>
                        <b>{lItem.AttributeName.Name}</b>
                        <sup style={{color: "#dc3545"}}>*</sup>
                    </>
                    :
                    lItem.AttributeName.Name
                }
            </p>
        );
    }

    renderInput(item, customItem, lName, lMin, lMax, lStyle) {
        let lItem = customItem ?? item;
        return (
            <input
                className="form-control"
                style={lStyle}
                type={__.inputTypeByDataTypeId(lItem.AttributeName.IdAttributeType)}
                onChange={(event) => {
                    this.inputHandler(event, item);
                }}
                name={lName}
                value={lItem.inputValue}
                min={lMin}
                max={lMax}
                placeholder={`${lItem.AttributeName.Name}...`}
            />
        );
    }

    renderDefaultInputField(item, lStyle) {
        let arrInputValue = item.inputValue;
        if (item.inputValue) {
            if (!Array.isArray(item.inputValue))
               arrInputValue = [item.inputValue]
        }

        if (item.IsMultipleAllowed) {
            return (
                <MySelectNew
                    returnFunc={(list) => {
                        let result = list.map(item => {
                            return item;
                        })
                        this.inputHandler(result, item)
                    }}
                    type={item.AttributeName.IdAttributeType}
                    placeholder={`${item.AttributeName.Name}...`}
                    dataList={arrInputValue}
                    isMulti={true}
                    styles={createSelectStyles}
                    className={''}
                    duplicateCallback={()=> {
                        store.dispatch(setModalData({
                            name: ModalTypes.app.alert,
                            data: {
                                content: "Новое значение не уникально для атрибута!",
                                disableButton: false
                            }
                        }))
                    }}
                />
            );
        } else {
            return (
                this.renderInput(item, undefined, undefined, undefined, undefined, lStyle)
            );
        }
    }


    renderAttributeInput(item, lStyle, lIcon) {
        if (item?.AttributeName?.IdDictionaries) {
            return this.renderExternalDictionaryItem(item)
        } else {
            switch (item.AttributeName.IdAttributeType) {
                case DataTypesEnum.Boolean:
                    return this.getSelectForBoolAttr({
                        val: item.inputValue,
                        onChange: (event) => {
                            this.inputHandler(event, item)
                        }
                    });
                case DataTypesEnum.DataTime:
                    if (item.IsMultipleAllowed) {
                        return this.renderDefaultInputField(item, lStyle)
                    } else {
                        return this.renderDateFragment(item)
                    }
                case DataTypesEnum.DocVersionRef:
                    if (item.IsMultipleAllowed) {
                        return (
                            <div>
                                <MultipleAttributeTable
                                    deleteRow={(lIndex) => {
                                        if (item.inputValue && item.inputValue.length > 0) {
                                            if (item.inputValue && (lIndex >= 0) && (lIndex < item.inputValue.length)) {
                                                const updatedData = __.deepCopy(item.inputValue)
                                                updatedData.splice(lIndex, 1);
                                                this.inputHandler(updatedData, item);
                                            }
                                        }
                                    }}
                                    addRow={(lIndex) => {
                                        this.addMultipleAllowAttributeRow(item, lIndex)
                                    }}
                                    item={{...item,
                                        Value: __.getCorrectValueForMultiply(item.inputValue) || []}}
                                    contents={(item, rowId) => this.getContentForVersion(item, rowId)}
                                />
                            </div>
                        );
                    } else {
                        return (
                            <table>
                                <tbody>
                                <tr>
                                    {this.getContentForVersion(item).map((lContent) => (
                                        <td style={lContent.style}>{lContent.content}</td>
                                    ))}
                                </tr>
                                </tbody>
                            </table>
                        );
                    }
                case DataTypesEnum.DocRef:
                    if (item.IsMultipleAllowed) {
                        return (
                            <div>
                                <MultipleAttributeTable
                                    deleteRow={(lIndex) => {
                                        if (item.inputValue && item.inputValue.length > 0) {
                                            if ((lIndex >= 0) && (lIndex < item.inputValue.length)) {
                                                const updatedData = __.deepCopy(item.inputValue);
                                                updatedData.splice(lIndex, 1);
                                                this.inputHandler(updatedData, item);
                                            }
                                        }
                                    }}
                                    addRow={(lIndex) => {
                                        this.addMultipleAllowAttributeRow(item, lIndex)
                                    }}
                                    item={{...item,
                                        Value: __.getCorrectValueForMultiply(item.inputValue) || []}}
                                    contents={(item, rowId) => this.getContentForDoc(item, rowId)}
                                />
                            </div>
                        );
                    } else {
                        return (
                            <>
                                <div className="input-icon">
                                    <input
                                        className="form-control"
                                        type={__.inputTypeByDataTypeId(item.AttributeName.IdAttributeType)}
                                        onChange={(event) => {
                                            this.inputHandler(event, item);
                                        }}
                                        value={item.inputValue}
                                        placeholder={`${item.AttributeName.Name}...`}
                                    />
                                    <>
                                        {this.state.goToDocumentTooltipShow && (
                                            <MyTooltip
                                                target={this.goToDocumentRef}
                                                text={"Перейти в реестр"}
                                                show={this.state.goToDocumentTooltipShow}
                                                placement={"top"}
                                                delay={500}
                                            />
                                        )}
                                        <span>
                                    <span
                                        className="svg-icon svg-icon-lg"
                                        ref={this.goToDocumentRef}
                                        onMouseEnter={() => {
                                            this.setState({goToDocumentTooltipShow: true});
                                        }}
                                        onMouseLeave={() => {
                                            this.setState({goToDocumentTooltipShow: false});
                                        }}
                                        onClick={() => {
                                            this.goToDocument(item.inputValue);
                                        }}
                                    >
                                      <i className="svg-icon icon-left_window icon-color-primary"/>
                                    </span>
                                </span>
                                    </>
                                </div>
                            </>);

                    }
                case DataTypesEnum.JsonObject:
                    if (item.IsMultipleAllowed) {
                        return (
                            <MultipleAttributeTable
                                deleteRow={(lIndex) => {
                                    if (item.inputValue && item.inputValue.length > 0) {
                                        if ((lIndex >= 0) && (lIndex < item.inputValue.length)) {
                                            const updatedData = __.deepCopy(item.inputValue);
                                            updatedData.splice(lIndex, 1);
                                            this.inputHandler(updatedData, item);
                                        }
                                    }
                                }}
                                addRow={(lIndex) => {
                                    this.addMultipleAllowAttributeRow(item, lIndex)
                                }}
                                item={{...item,
                                    Value: __.getCorrectValueForMultiply(item.inputValue) || []}}
                                contents={(item, rowId) => [{
                                    content: this.renderJsonObjectFragment(item, rowId),
                                    style: {width: "100%"}
                                }]}
                            />
                        )
                    } else {
                        return (
                            <>
                                {this.renderJsonObjectFragment(item)}
                            </>

                        )
                    }
                default:
                    return (
                        //TODO aa 01.02.2024 убрал иконки для унификации
                        // icon-barcode - если атрибут обязательный для ввода и уникальный
                        // icon-atributes_search - если атрибут обязательный для ввода и неуникальный

                        // <div className="input-icon">
                        // {this.renderDefaultInputField(item, lStyle)}
                        //     <span>
                        //       <span className="svg-icon svg-icon-lg">
                        //         <i className={`svg-icon svg-icon-lg icon-color-primary ${lIcon}`}/>
                        //       </span>
                        //     </span>
                        // </div>
                        this.renderDefaultInputField(item, lStyle)
                    );
            }
        }
    }

    renderItem(item, lStyle, lIcon) {
        if (!lIcon)
            lIcon = 'icon-barcode';
        return (
            <React.Fragment key={item.uid}>
                <div>
                    {this.renderAttributeName(item)}
                    {this.renderAttributeInput(item, lStyle, lIcon)}
                </div>
                <br/>
            </React.Fragment>
        );
    }

    renderJsonObjectFragment = (item, rowId) => {
        let lInputValue = item.inputValue;
        if (rowId) {
            if (item.inputValue && item.inputValue.length > 0 && item.inputValue[rowId - 1]) {
                lInputValue = item.inputValue[rowId - 1];
            } else {
                lInputValue = null;
            }
        }
        return <JsonObjectFragment lInputValue={lInputValue}
                                   rowId={rowId}
                                   item={item}
                                   inputHandler={this.inputHandler}
                                   isRedactorMode={true}/>
    }

    renderDateFragment(item, customItem, className, lName) {
        let lItem = customItem ?? item;

        return (
            <React.Fragment key={lItem.uid}>
                <div>
                    {this.renderInput(item, customItem, lName, "1900-01-01", "3000-12-31")}
                </div>
                <br/>
            </React.Fragment>
        );
    }

    renderExternalDictionaryItem(item) {
        const {externalDictionariesValues, externalDictionary} = this.state;

        if (!externalDictionariesValues || !externalDictionary) {
            return null;
        }
        return (
            <SearchableDictionariesSelect
                fields={item}
                onChange={(selectedValue) => {
                    this.inputHandler(selectedValue, item);
                }}
            />
        );
    }

    getContentForVersion(item, lInputValueIndex) {
        let lInputValue = item.inputValue;
        if (lInputValueIndex)
            lInputValue = item.inputValue[lInputValueIndex - 1];

        const goToVersionRef = React.createRef();
        return [
            {
                content: (
                    <>
                        {this.state.goToVersionTooltipShow[JSON.stringify({id: item.Id, rowId: lInputValueIndex})] && (
                            <MyTooltip
                                target={goToVersionRef}
                                text={"Перейти в реестр"}
                                show={this.state.goToVersionTooltipShow[JSON.stringify({
                                    id: item.Id,
                                    rowId: lInputValueIndex
                                })]}
                                placement={"top"}
                                delay={500}
                            />
                        )}
                        <span>
                        <span
                            className="svg-icon svg-icon-lg"
                            ref={goToVersionRef}
                            onMouseEnter={() => {
                                const lArrVersionForShow = __.deepCopy(this.state.goToVersionTooltipShow);
                                lArrVersionForShow[JSON.stringify({id: item.Id, rowId: lInputValueIndex})] = true;
                                this.setState({goToVersionTooltipShow: lArrVersionForShow});
                            }}
                            onMouseLeave={() => {
                                const lArrVersionForShow = __.deepCopy(this.state.goToVersionTooltipShow);
                                lArrVersionForShow[JSON.stringify({id: item.Id, rowId: lInputValueIndex})] = true;
                                this.setState({goToVersionTooltipShow: lArrVersionForShow});
                            }}
                            onClick={() => {
                                this.goToVersion(lInputValue);
                            }}
                        >
                            <i
                                style={{
                                    margin: "10px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                className="svg-icon icon-left_window icon-color-primary"
                            />
                        </span>
                      </span>
                    </>
                ),
            },
            {
                style: {width: "30%"},
                content: (
                    <input
                        className="form-control"
                        type={__.inputTypeByDataTypeId(item.AttributeName.IdAttributeType)}
                        onChange={(event) => {
                            //Если мультивыбор, то в event должен быть массив
                            if (lInputValueIndex) {
                                let lValue = event.target.value;
                                if (lValue?.indexOf("d=") != -1) {
                                    if (HelpFunctions.isValidURL(lValue)) {
                                        const url = new URL(lValue);
                                        lValue = url.searchParams.get("d");
                                    }
                                }
                                let updatedData = [];

                                if (item.inputValue && item.inputValue.length > 0) {
                                    if (!((lInputValueIndex - 1 >= 0) && (lInputValueIndex - 1 < item.inputValue.length))) {
                                        const lExtensibleInputValue = __.deepCopy(item.inputValue)
                                        lExtensibleInputValue.splice(lInputValueIndex - 1, 0, JSON.stringify({
                                            docId: lValue,
                                        }));
                                        updatedData = lExtensibleInputValue;
                                    } else {
                                        updatedData = item.inputValue.map((row, index) => {
                                            if (index === lInputValueIndex - 1) {
                                                return JSON.stringify({
                                                    docId: lValue,
                                                });
                                            }
                                            return row;
                                        });
                                    }
                                } else {
                                    updatedData[lInputValueIndex - 1] = JSON.stringify({
                                        docId: lValue,
                                    })
                                }
                                event = updatedData;
                            }


                            this.inputHandler(event, item);
                        }}
                        value={(HelpFunctions.isJSON(lInputValue) && JSON.parse(lInputValue)?.docId) ? JSON.parse(lInputValue).docId : ""}
                        placeholder={`Документ...`}
                    />
                ),
            },
            {
                style: {width: "70%"},
                content: (
                    <Select
                        loadOptions={this.loadVersionOptions}
                        onMenuOpen={() => this.handleMenuOpen(lInputValueIndex, item)}
                        options={this.state.options}
                        placeholder={"Выберите версию документа"}
                        onChange={(event) => {
                            let newElementIsDuplicate = false
                            //Если мультивыбор, то в event должен быть массив
                            if (lInputValueIndex) {
                                const parseFromJson = (el) => {
                                    return HelpFunctions.isJSON(el) ? JSON.parse(el) : null
                                }
                                const lObjEvent = event;
                                newElementIsDuplicate = (lObjEvent.value && lObjEvent.value !== '' && lObjEvent.value !== parseFromJson(item.inputValue[lInputValueIndex - 1])?.value && item.inputValue.some(el => parseFromJson(el)?.value?.toString() === lObjEvent.value?.toString()));

                                let lValue = newElementIsDuplicate ? '' : JSON.stringify({
                                    docId: parseFromJson(item.inputValue[lInputValueIndex - 1])?.docId,
                                    value: lObjEvent?.value,
                                    label: lObjEvent?.label,
                                });
                                let updatedData = [];

                                if (item.inputValue && item.inputValue.length > 0) {
                                    if (!((lInputValueIndex - 1 >= 0) && (lInputValueIndex - 1 < item.inputValue.length))) {
                                        const lExtensibleInputValue = __.deepCopy(item.inputValue)
                                        lExtensibleInputValue.splice(lInputValueIndex - 1, 0, lValue);
                                        updatedData = lExtensibleInputValue;
                                    } else {
                                        updatedData = item.inputValue.map((row, index) => {
                                            if (index === lInputValueIndex - 1) {
                                                return lValue;
                                            }
                                            return row;
                                        });
                                    }
                                } else {
                                    updatedData[lInputValueIndex - 1] = lValue
                                }
                                event = updatedData;
                            }
                            this.inputHandler(event, item);
                            if (newElementIsDuplicate) {
                                store.dispatch(setModalData({
                                    name: ModalTypes.app.alert,
                                    data: {
                                        content: "Новое значение не уникально для атрибута!",
                                        disableButton: false
                                    }
                                }))
                            }
                        }}
                        value={(HelpFunctions.isJSON(lInputValue) && JSON.parse(lInputValue)?.value) ? {
                            value: JSON.parse(lInputValue).value,
                            label: JSON.parse(lInputValue).label
                        } : null}
                        isLoading={this.state.isLoading}
                        styles={sbVersionSelect}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                text: "var(--hover-primary)",
                                primary25: "var(--light-primary)",
                                primary50: "var(--hover-primary)",
                                primary: "var(--originaltwo)",
                            },
                        })}
                        isClearable={true}
                        noOptionsMessage={() => "Необходимо сначала выбрать документ"}
                        loadingMessage={() => "Загрузка..."}
                    />
                ),
            },
        ];
    }

    getContentForDoc(item, lInputValueIndex) {
        let lInputValue = item.inputValue;
        if (lInputValueIndex)
            lInputValue = item.inputValue[lInputValueIndex - 1];

        const goToDocRef = React.createRef();
        return [
            {
                content: (
                    <>
                        {this.state.goToDocTooltipShow[JSON.stringify({id: item.Id, rowId: lInputValueIndex})] && (
                            <MyTooltip
                                target={goToDocRef}
                                text={"Перейти в реестр"}
                                show={this.state.goToDocTooltipShow[JSON.stringify({
                                    id: item.Id,
                                    rowId: lInputValueIndex
                                })]}
                                placement={"top"}
                                delay={500}
                            />
                        )}
                        <span>
                        <span
                            className="svg-icon svg-icon-lg"
                            ref={goToDocRef}
                            onMouseEnter={() => {
                                const lArrVersionForShow = __.deepCopy(this.state.goToDocTooltipShow);
                                lArrVersionForShow[JSON.stringify({id: item.Id, rowId: lInputValueIndex})] = true;
                                this.setState({goToDocTooltipShow: lArrVersionForShow});
                            }}
                            onMouseLeave={() => {
                                const lArrVersionForShow = __.deepCopy(this.state.goToDocTooltipShow);
                                lArrVersionForShow[JSON.stringify({id: item.Id, rowId: lInputValueIndex})] = true;
                                this.setState({goToDocTooltipShow: lArrVersionForShow});
                            }}
                            onClick={() => {
                                this.goToDocument(lInputValue);
                            }}
                        >
                            <i
                                style={{
                                    margin: "10px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                className="svg-icon icon-left_window icon-color-primary"
                            />
                        </span>
                      </span>
                    </>
                ),
            },
            {
                style: {width: "100%"},
                content: (
                    <input
                        className="form-control"
                        type={__.inputTypeByDataTypeId(item.AttributeName.IdAttributeType)}
                        onChange={(event) => {
                            let newElementIsDuplicate = false
                            //Если мультивыбор, то в event должен быть массив
                            if (lInputValueIndex) {
                                let lValue = event.target.value;
                                if (lValue?.indexOf("d=") !== -1) {
                                    if (HelpFunctions.isValidURL(lValue)) {
                                        const url = new URL(lValue);
                                        lValue = url.searchParams.get("d");
                                    }
                                }

                                const parseFromJson = (el) => {
                                    return HelpFunctions.isJSON(el) ? JSON.parse(el) : null
                                }
                                newElementIsDuplicate = (lValue && lValue !== '' && lValue !== parseFromJson(item.inputValue[lInputValueIndex - 1])?.docId && item.inputValue.some(el => parseFromJson(el)?.docId?.toString() === lValue.toString()));

                                let updatedData = [];

                                if (item.inputValue && item.inputValue.length > 0) {
                                    if (!((lInputValueIndex - 1 >= 0) && (lInputValueIndex - 1 < item.inputValue.length))) {
                                        const lExtensibleInputValue = __.deepCopy(item.inputValue)
                                        lExtensibleInputValue.splice(lInputValueIndex - 1, 0, newElementIsDuplicate ? '' : JSON.stringify({
                                            docId: lValue,
                                            value: null,
                                            label: null,
                                        }));
                                        updatedData = lExtensibleInputValue;
                                    } else {
                                        updatedData = item.inputValue.map((row, index) => {
                                            if (index === lInputValueIndex - 1) {
                                                return newElementIsDuplicate ? '' : JSON.stringify({
                                                    docId: lValue,
                                                    value: null,
                                                    label: null
                                                });
                                            }
                                            return row;
                                        });
                                    }
                                } else {
                                    updatedData[lInputValueIndex - 1] = newElementIsDuplicate ? '' : JSON.stringify({
                                        docId: lValue,
                                        value: null,
                                        label: null
                                    })
                                }
                                event = updatedData;
                            }
                            this.inputHandler(event, item);
                            if (newElementIsDuplicate) {
                                store.dispatch(setModalData({
                                    name: ModalTypes.app.alert,
                                    data: {
                                        content: "Новое значение не уникально для атрибута!",
                                        disableButton: false
                                    }
                                }))
                            }
                        }}
                        value={(HelpFunctions.isJSON(lInputValue) && JSON.parse(lInputValue)?.docId) ? JSON.parse(lInputValue).docId : ""}
                        placeholder={`Документ...`}
                    />
                ),
            },
        ];
    }

    async loadVersionOptions(inputValue, callback, lInputValueIndex, item) {
        this.setState({isLoading: true});
        const lDocRef = item ?? this.props.ActiveAttributes.find(item => item.AttributeName.IdAttributeType === DataTypes.DocVersionRef);
        let lInputValue = null;
        if (lDocRef && lDocRef.inputValue)
            lInputValue = lInputValueIndex ? lDocRef.inputValue[lInputValueIndex - 1] : lDocRef.inputValue;
        if (lDocRef && lDocRef.inputValue && HelpFunctions.isJSON(lInputValue) && JSON.parse(lInputValue).docId) {
            const options = await fetchVersionOptions(JSON.parse(lInputValue).docId);
            const filteredOptions = options.filter(option =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
            );
            callback(filteredOptions);
        } else {
            this.setState({options: []})
        }
        this.setState({isLoading: false});
    }

    handleMenuOpen = (lInputValueIndex, item) => {
        // Вызов функции загрузки опций при раскрытии списка
        this.loadVersionOptions('', options => {
            // Обновление опции в состоянии компонента
            this.setState({options});
        }, lInputValueIndex, item);
    };

    async goToVersion(docVersion) {
        if (!docVersion)
            return;

        if (HelpFunctions.isJSON(docVersion) && JSON.parse(docVersion)?.value && JSON.parse(docVersion)?.docId) {
            //1. Записываем ID версии для локейта
            this.props.setVersionIdForLocate(JSON.parse(docVersion)?.value);
            //2. Переходим к документу черех роутинг
            await this.goToDocument(JSON.parse(docVersion)?.docId);
        } else {
            store.dispatch(setModalData({
                name: ModalTypes.app.info,
                data: {
                    content: "Не выбрана версия для перехода",
                    disableButton: false,
                    type: "fail"
                }
            }));
        }
    }

    async goToDocument(docId) {
        await __.goToDocumentsByDocId({
            IdFilial: this.props.ActiveIdFilial.value ?? null,
            docId: docId,
            successAdditionalLogic: () => {
                this.props.setStateLeftAsideMenu({
                    isClose: false,
                    rootName: "DocumentApp"
                })
            }
        })
    }

    changeDepartments() {
        this.props.setModalData({
            name: ModalTypes.documents.mainTable.ChangeDepartments,
            data: {isMaster: true}
        });
    }

    checkInput() {

        const lDocVersionRef = this.props.ActiveAttributes.filter(item => item.AttributeName.IdAttributeType === DataTypes.DocVersionRef)
        if (lDocVersionRef && lDocVersionRef.length > 0) {
            if (!lDocVersionRef.IsMultipleAllowed) {
                if (lDocVersionRef.inputValue && HelpFunctions.isJSON(lDocVersionRef.inputValue) && !JSON.parse(lDocVersionRef.inputValue)?.value) {
                    this.props.setModalData({
                        name: ModalTypes.app.info,
                        data: {
                            type: "fail",
                            content: `Ошибка заполнения: <br/>Версия документа обязательна к заполнению`
                        }
                    });
                    return;
                }
            } else {
                if (lDocVersionRef.inputValue && lDocVersionRef.inputValue.length > 0) {
                    for (let i in lDocVersionRef.inputValue) {
                        if (lDocVersionRef.inputValue[i] && HelpFunctions.isJSON(lDocVersionRef.inputValue[i]) && !JSON.parse(lDocVersionRef.inputValue[i])?.value) {
                            this.props.setModalData({
                                name: ModalTypes.app.info,
                                data: {
                                    type: "fail",
                                    content: `Ошибка заполнения: <br/>Версия документа обязательна к заполнению`
                                }
                            });
                            return;
                        }
                    }
                }
            }
        }
        // проверка заполнения
        if (this.props.DocName === "" || this.props.DocName === null) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content: `Ошибка заполнения: <br/>Наименование документа обязательно к заполнению`}
            });
            this.setState({DocNameError: true});
            return;
        }

        if (this.props.RegNumber === "" || this.props.RegNumber === null) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content: `Ошибка заполнения: <br/>Номер документа обязателен к заполнению`}
            });
            this.setState({RegNumberError: true});
            return;
        }

        if (this.props.RegDate === "" || this.props.RegDate === null) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content: `Ошибка заполнения: <br/>Дата документа обязательна к заполнению`}
            });
            this.setState({RegDateError: true});
            return;
        }

        if (this.props.Comment === "" || this.props.Comment === null) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {
                    type: "fail",
                    content: `Ошибка заполнения: <br/>Комментарий для документа обязателен к заполнению`
                }
            });
            return;
        }

        for (let i = 0; i < this.props.ActiveAttributes.length; i++) {
            if (this.props.ActiveAttributes[i].inputValue === "" && this.props.ActiveAttributes[i].IsRequired) {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {
                        type: "fail",
                        content: `Ошибка заполнения: <br/>Атрибут "${this.props.ActiveAttributes[i].AttributeName.Name}" обязателен к заполнению`
                    }
                });
                this.setState({
                    AttributeError: {
                        Name: this.props.ActiveAttributes[i].AttributeName.Name,
                        IsError: true
                    }
                });
                return;
            }
        }

        // проверка файлов
        // заполнение файлов
        if (store.getState().globalState.settings.Content?.WithOriginalFilePdf == 1) {
            if (this.props.OriginalFile == null) {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {
                        type: "fail",
                        content: `Ошибка заполнения: <br/>Необходимо выбрать оригинальный файл документа (.pdf)`
                    }
                });
                this.setState({OriginalFileError: true});
                return;
            }

            // размер файла
            if (this.props.OriginalFile.size > 1024 * 1024 * 250) {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {
                        type: "fail",
                        content: `Ошибка заполнения: <br/>Максимальный размер оригинального файла документа не более 250мб`
                    }
                });
                this.setState({OriginalFileError: true});
                return;
            }

            for (let i = 0; i < this.props.OtherFiles.length; i++) {
                if (this.props.OtherFiles[i].size > 1024 * 1024 * 250) {
                    this.props.setModalData({
                        name: ModalTypes.app.info,
                        data: {
                            type: "fail",
                            content: `Ошибка заполнения: <br/>Максимальный размер файла "${this.props.OtherFiles[i].name}" не может превышать 250мб`
                        }
                    });
                    return;
                }
            }

            // расширение файла
            if (this.props.OriginalFile.name.split('.').pop() !== "pdf") {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {
                        type: "fail",
                        content: `Ошибка заполнения: <br/>Оригинальный файл документа должен иметь расширение .pdf`
                    }
                });
                this.setState({OriginalFileError: true});
                return;
            }
        }
        if (this.props.PreviewFile)
            if (this.props.PreviewFile.name.split('.').pop() !== "png") {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {type: "fail", content: `Ошибка заполнения: <br/>Файл документа должен иметь расширение .png`}
                });
                return;
            }

        // проверка типов
        for (let i = 0; i < this.props.ActiveAttributes.length; i++) {
            let idType = this.props.ActiveAttributes[i].AttributeName.IdAttributeType;
            let attrType = __.inputTypeByDataTypeId(idType);

            if (attrType === "datetime-local") {
                if (Array.isArray(this.props.ActiveAttributes[i].inputValue)) {
                    this.props.ActiveAttributes[i].inputValue = this.props.ActiveAttributes[i].inputValue.map(el => __.getCorrectDate(el))
                } else {
                    this.props.ActiveAttributes[i].inputValue = __.getCorrectDate(this.props.ActiveAttributes[i].inputValue);
                }
            }
        }

        this.setState({IsCheck: true});
        this.uploadDoc();
        // TODO очищение формы перенесено в функцию uploadDoc (чтобы очищалась форма после успешной загрузки документа, а не после любой ошибки)
        // this.setState({OriginalFile : null, PreviewFile: null, OtherFiles: []});
    }

    async clearInput(name) {
        if (name === "name") {
            this.props.setDocName("");
        } else if (name === "number") {
            this.props.setRegNumber("");
        } else if (name === "date") {
            this.props.setRegDate("");
        } else if (name === "comment") {
            this.props.setComment("");
        }
    }

    async docTypesSelectOnChange_loadAttributes(item) {
        let selectedType = __.deepCopy(this.props.LoadTypes.find(type => type.Id == item.value));
        this.props.setSelectedGroups(null);
        this.props.setTypeValue(item);

        let externalDictionariesValues = this.state.externalDictionariesValues;
        let externalDictionary = this.state.externalDictionary;
        const attributesWithExternalDictionaries = selectedType.TypeAttributes.filter(item => item.AttributeName.IdDictionaries).map(item => item.AttributeName.IdDictionaries)
        if (selectedType.TypeAttributes.find(typeAttribute => typeAttribute?.AttributeName?.IdDictionaries) && !externalDictionariesValues && !externalDictionary) {
            externalDictionariesValues = await API.dictionary().getDictionaryValues({
                filter : new Filter(FiltersType.ENTITY).add(
                    new EntityFilters().setEntityName("DictionaryValue").add({
                        Name : "IdDictionaries",
                        Value : attributesWithExternalDictionaries,
                        Operator: Operators.In
                    }).get()
                ),
                sorting: null
            });
            externalDictionary = await API.dictionary().getExternalDictionaries({filter: null, sorting: null});
            this.setState({
                externalDictionariesValues,
                externalDictionary
            })
        }

        for (let i = 0; i < selectedType.TypeAttributes.length; i++) {
            if (selectedType.TypeAttributes[i].AttributeName.IdDictionaries) {
                selectedType.TypeAttributes[i].uid = createUniqueIdString(6);
                selectedType.TypeAttributes[i].inputValue = {
                    value: null,
                    label: null
                }
            } else {
                selectedType.TypeAttributes[i].uid = createUniqueIdString(6);
                selectedType.TypeAttributes[i].inputValue = null;
            }

        }

        this.props.setShowBanner(false);

        this.props.setActiveType(selectedType.Id);
        this.props.setActiveAttributes(selectedType.TypeAttributes);
    }

    filialSelectOnChange_loadTypes(item) {
        this.props.setActiveIdFilial(item)
        this.props.setSelectedGroups(null)
        this.props.setTypeValue({
            value: null,
            label: "Не выбрано",
            isDisabled: true
        })

        this.setState({
            filesUpdated: true
        }, () => {
            let request_types = new apiRequest(apiUrl.DOCUMENTS.DOC_TREE, {
                filter: {IdFilial: this.props.ActiveIdFilial.value},
                sorting: new Sorting().add({Direction: 1, PropertyName: "Name"}).get()
            });
            let request_groups = new apiRequest(apiUrl.DOCUMENTS.DOC_TREE_GROUP, {
                filter: {IdFilial: this.props.ActiveIdFilial.value},
                sorting: new Sorting()
                    .add({Direction: 1, PropertyName: "Name"})
                    .add({Direction: 1, PropertyName: "GroupOrder"})
                    .get()
            });
            request_groups.execute((data) => {
                this.props.setLoadGroups(
                    data.Records.sort((a, b) => {
                        return a.GroupOrder - b.GroupOrder
                    })
                );
            }, (data) => {
                console.log("Ошибка загрузки списка групп документов: ", data)
            });
            this.setState({LoadTypesIsLoading: true})
            request_types.execute((data) => {
                this.props.setLoadTypes(data.Records);
                this.setState({LoadTypesIsLoading: false})
            }, (data) => {
                console.log("Ошибка загрузки списка типов документов: ", data)
                this.setState({LoadTypesIsLoading: false})
            });

        });
        this.props.setDepartmentCollection([])
    }

    getSelectForBoolAttr({val: value, onChange: onChangeHandler}) {
        let options = [
            {value: "", label: "Не выбрано"},
            {value: "1", label: "Да"},
            {value: "0", label: "Нет"},
        ]
        return (
            <React.Fragment>
                <Select
                    styles={sbSelect}
                    defaultValue={options[0]}
                    onChange={onChangeHandler}
                    options={options}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            text: 'var(--hover-primary)',
                            primary25: 'var(--light-primary)',
                            primary50: 'var(--hover-primary)',
                            primary: 'var(--originaltwo)',
                        },
                    })}
                />
            </React.Fragment>
        )
    }

    inputHandler(event, item = null) {
        if (item == null || !item.IsMultipleAllowed) {
            let value = event ? (event.target?.value ?? event.value) : null;

            if (typeof value === 'string' && value.trim()==='')
                value = null;

            if (item == null) {
                let nameInput = event.target.name;
                if (nameInput === "name") {
                    this.props.setDocName(value);
                    this.setState({DocNameError: value === ""});
                } else if (nameInput === "number") {
                    this.props.setRegNumber(value);
                    this.setState({RegNumberError: value === ""});
                } else if (nameInput === "date") {
                    if (new Date() < new Date(value)) {
                        value = new Date().toLocaleDateString().split('.').reverse().join('-')
                    }
                    this.props.setRegDate(value);
                    this.setState({RegDateError: value === ""});
                } else if (nameInput === "comment") {
                    this.props.setComment(value);
                } else if (nameInput === "printCheckbox") {
                    this.setState({AdditionalFileForPrinting: event.target.checked});
                }
                // else if (nameInput === "pageCount") {
                //     this.setState({FileForPrint_Pages: value});
                // }
            } else {
                if (item.AttributeName.IdDictionaries) {
                    value = event
                } else if (item.AttributeName.IdAttributeType === DataTypes.DocRef && value) {
                    if (value.indexOf("d=") != -1) {
                        if (HelpFunctions.isValidURL(value)) {
                            const url = new URL(value);
                            value = url.searchParams.get("d");
                        }
                    }
                } else if (item.AttributeName.IdAttributeType === DataTypes.DocVersionRef) {
                    if (event && ("value" in event) && ("label" in event)) {
                        const lObjEvent = event
                        value = JSON.stringify({
                            docId: HelpFunctions.isJSON(item.inputValue) ? JSON.parse(item.inputValue).docId : null,
                            value: lObjEvent.value,
                            label: lObjEvent.label
                        });

                    } else {
                        // lDocVersionRef=this.props.ActiveAttributes.find(item => item.AttributeName.IdAttributeType === DataTypes.DocVersionRef)

                        if (value?.indexOf("d=") != -1) {
                            if (HelpFunctions.isValidURL(value)) {
                                const url = new URL(value);
                                value = JSON.stringify({docId: url.searchParams.get("d"), value: null, label: null});
                            }
                        } else {
                            value = JSON.stringify({docId: value, value: null, label: null});
                        }
                    }
                }

                // TODO Насчет !item.IsConstant не уверен что он здесь нужен, но все вроде работает)
                // if (item.AttributeName.IdAttributeType === DataTypes.DataTime && !item.IsConstant) {
                //     TODO Пердположительно вернуть! Там надо где то просто сделать __.dateToServer(value, false, true)
                //     if (Array.isArray(value)) {
                //         value = value.map(el => __.getCorrectDate(el))
                //     } else {
                //         value = __.getCorrectDate(value);
                //     }
                // }

                let ActiveAttributes = __.deepCopy(this.props.ActiveAttributes);
                let index = ActiveAttributes.findIndex(attr => attr.uid === item.uid);

                ActiveAttributes[index].inputValue = value;

                this.setState({AttributeError: {...this.state.AttributeError, IsError: value === ""}});
                this.props.setActiveAttributes(ActiveAttributes);
            }
        } else {
            let ActiveAttributes = __.deepCopy(this.props.ActiveAttributes);
            let index = ActiveAttributes.findIndex(attr => attr.uid === item.uid);
            ActiveAttributes[index].inputValue = event;
            this.setState({AttributeError: {...this.state.AttributeError, IsError: !event || event?.length === 0}});
            this.props.setActiveAttributes(ActiveAttributes);
        }
    }

    async uploadDoc() {

        this.props.setModalData({
            name: ModalTypes.app.alert,
            data: {
                content: "Загрузка документа...",
                disableButton: true,
                fullBackground: false,
                gif: "documents"
            }
        });

        try {
            let type = this.props.LoadTypes.find(type => type.Id === this.props.ActiveType);

            let docHeader = {
                Name: this.props.DocName,
                Type: {
                    Id: type.Id,
                    Name: type.Name
                },
                IdFilial: this.props.ActiveIdFilial.value,
                RegDate: this.props.RegDate + "T00:00:00",
                RegNumber: this.props.RegNumber
            };

            let docAttributes = [];
            for (const attr of this.props.ActiveAttributes) {
                let lAttributeValue = "";
                if (attr.IsMultipleAllowed) {
                    if (attr.inputValue && attr.inputValue.length > 0) {
                        for (let i in attr.inputValue) {
                            if (attr.AttributeName.IdDictionaries) {
                                if (attr.inputValue[i] && attr.inputValue[i].value && attr.inputValue[i] && attr.inputValue[i].label) {
                                    docAttributes.push({
                                        AttributeName: attr.AttributeName,
                                        AttributeValue: attr.inputValue[i].label,
                                        IdDictionaryValue: attr.inputValue[i].value
                                    });

                                } else {
                                    docAttributes.push({
                                        AttributeName: attr.AttributeName,
                                        AttributeValue: null,
                                        IdDictionaryValue: null
                                    });
                                }
                            } else {
                                if (attr.AttributeName.IdAttributeType == 5 && attr.inputValue[i]) {
                                    if (Array.isArray(attr.inputValue[i])) {
                                        lAttributeValue = attr.inputValue[i].map(el => __.getCorrectDate(el))
                                    } else {
                                        lAttributeValue = __.getCorrectDate(attr.inputValue[i]);
                                    }
                                } else if (attr.AttributeName.IdAttributeType == DataTypes.DocVersionRef && attr.inputValue[i]) {
                                    if (HelpFunctions.isJSON(attr.inputValue[i]) && JSON.parse(attr.inputValue[i]).value) {
                                        lAttributeValue = JSON.stringify(JSON.parse(attr.inputValue[i]).value)
                                    }
                                } else if (attr.AttributeName.IdAttributeType == DataTypes.DocRef && attr.inputValue[i]) {
                                    if (HelpFunctions.isJSON(attr.inputValue[i]) && JSON.parse(attr.inputValue[i]).docId) {
                                        lAttributeValue = JSON.parse(attr.inputValue[i]).docId.toString();
                                    }
                                } else {
                                    lAttributeValue = attr.inputValue[i]
                                }

                                docAttributes.push({
                                    AttributeName: attr.AttributeName,
                                    AttributeValue: lAttributeValue
                                });
                            }
                        }
                    }
                    else {
                        if (attr.AttributeName.IdDictionaries) {
                            docAttributes.push({
                                AttributeName: attr.AttributeName,
                                AttributeValue: null,
                                IdDictionaryValue: null
                            });
                        } else {
                            docAttributes.push({
                                AttributeName: attr.AttributeName,
                                AttributeValue: null
                            });
                        }
                    }
                } else {

                    if (attr?.AttributeName?.IdDictionaries) {
                        if (attr.inputValue.value && attr.inputValue.label) {
                            docAttributes.push({
                                AttributeName: attr.AttributeName,
                                AttributeValue: attr.inputValue.label,
                                IdDictionaryValue: attr.inputValue.value
                            });
                        } else {
                            docAttributes.push({
                                AttributeName: attr.AttributeName,
                                AttributeValue: null,
                                IdDictionaryValue: null
                            });
                        }
                    } else {
                        if (attr.AttributeName.IdAttributeType == 5 && attr.inputValue) {
                            if (Array.isArray(attr.inputValue)) {
                                lAttributeValue = attr.inputValue.map(el => __.getCorrectDate(el))
                            } else {
                                lAttributeValue = __.getCorrectDate(attr.inputValue);
                            }
                        } else if (attr.AttributeName.IdAttributeType == DataTypes.DocVersionRef && attr.inputValue) {
                            if (HelpFunctions.isJSON(attr.inputValue) && JSON.parse(attr.inputValue).value) {
                                lAttributeValue = JSON.stringify(JSON.parse(attr.inputValue).value)
                            }
                        } else {
                            lAttributeValue = attr.inputValue
                        }
                        docAttributes.push({
                            AttributeName: attr.AttributeName,
                            AttributeValue: lAttributeValue
                        });
                    }
                }
            }
            // }

            let fileInfoList = [];
            if (this.props.OriginalFile) {
                fileInfoList.push({
                    Name: this.props.OriginalFile.name,
                    Type: 3,
                    Size: this.props.OriginalFile.size,
                    Description: "Основной файл",
                    // TODO SS 23692
                    PagesCount: 1, //this.state.FileForPrint_Pages,
                    PagesFormat: 1, //this.state.FileForPrint_Format
                });
            }

            for (const file of this.props.OtherFiles) {
                fileInfoList.push({
                    Name: file.name,
                    Type: 4,
                    Size: file.size,
                    Description: "---",
                });
            }

            if (this.props.PreviewFile) {
                fileInfoList.push({
                    Name: this.props.PreviewFile.name,
                    Type: 2,
                    Size: this.props.PreviewFile.size,
                    Description: "Файл предпросмотра",
                });
            }


            let body = {
                docHeader,
                docAttributes,
                comment: this.props.Comment,
                fileInfoList
            };

            let DocSaveRequest = await API.documents().saveNew(body);

            if (DocSaveRequest?.errorCode) {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {type: "fail", content: `Ошибка загрузки: <br/>${DocSaveRequest.message.Message}`}
                });
                return;
            }

            let lOtherFiles = [];
            let lFile;
            for (let i = 0; i < DocSaveRequest.length; i++) {
                let file;

                if (fileInfoList[i].Type === 2) {
                    file = this.props.PreviewFile;
                }

                if (fileInfoList[i].Type === 3) {
                    file = this.props.OriginalFile;
                }

                if (fileInfoList[i].Type === 4) {
                    if (fileInfoList[i - 1].Type === 4) {
                    } else {
                        let requestOtherFiles = [];
                        const lTempOtherFiles = Array.from([...this.props.OtherFiles]);

                        for (let j = 0; j < this.props.OtherFiles.length; j++) {

                            lTempOtherFiles[j].guid = DocSaveRequest[i + j];
                            requestOtherFiles[j] = await API.documents().uploadDocFiles({
                                file: this.props.OtherFiles[j],
                                Guid: DocSaveRequest[i + j]
                            });

                            if (requestOtherFiles[j]?.errorCode) {
                                this.props.setModalData({
                                    name: ModalTypes.app.info,
                                    data: {
                                        type: "fail",
                                        content: `Ошибка загрузки: <br/>${requestOtherFiles[j].message.Message}`
                                    }
                                });
                                return;
                            }
                        }
                        lOtherFiles = lOtherFiles.concat(lTempOtherFiles);
                    }
                }


                if (fileInfoList[i].Type !== 4) {
                    lFile = __.deepCopy(Array.from([...[file]]));
                    lFile[0].guid = DocSaveRequest[i];
                    lOtherFiles = lOtherFiles.concat(lFile);
                    let request = await API.documents().uploadDocFiles({
                        file: file,
                        Guid: DocSaveRequest[i]
                    });


                    if (request?.errorCode) {
                        this.props.setModalData({
                            name: ModalTypes.app.info,
                            data: {type: "fail", content: `Ошибка загрузки: <br/>${request.message.Message}`}
                        });
                        return;
                    }
                }

                let getUploadFilesProgress = await API.documents().getUploadFilesProgress({
                    uploadGuid: DocSaveRequest[i]
                });

                if (getUploadFilesProgress?.errorCode) {
                    this.props.setModalData({
                        name: ModalTypes.app.info,
                        data: {
                            type: "fail",
                            content: `Ошибка загрузки: <br/>${getUploadFilesProgress.message.Message}`
                        }
                    });
                    return;
                }

                if (getUploadFilesProgress.IsActive || getUploadFilesProgress.UploadedBytes !== getUploadFilesProgress.WholeBytes) {
                    this.props.setModalData({
                        name: ModalTypes.app.info,
                        data: {type: "fail", content: `Ошибка загрузки: <br/> Документ : ${i}`}
                    });
                    return;
                }
            }


            let saveNewCompleteExtended = await API.documents().saveNewCompleteExtended({commit: true});
            let saveNewComplete = saveNewCompleteExtended.DocVersion;
            saveNewComplete.idFilial = this.props.ActiveIdFilial.value

            //TODO для точного соответствия файлов, исключение ситуации файлов с одним именем и размером
            if (saveNewCompleteExtended?.errorCode) {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {type: "fail", content: `Ошибка загрузки: <br/>${saveNewCompleteExtended.message.Message}`}
                });
                return;
            }

            if (saveNewComplete.IdDoc) {
                let collection = this.props.collectionMaster;
                collection = __.deepCopy(collection);
                for (let i = 0; i < collection.length; i++) {
                    collection[i].IdDoc = saveNewComplete.IdDoc
                }
                this.props.SaveOrUpdateAndDeleteByFilter({collection: collection, IdDoc: saveNewComplete.IdDoc})
                store.dispatch({
                    type: DOCUMENT_PREVIEW_ACTIONS.UPDATE_DATA_FOR_DEPARTMENTS_SECTION,
                    payload: {departmentsSection: collection}
                })
            }

            //>-------------------------ЭП-----------------------
            if (this.props.SelectedGroups?.length > 0) {
                let collection = [];
                for (let i = 0; i < this.props.SelectedGroups.length; i++) {
                    collection.push(
                        {
                            Id: saveNewComplete.Id,
                            IdDoc: saveNewComplete.IdDoc,
                            IdDocGroup: this.props.SelectedGroups[i].value
                        }
                    )
                }
                let request = new apiRequest(apiUrl.DOCUMENTS.SAVE_DOC_TREE_SECTION, {
                    collection: collection
                });
                await request.execute(function (data) {
                }, function (data) {
                    if (data.status === 401) {
                        this.props.setModalData({
                            name: ModalTypes.app.info,
                            data: {type: "fail", content: `Ошибка помещенния в реестр: <br/>${data}`}
                        });
                        return;
                    }
                    console.log("Ошибка получения дерева группы документов (post): ", data);
                });
            }
            //<-------------------------ЭП новый метод -----------------------
            let filter = new Filter(FiltersType.ENTITY).add(
                new EntityFilters().setEntityName("DocFile")
                    .add({Name: "IdDoc", Value: saveNewComplete.IdDoc})
                    .add({Name: "IdDocVersion", Value: saveNewComplete.Id})
                    .get()
            ).get()
            const lFilesLoad = await API.documents().getFiles({
                filter,
                sorting: null
            });

            if (this.props.ContentText?.length > 0) {
                let idFile = lFilesLoad.Records.reverse().find(item => item.Type == 1)?.Id
                if (idFile) {
                    let response = await new apiRequestNew({
                        action: apiUrl.DOCUMENTS.UPDATE_DOC_TEXT,
                        headers: null,
                        params: {
                            id: idFile,
                            text: this.props.ContentText
                        }
                    }).execute();
                    if (!response.ok) {
                        store.dispatch(setModalData({
                            name: ModalTypes.app.alert,
                            data: {
                                content: "Во время сохранения документа произошла ошибка: Обновление содержания",
                                disableButton: false
                            }
                        }));
                        return;
                    }
                }
            }


            for (let j = 0; j < lOtherFiles.length; j++) {
                let lFoundFile = lFilesLoad.Records.filter(item =>
                    item.Id == saveNewCompleteExtended.GuidToDocFileCollection.find(item => item.Guid == lOtherFiles[j].guid).IdDocFile)
                if (lFoundFile && lFoundFile.length > 0) {
                    if (lOtherFiles[j].signatures)
                        for (let iSignature = 0; iSignature < lOtherFiles[j].signatures.length; iSignature++) {
                            let lData = new FormData();
                            lData.append("description", "Электронная подпись");

                            let files = lOtherFiles[j].signatures[iSignature];
                            files.id = createUniqueIdString(8);
                            files.description = "Электронная подпись";
                            files.new_name = lOtherFiles[j].signatures[iSignature].name;
                            lData.append("signature", files, lOtherFiles[j].signatures[iSignature].name);

                            await API.signatures().attach({
                                idDocFile: lFoundFile[0].Id,
                                FormData: lData,
                            });
                        }
                }
            }

            //>-------------------------ЭП-----------------------


            //добавление документа в выбранные пакеты
            if (this.state.SelectedPackages?.length > 0) {
                let collection = this.state.SelectedPackages.map(item => ({
                    IdDoc: saveNewComplete.IdDoc,
                    IdPackage: item.value
                }));
                let moveInPackages = await API.packages().saveOrUpdateCollection({collection: collection});

                if (moveInPackages?.errorCode) {
                    this.props.setModalData({
                        name: ModalTypes.app.info,
                        data: {type: "fail", content: `Ошибка загрузки в пакет: <br/>${moveInPackages.message.Message}`}
                    });
                    return;
                }
            }

            this.props.setOriginalFile(null);
            this.props.setPreviewFile(null);
            this.props.setOtherFiles([]);
            this.setState({filesUpdated: true}, () => {
                // очищение формы с файлами, т.к. было невозможно после ошибки загрузить тот же файл
                this.originalFileRef.current.value = "";
                this.previewFileRef.current.value = "";
                this.otherFilesRef.current.value = "";
            });

            store.dispatch({type: TREE_ACTIONS.RELOAD, payload: {}});


            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "success", content: `Документ успешно загружен`}
            });

            this.props.setOriginalFile(null);
            this.props.setPreviewFile(null);
            this.setState({
                ContentFile: "",
            })

            if (saveNewComplete) {
                this.props.setDisableBtnToDocument(false)
                this.setState({
                    saveNewComplete: saveNewComplete,
                })
                // console.log("состояние кнопки",this.props.disableBtnToDocument)
            }
        } catch (e) {
            e.message !== undefined ? this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {type: "fail", content: `Ошибка загрузки: <br/>${e.message}`}
                }) :
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {type: "fail", content: `Ошибка загрузки: <br/>${e.responseJSON.Message}`}
                });

        }
    }

    // загрузка пакетов для выбора
    async loadPackages(value) {
        await this.props.treeSearchPackage({Input: value, MasterSearchId: this.props.ActiveIdFilial.value});
        return this.props.packages.map(item => ({label: item.Name, value: item.Id}))
    }

    showOriginalFilesList() {
        this.setState({showOriginalFilesList: true});
    }

    showOtherFilesList() {
        this.setState({showOtherFilesList: true});
    }


    render() {
        const lFlagWithSection = this.props.globalSettings.WithSection === "1";
        let filialOptions = [];
        if (this.state.LoadFilial.length === 0) {
            filialOptions.push({
                value: null,
                label: "Не выбрано",
                isDisabled: true
            });
        }
        for (const filial of this.state.LoadFilial) {
            filialOptions.push({
                value: filial.Id,
                label: filial.Name,
                isDisabled: false
            });
        }

        let typesOptions = [];
        typesOptions.push({
            value: null,
            label: "Не выбрано",
            isDisabled: true
        });

        for (const loadType of this.props.LoadTypes) {
            if (this.props.LoadGroups
                .find(g => g.GroupTypeRefs
                    .find(item => item.IdDocType === loadType.Id && loadType.ModifyAccess))
            )
                typesOptions.push({
                    value: loadType.Id,
                    label: loadType.Name,
                    isDisabled: false
                });
        }

        let groupsOptions = [];
        groupsOptions.push({
            value: null,
            label: "Не выбрано",
            isDisabled: true,
            groupTypes: []
        });
        const groups = this.props.LoadGroups.filter(group => group.Type === 2)
        for (const group of groups) {
            groupsOptions.push({
                value: group.Id,
                label: group.Name,
                isDisabled: false,
                groupTypes: group.GroupTypeRefs,
            });
        }

        // TODO TP №21638,  №21567
        groupsOptions = this.props.ActiveType
            ? groupsOptions.filter(item => item.groupTypes.find(type => type.IdDocType === this.props.ActiveType))
            : groupsOptions


        let pageFormatOptions = [];

        for (const item in PrintFileFormat) {
            pageFormatOptions.push({
                value: PrintFileFormat[item],
                label: item,
                isDisabled: false
            });
        }

        let mod = this.props.theme.modString;


        const {showOtherFilesList, showOriginalFilesList} = this.state;

        if (filialOptions.length === 1 && this.props.ActiveIdFilial?.value !== filialOptions[0].value)
            this.filialSelectOnChange_loadTypes(filialOptions[0])


        //Атрибуты уникальности
        const lUniqueAttributes = this.props.ActiveAttributes.filter(attribute => attribute.IsUnique === true);
        //Атрибуты типа документа
        const lNotUniqueAttributes = this.props.ActiveAttributes.filter(attribute => attribute.IsUnique !== true);

        return (
            <div>
                {
                    (showOtherFilesList && this.props.OtherFiles.length > 0) &&
                    <FilesModal
                        files={this.props.OtherFiles}// files={this.state.OtherFiles}
                        onClose={() => this.setState({showOtherFilesList: false})}
                        onSave={(files) => this.props.setOtherFiles(files)}//this.setState({OtherFiles: files})
                    />
                }
                {
                    (showOriginalFilesList && this.props.OriginalFile?.name) &&
                    <FilesModal
                        caption={store.getState().globalState.settings.Content?.WithOriginalFilePdf == 1 ? "Оригинал (PDF)" : "Оригинал"}
                        files={[this.props.OriginalFile]}
                        onClose={() => this.setState({showOriginalFilesList: false})}
                        onSave={(files) => this.props.setOriginalFile(files)}
                    />
                }
                <div
                    className="header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled aside-left-menu">
                    <MobileMenu rootName={"MasterApp"}/>
                    <div className="d-flex flex-column flex-root">
                        <div className="aside aside-left d-flex aside-fixed">
                            <LeftMenu rootName="MasterApp"/>
                        </div>

                        <div className="d-flex flex-column flex-row-fluid wrapper">
                            <div className="content d-flex flex-column flex-column-fluid">
                                <TopMenu rootName="MasterApp"/>
                            </div>
                            <div className="d-flex flex-column flex-column-fluid px-4" style={{}}>
                                <div className="container-new" style={{}}>
                                    <div>
                                        <div className="row pt-4" style={{marginBottom: "50px"}}>
                                            <div className="col-lg-12" style={{padding: "0"}}>
                                                <Collapse in={this.props.isShowBanner}>
                                                    <div>
                                                        <div
                                                            className="card card-custom bgi-no-repeat bgi-size-cover gutter-b bg-primary"
                                                            style={{
                                                                height: "350px",
                                                                backgroundImage: `url('./images/downloadMaster${mod}.svg')`
                                                            }}>
                                                            <div className="card-body d-flex"
                                                                 style={{padding: "4rem 3.25rem"}}>
                                                                <div
                                                                    className="d-flex py-5 flex-column align-items-start flex-grow-1">
                                                                    <div className="flex-grow-1">
                                                                    <span
                                                                        className="text-white font-weight-bolder font-size-h1">Загрузка Документов</span>
                                                                        <p className="text-white font-weight-bold mt-3">Мастер
                                                                            загрузки поможет вам корректно
                                                                            загрузить<br/>
                                                                            новый документ для работы с ним в приложении
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Collapse>
                                            </div>

                                            <div className="card card-custom container-solid col-xl-12 mb-1"
                                                 style={{borderRadius: "20px"}}>
                                                <div className="card-header card-header-colored">
                                                    <div className="card-title">
                                                        <span className="card-icon"/>
                                                        <h4 className="card-label text-weight-bolder text-color-primary"
                                                            title="Настройки отчета">
                                                            <b>Основные настройки</b>
                                                        </h4>
                                                    </div>
                                                </div>

                                                <form className="form">
                                                    <div className="card-body p-5">
                                                        <div className="row">
                                                            <div className="col-lg-3">
                                                                <div>
                                                                    {filialOptions.length !== 1 ?
                                                                        <>
                                                                            <p className="mb-2">Выберите {store.getState().globalState.settings.Content?.FilialCaption ?? 'филиал'} для
                                                                                документа</p>
                                                                            <Select
                                                                                styles={sbSelect}
                                                                                defaultValue={filialOptions[0]}
                                                                                value={this.props.ActiveIdFilial ?? ''}
                                                                                onChange={(item) => {
                                                                                    this.filialSelectOnChange_loadTypes(item)
                                                                                }}
                                                                                options={filialOptions}
                                                                                placeholder={`Выберите ${store.getState().globalState.settings.Content?.FilialCaption ?? 'филиал'}`}
                                                                                theme={(theme) => ({
                                                                                    ...theme,
                                                                                    colors: {
                                                                                        ...theme.colors,
                                                                                        text: 'var(--hover-primary)',
                                                                                        primary25: 'var(--light-primary)',
                                                                                        primary50: 'var(--hover-primary)',
                                                                                        primary: 'var(--originaltwo)',
                                                                                    },
                                                                                })}
                                                                            />
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <p className="mb-2">{`Текущий ${store.getState().globalState.settings.Content?.FilialCaption ?? 'филиал'}`}</p>
                                                                            <p className="mb-2">{filialOptions[0].label}</p>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                {
                                                                    this.props.ActiveIdFilial &&
                                                                    <>
                                                                        <p className="mb-2">Выберите тип документа</p>
                                                                        {this.state.LoadTypesIsLoading ?
                                                                            <div className="d-flex align-items-center">
                                                                                <div
                                                                                    style={{marginTop: "10px"}}
                                                                                    color={{
                                                                                        text: 'var(--hover-primary)',
                                                                                        primary25: 'var(--light-primary)',
                                                                                        primary50: 'var(--hover-primary)',
                                                                                        primary: 'var(--originaltwo)',
                                                                                    }}
                                                                                >
                                                                                    Загрузка...
                                                                                </div>
                                                                                <div style={{marginLeft: "200px"}}
                                                                                     className="spinner-border ms-auto"
                                                                                     role="status"
                                                                                     aria-hidden="true"></div>
                                                                            </div>
                                                                            :
                                                                            <Select
                                                                                styles={sbSelect}
                                                                                value={this.props.typeValue}
                                                                                onChange={(item) => {
                                                                                    this.docTypesSelectOnChange_loadAttributes(item)
                                                                                }}
                                                                                options={typesOptions}
                                                                                theme={(theme) => ({
                                                                                    ...theme,
                                                                                    colors: {
                                                                                        ...theme.colors,
                                                                                        text: 'var(--hover-primary)',
                                                                                        primary25: 'var(--light-primary)',
                                                                                        primary50: 'var(--hover-primary)',
                                                                                        primary: 'var(--originaltwo)',
                                                                                    },
                                                                                })}
                                                                            />
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                            {
                                                                lFlagWithSection &&
                                                                <div className="col-lg-3">
                                                                    {
                                                                        this.props.ActiveType &&
                                                                        <>
                                                                            <p className="mb-2">Выберите раздел
                                                                                документа</p>
                                                                            <Select
                                                                                styles={sbSelect}
                                                                                placeholder="Выберите раздел документа"
                                                                                title="Выберите раздел документа"
                                                                                closeMenuOnSelect={false}
                                                                                components={animatedComponents}
                                                                                isMulti
                                                                                value={this.props.SelectedGroups}
                                                                                onChange={(value) => this.props.setSelectedGroups(value)}
                                                                                options={groupsOptions}
                                                                                noOptionsMessage={() => "Нет доступных разделов"}
                                                                                theme={(theme) => ({
                                                                                    ...theme,
                                                                                    colors: {
                                                                                        ...theme.colors,
                                                                                        text: 'var(--hover-primary)',
                                                                                        primary25: 'var(--light-primary)',
                                                                                        primary50: 'var(--hover-primary)',
                                                                                        primary: 'var(--originaltwo)',
                                                                                    },
                                                                                })}
                                                                            />
                                                                        </>
                                                                    }
                                                                </div>
                                                            }
                                                            <div className="col-lg-3">
                                                                {
                                                                    this.props.ActiveType &&
                                                                    <>
                                                                        <p className="mb-2">Выберите пакеты для
                                                                            документа</p>
                                                                        <AsyncSelect
                                                                            key={this.props.activeIdFilial?.value}
                                                                            title="Выберите пакет"
                                                                            loadOptions={this.loadPackages}
                                                                            onChange={(value) => this.setState({SelectedPackages: value})}
                                                                            isMulti
                                                                            cacheOptions
                                                                            defaultOptions
                                                                            placeholder="Введите название пакета"
                                                                            noOptionsMessage={() => {
                                                                                return "Не найдено пакетов для выбора"
                                                                            }}
                                                                            loadingMessage={() => {
                                                                                return "Загрузка..."
                                                                            }}
                                                                            theme={(theme) => ({
                                                                                ...theme,
                                                                                colors: {
                                                                                    ...theme.colors,
                                                                                    text: 'var(--hover-primary)',
                                                                                    primary25: 'var(--light-primary)',
                                                                                    primary50: 'var(--hover-primary)',
                                                                                    primary: 'var(--originaltwo)',
                                                                                },
                                                                            })}
                                                                        />
                                                                    </>
                                                                }
                                                            </div>
                                                            {this.props.globalSettings.WithDepartments === "1" && this.props.ActiveType &&
                                                                <div className="col-lg-3 d-flex align-items-end">
                                                                        <span onClick={this.changeDepartments}
                                                                              className="mt-4 btn btn-success flex-btn font-weight-bolder btn-sm py-3 px-6 text-uppercase"
                                                                              title="Выбрать подразделения"
                                                                        >
                                                                            Выбрать подразделения
                                                                        </span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className={"d-flex col-lg-12 p-0"}>
                                                {
                                                    this.props.ActiveType &&
                                                    this.props.typeValue.value &&
                                                    <>
                                                        <div className="card card-custom container-solid col-lg-6"
                                                             style={{borderRadius: "20px"}}>
                                                            <div className="card-header card-header-colored">
                                                                <div className="card-title">
                                                                    <span className="card-icon"/>
                                                                    <h4 className="card-label text-weight-bolder text-color-primary"
                                                                        title="Настройки отчета">
                                                                        <b>Настройки атрибутов</b>
                                                                    </h4>
                                                                </div>
                                                            </div>

                                                            <div className="card-body beautiful-scroll-10 p-5"
                                                                 style={{overflow: "auto"}}>
                                                                <div className="form-group mt-2">
                                                                    {this.renderConstRequiredSection()}
                                                                    <div>
                                                                        {lUniqueAttributes && lUniqueAttributes.length > 0 &&
                                                                            <div className="form-group mt-2">
                                                                                <h4>Атрибуты уникальности</h4>
                                                                                {
                                                                                    lUniqueAttributes.map(item => {

                                                                                        if (item.IsRequired) {
                                                                                            return (
                                                                                                this.renderItem(item, (this.state.AttributeError.Name === item.AttributeName.Name && this.state.AttributeError.IsError) ? {borderColor: "#F64E60"} : {})
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                this.renderItem(item)
                                                                                            );
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {lNotUniqueAttributes && lNotUniqueAttributes.length > 0 &&
                                                                            <div>
                                                                                <h4>Атрибуты по типу документа</h4>
                                                                                {
                                                                                    lNotUniqueAttributes.map((item, index) => {
                                                                                        if (item.IsRequired) {
                                                                                            return (
                                                                                                this.renderItem(item, (this.state.AttributeError.Name === item.AttributeName.Name && this.state.AttributeError.IsError) ? {borderColor: "#F64E60"} : {}, 'icon-atributes_search')
                                                                                            );

                                                                                        } else {
                                                                                            return (
                                                                                                this.renderItem(item, undefined, 'icon-atributes_search')
                                                                                            );
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    this.props.ActiveType &&
                                                    this.props.typeValue.value &&
                                                    <div className="card card-custom container-solid col-lg-6"
                                                         style={{borderRadius: "20px"}}>
                                                        <div className="card-header card-header-colored">
                                                            <div className="card-title">
                                                                <span className="card-icon"/>
                                                                <h4 className="card-label text-weight-bolder text-color-primary"
                                                                    title="Настройки отчета"
                                                                >
                                                                    <b>Загрузка файлов</b>
                                                                </h4>
                                                            </div>
                                                        </div>

                                                        <div className="card-body beautiful-scroll-10 p-5">
                                                            <div className="form-group row">
                                                                <div className="col-lg-6">
                                                                    <p className="my-4">

                                                                        <b>Оригинал {store.getState().globalState.settings.Content?.WithOriginalFilePdf == 1 && <>(PDF)</>}
                                                                            {   store.getState().globalState.settings.Content?.WithOriginalFilePdf == 1 &&
                                                                                <sup style={{color: "#dc3545"}}>*</sup>
                                                                            }
                                                                        </b>
                                                                        {
                                                                            this.state.showOriginalFilesTooltipShow &&
                                                                            <MyTooltip
                                                                                target={this.showOriginalFilesRef}
                                                                                text={"Просмотр выбранных файлов"}
                                                                                show={this.state.showOriginalFilesTooltipShow}
                                                                                placement={"top"} delay={500}
                                                                            />
                                                                        }
                                                                        {
                                                                            this.props.OriginalFile?.name &&
                                                                            <span
                                                                                className="btn btn-icon btn-sm mx-2 h-auto"
                                                                                ref={this.showOriginalFilesRef}
                                                                                onClick={this.showOriginalFilesList}
                                                                                onMouseEnter={() => {
                                                                                    this.setState({showOriginalFilesTooltipShow: true})
                                                                                }}
                                                                                onMouseLeave={() => {
                                                                                    this.setState({showOriginalFilesTooltipShow: false})
                                                                                }}
                                                                            >
                                                                            <i className="svg-icon icon-Documents icon-color-primary"/>
                                                                        </span>
                                                                        }
                                                                    </p>
                                                                    <DropFileComponent elemRef={this.originalFileRef}
                                                                                       name={store.getState().globalState.settings.Content?.WithOriginalFilePdf == 1 ? "original" : "originalAny"}
                                                                                       accept={store.getState().globalState.settings.Content?.WithOriginalFilePdf == 1 ? "application/pdf" : "*"}
                                                                                       multiple={false}
                                                                                       setFiles={(file) => this.props.setOriginalFile(file)}
                                                                                       value={this.props.OriginalFile}
                                                                    />
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <p className="my-2 text-nowrap">
                                                                        <b>Файл предпросмотра (PNG)</b>
                                                                        {
                                                                            this.state.previewInfoTooltipShow &&
                                                                            <MyTooltip target={this.previewInfoRef}
                                                                                       text={"Файл предпросмотра не является обязательным, при его отсутствии предпросмотр будет сгенерирован автоматически"}
                                                                                       show={this.state.previewInfoTooltipShow}
                                                                                       placement={"top"} delay={500}
                                                                            />
                                                                        }
                                                                        <span className="btn btn-icon btn-circle btn-sm"
                                                                              ref={this.previewInfoRef}
                                                                              onMouseEnter={() => {
                                                                                  this.setState({previewInfoTooltipShow: true})
                                                                              }}
                                                                              onMouseLeave={() => {
                                                                                  this.setState({previewInfoTooltipShow: false})
                                                                              }}
                                                                        >
                                                                        <i className="svg-icon icon-Info"/>
                                                                    </span>
                                                                    </p>
                                                                    <DropFileComponent elemRef={this.previewFileRef}
                                                                                       name={"preview"}
                                                                                       accept={"image/png"}
                                                                                       multiple={false}
                                                                                       setFiles={(file) => this.props.setPreviewFile(file)}
                                                                                       value={this.props.PreviewFile}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <div className="col-lg-6">
                                                                    <p className="mb-3">Дополнительные файлы
                                                                        {
                                                                            this.state.showOtherFilesTooltipShow &&
                                                                            <MyTooltip target={this.showOtherFilesRef}
                                                                                       text={"Просмотр выбранных файлов"}
                                                                                       show={this.state.showOtherFilesTooltipShow}
                                                                                       placement={"top"} delay={500}
                                                                            />
                                                                        }
                                                                        {
                                                                            this.props.OtherFiles.length > 0 &&
                                                                            <span
                                                                                className="btn btn-icon btn-sm mx-2 h-auto"
                                                                                ref={this.showOtherFilesRef}
                                                                                onClick={this.showOtherFilesList}
                                                                                onMouseEnter={() => {
                                                                                    this.setState({showOtherFilesTooltipShow: true})
                                                                                }}
                                                                                onMouseLeave={() => {
                                                                                    this.setState({showOtherFilesTooltipShow: false})
                                                                                }}>
                                                                        <i className="svg-icon icon-Documents icon-color-primary"/>
                                                                    </span>
                                                                        }
                                                                    </p>

                                                                    <DropFileComponent elemRef={this.otherFilesRef}
                                                                                       name={"other"}
                                                                                       accept={"*"}
                                                                                       multiple
                                                                                       setFiles={(files) => {
                                                                                           this.props.setOtherFiles(files)
                                                                                           // this.setState({OtherFiles: files})
                                                                                       }}
                                                                                       value={this.props.OtherFiles}
                                                                    />
                                                                </div>
                                                                <div className="separator separator-dashed"/>
                                                            </div>
                                                            <div className="form-group row p-5">
                                                                <div className="d-flex align-items-center mb-3">
                                                                    <h4 className="mb-0">Содержание документа</h4>
                                                                    {
                                                                        this.state.contentTooltipShow &&
                                                                        <MyTooltip target={this.contentRef}
                                                                                   text={"Содержание не является обязательным, при его отсутствии будет сгенерировано автоматически"}
                                                                                   show={this.state.contentTooltipShow}
                                                                                   placement={"top"} delay={500}
                                                                        />
                                                                    }
                                                                    <span
                                                                        className="btn btn-icon btn-circle btn-sm icon-info-decor"
                                                                        ref={this.contentRef}
                                                                        onMouseEnter={() => {
                                                                            this.setState({contentTooltipShow: true})
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            this.setState({contentTooltipShow: false})
                                                                        }}
                                                                    >
                                                                    <i className="svg-icon icon-Info"/>
                                                                </span>
                                                                </div>
                                                                <textarea className={"form-control beautiful-scroll-10"}
                                                                          id="content" cols="100"
                                                                          name="content"
                                                                          value={this.props.ContentText}
                                                                          onChange={(e) => {
                                                                              this.props.setContentText(e.target.value);
                                                                          }}
                                                                          rows="20" style={{resize: "none"}}
                                                                />
                                                            </div>

                                                            <div className="card-footer d-flex justify-content-between">
                                                            <span className="btn btn-primary font-weight-bold"
                                                                  onClick={this.checkInput}
                                                            >
                                                                Сохранить
                                                            </span>
                                                                <span
                                                                    className="btn btn-outline-secondary font-weight-bold"
                                                                    onClick={() => this.setState({...this.defaultState})}
                                                                >
                                                                Сбросить
                                                            </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            {this.props.ActiveType && !this.props.disableBtnToDocument &&
                                                <div className="d-flex flex-grow-1 justify-content-center my-2">
                                                    <span onClick={() => this.goToDocument(this.state.saveNewComplete.IdDoc)}
                                                          className="btn btn-success flex-btn btn-sm py-3 px-6 text-uppercase"
                                                          title="Перейти к документу"
                                                    >
                                                          Перейти к документу
                                                    </span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        asideMenuIsClose: state.globalState.app.asideMenuIsClose,
        filials: state.globalState.filial.All,
        globalSettings: state.globalState.settings.Content,
        packages: state.packages.treeSearch.folders,
        theme: state.globalState.theme,
        collectionMaster: state.department.departments.departmentCollection,
        //Сохранение данных для выбора документов
        OtherFiles: state.masterApp.OtherFiles,
        OriginalFile: state.masterApp.OriginalFile,
        PreviewFile: state.masterApp.PreviewFile,
        isShowBanner: state.masterApp.isShowBanner,
        ActiveIdFilial: state.masterApp.ActiveIdFilial,
        ActiveType: state.masterApp.ActiveType,
        SelectedGroups: state.masterApp.SelectedGroups,
        LoadTypes: state.masterApp.LoadTypes,
        LoadGroups: state.masterApp.LoadGroups,
        typeValue: state.masterApp.typeValue,
        DocName: state.masterApp.DocName,
        RegNumber: state.masterApp.RegNumber,
        RegDate: state.masterApp.RegDate,
        ActiveAttributes: state.masterApp.ActiveAttributes,
        disableBtnToDocument: state.masterApp.disableBtnToDocument,
        Comment: state.masterApp.Comment,
        ContentText: state.masterApp.ContentText,

        activeFilialId: state.globalState.filial.Active.Id,
        verifyDocs: state.document.verifyDocs,
    }
}

const mapDispatchToProps = {
    setStateLeftAsideMenu,
    setModalData,
    treeSearchPackage,
    setDepartmentCollection,
    SaveOrUpdateAndDeleteByFilter,
    setOtherFiles,
    setOriginalFile,
    setPreviewFile,
    setActiveIdFilial,
    setShowBanner,
    setActiveType,
    setSelectedGroups,
    setLoadTypes,
    setLoadGroups,
    setTypeValue,
    setDocName,
    setDocNameError,
    setRegNumber,
    setRegDate,
    setActiveAttributes,
    setDisableBtnToDocument,
    setComment,
    setContentText,
    setVersionIdForLocate,
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterApp);
