import {createUniqueIdString} from "./CreateUniqueId";
import {store} from "../index";

export const TreeTypes = {
    ROOT : "ROOT",
    FOLDER : "FOLDER",
    DOC : "DOCUMENT"
};


export class TreeCombine {
    constructor({docs, group}) {
        this.docs = docs;
        this.group = group;
        this._tree = {
            treeId : createUniqueIdString(8), // уникальный id для определения конкретного узла дерева
            parent : null,
            type : TreeTypes.ROOT,
            info : {},
            children : []
        };
    }

    sort() {
        const compare = function(a, b) {
            if (a.info.GroupOrder > b.info.GroupOrder) return 1;
            if (a.info.GroupOrder === b.info.GroupOrder) return 0;
            if (a.info.GroupOrder < b.info.GroupOrder) return -1;
        }

        this._tree.children.sort(compare);

        return this;
    }

    create() {
        for(let i = 0; i < this.group.length; i++) {

            let folder = {
                treeId : createUniqueIdString(8), // уникальный id для определения конкретного узла дерева
                parent : this._tree.treeId,
                type : TreeTypes.FOLDER,
                children : [],
                info : this.group[i]
            };

            for (let j = 0; j < this.docs.length; j++) {
                if(this.combine(this.group[i], this.docs[j])) {
                    let item = new ItemTree({
                        parent : folder.treeId,
                        type : TreeTypes.DOC,
                        info : this.docs[j],
                        children : null
                    });
                    folder.children.push(item);
                }
            }
            this._tree.children.push(folder);
        }

        return this;
    }

    combine(group, document) {
        for (let i = 0; i < group.GroupTypeRefs.length; i++) {
            if(document.Id == group.GroupTypeRefs[i].IdDocType) {
                return true;
            }
        }
        return false;
    }

    get() {
        return this._tree;
    }

    // поиск узла в дереве по его id
    // например: let parent = TreeCombine.searchByTreeId(tree_node.parent);
    static searchByTreeId = ({treeId, isSearchTree = false, tree = null}) => {

        if(tree === null) {
            if(isSearchTree) {
                tree = {...store.getState().document.tree.search};
            } else {
                tree = {...store.getState().document.tree.render};
            }
        }
        if(tree.treeId === treeId) {
            return tree;
        }

        return this.findByTreeId(tree.children, treeId);
    }
    static searchByTreeId_packagesRightDocuments = ({treeId, isSearchTree = false, tree = null}) => {
        if(tree === null) {
            if(isSearchTree) {
                tree = {...store.getState().packages.treeForDocuments.search};
            } else {
                tree = {...store.getState().packages.treeForDocuments.render};
            }
        }
        if(tree.treeId === treeId) {
            return tree;
        }
        return this.findByTreeId(tree.children, treeId);
    }

    // рекурсивный поиск в многоуровневом дереве
    static findByTreeId = (List, Id) => {
        for (let i = 0; i < List.length; i++) {
            if (List[i].treeId == Id) return List[i]
            else {
                if (List[i].children && List[i].children.length > 0) {
                    const foundItem = this.findByTreeId(List[i].children, Id);
                    if (foundItem) {
                        return foundItem
                    }
                }
            }
        }
    }
}


export class ItemTree {
    constructor({parent, children, type, info}) {
        // уникальный id для определения конкретного узла дерева
        this.treeId = createUniqueIdString(8);
        this.parent = parent;
        this.children = children;
        this.type = type;
        this.info = info;
    }
}
