import React from "react";
import {connect} from "react-redux";

import {__} from "../../../tools/HelpFunctions";
import {
    handlerSearchPackagesPath,
    handlerTreePackage
} from "../../../store/packages/actionCreators/package_TreeSearchActionCreator";
import {movingDocumentsInPackageFromDocuments} from "../../../store/packages/actionCreators/package_TransferActionCreator";
import {setViewModePreviewPackages} from "../../../store/packages/actionCreators/package_MainTableActionCreator";
import {Routing} from "../../../tools/routing/Routing";
import {history, store} from "../../../index";
import TreePackageFolder from "../../tree/packages/TreePackageFolder";
import {TableTypesEnum} from "../../../tools/StaticTypes";
import {setLoaderModalData, setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";


class TreePackagesFolderSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItem: null,
            selectedNode : null,
            IsOpen: false,
        }
        this.selectNode = this.selectNode.bind(this);
        //this.selectNodeDoc = this.selectNodeDoc.bind(this);
    }

    componentWillUnmount() {
        //TODO доделать, чтобы очищалось после закрытия, отмены
        // this.props.treeSearchPackage({IsClear: true});
    }

    // selectNodeDoc(item){
    //     this.setState({
    //         selectedNode : item.Id
    //     });
    //     this.props.selectNode(item);
    // }

    selectNode(item) {
        // this.props.setModalData({
        //     name : ModalTypes.app.alert,
        //     data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "packages"}
        // });

        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "packages", key : "TreePackagesFolderSearch51" }
        }));

        this.props.handlerSearchPackagesPath({
            TableType : this.props.tableRole,
            Id: item.Id,
            Next : {
                action : ()=>{
                    /* TODO КН 03.05.24 в handlerTreePackage есть функция PackageAddNodesTree,
                        которая используется для данных нужных ф-ии getDocumentsForTreePackages,
                        которая не расчитана на получение tableRole === 'modal'.
                        Поэтому если вдруг решите вызывать this.selectNode и для 'modal' учитывайте этот факт,
                        мб вы можете что то сломать, или забыть проработать какой то кейс
                     */
                    this.props.handlerTreePackage({
                        TableType : this.props.tableRole,
                        Next: {
                            action : () => {
                                if(this.props.viewModePreview === "welcome") {
                                    this.props.setViewModePreviewPackages({
                                        ViewMode : "none",
                                    });
                                }

                                // this.props.setModalData({});
                                //Новый прелоадер
                                this.props.setLoaderModalData({keyDeleted: "TreePackagesFolderSearch64"});


                                let query = this.props.router.location.query;
                                let queryLink = "";

                                if(this.props.tableRole === TableTypesEnum.Additional) {
                                    if(this.props.viewModePreview !== "two_table_none") {
                                        this.props.setViewModePreviewPackages({
                                            ViewMode: "two_table_none"
                                        })
                                    }
                                    queryLink = Routing.CreateRef({
                                        oldQuery : query,
                                        newQuery : {p2 : item.Id},
                                        excludedKeys : ["dwp"]
                                    });
                                } else {
                                    queryLink = Routing.CreateRef({
                                        oldQuery : query,
                                        newQuery : {p : item.Id},
                                        excludedKeys : []
                                    });
                                }

                                let link = `${this.props.router.location.pathname}${queryLink}`;
                                history.push(link);
                            },
                            params: {}
                        }
                    });
                },
                params : {}
            }
        })
        //TODO aa чтобы не закрывалась модалка, иначе в модалке выходит из div
        if (this.props.backFocus)
            this.props.backFocus();
    }

    render() {
        // let checkedStyle = {
        //     backgroundColor: "#8bf39d0d",
        //     border: "1px solid #087c103d",
        // }
        let data;
        switch (this.props.tableRole) {
            case TableTypesEnum.Main:
                data = __.deepCopy(this.props.treeSearch.tree?.Children);
                break;
            case TableTypesEnum.Additional:
                data = __.deepCopy(this.props.treeSearchAdditional?.tree?.Children);
                break;
            case TableTypesEnum.Modal:
                data = __.deepCopy(this.props.treeSearchModal?.tree?.Children);
                break;
            case TableTypesEnum.Download:
            default:
                data = __.deepCopy(this.props.treeSearchAdditional.folders);
                break;
        }

        let depth = 0;

        if(!data) return null;
        return(
            <ul className="menu-nav">
                {
                    data.map((item, index) => {
                        if(this.props.tableRole === TableTypesEnum.Modal) {
                           return (
                               <TreePackageFolder
                                   key={item.TreeId}
                                   data={item}
                                   isSearch={true}
                                   selectNodeDoc={this.props.selectNode}
                                   depth={depth + 1}
                                   tableRole={this.props.tableRole}
                                   //TODO aa чтобы не закрывалась модалка, иначе в модалке выходит из div
                                   backFocus = {() => {if (this.props.backFocus) {this.props.backFocus()}}}
                               />
                           )
                            /*
                            if(item.Id === this.state.selectedNode) {
                                return (
                                    <React.Fragment>
                                        <li className={"menu-link-p menu-item "} key={item.Id}>
                                     <span className="menu-link menu-toggle" style={checkedStyle}  onClick={()=>{this.selectNodeDoc(item)}}>
                                         <span className={`svg-icon menu-icon`}>
                                            <i className={"icon-on1"} />
                                         </span>
                                        <span className={`menu-text`}>{item.Name}
                                        </span>
                                         {
                                             item.ContainsChilds &&
                                             <span className={`svg-icon menu-icon`} onClick={() => {this.switchFolderState(item.Id)}}>
                                                <i className={`arrow_m ${this.state.IsOpen ? "arrow-bottom" : "arrow-right"} icon-Arrows_1`} />
                                             </span>
                                         }
                                    </span>
                                            <div className="menu-submenu menu-submenu-p" >

                                            </div>
                                        </li>

                                    </React.Fragment>
                                );
                            } else {
                                return (
                                    <React.Fragment>
                                        <li className={"menu-link-p menu-item "} key={item.Id}>
                                     <span className="menu-link menu-toggle"  onClick={()=>{this.selectNodeDoc(item)}}>
                                         <span className={`svg-icon menu-icon`}>
                                            <i className={"icon-on1"} />
                                         </span>
                                        <span className={`menu-text`}>{item.Name}
                                        </span>
                                         {
                                             item.ContainsChilds &&
                                             <span className={`svg-icon menu-icon`} onClick={() => {this.switchFolderState(item.Id)}}>
                                                <i className={`arrow_m ${this.state.IsOpen ? "arrow-bottom" : "arrow-right"} icon-Arrows_1`} />
                                             </span>
                                         }
                                    </span>
                                            <div className="menu-submenu menu-submenu-p" >

                                            </div>
                                        </li>

                                    </React.Fragment>
                                );
                            }

                             */
                        } else if (this.props.tableRole === TableTypesEnum.Main || this.props.tableRole === TableTypesEnum.Additional) {
                            return (
                                <TreePackageFolder
                                    key={item.TreeId}
                                    data={item}
                                    isSearch={true}
                                    depth={depth + 1}
                                    tableRole={this.props.tableRole}
                                    selectNodeDoc={(itemNode)=>this.selectNode(itemNode)}
                                    // backFocus = {() => {if (this.props.backFocus) {this.props.backFocus()}}}
                                />
                            )
                        } else {
                            // выделение активного node
                            let activeStyle = {};
                            let activeItemStyleText = "";
                            let activeItemStyleIcon = "icon-color-grey";
                            if((this.props.activeNode && item.Id === this.props.activeNode.Node.Id) || this.state.activeItem?.Id === item.Id) {
                                activeStyle = {"border": "2px var(--elem-bg) solid"};
                                activeStyle.background = "var(--elem-bg)";
                                activeStyle.transition = "background-color 0.3s";
                                activeStyle.minHeight = "45px";
                                activeStyle.borderRadius = "25px";

                                activeItemStyleText = "text-color-primary-dark";
                                activeItemStyleIcon = "icon-color-primary-dark";
                            }
                            activeStyle.marginTop =  "3px";
                            activeStyle.marginBottom =  "3px";
                            let folderIcon = item.ContainsChilds ? "icon-on1" : "icon-off1";

                            return (
                                <li className={"menu-link-p menu-item "} key={item.Id}>
                                    <span className="menu-link menu-toggle" style={activeStyle}  onClick={()=>{
                                        if (this.props.selectNode) {
                                            this.setState({activeItem: item})
                                            this.props.selectNode(item)
                                        }
                                        else this.selectNode(item)
                                    }}>
                                        <span className="svg-icon menu-icon"><i className={folderIcon + " " + activeItemStyleIcon} /></span>
                                        <span className={`menu-text ${activeItemStyleText}`}>{item.Name}</span>
                                        {/*<i className={`arrow_m icon-Arrows_1`} />*/}
                                    </span>
                                    <div className="menu-submenu menu-submenu-p" >

                                    </div>
                                </li>
                            );
                        }
                    })
                }
            </ul>
        );
    }
}


const  mapStateToProps = state => {
    return {
        activeNode : state.packages.tree.activeNode,
        router : state.router,
        treeSearch : state.packages.treeSearch, // данные в обычном режиме
        treeSearchAdditional : state.packages.treeSearchAdditional, // данные в обычном режиме
        treeSearchModal: state.packages.treeSearchModal,
        viewModePreview : state.packages.rootState.viewModePreview,
    }
}

const  mapDispatchToProps = {
    handlerSearchPackagesPath,
    handlerTreePackage,
    movingDocumentsInPackageFromDocuments,
    setLoaderModalData,
    setModalData,
    setViewModePreviewPackages,
}

export default connect(mapStateToProps, mapDispatchToProps)(TreePackagesFolderSearch);
