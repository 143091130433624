import React from "react";
import {connect} from "react-redux";
import {
    contextMenuTypes, ModalTypes,
} from "../../../tools/StaticTypes";
import {
    setContextMenuData, setModalData,
    setStateLeftAsideMenu
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {
    createDocumentCards,
} from "../../../store/documents/actionCreators/document_PreviewActionCreator";
import {changeDocumentSearchInputsContextMenu, changeViewMode} from "../../../store/documents/actionCreators/document_SearchActionCreator";
import {combineMainTableAttributes, mainTableSearchDoc} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {updateUserSettings} from "../../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import {changeDocumentViewMode} from "../../../store/documents/actionCreators/document_RootStateActionCreator";

class ContextMenuTreePackages extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            scrollY : window.scrollY, // TODO: пока оставил позицию окна при контексте но если не будем юзать -  удалить
            scrollX : window.scrollX,
            isHover : false
        };

        this.transfer = this.transfer.bind(this);
    }

    componentDidMount() {
        document.onscroll = (event) => { // скрытие контекстного меню при скроле документа
            if(this.props.contextMenu.name === contextMenuTypes.MainTableTR) {
                this.props.setContextMenuData({});
            }
        };
        document.onclick = (event) => {
            if(!this.state.isHover) {
                this.props.setContextMenuData({});
            }
        };
    }

    // отписываемся от событий при удалении элемента
    componentWillUnmount() {
        document.onscroll = null;
        document.onclick = null;
    }

    transfer() {
        this.props.setModalData({
            name: ModalTypes.packages.transfer,
            data: this.props.contextMenu.data
        });

        this.props.setContextMenuData({})
    }



    render() {

        let contextStyle = {
            position : "fixed",
            top :  this.props.contextMenu.position.y,
            left :  this.props.contextMenu.position.x,
            zIndex: 1100
        };


        return (
            <nav id="context-menu" className="context-menu" style={contextStyle} onContextMenu={(event) => {event.preventDefault()}}
                 onMouseEnter={(event) => {this.setState({isHover : true})}}
                 onMouseLeave={(event) => {this.setState({isHover : false})}}>
                <ul className="context-menu__items">
                    <li className="context-menu__item" onClick={(ev) => {this.transfer(ev)}} style={{cursor : "pointer"}}>
                        <span className="context-menu__link">
                            <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-3">
                                <i className="svg-icon svg-icon-sm text-primary icon-Doc_view_14"> </i>
                            </span>
                            Перенести пакет
                        </span>
                    </li>
                </ul>
            </nav>
        );
    }
}

const  mapStateToProps = state => {
    return {
        contextMenu :  state.globalState.app.contextMenu,
    }
}

const  mapDispatchToProps = {
    setContextMenuData,
    createDocumentCards,
    changeDocumentSearchInputsContextMenu,
    mainTableSearchDoc,
    combineMainTableAttributes,
    changeViewMode,
    updateUserSettings,
    setStateLeftAsideMenu,
    changeDocumentViewMode,

    setModalData
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenuTreePackages);
