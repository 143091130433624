import React, {createRef} from "react";
import {connect} from "react-redux";
import {Dropdown} from "react-bootstrap";
import {MyTooltip} from "../../overPage/tooltip/MyTooltip";
import {
    breadcrumbsAdd,
    breadcrumbsMoveNext,
    breadcrumbsMovePrev,
    PackageAddNodesTree,
    SelectActivePackageDocument,
    SelectActivePackageNode,
    breadcrumbsMoveSelect,
    togglePackagesFolders
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {changePackagesTablesViewMode, changeViewFilter, exportExcel, PackagesPaginationMovePage, setViewModePreviewPackages} from "../../../store/packages/actionCreators/package_MainTableActionCreator";
import {
    AdminLevelEnum,
    ModalTypes,
    PackagesViewFilters,
    RootComponentsStateViewModeTypes,
    TableTypesEnum,
    UserSettingsActionsTypes,
    UserSettingsNames
} from "../../../tools/StaticTypes";
import {changePackagesViewMode} from "../../../store/packages/actionCreators/package_RootStateActionCreator";
import {
    handlerIsSearch,
    updateInputsFieldPackage, updateInputsFieldSortingPackage
} from "../../../store/packages/actionCreators/package_InputActionCreator";
import {
    setLoaderModalData,
    setModalData,
    setStateLeftAsideMenu,
    setUserModalShow
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import EntityReport from "../../../tools/EntityReport";
import {IReportState} from "../../../tools/API_NEW/ServerTypes";
import {__} from "../../../tools/HelpFunctions";
import {history, store} from "../../../index";
import {TreeCombinePackage} from "../../../tools/TreeCombinePackage";
import {Routing} from "../../../tools/routing/Routing";
import {updateUserSettings} from "../../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import DropdownItem from "react-bootstrap/DropdownItem";
import {ActionQueue} from "../../../store/rootReducer";
import {
    checkAllRowsOnPage_MainTable,
    checkAllRowsOnPage_packagesDocumentRight, deleteRowFromChecked_MainTable,
    deleteRowFromChecked_packagesDocumentRight
} from "../../../store/packages/actionCreators/package_DocumentRightActionCreator";
import {API} from "../../../tools/API_NEW/API";

class MainTableSearchPackage extends React.Component {
    resizeObserver = null;
    resizeElement = createRef();
    constructor(props) {
        super(props);

        this.lFlagCopyUrl1C = this.props.globalSettings.WithCopyUrl1C === "1";

        this.fitInContainerSize = React.createRef();
        this.state = {
            applyFilterTooltipShow : false,
            cancelFilterTooltipShow : false,
            timeFilterTooltipShow : false,
            exportXlsTooltipShow : false,
            deleteDocTooltipShow : false,
            updateTableTooltipShow : false,
            tableViewTooltipShow : false,
            bigViewTooltipShow : false,
            middleViewTooltipShow : false,
            additionalViewTooltipShow : false,
            movePrevViewTooltipShow : false,
            moveNextViewTooltipShow : false,
            plusPackagesTooltipShow : false,
            downloadTooltipShow : false,

            fitInContainerSizeTooltipShow : false,

            packagesViewFilterTooltipShow : false,
            documentsViewFilterTooltipShow : false,
            isFitInContainerSize : false,

            deleteDocumentTooltipShow : false,

            //для дропдауна(скрыть/показать) при изменении ширины
            CurrentWidth: 1_000_000,
            UpdateCounter: 0,
            Menu: {
                isShowRight: true,
                isShowCenter: true,
                isShowLeft: true,
                DeletingSizes: {
                    Center: null,
                    CenterBlock: null,
                    Left: null,
                    LeftBlock: null,
                    Right: null,
                    RightBlock: null
                }
            }
        }

        this.applyFilterRef = React.createRef();
        this.cancelFilterRef = React.createRef();
        this.timeFilterRef = React.createRef();
        this.exportXlsRef = React.createRef();
        this.deleteDocumentRef = React.createRef();
        this.deleteDocRef = React.createRef();
        this.updateTableRef = React.createRef();
        this.tableViewRef = React.createRef();
        this.bigViewRef = React.createRef();
        this.middleViewRef = React.createRef();
        this.additionalViewRef = React.createRef();
        this.movePrevViewRef = React.createRef();
        this.moveNextViewRef = React.createRef();
        this.plusPackagesRef = React.createRef();

        this.packagesViewFilterRef = React.createRef();
        this.documentsViewFilterRef = React.createRef();

        this.copyUrlRef = React.createRef();
        this.copyUrl1CRef = React.createRef();
        this.downloadRef = React.createRef();

        this.movePrev = this.movePrev.bind(this);
        this.moveNext = this.moveNext.bind(this);
        this.changeViewMode = this.changeViewMode.bind(this);
        this.changeViewFilter = this.changeViewFilter.bind(this);
        this.toggleTwoTables = this.toggleTwoTables.bind(this);
        this.handleFilters = this.handleFilters.bind(this);
        this.PlusPackage = this.PlusPackage.bind(this);
        this.deletePackage = this.deletePackage.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);
        this.updateNamePackage = this.updateNamePackage.bind(this);
        this.exportXLS = this.exportXLS.bind(this);

        this.viewModeBtns = this.viewModeBtns.bind(this);
        this.filtersBtns = this.filtersBtns.bind(this);
        this.togglePacketsAndDocs = this.togglePacketsAndDocs.bind(this);
        this.setButtons = this.setButtons.bind(this);
        this.hideNextItem = this.hideNextItem.bind(this);
        this.showNextItem = this.showNextItem.bind(this);
        this.addDropdownItems = this.addDropdownItems.bind(this);

        this.loadPackages = this.loadPackages.bind(this);
        this.updateTable = this.updateTable.bind(this);
        this.copyUrl = this.copyUrl.bind(this);
        this.getCheckedFieldsView = this.getCheckedFieldsView.bind(this);
        this.checkboxOnChange = this.checkboxOnChange.bind(this);
        this.getDocsAsZipArchive = this.getDocsAsZipArchive.bind(this);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.tableRole === "main" && this.props.activeNode !== prevProps.activeNode)
            store.dispatch(deleteRowFromChecked_MainTable({isClear: true}))

        if (this.props.tableRole !== prevProps.tableRole
        || (this.props.tableRole === "download" && this.props.activeNodeDownload !== prevProps.activeNodeDownload)
        || (this.props.tableRole === "additional" && this.props.activeNodeAdditional?.Node.Id !== prevProps.activeNodeAdditional?.Node.Id)
        )
            store.dispatch(deleteRowFromChecked_packagesDocumentRight({isClear: true}))
    }

    componentWillUnmount() {
        store.dispatch(deleteRowFromChecked_packagesDocumentRight({isClear: true}))
        store.dispatch(deleteRowFromChecked_MainTable({isClear: true}))
    }

    checkboxOnChange() {
        let docs = this.props.body.filter(item => item.Type === "PackagesDocument" && !item.Node.IsMarkedForDelete);
        let viewFilter
        if (this.props.tableRole === TableTypesEnum.Main)
            viewFilter = this.props.viewFilter
        else if (this.props.tableRole === TableTypesEnum.Additional)
            viewFilter = this.props.viewFilterAdditional
        else if (this.props.tableRole === TableTypesEnum.Download)
            viewFilter = this.props.viewFilterDownload

        if (viewFilter !== "OnlyPackages"){
            if (this.props.tableRole === TableTypesEnum.Main) this.props.checkAllRowsOnPage_MainTable({Body: docs})
            else this.props.checkAllRowsOnPage_packagesDocumentRight({Body: docs});
        }
    }

    hideNextItem(Entry, DeletingSize, callback) {
        //types -  Entry:  ResizeObserverEntry, DeletingSize: number, callback: ()=>void
        let L = Entry.contentRect.width;

        let l1 = Entry.target.children[0].clientWidth ?? 0;
        let l2 = Entry.target.children[1] ? Entry.target.children[1].clientWidth : 0;
        let l3 = Entry.target.children[2] ? Entry.target.children[2].clientWidth : 0;
        let l4 = Entry.target.children[3] ? Entry.target.children[3].clientWidth : 0;
        let l5 = Entry.target.children[4] ? Entry.target.children[4].clientWidth : 0;

        if((L < (l1 + l2 + l3 + l4 + l5) * 1.1) && this.state.Menu.DeletingSizes.Right === null) {
            this.setState({
                Menu: {...this.state.Menu,
                    isShowRight: false,
                    DeletingSizes: {...this.state.Menu.DeletingSizes, Right: DeletingSize, RightBlock: l4}}
            });
            callback();
        }
        if((L < (l1 + l2 + l3 + l4) * 1.1) && this.state.Menu.DeletingSizes.Center === null)  {
            this.setState({
                Menu: {...this.state.Menu,
                    isShowCenter: false,
                    DeletingSizes: {...this.state.Menu.DeletingSizes, Center: DeletingSize, CenterBlock: l3}}
            });
           callback();
        }
        if ((L < (l1 + l2 + l3) * 1.1 ) && this.state.Menu.DeletingSizes.Left === null) {
            this.setState({
                Menu: {...this.state.Menu,
                    isShowLeft: false,
                    DeletingSizes: {...this.state.Menu.DeletingSizes, Left: DeletingSize, LeftBlock: l2}}
            });

            callback();
        }
    }

    showNextItem(CurrentSize, containers, Entry, callback) {
        //types CurrentSize: number, containers:  Element[], Entry:  ResizeObserverEntry, callback: (isAll:boolean)=>void

        let L = Entry.contentRect.width;

        let l1 = Entry.target.children[0].clientWidth ?? 0;
        let l2 = Entry.target.children[1] ? Entry.target.children[1].clientWidth : 0;
        let l3 = Entry.target.children[2] ? Entry.target.children[2].clientWidth : 0;
        let l4 = Entry.target.children[3] ? Entry.target.children[3].clientWidth : 0;
        let l5 = Entry.target.children[4] ? Entry.target.children[4].clientWidth : 0;



        const {Menu: {DeletingSizes} } = this.state;

        if (L >= ((DeletingSizes.LeftBlock ?? l2) + 100) * 1.1 && DeletingSizes.Left !== null) {

            this.setState({
                Menu: {...this.state.Menu,
                    isShowLeft: true,
                    DeletingSizes: {...this.state.Menu.DeletingSizes, Left: null}}
            });
            callback(false);
        }
        if(L >= (l1 + (DeletingSizes.LeftBlock ?? l2) +  (DeletingSizes.CenterBlock ?? l3) + 100) * 1.1 && DeletingSizes.Center !== null)  {

            this.setState({
                Menu: {...this.state.Menu,
                    isShowCenter: true,
                    DeletingSizes: {...this.state.Menu.DeletingSizes, Center: null}}
            });
            callback(false);
        }
        if(L >= (l1 + (DeletingSizes.LeftBlock ?? l2) + (DeletingSizes.CenterBlock ?? l3) + (DeletingSizes.RightBlock ?? 100)) * 1.1 && DeletingSizes.Right !== null) {

            this.setState({
                Menu: {...this.state.Menu,
                    isShowRight: true,
                    DeletingSizes: {...this.state.Menu.DeletingSizes, Right: null}}
            });
            callback(true);
        }
    }
    componentDidMount() {
        //для дропдауна(скрыть/показать) при изменении ширины
        const Exec = (entries) => {
            for (let entry of entries) {

                let CurrentWidth = this.state.CurrentWidth;

                this.setState({ CurrentWidth: entry.contentRect.width}, ()=>{
                    // let isMinHeight = entry.contentRect.height > 50;
                    let containers = Array.from(entry.target.children);

                    let lastDeleteSize = 0;
                    if( this.state.UpdateCounter === 0) {
                        lastDeleteSize = 1_000_000;
                    } else {
                        if(this.state.Menu?.DeletingSizes.Right && !this.state.Menu.DeletingSizes.Center) {

                            lastDeleteSize = this.state.Menu?.DeletingSizes.Right;
                        }
                        else if(this.state.Menu?.DeletingSizes.Right && this.state.Menu.DeletingSizes.Center && !this.state.Menu.DeletingSizes.Left) {
                            lastDeleteSize = this.state.Menu?.DeletingSizes.Center;
                        }
                        else if(this.state.Menu?.DeletingSizes.Right && this.state.Menu.DeletingSizes.Center && this.state.Menu.DeletingSizes.Left){
                            lastDeleteSize = this.state.Menu?.DeletingSizes.Left;
                        }
                        else {
                            lastDeleteSize = 1_000_000;
                        }
                    }

                    let delta = Math.abs(lastDeleteSize -  entry.contentRect.width) / entry.contentRect.width;
                    if(delta > 0.03 || this.state.UpdateCounter === 0) {
                        if (CurrentWidth >= entry.contentRect.width) this.hideNextItem(entry, entry.contentRect.width, () => {
                            this.setState({UpdateCounter: this.state.UpdateCounter + 1});
                        });

                        else if (CurrentWidth < entry.contentRect.width) this.showNextItem(entry.contentRect.width, containers, entry, (isAll) => {
                            if (isAll) {
                                this.setState({UpdateCounter: 0});
                            } else {
                                this.setState({UpdateCounter: this.state.UpdateCounter + 1});
                            }
                        });
                    }

                });
            }
        }

        if(this.state.Menu) {
            this.resizeObserver = new ResizeObserver((entries) => {
                Exec(entries);
            });
            this.resizeObserver.observe(this.resizeElement.current);

        }

       let index = this.props.userSettings.findIndex(allUserSettings => allUserSettings.Name === "packageTypeConfig")
       let indexAdditions = this.props.userSettings.findIndex(allUserSettings => allUserSettings.Name === "packageTypeConfigAdditional")
       if(index >= 0) {
           this.props.changePackagesTablesViewMode({
               Table: this.props.userSettings[index].Value[0].Table,
               ViewMode:this.props.userSettings[index].Value[0].ViewMode,
               IsDownload : this.props.tableRole === "download"
           });
       }
       if (indexAdditions >= 0){
           this.props.changePackagesTablesViewMode({
               Table: this.props.userSettings[indexAdditions].Value[0].Table,
               ViewMode:this.props.userSettings[indexAdditions].Value[0].ViewMode,
               IsDownload : this.props.tableRole === "download"
           });
       }
    }

    async handleFilters(event) {
        // Если нажали "Показать/скрыть фильтр"
        if (event.target.id === "show") {
            // Если нажали в основной (левой) таблице с пакетами
            if (this.props.tableRole === "main") {
                this.props.changePackagesViewMode({
                    // Если в модалке отображались фильтры - скрываем их и отображаем дерево, иначе - отображаем фильтры
                    ViewMode: this.props.viewMode === RootComponentsStateViewModeTypes.PackagesApp.Search
                        ? RootComponentsStateViewModeTypes.PackagesApp.Tree
                        : RootComponentsStateViewModeTypes.PackagesApp.Search
                })
            }
            // Если нажали В дополнительной (правой) таблице, именно пакетами (только обычными) (this.props.tableRole === "additional") ИЛИ дополнительной (правой) таблице, именно с пакетами загрузки (this.props.tableRole === "downoload")
            else {
                this.props.setUserModalShow({
                    // Если фильтры были открыты - скрываем модалку, иначе - устанавливаем модалку фильтров
                    isShow: !(this.props.userModalShow && this.props.userModalType === "treePackagesSearch"),
                    Type: "treePackagesSearch",
                    // для скрытия пакетов при закрытии модального окна с фильтрами в таблице пакетов загрузки
                    TableRole: this.props.tableRole
                });
            }
        }
        // Если нажали "Сбросить и закрыть фильтр" (event.target.id === "disabled")
        else {
            let searchInputs, currentSortedParams, hideFiltersFunc
            if (this.props.tableRole === "main") {
                searchInputs = this.props.searchInputsMain
                currentSortedParams = store.getState().packages.tree.SortedParams
                hideFiltersFunc = ({Next = null, Error = null}) => {
                    // Cкрываем фильтры, отображаем дерево
                    this.props.changePackagesViewMode({
                        ViewMode: RootComponentsStateViewModeTypes.PackagesApp.Tree,
                        Next: {
                            action: () => {
                                if (Next) {Next.action()}
                            }
                        },
                        Error: {
                            action: () => {
                                if (Error) {Error.action()}
                            }
                        }
                    })
                }
            } else {
                if (this.props.tableRole === "additional") {
                    searchInputs = this.props.searchInputsAdditional
                    currentSortedParams = store.getState().packages.treeAdditional.SortedParams
                } else if (this.props.tableRole === "download") {
                    searchInputs = this.props.searchInputsDownload
                    currentSortedParams = store.getState().packages.treeDownload.SortedParams
                }
                hideFiltersFunc = ({Next}) => {
                    // Cкрываем модалку фильтров
                    this.props.setUserModalShow({
                        isShow: false,
                        Type: "treePackagesSearch",
                        TableRole: this.props.tableRole,
                        Next: {
                            action: () => {
                                if (Next) {Next.action()}
                            }
                        },
                        Error: {
                            action: () => {
                                if (Error) {Error.action()}
                            }
                        }
                    });
                }
            }

            let wasSortedByUser;
            for (const [key, value] of Object.entries(currentSortedParams)) {
                if (value) {
                    wasSortedByUser = true;
                    break;
                }
            }

            // Если есть принятые фильтры или сортировка - надо сбросить
            if (Object.values(searchInputs.AppliedParams).filter(Boolean).length || wasSortedByUser) {
                this.props.setLoaderModalData({
                    data : {
                        content : "Загрузка пакетов...",
                        disableButton : true,
                        fullBackground : true,
                        gif : "packages",
                        key: "MainTablePackage413"
                    },
                    Next : {
                        action : ()=> {
                            hideFiltersFunc({
                                Next : {
                                    action : async () => {
                                        this.props.updateInputsFieldSortingPackage({
                                            TableRole: this.props.tableRole,
                                            Next: {
                                                action: async () => {
                                                    await this.props.updateInputsFieldPackage({
                                                        TableRole: this.props.tableRole,
                                                        Next: {
                                                            action: async () => {
                                                                this.props.handlerIsSearch({
                                                                    TableType: this.props.tableRole,
                                                                    Next: {
                                                                        action: async () => {
                                                                            await this.props.PackagesPaginationMovePage({
                                                                                PageNumber: 1,
                                                                                TableType: this.props.tableRole,
                                                                                Next: {
                                                                                    action: () => {
                                                                                        __.deleteDocFromLinkUsingActiveNode(this.props.tableRole);
                                                                                        this.props.setLoaderModalData({keyDeleted: "MainTableSearchPackage425"});
                                                                                    },
                                                                                    params: {}
                                                                                }
                                                                            });
                                                                        },
                                                                        params: {}
                                                                    }
                                                                });

                                                            },
                                                            params: {}
                                                        }
                                                    });
                                                },
                                                params: {}
                                            }
                                        })
                                    }
                                }
                            })
                        }
                    }
                })
            } else {
                hideFiltersFunc({
                    Next: {
                        action: () => {
                            // Очистка полей фильтров
                            this.props.updateInputsFieldPackage({
                                TableRole: this.props.tableRole
                            })
                        }
                    }
                })
            }
        }
    }

    loadPackages(isChangeViewMode) {

        this.props.ActionQueue({
            List : [
                //Новый прелоадер
                {action: this.props.SelectActivePackageNode, params : {
                        Id : this.props.activeNodeAdditional.Node.Id ?? 2,
                        TableType : "additional"
                    }, name: "SelectActivePackageNode"},
                {action: this.props.SelectActivePackageDocument, params : {
                        TableType : "additional"
                    }, name: "SelectActivePackageDocument"},
                {action: this.props.setViewModePreviewPackages, params : {
                        ViewMode : isChangeViewMode ? "two_table_none" : this.props.viewModePreview
                    }, name: "setViewModePreviewPackages"},
                {action: this.props.breadcrumbsAdd, params : {
                        NodeId : this.props.activeNodeAdditional.Node.Id ?? 2,
                        Name : this.props.activeNodeAdditional.Node.Name ?? "Пакеты",
                        TableType : "additional"
                    }, name: "breadcrumbsAdd"},

            ],
            debug : true
        });
    }


    toggleTwoTables() {
        if(this.props.viewModePreview === "two_table_none" || this.props.viewModePreview === "right_document_table" || this.props.viewModePreview === "two_table_download") {

            let activeDocument= this.props.activeDocument;
            let query = this.props.router.location.query;
            let queryLink = "";

            queryLink = Routing.CreateRef({
                oldQuery : query,
                newQuery : {},
                excludedKeys : ["p2","d2","dt2","dg2","dd2","dwp","dwd"]
            });

            let link = `${this.props.router.location.pathname}${queryLink}`;
            history.push(link);
            if(activeDocument){
                this.props.setViewModePreviewPackages({
                    ViewMode : "normal",
                });
            }else{
                this.props.setViewModePreviewPackages({
                    ViewMode : "none",
                });
            }

        }
        else {

            let query = this.props.router.location.query;
            let queryLink = "";

            queryLink = Routing.CreateRef({
                oldQuery : query,
                newQuery : {p2 : 2},
                excludedKeys : ["d2","dt2","dg2","dd2","dwp","dwd"]
            });

            let link = `${this.props.router.location.pathname}${queryLink}`;
            //TODO при открытии дополнительной таблицы, пользователь по шагам видит свой предыдущий путь
            //history.replace(link);
            history.push(link);

            this.loadPackages(true);
        }
    }

    async movePrev() {
        this.props.setLoaderModalData({
            data: {
                content: "Загрузка...",
                disableButton: true,
                fullBackground: true,
                gif: "packages",
                key: "MainTableSearchPackage701"
            }
        })

        let breadcrumbsArrowPrev;
        if(this.props.tableRole === "main") breadcrumbsArrowPrev = this.props.breadcrumbsArrowPrev;
        else if(this.props.tableRole === "additional") breadcrumbsArrowPrev = this.props.breadcrumbsArrowPrevAdditional;
        else if(this.props.tableRole === "download") breadcrumbsArrowPrev = this.props.breadcrumbsArrowPrevDownload;
        if(breadcrumbsArrowPrev) {
            await this.props.breadcrumbsMovePrev({
                TableType : this.props.tableRole,
                IsDownload : this.props.tableRole === "download" ? true : undefined,
                navigate: true
            });
        }
        await this.props.setViewModePreviewPackages({
            ViewMode : (this.props.viewModePreview=="none" || this.props.viewModePreview=="normal") ? "none" : this.props.viewModePreview,
        });
        // TODO КН 23.01.24 Прим.: tree.activeDocument сетается в null
        await this.props.SelectActivePackageDocument({TableType: this.props.tableRole});

        this.props.setLoaderModalData({keyDeleted: "MainTableSearchPackage721"});
    }

    async moveNext() {
        this.props.setLoaderModalData({
            data: {
                content: "Загрузка...",
                disableButton: true,
                fullBackground: true,
                gif: "packages",
                key: "MainTableSearchPackage731"
            }
        })

        let breadcrumbsArrowNext;
        if(this.props.tableRole === "main") breadcrumbsArrowNext = this.props.breadcrumbsArrowNext;
        else if(this.props.tableRole === "additional") breadcrumbsArrowNext = this.props.breadcrumbsArrowNextAdditional;
        else if(this.props.tableRole === "download") breadcrumbsArrowNext = this.props.breadcrumbsArrowNextDownload;
        if(breadcrumbsArrowNext) {
            await this.props.breadcrumbsMoveNext({
                TableType : this.props.tableRole,
                IsDownload : this.props.tableRole === "download" ? true : undefined,
                navigate: true
            });
        }
        await this.props.setViewModePreviewPackages({
            ViewMode : (this.props.viewModePreview=="none" || this.props.viewModePreview=="normal") ? "none" : this.props.viewModePreview,
        });
        // TODO КН 23.01.24 Прим.: tree.activeDocument сетается в null
        await this.props.SelectActivePackageDocument({TableType: this.props.tableRole});

        this.props.setLoaderModalData({keyDeleted: "MainTableSearchPackage752"});
    }

    changeViewMode(Table, ViewMode) {
        this.props.updateUserSettings({
            Name : UserSettingsNames.PACKAGE_TYPE_CONFIG,
            Action : UserSettingsActionsTypes.Update,
            Data: {
                Table,
                ViewMode,
                IsDownload: this.props.tableRole === "download"
            },
        })
        this.props.changePackagesTablesViewMode({
            Table,
            ViewMode,
            IsDownload: this.props.tableRole === "download"
        });
    }

    // @param {"documents" || "packages"} Type
    async changeViewFilter(Type, lStoreValue) {
        let treeFieldName;
        if (this.props.tableRole === "main") {
            treeFieldName = 'tree'
        } else if (this.props.tableRole === "additional") {
            treeFieldName = 'treeAdditional'
        } else if (this.props.tableRole === "download") {
            treeFieldName = 'treeDownload'
        }

        const treeBeforeChange = store.getState().packages[treeFieldName]
        const oldViewFilterValue = treeBeforeChange.viewFilter
        this.props.changeViewFilter({
            Type,
            TableType: this.props.tableRole,
            lStoreValue: lStoreValue,
        });
        const treeAfterChange = store.getState().packages[treeFieldName]
        const newViewFilterValue = treeAfterChange.viewFilter

        if (oldViewFilterValue !== newViewFilterValue) {
            const countOfPages = Math.ceil(treeAfterChange.lastLoad.documentsTotal / treeAfterChange.paginationPageSize);
            let newPageNumber = treeAfterChange.paginationPageNumber // TODO Кн 19.04.24 в treeAfterChange.paginationPageNumber еще лежит старая страница
            // Если страниц меньше чем стояла текущая, то поставить последнюю
            if (countOfPages < newPageNumber) {
                newPageNumber = countOfPages ?? 1
            }
            this.props.setLoaderModalData({
                data: {
                    content: "Загрузка пакетов...",
                    disableButton: true,
                    fullBackground: true,
                    gif: "packages",
                    key: "MainTablePackage871"
                }
            })
            let currentSortedParams, FieldSorting, Direction;
            if (this.props.tableRole === "main") {
                currentSortedParams = store.getState().packages.tree.SortedParams
            } else if (this.props.tableRole === "additional") {
                currentSortedParams = store.getState().packages.treeAdditional.SortedParams
            } else if (this.props.tableRole === "download") {
                currentSortedParams = store.getState().packages.treeDownload.SortedParams
            }
            for (const [key, value] of Object.entries(currentSortedParams)) {
                if (value) {
                    FieldSorting = key
                    Direction = value
                    break;
                }
            }
            await this.props.PackagesPaginationMovePage({
                PageNumber: newPageNumber,
                TableType: this.props.tableRole,
                FieldSorting,
                Direction,
                Next: {
                    action: () => {
                        __.deleteDocFromLinkUsingActiveNode(this.props.tableRole);
                        this.props.setLoaderModalData({keyDeleted: "MainTableSearchPackage893"});
                    },
                    params: {}
                }
            })
        }
    }

    PlusPackage(inLevel = false, isAdditional = false) {
        let activeNode = isAdditional ? this.props.activeNodeAdditional : this.props.activeNode

        if(inLevel) {
            let IdParent = this.props.activeNode.Node.Id === 2
                ? activeNode.Node.Id
                : activeNode.Node.IdParent;

            this.props.setModalData({
                name: ModalTypes.packages.crud,
                data: {
                    type : "createNewPackages",
                    inLevel,
                    idParent : IdParent,
                    activeNode : activeNode
                }
            });
        } else {
            this.props.setModalData({
                name: ModalTypes.packages.crud,
                data: {
                    type : "createNewPackages",
                    inLevel : inLevel,
                    idParent : activeNode.Node.Id,
                    activeNode : activeNode
                }
            });
        }
    }

    deletePackage() {
        const { tableRole, activeNode, activeNodeAdditional, setModalData} = this.props;
        const currentNode = tableRole === "main" ? activeNode : activeNodeAdditional;

        setModalData({
            name: ModalTypes.packages.crud,
            data: {
                type : "deletePackage",
                tableRole: tableRole,
                idParent : currentNode,
                activeNode : new TreeCombinePackage(true, tableRole, false).searchById(currentNode.Node.IdParent)
            }
        });
    }

    updateNamePackage(){
        this.props.setModalData({
            name: ModalTypes.packages.crud,
            data: {
                type : "updateNamePackage",
                activeNode : this.props.activeNode
            }
        });
    }

    async exportXLS(){
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        //Новый прелоадер
        this.props.setLoaderModalData({
            data : {content : "Загрузка отчета...", disableButton : true, fullBackground : false, key: "MainTableSearchPackage799"}
        });
        try {
            let reportType =
                // (this.props.tableRole === TableTypesEnum.Main || this.props.tableRole === TableTypesEnum.Additional) ?
                    "packages"
                // : "download";

            let activeNode;
            if(this.props.tableRole === TableTypesEnum.Main) activeNode = this.props.activeNode;
            else if(this.props.tableRole === TableTypesEnum.Additional) activeNode = this.props.activeNodeAdditional;
            else if(this.props.tableRole === TableTypesEnum.Download) activeNode = this.props.activeNodeDownload;


            let entityReport = new EntityReport({
                Type : reportType,
                idObject : activeNode.Node.Id,
                Columns : [],
                ReportName : __.translit(activeNode.Node.Name)
            });

            let reportGuid = await entityReport.create(this.props.tableRole);


            while(true) {
                let status = await entityReport.getState();
                if (status == IReportState.Done) break;
                await sleep(5000);
            }

            let report = await entityReport.getReportFile();

            var link = document.createElement("a");
            link.setAttribute("href", URL.createObjectURL(report.file));
            link.setAttribute("download", report.name);
            link.click();
            //Новый прелоадер
            this.props.setLoaderModalData({keyDeleted: "MainTableSearchPackage799"});
        }
        catch(e) {
            //Новый прелоадер
            this.props.setLoaderModalData({keyDeleted: "MainTableSearchPackage799"});
        }
    }

    deleteDocument() {
        let checkedFields = this.props.tableRole === TableTypesEnum.Main
            ? this.props.checkedFieldsMainTable
            : this.props.checkedFields
        let activeNode
        if (this.props.tableRole === TableTypesEnum.Main) activeNode = this.props.activeNode
        else if (this.props.tableRole === TableTypesEnum.Additional) activeNode = this.props.activeNodeAdditional
        const collection = []

        for (let child of activeNode?.Children) {
            if (checkedFields.includes(child.Node.Version?.IdDoc)) {
                collection.push({Id: child.Node.IdPackageDocRef})
            }
        }

        this.props.setModalData({
            name: ModalTypes.packages.crud,
            data: {
                type: "deleteDocument",
                collection,
                activeNode,
            }
        });
    }
    async updateTable() {
        // TODO КН 17.04.24 Неужели все это надо делать для обновления таблицы, и если да все ли збесь делается
        let IsDownload = this.props.tableRole === "download";
        let activeNode;
        if (this.props.tableRole === "main") activeNode = this.props.activeNode;
        else if (this.props.tableRole === "additional") activeNode = this.props.activeNodeAdditional;
        else if (this.props.tableRole === "download") activeNode = this.props.activeNodeDownload;
        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data: {
                content: "Загрузка пакетов...",
                disableButton: true,
                fullBackground: true,
                gif: "packages",
                key: "MainTablePackage850"
            }
        }))

        await this.props.PackageAddNodesTree({
            Id: activeNode.Node.Id,
            TableType: this.props.tableRole,
            IsDownload: IsDownload,
            Next: {
                action: async () => {
                    this.props.SelectActivePackageNode({
                        Id: activeNode.Node.Id,
                        TableType: this.props.tableRole,
                        IsDownload: IsDownload
                    });

                    this.props.togglePackagesFolders({
                        IdFolder: activeNode.Node.Id,
                        TableType: this.props.tableRole,
                        IsDownload: IsDownload,
                    });

                    this.props.breadcrumbsAdd({
                        NodeId: activeNode.Node.Id,
                        Name: activeNode.Node.Name,
                        ParentId: activeNode.Node.IdParent,
                        TableType: this.props.tableRole,
                        IsDownload: IsDownload
                    });
                    await this.props.breadcrumbsMoveSelect({
                        NodeId: activeNode.Node.Id,
                        TableType: this.props.tableRole,
                        IsDownload: IsDownload
                    });

                    if (this.props.tableRole == "main") {
                        if (this.props.viewModePreview == "none" || this.props.viewModePreview == "normal") {

                            this.props.setViewModePreviewPackages({
                                ViewMode: "none",
                            });
                        } else {
                            this.props.setViewModePreviewPackages({
                                ViewMode: this.props.viewModePreview,
                            });
                        }
                    } else if (this.props.tableRole == "download") {
                        if (this.props.viewModePreview == "two_table_download") {
                            this.props.setViewModePreviewPackages({
                                ViewMode: "two_table_download",
                            });
                        } else {
                            this.props.setViewModePreviewPackages({
                                ViewMode: "none",
                            });
                        }
                    }
                    this.props.SelectActivePackageDocument({TableType: this.props.tableRole});
                    // }


                    let query = this.props.router.location.query;
                    let queryLink = "";
                    if (this.props.tableRole === "main") {
                        queryLink = Routing.CreateRef({
                            oldQuery: query,
                            newQuery: {p: activeNode.Node.Id},
                            excludedKeys: ["d"]
                        });
                    } else if (this.props.tableRole === "additional") {
                        queryLink = Routing.CreateRef({
                            oldQuery: query,
                            newQuery: {p2: activeNode.Node.Id},
                            excludedKeys: ["d2"]
                        });
                    } else if (this.props.tableRole === "download") {
                        queryLink = Routing.CreateRef({
                            oldQuery: query,
                            newQuery: {dwp: activeNode.Node.Id},
                            excludedKeys: ["dwd"]
                        });
                    }

                    let link = `${this.props.router.location.pathname}${queryLink}`;
                    history.push(link);
                    // store.dispatch(setModalData({}));
                    //Новый прелоадер
                    store.dispatch(setLoaderModalData({keyDeleted: "MainTableSearchPackage932"}));
                },
                params: {}
            }
        });
    }

    copyUrl(for1C = false) {
        // if(for1C) {
        //     __.copyUrl1c();
        // } else {
            let idPack, idDoc;
            const {tableRole, activeNode, activeDocument, activeNodeAdditional, activeDocumentAdd, activeNodeDownload, activeDocumentDownload} = this.props;
            switch (tableRole) {
                case TableTypesEnum.Main: {
                    idPack = activeNode.Node.Id;
                    idDoc = activeDocument?.Node.Id;
                    break;
                }
                case TableTypesEnum.Additional: {
                    idPack = activeNodeAdditional.Node.Id;
                    idDoc = activeDocumentAdd?.Node.Id;
                    break;
                }
                case TableTypesEnum.Download: {
                    idPack = activeNodeDownload.Node.Id;
                    idDoc = activeDocumentDownload?.Node.Id;
                    break;
                }

                default: return
            }
        if (for1C) {
            __.copyUrl1c(idDoc ?? null, idPack, tableRole);
        } else
            __.copyUrl(idDoc ?? null, idPack, tableRole === TableTypesEnum.Download);
        // }
        __.showModalCopyComplete(this.props.setModalData);
    }

    //рендер кнопок, которые переключают viewMode
    viewModeBtns(tableClass = "", middleCardsClass = "", bigCardsClass = "", toggleButtonClass = "") {
        if(this.props.tableRole !== "main") {
            return (
                <div className="d-flex btn-toolbar card-toolbar justify-content-center">
                    {
                        this.state.tableViewTooltipShow &&
                        <MyTooltip target={this.tableViewRef}
                                   text={"Таблица"}
                                   show={this.state.tableViewTooltipShow}
                                   placement={"top"} delay={500}
                        />
                    }
                    <span className={tableClass}
                          ref={this.tableViewRef}
                          onMouseEnter={() => {this.setState({tableViewTooltipShow: true})}}
                          onMouseLeave={() => {this.setState({tableViewTooltipShow: false})}}
                          onClick={() => {this.changeViewMode(this.props.tableRole,
                              RootComponentsStateViewModeTypes.PackagesApp.Table_table)}}>
                          <i className="svg-icon icon-Table_grid"/>
                     </span>

                    {
                        this.state.middleViewTooltipShow &&
                        <MyTooltip target={this.middleViewRef}
                                   text={"Средние значки"}
                                   show={this.state.middleViewTooltipShow}
                                   placement={"top"} delay={500}
                        />

                    }
                    <span className={middleCardsClass}
                          ref={this.middleViewRef}
                          onMouseEnter={() => {this.setState({middleViewTooltipShow: true})}}
                          onMouseLeave={() => {this.setState({middleViewTooltipShow: false})}}
                          onClick={() => {this.changeViewMode(this.props.tableRole,
                              RootComponentsStateViewModeTypes.PackagesApp.Table_middleCards)}}>
                          <i className="svg-icon icon-Grid_list"/>
                     </span>


                    {
                        this.state.bigViewTooltipShow &&
                        <MyTooltip target={this.bigViewRef}
                                   text={"Крупные значки"}
                                   show={this.state.bigViewTooltipShow}
                                   placement={"top"} delay={500}
                        />
                    }
                    <span className={bigCardsClass}
                          ref={this.bigViewRef}
                          onMouseEnter={() => {this.setState({bigViewTooltipShow: true})}}
                          onMouseLeave={() => {this.setState({bigViewTooltipShow: false})}}
                          onClick={() => {this.changeViewMode(this.props.tableRole,
                              RootComponentsStateViewModeTypes.PackagesApp.Table_bigCards)}}>
                          <i className="svg-icon icon-Grid_card"/>
                     </span>
                    {
                        this.state.additionalViewTooltipShow &&
                        <MyTooltip target={this.additionalViewRef}
                                   text={"Показать/Скрыть дополнительную таблицу"}
                                   show={this.state.additionalViewTooltipShow}
                                   placement={"top"} delay={500}
                        />
                    }

                    {/*{*/}
                    {/*    this.props.tableRole === "main" &&*/}
                    {/*    <span className={toggleButtonClass}*/}
                    {/*          ref={this.additionalViewRef}*/}
                    {/*          onMouseEnter={() => {this.setState({additionalViewTooltipShow: true})}}*/}
                    {/*          onMouseLeave={() => {this.setState({additionalViewTooltipShow: false})}}*/}
                    {/*          onClick={() => {this.toggleTwoTables()}}>*/}
                    {/*        {*/}
                    {/*            (this.props.viewModePreview === "two_table_none" || this.props.viewModePreview === "right_document_table" || this.props.viewModePreview === "two_table_download") &&*/}
                    {/*            <i className="svg-icon icon-Remove_table"/>*/}
                    {/*        }*/}
                    {/*        {*/}
                    {/*            (this.props.viewModePreview === "none" || this.props.viewModePreview === "normal") &&*/}
                    {/*            <i className="svg-icon icon-Add_table"/>*/}
                    {/*        }*/}
                    {/*    </span>*/}
                    {/*}*/}

                </div>
            )
        } else {
            return (
                <div className="d-flex btn-toolbar card-toolbar justify-content-center">
                    {
                        this.state.tableViewTooltipShow &&
                        <MyTooltip target={this.tableViewRef}
                                   text={"Таблица"}
                                   show={this.state.tableViewTooltipShow}
                                   placement={"top"} delay={500}
                        />
                    }
                    <span className={tableClass}
                          ref={this.tableViewRef}
                          onMouseEnter={() => {
                              this.setState({tableViewTooltipShow: true})
                          }}
                          onMouseLeave={() => {
                              this.setState({tableViewTooltipShow: false})
                          }}
                          onClick={() => {
                              this.changeViewMode(this.props.tableRole, RootComponentsStateViewModeTypes.PackagesApp.Table_table)
                          }}>
                          <i className="svg-icon icon-Table_grid"/>
                     </span>

                    {
                        this.state.middleViewTooltipShow &&
                        <MyTooltip target={this.middleViewRef}
                                   text={"Средние значки"}
                                   show={this.state.middleViewTooltipShow}
                                   placement={"top"} delay={500}
                        />

                    }
                    <span className={middleCardsClass}
                          ref={this.middleViewRef}
                          onMouseEnter={() => {
                              this.setState({middleViewTooltipShow: true})
                          }}
                          onMouseLeave={() => {
                              this.setState({middleViewTooltipShow: false})
                          }}
                          onClick={() => {
                              this.changeViewMode(this.props.tableRole, RootComponentsStateViewModeTypes.PackagesApp.Table_middleCards)
                          }}>
                          <i className="svg-icon icon-Grid_list"/>
                     </span>


                    {
                        this.state.bigViewTooltipShow &&
                        <MyTooltip target={this.bigViewRef}
                                   text={"Крупные значки"}
                                   show={this.state.bigViewTooltipShow}
                                   placement={"top"} delay={500}
                        />
                    }
                    <span className={bigCardsClass}
                          ref={this.bigViewRef}
                          onMouseEnter={() => {
                              this.setState({bigViewTooltipShow: true})
                          }}
                          onMouseLeave={() => {
                              this.setState({bigViewTooltipShow: false})
                          }}
                          onClick={() => {
                              this.changeViewMode(this.props.tableRole, RootComponentsStateViewModeTypes.PackagesApp.Table_bigCards)
                          }}>
                          <i className="svg-icon icon-Grid_card"/>
                     </span>
                    {
                        this.state.additionalViewTooltipShow &&
                        <MyTooltip target={this.additionalViewRef}
                                   text={"Показать/Скрыть дополнительную таблицу"}
                                   show={this.state.additionalViewTooltipShow}
                                   placement={"top"} delay={500}
                        />
                    }

                    {
                        this.props.tableRole === "main" &&
                        <span className={toggleButtonClass}
                              ref={this.additionalViewRef}
                              onMouseEnter={() => {
                                  this.setState({additionalViewTooltipShow: true})
                              }}
                              onMouseLeave={() => {
                                  this.setState({additionalViewTooltipShow: false})
                              }}
                              onClick={() => {
                                  this.toggleTwoTables()
                              }}>
                            {
                                (this.props.viewModePreview === "two_table_none" || this.props.viewModePreview === "right_document_table" || this.props.viewModePreview === "two_table_download") &&
                                <i className="svg-icon icon-Remove_table"/>
                            }
                            {
                                (this.props.viewModePreview === "none" || this.props.viewModePreview === "normal") &&
                                <i className="svg-icon icon-Add_table"/>
                            }
                        </span>
                    }
                </div>
            )
        }
    }
    //рендер кнопок фильтров
    filtersBtns() {
        //TODO aa Задача № 20065 БАГ. Фильтры в Пакетах при открытой доп таблице
        let searchInputs;
        if (this.props.tableRole == "additional") {
            searchInputs = this.props.searchInputsAdditional;
        } else if (this.props.tableRole == "download") {
            searchInputs = this.props.searchInputsDownload;
        } else {
            searchInputs = this.props.searchInputsMain;
        }

        let filterStyle = "icon-color-primary";
        if(searchInputs.AppliedParams) {
            // todo: ms 03.04
            let isFilter = Object.values(searchInputs.AppliedParams).some(item => item)
            if(isFilter) {
                filterStyle = "icon-color-secondary";
            }
        }
        return (
            <div className="d-flex btn-toolbar card-toolbar">
            {
                this.state.applyFilterTooltipShow &&
                <MyTooltip target={this.applyFilterRef}
                           text={"Показать/скрыть фильтр"}
                           show={this.state.applyFilterTooltipShow}
                           placement={"top"} delay={500}
                />
            }
            <span className="btn btn-icon btn-circle btn-sm mx-2" ref={this.applyFilterRef}
                  onMouseEnter={()=>{this.setState({applyFilterTooltipShow : true})}}
                  onMouseLeave={()=>{this.setState({applyFilterTooltipShow : false})}}
                  onClick={async (event) => {
                      await this.handleFilters(event)
                  }}>
                        <i id="show" className={`svg-icon icon-Table_edit_7 ${filterStyle}`}/>
                    </span>

            {
                this.state.cancelFilterTooltipShow &&
                <MyTooltip target={this.cancelFilterRef}
                           text={"Сбросить и закрыть фильтр"}
                           show={this.state.cancelFilterTooltipShow}
                           placement={"top"} delay={500}
                />
            }
            <span className="btn btn-icon btn-circle btn-sm" ref={this.cancelFilterRef}
                  onMouseEnter={()=>{this.setState({cancelFilterTooltipShow : true})}}
                  onMouseLeave={()=>{this.setState({cancelFilterTooltipShow : false})}}
                  onClick={async (event) => {
                      await this.handleFilters(event)
                  }}>
                        <i id="disabled" className="svg-icon icon-Table_edit_8 icon-color-primary"/>
                    </span>

        </div>
        )
    }

    //рендер кнопок переключения видимости пакетов и документов
    togglePacketsAndDocs(packClass = "", docClass = "") {
        return (
            <div className="d-flex btn-toolbar card-toolbar justify-content-center">
                {
                    this.state.packagesViewFilterTooltipShow &&
                    <MyTooltip target={this.packagesViewFilterRef}
                               text={"Показать/Скрыть Пакеты"}
                               show={this.state.packagesViewFilterTooltipShow}
                               placement={"top"} delay={500}
                    />
                }
                <span className="btn btn-icon btn-circle btn-sm mx-2" ref={this.packagesViewFilterRef}
                      onMouseEnter={()=>{this.setState({packagesViewFilterTooltipShow : true})}}
                      onMouseLeave={()=>{this.setState({packagesViewFilterTooltipShow : false})}}
                      onClick={async () => {
                          await this.changeViewFilter("packages")
                      }}
                >
                    <i className={`svg-icon icon-pak_on ${packClass}`}/>
                </span>

                {
                    this.state.documentsViewFilterTooltipShow &&
                    <MyTooltip target={this.documentsViewFilterRef}
                               text={"Показать/Скрыть Документы"}
                               show={this.state.documentsViewFilterTooltipShow}
                               placement={"top"} delay={500}
                    />
                }
                <span className="btn btn-icon btn-circle btn-sm" ref={this.documentsViewFilterRef}
                      onMouseEnter={()=>{this.setState({documentsViewFilterTooltipShow : true})}}
                      onMouseLeave={()=>{this.setState({documentsViewFilterTooltipShow : false})}}
                      onClick={async () => {
                          await this.changeViewFilter("documents")
                      }}>
                        <i className={`svg-icon icon-Documents ${docClass}`}/>
                    </span>

            </div>
        )
    }

    //настройка видимости блоков кнопок
    setButtons() {
        let viewMode, viewFilter, breadcrumbsArrowPrev, breadcrumbsArrowNext;
        if (this.props.tableRole === "main") {
            viewMode = this.props.viewModeMainTable;
            viewFilter = this.props.viewFilter;
            breadcrumbsArrowPrev = this.props.breadcrumbsArrowPrev;
            breadcrumbsArrowNext = this.props.breadcrumbsArrowNext;
        }
        else if (this.props.tableRole === "additional") {
            viewMode = this.props.viewModeAdditionalTable;
            viewFilter = this.props.viewFilterAdditional;
            breadcrumbsArrowPrev = this.props.breadcrumbsArrowPrevAdditional;
            breadcrumbsArrowNext = this.props.breadcrumbsArrowNextAdditional;
        }
        else if (this.props.tableRole === "download") {
            viewMode = this.props.viewModeDownloadTable;
            viewFilter = this.props.viewFilterDownload;
            breadcrumbsArrowPrev = this.props.breadcrumbsArrowPrevDownload
            breadcrumbsArrowNext = this.props.breadcrumbsArrowNextDownload;
        }

        let viewFilterPackagesClass, viewFilterDocumentsClass
        if (viewFilter === PackagesViewFilters.All) {
            viewFilterPackagesClass = "icon-color-primary";
            viewFilterDocumentsClass = "icon-color-primary";
        } else if (viewFilter === PackagesViewFilters.OnlyPackages) {
            viewFilterPackagesClass = "icon-color-primary";
            viewFilterDocumentsClass = "";
        } else if (viewFilter === PackagesViewFilters.OnlyDocuments) {
            viewFilterPackagesClass = "";
            viewFilterDocumentsClass = "icon-color-primary";
        }

        let changeViewModeBottomClass_table =
            viewMode === RootComponentsStateViewModeTypes.PackagesApp.Table_table
                ? "btn btn-icon btn-sm btn-primary mr-1 table-menu-btn"
                : "btn btn-icon btn-sm btn-circle mr-1";
        let changeViewModeBottomClass_middleCards =
            viewMode === RootComponentsStateViewModeTypes.PackagesApp.Table_middleCards
                ? "btn btn-icon btn-sm btn-primary mr-1 table-menu-btn"
                : "btn btn-icon btn-sm btn-circle mr-1";
        let changeViewModeBottomClass_bigCards =
            viewMode === RootComponentsStateViewModeTypes.PackagesApp.Table_bigCards
                ? "btn btn-icon btn-sm btn-primary mr-1 table-menu-btn"
                : "btn btn-icon btn-sm btn-circle mr-1";

        let toggleButtonClass = "btn btn-icon btn-sm btn-circle mr-1"
        if(this.props.viewModePreview === "two_table_none" || this.props.viewModePreview === "right_document_table" || this.props.viewModePreview === "two_table_download") {
            toggleButtonClass = "btn btn-icon btn-sm btn-primary mr-1 table-menu-btn"
        }

        let prevStyle = breadcrumbsArrowPrev ? {} : {backgroundColor: "#b5b5c3", borderColor: "#b5b5c3"};
        let nextStyle = breadcrumbsArrowNext ? {} : {backgroundColor: "#b5b5c3", borderColor: "#b5b5c3"};

        const {Menu: {isShowRight, isShowLeft, isShowCenter}} = this.state;

        return (
            <>
                <div className="d-flex btn-toolbar card-toolbar justify-content-start">
                    {
                        this.state.movePrevViewTooltipShow &&
                        <MyTooltip target={this.movePrevViewRef}
                                   text={"К предыдущему пакету"}
                                   show={this.state.movePrevViewTooltipShow}
                                   placement={"top"} delay={500}
                        />
                    }
                    <span className={`btn btn-icon btn-sm btn-primary btn- mr-2 table-menu-arrow-btn ${!breadcrumbsArrowPrev && "disabled"}`} onClick={this.movePrev} style={prevStyle}
                          ref={this.movePrevViewRef}
                          onMouseEnter={()=>{this.setState({movePrevViewTooltipShow : true})}}
                          onMouseLeave={()=>{this.setState({movePrevViewTooltipShow : false})}}>
                          <i className="svg-icon icon-Arrows_4"/>
                     </span>
                    {
                        this.state.moveNextViewTooltipShow &&
                        <MyTooltip target={this.moveNextViewRef}
                                   text={"К следующему пакету"}
                                   show={this.state.moveNextViewTooltipShow}
                                   placement={"top"} delay={500}
                        />
                    }
                    <span className={`btn btn-icon btn-sm btn-primary btn- mr-2 table-menu-arrow-btn ${!breadcrumbsArrowNext && "disabled"}`} onClick={this.moveNext} style={nextStyle}
                          ref={this.moveNextViewRef}
                          onMouseEnter={()=>{this.setState({moveNextViewTooltipShow : true})}}
                          onMouseLeave={()=>{this.setState({moveNextViewTooltipShow : false})}}>
                          <i className="svg-icon icon-Arrows_3"/>
                     </span>
                </div>

                {isShowLeft &&
                    //блок кнопок переключения viewMode
                    this.viewModeBtns(changeViewModeBottomClass_table,
                        changeViewModeBottomClass_middleCards,
                        changeViewModeBottomClass_bigCards,
                        toggleButtonClass)
                }

                {isShowCenter &&
                    this.filtersBtns()
                }

                {isShowRight &&
                    //блок кнопок переключения видимости пакетов и документов
                    this.togglePacketsAndDocs(viewFilterPackagesClass, viewFilterDocumentsClass)
                }
            </>

        )
    }

    //добавляем кнопки, которые были скрыты в дропдаун
    addDropdownItems() {
        const {Menu: {isShowRight, isShowLeft, isShowCenter}} = this.state;
        let viewFilter;
        if(this.props.tableRole === "main") viewFilter =  this.props.viewFilter;
        else if(this.props.tableRole === "additional") viewFilter =  this.props.viewFilterAdditional;
        else if(this.props.tableRole === "download") viewFilter =  this.props.viewFilterDownload;

        const isViewModePreviewNone = this.props.viewModePreview === "none" || this.props.viewModePreview === "normal"

        let icon = "icon-Table_edit_7"
        if (this.props.tableRole === TableTypesEnum.Main) {
            if (this.props.viewModeMainTable === RootComponentsStateViewModeTypes.PackagesApp.Table_table)
                icon = "icon-Table_grid"
            else if (this.props.viewModeMainTable === RootComponentsStateViewModeTypes.PackagesApp.Table_middleCards)
                icon = "icon-Grid_list"
            else if (this.props.viewModeMainTable === RootComponentsStateViewModeTypes.PackagesApp.Table_bigCards)
                icon = "icon-Grid_card"
        }
        if (this.props.tableRole === TableTypesEnum.Additional) {
            if (this.props.viewModeAdditionalTable === RootComponentsStateViewModeTypes.PackagesApp.Table_table)
                icon = "icon-Table_grid"
            else if (this.props.viewModeAdditionalTable === RootComponentsStateViewModeTypes.PackagesApp.Table_middleCards)
                icon = "icon-Grid_list"
            else if (this.props.viewModeAdditionalTable === RootComponentsStateViewModeTypes.PackagesApp.Table_bigCards)
                icon = "icon-Grid_card"
        }
        else if (this.props.tableRole === TableTypesEnum.Download) {
            if (this.props.viewModeDownloadTable === RootComponentsStateViewModeTypes.PackagesApp.Table_table)
                icon = "icon-Table_grid"
            else if (this.props.viewModeDownloadTable === RootComponentsStateViewModeTypes.PackagesApp.Table_middleCards)
                icon = "icon-Grid_list"
            else if (this.props.viewModeDownloadTable === RootComponentsStateViewModeTypes.PackagesApp.Table_bigCards)
                icon = "icon-Grid_card"
        }
        return (
            <>
                {!isShowLeft &&
                    <>
                        <Dropdown>
                            <Dropdown.Toggle className={"dropdown-toggle"}>
                                <span className="btn btn-icon btn-circle btn-sm mr-1">
                                    <i className={`svg-icon icon-color-white ${icon}`}/>
                                </span>
                                Сменить вид отображения
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => {this.changeViewMode(this.props.tableRole,
                                    RootComponentsStateViewModeTypes.PackagesApp.Table_table)}}>
                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                        <i className="dropdown-item svg-icon icon-color-primary icon-Table_grid"/>
                                    </span>
                                    Таблица
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => {this.changeViewMode(this.props.tableRole,
                                    RootComponentsStateViewModeTypes.PackagesApp.Table_middleCards)}}>
                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                        <i className="dropdown-item svg-icon icon-color-primary icon-Grid_list"/>
                                    </span>
                                    Средние значки
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => {this.changeViewMode(this.props.tableRole,
                                    RootComponentsStateViewModeTypes.PackagesApp.Table_bigCards)}}>
                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                        <i className="dropdown-item svg-icon icon-color-primary icon-Grid_card"/>
                                    </span>
                                    Крупные значки
                                </Dropdown.Item>
                            </Dropdown.Menu>

                        </Dropdown>
                        {
                            this.props.tableRole === "main" &&
                            <Dropdown.Item href="#" onClick={() => {this.toggleTwoTables()}}>
                                <span className={"btn btn-icon btn-circle btn-sm mr-1"}>
                                    {
                                        (this.props.viewModePreview === "two_table_none"
                                            || this.props.viewModePreview === "right_document_table"
                                            || this.props.viewModePreview === "two_table_download")
                                        &&
                                        <i className="dropdown-item svg-icon icon-Remove_table"/>
                                    }
                                    {
                                        isViewModePreviewNone &&
                                        <i className="dropdown-item svg-icon icon-Add_table"/>
                                    }
                                </span>
                                {`${isViewModePreviewNone ? "Показать " : "Скрыть"} дополнительную таблицу`}
                            </Dropdown.Item>
                        }
                    </>
                }

                {!isShowCenter &&
                    <>
                        <Dropdown.Item href="#" id="show" onClick={async (event) => {
                            await this.handleFilters(event)
                        }}>
                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                <i className="dropdown-item svg-icon icon-color-primary icon-Table_edit_7"/>
                            </span>
                            Показать/скрыть фильтр
                        </Dropdown.Item>
                        <Dropdown.Item href="#" onClick={async (event) => {
                            await this.handleFilters(event)
                        }}>
                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                <i className="dropdown-item svg-icon icon-color-primary icon-Table_edit_8"/>
                            </span>
                            Сбросить фильтр
                        </Dropdown.Item>
                    </>
                }

                {!isShowRight &&
                    <>
                        <Dropdown.Item href="#" disabled={viewFilter === "OnlyDocuments"} onClick={async () => {
                            await this.changeViewFilter("documents")
                        }}>
                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                <i className="dropdown-item svg-icon icon-color-primary icon-Documents"/>
                            </span>
                            {`${viewFilter === "OnlyPackages" ? "Показать " : "Скрыть "}документы`}
                        </Dropdown.Item>
                        <Dropdown.Item href="#" disabled={viewFilter === "OnlyPackages"} onClick={async () => {
                            await this.changeViewFilter("packages")
                        }}>
                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                <i className="dropdown-item svg-icon icon-color-primary icon-pak_on"/>
                            </span>
                            {`${viewFilter === "OnlyDocuments" ? "Показать " : "Скрыть "}пакеты`}
                        </Dropdown.Item>
                    </>
                }
            </>
        )

    }


    getCheckedFieldsView() {
        if(this.props.tableRole === "main" && this.props.checkedFieldsMainTable.length !== 0)
            return <div>Выбрано файлов: {this.props.checkedFieldsMainTable.length}</div>
        else if (this.props.tableRole !== "main" && this.props.checkedFields.length !== 0 )
            return <div>Выбрано файлов: {this.props.checkedFields.length}</div>

    }

    async getDocsAsZipArchive() {
        let checkedFields = this.props.tableRole === TableTypesEnum.Main
            ? this.props.checkedFieldsMainTable
            : this.props.checkedFields

        if (checkedFields.length > 0) {
            try {
                this.props.setLoaderModalData({
                    data: {content: "Загрузка отчета...", disableButton: true, fullBackground: false,  gif: "packages", key: "MainTableSearch"}
                });

                const zipFile = await API.documents().ExportDocsAsZipArchive({
                    IdDocs: checkedFields,
                    includeMainFile: true,
                    includeAttached: true,
                    includeSigs: true
                });

                const link = document.createElement("a");
                link.setAttribute("href", URL.createObjectURL(zipFile.file));
                link.setAttribute("download", zipFile.name);
                link.click();

                this.props.setLoaderModalData({keyDeleted: "MainTableSearch540"});
            }catch(e) {
                console.log(e)
            }
        }
    }

    render() {
        const {Menu: {isShowRight, isShowLeft, isShowCenter}} = this.state;

        let {
            body,
        } = this.props;

        let checkedFields
        if(this.props.tableRole === "main") checkedFields = this.props.checkedFieldsMainTable;
        else checkedFields = this.props.checkedFields

        let docs = body.filter(item => item.Type === "PackagesDocument" && !item.Node.IsMarkedForDelete);
        let checked = (checkedFields.length >= docs.length) && docs.length > 0;

        return (
            <>
            <div className="card-header border-0 py-2 mb-3" ref={this.resizeElement}>
                {<this.setButtons />}
                {
                    this.props.tableRole !== "main" ?
                        <div className="card-toolbar justify-content-end" style={{flexWrap: "nowrap"}}>

                            {/*Дропдаун для доп таблицы */}
                            {!isShowRight || !isShowCenter || !isShowLeft ?
                                <>
                                    {
                                        this.state.fitInContainerSizeTooltipShow &&
                                            <MyTooltip target={this.fitInContainerSize}
                                                       text={"Вписать"}
                                                       show={this.state.fitInContainerSizeTooltipShow}
                                                       placement={"top"} delay={500}
                                            />
                                    }
                                    <span className={`btn btn-icon ${!this.state.isFitInContainerSize ? 'btn-circle' : 'btn-primary'} btn-sm mx-2`} ref={this.fitInContainerSize}
                                          onClick={
                                              () => {
                                                  if (!this.state.isFitInContainerSize)
                                                      this.props.fitInContainerSize()
                                                  else
                                                      this.props.unFitInContainerSize();
                                                  this.setState({isFitInContainerSize: !this.state.isFitInContainerSize});
                                                  // this.props.fitInContainerSize
                                              }
                                          }
                                          onMouseEnter={()=>{this.setState({fitInContainerSizeTooltipShow : true})}}
                                          onMouseLeave={()=>{this.setState({fitInContainerSizeTooltipShow : false})}}>
                                        <i className={`svg-icon ${this.state.isFitInContainerSize ? '' : 'icon-color-primary'} icon-Doc_view_2`}/>
                                        </span>
                                    <Dropdown className={"mx-1"}>
                                        <Dropdown.Toggle className="btn btn-circle btn-outline-success dropdown-toggle">
                                            <i className="svg-icon icon-Settings"/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#" onClick={this.exportXLS}>
                                                <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                    <i className="dropdown-item svg-icon icon-color-primary icon-Table_edit_10"/>
                                                </span>
                                                Экспорт документов в Excel
                                            </Dropdown.Item>
                                            {
                                                this.props.adminLevel > AdminLevelEnum.CommonUser &&
                                                this.props.tableRole !== TableTypesEnum.Download &&
                                                <DropdownItem href="#" onClick={this.deleteDocument}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-Reload" />
                                                    </span>
                                                    Удалить документ(ы)
                                                </DropdownItem>
                                            }
                                            <DropdownItem href="#" onClick={async () => await this.updateTable()}>
                                                <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                    <i className="dropdown-item svg-icon icon-color-primary icon-Reload" />
                                                </span>
                                                Обновить пакет
                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={() => this.copyUrl(false)}>
                                                <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                    <i className="dropdown-item svg-icon icon-color-primary icon-Doc_view_14" />
                                                </span>
                                                Скопировать ссылку
                                            </DropdownItem>
                                            {this.lFlagCopyUrl1C &&
                                                <DropdownItem href="#" onClick={() => this.copyUrl(true)}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-c_16" />
                                                    </span>
                                                    Скопировать ссылку для 1С
                                                </DropdownItem>
                                            }
                                            {checkedFields.length !== 0 &&
                                                <Dropdown.Item href="#" onClick={this.getDocsAsZipArchive} className="dropdown-item">
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className={`dropdown-item svg-icon icon-color-primary icon-Doc_view_4`} />
                                                        </span>
                                                    Скачать отмеченные документы
                                                </Dropdown.Item>
                                            }
                                            {
                                                this.props.tableRole !== TableTypesEnum.Download &&
                                                this.props.adminLevel > AdminLevelEnum.CommonUser &&
                                                <>
                                                    <DropdownItem href="#" onClick={() => this.PlusPackage(false, true)}><span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-Plus" />
                                                    </span>
                                                        Создать новый пакет в текущем
                                                    </DropdownItem>
                                                    <DropdownItem href="#" onClick={()=>{this.deletePackage()}}><span className="btn btn-icon btn-circle btn-sm mr-1" >
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-Table_edit_12" />
                                                    </span>
                                                        Удалить текущий пакет
                                                    </DropdownItem>
                                                </>
                                            }
                                            <this.addDropdownItems />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </>
                                :
                                <>
                                    {
                                        store.getState().globalState.settings.Content?.WithDownloadFiles == "1" &&
                                        <>
                                            {
                                                this.state.downloadTooltipShow &&
                                                <MyTooltip target={this.downloadRef}
                                                           text={"Скачать файл"}
                                                           show={this.state.downloadTooltipShow}
                                                           placement={"top"} delay={500}
                                                />
                                            }
                                            {checkedFields.length !== 0 &&
                                                <span className="btn btn-icon btn-sm ml-2" ref={this.downloadRef}
                                                      onClick={this.getDocsAsZipArchive}
                                                      onMouseEnter={() => {
                                                          this.setState({downloadTooltipShow: true})
                                                      }}
                                                      onMouseLeave={() => {
                                                          this.setState({downloadTooltipShow: false})
                                                      }}
                                                >
                                            <i className={`svg-icon svg-icon-sm text-primary icon-Doc_view_4`}/>
                                        </span>
                                            }
                                        </>
                                    }
                                    {
                                        this.state.deleteDocumentTooltipShow &&
                                        <MyTooltip target={this.deleteDocumentRef}
                                                   text={"Исключить документ(ы) из пакета"}
                                                   show={this.state.deleteDocumentTooltipShow}
                                                   placement={"top"} delay={500}
                                        />
                                    }
                                    {
                                        this.props.adminLevel > AdminLevelEnum.CommonUser &&
                                        this.props.tableRole === TableTypesEnum.Additional &&
                                        <span className={`btn btn-icon btn-sm ${checkedFields.length === 0 && "invisible"}`}
                                              ref={this.deleteDocumentRef}
                                              onClick={this.deleteDocument}
                                              onMouseEnter={()=>{this.setState({deleteDocumentTooltipShow : true})}}
                                              onMouseLeave={()=>{this.setState({deleteDocumentTooltipShow : false})}}
                                        >
                                            <i className="svg-icon icon-Doc_view_8 icon-color-red"/>
                                        </span>
                                    }
                                    {
                                        this.state.updateTableTooltipShow &&
                                        <MyTooltip target={this.updateTableRef}
                                                   text={"Обновить пакет"}
                                                   show={this.state.updateTableTooltipShow}
                                                   placement={"top"} delay={500}
                                        />
                                    }
                                    <span className="btn btn-icon btn-sm ml-2" ref={this.updateTableRef}
                                          onClick={async () => await this.updateTable()}
                                          onMouseEnter={()=>{this.setState({updateTableTooltipShow : true})}}
                                          onMouseLeave={()=>{this.setState({updateTableTooltipShow : false})}}>
                                        <i className="svg-icon icon-Reload icon-color-primary"/>
                                    </span>
                                    {
                                        this.state.copyUrlTooltipShow &&
                                        <MyTooltip target={this.copyUrlRef}
                                                   text={"Скопировать ссылку"}
                                                   show={this.state.copyUrlTooltipShow}
                                                   placement={"top"} delay={500}
                                        />
                                    }
                                    <span className="btn btn-icon btn-sm ml-2" ref={this.copyUrlRef}
                                          onClick={(event) => {
                                              this.copyUrl(false);
                                              // __.copyUrl();
                                              // __.showModalCopyComplete(this.props.setModalData);
                                          }}
                                          onMouseEnter={() => {this.setState({copyUrlTooltipShow: true})}}
                                          onMouseLeave={() => {this.setState({copyUrlTooltipShow: false})}}
                                    >
                                        <i className={`svg-icon svg-icon-sm ${((window.location.href.indexOf("d=") == -1) && false) ? "disabled" : "text-primary"} icon-Doc_view_14`}/>
                                    </span>
                                    {this.lFlagCopyUrl1C &&
                                        <>
                                            {
                                                this.state.copyUrl1CTooltipShow &&
                                                <MyTooltip target={this.copyUrl1CRef}
                                                           text={"Скопировать ссылку для 1С"}
                                                           show={this.state.copyUrl1CTooltipShow}
                                                           placement={"top"} delay={500}
                                                />
                                            }
                                            <span className="btn btn-icon btn-sm ml-2" ref={this.copyUrl1CRef}
                                                  disabled={
                                                      store.getState().router?.location?.search?.indexOf("d=") == -1 && false
                                                  }
                                                  onClick={(event) => {
                                                      this.copyUrl(true)
                                                      // __.copyUrl1c(lDoc, lPack);
                                                      __.showModalCopyComplete(this.props.setModalData);
                                                  }}
                                                  onMouseEnter={() => {
                                                      this.setState({copyUrl1CTooltipShow: true})
                                                  }}
                                                  onMouseLeave={() => {
                                                      this.setState({copyUrl1CTooltipShow: false})
                                                  }}>
                                                <i className={`svg-icon svg-icon-sm ${((store.getState().router?.location?.search?.indexOf("d=") == -1) && false) ? "disabled" : "text-primary"} icon-c_16`}/>
                                            </span>
                                        </>
                                    }
                                    {
                                        this.state.fitInContainerSizeTooltipShow &&
                                        <MyTooltip target={this.fitInContainerSize}
                                                   text={"Вписать"}
                                                   show={this.state.fitInContainerSizeTooltipShow}
                                                   placement={"top"}
                                                   delay={500}
                                        />
                                    }
                                    <span className={`btn btn-icon ${!this.state.isFitInContainerSize ? 'btn-circle' : 'btn-primary'} btn-sm mx-2`} ref={this.fitInContainerSize}
                                          onClick={
                                              () => {
                                                  if (!this.state.isFitInContainerSize)
                                                      this.props.fitInContainerSize()
                                                  else
                                                      this.props.unFitInContainerSize();
                                                  this.setState({isFitInContainerSize: !this.state.isFitInContainerSize});
                                                  // this.props.fitInContainerSize
                                              }
                                          }
                                          onMouseEnter={()=>{this.setState({fitInContainerSizeTooltipShow : true})}}
                                          onMouseLeave={()=>{this.setState({fitInContainerSizeTooltipShow : false})}}
                                    >
                                        <i className={`svg-icon ${this.state.isFitInContainerSize ? '' : 'icon-color-primary'} icon-Doc_view_2`}/>
                                    </span>

                                    {
                                        this.state.exportXlsTooltipShow &&
                                        <MyTooltip target={this.exportXlsRef}
                                                   text={"Экспорт документов в Excel"}
                                                   show={this.state.exportXlsTooltipShow}
                                                   placement={"top"} delay={500}
                                        />
                                    }
                                    <span className="btn btn-icon btn-circle btn-sm mx-1" ref={this.exportXlsRef}
                                          onClick={this.exportXLS}
                                          onMouseEnter={()=>{this.setState({exportXlsTooltipShow : true})}}
                                          onMouseLeave={()=>{this.setState({exportXlsTooltipShow : false})}}>
                                        <i className="svg-icon icon-color-primary icon-Table_edit_10"/>
                                    </span>

                                    {(this.props.tableRole !== "download" && this.props.tableRole !== "main") &&
                                        this.props.adminLevel > AdminLevelEnum.CommonUser &&
                                        <>
                                            {
                                                this.state.plusPackagesTooltipShow &&
                                                <MyTooltip target={this.plusPackagesRef}
                                                           text={"Создать новый пакет в текущем"}
                                                           show={this.state.plusPackagesTooltipShow}
                                                           placement={"top"} delay={500}
                                                />
                                            }
                                                <span className="btn btn-icon btn-circle btn-sm mx-1" ref={this.plusPackagesRef}
                                                      onMouseEnter={()=>{this.setState({plusPackagesTooltipShow : true})}}
                                                      onMouseLeave={()=>{this.setState({plusPackagesTooltipShow : false})}}>
                                                    <i className="svg-icon icon-color-primary icon-Plus" onClick={() => this.PlusPackage(false, true)}/>
                                                </span>
                                            {
                                                this.state.deleteDocTooltipShow &&
                                                <MyTooltip target={this.deleteDocRef}
                                                           text={"Удалить текущий пакет"}
                                                           show={this.state.deleteDocTooltipShow}
                                                           placement={"top"} delay={500}
                                                />
                                            }
                                                <span className="btn btn-icon btn-circle btn-sm mx-1" ref={this.deleteDocRef}
                                                      onMouseEnter={()=>{this.setState({deleteDocTooltipShow : true})}}
                                                      onMouseLeave={()=>{this.setState({deleteDocTooltipShow : false})}}>
                                                    <i className="svg-icon icon-color-primary icon-Table_edit_12" onClick={()=>{this.deletePackage()}}/>
                                                </span>
                                        </>
                                    }
                                </>
                            }
                            {
                                this.props.tableRole === "main" &&
                                <Dropdown className={"mx-1 zindex-dropdown"}>
                                    <Dropdown.Toggle className="btn btn-circle btn-outline-success dropdown-toggle">
                                        <i className="svg-icon icon-Settings"/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#"  onClick={(()=>{ this.props.setUserModalShow({ isShow : true, Type : "treeDocuments" })})}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Sub"/>
                                            </span>
                                            Дерево документов
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#"  onClick={(()=>{ this.props.setUserModalShow({ isShow : true, Type : "treeDownload" })})}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Filter_data"/>
                                            </span>
                                            Дерево Пакетов Загрузки
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={this.exportXLS}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Table_edit_10"/>
                                            </span>
                                            Экспорт документов в Excel
                                        </Dropdown.Item>
                                        <DropdownItem href="#" onClick={async () => await this.updateTable()}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Reload" />
                                            </span>
                                            Обновить пакет
                                        </DropdownItem>
                                        <DropdownItem href="#" onClick={() => this.copyUrl(false)}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Doc_view_14" />
                                            </span>
                                            Скопировать ссылку
                                        </DropdownItem>
                                        {this.lFlagCopyUrl1C &&
                                            <DropdownItem href="#" onClick={() => this.copyUrl(true)}>
                                                <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                    <i className="dropdown-item svg-icon icon-color-primary icon-c_16" />
                                                </span>
                                                Скопировать ссылку для 1С
                                            </DropdownItem>
                                        }
                                        {checkedFields.length !== 0 &&
                                            <Dropdown.Item href="#" onClick={this.getDocsAsZipArchive} className="dropdown-item">
                                                <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                    <i className={`dropdown-item svg-icon icon-color-primary icon-Doc_view_4`} />
                                                    </span>
                                                Скачать отмеченные документы
                                            </Dropdown.Item>
                                        }
                                        {
                                            this.props?.adminLevel > AdminLevelEnum.CommonUser &&
                                            <>
                                                <Dropdown.Item href="#" onClick={()=>{this.PlusPackage(false)}}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-Plus"/>
                                                    </span>
                                                    Создать пакет в текущем
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={()=>{this.PlusPackage(true)}}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-Plus"/>
                                                    </span>
                                                    Создать пакет на уровне с текущим
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={this.updateNamePackage}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-name_search"/>
                                                    </span>
                                                    Переименовать текущий пакет
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={this.deletePackage}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-Table_edit_12"/>
                                                    </span>
                                                    Удалить текущий пакет
                                                </Dropdown.Item>
                                            </>
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </div>
                        :
                        <div className="card-toolbar justify-content-end" style={{flexWrap: "nowrap"}}>
                            {
                                this.state.deleteDocumentTooltipShow &&
                                <MyTooltip target={this.deleteDocumentRef}
                                           text={"Исключить документ(ы) из пакета"}
                                           show={this.state.deleteDocumentTooltipShow}
                                           placement={"top"} delay={500}
                                />
                            }
                            { isShowRight &&
                                <>
                                    {
                                        this.props.adminLevel > AdminLevelEnum.CommonUser &&
                                        this.props.tableRole !== TableTypesEnum.Download &&
                                        <span className={`btn btn-icon btn-sm ${checkedFields.length === 0 && "invisible"}`}
                                              ref={this.deleteDocumentRef}
                                              onClick={this.deleteDocument}
                                              onMouseEnter={()=>{this.setState({deleteDocumentTooltipShow : true})}}
                                              onMouseLeave={()=>{this.setState({deleteDocumentTooltipShow : false})}}
                                        >
                                            <i className="svg-icon icon-Doc_view_8 icon-color-red"/>
                                        </span>
                                    }
                                    {
                                        store.getState().globalState.settings.Content?.WithDownloadFiles == "1" &&
                                        <>
                                            {
                                                this.state.downloadTooltipShow &&
                                                <MyTooltip target={this.downloadRef}
                                                           text={"Скачать файл"}
                                                           show={this.state.downloadTooltipShow}
                                                           placement={"top"} delay={500}
                                                />
                                            }
                                            <span
                                                className={`btn btn-icon btn-sm ${checkedFields.length === 0 && "invisible"}`}
                                                ref={this.downloadRef}
                                                onClick={this.getDocsAsZipArchive}
                                                onMouseEnter={() => {
                                                    this.setState({downloadTooltipShow: true})
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({downloadTooltipShow: false})
                                                }}>
                                            <i className={`svg-icon svg-icon-sm ${checkedFields.length > 0 ? "text-primary" : "disabled"} icon-Doc_view_4`}/>
                                        </span>
                                        </>
                                    }
                            {
                                this.state.updateTableTooltipShow &&
                                <MyTooltip target={this.updateTableRef}
                                           text={"Обновить пакет"}
                                           show={this.state.updateTableTooltipShow}
                                           placement={"top"} delay={500}
                                />
                            }
                            <span className="btn btn-icon btn-sm mx-2" ref={this.updateTableRef}
                                  onClick={async () => await this.updateTable()}
                                  onMouseEnter={()=>{this.setState({updateTableTooltipShow : true})}}
                                  onMouseLeave={()=>{this.setState({updateTableTooltipShow : false})}}>
                                <i className="svg-icon icon-Reload icon-color-primary"/>
                            </span>


                            {
                                this.state.copyUrlTooltipShow &&
                                <MyTooltip target={this.copyUrlRef}
                                           text={"Скопировать ссылку"}
                                           show={this.state.copyUrlTooltipShow}
                                           placement={"top"} delay={500}
                                />
                            }
                            <span className="btn btn-icon btn-sm ml-2" ref={this.copyUrlRef}
                                  disabled={
                                      window.location.href.indexOf("d=") == -1  && false
                                  }
                                  onClick={(event) => {
                                      this.copyUrl();
                                      // __.copyUrl();
                                      // __.showModalCopyComplete(this.props.setModalData);
                                  }

                                  }
                                  onMouseEnter={() => {
                                      this.setState({copyUrlTooltipShow: true})
                                  }}
                                  onMouseLeave={() => {
                                      this.setState({copyUrlTooltipShow: false})
                                  }}
                            >
                                <i className={`svg-icon svg-icon-sm ${window.location.href.indexOf("d=") == -1  && false ? "disabled" : "text-primary"} icon-Doc_view_14`}/>
                            </span>

                            {this.lFlagCopyUrl1C &&
                                <>
                                    {
                                        this.state.copyUrl1CTooltipShow &&
                                        <MyTooltip target={this.copyUrl1CRef}
                                                   text={"Скопировать ссылку для 1С"}
                                                   show={this.state.copyUrl1CTooltipShow}
                                                   placement={"top"} delay={500}
                                        />
                                    }
                                    <span className="btn btn-icon btn-sm ml-2" ref={this.copyUrl1CRef}
                                          disabled={
                                              store.getState().router?.location?.search?.indexOf("d=") == -1  && false
                                          }
                                          onClick={(event) => {
                                              __.copyUrl1c();
                                              __.showModalCopyComplete(this.props.setModalData);
                                          }}
                                          onMouseEnter={() => {
                                              this.setState({copyUrl1CTooltipShow: true})
                                          }}
                                          onMouseLeave={() => {
                                              this.setState({copyUrl1CTooltipShow: false})
                                          }}
                                    >
                                        <i className={`svg-icon svg-icon-sm ${store.getState().router?.location?.search?.indexOf("d=") == -1  && false ? "disabled" : "text-primary"} icon-c_16`}/>
                                    </span>
                                    </>
                            }
                                </>
                            }

                            {
                                this.props.tableRole !== "main" &&
                                <>
                                    {
                                        this.state.fitInContainerSizeTooltipShow &&
                                        <MyTooltip target={this.fitInContainerSize}
                                                   text={"Вписать"}
                                                   show={this.state.fitInContainerSizeTooltipShow}
                                                   placement={"top"} delay={500}
                                        />
                                    }
                                    <span className={`btn btn-icon ${!this.state.isFitInContainerSize ? 'btn-circle' : 'btn-primary'} btn-sm mx-2`} ref={this.fitInContainerSize}
                                          onClick={
                                              () => {
                                                  if (!this.state.isFitInContainerSize)
                                                      this.props.fitInContainerSize()
                                                  else
                                                      this.props.unFitInContainerSize();
                                                  this.setState({isFitInContainerSize: !this.state.isFitInContainerSize});
                                                  // this.props.fitInContainerSize
                                              }
                                          }
                                          onMouseEnter={()=>{this.setState({fitInContainerSizeTooltipShow : true})}}
                                          onMouseLeave={()=>{this.setState({fitInContainerSizeTooltipShow : false})}}
                                    >
                                          <i className={`svg-icon ${this.state.isFitInContainerSize ? '' : 'icon-color-primary'} icon-Doc_view_2`}/>
                                    </span>

                                    {

                                        this.state.exportXlsTooltipShow &&
                                        <MyTooltip target={this.exportXlsRef}
                                                   text={"Экспорт документов в Excel"}
                                                   show={this.state.exportXlsTooltipShow}
                                                   placement={"top"} delay={500}
                                        />
                                    }
                                    <span className="btn btn-icon btn-circle btn-sm mx-1" ref={this.exportXlsRef}
                                          onClick={this.exportXLS}
                                          onMouseEnter={() => {
                                              this.setState({exportXlsTooltipShow: true})
                                          }}
                                          onMouseLeave={() => {
                                              this.setState({exportXlsTooltipShow: false})
                                          }}
                                    >
                                         <i className="svg-icon icon-color-primary icon-Table_edit_10"/>
                                    </span>
                                </>
                            }
                            {(this.props.tableRole !== "download" && this.props.tableRole !== "main") &&
                                this.props.adminLevel > AdminLevelEnum.CommonUser &&
                                <>
                                    {
                                        this.state.plusPackagesTooltipShow &&
                                        <MyTooltip target={this.plusPackagesRef}
                                                   text={"Создать новый пакет в текущем"}
                                                   show={this.state.plusPackagesTooltipShow}
                                                   placement={"top"} delay={500}
                                        />
                                    }
                                    <span className="btn btn-icon btn-circle btn-sm mx-1" ref={this.plusPackagesRef}
                                          onMouseEnter={()=>{this.setState({plusPackagesTooltipShow : true})}}
                                          onMouseLeave={()=>{this.setState({plusPackagesTooltipShow : false})}}
                                    >
                                        <i className="svg-icon icon-color-primary icon-Plus" onClick={this.PlusPackage}/>
                                    </span>
                                    {
                                        this.state.deleteDocTooltipShow &&
                                        <MyTooltip target={this.deleteDocRef}
                                                   text={"Удалить текущий пакет"}
                                                   show={this.state.deleteDocTooltipShow}
                                                   placement={"top"} delay={500}
                                        />
                                    }
                                    <span className="btn btn-icon btn-circle btn-sm mx-1" ref={this.deleteDocRef}
                                          onMouseEnter={()=>{this.setState({deleteDocTooltipShow : true})}}
                                          onMouseLeave={()=>{this.setState({deleteDocTooltipShow : false})}}
                                    >
                                        <i className="svg-icon icon-color-primary icon-Table_edit_12" onClick={()=>{this.deletePackage()}}/>
                                    </span>
                                </>
                            }
                            {
                                this.state.fitInContainerSizeTooltipShow &&
                                <MyTooltip target={this.fitInContainerSize}
                                           text={"Вписать"}
                                           show={this.state.fitInContainerSizeTooltipShow}
                                           placement={"top"} delay={500}
                                />
                            }
                            <span className={`btn btn-icon ${!this.state.isFitInContainerSize ? 'btn-circle' : 'btn-primary'} btn-sm mx-2`} ref={this.fitInContainerSize}
                                  onClick={
                                      () => {
                                          if (!this.state.isFitInContainerSize)
                                              this.props.fitInContainerSize()
                                          else
                                              this.props.unFitInContainerSize();
                                          this.setState({isFitInContainerSize: !this.state.isFitInContainerSize});
                                          // this.props.fitInContainerSize
                                      }
                                  }
                                  onMouseEnter={()=>{this.setState({fitInContainerSizeTooltipShow : true})}}
                                  onMouseLeave={()=>{this.setState({fitInContainerSizeTooltipShow : false})}}
                            >
                                <i className={`svg-icon ${this.state.isFitInContainerSize ? '' : 'icon-color-primary'} icon-Doc_view_2`}/>
                            </span>


                            {/*Дропдаун для доп таблицы */}
                            {this.props.tableRole !== "main" && !isShowRight &&
                                <Dropdown className={"mx-1"}>
                                    <Dropdown.Toggle className="btn btn-circle btn-outline-success dropdown-toggle">
                                        <i className="svg-icon icon-Settings"/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#"  onClick={(()=>{ this.props.setUserModalShow({ isShow : true, Type : "treeDocuments" })})}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Sub"/>
                                            </span>
                                            Дерево документов
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                            {
                                this.props.tableRole === "main" &&
                                <Dropdown className={"mx-1"}>
                                    <Dropdown.Toggle className="btn btn-circle btn-outline-success dropdown-toggle">
                                        <i className="svg-icon icon-Settings"/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#"  onClick={(()=>{ this.props.setUserModalShow({ isShow : true, Type : "treeDocuments" })})}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Sub"/>
                                            </span>
                                            Дерево документов
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#"  onClick={(()=>{
                                            this.loadPackages(false);
                                            this.props.setUserModalShow({ isShow : true, Type : "treePackages" })})}
                                        >
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Packet"/>
                                            </span>Дерево пакетов
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#"  onClick={(()=>{ this.props.setUserModalShow({ isShow : true, Type : "treeDownload" })})}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Filter_data"/>
                                            </span>
                                            Дерево Пакетов Загрузки
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={this.exportXLS}>
                                            <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                <i className="dropdown-item svg-icon icon-color-primary icon-Table_edit_10"/>
                                            </span>
                                            Экспорт документов в Excel
                                        </Dropdown.Item>
                                        { !isShowRight &&
                                            <>
                                                {
                                                    this.props.adminLevel > AdminLevelEnum.CommonUser &&
                                                    this.props.tableRole !== TableTypesEnum.Download &&
                                                    <DropdownItem href="#" onClick={this.deleteDocument} className={`${checkedFields.length === 0 && "d-none"}`}>
                                                        <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                            <i className="dropdown-item svg-icon icon-color-red icon-Doc_view_8" />
                                                        </span>
                                                       Исключить документ(ы) из пакета
                                                    </DropdownItem>
                                                }
                                                {checkedFields.length !== 0 &&
                                                    <Dropdown.Item href="#" onClick={this.getDocsAsZipArchive} className="dropdown-item">
                                                        <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                            <i className={`dropdown-item svg-icon icon-color-primary icon-Doc_view_4`} />
                                                        </span>
                                                        Скачать отмеченные документы
                                                    </Dropdown.Item>
                                                }
                                                <DropdownItem href="#" onClick={async () => await this.updateTable()}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-Reload" />
                                                    </span>
                                                    Обновить пакет
                                                </DropdownItem>
                                                <DropdownItem href="#" onClick={() => this.copyUrl(false)}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-Doc_view_14" />
                                                    </span>
                                                    Скопировать ссылку
                                                </DropdownItem>
                                                {this.lFlagCopyUrl1C &&
                                                    <DropdownItem href="#" onClick={() => this.copyUrl(true)}>
                                                        <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                            <i className="dropdown-item svg-icon icon-color-primary icon-c_16" />
                                                        </span>
                                                        Скопировать ссылку для 1С
                                                    </DropdownItem>
                                                }
                                            </>
                                        }
                                        {
                                            this.props?.adminLevel > AdminLevelEnum.CommonUser &&
                                            <>
                                                <Dropdown.Item href="#" onClick={()=>{this.PlusPackage(false)}}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-Plus"/>
                                                    </span>
                                                    Создать пакет в текущем
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={()=>{this.PlusPackage(true)}}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-Plus"/>
                                                    </span>
                                                    Создать пакет на уровне с текущим
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={this.updateNamePackage}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-name_search"/>
                                                    </span>
                                                    Переименовать текущий пакет
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={this.deletePackage}>
                                                    <span className="btn btn-icon btn-circle btn-sm mr-1">
                                                        <i className="dropdown-item svg-icon icon-color-primary icon-Table_edit_12"/>
                                                    </span>
                                                    Удалить текущий пакет
                                                </Dropdown.Item>
                                                <this.addDropdownItems />
                                            </>
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </div>
                }
            </div>
                <div className={"d-flex"} style={{marginLeft: "2rem", height: "3rem"}}>
                    {
                        ((this.props.tableRole === TableTypesEnum.Main && this.props.viewModeMainTable !== RootComponentsStateViewModeTypes.PackagesApp.Table_table) ||
                        (this.props.tableRole === TableTypesEnum.Additional && this.props.viewModeAdditionalTable !== RootComponentsStateViewModeTypes.PackagesApp.Table_table) ||
                        (this.props.tableRole === TableTypesEnum.Download && this.props.viewModeDownloadTable !== RootComponentsStateViewModeTypes.PackagesApp.Table_table)) &&
                        <div style={{width:"35px"}}>
                            {(this.props.adminLevel > AdminLevelEnum.CommonUser
                                || this.props.tableRole !== TableTypesEnum.Main) &&
                                <label className="checkbox checkbox-lg checkbox-inline mr-2">
                                    <input type="checkbox" checked={checked} onChange={this.checkboxOnChange}/>
                                    {/*<span className={this.props.tableRole === "main" ? "main-table-delete main-table-head-delete" : "row-checked"} />*/}
                                    <span className="row-checked" />
                                </label>
                            }
                        </div>
                    }
                    {this.getCheckedFieldsView()}
                </div>
            </>
        );
    }
}


const  mapStateToProps = state => {
    return {
        activeDocument : state.packages.tree.activeDocument,
        activeDocumentAdd : state.packages.treeAdditional.activeDocument,
        activeDocumentDownload: state.packages.treeDownload.activeDocument,

        activeNode : state.packages.tree.activeNode,
        activeNodeAdditional : state.packages.treeAdditional.activeNode,
        activeNodeDownload : state.packages.treeDownload.activeNode,

        adminLevel : state.globalState.user.AdminLevel,
        asideMenuIsClose: state.globalState.app.asideMenuIsClose,

        breadcrumbsArrowNext : state.packages.tree.breadcrumbsArrowNext,
        breadcrumbsArrowPrev : state.packages.tree.breadcrumbsArrowPrev,
        breadcrumbsArrowNextAdditional : state.packages.treeAdditional.breadcrumbsArrowNext,
        breadcrumbsArrowPrevAdditional : state.packages.treeAdditional.breadcrumbsArrowPrev,
        breadcrumbsArrowNextDownload : state.packages.treeDownload.breadcrumbsArrowNext,
        breadcrumbsArrowPrevDownload : state.packages.treeDownload.breadcrumbsArrowPrev,

        checkedFields : state.packages.mainTable.checkedFields,
        checkedFieldsMainTable : state.packages.mainTable.checkedFieldsMainTable,
        globalSettings: state.globalState.settings.Content,

        router : state.router,
        searchInputsMain: state.packages.tree.searchInputs,
        searchInputsAdditional: state.packages.treeAdditional.searchInputs,
        searchInputsDownload: state.packages.treeDownload.searchInputs,

        userModalShow : state.globalState.app.userModalShow,
        userModalTableRole : state.globalState.app.userModalTableRole,
        userModalType : state.globalState.app.userModalType,
        userSettings : state.globalState.userSettings,

        viewFilter : state.packages.tree.viewFilter,
        viewFilterAdditional : state.packages.treeAdditional.viewFilter,
        viewFilterDownload : state.packages.treeDownload.viewFilter,
        viewMode : state.packages.rootState.viewMode,
        viewModeMainTable : state.packages.rootState.viewModeMainTable,
        viewModeAdditionalTable : state.packages.rootState.viewModeAdditionalTable,
        viewModeDownloadTable : state.packages.rootState.viewModeDownloadTable,
        viewModePreview : state.packages.rootState.viewModePreview,

    }
}

const  mapDispatchToProps = {
    ActionQueue,
    breadcrumbsAdd,
    breadcrumbsMoveNext,
    breadcrumbsMovePrev,
    breadcrumbsMoveSelect,
    changePackagesTablesViewMode,
    changePackagesViewMode,
    changeViewFilter,
    checkAllRowsOnPage_packagesDocumentRight,
    checkAllRowsOnPage_MainTable,
    deleteRowFromChecked_packagesDocumentRight,
    exportExcel,
    handlerIsSearch,
    PackageAddNodesTree,
    PackagesPaginationMovePage,
    SelectActivePackageDocument,
    SelectActivePackageNode,
    setLoaderModalData,
    setModalData,
    setUserModalShow,
    setViewModePreviewPackages,
    setStateLeftAsideMenu,
    togglePackagesFolders,
    updateInputsFieldPackage,
    updateInputsFieldSortingPackage,
    updateUserSettings,

}



export default connect(mapStateToProps, mapDispatchToProps)(MainTableSearchPackage);
