import React from "react";
import {API} from "../../../tools/API_NEW/API";
import {__, fetchVersionOptions, HelpFunctions} from "../../../tools/HelpFunctions";
import {connect} from "react-redux";
import Select from "react-select";
import {setVersionIdForLocate} from "../../../store/masterApp/actionCreator/masterAppActionCreator";
import {contextMenuTypes, DataTypes, ModalTypes} from "../../../tools/StaticTypes";
import {
    addModalData,
    setContextMenuData,
    setStateLeftAsideMenu
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";

const selectStyles = {
    control: (provided, state) => ({
        ...provided,
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        backgroundColor: null,
    }),
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "5px",
            height: "5px",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
        },
        "::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            /*background-color: rgb(239 137 137 / 20%);*/
            backgroundColor: "var(--background-colorBS)",
        },
        "::-webkit-scrollbar-track": {
            borderRadius: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.35)",
        }
    }),
    placeholder: (base) => ({
        ...base,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    })
}

class Preloader extends React.Component {
    render () {
        return(
            <div className={"d-flex align-items-center justify-content-center"}
             style={{height: '2.5rem'}}
        >
            <div className="spinner-border icon-color-primary" style={{
                width: '1.25rem',
                height: '1.25rem',
            }} role="status"/>
        </div>
        )
    }
}

const getRefIconColorCN = (refIsWork, hasError) => {
    if (hasError) {
        if (refIsWork) {
            return 'icon-color-red'
        } else {
            return 'icon-color-red opacity-60'
        }
    } else if (!refIsWork) {
        return 'icon-color-grey'
    } else {
        return 'icon-color-primary'
    }
}

/*
    // TODO КН 01.02.24 Для компоненты указан React.memo() с помощью React.PureComponent!
    // TODO КН 01.02.24 param* - обязательный атрибут
    @param {string} viewMode
    @param* {any} versionIdValue
    @param(*forNotRedactorMode) {any} shortDescription - shortDescription атрибута (то что используется в качестве строки для отображения в неРедактор моде)
    @param* {boolean} isRedactorMode
    @param {boolean} hardDisabledEditing - disable редактирования при isRedactorMode
    @param(*forRedactorMode) setCurrentVersionValue(versionValue) - функция из родятеля которая сетает versionValue куда ему нужно
 */
class DocVersionRef extends React.PureComponent  {
    constructor(props) {
        super(props)

        this.state = {
            versionIdValue: this.props.versionIdValue,
            docIdValue: null,
            versionsData: null,
            versionDataIsLoading: false, // загружается docIdValue по versionIdValue
            versionOptionsIsLoading: false, // загружается информация по версиям по docIdValue,
            docDataIsLoading: false, // загружается docRefInfo по docId
            docRefInfo: null, // В редактор моде в любом случае делаем проверку док-та CheckDocumentExistOrAccess, и чтобы потом при клике на кнопку перехода к документу не запрашивать эту инфу еще раз, храним её в стейте

            inputDocIdDebounceAnchor: null,

            errorMessages: [],
        }

        this.changeDocIdInput = this.changeDocIdInput.bind(this)
        this.getVersionsDataByDocIdValue = this.getVersionsDataByDocIdValue.bind(this)
        this.updateDocVersionDataByVersionId = this.updateDocVersionDataByVersionId.bind(this)
        this.goToDocument = this.goToDocument.bind(this)
        this.onChangeSelect = this.onChangeSelect.bind(this)
        this.getDocRefInfo = this.getDocRefInfo.bind(this)
        this.onContextMenuHandler = this.onContextMenuHandler.bind(this)
        this.handleErrorMessagesField = this.handleErrorMessagesField.bind(this)
    }

    async componentDidMount() {
        if (this.props.isRedactorMode) {
            this.setState({versionIdValue: this.props.versionIdValue})
            await this.updateDocVersionDataByVersionId({
                docVersionIdValue: this.props.versionIdValue,
                needVersionData: this.props.isRedactorMode,
                needErrorModal: false,
                isRefreshByNoShortDescription: false,
            })
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        // Если редактор мод был выключен и стал включен
        if (!prevProps.isRedactorMode && this.props.isRedactorMode) {
            if (this.state.versionIdValue !== this.props.versionIdValue) {
                this.setState({versionIdValue: this.props.versionIdValue})
            }
            await this.updateDocVersionDataByVersionId({
                docVersionIdValue: this.props.versionIdValue,
                needVersionData: this.props.isRedactorMode,
                needErrorModal: this.props.isRedactorMode,
                isRefreshByNoShortDescription: false
            })
        } else if (
            prevState.inputDocIdDebounceAnchor === this.state.inputDocIdDebounceAnchor
            && this.state.versionIdValue?.toString() === prevState.versionIdValue?.toString()
            && this.props.versionIdValue?.toString() !== this.state.versionIdValue?.toString()
        ) {
            this.setState({versionIdValue: this.props.versionIdValue})
            if (this.props.isRedactorMode) {
                await this.updateDocVersionDataByVersionId({
                    docVersionIdValue: this.props.versionIdValue,
                    needVersionData: this.props.isRedactorMode,
                    needErrorModal: this.props.isRedactorMode,
                    isRefreshByNoShortDescription: false
                })
            } else {
                this.setState({docIdValue: null, versionsData: null, docRefInfo: null})
            }
        }
    }

    /*
        @param* {'add' | 'remove'} action
        @param* {any} errorId
        @param {string} errorMessage
     */
    handleErrorMessagesField(action, errorId, errorMessage = '') {
        const currentErrorMessages = this.state.errorMessages
        let newErrorMessagesValue;
        if (action === 'add') {
            const errorObj = {
                id: errorId,
                message: errorMessage,
            }
            const foundIndex = currentErrorMessages.findIndex(el=>el.id === errorId)
            if (foundIndex !== -1) {
                newErrorMessagesValue = [
                    ...currentErrorMessages.slice(0, foundIndex),
                    errorObj,
                    ...currentErrorMessages.slice(foundIndex+1),
                ]
            } else {
                newErrorMessagesValue = [...currentErrorMessages, errorObj]
            }
        } else if (action === 'remove') {
            const foundIndex = currentErrorMessages.findIndex(el=>el.id === errorId)
            if (foundIndex !== -1) {
                newErrorMessagesValue = [
                    ...currentErrorMessages.slice(0, foundIndex),
                    ...currentErrorMessages.slice(foundIndex+1),
                ]
            }
        }
        newErrorMessagesValue && this.setState({
            errorMessages: newErrorMessagesValue,
        })
    }

    async changeDocIdInput(event) {
        let docIdValue = event.target.value;
        if (docIdValue.indexOf("d=") != -1) {
            if (HelpFunctions.isValidURL(docIdValue)) {
                const url = new URL(docIdValue);
                docIdValue = url.searchParams.get("d");
            }
        }
        this.setState({
            docIdValue: docIdValue,
            versionIdValue: '',
            versionsData: null,
            docRefInfo: null,
        }, () => {
            this.props.setCurrentVersionValue && this.props.setCurrentVersionValue('');
            clearTimeout(this.state.inputDocIdDebounceAnchor)
            const timeout = setTimeout( async () => {
                const newVersionsData = await this.getVersionsDataByDocIdValue(docIdValue, this.props.isRedactorMode)
                const docRefInfo = await this.getDocRefInfo(docIdValue, this.props.isRedactorMode)
                this.setState({versionsData: newVersionsData, docRefInfo}, () => {
                    this.setState({inputDocIdDebounceAnchor: null});
                })
            }, 800)
            this.setState({inputDocIdDebounceAnchor: timeout})
        })
    }

    async getVersionsDataByDocIdValue(docIdValue, needErrorModal) {
        this.setState({versionOptionsIsLoading: true})
        let versionsData = []
        if (docIdValue && !(isNaN(docIdValue))) {
            try {
                this.handleErrorMessagesField('remove', 'versionOptionsRequest')
                versionsData = await fetchVersionOptions(Number(docIdValue))
            }
            catch(error) {
                needErrorModal && this.props.addModalData({
                    name: ModalTypes.app.info,
                    data: {
                        content: error.message,
                        disableButton: false,
                        type: "fail"
                    }
                });
                this.handleErrorMessagesField('add', 'versionOptionsRequest', error.message)
            }
        }
        this.setState({versionOptionsIsLoading: false})
        return versionsData;
    }

    // TODO КН 08.04.24 Меняя что то здесь, нужно менять и в DocRef в аналогичной ф-ии
    async getDocRefInfo(docIdValue, needErrorModal) {
        let docRefInfo = null;
        this.setState({docDataIsLoading: true})
        if (docIdValue !== null && !(isNaN(docIdValue))) {
            this.handleErrorMessagesField('remove', 'docRefInfoRequest')
            docRefInfo = await __.CheckDocumentExistOrAccess(Number(docIdValue), !needErrorModal, true)
            if (docRefInfo?.errorMessage) {
                this.handleErrorMessagesField('add', 'docRefInfoRequest', docRefInfo.errorMessage)
                docRefInfo = null;
            }
        }
        this.setState({docDataIsLoading: false})
        return docRefInfo;
    }

    async updateDocVersionDataByVersionId({docVersionIdValue, needVersionData, needErrorModal, isRefreshByNoShortDescription}) {
        let docId = null;
        this.setState({versionDataIsLoading: true, versionIdValue: docVersionIdValue})
        if (docVersionIdValue && !(isNaN(docVersionIdValue))) {
            this.handleErrorMessagesField('remove', 'getVersionDataRequest')
            const versionData = await API.documents().getVersionData({id: Number(docVersionIdValue)});
            if (!versionData?.errorCode) {
                // Если нам не пришла ошибка, но shortDescription почему то изначально не пришел (Если такое случилось, то скорее всего ошибка на сервере)
                if (isRefreshByNoShortDescription) {
                    this.handleErrorMessagesField('add', 'getVersionDataRequest', 'Неизвестная ошибка')
                } else {
                    docId = versionData.IdDoc
                }
            } else {
                needErrorModal && this.props.addModalData({
                    name: ModalTypes.app.info,
                    data: {
                        content: versionData.message.Message,
                        disableButton: false,
                        type: "fail"
                    }
                });
                this.handleErrorMessagesField('add', 'getVersionDataRequest', versionData.message.Message)
            }
        }

        this.setState({versionDataIsLoading: false,  docIdValue: docId, docRefInfo: null})

        /* TODO КН 08.04.24 Если код будет исправлен так, что при !!isRefreshByNoShortDescription docId сможет быть не null,
             то нужно будет дописать условие как в DocRef после этого запроса */
        const docRefInfo = await this.getDocRefInfo(docId, needErrorModal)
        const setStateParams = {docRefInfo}
        if (needVersionData) {
            setStateParams.versionsData = await this.getVersionsDataByDocIdValue(docId, needErrorModal);
        }
        this.setState(setStateParams);
    }

    // Для перехода нужен docVersionId {number}, И, если есть, уже готовый docRefInfo
    async goToDocument({docVersionId = null, docRefInfo = null, isRedactorMod}) {
        let confirm = true;
        if (isRedactorMod) {
            confirm = window.confirm("Возможно имеются несохраненные данные. Продолжить?")
        }
        if (confirm) {
            const viewMode = this.props?.viewMode
            const successAdditionalLogic = () => {
                if (viewMode === "card") {
                    this.props.setStateLeftAsideMenu({
                        isClose: false,
                        rootName: "DocumentApp"
                    })
                }
            }
            if (docRefInfo) {
                this.props.setVersionIdForLocate(docVersionId);
                __.goToDocumentsByDocRefInfo({
                    docRefInfo,
                    successAdditionalLogic,
                    failAdditionalLogic: () => this.props.setVersionIdForLocate(null),
                })
            } else {
                await __.goToDocumentsByDocVersionId({
                    docVersionId,
                    successAdditionalLogic,
                })
            }
        }
    }

    onChangeSelect(event) {
        const newVersionIdValue = event.value;
        if (this.state.versionIdValue?.toString() !== newVersionIdValue?.toString()) {
            this.setState({versionIdValue: newVersionIdValue}, () => {
                this.props.setCurrentVersionValue && this.props.setCurrentVersionValue(newVersionIdValue);
            });
        }
    }

    // Для перехода нужен docVersionId {number} И, если есть, уже готовый docRefInfo
    onContextMenuHandler({event, docRefInfo, docVersionId}) {
        this.props.setContextMenuData({
            name : contextMenuTypes.DocRef,
            data : {
                docVersionId: docVersionId,
                docRefInfo: docRefInfo,
            },
            position : {
                x : event.clientX,
                y : event.clientY
            }
        });
    }

    render() {
        const {docIdValue, versionIdValue, docRefInfo, errorMessages, versionDataIsLoading, versionOptionsIsLoading, docDataIsLoading} = this.state;
        const someDataIsLoading =  versionDataIsLoading || versionOptionsIsLoading || docDataIsLoading
        let iconColorCN, refIsWork;
        let error = errorMessages.length === 0 ? null : errorMessages.map(el=>el.message).join('; ')
        if (this.props.isRedactorMode) {
            refIsWork = !this.state.inputDocIdDebounceAnchor && !this.state.docDataIsLoading && versionIdValue !== null && !(isNaN(versionIdValue)) && docRefInfo;
            iconColorCN = getRefIconColorCN(refIsWork, error)
            const optionsOfSelect = this.state.versionsData;
            const valueOfSelect = versionIdValue ?
                (optionsOfSelect?.find(el => el.value === Number(versionIdValue))
                    ?? {value: !(isNaN(versionIdValue)) ? Number(versionIdValue) : '', label: versionIdValue.toString()} // TODO КН 27.01.24 fakeOption (когда стояло id версии, который не существует)
                ) : null;
            return (
                <div className="d-flex align-items-center">
                    {this.state.docDataIsLoading ?
                        <span className={`svg-icon svg-icon-lg`}>
                            <Preloader/>
                        </span>
                        : <span className={`d-flex svg-icon svg-icon-lg ${(refIsWork) ? 'cursor-pointer' : ''}`}
                                onClick={() => refIsWork && this.goToDocument({
                                    docVersionId: Number(versionIdValue),
                                    docRefInfo: docRefInfo,
                                    isRedactorMod: true,
                                })}
                                title={'Перейти в реестр'}
                        >
                            <i title={error} className={`svg-icon icon-left_window ${iconColorCN}`}/>
                        </span>
                    }
                    <div className="ml-3 position-relative" style={{width: '30%'}}>
                        <input className="form-control"
                               type={__.inputTypeByDataTypeId(DataTypes.DocRef)}
                               value={this.state.versionDataIsLoading ? '' : (docIdValue || '')}
                               onChange={this.changeDocIdInput}
                               disabled={this.props.hardDisabledEditing || someDataIsLoading}
                        />
                        {this.state.versionDataIsLoading && <div className="position-absolute-center"><Preloader/></div>}
                    </div>
                    <div className="ml-3" style={{width: '100%'}} title={"Выберите версию документа"}>
                        <Select
                            options={optionsOfSelect}
                            isDisabled={this.props.hardDisabledEditing || this.state.inputDocIdDebounceAnchor || !optionsOfSelect || (someDataIsLoading ? true : (optionsOfSelect.length < 1))}
                            placeholder={"Выберите версию документа"}
                            onChange={this.onChangeSelect}
                            value={valueOfSelect}
                            isLoading={this.state.versionOptionsIsLoading}
                            styles={selectStyles}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    text: 'var(--hover-primary)',
                                    primary25: 'var(--light-primary)',
                                    primary50: 'var(--hover-primary)',
                                    primary: 'var(--originaltwo)',
                                },
                            })}
                            loadingMessage={() => "Загрузка..."}
                        />
                    </div>
                </div>
            )
        } else {
            if (this.state.versionDataIsLoading || this.state.versionOptionsIsLoading || this.state.docDataIsLoading) {
                return <Preloader/>
            }
            refIsWork = versionIdValue !== null && !(isNaN(versionIdValue));
            // Если error нет, но нет shortDescription - отобразить ошибку
            if (!error) {
                error = (refIsWork && !this.props.shortDescription) ? 'noShortDescriptionError' : null
            }
            iconColorCN = getRefIconColorCN(refIsWork, error)
            const renderString = this.props.shortDescription || versionIdValue || ''
            return (
                <div className={'d-flex align-items-center justify-content-center flex-grow-1'}>
                    <div className={`${refIsWork ? 'cursor-pointer ' : ''}d-flex align-items-center justify-content-center`}
                         style={{
                             flexGrow: '1',
                             backgroundColor: 'var(--light)',
                             borderRadius: '0.42rem', padding: '0 1rem', borderWidth: 0, height: '2.5rem'
                         }}
                         title={renderString}
                         onClick={()=> refIsWork && this.goToDocument({
                             docVersionId: Number(versionIdValue),
                             // TODO КН 08.04.24 Думаю может быть такое, что docRefInfo лежит в стейте (напр. после выхода из редактор мода, если получится проаапдейтить эту компоненту, а не удалить и создать новую)
                             docRefInfo: docRefInfo,
                             isRedactorMod: false,
                         })}
                         onContextMenu={(event) => {
                             event.preventDefault();
                             event.stopPropagation();
                             refIsWork && this.onContextMenuHandler({
                                 event,
                                 docVersionId: Number(versionIdValue),
                                 // TODO КН 08.04.24 Думаю может быть такое, что docRefInfo лежит в стейте (напр. после выхода из редактор мода, если получится проаапдейтить эту компоненту, а не удалить и создать новую)
                                 docRefInfo: docRefInfo,
                             })
                         }}
                    >
                        <i title={error === 'noShortDescriptionError' ? null : error}
                           className={`icon-Documents ${iconColorCN}`}
                           style={{fontSize: '1.5rem'}}
                        />
                        {renderString &&
                            <span className="ml-3 overflow-hidden text-overflow-ellipsis" style={{
                                maxWidth: '15rem',
                                textWrap: 'nowrap',
                            }}>
                                {renderString}
                            </span>
                        }
                    </div>
                    {error &&
                        <i title={'Повторить запрос'} onClick={() => {
                            this.updateDocVersionDataByVersionId({
                                docVersionIdValue:this.props.versionIdValue,
                                needVersionData: false,
                                needErrorModal: false,
                                isRefreshByNoShortDescription: error === 'noShortDescriptionError'
                            })
                        }} className={`icon-refresh_16 icon-color-primary ml-3 cursor-pointer`} style={{fontSize: '1.5rem'}}/>
                    }
                </div>
            )
        }
    }
}

const mapDispatchToProps = {
    setVersionIdForLocate,
    setStateLeftAsideMenu,
    setContextMenuData,
    addModalData,
}

export default connect(null, mapDispatchToProps)(DocVersionRef)
