import {store} from "../../../index";
import {EntityFilters, Filter, FiltersType, Sorting} from "../../../tools/Filters";
import {apiRequest} from "../../../tools/API/apiRequest";
import {apiUrl} from "../../../tools/API/apiUrl";
import {PACKAGES_TREE_ACTIONS} from "../packageActionsList";
import {AUTH_ACTIONS} from "../../globalState/globalStateActionsList";
import {__} from "../../../tools/HelpFunctions";
import {
    breadcrumbsAdd, GetPackageTypes,
    PackageAddNodesTree,
    SelectActivePackageNode,
    togglePackagesFolders
} from "./package_TreeActionCreator";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import {TreeCombinePackage} from "../../../tools/TreeCombinePackage";
import {TableTypesEnum} from "../../../tools/StaticTypes";

/*
 @param isDeployable - "Разворачиваемо?";  могут ли элементы раскрываться в список подэлементов
 */
export const treeSearchPackage = ({   TableType = TableTypesEnum.Main,
                                      IdPackage= 2,
                                      Input,
                                      Id = null,
                                      IsFilters= false,
                                      IsSearch = false,
                                      isDeployable = false,
                                      MasterSearchId = null,
                                      IsClear=false,
                                      IsDownload = false,
                                      Next = null,
                                      Error = null}) => {
    return async dispatch => {
        try {
            if(IsClear) {
                dispatch({type:PACKAGES_TREE_ACTIONS.GET_GROUP_SEARCH, payload : {
                        tableType: TableType,
                        folders: [],
                        totalRecordsCount : 0,
                        IsDownload : IsDownload,
                        tree: null,
                        activeNode: null
                    }})
                return
            }

            if(IsDownload || TableType === TableTypesEnum.Download) {
                IdPackage = 1;
            }

            const Filial = store.getState().globalState.filial.Active;

            let filter = new Filter(FiltersType.ENTITY)

            if (MasterSearchId) {
                let entity = new EntityFilters().setEntityName("DocPackageExt")
                entity.add({Name: "IdFilial", Value: MasterSearchId})//Filial.Id

                //Пустой символ
                entity.add({Name: "Name", Value: Input ? Input : "", Operator: 2})
                filter.add(entity.get())
            } else if(Id) {
                filter.add(
                    new EntityFilters().setEntityName("DocPackageExt")
                        .add({Name: "IdFilial", Value: Filial.Id})
                        .add({Name: "IdParent", Value: Id,})
                        .get()
                )
            } else {
                filter.add(
                    new EntityFilters().setEntityName("DocPackageExt")
                        .add({Name: "IdFilial", Value: Filial.Id})
                        .add({Name: "Name", Value: Input, Operator: 2})
                        .get()
                )
            }

            filter.addAttribute({Name:"IdPackage",Value:IdPackage})
            filter.addAttribute({Name: "PagingFilter", Value: {PageSize : 25, PageOffset : 0}})

            let sorting = new Sorting();
            sorting.add({PropertyName: "ModifyDate", Direction: 2})
            sorting.add({PropertyName: "Id", Direction: 2})

            filter = filter.get();
            sorting = sorting.get();
            let request = await new apiRequest(apiUrl.PACKAGE.PAC_TREE, {
                filter,
                sorting
            });

            await request.execute(function (data) {
                if (IsFilters) {
                    // TODO КН 12.01.24 Возможно здесь для чистоты стоит тоже добавить if (isDeployable), и прокидывать его как True в эту функцию в случае когда IsFilters === true, раз создал поле isDeployable
                    // TODO КН 12.01.24 Cто процентов тут есть баги из-за отсутсвия зависимости от TableType
                    // TODO КН 08.05.24 А он сюда никогда не заходит вообще. Если эта туду еще висит, проверьте еще раз что не исопльзуется и сотрите это условие, не стер т.к. пока не приняли мерж
                    let activeNode = __.deepCopy(store.getState().packages.tree.activeNode);
                    let newChildren = [];
                    for (let i = 0; i < data.Records.length; i++) {
                        let TreeId = createUniqueIdString(6);
                        let Type = "PackagesFolder";
                        let Children = [];
                        newChildren.push(
                            {
                                TreeId: TreeId,
                                Node: data.Records[i],
                                Type: Type,
                                Children: Children
                            }
                        )
                    }
                    activeNode.Children = newChildren
                    dispatch({
                        type: PACKAGES_TREE_ACTIONS.SEARCH_NODE, payload: {
                            searchNode: activeNode,
                            tableType: TableType
                        }
                    })
                } else {
                    dispatch({
                        type: PACKAGES_TREE_ACTIONS.GET_GROUP_SEARCH, payload: {
                            tableType: TableType,
                            folders: data.Records,
                            totalRecordsCount: data.TotalRecordsCount,
                            IsDownload: IsDownload,
                            Input: Input
                        }
                    });

                    if (isDeployable) {
                        if (TableType === TableTypesEnum.Main || TableType === TableTypesEnum.Modal || TableType === TableTypesEnum.Additional) {
                            if (Input) {
                                let tree = new TreeCombinePackage(false, TableType, IsDownload, IsSearch).create(IsDownload).get();
                                dispatch({
                                    type: PACKAGES_TREE_ACTIONS.CREATE_SEARCH_TREE,
                                    payload: {tree: tree, tableType: TableType}
                                })
                            } else {
                                let tree = new TreeCombinePackage(true, TableType, IsDownload, IsSearch).update(Id).get();

                                let openedFolders;
                                switch (TableType) {
                                    case TableTypesEnum.Main:
                                        openedFolders = store.getState().packages.treeSearch.openedFolders;
                                        break;
                                    case TableTypesEnum.Modal:
                                        openedFolders = store.getState().packages.treeSearchModal.openedFolders;
                                        break;
                                    case TableTypesEnum.Additional:
                                    default:
                                        openedFolders = store.getState().packages.treeSearchAdditional.openedFolders;
                                        break;
                                }

                                if (openedFolders.includes(Id)) {
                                    openedFolders = openedFolders.filter(item => item !== Id);
                                } else {
                                    openedFolders = [...openedFolders, Id];
                                }

                                dispatch({
                                    type: PACKAGES_TREE_ACTIONS.CREATE_SEARCH_TREE, payload: {
                                        tree: tree,
                                        openedFolders: openedFolders,
                                        tableType: TableType,
                                    }
                                })
                            }
                        }
                    }
                    if (TableType === TableTypesEnum.Download) {
                        let tree = new TreeCombinePackage(false, TableType, IsDownload, true).create(IsDownload).get();

                        dispatch({
                            type: PACKAGES_TREE_ACTIONS.CREATE_SEARCH_TREE,
                            payload: {tree: tree, tableType: TableType}
                        })

                        // TODO КН 11.01.24 такого type вообще нет
                        // dispatch({
                        //     type: PACKAGES_TABLE_ACTIONS.SEARCH_DOWNLOAD, payload: {
                        //         tableType: TableType,
                        //         tree: data.Records
                        //     }
                        // })
                    }
                }
                if (Next) {
                    Next.action(Next.params);
                } // следующее действие если есть цепочка
            }, function (data) {
                if (data.status === 401) {
                    dispatch({type: AUTH_ACTIONS.IS_AUTH, payload: false});
                }
                console.log("Ошибка получения дерева группы документов (post post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const handlerSearchPackagesPath = ({TableType = "main",Id,Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let request = new apiRequest(apiUrl.PACKAGE.GET_PACKAGE_PATH, {
                Id
            });

            request.execute(function(data) {

                dispatch({type : PACKAGES_TREE_ACTIONS.PATH_TO_NODE, payload : {
                        Nodes : data,
                        tableType : TableType
                    }});
                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения дерева группы документов (post post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

//TODO переименовать ---- поле с фильтром в боковом меню
export const setTreeSearchQuery = ({Text = "", TableType = null, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            if (TableType) {
                const oldSearches = [...store.getState().packages.treeSearchQuery];
                const newSearches = oldSearches.filter(oldSearch => oldSearch.TableType !== TableType)
                newSearches.push({TableType, Search: Text})
                dispatch({type : PACKAGES_TREE_ACTIONS.SET_TREE_SEARCH_QUERY, payload : newSearches});
            }
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}



export const handlerTreePackage = ({TableType = "main", IsDownload = false, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let newChildren= __.deepCopy(store.getState().packages.temporaryData);
            newChildren.splice(0,1);
            for (let i = 0; i < newChildren.length; i++) {
                await dispatch(PackageAddNodesTree({Id : newChildren[i].Id, TableType, IsDownload}));
                await dispatch(togglePackagesFolders({IdFolder: newChildren[i].Id, TableType, IsDownload}));
                // TODO КН 23.04.24 Вырезал ф-ию GetPackageTypes из PackageAddNodesTree. Пока сделал так
                if (i === newChildren.length - 1) {
                    await store.dispatch(GetPackageTypes({
                        idPackage: newChildren[i].Id, TableType, IsDownload
                    }));
                }
            }
            await dispatch(SelectActivePackageNode({Id : newChildren.at(-1).Id, TableType, IsDownload}));
            await dispatch(breadcrumbsAdd({NodeId : newChildren.at(-1).Id, Name : newChildren.at(-1).Name, TableType}));
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка

        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}
