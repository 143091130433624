import React  from 'react';
import CreatableSelect from 'react-select/creatable';
import {__} from "../../../tools/HelpFunctions";
import {DataTypes} from "../../../tools/StaticTypes";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import "../../Reports/datePicker.css"

export class MySelectNew extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            List : [],
            Input : '',
            isVisibleDateHandler: false,
            updatedByThisComponent: false,
        };

        this.dataList = this.dataList.bind(this);
        this.dataListInputChange = this.dataListInputChange.bind(this);
        this.updateByProps = this.updateByProps.bind(this);
        this.dataListKeyDown = this.dataListKeyDown.bind(this);
        this.createOption = this.createOption.bind(this);
    }

    componentDidMount() {
        this.updateByProps()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.dataList !== this.props.dataList) {
            if (this.state.updatedByThisComponent) {
                this.setState({updatedByThisComponent: false})
            } else {
                this.updateByProps()
            }
        }
    }

    updateByProps() {
        const dataListObj = this.props.dataList?.map((item) => this.createOption(__.deepCopy(item))) ?? []
        this.setState({
            Input: '',
            List: Array.from(new Set(dataListObj))
        });
    }


    createOption = (label) => ({
        label,
        value: label,
    });

    dataList(list, actionMeta) {
        this.setState({
            List : list
        });
        let returnedValue = [];
        if(list?.length) {
            for (let i = 0; i < list.length; i++) {
                returnedValue.push(list[i].value);
            }
        }
        this.setState({updatedByThisComponent: true})
        this.props.returnFunc(returnedValue);
        if (this.props.type === DataTypes.DataTime) {
            if (actionMeta.action === 'remove-value' || actionMeta.action === 'clear')
                queueMicrotask(() => {
                    this.setState({isVisibleDateHandler: false})
                })
        }
    }

    dataListInputChange(inputValue) {
        if (this.props.updateInputValue)
            inputValue = this.props.updateInputValue(inputValue);
        if (inputValue.trim() !== '') {
            this.setState({Input: inputValue});
        }
        if(this.props.setSinglePackName) this.props.setSinglePackName(inputValue)
    }

    dataListKeyDown(event) {
        switch (this.props.type) {
            case DataTypes.Integer:
                // const isNumber = /^[+-]?[0-9]{1,9}(?:\.[0-9]{1,9})?$/i.test(__.deepCopyJSON(this.state.Input)+''+event.key)
                const isInteger = /^[+-]?[0-9]$/i.test(event.key)
                if (!isInteger && event.key !== "Backspace" && !(__.deepCopyJSON(this.state.Input)==='' && event.key==='-'))
                    event.preventDefault();
                break;
            case DataTypes.Float:
            //
            // ^[+-]?[0-9]{1,9}(?:\.[0-9]{1,2})?$
            //     Это регулярное выражение гласит
            //
            //     знак необязателен
            //     не менее одной и не более 9 цифр в качестве целочисленной части
            //     если есть десятичная точка, то по крайней мере одна и максимум две цифры после нее.

                const isFloat = /^[+-]?[0-9]{1,9}(?:\.[0-9]{1,9})?$/i.test(__.deepCopyJSON(this.state.Input)+''+event.key)
                // const isNumber = /^[0-9]$/i.test(event.key)
                if (!isFloat && event.key !== "Backspace" && !(__.deepCopyJSON(this.state.Input)==='' && event.key==='-') && !(__.deepCopyJSON(this.state.Input).indexOf('.')===-1 && event.key==='.'))
                  event.preventDefault();
                break;


        }

        const createOption = (label) => ({
            label,
            value: label,
        });

        const inputValue = __.deepCopyJSON(this.state.Input);
        let value = __.deepCopyJSON(this.state.List);

        if(!value) value = [];

        if (!inputValue) return;

        if(event.key === "Enter" || event.key === "Tab") {
            let newValue = [];
            let inputValueIsDuplicate = false
            for (let i = 0; i < value.length; i++) {
                newValue.push(JSON.stringify(value[i]));
            }
            const newOptionAsJSON = JSON.stringify(createOption(inputValue))
            if (newValue.some(el => el === newOptionAsJSON)) {
                inputValueIsDuplicate = true
            } else {
                newValue.push(newOptionAsJSON);
            }
            newValue = Array.from(new Set(newValue));
            for (let i = 0; i < newValue.length; i++) {
                newValue[i] = JSON.parse(newValue[i]);
            }

            this.setState({
                Input: '',
                List: newValue,
            }, ()=>{
                let returnedValue = [];
                for (let i = 0; i < newValue.length; i++) {
                    returnedValue.push(newValue[i].value);
                }
                this.setState({updatedByThisComponent: true})
                this.props.returnFunc(returnedValue);
            });

            event.preventDefault();

            if (inputValueIsDuplicate) {
                this.props.duplicateCallback && this.props.duplicateCallback()
            }
        }

    };

    render() {
        return (
            <>
                {!this.state.isVisibleDateHandler ?
                    <CreatableSelect
                        components={{DropdownIndicator: null}}
                        className={this.props.className ?? "col-lg-12"}
                        inputValue={this.state.Input}
                        isClearable
                        isMulti={this.props.isMulti}
                        menuIsOpen={false}
                        onChange={this.dataList}
                        onInputChange={this.dataListInputChange}
                        onKeyDown={this.dataListKeyDown}
                        placeholder={this.props.placeholder}
                        value={this.state.List}
                        styles={this.props.styles}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: "0.42rem",
                            colors: {
                                ...theme.colors,
                                primary: 'var(--originaltwo)',
                            }
                        })}
                        onFocus={async () => {
                            if (this.props.type === DataTypes.DataTime)
                                this.setState({isVisibleDateHandler: true})
                        }}
                        isDisabled={this.props.hardDisabledEditing}
                    /> :
                    <DatePicker locale={ru}
                                open
                                selected={null}
                                onChange={(date) => {
                                    let copyOfOptions = __.deepCopyJSON(this.state.List);

                                    // TODO 13.02.24 КН Получение массива типа ['02.06.2024', '00:00:00']
                                    const dateAsArray = date?.toLocaleString().split(', ')
                                    const newOption = this.createOption(dateAsArray[0]);

                                    const newValueIsDuplicate = copyOfOptions.some(el => el.value === newOption.value)
                                    if (!newValueIsDuplicate) {
                                        copyOfOptions.push(newOption);
                                    }
                                    this.setState({
                                        List: copyOfOptions,
                                    })
                                    this.setState({updatedByThisComponent: true})
                                    this.props.returnFunc(copyOfOptions.map(el=>el.value));

                                    this.setState({isVisibleDateHandler: false})
                                    if (newValueIsDuplicate) {
                                        this.props.duplicateCallback && this.props.duplicateCallback()
                                    }
                                }}
                    />
                }
            </>

        );
    }
}
