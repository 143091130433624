import React from "react";
import {connect} from "react-redux";
import {ModalTypes} from "../../../tools/StaticTypes";
import {deleteModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {__, HelpFunctions, validateAttributeJSONByTable, validateAttributeJSONByTree} from "../../../tools/HelpFunctions";
import AceEditor from "react-ace";


// modal.data.content => обязательно
class ShowJsonObjectModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contentOnHover: false,
            jsonString: this.props.modal.data.content && this.props.modal.data.content !== '-'
                ? JSON.stringify(JSON.parse(HelpFunctions.isJSON(this.props.modal.data.content)), null, 2)
                : null,
            shortDescription: this.props.modal.data.content && this.props.modal.data.content !== '-'
                && (JSON.parse(HelpFunctions.isJSON(this.props.modal.data.content))?.shortDescription ?? ''),
            isJsonRedactorError: false,
            jsonRedactorErrorMessage: "",
        };

        this.modalName = ModalTypes.app.show;

        this.hide = this.hide.bind(this);
        this.hideOutSide = this.hideOutSide.bind(this);
        this.save = this.save.bind(this);
        this.validateJSONByTable = this.validateJSONByTable.bind(this);
        this.validateJSONByTree = this.validateJSONByTree.bind(this);
    }

    // скрывать по кнопке
    hide() {
        this.props.deleteModalData({modalId: this.props.modal.id})
    }

    hideOutSide() { // скрывать при клике вне окна
        if (!this.state.contentOnHover) this.hide();
    }

    validateJSONByTable(jsonObject, callback) {
        const validResult = validateAttributeJSONByTable(jsonObject);

        this.setState({
            isJsonRedactorError: !validResult.valid,
            jsonRedactorErrorMessage: "Неверная структура JSON-объекта"
        }, () => {
            callback();
        });
    }
    validateJSONByTree(jsonObject, callback) {
        const validResult = validateAttributeJSONByTree(jsonObject);

        this.setState({
            isJsonRedactorError: !validResult.valid,
            jsonRedactorErrorMessage: "Неверная структура JSON-объекта"
        }, () => {
            callback();
        });
    }

    save() {
        let saveValue;

        if (!HelpFunctions.isJSON(this.state.jsonString)) {
            return;
        }

        saveValue = this.state.jsonString;
        if (this.state.shortDescription) {
            saveValue = JSON.parse(saveValue)
            saveValue.shortDescription = this.state.shortDescription
            saveValue = JSON.stringify(saveValue)
        }

        const isTableObject = JSON.parse(saveValue)?.type === 'table';
        const isTreeObject = JSON.parse(saveValue)?.type === 'tree';
        if(isTableObject) {
            this.validateJSONByTable(saveValue, () => {
                if(!this.state.isJsonRedactorError) {
                    if (this.props.modal.data.callback) this.props.modal.data.callback(saveValue);
                    this.hide();
                }
            });
        } else if (isTreeObject) {
            this.validateJSONByTree(saveValue, () => {
                if(!this.state.isJsonRedactorError) {
                    if (this.props.modal.data.callback) this.props.modal.data.callback(saveValue);
                    this.hide();
                }
            });
        }else {
            if (this.props.modal.data.callback) this.props.modal.data.callback(saveValue);
            this.hide();
        }
    }

    render() {
        const redactorMode = this.props.modal.data.redactorMode;
        let modalStyle = {
            display: "block",
            backgroundColor: "#061c3e66",
        };

        const shortDescription = this.state.shortDescription
                ? this.state.shortDescription
                : 'Описание отсутствует'

        return (
            <>
                <div className="modal fade show modal-custom" style={modalStyle} onClick={this.hideOutSide}>
                    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-l"
                         style={{minWidth: '500px', width: 'fit-content'}}
                    >
                        <div className="modal-content  modal-content-json"
                             onMouseEnter={() => this.setState({contentOnHover: true})}
                             onMouseLeave={() => this.setState({contentOnHover: false})}
                             onClick={(e) => e.stopPropagation()}
                        >
                            <div className="modal-header p-4 border-bottom-0">
                                <h5 className="modal-title text-success font-weight-bolder ">
                                    Описание JSON-объекта
                                </h5>
                            </div>

                            <div className={'d-flex flex-column justify-content-center my-3 mx-7'}
                                 style={{gap: '1rem', marginBottom: '1rem'}}
                            >
                                <span className={'font-weight-bolder'}>Краткое описание объекта:</span>
                                {redactorMode
                                    ?
                                    <input className={`form-control json-modal-input d-inline w-auto`}
                                           value={this.state.shortDescription}
                                           onChange={(e) => this.setState({shortDescription: e.target.value})}
                                    />
                                    :
                                    <span>{shortDescription}</span>
                                }
                            </div>
                            <div className={`modal-body beautiful-scroll-10 modal-body-json`}>
                                     <div>
                                         <div className={'mb-4 font-weight-bolder'}>Содержимое объекта:</div>
                                         {
                                             this.state.isJsonRedactorError &&
                                             <label>
                                                    <span
                                                        className="label label-inline label-light-danger font-weight-bolder mb-2">
                                                        {this.state.jsonRedactorErrorMessage}
                                                    </span>
                                             </label>

                                         }
                                         <AceEditor
                                             className={'beautiful-scroll-10'}
                                             mode="json"
                                             theme={'xcode'}
                                             name="content"
                                             readOnly={!this.props.modal.data.redactorMode}
                                             value={this.state.jsonString}
                                             onChange={(newValue) => this.setState({jsonString: newValue})}
                                             editorProps={{$blockScrolling: true}}
                                             width={"100%"}
                                             height={"250px"}
                                             setOptions={{
                                                 enableBasicAutocompletion: true,
                                                 enableLiveAutocompletion: true,
                                             }}
                                             style={{
                                                 boxShadow: "0px 0px 5px 1px grey"
                                             }}
                                         />
                                     </div>
                            </div>
                            <div className="modal-footer align-content-center text-center justify-content-center">
                                <button type="button"
                                        className="btn btn-lg btn-light-success"
                                        onClick={this.hide}
                                >
                                    Закрыть
                                </button>
                                {this.props.modal.data.redactorMode &&
                                    <button type="button"
                                            className="btn btn-lg btn-primary"
                                            onClick={this.save}
                                    >
                                        Принять изменения
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


const mapStateToProps = state => {
    return {
        router: state.router,
    }
}

const mapDispatchToProps = {
    deleteModalData,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowJsonObjectModal);
