import React from "react";
import {connect} from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {API} from "../../../tools/API_NEW/API";
import {DocumentVersion} from "../../../tools/classes/documents/DocumentVersion";
import {setLoaderModalData, setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {
    breadcrumbsAdd, breadcrumbsMoveSelect,
    PackageAddNodesTree,
    SelectActivePackageDocument, SelectActivePackageNode, togglePackagesFolders
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {ModalTypes} from "../../../tools/StaticTypes";
import {store} from "../../../index";
const animatedComponents = makeAnimated();

const styles = {
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "5px",
            backgroundColor: "rgba(255, 255, 155, 0.3)"
        },
        "::-webkit-scrollbar-track": {
            borderRadius: "10px",
            backgroundColor: "var(--light-primary)"
        },
        "::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            backgroundColor: "var(--background-colorBS)"
        },
    }),
    control: (base) => ({
        ...base,
        marginLeft: "15px",
        width: "300px"
    }),
    menu: (base) => ({
        ...base,
        marginLeft: "15px",
        width: "300px"
    })
}

const isVersionEnum = {
    isCurrent: "isCurrentVersion",
    isChange: "isChangeVersion",
}

class ChangeVersionDocInPackage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVersion: isVersionEnum.isCurrent,
            versionOptions: [],
            selectedVersion: null,
            activeDocument: null
        };

        this.hide = this.hide.bind(this);
        this.changeVersion = this.changeVersion.bind(this);
        this.changeIsCurrentVersion = this.changeIsCurrentVersion.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.setActiveDocument = this.setActiveDocument.bind(this);
        this.refreshTable = this.refreshTable.bind(this);
    }

    async componentDidMount() {
        await this.setActiveDocument();

        const {activeDocument: { additionalSections: { versionSection: { versions: { Records } } }}} = this.state;
        Records.forEach(version => {
            const date = new Date(version.VersionTime).toLocaleString();
            this.state.versionOptions.push({
                value :  version.Version,
                label :  `${version.Version} от ${date}`,
                docVersion: new DocumentVersion(version),
                isDisabled : false
            })
        });

        this.setState({
            selectedVersion: this.state.versionOptions.find(item => item.value === this.state.activeDocument?.Node.Version.Version),
            isVersion: this.state.activeDocument?.Node.IsCurrentVersion ? isVersionEnum.isCurrent : isVersionEnum.isChange,
        });
    }

    async setActiveDocument() {
        const { modal} = this.props;
        let activeDoc;
        if(modal.data.TableType === "main") { activeDoc = this.props.activeDocument }
        else if(modal.data.TableType === "additional") { activeDoc = this.props.activeDocumentAdditional }
        else if(modal.data.TableType === "download") { activeDoc = this.props.activeDocumentDownload }
        this.setState({ activeDocument: activeDoc});
    }

    // скрывать модалку
    hide() {

        this.props.setModalData({});
    }

    changeVersion(item) {
        this.setState({selectedVersion: item})
    }

    changeIsCurrentVersion(event) {
        this.setState({isVersion: event.target.value});
    };

    async refreshTable({activeDocument = null, data, tableType}) {
        const activeNode = tableType === "main"
            ? this.props.activeNode
            : this.props.activeNodeAdditional

        await this.props.PackageAddNodesTree({
            Id : activeNode.Node.Id,
            TableType : tableType,
            IsDownload : data.IsDownload,
        });
        await this.props.SelectActivePackageNode({
            Id : activeNode.Node.Id,
            TableType : tableType,
            IsDownload : data.IsDownload,
        });
        await this.props.togglePackagesFolders({
            IdFolder : activeNode.Node.Id,
            TableType : tableType,
            IsDownload : data.IsDownload,
        });

        await this.props.breadcrumbsAdd({
            NodeId : activeNode.Node.Id,
            Name : activeNode.Node.Name,
            ParentId : activeNode.Node.IdParent,
            TableType : tableType,
            IsDownload : data.IsDownload,
        });
        await this.props.breadcrumbsMoveSelect({
            NodeId: activeNode.Node.Id,
            TableType : tableType,
            IsDownload : data.IsDownload,
        });
        if (activeDocument)
            await this.props.SelectActivePackageDocument({
                Id: activeDocument.Node.Id,
                TableType : tableType,
                IsDownload : data.IsDownload,
            });
    }
    async saveChanges() {
        try {
            // this.props.setModalData({
            //     name : ModalTypes.app.alert,
            //     data : {content : "Загрузка...", disableButton : true, gif : "packages"}
            // });
            //Новый прелоадер
            store.dispatch(setLoaderModalData({
                data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "packages", key : "ChangeVersionDocInPackage117"}
            }));
            const { modal: { data } } = this.props;
            const {selectedVersion : { docVersion }, isVersion, activeDocument} = this.state;
            const activeNode = data.TableType === "main"
                ? this.props.activeNode
                : this.props.activeNodeAdditional

            let entity = {
                DocVersion: docVersion,
                IdPackage: activeNode.Node.Id,
                IdDoc: activeDocument.Node.Id,
                Id: activeDocument.Node.IdPackageDocRef
            };

            if(isVersion === isVersionEnum.isCurrent) {
                delete entity.DocVersion;
            }

            await API.packages().updatePackageDocRef({ entity: entity });

            await this.refreshTable({activeDocument, data, tableType : data.TableType})
            if (this.props.viewMode.includes("two_table")) {
                if (data.TableType === "additional") await this.refreshTable({data, tableType : "main"})
                else await this.refreshTable({data, tableType : "additional"})
            }

            //Новый прелоадер
            store.dispatch(setLoaderModalData({
                keyDeleted: "ChangeVersionDocInPackage169"
            }));
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type : "success", content : `Версия документа в пакете успешно изменена!`}
            });

        } catch (e) {
            //Новый прелоадер
            store.dispatch(setLoaderModalData({
                keyDeleted: "ChangeVersionDocInPackage178"
            }));
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type : "fail", content : `Ошибка изменения версии документа в пакете!`}
            });

            throw new Error(e);
        }
    }


    render() {
        const modalStyle = {
            display : "block",
            backgroundColor: "#061c3e66",
        };

        const radioBtnStyle = {
            accentColor: "var(--success)",
            margin: 10,
            marginLeft: 20
        }

        const { activeNode } = this.props;
        const { isVersion, selectedVersion, activeDocument } = this.state;

        return (
            <div className="modal fade show" style={modalStyle} onClick={this.hide}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                    <div className="modal-content adaptive-ChangeVersionDocInPackage" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header" style={{padding: "1rem 1rem", borderBottom: "none"}}>
                            <h5 className="modal-title text-success font-weight-bolder ">
                                Изменение версии документа в пакете
                            </h5>
                        </div>
                        <div className="modal-body " style={{padding:" 0.8rem 1.75rem"}}>
                            <span className={"label label-inline label-light-info font-weight-bolder mb-5 w-100"} style={{color:"var(--text-primary)", letterSpacing: "0.05em"}}>
                                Версия документа "{activeDocument?.Node.Name}" в пакете "{activeNode?.Node.Name}"
                            </span>


                            <div className="row align-items-center">
                                <input style={radioBtnStyle}
                                    id={isVersionEnum.isCurrent}
                                    type="radio"
                                    name="version"
                                    value={isVersionEnum.isCurrent}
                                    onChange={(event) => {this.changeIsCurrentVersion(event)}}
                                    checked={isVersion === isVersionEnum.isCurrent}/>
                                <label className={'mb-0'} htmlFor={isVersionEnum.isCurrent}>Всегда текущая</label>
                            </div>
                            <div className="row align-items-center">
                                <input style={radioBtnStyle}
                                       id={isVersionEnum.isChange}
                                       type="radio"
                                       name="version"
                                       value={isVersionEnum.isChange}
                                       onChange={(event) => {this.changeIsCurrentVersion(event)}}
                                       checked={isVersion === isVersionEnum.isChange}/>
                                <label className={'mb-0'} htmlFor={isVersionEnum.isChange}>Определенная версия</label>
                                <Select
                                    styles={styles}
                                    components={animatedComponents}
                                    isDisabled={isVersion === isVersionEnum.isCurrent}
                                    value={selectedVersion}
                                    onChange={(item)=>{this.changeVersion(item)}}
                                    options={this.state.versionOptions}
                                    placeholder="Выберите версию документа"
                                    title="Выберите версию документа"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            text: 'var(--hover-primary)',
                                            primary25: 'var(--light-primary)',
                                            primary50: 'var(--hover-primary)',
                                            primary: 'var(--originaltwo)',
                                        },
                                    })}
                                />
                            </div>
                        </div>
                        <div className="modal-footer" style={{padding: "1rem 1rem", borderTop: "none"}}>
                            <button type="button"
                                    className="btn btn-primary font-weight-bolder"
                                    onClick={this.saveChanges}>
                                Изменить версию
                            </button>
                            <button type="button"
                                    className="btn btn-light font-weight-bold"
                                    onClick={this.hide}>
                                Отменить действие
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const  mapStateToProps = state => {
    return {
        activeDocument: state.packages.tree.activeDocument,
        activeDocumentAdditional: state.packages.treeAdditional.activeDocument,
        activeDocumentDownload: state.packages.treeDownload.activeDocument,
        activeNode: state.packages.tree.activeNode,
        activeNodeAdditional: state.packages.treeAdditional.activeNode,
        viewMode: state.packages.rootState.viewModePreview
    }
}

const  mapDispatchToProps = {
    setModalData,
    PackageAddNodesTree,
    SelectActivePackageNode,
    togglePackagesFolders,
    breadcrumbsAdd,
    breadcrumbsMoveSelect,
    SelectActivePackageDocument,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeVersionDocInPackage);
