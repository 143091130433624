export const darkBlue_theme = `
@charset "UTF-8";
:root {
    --blue: #204BA0 !important;
    --originaltwo: #365DAA !important;
    --original-light: #365DAA !important;
    --red: #dc3545 !important;
    --orange: #fd7e14 !important;
    --yellow: #ffc107 !important;
    --green: #28a745 !important;
    --teal: #20c997 !important;
    --cyan: #17a2b8 !important;
    --white: #ffffff !important;
    --gray: #abaeb7 !important;
    --gray-dark: #3F4254 !important;
    --primary: #0066FF !important;
    --secondary: #E4E6EF !important;
    --success: #204BA0 !important;
    --hover: #16a39d !important;
    --warning: #FFA800 !important;
    --danger: #F64E60 !important;
    --light: #F3F6F9 !important;
    --dark: #181C32 !important;
    --white: #ffffff !important;
    --gold: #deebff52 !important;

    --blackout: #010423f8 !important;
    --blackout-light: #010423a6 !important;
    --body-bg: #f3f6fa !important;
    --elem-bg: #f5f6fa !important;
    --icon-primary: var(--originaltwo) !important;
    --icon-secondary: #28a745 !important;
    --icon-secondary-active: #20c997 !important;
    --icon-color-table: var(--originaltwo)  !important;
    --left-menu-icon-color: var(--white) !important;
    --text-primary: var(--originaltwo) !important;
    --text-secondary: #28a745 !important;
    --text-secondary-active: #20c997 !important;
    --text-hover: var(--originaltwo) !important;
    --text-welcome: var(--white) !important;
    --btn-primary: var(--success) !important;
    --btn-hover: var(--originaltwo) !important;
    --selection-color: #6992E7B2 !important;
    --pagination-color: var(--originaltwo) !important;
    --pagination-bg: var(--elem-bg) !important;
    --search-result-highlight: #1fd79852 !important;

    --hover-primary: #e6f1e8 !important;
    --light-primary: #f5f6fa !important;
    --active-primary: #c1ebc6 !important;
    --hover-button: #0BB7AF !important;
    --hover-primary-transp: #a5a3ff9c !important;
    --light-primary-transp: #a5a3ff4a !important;

    --breakpoint-xs: 0 !important;
    --breakpoint-sm: 576px !important;
    --breakpoint-md: 768px !important;
    --breakpoint-lg: 992px !important;
    --breakpoint-xl: 1200px !important;
    --breakpoint-xxl: 1400px !important;
    
    --background-colorBS: #89B2EF3F !important;
}
`;

export const lightBlue_theme = `
@charset "UTF-8";
:root {
    --blue: #0364ff !important;
    --originaltwo: #3599fe !important;
    --original-light: #3599fe !important;
    --red: #dc3545 !important;
    --orange: #fd7e14 !important;
    --yellow: #ffc107 !important;
    --green: #28a745 !important;
    --teal: #20c997 !important;
    --cyan: #17a2b8 !important;
    --white: #ffffff !important;
    --gray: #abaeb7 !important;
    --gray-dark: #3F4254 !important;
    --primary: #0066FF !important;
    --secondary: #E4E6EF !important;
    --success: #0364ff !important;
    --hover: #418aff !important;
    --warning: #FFA800 !important;
    --danger: #F64E60 !important;
    --light: #F3F6F9 !important;
    --dark: #181C32 !important;
    --white: #ffffff !important;
    --hover-button: #6a98ff !important;
    --gold: #deebff52 !important;
    
    --blackout: #010423f8 !important;
    --blackout-light: #010423a6 !important;
    --body-bg: #f3f6fa !important;
    --elem-bg: #f5f6fa !important;
    --icon-primary: var(--originaltwo) !important;
    --icon-secondary: #28a745 !important;
    --icon-secondary-active: #20c997 !important;
    --icon-color-table: var(--originaltwo)  !important;
    --left-menu-icon-color: var(--white) !important;
    --text-primary: var(--originaltwo) !important;
    --text-secondary: #28a745 !important;
    --text-secondary-active: #20c997 !important;
    --text-hover: var(--originaltwo) !important;
    --text-welcome: var(--white) !important;
    --btn-primary: var(--success) !important;
    --btn-hover: var(--originaltwo) !important;
    --selection-color: #6992E7B2 !important;
    --pagination-color: var(--originaltwo) !important;
    --pagination-bg: var(--elem-bg) !important;
    --search-result-highlight: #1fd79852 !important;
    
    --hover-button: #0BB7AF !important;
    --hover-primary: #e6f1e8 !important;
    --light-primary: #f5f6fa !important;
    --active-primary: #c1ebc6 !important;
    --hover-primary-transp: #a3d0ff9c !important;
    --light-primary-transp: #a3d8ff4a !important;
    
    --breakpoint-xs: 0 !important;
    --breakpoint-sm: 576px !important;
    --breakpoint-md: 768px !important;
    --breakpoint-lg: 992px !important;
    --breakpoint-xl: 1200px !important;
    --breakpoint-xxl: 1400px !important;
    
    
    --background-colorBS: #89B2EF3F !important;
}
`;

export const azure_theme = `
@charset "UTF-8";
:root {
    --blue: #009DB9 !important;
    --originaltwo: #24315B !important;
    --original-light: #009DB9 !important;
    --red: #dc3545 !important;
    --orange: #FEB93D !important;
    --yellow: #ffc107 !important;
    --green: #28a745 !important;
    --teal: #20c997 !important;
    --cyan: #17a2b8 !important;
    --white: #ffffff !important;
    --gray: #7E8299 !important;
    --gray-dark: #3F4254 !important;
    --primary: #0066FF !important;
    --secondary: #E4E6EF !important;
    --success: #24315B !important;
    --hover: #16a39d !important;
    --warning: #FFA800 !important;
    --danger: #F64E60 !important;
    --light: #F3F6F9 !important;
    --dark: #181C32 !important;
    --white: #ffffff !important;
    --gold: hsla(226, 43%, 25%, 0.05) !important;

    --blackout: #010423f8 !important;
    --blackout-light: #010423a6 !important;
    --body-bg: #f3f6fa !important;
    --elem-bg: #f5f6fa !important;
    --icon-primary: #24315B !important;
    --icon-secondary: #28a745 !important;
    --icon-secondary-active: #20c997 !important;
    --icon-color-table: #009DB9 !important;
    --left-menu-icon-color: var(--white) !important;
    --text-primary: var(--originaltwo) !important;
    --text-secondary: #28a745 !important;
    --text-secondary-active: #20c997 !important;
    --text-hover: var(--originaltwo) !important;
    --text-welcome: var(--white) !important;
    --btn-primary: var(--success) !important;
    --btn-hover: var(--originaltwo) !important;
    --selection-color: #6992E7B2 !important;
    --pagination-color: var(--originaltwo) !important;
    --pagination-bg: var(--elem-bg) !important;
    --search-result-highlight: #1fd79852 !important;

    --hover-button: #d72426 !important;
    --hover-primary: #a7e4ef !important;
    --light-primary: #f4f4fb !important;
    --active-primary: #beebc9 !important;
    --hover-primary-transp: #24315b40 !important;
    --light-primary-transp: #24315b20 !important;


    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --breakpoint-xxl: 1400px;

    --background-colorBS: #878787 !important;
}
`;

export const yellow_theme = `
@charset "UTF-8";
:root {
     --blue: #9E2C2D !important;
    --originaltwo: #FFBA00 !important;
    --original-light: #FFBA00 !important;
    --graphite: #333333 !important;
    --red: #dc3545 !important;
    --orange: #fd7e14 !important;
    --yellow: #ffc107 !important;
    --green: #28a745 !important;
    --teal: #20c997 !important;
    --cyan: #17a2b8 !important;
    --white: #ffffff !important;
    --gray: #7E8299 !important;
    --gray-dark: #3F4254 !important;
    --primary: #0066FF !important;
    --secondary: #E4E6EF !important;
    --success: #f3c855 !important;
    --hover: #16a39d !important;
    --warning: #FFA800 !important;
    --danger: #F64E60 !important;
    --light: #F3F6F9 !important;
    --dark: #181C32 !important;
    --white: #ffffff !important;

    --blackout: #211402fd !important;
    --blackout-light: #211402a6 !important;
    --body-bg: #f3f6fa !important;
    --elem-bg: #fff9ed !important;
    --icon-primary: #3F4254 !important;
    --icon-secondary: #099a66 !important;
    --icon-secondary-active: var(--icon-secondary) !important;
    --icon-color-table: var(--graphite) !important;
    --left-menu-icon-color: var(--graphite) !important;
    --text-primary: var(--graphite) !important;
    --text-secondary: #099a66 !important;
    --text-secondary-active: var(--text-secondary) !important;
    --text-hover: var(--graphite) !important;
    --text-welcome: var(--graphite) !important;
    --btn-primary: var(--originaltwo) !important;
    --btn-hover: #e6c300 !important;
    --selection-color: #3F4254ab !important;
    --pagination-color: var(--graphite) !important;
    --pagination-bg: #fbed9e !important;
    --search-result-highlight: #b3a43b6b !important;

    --hover-button: #FBED9E !important;
    --hover-primary: #FBED9E !important;
    --light-primary: #f5f6fa !important;
    --active-primary: #FFBA0080 !important;
    --hover-primary-transp: #ffba0040 !important;
    --light-primary-transp: #ffba0020 !important;


    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --breakpoint-xxl: 1400px;

    --background-colorBS: #878787 !important;
}
`;

export const red_theme = `
@charset "UTF-8";
:root {
     --blue: #9E2C2D !important;
    --originaltwo: #882324 !important;
    --original-light: #882324 !important;
    --red: #dc3545 !important;
    --orange: #fd7e14 !important;
    --yellow: #ffc107 !important;
    --green: #28a745 !important;
    --teal: #20c997 !important;
    --cyan: #17a2b8 !important;
    --white: #ffffff !important;
    --gray: #7E8299 !important;
    --gray-dark: #3F4254 !important;
    --primary: #0066FF !important;
    --secondary: #E4E6EF !important;
    --success: #9E2C2D !important;
    --hover: #16a39d !important;
    --warning: #FFA800 !important;
    --danger: #F64E60 !important;
    --light: #F3F6F9 !important;
    --dark: #181C32 !important;
    --white: #ffffff !important;
    --gold: #d6c09a2e !important;
    --checked: #ffc107 !important;
    
    --blackout: #211402fd !important;
    --blackout-light: #211402a6 !important;
    --body-bg: var(--light-primary) !important;
    --elem-bg: #fff5e5 !important;
    --icon-primary: var(--originaltwo) !important;
    --icon-secondary: #28a745 !important;
    --icon-secondary-active: #20c997 !important;
    --icon-color-table: var(--originaltwo) !important;
    --left-menu-icon-color: var(--white) !important;
    --text-primary: var(--originaltwo) !important;
    --text-secondary: #28a745 !important;
    --text-secondary-active: #20c997 !important;
    --text-hover: var(--hover-primary) !important;
    --text-welcome: var(--white) !important;
    --btn-primary: var(--originaltwo) !important;
    --btn-hover: #d72426 !important;
    --selection-color: #e76969b2 !important;
    --pagination-color: var(--originaltwo) !important;
    --pagination-bg: #f7efe1 !important;
    --search-result-highlight: #d76b1f52 !important;

    
    --hover-button: #d72426 !important;
    --hover-primary: #f7d2d2 !important;
    --hover-primary-transp: #cd42422e !important;
    --light-primary: #fff3f3 !important;
    --light-primary-transp: #ffd2d27f !important;
    --active-primary: #d76768b0 !important;
    
    
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --breakpoint-xxl: 1400px;
    
    --background-colorBS: #EF898933 !important;
}
`;
