
// действия связанные с деревом документов
export const TREE_ACTIONS = {
    // получение документов для дерева (типов документов)
    GET_DOCS : "DOCUMENT | TREE | GET_DOCS",
    // получение разделы (в модальном окне) документов
    GET_SECTIONS : "DOCUMENT | TREE | GET_SECTIONS",
    // получение групп документов (папки в дереве)
    GET_GROUP : "DOCUMENT | TREE | GET_GROUP",
    //получение структуры
    GET_STRUCTURE : "DOCUMENT | TREE | GET_STRUCTURE",
    // генирация дерева по полученным данным
    CREATE : "DOCUMENT | TREE | CREATE",
    // поиск по дереву
    SEARCH : "DOCUMENT | TREE | SEARCH",
    //текст строки поиска в вкладке "Документы"
    SET_TREE_SEARCH : "DOCUMENT | TREE | SET_TREE_SEARCH",
    // переключение между обычным деревом
    // и деревом с отображением поиска
    SEARCH_TOGGLE : "DOCUMENT | TREE | SEARCH_TOGGLE",
    // сохраняем выбранный узел дерева
    SELECT_NODE : "DOCUMENT | TREE | SELECT_NODE",
    // переключить открыт/закрыт папки в дереве
    TOGGLE_FOLDERS : "DOCUMENT | TREE | TOGGLE_FOLDERS",
    TOGGLE_STRUCTURES : "DOCUMENT | TREE | TOGGLE_STRUCTURES",
    // запустить перезагрузку дерева
    RELOAD: "DOCUMENT | TREE | RELOAD",
    TOGGLE_IS_LOADING : "DOCUMENT | TREE | TOGGLE_IS_LOADING",
};

// действия связанные с таблицой документов
export const MAIN_TABLE_ACTIONS = {
    // загрузка документов для отображения их в таблице
    DOWNLOAD_DOC_COLLECTION : "DOCUMENT | MAIN_TABLE | DOWNLOAD_DOC_COLLECTION",
    // объединяем данные для отображения их в таблице
    COMBINE_ATTRIBUTE : "DOCUMENT | MAIN_TABLE | COMBINE_ATTRIBUTE",
    // установить кол-во элементов отображаемых в таблице
    SET_PAGINATION_ON_PAGE_SIZE : "DOCUMENT | MAIN_TABLE | SET_PAGINATION_ON_PAGE_SIZE",
    // установить номер страницы пагинации в таблице
    SET_PAGINATION_PAGE_NUMBER : "DOCUMENT | MAIN_TABLE | SET_PAGINATION_PAGE_NUMBER",
    // сохраняем документ (строку в таблице) как отмеченную
    ADD_ROW_TO_CHECKED : "DOCUMENT | MAIN_TABLE | ADD_ROW_TO_CHECKED",
    // удаляем документ (строку в таблице) из отмеченных
    DELETE_ROW_FROM_CHECKED : "DOCUMENT | MAIN_TABLE | DELETE_ROW_FROM_CHECKED",
    // добавляем все документы (строки в таблице) к отмеченным
    CHECK_ALL_ROWS_ON_PAGE : "DOCUMENT | MAIN_TABLE | CHECK_ALL_ROWS_ON_PAGE",
    // отмечаем строку как активную
    SELECT_ACTIVE_ROW : "DOCUMENT | MAIN_TABLE | SELECT_ACTIVE_ROW",
    // сброс данных для обновления таблицы
    RESET_FOR_UPDATE : "DOCUMENT | MAIN_TABLE | RESET_FOR_UPDATE",
    // установить дефолтное значение размера столбца
    SET_DEFAULT_WIDTH_OF_COLUMN: "DOCUMENT | MAIN_TABLE | SET_DEFAULT_WIDTH_OF_COLUMN",
    // изменить способ сортировки: по возрастанию или убыванию
    CHANGE_SORT_DIRECTION: "DOCUMENT | MAIN_TABLE | CHANGE_SORT_DIRECTION",
    // флаг на закрытие всех вкладок
    CLOSE_OPENED_TABS: "DOCUMENT | MAIN_TABLE | CLOSE_OPENED_TABS",
    //получить информацию по докумнту,по которому нажали
    GET_ACTIVE_DOC:"DOCUMENT | MAIN_TABLE | GET_ACTIVE_DOC",

};

// глобальное состояние для компонента DocumentApp
export const DOCUMENT_ROOT_STATE_ACTIONS = {
    // изменить режим отображения
    CHANGE_VIEW_MODE : "DOCUMENT | ROOT_STATE | CHANGE_VIEW_MODE",
    // изменить режим отображения фильтров
    CHANGE_VIEW_MODE_SEARCH : "DOCUMENT | ROOT_STATE | CHANGE_VIEW_MODE_SEARCH",
    // переключение видимости asideMenu
    TOGGLE_SHOW_ASIDE_MENU: "DOCUMENT | ROOT_STATE | TOGGLE_SHOW_ASIDE_MENU"
};


// поиск в документах
export const DOCUMENT_SEARCH_ACTIONS = {
    // изменить режим отображения
    CHANGE_INPUTS : "DOCUMENT | SEARCH | CHANGE_INPUTS",
    CHANGE_VIEW_MODE : "DOCUMENT | SEARCH | CHANGE_VIEW_MODE",
    SET_SEARCH_START : "DOCUMENT | SEARCH | SET_SEARCH_START",
    // переключение режима фильтра "Расширенные фильтры"
    IS_EXT_SEARCH : "DOCUMENT | SEARCH | IS_EXT_SEARCH",
};

// действия связанные с предпросмотром документов
export const DOCUMENT_PREVIEW_ACTIONS = {
    // сбор и сохранение данных для нового выбранного документа
    CREATE_BY_NEW_OPEN_DOC : "DOCUMENT | PREVIEW | CREATE_BY_NEW_OPEN_DOC",
    // выбор активного (показанного) файла для предпросмотра
    SELECT_ACTIVE_FILE : "DOCUMENT | PREVIEW | SELECT_ACTIVE_FILE",
    //сохранение размера предпросмтора
    SET_SIZE_PREVIEW : "DOCUMENT | PREVIEW | SET_SIZE_PREVIEW",
    // сбор данных для отображения в дополнительных разделах
    COLLECT_DATA_FOR_ADDITIONAL_SECTIONS_NORMAL : "DOCUMENT | ADDITIONAL_SECTIONS | COLLECT_DATA_FOR_ADDITIONAL_SECTIONS_NORMAL",
    // обновление данных по дополнительному разделу с подразделениями
    UPDATE_DATA_FOR_DEPARTMENTS_SECTION : "DOCUMENT | ADDITIONAL_SECTIONS | UPDATE_DATA_FOR_DEPARTMENTS_SECTION",
    // открытие или закрытие дополнительных разделов
    TOGGLE_ADDITIONAL_SECTIONS_OPEN : "DOCUMENT | ADDITIONAL_SECTIONS | TOGGLE_ADDITIONAL_SECTIONS_OPEN",
    // открытие или закрытие дополнительных разделов для карточки документа
    TOGGLE_ADDITIONAL_SECTIONS_OPEN_CARDS : "DOCUMENT | CARDS | TOGGLE_ADDITIONAL_SECTIONS_OPEN_CARDS",
    // создание новой карточки документов
    CREATE_DOC_CARDS : "DOCUMENT | CARDS | CREATE_DOC_CARDS",
    // обновление карточки документа
    UPDATE_DOC_CARDS : "DOCUMENT | CARDS | UPDATE_DOC_CARDS",
    // закрыть карточку документа
    DELETE_DOC_CARDS : "DOCUMENT | CARDS | DELETE_DOC_CARDS",
    // выбрать активную для просмотра карточку документа
    SELECT_ACTIVE_DOC_CARDS : "DOCUMENT | CARDS | SELECT_ACTIVE_DOC_CARDS",
    // выбрать активный файл предпросмотра в карточке документа
    SELECT_ACTIVE_PREVIEW_FILE_FOR_CARDS : "DOCUMENT | CARDS | SELECT_ACTIVE_PREVIEW_FILE",
    // создаем данные для редактирования карточки документа
    CREATE_REDACTOR_DATA : "DOCUMENT | CARDS | REDACTOR | CREATE_OR_DELETE_DATA",
    // обновление аттрибутов в режиме редактирования
    UPDATE_ATTRIBUTES_REDACTOR_DATA : "DOCUMENT | CARDS | REDACTOR | UPDATE_ATTRIBUTES",
    // обновление содержимого в режиме редактирования
    UPDATE_CONTENT_REDACTOR_DATA : "DOCUMENT | CARDS | REDACTOR | UPDATE_CONTENT",
    // обновление имени или описание файла в режиме редактирования
    UPDATE_FILES_NAME_OR_DESCRIPTION : "DOCUMENT | CARDS | REDACTOR | UPDATE_FILES_NAME_OR_DESCRIPTION",
    // добавление новых файлов в режиме редактирования
    UPDATE_FILES_ADD_NEW : "DOCUMENT | CARDS | REDACTOR | UPDATE_FILES_ADD_NEW",
    // удаление файлов в режиме редактирования
    UPDATE_FILES_DELETE : "DOCUMENT | CARDS | REDACTOR | UPDATE_FILES_DELETE",
    // удаление новый прикрепленных файлов
    UPDATE_FILES_DELETE_NEW_ATTACHMENT : "DOCUMENT | CARDS | REDACTOR | UPDATE_FILES_DELETE_NEW_ATTACHMENT",
    //обновление типа документа
    UPDATE_DOC_TYPE_REDACTOR_DATA: "DOCUMENT | CARDS | REDACTOR | UPDATE_DOC_TYPE",
    // выбрать файл основным в режиме редактирования
    SET_MAIN_FILE : "DOCUMENT | CARDS | REDACTOR | SET_MAIN_FILE",
    // проверка какие данные пользователь ввел перед отправлением на сервер
    CHECK_INPUT_DATA_BEFORE_UPDATE : "DOCUMENT | CARDS | REDACTOR | CHECK_INPUT_DATA_BEFORE_UPDATE",
    // сброс предпросмотра
    RESET : "DOCUMENT | PREVIEW | RESET",
    TYPE_File : "DOCUMENT | PREVIEW | TYPE_File",
    // обновление имени или описание файла в режиме редактирования
    SELECT_ACTIVE_ROW_FILE : "DOCUMENT | CARDS | REDACTOR | SELECT_ACTIVE_ROW_FILE",
    DELETE_SIGNATURE: "DOCUMENT | CARDS | REDACTOR | DELETE_SIGNATURE",
    UPDATE_SIGNATURE: "DOCUMENT | CARDS | REDACTOR | UPDATE_SIGNATURE",
    // Список файлов для просмотра подписей
    SET_ROW_FILE_FOR_SHOW_SIGNATURE : "DOCUMENT | CARDS | REDACTOR | SET_ROW_FILE_FOR_SHOW_SIGNATURE",
    // настройка содержания для конкретного файла
    SET_FILE_CONTENT: "DOCUMENT | ADDITIONAL_SECTIONS | SET_FILE_CONTENT",
    // выбор вида отображения файла (оригинал / предпросмотр)
    SELECT_VIEW_FOR_ACTIVE_FILE: "DOCUMENT | PREVIEW | SELECT_VIEW_FOR_ACTIVE_FILE",
    SET_ACTIVE_FILE_INDEX:"DOCUMENT | PREVIEW | SET_ACTIVE_FILE_INDEX",
};

// временные данные во избежании повторных запросов к серверу
export const DOCUMENT_TEMPORARY_DATA =  {
    // сохранить произвольные данные
    SAVE : "DOCUMENT | TEMPORARY_DATA | SAVE",
    // стереть все данные
    CLEAR : "DOCUMENT | TEMPORARY_DATA | CLEAR",
}

//данные о верификации документов
export const DOCUMENT_VERIFY =  {
    // сохранить произвольные данные
    UPDATE_VERIFY_DOCS : "DOCUMENT | DOCUMENT_VERIFY | UPDATE_VERIFY_DOCS",
    // стереть все данные
    CLEAR : "DOCUMENT | DOCUMENT_VERIFY | CLEAR",
}
