import React from "react";
import {connect} from "react-redux";
import "moment/locale/ru";
import moment from 'moment';
import {
    changeVersionDocumentCards,
    collectDataForAdditionalSections_Normal,
    createPreviewBySelectDocument,
    toggleAdditionalSectionsOpen, toggleAdditionalSectionsOpen_Cards
} from "../../store/documents/actionCreators/document_PreviewActionCreator";
import {attributesSectionsTypes} from "../../tools/StaticTypes";
import {__} from "../../tools/HelpFunctions";
import {store} from "../../index";
import {setLoaderModalData} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {setVersionIdForLocate} from "../../store/masterApp/actionCreator/masterAppActionCreator";



class VersionSection extends React.Component {
    constructor(props) {
        super(props);


        this.state = {

        };

        this.sectionName = attributesSectionsTypes.versionSection;

        this.toggle = this.toggle.bind(this);
        this.selectVersion = this.selectVersion.bind(this);
        this.handleVersionIdForLocate = this.handleVersionIdForLocate.bind(this);
    }

    handleVersionIdForLocate() {
        if (this.props.VersionIdForLocate) {
            let sectionData = this.getSectionData(true, false).sectionData;
            if (sectionData && sectionData.versions && sectionData.versions.length > 0) {
                let lVersionIndex = sectionData.versions.findIndex(item => item.Id == this.props.VersionIdForLocate);
                if (lVersionIndex && lVersionIndex !== -1) {
                    this.selectVersion(lVersionIndex);
                    this.props.setVersionIdForLocate(null);
                }
            }
        }
    }

    componentDidMount() {
        this.handleVersionIdForLocate();
    }

    // открытье\закрытие раздела
    toggle() {
        if(this.props.viewMode === "normal") {
            this.props.toggleAdditionalSectionsOpen({
                Name : this.sectionName
            });
        } else if (this.props.viewMode === "card") {
            this.props.toggleAdditionalSectionsOpen_Cards({
                Name : this.sectionName
            });
        }
    }
    getSectionData(flagSectionData, flagIsReady) {
        let sectionData;
        let isReady;
        if(this.props.viewMode === "normal") {
            if (flagSectionData) {
                sectionData = this.props.additionalSectionsData.state[this.sectionName];
            }
            if (flagIsReady) {
                isReady = this.props.additionalSectionsData.isReady;
            }
        }
        else if(this.props.viewMode === "card") {
            let card;
            if(this.props.redactorMode && this.props.cards.redactorData) {
                card = this.props.cards.redactorData;
            } else {
                card = this.props.cards.collection.find(card => card.Id === this.props.cards.activeCardId);
            }
            if (flagSectionData) {
                sectionData = __.deepCopy(card.additionalSections[this.sectionName]);
                sectionData.isOpen = this.props.cards.collection
                    .find(card => card.Id === this.props.cards.activeCardId)
                    .additionalSections[this.sectionName].isOpen;
            }
            if (flagIsReady) {
                isReady = card.additionalSections.isReady;
            }
        }
        return {sectionData, isReady};

    }
    // выбрать другую версию документа
    async selectVersion(index, item) {
        if (!this.props.redactorMode) {
            if (this.props.viewMode === "normal") {
        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "documents", key : "VersionSection55" }
        }));
                await this.props.createPreviewBySelectDocument({
                    VersionIndex: index,
                    Next: {
                        action: async () => {
                            await this.props.collectDataForAdditionalSections_Normal({
                                VersionIndex: index
                            });
                            //перенесено на componentDidUpdate, иначе закрывается слишком рано
                            // store.dispatch(setModalData({}));
                        },
                        params: {}
                    }
                });
            } else if (this.props.viewMode === "card") {
                store.dispatch(setLoaderModalData({
                    data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "documents", key : "VersionSection55" }
                }));
                await this.props.changeVersionDocumentCards({
                    VersionIndex: index,
                    CardId: this.props.cards.activeCardId,
                    NewVersionItem: item,
                });
                //перенесено на componentDidUpdate, иначе закрывается слишком рано
                // store.dispatch(setModalData({}));
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // TODO SS 23791 убрано условие, иначе при выборе текущей версии оставался бесконечный прелоадер
        // let card;
        // if(this.props.redactorMode && this.props.cards.redactorData) {
        //     card = this.props.cards.redactorData;
        // } else {
        //     card = this.props.cards.collection.find(card => card.Id === this.props.cards.activeCardId);
        // }
        //
        // let prevCard;
        // if(prevProps.redactorMode && prevProps.cards.redactorData) {
        //     prevCard = prevProps.cards.redactorData;
        // } else {
        //     prevCard = prevProps.cards.collection.find(card => card.Id === prevProps.cards.activeCardId);
        // }
        //
        //
        // if (this.props.additionalSectionsData?.state[this.sectionName]?.activeVersionIndex
        //     != prevProps.additionalSectionsData?.state[this.sectionName]?.activeVersionIndex
        //     ||
        //     card?.additionalSections[this.sectionName].activeVersionIndex != prevCard?.additionalSections[this.sectionName].activeVersionIndex
        // )
        // {
        //     store.dispatch(setModalData({}));
        // }

        this.handleVersionIdForLocate();

        //Новый прелоадер
        store.dispatch(setLoaderModalData({keyDeleted: "VersionSection111"}));
    }


    render() {
        let {sectionData, isReady} = this.getSectionData(true, true)
        let cardTitleClass = "card-title";
        let cardCollapseClass = "collapse show";
        if(!sectionData.isOpen) {
            cardTitleClass = "card-title collapsed";
            cardCollapseClass = "collapse";
        }

        return (
            <div className="card">
                <div className="card-header" onClick={this.toggle}>
                    <div className={cardTitleClass}>
                        Версии документа
                    </div>
                </div>
                <div className={cardCollapseClass}>
                    <div className="card-body p-0">
                        <table className="table table-bordered table-hover table-striped m-0">
                            <thead>
                            <tr>
                                <th className="col-lg-4">Дата</th>
                                <th className="col-lg-7">Тип документа</th>
                                <th className="col-lg-1">Номер</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                isReady &&
                                sectionData.versions.map((item, index)=>{
                                    let timeMoment = new moment(item.VersionTime);

                                    let trClass = "";
                                    if(index === sectionData.activeVersionIndex) {
                                        trClass = "tr-active";
                                    }

                                    return(
                                        <tr className={trClass} key={item.Version} onClick={()=> {this.selectVersion(index, item)}}>
                                            <td title={timeMoment.format("LTS")}>
                                               <time>{timeMoment.format("L")}</time>
                                            </td>
                                            <td title={item.Type?.Name}>
                                                {item.Type?.Name}
                                            </td>
                                            <td title={item.VersionComment}>
                                                {/*TODO aa 25.01.2024 убрана битая ссылка*/}
                                                {/*<a href="#" className="btn btn-icon btn-outline-success btn-circle btn-xs">*/}
                                                <div className="btn btn-icon btn-outline-success btn-circle btn-xs">
                                                    {item.Version}
                                                </div>
                                                {/*</a>*/}
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        additionalSectionsData : state.document.additionalSections,
        cards : state.document.cards,

        VersionIdForLocate: state.masterApp.VersionIdForLocate,
    }
}

const  mapDispatchToProps = {
    changeVersionDocumentCards,
    collectDataForAdditionalSections_Normal,
    createPreviewBySelectDocument,
    toggleAdditionalSectionsOpen,
    toggleAdditionalSectionsOpen_Cards,
    setVersionIdForLocate,
}

export default connect(mapStateToProps, mapDispatchToProps)(VersionSection);
