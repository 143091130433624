import React from 'react';

const FileDownloadComponent = ({ fileUrl, fileName, fileSize }) => {
    return (
        <>
            <span
                className="text-muted font-weight-bold font-size-base mr-1">{"Файл:"}
            </span>
            <a style={{marginRight: "2px", width: "100%", justifyContent: "left"}} title={`Скачать ${fileName}`} href={fileUrl} download
               className="btn btn-icon">
                <span
                    style={{textOverflow: "ellipsis", maxWidth: "100%", overflow: "hidden"}}
                    className={`text-dark-50 font-weight-bolder font-size-base mr-3 text-nowrap`}
                    title={`Скачать ${fileName}`}
                >
                    {fileName}
                </span>
                <i className="svg-icon icon-Doc_view_4 icon-color-primary" style={{fontSize: "1.5rem"}}/>
            </a>

            <span className="form-text text-muted mt-4">{`Размер файла - ${fileSize}`}</span>
        </>
    );
};

export default FileDownloadComponent;
