export const ActionTypes = {
    SET_OTHER_FILES: "MASTER_APP | SET_OTHER_FILES",
    SET_ORIGINAL_FILE: "MASTER_APP | SET_ORIGINAL_FILE",
    SET_SHOW_BANNER: "MASTER_APP | SET_SHOW_BANNER",
    SET_ACTIVE_ID_FILIAL: "MASTER_APP | SET_ACTIVE_ID_FILIAL",
    SET_ACTIVE_TYPE: "MASTER_APP | SET_ACTIVE_TYPE",
    SET_SELECTED_GROUPS: "MASTER_APP | SET_SELECTED_GROUPS",
    SET_TYPE_VALUE: "MASTER_APP | SET_TYPE_VALUE",
    SET_LOAD_TYPES: "MASTER_APP | SET_LOAD_TYPES",
    SET_LOAD_GROUPS: "MASTER_APP | SET_LOAD_GROUPS",
    SET_DOC_NAME: "MASTER_APP | SET_DOC_NAME",
    SET_DOC_NAME_ERROR: "MASTER_APP | SET_DOC_NAME_ERROR",
    SET_REG_NUMBER: "MASTER_APP | SET_REG_NUMBER",
    SET_REG_DATE: "MASTER_APP | SET_REG_DATE",
    SET_ACTIVE_ATTRIBUTES: "MASTER_APP | SET_ACTIVE_ATTRIBUTES",
    SET_PREVIEW_FILE: "MASTER_APP | SET_PREVIEW_FILE",
    SET_DISABLE_BTN_TO_DOCUMENT: "MASTER_APP | SET_DISABLE_BTN_TO_DOCUMENT",
    SET_COMMENT: "MASTER_APP | SET_COMMENT",
    SET_CONTENT_TEXT: "MASTER_APP | SET_CONTENT_TEXT",
    SET_VERSION_ID_FOR_LOCATE: "MASTER_APP | SET_VERSION_ID_FOR_LOCATE",
};
