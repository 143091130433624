import React from "react";
import {connect} from "react-redux";
import {
    PackagesPaginationMovePage,
    setPaginationPageSizeInPackages
} from "../../../store/packages/actionCreators/package_MainTableActionCreator";
import {setLoaderModalData, setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import PaginationSelect from "../../PaginationSelect";
import {store} from "../../../index";
import {
    SelectActivePackageNode
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {
    handlerSearchPackages
} from "../../../store/packages/actionCreators/package_InputActionCreator";
import {ActionQueue} from "../../../store/rootReducer";
import {__} from "../../../tools/HelpFunctions";

class PaginationCounterPackages extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.change = this.change.bind(this);
        this.getCurrentSearchInputs = this.getCurrentSearchInputs.bind(this);
    }

    getCurrentSearchInputs() {
        let searchInputs;

        if (this.props.tableRole === "main") {
            searchInputs = this.props.searchInputsMain
        } else if (this.props.tableRole === "additional") {
            searchInputs = this.props.searchInputsAdditional
        } else if (this.props.tableRole === "download") {
            searchInputs = this.props.searchInputsDownload
        }

        return searchInputs;
    }

    change(value) {
        const newSize = value;
        const searchInputs = this.getCurrentSearchInputs();

        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "packages", key : "PaginationCounterPackages35" }
        }));

        let currentSortedParams, FieldSorting, Direction;
        if (this.props.tableRole === "main") {
            currentSortedParams = store.getState().packages.tree.SortedParams
        } else if (this.props.tableRole === "additional") {
            currentSortedParams = store.getState().packages.treeAdditional.SortedParams
        } else if (this.props.tableRole === "download") {
            currentSortedParams = store.getState().packages.treeDownload.SortedParams
        }
        for (const [key, value] of Object.entries(currentSortedParams)) {
            if (value) {
                FieldSorting = key
                Direction = value
                break;
            }
        }

        // TODO КН 14.05.24 Думаю, что тут не должно быть никаких условий и происходить одна логика
        if (Object.values(searchInputs.AppliedParams).some(Boolean)){
            this.props.ActionQueue({
                List : [
                    // TODO КН 14.05.24 Убрал, т.к. все что внутри PackageAddNodesTree итак сделается в PackagesPaginationMovePage
                    // {action : this.props.PackageAddNodesTree, params : {Id: activeNodeId}, name : "PackageAddNodesTree"},
                    {action : this.props.setPaginationPageSizeInPackages, params : {
                            Count: newSize, TableType: this.props.tableRole
                        }, name : "setPaginationPageSizeInPackages"},
                    {action : this.props.PackagesPaginationMovePage, params : {TableType: this.props.tableRole, FieldSorting, Direction}, name : "PackagesPaginationMovePage"},
                    {action : this.props.setLoaderModalData, params : {keyDeleted: "InputSearchPackage421"}, name : "setLoaderModalData"},
                ],
                debug : false,
            });
            __.deleteDocFromLinkUsingActiveNode(this.props.tableRole);
        } else {
            this.props.ActionQueue({
                List : [
                    {action : this.props.setPaginationPageSizeInPackages, params : {
                            Count: newSize, TableType: this.props.tableRole
                        }, name : "setPaginationPageSizeInPackages"},
                    {action : this.props.PackagesPaginationMovePage, params : {TableType: this.props.tableRole, FieldSorting, Direction}, name : "PackagesPaginationMovePage"},
                    {action : () => {
                            this.props.setPageInBreadcrumbs();
                            this.props.setLoaderModalData({keyDeleted: "PaginationCounterPackages50"})
                        }, params : {}, name : "setLoaderModalData"},

                ],
                debug : false,
            });
            __.deleteDocFromLinkUsingActiveNode(this.props.tableRole);
        }
    }

    render() {

        let paginationPageSize = this.props.tableRole === "main"
            ?  this.props.paginationPageSize
            :  this.props.tableRole === "additional"
                ? this.props.paginationPageSizeAdditional
                : this.props.paginationPageSizeDownload;


        let {activePage, totalItemsCount, isViewModeNone} = this.props;
        let from = ((activePage - 1) * paginationPageSize) + 1;
        let to = ((Number.parseInt((activePage - 1) ) * paginationPageSize)) + Number.parseInt(paginationPageSize);

        if(to > totalItemsCount) to = totalItemsCount;

        let infoText = "...";
        if(totalItemsCount) {
            if(totalItemsCount <= 0) {
                infoText = (<span className="text-muted">Документов не обнаружено.</span>);
            }
            else {
                infoText = (<span className="text-muted">{isViewModeNone ? "Показано" : ""} {from} - {to} из {totalItemsCount}</span>);
            }
        }


        return (
            <PaginationSelect activePage={activePage}
                              infoText={infoText}
                              paginationPageSize={paginationPageSize}
                              change={this.change}
                              setPageInBreadcrumbs={this.props.setPageInBreadcrumbs}
            />
        );
    }
}

const  mapStateToProps = state => {
    return {
        activeNode: state.packages.tree.activeNode,
        activeNodeAdditional: state.packages.treeAdditional.activeNode,
        activeNodeDownload: state.packages.treeDownload.activeNode,

        paginationPageSize : state.packages.tree.paginationPageSize,
        paginationPageSizeAdditional : state.packages.treeAdditional.paginationPageSize,
        paginationPageSizeDownload : state.packages.treeDownload.paginationPageSize,

        searchInputsMain: state.packages.tree.searchInputs,
        searchInputsAdditional: state.packages.treeAdditional.searchInputs,
        searchInputsDownload: state.packages.treeDownload.searchInputs,
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    handlerSearchPackages,
    PackagesPaginationMovePage,
    SelectActivePackageNode,
    setLoaderModalData,
    setModalData,
    setPaginationPageSizeInPackages,

}

export default connect(mapStateToProps,mapDispatchToProps)(PaginationCounterPackages);
