import React from "react";
import {connect} from "react-redux";
import {
    contextMenuTypes, ModalTypes, PackagesViewFilters,
    RootComponentsStateViewModeTypes,
    UserSettingsActionsTypes,
    UserSettingsNames
} from "../../../tools/StaticTypes";
import {
    setContextMenuData, setLoaderModalData, setModalData,
    setStateLeftAsideMenu
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {
    createDocumentCards, selectActiveDocumentCard,
} from "../../../store/documents/actionCreators/document_PreviewActionCreator";
import {changeDocumentSearchInputsContextMenu, changeViewMode} from "../../../store/documents/actionCreators/document_SearchActionCreator";
import {combineMainTableAttributes, mainTableSearchDoc} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {updateUserSettings} from "../../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import {changeDocumentViewMode} from "../../../store/documents/actionCreators/document_RootStateActionCreator";
import {changePackagesViewMode} from "../../../store/packages/actionCreators/package_RootStateActionCreator";
import {
    handlerSearchPackages,
    updateInputsFieldPackage
} from "../../../store/packages/actionCreators/package_InputActionCreator";
import {changeViewFilter} from "../../../store/packages/actionCreators/package_MainTableActionCreator";
import {
    getDocumentsForTreePackages,
    SelectActivePackageNode
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {store} from "../../../index";
import {API} from "../../../tools/API_NEW/API";
import {toggleFoldersForTree, treeSelectNode} from "../../../store/documents/actionCreators/document_TreeActionCreator";
import {__} from "../../../tools/HelpFunctions";
import "bootstrap-icons/font/bootstrap-icons.css";
import {DOCUMENT_VERIFY} from "../../../store/documents/documentActionsList";
import {EntityFilters, Filter, FiltersType} from "../../../tools/Filters";

class ContextMenuMainTableTr extends React.Component {
    constructor(props) {
        super(props);
        this.lFlagCopyUrl1C = this.props.globalSettings.WithCopyUrl1C === "1";

        this.state = {
            scrollY : window.scrollY, // TODO: пока оставил позицию окна при контексте но если не будем юзать -  удалить
            scrollX : window.scrollX,
            isHover : false
        };

        this.name = contextMenuTypes.MainTableTR;


        this.copy = this.copy.bind(this);
        this.addDocCard = this.addDocCard.bind(this);
        this.addDocCardFromPackages = this.addDocCardFromPackages.bind(this);
        this.addDocCardFromSearch = this.addDocCardFromSearch.bind(this);
        this.search = this.search.bind(this);
        this.isDocumentApp = this.isDocumentApp.bind(this);
        this.showShareModal=this.showShareModal.bind(this);

    }

    componentDidMount() {
        document.onscroll = (event) => { // скрытие контекстного меню при скроле документа
            if(this.props.contextMenu.name === contextMenuTypes.MainTableTR) {
                this.props.setContextMenuData({});
            }
        };
        document.onclick = (event) => {
            if(!this.state.isHover) {
                this.props.setContextMenuData({});
            }
        };
    }

    // отписываемся от событий при удалении элемента
    componentWillUnmount() {
        document.onscroll = null;
        document.onclick = null;
    }

    showShareModal(){
        this.props.setModalData({
            name:ModalTypes.messenger.shareDocs,
            data:{}
        })
    }
    copy() {
        let copytext = document.createElement('input');

        copytext.value = this.isDocumentApp()
            ? this.props.contextMenu.data.item.Value
            : this.props.contextMenu.data?.itemData.Value

        document.body.appendChild(copytext);
        copytext.select();
        document.execCommand('copy');
        document.body.removeChild(copytext);

        this.props.setModalData({
            name : ModalTypes.app.alert,
            data : {content: `Содержимое скопировано`, disableButton: true}
        });

        setTimeout(() => {
            this.props.setModalData({});
            // //Новый прелоадер
            // this.props.setLoaderModalData({keyDeleted: "ContextMenuMainTableTr96"});
            }, 500);
        this.props.setContextMenuData({});
    }


    async addDocCardFromSearch() {
        const IdDoc = this.props.contextMenu.data.tr.info.DocVersion.IdDoc;
        const IdVersion = this.props.contextMenu.data.tr.info.DocVersion.Id;
        try {
            store.dispatch(setLoaderModalData({
                data: {
                    content: "Загрузка...",
                    disableButton: true,
                    fullBackground: false,
                    gif: "documents",
                    key: "ContextMenuMainTableTr113"
                }
            }));

            let haveBeenVerified = false;
            let docVerifiedInfo = null;

            //Если документ уже верифицирован, то не делаем запрос
            if (this.props.verifyDocs) {
                if (this.props.verifyDocs[IdDoc]) {
                    haveBeenVerified = true;
                    docVerifiedInfo = this.props.verifyDocs[IdDoc][0];
                }
            }

            if (!haveBeenVerified) {
                docVerifiedInfo = await API.search().verifyDocClientLinkIds({
                    linkIds: {
                        IdDoc: IdDoc,
                        IdFilial: this.props.activeFilialId
                    }
                });
                // сохраняем в редакс
                store.dispatch({
                    type: DOCUMENT_VERIFY.UPDATE_VERIFY_DOCS, payload: {
                        id: IdDoc,
                        data: docVerifiedInfo
                    }
                });
            }
            if (docVerifiedInfo.errorCode) {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {
                        type: "fail",
                        content: `
                        Ошибка: ${docVerifiedInfo.message.ExceptionName} <br/>
                        Время: ${docVerifiedInfo.message.ExceptionTime} <br/>
                        Сообщение: ${docVerifiedInfo.message.Message} <br/>
                    `
                    }
                });
            } else {
                const groupTree = __.findGroup(store.getState().document.tree.render.children, docVerifiedInfo.IdDocGroup, false, true);

                const typeTree = groupTree?.children.find(type => type.info.Id === docVerifiedInfo.IdDocType);

                if (groupTree?.info?.StructureNode) {
                    let ids = [];
                    __.findParents(store.getState().document.tree.render.children, groupTree.parent, ids);
                    ids.forEach(item => {
                        store.dispatch(toggleFoldersForTree({IdFolder: item, KeepOpen: true}))
                    });
                }
                store.dispatch(toggleFoldersForTree({IdFolder: groupTree?.info?.Id, KeepOpen: true}));

                if (!typeTree) {
                    this.props.setModalData({
                        name: ModalTypes.app.info,
                        data: {type: "fail", content: `Недостаточно прав для открытия документа`}
                    });

                    store.dispatch(setLoaderModalData({keyDeleted: "PackagesPreview378"}));

                    return
                }
                let getLastVersion = await API.documents().getAllVersions({
                    filter: new Filter(FiltersType.ENTITY).add(
                        new EntityFilters().setEntityName("DocVersion").add({
                            Name: "IdDoc",
                            Value: IdDoc
                        }).get()
                    ).get(),
                    sorting: null,
                }).then((res) => {
                    getLastVersion = res.Records[res.Records.length - 1]
                })
                    .then(() => {
                        this.props.createDocumentCards({
                            IdDoc: docVerifiedInfo.IdDoc,
                            IdVersion: getLastVersion.Id,
                            packInfo: {
                                Node: {
                                    Type: {
                                        Id: groupTree.info.Id,
                                        Name: groupTree.info.Name,
                                    }
                                },
                                IdDocGroup: docVerifiedInfo.IdDocGroup,
                                DocGroupName: groupTree?.info.Name
                            }
                        })
                    }).then(() => {
                        this.props.updateUserSettings({
                            Name: UserSettingsNames.DOCUMENT_CONFIG,
                            Action: UserSettingsActionsTypes.Update,
                            Data: {
                                IdDoc: IdDoc,
                                IdVersion: IdVersion,
                                Name: `тест `
                            }
                        });

                        this.props.setContextMenuData({});
                    })
            }
        } finally {
            let res = await API.documents().getContentForCurrentFile({
                IdDoc: IdDoc,
                IdVersion: IdVersion,
            })
            if (res?.errorCode) {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {
                        content: `Ошибка открытия: файл поврежден`, type: "fail"
                    }
                });
            } else {
                this.props.setLoaderModalData({keyDeleted: "ContextMenuMainTableTr254"});
            }
        }
    }
    async addDocCardFromPackages() {
        const IdDoc = this.props.contextMenu.data.item.Node.Version.IdDoc;
        const IdVersion = this.props.contextMenu.data.item.Node.Version.Id;
        try {
            store.dispatch(setLoaderModalData({
                data: {
                    content: "Загрузка...",
                    disableButton: true,
                    fullBackground: false,
                    gif: "documents",
                    key: "ContextMenuMainTableTr113"
                }
            }));

            let haveBeenVerified = false;
            let docVerifiedInfo = null;

            //Если документ уже верифицирован, то не делаем запрос
            if (this.props.verifyDocs) {
                if (this.props.verifyDocs[IdDoc]) {
                    haveBeenVerified = true;
                    docVerifiedInfo = this.props.verifyDocs[IdDoc][0];
                }
            }

            if (!haveBeenVerified) {
                docVerifiedInfo = await API.search().verifyDocClientLinkIds({
                    linkIds: {
                        IdDoc: IdDoc,
                        IdFilial: this.props.activeFilialId
                    }
                });
                // сохраняем в редакс
                store.dispatch({
                    type: DOCUMENT_VERIFY.UPDATE_VERIFY_DOCS, payload: {
                        id: IdDoc,
                        data: docVerifiedInfo
                    }
                });
            }
            if (docVerifiedInfo.errorCode) {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {
                        type: "fail",
                        content: `
                        Ошибка: ${docVerifiedInfo.message.ExceptionName} <br/>
                        Время: ${docVerifiedInfo.message.ExceptionTime} <br/>
                        Сообщение: ${docVerifiedInfo.message.Message} <br/>
                    `
                    }
                });
            } else {
                const groupTree = __.findGroup(store.getState().document.tree.render.children, docVerifiedInfo.IdDocGroup, false, true);

                const typeTree = groupTree?.children.find(type => type.info.Id === docVerifiedInfo.IdDocType);

                if (groupTree?.info?.StructureNode) {
                    let ids = [];
                    __.findParents(store.getState().document.tree.render.children, groupTree.parent, ids);
                    ids.forEach(item => {
                        store.dispatch(toggleFoldersForTree({IdFolder: item, KeepOpen: true}))
                    });
                }
                store.dispatch(toggleFoldersForTree({IdFolder: groupTree?.info?.Id, KeepOpen: true}));

                if (!typeTree) {
                    this.props.setModalData({
                        name: ModalTypes.app.info,
                        data: {type: "fail", content: `Недостаточно прав для открытия документа`}
                    });

                    store.dispatch(setLoaderModalData({keyDeleted: "PackagesPreview378"}));

                    return
                }
                let getLastVersion = await API.documents().getAllVersions({
                    filter: new Filter(FiltersType.ENTITY).add(
                        new EntityFilters().setEntityName("DocVersion").add({
                            Name: "IdDoc",
                            Value: IdDoc
                        }).get()
                    ).get(),
                    sorting: null,
                }).then((res) => {
                    getLastVersion = res.Records[res.Records.length - 1]
                    // console.log('getLastVersion', getLastVersion)
                })
                    .then(() => {
                        this.props.createDocumentCards({
                            IdDoc: docVerifiedInfo.IdDoc,
                            IdVersion: getLastVersion.Id,
                            packInfo: {
                                ...this.props.contextMenu.data.item,
                                IdDocGroup: docVerifiedInfo.IdDocGroup,
                                DocGroupName: groupTree?.info.Name
                            }
                        })
                    }).then(() => {
                        this.props.updateUserSettings({
                            Name: UserSettingsNames.DOCUMENT_CONFIG,
                            Action: UserSettingsActionsTypes.Update,
                            Data: {
                                IdDoc: IdDoc,
                                IdVersion: IdVersion,
                                Name: `${this.props.contextMenu.data.item.Node.Name} ${this.props.contextMenu.data.item.Node.ShortDescription}`
                            }
                        });

                        this.props.setContextMenuData({});
                    })
            }
        } finally {
            let res = await API.documents().getContentForCurrentFile({
                IdDoc: IdDoc,
                IdVersion: IdVersion,
            })
            if (res?.errorCode) {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {
                        content: `Ошибка открытия: файл поврежден`, type: "fail"
                    }
                });
            } else {
                this.props.setLoaderModalData({keyDeleted: "ContextMenuMainTableTr254"});
            }
        }
    }

    async addDocCard() {
        try {
            store.dispatch(setLoaderModalData({
                data: {
                    content: "Загрузка...",
                    disableButton: true,
                    fullBackground: false,
                    gif: "documents",
                    key: "ContextMenuMainTableTr113"
                }
            }));
            await this.props.createDocumentCards({
                IdDoc: this.props.contextMenu.data.tr.Version.IdDoc,
                IdVersion: this.props.contextMenu.data.tr.Version.Id,
                isRightClick: true,
            });
            await this.props.updateUserSettings({
                Name: UserSettingsNames.DOCUMENT_CONFIG,
                Action: UserSettingsActionsTypes.Update,
                Data: {
                    IdDoc: this.props.contextMenu.data.tr.Version.IdDoc,
                    IdVersion: this.props.contextMenu.data.tr.Version.Id,
                    Name: `${this.props.contextMenu.data.tr.Info.Name} ${this.props.contextMenu.data.tr.Info.ShortDescription}`
                }
            });

            this.props.setContextMenuData({});
        } finally {
            this.props.setLoaderModalData({keyDeleted: "ContextMenuMainTableTr254"});
            let res = await API.documents().getContentForCurrentFile({
                IdDoc: this.props.contextMenu.data.tr.Version.IdDoc,
                IdVersion: this.props.contextMenu.data.tr.Version.Id,
            })
            if (res?.errorCode) {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {
                        content: `Ошибка открытия: файл поврежден`, type: "fail"
                    }
                });
            }
        }
    }

    isDocumentApp() {
        return !this.props.contextMenu.data?.itemData
    }


    search(){
        // this.props.setModalData({
        //     name: ModalTypes.app.alert,
        //     data: {content: "Загрузка документов...", disableButton: true, fullBackground: false, gif: "documents"}
        // })
        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка документов...", disableButton : true, fullBackground : false, gif : "documents", key : "ContextMenuMainTableTr284" }
        }));
        if (this.isDocumentApp()) {
            this.props.changeDocumentViewMode({
                ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly
            });

            this.props.setStateLeftAsideMenu({
                isClose: false,
                Next: {
                    action: () => {
                        this.props.changeViewMode({
                            isSearch: true,
                            Next: {
                                action: () => {
                                    setTimeout(() => { // TODO: исправить этот кастыль
                                        this.props.changeDocumentSearchInputsContextMenu({
                                            Attribute: this.props.contextMenu.data.item,
                                            Value: this.props.contextMenu.data.item.Value,
                                            isConstant: this.props.contextMenu.data.item.IsConstant,
                                            Next: {
                                                action: () => {
                                                    this.props.mainTableSearchDoc({
                                                        Next: {
                                                            action: () => {
                                                                this.props.combineMainTableAttributes({
                                                                    Next: {
                                                                        action: () => {
                                                                            // this.props.setModalData({});
                                                                            //Новый прелоадер
                                                                            this.props.setLoaderModalData({keyDeleted: "ContextMenuMainTableTr306"});
                                                                        },
                                                                        params: {}
                                                                    }})
                                                            },
                                                            params: {}
                                                        }
                                                    });
                                                },
                                                params: {}
                                            }
                                        });
                                    }, 50);
                                },
                                params: {}
                            }
                        })
                        this.props.setContextMenuData({});
                    },
                    params: {}
                }
            });
        } else {
            // TODO КН 02.05.2024 этот else не лолжен впринципе когда отработать, т.к. если это this.isDocumentApp() true, значит вызов компоненты был из пакетов. А при таком раскладе location !== /documents, а ф-ия (this.search()) не вызовется никогда при location !== /documents
            this.props.changePackagesViewMode({
                ViewMode: RootComponentsStateViewModeTypes.PackagesApp.Search
            })
            const itemData = this.props.contextMenu.data?.itemData
            // TODO КН 02.05.2024 Допленение к todo выше того же числа. Мб тут было пытались сделать логику для доп таблицы документов в пакетах
            const searchInputs = store.getState().packages.search.searchInputs
            const RegDateAsString = itemData.Name === "RegDate"
                ? this.props.contextMenu.data?.itemData.Value.split("T")[0].split("-").reverse().join(".")
                : searchInputs.RegDateAsString
            const ModifyDate = itemData.Name === "Version.ModifyTime"
                ? this.props.contextMenu.data?.itemData.Value.split("T")[0].split("-").reverse().join(".")
                : searchInputs.ModifyDate
            const RegNumber = itemData.Name === "RegNumber"
                ? this.props.contextMenu.data?.itemData.Value
                : searchInputs.RegNumber
            const Name = itemData.Name === "Name"
                ? this.props.contextMenu.data?.itemData.Value
                : searchInputs.Name
            const TypeName = itemData.Name === "TypeName"
                ? this.props.contextMenu.data?.itemData.Value
                : searchInputs.TypeName

            const lActiveNode = this.props.contextMenu.data?.item.Node.IdPackageDocRef
            this.props.setStateLeftAsideMenu({
                isClose: false,
                Next: {
                    action: () => {
                        setTimeout(() => { // TODO: исправить этот кастыль
                            this.props.updateInputsFieldPackage({
                                TableRole: "main",
                                RegDateAsString,
                                RegNumber,
                                Name,
                                TypeName,
                                ModifyDate,
                                Next: {
                                    action: () => {
                                        this.props.getDocumentsForTreePackages({
                                            SearchByFilters: true,
                                            Next: {
                                                action: () => {
                                                    this.props.handlerSearchPackages({
                                                        Next: {
                                                            action: () => {
                                                                this.props.SelectActivePackageNode({
                                                                    Id: lActiveNode,
                                                                    contextSearch: true,
                                                                    TableType: "main",
                                                                })
                                                            },
                                                            params: {}
                                                        }
                                                    })
                                                },
                                                params: {}
                                            }
                                        })
                                    },
                                    params: {}
                                }
                            });
                        }, 50);
                        this.props.setContextMenuData({});
                    },
                    params: {}
                }
            });
            this.props.changeViewFilter({
                Type: "documents",
                TableType: this.props.tableRole,
                lStoreValue: PackagesViewFilters.OnlyDocuments
            });
            // this.props.setModalData({});
            //Новый прелоадер
            this.props.setLoaderModalData({keyDeleted: "ContextMenuMainTableTr397"});
        }
    }


    render() {

        let contextStyle = {
            position : "fixed",
            top :  this.props.contextMenu.position.y,
            left :  this.props.contextMenu.position.x
        };

        let isMessengerEnable = Number(this.props.isMessengerEnable)
        let allUsers = this.props.allUsers
        const location = store.getState().router.location.pathname

        return (
            <nav id="context-menu" className="context-menu" style={contextStyle} onContextMenu={(event) => {event.preventDefault()}}
            onMouseEnter={(event) => {this.setState({isHover : true})}}
            onMouseLeave={(event) => {this.setState({isHover : false})}}>
                <ul className="context-menu__items">
                    { isMessengerEnable===1 && allUsers &&
                        <li className="context-menu__item"
                            onClick={this.showShareModal}
                            style={{cursor : "pointer"}}
                        >
                        <span className="context-menu__link">
                            <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-3">
                               <i className="bi bi-reply-fill svg-icon svg-icon-sm text-primary"></i>
                            </span>
                            Поделиться
                        </span>
                        </li>
                    }
                    <li className="context-menu__item"
                        onClick={() => {

                            const lIdDoc = this.props.contextMenu.data?.tr?.Info?.Id ?? ( this.props.contextMenu.data?.tr?.info?.Id ??
                                (this.props.contextMenu.data?.item?.Type == "PackagesDocument" ? (
                                    this.props.contextMenu.data?.item?.Node?.Id ?? null)  : null)
                            );
                            const lIdPack =(this.props.contextMenu.data?.item?.Type != "PackagesDocument") ? this.props.contextMenu.data?.item?.Node?.Id : null;

                            if (lIdDoc || lIdPack)
                            {
                                __.copyUrl(lIdDoc, lIdPack);
                                __.showModalCopyComplete(this.props.setModalData);
                            }
                            else {
                                __.copyUrl();
                                __.showModalCopyComplete(this.props.setModalData);
                            }
                            this.props.setContextMenuData({});}}
                        style={{cursor : "pointer"}}>
                        <span className="context-menu__link">
                            <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-3">
                                <i className="svg-icon svg-icon-sm text-primary icon-Doc_view_14"> </i>
                            </span>
                            Скопировать ссылку
                        </span>
                    </li>
                    {this.lFlagCopyUrl1C &&
                        <li className="context-menu__item"
                            onClick={() => {
                                const lIdDoc = this.props.contextMenu.data?.tr?.Info?.Id ?? ( this.props.contextMenu.data?.tr?.info?.Id ??
                                    (this.props.contextMenu.data?.item?.Type == "PackagesDocument" ? (
                                        this.props.contextMenu.data?.item?.Node?.Id ?? null)  : null)
                                );
                                const lIdPack =(this.props.contextMenu.data?.item?.Type != "PackagesDocument") ? this.props.contextMenu.data?.item?.Node?.Id : null;

                                if (lIdDoc || lIdPack) {
                                    __.copyUrl1c(lIdDoc, lIdPack);
                                    __.showModalCopyComplete(this.props.setModalData);
                                } else {
                                    __.copyUrl1c();
                                    __.showModalCopyComplete(this.props.setModalData);
                                }
                                this.props.setContextMenuData({});}}
                            style={{cursor : "pointer"}}>
                            <span className="context-menu__link">
                                <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-3">
                                    <i className="svg-icon svg-icon-sm text-primary icon-c_16"> </i>
                                </span>
                                Скопировать ссылку для 1С
                            </span>
                        </li>
                    }
                    {
                        location === '/documents'  &&
                        <>
                            <li className="context-menu__item" onClick={this.copy} style={{cursor : "pointer"}}>
                                <span className="context-menu__link">
                                    <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-3">
                                        <i className="svg-icon svg-icon-sm text-primary icon-Doc_view_14"> </i>
                                    </span>
                                    Скопировать содержимое
                                </span>
                            </li>
                            <li className="context-menu__item" onClick={this.search} style={{cursor : "pointer"}}>
                                <span className="context-menu__link">
                                    <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-3">
                                        <i className="svg-icon svg-icon-sm text-primary icon-Search_menu"> </i>
                                    </span>
                                    Фильтр по значению
                                </span>
                            </li>
                            <li className="context-menu__item" onClick={this.addDocCard} style={{cursor : "pointer"}}>
                                <span className="context-menu__link">
                                    <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-3">
                                        <i className="svg-icon svg-icon-sm text-primary icon-Table_edit_3"> </i>
                                    </span>
                                    Карточка документа
                                </span>
                            </li>
                        </>
                    }
                    {
                        location === '/packages' &&
                        this.props.contextMenu?.data?.item.Type == "PackagesDocument" && (
                            <li className="context-menu__item" onClick={this.addDocCardFromPackages} style={{cursor: "pointer"}}>
                                <span className="context-menu__link">
                                    <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-3">
                                        <i className="svg-icon svg-icon-sm text-primary icon-Table_edit_3"> </i>
                                    </span>
                                    Карточка документа
                                </span>
                            </li>
                        )
                    }
                    {
                        location === '/search' && (
                            <li className="context-menu__item" onClick={this.addDocCardFromSearch} style={{cursor: "pointer"}}>
                                <span className="context-menu__link">
                                    <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-3">
                                        <i className="svg-icon svg-icon-sm text-primary icon-Table_edit_3"> </i>
                                    </span>
                                    Карточка документа
                                </span>
                            </li>
                        )
                    }
                </ul>
            </nav>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeFilialId: state.globalState.filial.Active.Id,
        contextMenu: state.globalState.app.contextMenu,
        globalSettings: state.globalState.settings.Content,
        isMessengerEnable: state.globalState.settings.Content.WithMessenger,
        allUsers: state.globalState.AllUsers,
        verifyDocs: state.document.verifyDocs,
    }
}

const mapDispatchToProps = {
    changeDocumentSearchInputsContextMenu,
    changeDocumentViewMode,
    changePackagesViewMode,
    changeViewFilter,
    changeViewMode,
    combineMainTableAttributes,
    createDocumentCards,
    handlerSearchPackages,
    mainTableSearchDoc,
    updateInputsFieldPackage,
    updateUserSettings,
    getDocumentsForTreePackages,
    selectActiveDocumentCard,
    SelectActivePackageNode,
    setContextMenuData,
    setStateLeftAsideMenu,
    setModalData,
    setLoaderModalData,
    treeSelectNode,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenuMainTableTr);
