import React from "react";
import {connect} from "react-redux";
import {treeSearchPackage} from "../../../store/packages/actionCreators/package_TreeSearchActionCreator";
import {RootComponentsStateViewModeTypes, TableTypesEnum} from "../../../tools/StaticTypes";
import {changePackagesDownloadViewMode} from "../../../store/packages/actionCreators/package_RootStateActionCreator";
import {
    breadcrumbsAdd,
    getDocumentsForTreePackages,
    getPackageGroupsForTree, GetPackageTypes, PackageCreateTree, SelectActivePackageNode
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {handlerIsSearch} from "../../../store/packages/actionCreators/package_InputActionCreator";
import {store} from "../../../index";
import {ActionQueue} from "../../../store/rootReducer";



class TreePackageSearchDownload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            TreeSearch : "", // текст для поиска
            clearHover : false,
        };

        this.SearchSubmit = this.SearchSubmit.bind(this);
        this.inputOnChange = this.inputOnChange.bind(this);
        this.startSearch = this.startSearch.bind(this);
        this.clearInput = this.clearInput.bind(this);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevState.TreeSearch !== this.state.TreeSearch) && this.state.TreeSearch === "" ) {
            this.clearInput()
        }
    }

    componentDidMount() {
        const sortedParams = store.getState().packages.treeDownload.SortedParams

        let FieldSorting, Direction;
        for (const [key, value] of Object.entries(sortedParams)) {
            if (value) {
                FieldSorting = key
                Direction = value
                break;
            }
        }

        if(!this.props.tree_render) {
            this.props.ActionQueue({
                List : [
                    {action : this.props.getPackageGroupsForTree, params : {
                            FieldSorting,
                            Direction,
                            IdPackageType : 1,
                            IdParent : 1,
                            IsDownload : true,
                        }, name : "getPackageGroupsForTree"},
                    {action : this.props.getDocumentsForTreePackages, params : {
                            FieldSorting,
                            Direction,
                            Id : 1,
                            IsDownload : true,
                        }, name : "getDocumentsForTreePackages"},
                    {action : this.props.GetPackageTypes, params : {
                            idPackage : "1",
                            IsDownload : true,
                        }, name : "GetPackageTypes"},
                    {action : this.props.PackageCreateTree, params : {IsDownload : true,}, name : "PackageCreateTree"},
                    {action : this.props.SelectActivePackageNode, params : {
                            Id : 1,
                            IsDownload : true,
                        }, name : "SelectActivePackageNode"},
                    {action : this.props.breadcrumbsAdd, params : {
                            NodeId : 1,
                            Name : "Пакеты Загрузки",
                            ParentId : null,
                            IsDownload : true
                        }, name : "breadcrumbsAdd"},
               ],
                debug : false,
            });
        }
    }

    SearchSubmit(event) {
        event.preventDefault();
        this.startSearch(this.props.value);
    }

    inputOnChange(event) {
        this.setState((state) => {
            this.startSearch(event.target.value);
            return { TreeSearch: event.target.value };
        });
        this.props.changeValue(event.target.value)
    }

    startSearch(SearchText) {
        if (SearchText.length > 0) {
            this.props.treeSearchPackage({
                Input: SearchText,
                IsDownload: true,
                TableType: TableTypesEnum.Downoload,

                Next: {
                    action: () => {
                        if (this.state.TreeSearch !== "")
                            this.props.changePackagesDownloadViewMode({
                                ViewMode : RootComponentsStateViewModeTypes.PackagesApp.Tree_Search_Download
                            })
                    },
                    params: {}
                }
            });
        }
    }

    // сбрасываем поле ввода
    clearInput() {
        this.startSearch("");
        this.props.changeValue("");
        if(this.props.isSearch) {
            this.props.handlerIsSearch({TableType: "download", value: false});
        }
        this.props.changePackagesDownloadViewMode({
            ViewMode : RootComponentsStateViewModeTypes.PackagesApp.Tree_Download
        })
    }

    render() {

        let searchIcon = "icon-Search ";
        if(this.state.clearHover) { // для отображения красного крестика
            searchIcon = "icon-Delete icon-color-red";
        }


        return(
            <div  style={{margin: "10px 10px"}}>
                <div className="input-icon h-40px">
                    <form onSubmit={this.SearchSubmit}>
                        <input type="text"
                               className="form-control form-control-lg form-control-solid h-40px"
                               placeholder="Поиск"
                               value={this.props.value}
                               onChange={this.inputOnChange}
                               onKeyDown={(event)=>{
                                    if(event.code  === "Backspace" && this.props.value.length <= 2) {
                                    this.clearInput()
                            }
                        }}/>
                    </form>
                    <span>
                        <span className="svg-icon svg-icon-lg" style={{cursor : "pointer"}}
                              onMouseEnter={()=>{this.setState({clearHover : true})}}
                              onMouseLeave={()=>{this.setState({clearHover : false})}}
                              onClick={this.clearInput}>
                            <i className={"svg-icon svg-icon-lg " + searchIcon}/>
                        </span>
                    </span>
                </div>
            </div>
        );
    }
}


const  mapStateToProps = state => {
    return {
        tree_render : state.packages.treeDownload.render,
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    treeSearchPackage,
    changePackagesDownloadViewMode,
    handlerIsSearch,
    getPackageGroupsForTree,
    getDocumentsForTreePackages,
    GetPackageTypes,
    PackageCreateTree,
    SelectActivePackageNode,
    breadcrumbsAdd,
}


export default connect(mapStateToProps, mapDispatchToProps)(TreePackageSearchDownload);
