import React from 'react';
import {HelpFunctions} from "../../tools/HelpFunctions";
import FileNameWithEllipsis from "../FileNameWithEllipsis";
import ImagePreview from "./ImagePreview";

class ImagePreviewWithLabel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileBlob: null,
        }
        this.onClickFile = this.onClickFile.bind(this)
    }

    async onClickFile() {
        const fileObj = this.props.fileObj
        const blob = this.state.fileBlob || await HelpFunctions.blobFromJSONString(fileObj.fileAsJSON)
        HelpFunctions.onClickFile(blob, fileObj.fileName)
    }

    render() {
        return (
            <>
                <ImagePreview fileObj={this.props.fileObj} setFileBlob={fileBlob=>this.setState({setFileBlob: fileBlob})}/>
                <div className='d-flex position-absolute w-100 bottom-0 px-1 py-1 overflow-hidden text-overflow-ellipsis'
                     style={{color:'white', background: 'rgba(0,0,0,0.6)'}}
                >
                    <div className="d-flex flex-grow-1 overflow-hidden mx-1">
                        <FileNameWithEllipsis fileName={this.props.fileObj.fileName} />
                    </div>
                    <div onClick={() => this.onClickFile()} title={`Загрузить ${this.props.fileObj.fileName}`}
                         className="btn btn-icon btn-xs" style={{marginRight : "2px"}}
                    >
                        <i className="svg-icon icon-Doc_view_4 icon-1x icon-color-white"/>
                    </div>
                </div>
            </>
        )
    }
}
export default ImagePreviewWithLabel;
