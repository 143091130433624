import React from "react";
import {connect} from "react-redux";
import {ModalTypes, Operators, RootComponentsStateViewModeTypes} from "../../../tools/StaticTypes";
import {
    addModalData, deleteModalData,
    setLoaderModalData,
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {apiRequest} from "../../../tools/API/apiRequest";
import {apiUrl} from "../../../tools/API/apiUrl";
import {__} from "../../../tools/HelpFunctions";
import {
    combineMainTableAttributes,
    mainTableDownloadDocCollection
} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {Routing} from "../../../tools/routing/Routing";
import {history, store} from "../../../index";
import {EntityFilters, Filter, FiltersType} from "../../../tools/Filters";
import {changeDocumentViewMode} from "../../../store/documents/actionCreators/document_RootStateActionCreator";
import {DataTypesEnum} from "../../../tools/API_NEW/ServerTypes";
import {API} from "../../../tools/API_NEW/API";
import AttributeWithDictionaries from "../../additionalDataSection/docRefsComponents/AttributeWithDictionaries";
import {MySelectNew} from "../../tools/MySelect/MySelectNew";
import MultipleDocRef from "../../additionalDataSection/docRefsComponents/MultipleDocRef";
import DocRef from "../../additionalDataSection/docRefsComponents/DocRef";
import MultipleDocVersionRef from "../../additionalDataSection/docRefsComponents/MultipleDocVersionRef";
import DocVersionRef from "../../additionalDataSection/docRefsComponents/DocVersionRef";
import MultipleAttributeTable from "../../tools/MultipleAttributeTable/MultipleAttributeTable";
import JsonObjectFragment from "../../root/JsonObjectFragment";
import {DOCUMENT_VERIFY} from "../../../store/documents/documentActionsList";

const animatedComponents = makeAnimated();

const sbSelect = {
    menuList: (base) => ({
        ...base,

        "::-webkit-scrollbar": {
            width: "5px",
            height: "5px",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
        },

        "::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            /*background-color: rgb(239 137 137 / 20%);*/
            backgroundColor: "var(--background-colorBS)",
        },

        "::-webkit-scrollbar-track": {
            borderRadius: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.35)",
        }
    })
}
const createSelectStyles = {
    input: (css, state) => ({
        ...css,
        width: "100%",
        "> div": {
            width: "100%"
        },
        input: {
            width: `100% !important`,
            textAlign: "left"
        }
    })
}

const getAttributeComponent = (
    {
        attributeData,
        disabled,
        // TODO КН КОГДА ПЕРЕНОСИЛ КОД Из newDocumentByVersion, функции ниже были прописаны через this, и чтобы вынести из компоненты функцию пока так их прокидываю
        externalDictionariesValues,
        changeAttributeValue,
        renderJsonObjectFragment,
        addMultiplyRowHandler,
        deleteMultiplyRowHandler
    }) => {
    const isMultipleAllowed = attributeData.IsMultipleAllowed
    const isRequired = attributeData.IsRequired
    const attributeType = attributeData.AttributeName.IdAttributeType
    if (attributeData.AttributeName.IdDictionaries) {
        return (
            <AttributeWithDictionaries
                hardDisabledEditing={disabled}
                attributeValue={attributeData.AttributeValue}
                idDictionaryValue={attributeData.IdDictionaryValue}
                idDictionaries={attributeData.AttributeName.IdDictionaries}
                dictionaryFieldName={attributeData.AttributeName.DictionaryFieldName}
                externalDictionariesValues={externalDictionariesValues}
                isRedactorMode={true}
                isMultiply={isMultipleAllowed}
                setNewValues={(newValues) => {
                    changeAttributeValue({
                        id: attributeData.AttributeName.Id,
                        value: newValues,
                        isRequired: isRequired,
                        isDictionary: true
                    })
                }}
            />
        )
    } else if (attributeType === DataTypesEnum.Integer) {
        if (isMultipleAllowed) {
            return (
                <MySelectNew
                    hardDisabledEditing={disabled}
                    returnFunc={(list) => {
                        changeAttributeValue({
                            id: attributeData.AttributeName.Id,
                            value: list.slice(),
                            isRequired: isRequired
                        })
                    }}
                    dataList={__.getCorrectValueForMultiply(attributeData.AttributeValue)}
                    type={attributeType}
                    placeholder={`${attributeData.AttributeName.Name}...`}
                    isMulti={true}
                    // стили для отображения контекстного меню браузера как текстового поля (копировать/вставить и т.д.)
                    styles={createSelectStyles}
                    className={''}
                />
            )
        } else {
            return (
                <input
                    className="form-control"
                    value={attributeData.AttributeValue || ''}
                    type={'number'}
                    step={'any'}
                    disabled={disabled}
                    onChange={(e) => changeAttributeValue({
                        id: attributeData.AttributeName.Id,
                        value: e.target.value,
                        isRequired: isRequired
                    })}/>

            )
        }
    } else if (attributeType === DataTypesEnum.Float) {
        if (isMultipleAllowed) {
            return (
                <MySelectNew
                    hardDisabledEditing={disabled}
                    returnFunc={(list) => {
                        changeAttributeValue({
                            id: attributeData.AttributeName.Id,
                            value: list.slice(),
                            isRequired: isRequired
                        })
                    }
                    }
                    dataList={__.getCorrectValueForMultiply(attributeData.AttributeValue)}
                    type={attributeType}
                    placeholder={`${attributeData.AttributeName.Name}...`}
                    isMulti={true}
                    // стили для отображения контекстного меню браузера как текстового поля (копировать/вставить и т.д.)
                    styles={createSelectStyles}
                    className={''}
                />
            )
        } else {
            return (
                <input
                    className="form-control"
                    value={attributeData.AttributeValue || ''}
                    type={'number'}
                    step={'any'}
                    disabled={disabled}
                    onChange={(e) => changeAttributeValue({
                        id: attributeData.AttributeName.Id,
                        value: e.target.value,
                        isRequired: isRequired
                    })}/>

            )
        }
    } else if (attributeType === DataTypesEnum.String) {
        if (isMultipleAllowed) {
            return (
                <MySelectNew
                    hardDisabledEditing={disabled}
                    returnFunc={(list) => {
                        changeAttributeValue({
                            id: attributeData.AttributeName.Id,
                            value: list.slice(),
                            isRequired: isRequired
                        })
                    }}
                    type={attributeType}
                    placeholder={`${attributeData.AttributeName.Name}...`}
                    dataList={__.getCorrectValueForMultiply(attributeData.AttributeValue)}
                    isMulti={true}
                    styles={createSelectStyles}
                    className={''}
                />
            )
        } else {
            return (
                <input
                    className="form-control"
                    value={attributeData.AttributeValue || ''}
                    disabled={disabled}
                    onChange={(e) => {
                        changeAttributeValue({
                            id: attributeData.AttributeName.Id,
                            value: e.target.value,
                            isRequired: isRequired
                        })
                    }}/>

            )
        }
    } else if (attributeType === DataTypesEnum.Boolean) {
        // TODO Не представляю как может случиться if (isMultipleAllowed) {}
        return (
            <input
                className="form-control"
                value={attributeData.AttributeValue || ''}
                type={'checkbox'}
                disabled={disabled}
                onChange={(e) => changeAttributeValue({
                    id: attributeData.AttributeName.Id,
                    value: e.target.value,
                    isRequired: isRequired
                })}
            />
        )
    } else if (attributeType === DataTypesEnum.DataTime) {
        if (isMultipleAllowed) {
            return (
                <MySelectNew
                    hardDisabledEditing={disabled}
                    returnFunc={(list) => {
                        changeAttributeValue({
                            id: attributeData.AttributeName.Id,
                            value: list.slice(),
                            isRequired: isRequired
                        })
                    }}
                    type={attributeType}
                    placeholder={`${attributeData.AttributeName.Name}...`}
                    dataList={__.getCorrectValueForMultiply(attributeData.AttributeValue)}
                    isMulti={true}
                    styles={createSelectStyles}
                    className={''}
                    duplicateCallback={()=> {
                        store.dispatch(addModalData({
                            name: ModalTypes.app.alert,
                            data: {
                                content: "Новое значение не уникально для атрибута!",
                                disableButton: false
                            }
                        }))
                    }}
                />
            )
        } else {
            return (
                <input
                    className="form-control"
                    type={'date'}
                    min="1900-01-01"
                    max="3000-12-31"
                    value={(attributeData.AttributeValue && __.dateToServer(attributeData.AttributeValue, false, true)) || ''}
                    disabled={disabled}
                    onChange={(e) => {
                        changeAttributeValue({
                            id: attributeData.AttributeName.Id,
                            value: __.getCorrectDate(e.target.value),
                            isRequired: isRequired
                        })
                    }}/>
            )
        }
    } else if (attributeType === DataTypesEnum.DocRef) {
        if (isMultipleAllowed) {
            return (
                <MultipleDocRef
                    hardDisabledEditing={disabled}
                    item={attributeData}
                    valueFieldName={'AttributeValue'}
                    isRedactorMode={true}
                    setNewItemValue={newValueOfItem => {
                        changeAttributeValue({
                            id: attributeData.AttributeName.Id,
                            value: newValueOfItem,
                            isRequired: isRequired
                        })
                    }}
                    duplicateCallback={()=> {
                        store.dispatch(addModalData({
                            name: ModalTypes.app.alert,
                            data: {
                                content: "Новое значение не уникально для атрибута!",
                                disableButton: false,
                            }
                        }))
                    }}
                />
            )
        } else {
            return (
                <DocRef
                    docIdValue={attributeData.AttributeValue}
                    shortDescription={attributeData.ShortDescription}
                    isRedactorMode={true}
                    hardDisabledEditing={disabled}
                    setCurrentDocIdValue={(value)=>{
                        changeAttributeValue({
                            id: attributeData.AttributeName.Id,
                            value: value,
                            isRequired: isRequired
                        })}
                    }
                />
            )
        }
    } else if (attributeType === DataTypesEnum.DocVersionRef) {
        if (isMultipleAllowed) {
            return (
                <MultipleDocVersionRef
                    item={attributeData}
                    valueFieldName={'AttributeValue'}
                    isRedactorMode={true}
                    hardDisabledEditing={disabled}
                    setNewItemValue={newValueOfItem => {
                        changeAttributeValue({
                            id: attributeData.AttributeName.Id,
                            value: newValueOfItem,
                            isRequired: isRequired
                        })
                    }}
                    duplicateCallback={()=> {
                        store.dispatch(addModalData({
                            name: ModalTypes.app.alert,
                            data: {
                                content: "Новое значение не уникально для атрибута!",
                                disableButton: false
                            }
                        }))
                    }}
                />
            )
        } else {
            return (
                <DocVersionRef
                    hardDisabledEditing={disabled}
                    versionIdValue={attributeData.AttributeValue}
                    shortDescription={attributeData.ShortDescription}
                    isRedactorMode={true}
                    setCurrentVersionValue={(value)=> {
                        changeAttributeValue({
                            id: attributeData.AttributeName.Id,
                            value: value,
                            isRequired: isRequired
                        })
                    }
                    }
                />
            )
        }
    } else if (attributeType === DataTypesEnum.JsonObject) {
        if (isMultipleAllowed) {
            return (
                <MultipleAttributeTable
                    hardDisabledEditing={disabled}
                    deleteRow={(lIndex) => deleteMultiplyRowHandler(lIndex, attributeData)}
                    addRow={(lIndex) => addMultiplyRowHandler(lIndex, attributeData)}
                    item={{
                        ...attributeData,
                        Value: __.getCorrectValueForMultiply(attributeData.AttributeValue) || []
                    }}
                    contents={(item, rowId) => [{
                        content: renderJsonObjectFragment(item, rowId, true, true, disabled),
                        style: {width: "100%"}
                    }]}
                />
            )
        } else {
            return (
                renderJsonObjectFragment(attributeData, null, false, true, disabled)
            )
        }
    } else {
        // DataTypesEnum.RowBlob и DataTypesEnum.SearchBlob - вроде @deprecated
        return null
    }
}

class ChangeDocumentTypeModalOriginal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sortingTypesName: this.props.allTypes.filter(group => group.TypeAttributes.length > 0 && group.Id !== this.props.activeDocument.Type.Id)
                .map(group => ({
                    Attributes: group.TypeAttributes,
                    label: group.Name,
                    value: group.Id,
                })),
            targetDataType: '',
            newDocument: {
                unifiedDocAttributes: [],
                docHeader: {
                    Id: this.props.activeDocument.Info.Id,
                    IdFilial: this.props.activeDocument.Info.IdFilial,
                    Name: this.props.activeDocument.Info.Name,
                    RegDate: this.props.activeDocument.Info.RegDate,
                    RegNumber: this.props.activeDocument.Info.RegNumber,
                    Type: {
                        Id: null,
                        Name: null,
                    }
                },
            },
            oldDocument: {
                Info: this.props.activeDocument.Info,
                unifiedDocAttributes: __.convertAttributesArrayToUnique(
                    __.transformAttributesData({
                        attributesData: this.props.activeDocument.Info.Attributes,
                        docTypeId: this.props.activeDocument.Type.Id,
                        newValueFieldName: 'AttributeValue',
                        needAllAttributesOnType: true
                    }),
                    'AttributeValue'
                )
            },
            ActiveIdFilial : null,
            SelectedGroups: [],
            LoadGroups: store.getState().document.tree.group,
            ActiveType : null,

            countEmptyRequiredFields: [],
        };

        this.hide = this.hide.bind(this);
        this.setTargetDataType = this.setTargetDataType.bind(this);
        this.changeAttributeValue = this.changeAttributeValue.bind(this);
        this.postNewDocumentType = this.postNewDocumentType.bind(this);
        this.changeName = this.changeName.bind(this);
        this.changeRegNumber = this.changeRegNumber.bind(this);
        this.changeRegDate = this.changeRegDate.bind(this);

        this.checkRequired = this.checkRequired.bind(this);

        this.renderJsonObjectFragment = this.renderJsonObjectFragment.bind(this);
        this.deleteMultiplyRowHandler = this.deleteMultiplyRowHandler.bind(this);
        this.addMultiplyRowHandler = this.addMultiplyRowHandler.bind(this);
    }

    // скрывать модалку
    hide() {
        this.props.deleteModalData({
            modalId: this.props.modal.id
        });
    }

    async setTargetDataType(type) {
        let findType = this.props.allTypes.find(item => item.Id === type.value)
        const attributesWithExternalDictionaries = findType.TypeAttributes.filter(item => item.AttributeName.IdDictionaries).map(item => item.AttributeName.IdDictionaries)
        if (attributesWithExternalDictionaries.length > 0) {
            store.dispatch(setLoaderModalData({
                data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents", key : "ChangeDocumentTypeModalOriginal467" }
            }));
            const externalDictionariesValues = await API.dictionary().getDictionaryValues({
                filter : new Filter(FiltersType.ENTITY).add(
                    new EntityFilters().setEntityName("DictionaryValue").add({
                        Name : "IdDictionaries",
                        Value : attributesWithExternalDictionaries,
                        Operator: Operators.In
                    }).get()
                ),
                sorting: null,
            });
            store.dispatch(setLoaderModalData({keyDeleted: "ChangeDocumentTypeModalOriginal479"}))
            if (externalDictionariesValues.errorCode) {
                this.props.addModalData({
                    name: ModalTypes.app.info,
                    data: {
                        content: externalDictionariesValues.message.Message,
                        disableButton: false,
                        type: "fail"
                    }
                })
                return;
            }
            this.setState({
                externalDictionariesValues,
            })
        }

        const adjustedOldAttributes = __.excludeAttributesDifferInMultiplyAllowed({
            attributes: this.state.oldDocument.Info.Attributes,
            firstTypeId: this.state.oldDocument.Info.Type.Id,
            secondTypeId: findType.Id,
        })

        let unifiedDocAttributes = __.convertAttributesArrayToUnique(
            __.transformAttributesData({
                attributesData: adjustedOldAttributes,
                docTypeId: findType.Id,
                newValueFieldName: 'AttributeValue',
                needAllAttributesOnType: true
            }),
            'AttributeValue'
        )

        const newValueOfUpdatedDocumentAttributes = {
            ...this.state.newDocument,
            unifiedDocAttributes: unifiedDocAttributes,
            docHeader: {
                ...this.state.newDocument.docHeader,
                Type: {
                    Id: findType.Id,
                    Name: findType.Name
                },
            }
        }

        await this.setState({newDocument: newValueOfUpdatedDocumentAttributes})
        await this.setState({targetDataType: findType})
        await this.setState({ActiveType: findType.Id})
        await this.checkRequired()
    }

    changeName(value){
        const newValueOfUpdatedDocumentAttributes = {
            ...this.state.newDocument,
            docHeader: {
                ...this.state.newDocument.docHeader,
                Name: value
            }
        }
        this.setState({newDocument: newValueOfUpdatedDocumentAttributes})
    }

    changeRegNumber(value){
        const newValueOfUpdatedDocumentAttributes = {
            ...this.state.newDocument,
            docHeader: {
                ...this.state.newDocument.docHeader,
                RegNumber: value
            }
        }
        this.setState({newDocument: newValueOfUpdatedDocumentAttributes})
    }

    changeRegDate(value){
        const newValueOfUpdatedDocumentAttributes = {
            ...this.state.newDocument,
            docHeader: {
                ...this.state.newDocument.docHeader,
                RegDate: value,
            }
        }
        this.setState({newDocument: newValueOfUpdatedDocumentAttributes})
    }

    changeAttributeValue({id, value, isRequired, isBoolean, isDictionary}) {
        if (isBoolean) {
            if (value) {
                value = "1";
            } else {
                value = "0";
            }
        }
        this.setState({
            newDocument: {
                ...this.state.newDocument,
                unifiedDocAttributes: this.state.newDocument.unifiedDocAttributes.map((item) => {
                    if (item.AttributeName.Id === id) {
                        let newAttributeValue, newIdDictionaryValue, newIdValue
                        if (isDictionary) {
                            newAttributeValue = value.value
                            newIdDictionaryValue = value.idDictionaryValue
                        } else {
                            newAttributeValue = value
                            newIdDictionaryValue = undefined
                        }
                        if (Array.isArray(newAttributeValue)) {
                            const oldIdAsArray = Array.isArray(item.Id) ? item.Id : [item.Id]
                            newIdValue = newAttributeValue.map((v, index) => oldIdAsArray[index])
                        } else {
                            newIdValue = Array.isArray(item.Id) ? item.Id[0] : item.Id // TODO КН на всякий сделал обработчик isArray(item.Id), но по факту не должен он быть массивом когда пришел value не array
                        }
                        /* TODO КН 12.04.24 - Вообще по хорошему еще ShortDescription который в item лежит сетать,
                            но впринципе он нигде в этой компонете пока не используется, поэтому пока можно не делать
                         */
                        return {
                            ...item,
                            Id: newIdValue,
                            AttributeValue: newAttributeValue,
                            IdDictionaryValue: newIdDictionaryValue,
                        }
                    } else return item;
                })
            }
        }, () => {
            if (isRequired) {
                this.checkRequired()
            }
        });
    }

    checkRequired(){
        const emptyRequiredFields = this.state.newDocument.unifiedDocAttributes.filter(el=> el.IsRequired && !el.AttributeValue)
        this.setState({countEmptyRequiredFields: emptyRequiredFields.length})
    }

    async postNewDocumentType(){
        const emptyMultiAttr = this.state.newDocument.unifiedDocAttributes.find(attrData=> Array.isArray(attrData.AttributeValue) && attrData.AttributeValue.some(el => !el || el === ''))
        if (emptyMultiAttr) {
            this.props.addModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content: `Не заполнено поле множественного атрибута "${emptyMultiAttr.AttributeName.Name}"` }
            })
        } else {
            // сохраняем в редакс
            store.dispatch({
                type: DOCUMENT_VERIFY.UPDATE_VERIFY_DOCS, payload: {
                    idForDelete: this.state.newDocument.docHeader.Id
                }
            });
            const docAttributes = [];
            this.state.newDocument.unifiedDocAttributes.forEach(attr => {
                if (Array.isArray(attr.AttributeValue)) {
                    if (attr.AttributeValue.length) {
                        attr.AttributeValue.forEach((value, index) => {
                            let idDictionaryValueForAttr
                            if (attr.AttributeName.IdDictionaries) {
                                const idDictionaryValueAsArray = Array.isArray(attr?.IdDictionaryValue) ? attr?.IdDictionaryValue : [attr?.IdDictionaryValue]
                                idDictionaryValueForAttr = idDictionaryValueAsArray[index];
                            } else {
                                idDictionaryValueForAttr = null
                            }
                            docAttributes.push({
                                Id: attr.Id[index],
                                IdDictionaryValue: idDictionaryValueForAttr ?? null,
                                AttributeName: attr.AttributeName,
                                AttributeValue: value,
                            })
                        })
                    } else {
                        docAttributes.push({
                            Id: undefined,
                            IdDictionaryValue: null,
                            AttributeName: attr.AttributeName,
                            AttributeValue: null,
                        })
                    }
                } else {
                    const isEmptyString = attr.AttributeValue === ''
                    docAttributes.push({
                        Id: attr.Id,
                        IdDictionaryValue: isEmptyString ? null : attr?.IdDictionaryValue,
                        AttributeName: attr.AttributeName,
                        AttributeValue: isEmptyString ? null : attr.AttributeValue,
                    });
                }
            });

            let docAttributesForDelete = []
            const newTypeId = this.state.targetDataType.Id
            const oldTypeId = this.state.oldDocument.Info.Type.Id
            const storeTreeDocs = store.getState().document.tree.docs
            const getAttributeMetaData = (attribute, docTypeId) => {
                const attributeId = attribute.AttributeName.Id // not attribute.Id!!!
                return storeTreeDocs.find(lDocType => lDocType.Id === docTypeId)?.TypeAttributes.find(lAttr => lAttr.AttributeName.Id === attributeId)
            }
            this.state.oldDocument.Info.Attributes.forEach(attribute => {
                if (!docAttributes.find(el => el.AttributeName.Id === attribute.AttributeName.Id && el.Id === attribute.Id)) {
                    const attributeMetaDataForNewTypeId = getAttributeMetaData(attribute, newTypeId)
                    const attributeMetaDataForOldTypeId = getAttributeMetaData(attribute, oldTypeId)
                    if (
                        // Если атрибут есть в новом типе
                        !!attributeMetaDataForNewTypeId
                        // и если атрибут не (был раньше мульти, а стал неМульти)
                        && !(attributeMetaDataForOldTypeId?.IsMultipleAllowed && !attributeMetaDataForNewTypeId?.IsMultipleAllowed)
                    ) {
                        docAttributesForDelete.push(attribute)
                    }
                }
            })
            docAttributesForDelete = docAttributesForDelete.length < 1 ? undefined : docAttributesForDelete
            store.dispatch(setLoaderModalData({
                data: {
                    content: "Загрузка...",
                    disableButton: true,
                    fullBackground: false,
                    gif: "documents",
                    key: "ChangeDocumentTypeModalOriginal698"
                }
            }));
            const closeLoader = () => store.dispatch(setLoaderModalData({keyDeleted: "ChangeDocumentTypeModalOriginal701"}));
            try {
                const IsOldServer = store.getState().globalState.settings.Content?.IsOldServer === "1";
                let requestResult;
                if(IsOldServer) {
                    requestResult = await API.documents().updateDocAttributes({
                        docHeader: {
                            Id: this.state.newDocument.docHeader.Id,
                            IdFilial: this.state.newDocument.docHeader.IdFilial,
                            RegNumber: this.state.newDocument.docHeader.RegNumber,
                            Name: this.state.newDocument.docHeader.Name,
                            Type: this.state.newDocument.docHeader.Type,
                            RegDate: this.state.newDocument.docHeader.RegDate
                        },
                        docAttributes,
                        docAttributesForDelete
                    })
                }else{
                     requestResult = await API.documents().updateDocAttributesEXT({
                        docHeader: {
                            Id: this.state.newDocument.docHeader.Id,
                            IdFilial: this.state.newDocument.docHeader.IdFilial,
                            RegNumber: this.state.newDocument.docHeader.RegNumber,
                            Name: this.state.newDocument.docHeader.Name,
                            Type: this.state.newDocument.docHeader.Type,
                            RegDate: this.state.newDocument.docHeader.RegDate
                        },
                        docAttributes,
                        docAttributesForDelete
                    })
                }

                if (requestResult?.errorCode) {
                    throw (requestResult.message)
                }
                await this.props.mainTableDownloadDocCollection({});
                await this.props.combineMainTableAttributes({});

                let query = this.props.router.location.query;
                let queryLink = Routing.CreateRef({
                    oldQuery: query,
                    newQuery: {},
                    excludedKeys: ["d"]
                });

                if (this.state.SelectedGroups.length > 0) {
                    let collection = [];
                    for (let i = 0; i < this.state.SelectedGroups.length; i++) {
                        collection.push(
                            {
                                Id: this.state.oldDocument.Info.Version.Id,
                                IdDoc: this.state.oldDocument.Info.Version.IdDoc,
                                IdDocGroup: this.state.SelectedGroups[i].value
                            }
                        )
                    }
                    let request = new apiRequest(apiUrl.DOCUMENTS.SAVE_DOC_TREE_SECTION, {
                        collection: collection
                    });
                    await request.execute(() => {});
                }
                let link = `${this.props.router.location.pathname}${queryLink}`;
                await history.push(link);

                this.props.changeDocumentViewMode({
                    ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly
                })
                this.hide()
                closeLoader()
                this.props.addModalData({
                    name: ModalTypes.app.info,
                    data: {type: "success", content: `Тип документа успешно изменён!`}
                });
            } catch (e) {
                closeLoader()
                if (e.status === 401) {
                    this.props.addModalData({
                        name: ModalTypes.app.info,
                        data: {type: "fail", content: `Ошибка помещенния в реестр: <br/>${e}`}
                    });
                } else {
                    this.props.addModalData({
                        name: ModalTypes.app.info,
                        data: {type: "fail", content: e?.responseJSON?.Message ?? e?.Message ?? 'Неизвестная ошибка'}
                    });
                }
            }
        }
    }

    deleteMultiplyRowHandler(lIndex, item) {
        const value = Array.isArray(item.AttributeValue) ? item.AttributeValue : [item.AttributeValue]
        const newValue = [...value.slice(0, lIndex), ...value.slice(lIndex + 1, value.length)];
        this.changeAttributeValue({
            id: item.AttributeName.Id,
            value: newValue,
            isRequired: item.IsRequired
        })
    }

    addMultiplyRowHandler(lIndex, item) {
        const currentValue =  __.getCorrectValueForMultiply(item.AttributeValue)
        let newValue;
        if (currentValue && currentValue.length > 0) {
            if (!((lIndex - 1 >= 0) && (lIndex - 1 < currentValue.length))) {
                const lExtensibleInputValue = __.deepCopy(currentValue);
                lExtensibleInputValue.splice(lIndex - 1, 0, null);
                newValue = lExtensibleInputValue
            }
        } else {
            newValue=[null]
        }
        newValue && this.changeAttributeValue({
            id: item.AttributeName.Id,
            value: newValue,
            isRequired: item.IsRequired
        })
    }
    renderJsonObjectFragment = (item, rowId, IsMultipleAllowed, redactorMode, hardDisabledEditing) => {
        let lInputValue = item?.AttributeValue
        if (rowId) {
            if (item?.AttributeValue && item.AttributeValue.length > 0 && (rowId - 1) >= 0 && (rowId - 1) < item.AttributeValue.length) {
                lInputValue = item.AttributeValue[rowId - 1];
            } else {
                lInputValue = null;
            }
        }
        return <JsonObjectFragment lInputValue={lInputValue}
                                   lAllInputValue={item?.AttributeValue}
                                   isRedactorMode={redactorMode}
                                   rowId={rowId}
                                   item={item}
                                   IsMultipleAllowed
                                   hardDisabledEditing={hardDisabledEditing}
                                   inputHandler={(value, item) => {
                                       this.changeAttributeValue({
                                           id: item.AttributeName.Id,
                                           value: IsMultipleAllowed ? value : value.value,
                                           isRequired: item.IsRequired
                                       })
                                   }}
        />
    }

    render() {
        let modalStyle = {
            display : "block",
            backgroundColor: "#061c3e66",
        };
        let searchIconEndDate = "icon-calendar_search "
        let mismatchAttributes;

        if(this.state.targetDataType){
            mismatchAttributes = this.state?.targetDataType?.TypeAttributes.map(item => item.AttributeName.Name)
        }

        let groupsOptions = [];
        groupsOptions.push({
            value :  null,
            label :  "Не выбрано",
            isDisabled : true,
            groupTypes: []
        });
        for(let i = 0; i < this.state.LoadGroups.length; i++) {
            groupsOptions.push({
                value :  this.state.LoadGroups[i].Id,
                label :  this.state.LoadGroups[i].Name,
                isDisabled : false,
                groupTypes: this.state.LoadGroups[i].GroupTypeRefs,
            });
        }

        // TODO TP №21638,  №21567
        groupsOptions = this.state.ActiveType
            ? groupsOptions.filter(item => item.groupTypes.find(type => type.IdDocType === this.state.ActiveType))
            : groupsOptions

        return (
            <div className="modal fade show" style={modalStyle} onClick={this.hide}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" style={{minWidth: '80vw'}}>
                    <div className="modal-content"
                         onClick={(e) => e.stopPropagation()}
                    >
                        <div className="modal-header" style={{padding: "1rem 1rem", borderBottom: "none"}}>
                            <h5 className="modal-title text-success font-weight-bolder ">
                                Подтвердите перенос документа
                            </h5>
                        </div>
                        <div className="modal-body beautiful-scroll-10" style={{height: "40vw", padding:" 0.8rem 1.75rem"}}>
                            <div className="row">
                                <div className="col-lg-4">
                                    <form className="form">
                                        <div className="form-group mb-4 row">
                                            <div className="col-lg-12">
                                                <label>
                                                    <span className="label label-inline label-light-warning font-weight-bolder">
                                                        Внимание!
                                                    </span>
                                                    Вы переносите документ:
                                                </label>
                                                <input
                                                  type="email"
                                                  className="form-control"
                                                  value={this.state.oldDocument.Info.Name}
                                                  disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group mb-4 row">
                                            <div className="col-lg-12">
                                                <label>Текущий тип документа: </label>
                                                <input
                                                  type="email"
                                                  className="form-control"
                                                  placeholder={this.state.oldDocument.Info.Type.Name}
                                                  disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group mb-4 row">
                                            <div className="col-lg-12">
                                                <label>Новый тип документа: </label>
                                                    <Select
                                                      components={animatedComponents}
                                                      onChange={(item) => {this.setTargetDataType(item)}}
                                                      options={this.state.sortingTypesName}
                                                      placeholder="Выберите тип"
                                                      title="Из папки"
                                                      theme={(theme) => ({
                                                          ...theme,
                                                          colors: {
                                                              ...theme.colors,
                                                              text: 'var(--hover-primary)',
                                                              primary25: 'var(--light-primary)',
                                                              primary50: 'var(--hover-primary)',
                                                              primary: 'var(--originaltwo)',
                                                          },
                                                      })}
                                                    />
                                            </div>
                                        </div>

                                        <div className="form-group mb-4 row">
                                            <div className="col-lg-12">
                                                <label>Раздел документа: </label>
                                                <Select
                                                    styles={sbSelect}
                                                    placeholder="Выберите раздел документа"
                                                    title="Выберите раздел документа"
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    isMulti
                                                    onChange={(value) => this.setState({SelectedGroups: value})}
                                                    options={this.state.ActiveType ? groupsOptions : []}
                                                    noOptionsMessage={() => "Нет доступных разделов"}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: 'var(--hover-primary)',
                                                            primary25: 'var(--light-primary)',
                                                            primary50: 'var(--hover-primary)',
                                                            primary: 'var(--originaltwo)',
                                                        },
                                                    })}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>Дата <span className="text-danger">*</span></label>
                                            <div className="input-icon h-40px my-2" >
                                                 <span className="svg-icon svg-icon-lg"
                                                       //TODO выяснить зачем эта функция
                                                       // onClick={(event)=>{this.clearInput(event)}}>
                                                       onClick={(event)=>{}}>
                                                     <i id="EndDate" className={`svg-icon svg-icon-lg icon-color-primary ${searchIconEndDate}`}/>
                                                 </span>
                                                 <input type="date" className="form-control"
                                                        min="1900-01-01"
                                                        max="3000-12-31"
                                                        placeholder="Дата 'ПО'"
                                                        title="Дата 'ПО'"
                                                        value={this.state.newDocument.docHeader.RegDate?.split('T')[0]}
                                                        onChange={(e) => {
                                                            let newRegDateValue = e.target.value
                                                            const todayDate = new Date()
                                                            if (todayDate < new Date(newRegDateValue)) {
                                                                newRegDateValue = todayDate.toLocaleDateString().split('.').reverse().join('-')
                                                            }
                                                            newRegDateValue = newRegDateValue + "T00:00:00"
                                                            this.changeRegDate(newRegDateValue)
                                                        }}
                                                        name="dateFinish"
                                                 />
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <label>Номер <span className="text-danger">*</span></label>
                                                    <input type="text"
                                                           className="form-control"
                                                           placeholder="Номер"
                                                           value={this.state.newDocument.docHeader.RegNumber}
                                                           onChange={e => this.changeRegNumber(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>Версия <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" value={this.state.oldDocument.Info.Version.Version} disabled/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group mb-4 row">
                                            <div className="col-lg-12">
                                                <label>Наименование <span className="text-danger">*</span></label>
                                                <div className="input-group">
                                                    <textarea className="form-control"
                                                              onChange={e => this.changeName(e.target.value)}
                                                              value={this.state.newDocument.docHeader.Name}
                                                              placeholder={'Введите наименование'}
                                                              rows="3"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-4">
                                    <p>
                                        <span className="label label-inline label-light-success font-weight-bold">
                                            Текущие
                                        </span>
                                        Атрибуты реестра “{this.state.oldDocument.Info.Type.Name}”
                                    </p>
                                    <div className="table-responsive" style={{maxHeight: '63vh'}}>
                                        <table
                                            className="table table-hover table-striped table-borderless table-vertical-center"
                                            style={{tableLayout: 'fixed'}}
                                        >
                                            <thead className="thead-light">
                                            <tr className="text-uppercase">
                                                <th style={{borderRadius: "1em 0 0 0", width: '30%'}}>
                                                    <p className="text-success m-0 font-weight-normal">Атрибут</p>
                                                </th>
                                                <th style={{borderRadius: "0 1em  0 0"}}>
                                                    <p className="text-success m-0 font-weight-normal">Значение</p>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody style={{fontSize: "0.75em"}}>
                                            {
                                                this.state.oldDocument.unifiedDocAttributes?.map((attributeData)=> {
                                                    let isMismatch = false;
                                                    if (this.state.targetDataType) {
                                                        isMismatch = mismatchAttributes.find(attribute => attribute === attributeData.AttributeName.Name)
                                                    }
                                                    return (
                                                        <tr
                                                            style={this.state.targetDataType && !isMismatch ?
                                                                {backgroundColor: '#F58B8B'}
                                                                : this.state.targetFolder ?
                                                                    {backgroundColor: 'limegreen'}
                                                                    : null}
                                                            key={attributeData.AttributeName.Name}
                                                        >
                                                            <td style={{wordBreak: 'break-word'}}>{attributeData.AttributeName.Name}</td>
                                                            <td>
                                                                {getAttributeComponent({
                                                                    attributeData,
                                                                    disabled: true,
                                                                    renderJsonObjectFragment: this.renderJsonObjectFragment,
                                                                })}
                                                            </td>
                                                        </tr>
                                                    )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                { this.state.targetDataType && <div className="col-lg-4">
                                    <p>
                                        <span className="label label-inline label-light-primary font-weight-bold">
                                            Новые
                                        </span>
                                        Атрибуты реестра “{this.state.targetDataType.Name}”
                                    </p>
                                    <div className="table-responsive" style={{maxHeight: '63vh'}}>
                                        <table
                                            className="table table-hover table-striped table-borderless table-vertical-center"
                                            style={{tableLayout: 'fixed'}}
                                        >
                                            <thead className="thead-light">
                                            <tr className="text-uppercase">
                                                <th style={{borderRadius: "1em 0 0 0", width: '30%'}}>
                                                    <p className="text-success m-0 font-weight-normal">Атрибут</p>
                                                </th>
                                                <th style={{borderRadius: "0 1em  0 0"}}>
                                                    <p className="text-success m-0 font-weight-normal">Значение</p>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody style={{fontSize : "0.75em"}}>
                                            {this.state.newDocument.unifiedDocAttributes.map((attributeData) => {
                                                // get styles
                                                let withValue
                                                if (attributeData.IsMultipleAllowed) {
                                                    if (!Array.isArray(attributeData?.AttributeValue)) {
                                                        withValue = attributeData?.AttributeValue
                                                    } else {
                                                        withValue = attributeData.AttributeValue.length > 0
                                                    }
                                                } else {
                                                    withValue = attributeData?.AttributeValue
                                                }
                                                let style = null
                                                if (!withValue && attributeData.IsRequired) {
                                                    style = {backgroundColor: '#F0F2B0'}
                                                } else if (withValue) {
                                                    style = {backgroundColor: '#B0F2B7'}
                                                }

                                                return (
                                                    <tr style={style}
                                                        key={attributeData.AttributeName.Id}
                                                    >
                                                        <td style={{wordBreak: 'break-word', fontWeight: attributeData.IsRequired ? '700' : 'initial'}}>
                                                            {attributeData.AttributeName.Name}
                                                        </td>
                                                        <td>
                                                            {getAttributeComponent({
                                                                attributeData,
                                                                disabled: false,
                                                                externalDictionariesValues: this.state.externalDictionariesValues,
                                                                changeAttributeValue: this.changeAttributeValue,
                                                                renderJsonObjectFragment: this.renderJsonObjectFragment,
                                                                addMultiplyRowHandler: this.addMultiplyRowHandler,
                                                                deleteMultiplyRowHandler: this.deleteMultiplyRowHandler,
                                                            })}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer" style={{padding: "1rem 1rem", borderTop: "none"}}>
                            <button type="button"
                                    disabled={this.state.countEmptyRequiredFields || !this.state.targetDataType}
                                    className="btn btn-primary font-weight-bolder"
                                    onClick={this.postNewDocumentType}
                            >
                                Перенести документ в новый реестр
                            </button>
                            <button
                              type="button"
                              className="btn btn-light font-weight-bold"
                              onClick={this.hide}
                            >
                                Отменить действие
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const  mapStateToProps = state => {
    return {
        activeNode : state.document.mainTable.activeNode,
        activeDocument : state.document.mainTable.activeRow,
        allTypes: state.document.tree.docs,
        router : state.router,
    }
}

const  mapDispatchToProps = {
    addModalData,
    deleteModalData,
    combineMainTableAttributes,
    mainTableDownloadDocCollection,
    changeDocumentViewMode
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDocumentTypeModalOriginal);
