import React from 'react';
import {ThemeNamesEnum} from "../../../tools/StaticTypes";
import {store} from "../../../index";

// Компонент приветствующий пользователя при первом запуске приложения
export default class Preloader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        // this.selectPreloaderFile = this.selectPreloaderFile.bind(this);
    }

    // selectPreloaderFile() {
    //     const theme = JSON.parse(localStorage.getItem('color_theme'));
    //     switch(theme?.lThemeName) {
    //         // case(ThemeNamesEnum.RedTheme): return "preloaderred.gif";
    //         case(ThemeNamesEnum.AzureTheme): return "preloaderazure.gif";
    //         case(ThemeNamesEnum.YellowTheme): return "preloaderyellow.gif"; //
    //         default: return "preloaderazure.gif"
    //     }
    // }


    render() {
        let mod = store.getState().globalState.theme.modString
        return (
            <div style={{width : "100vw", height : "100vh", backgroundColor : "white"}}>
                <div className="position-absolute-center">
                    <img src={`preloader${mod}.gif`} alt="Загрузка приложения" style={{width:"20vh"}}/>
                </div>
            </div>
        );
    }

}

