import {apiRequest} from "../../../tools/API/apiRequest";
import {AUTH_ACTIONS, SETTINGS_ACTIONS} from "../globalStateActionsList";
import XML from "../../../tools/XML";
import {apiUrl} from "../../../tools/API/apiUrl";
import {UserSettingsActionsTypes, UserSettingsNames} from "../../../tools/StaticTypes";
import {ColorTheme, store} from "../../../index";
import {TreeCombine} from "../../../tools/TreeCombine";
import {__, HelpFunctions} from "../../../tools/HelpFunctions";
import {EntityFilters, Filter, FiltersType, Sorting} from "../../../tools/Filters";
import {MESSENGER_ACTION} from "../../messenger/messengerActionList";

// Получение глобальных настроек пользователей
// (ArmType = 2, по умолчанию)
export const getGlobalClientSettings = ({ArmType = 2, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let request = new apiRequest(apiUrl.SETTINGS.GLOBAL, {
                armType : ArmType,
            });

            request.execute(function(data) {
                let Content="";
                let GlobalSettings= {};
                if(!data.Content){
                    let defaultXML =
                        `<?xml version="1.0"?>
                        <configuration>
                            <property name="IsRelease" value="1"/>
                              <property name="IsCreateGridFilterInDocument" value="1"/>
                              <property name="IsCreateGridHeaderFilterInDocument" value="1"/>
                              <property name="IsCreateGridFilterInPackage" value="1"/>
                              <property name="IsCreateGridHeaderFilterInPackage" value="1"/>
                              <property name="IsCreateGridFilterInSearch" value="1"/>
                              <property name="IsCreateGridHeaderFilterInSearch" value="1"/>
                              <property name="IsExternalPluginForPdf" value="0"/>
                              <property name="AllowUserAttachFile" value="1"/>
                              <property name="IsSaveFileButtonStateInPreview" value="1"/>
                              <property name="EnableSharedPackage" value="1"/>
                              <property name="UserPackageParentId" value="2"/>
                              <property name="EnableClassifier" value="1"/>
                              <property name="EnableMoveToFullVersionInDocView" value="1" />
                              <property name="EnableMoveToFullVersionInLight" value="1"/>
                              <property name="EnableDocSections" value="0"/>
                              <property name="EnableDocDepartments" value="0"/>
                              <property name="WebFullAddress" value="http://archive.asv.org.ru/client/"/>
                              <property name="FilterTimerTick" value="3000"/>
                              <property name="ChangeOrganizationTooltip" value="Сменить Филиал"/>
                              <property name="WithSignature" value="0"/>
                              <property name="WithDepartments" value="0"/>
                              <property name="WithSection" value="0"/>
                              <property name="WithMessenger" value="0"/>
                              </configuration>
                    `;
                    let Content2 = XML.xmlToJson(XML.parse(defaultXML))
                    data.ArmType=2;
                    data.Description="";
                    data.Id=2;


                    Content2.configuration.property.forEach((item) =>
                        GlobalSettings[item["@attributes"].name] = item["@attributes"].value
                    )
                }else{

                    Content = XML.xmlToJson(XML.parse(data.Content));
                    Content.configuration.property.forEach( (item) =>
                        GlobalSettings[item["@attributes"].name] = item["@attributes"].value
                    )
                }
                let result = {
                    ArmType : data.ArmType,
                    Content :  GlobalSettings,
                    Description :  data.Description,
                    Id :  data.Id
                }
                dispatch({type : SETTINGS_ACTIONS.GET_GLOBAL_CLIENT_SETTINGS, payload : result});

                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка

            },function(data) {
                if(data.status === 401) {
                    dispatch({type :  AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения глобальных настроек SETTINGS_ACTIONS.GET_GLOBAL_CLIENT_SETTINGS (post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения глобальных настроек SETTINGS_ACTIONS.GET_GLOBAL_CLIENT_SETTINGS (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


export const getDepartments = ({Next = null, Error = null}) => {
    const activeFilialId =store.getState().globalState.filial.Active.Id
    return async dispatch => {
        try {
            let request = new apiRequest(apiUrl.SETTINGS.DEPARTMENTS, {
                filter : new Filter(FiltersType.ENTITY).add(
                    new EntityFilters().setEntityName("Department").add({
                        Name : "IdFilial",
                        Value : activeFilialId
                    }).get()
                ).get(),
                sorting : new Sorting().add({Direction: 1,PropertyName : "Name"}).get()
            });

            request.execute(function(data) {
                const payload = __.deepCopy(data.Records)
                payload.map(item => item.isChecked = false)
                dispatch({type : SETTINGS_ACTIONS.GET_DEPARTMENTS, payload});

                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
            });
        } catch (exception) {
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}



// обновление подразделений
export const SaveOrUpdateAndDeleteByFilter = ({IdDoc = null, collection = null, Next = null, Error = null}) => {
    if (!IdDoc) IdDoc = store.getState().document.mainTable.activeRow.Version.IdDoc
    return async dispatch => {
        try {
            let request = new apiRequest(apiUrl.SETTINGS.UPLOAD_DEPARTMENTS, {
                entitiesForSaveOrUpdate: collection,
                filtersForDelete:[
                    new EntityFilters().setEntityName("DocDepartmentRef").add({
                        Name: "IdDoc",
                        Value: IdDoc
                    }).get()],
            });

            request.execute(function(data) {

                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения данных о филиале USER_ACTIONS.GET_FILIAL (post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения данных о филиале USER_ACTIONS.GET_FILIAL (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const setColorTheme = ({Theme = {lThemeName: "default_theme", lThemeType: "light"}, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            if (!Theme) Theme = {lThemeName: "default_theme", lThemeType: "light"}
            await dispatch({type: SETTINGS_ACTIONS.SET_THEME, payload: Theme});
            localStorage.setItem("color_theme", JSON.stringify(Theme));
            if (Next) {
                Next.action(Next.params);
            } // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка изменения темы SETTINGS_ACTIONS.SET_THEME (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// получение собственных настроек для текущего пользователя
export const getUserSettings = ({Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let request = new apiRequest(apiUrl.SETTINGS.USER, {});
            let activeFilial=null;
            let selectedTheme=null;
            request.execute(async function (data) {
                let userSettings = [...data];
                for (let i = 0; i < userSettings.length; i++) {
                    userSettings[i].Value = JSON.parse(userSettings[i].Value);
                    if( userSettings[i].Name===UserSettingsNames.FILIAL_CONFIG){
                        activeFilial=userSettings[i].Value[0];
                        await dispatch({type: SETTINGS_ACTIONS.SET_FILIAL, payload: activeFilial});
                    }
                    if(userSettings[i].Name===UserSettingsNames.THEME_CONFIG){
                        selectedTheme=userSettings[i].Value[0];
                        // ColorTheme.setTheme(selectedTheme.lThemeName);
                        // selectedTheme="red_theme";
                        ColorTheme.setTheme(selectedTheme);
                           await dispatch({type: SETTINGS_ACTIONS.SET_THEME, payload: selectedTheme});
                    }
                }

                await dispatch({type: SETTINGS_ACTIONS.GET_USER_SETTINGS, payload: userSettings});
                if (Next) {
                    Next.action(Next.params);
                } // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения пользовательских настроек SETTINGS_ACTIONS.GET_GLOBAL_CLIENT_SETTINGS (post):", data);
            });
        } catch (exception) {
            console.log("Ошибка получения пользовательских настроек SETTINGS_ACTIONS.GET_GLOBAL_CLIENT_SETTINGS (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


// обновление собственных настроек для текущего пользователя;
// Name (UserSettingsNames) - имя пользовательской настройки;
// Action - действие, которое необходимо выполнить (UserSettingsActionsTypes);
// Data - данные для выполнения действия;
export const updateUserSettings = ({Name, Action, Data = null,Next = null, Error = null}) => {
    return async dispatch => {
        try {
            // Node.parent.info.Id -> это IdDocGroup
            // Node.info.Id -> это PropertyName: "Type.Id"

            // MAIN_TABLE_CONFIG - настройки столбцов основной таблицы
            if(Name === UserSettingsNames.MAIN_TABLE_CONFIG) {

                // выбранный узел дерева
                let Node = __.deepCopy(store.getState().document.tree.activeNode);
                // получаем выбранную папку в дереве
                Node.parent = __.deepCopy(TreeCombine.searchByTreeId({treeId : Node.parent}));

                // информация о выбранной таблице
                let _info = {
                    IdDocGroup: Number.parseInt(Node.parent.info.Id),
                    IdFilial:  Number.parseInt(__.deepCopy(store.getState().globalState.filial.Active).Id),
                    TypeId: Number.parseInt(Node.info.Id)
                };

                // обновление или добавление настройки
                if(Action === UserSettingsActionsTypes.Update) {
                    // все найденные настройки для таблиц
                    let allTableSettings = __.deepCopy(HelpFunctions.FilterLoadSettings({name : Name}));

                    let newValue = {
                        info : _info,
                        columns : []
                    };

                    for(let i = 0; i < Data.length; i++) {
                        newValue.columns.push({
                            Name : Data[i].Name,
                            Value : Data[i].Value,
                            Visible : Data[i].UserVisible
                        });
                    }

                    // если allTableSettings найдена, значит обновляем ее
                    if(allTableSettings) {
                        // номер настройки для конкретной таблицы
                        let foundTableIndex = allTableSettings.Value
                            .findIndex(Value =>
                                Number.parseInt(Value.info.IdDocGroup) === Number.parseInt(_info.IdDocGroup) &&
                                Number.parseInt(Value.info.TypeId)     === Number.parseInt(_info.TypeId) &&
                                Number.parseInt(Value.info.IdFilial)   === Number.parseInt(_info.IdFilial)
                            );

                        // если index найден значит обновляем его
                        if(foundTableIndex >= 0) {
                            allTableSettings.Value[foundTableIndex] = newValue;
                        }
                        // если не найден, значит добавляем новый item
                        else {
                            allTableSettings.Value.push(newValue);
                        }

                        allTableSettings.Value = JSON.stringify(allTableSettings.Value);

                        let request = new apiRequest(apiUrl.SETTINGS.UPDATE_USER, {
                            collection : [allTableSettings]
                        });

                        request.execute(function (data) {
                            // возвращена обновленная пользовательская настройка
                            let result = __.deepCopy(data);
                            result[0].Value = JSON.parse(result[0].Value);

                            let allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                            // узнаем index данной настройки среди всех существующих для ее обновления локально
                            let allUserSettingsFindIndex = allUserSettings.findIndex(allUserSettings => allUserSettings.Id === result[0].Id);

                            dispatch({type : SETTINGS_ACTIONS.UPDATE_USER_SETTINGS, payload : {
                                // новое значение
                                item : result[0],
                                // index значения для обновления
                                index : allUserSettingsFindIndex
                            }});

                            // следующее действие если есть цепочка
                            if(Next) {Next.action(Next.params);}
                        },
                        function (data) {
                            if(data.status === 401) {
                                dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                            }
                            console.log("Ошибка обновления пользовательских настроек:", data);
                        });

                    }
                    // если allTableSettings не найдена, то необходимо создать новую настройку
                    else {
                        let newSettingsItem = { // TODO: Не проверял работает ли
                            Name : Name,
                            Value : [newValue],
                            User : store.getState().globalState.user,
                            ArmType : store.getState().globalState.settings.ArmType,
                        };

                        newSettingsItem.Value = JSON.stringify(newSettingsItem.Value);

                        let request = new apiRequest(apiUrl.SETTINGS.UPDATE_USER, {
                            collection : [newSettingsItem]
                        });

                        request.execute(function (data) {
                            let result = __.deepCopy(data);
                            result[0].Value = JSON.parse(result[0].Value);

                            let allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                            let allUserSettingsFindIndex = allUserSettings.findIndex(allUserSettings => allUserSettings.Id === result[0].Id);

                            dispatch({type : SETTINGS_ACTIONS.UPDATE_USER_SETTINGS, payload : {
                                item : result[0],
                                index : allUserSettingsFindIndex
                            }});

                            // следующее действие если есть цепочка
                            if(Next) {Next.action(Next.params);}
                        },
                            function (data) {
                                if(data.status === 401) {
                                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                                }
                                console.log("Ошибка обновления пользовательских настроек:", data);
                        });
                    }
                }
                // удаление пункта настройки (определенную таблицу) -> кнопка "по умолчанию"
                else if(Action === UserSettingsActionsTypes.DeleteOne) {
                    let allTableSettings = __.deepCopy(HelpFunctions.FilterLoadSettings({name : Name}));

                    if(allTableSettings) {
                        let foundTableIndex = allTableSettings.Value
                            .findIndex(Value =>
                                Number.parseInt(Value.info.IdDocGroup) === Number.parseInt(_info.IdDocGroup) &&
                                Number.parseInt(Value.info.TypeId)     === Number.parseInt(_info.TypeId) &&
                                Number.parseInt(Value.info.IdFilial)   === Number.parseInt(_info.IdFilial)
                            );

                        // удаляем нужный элемент из массива
                        allTableSettings.Value.splice(foundTableIndex, 1);

                        allTableSettings.Value = JSON.stringify(allTableSettings.Value);

                        let request = new apiRequest(apiUrl.SETTINGS.UPDATE_USER, {
                            collection : [allTableSettings]
                        });

                        request.execute(function (data) {
                            let result = __.deepCopy(data);
                            result[0].Value = JSON.parse(result[0].Value);

                            let allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                            let allUserSettingsFindIndex = allUserSettings.findIndex(allUserSettings => allUserSettings.Id === result[0].Id);

                            dispatch({type : SETTINGS_ACTIONS.UPDATE_USER_SETTINGS, payload : {
                                item : result[0],
                                index : allUserSettingsFindIndex
                            }});

                            // следующее действие если есть цепочка
                            if(Next) {Next.action(Next.params);}
                            },
                            function (data) {
                                if(data.status === 401) {
                                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                                }
                                console.log("Ошибка обновления пользовательских настроек:", data);
                        });
                    }
                }
                // удаление настройки полностью (все таблицы)
                else if(Action === UserSettingsActionsTypes.DeleteAll) {
                    let allTableSettings = __.deepCopy(HelpFunctions.FilterLoadSettings({name : Name}));
                    allTableSettings.Value = [];
                    allTableSettings.Value = JSON.stringify(allTableSettings.Value);

                    let request = new apiRequest(apiUrl.SETTINGS.UPDATE_USER, {
                        collection : [allTableSettings]
                    });

                    request.execute(function (data) {
                        let result = __.deepCopy(data);
                        result[0].Value = JSON.parse(result[0].Value);

                        let allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                        let allUserSettingsFindIndex = allUserSettings.findIndex(allUserSettings => allUserSettings.Id === result[0].Id);

                        dispatch({type : SETTINGS_ACTIONS.UPDATE_USER_SETTINGS, payload : {
                            item : result[0],
                            index : allUserSettingsFindIndex
                        }});

                        // следующее действие если есть цепочка
                        if(Next) {Next.action(Next.params);}
                    },
                    function (data) {
                        if(data.status === 401) {
                            dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                        }
                        console.log("Ошибка обновления пользовательских настроек:", data);
                    });
                }
            }
            else if(Name === UserSettingsNames.SEARCH_CONFIG) {
                const activeTabId = store.getState().search.rootState.activeTab.Id;
                const inputIndex = store.getState().search.searchState.searchInputsList.findIndex(item => item.Id === activeTabId);
                const searchInputs = __.deepCopy(store.getState().search.searchState.searchInputsList[inputIndex]);
                let user = store.getState().globalState.user
                let armType= store.getState().globalState.auth.armType
                let filial = store.getState().globalState.filial.Active.Id
                let allUserSettings = __.deepCopy(store.getState().globalState.userSettings);

                let totalInServer = store.getState().search.mainTable.collection.totalInServer;


                if(Data) {
                    searchInputs.FilialIds = Data.FilialIds;
                    searchInputs.TypeIds = Data.TypeIds;
                    searchInputs.totalInServer = totalInServer;
                }
                searchInputs.filial=filial;

                if(Action === UserSettingsActionsTypes.Update) {


                    if(allUserSettings.findIndex(allUserSettings => allUserSettings.Name === Name)>=0){
                        let allUserSettingsFindIndex= allUserSettings.findIndex(allUserSettings => allUserSettings.Name === Name)
                        let addSearch= allUserSettings[allUserSettingsFindIndex];

                        if(addSearch.Value.length < 5){
                            addSearch.Value.push(searchInputs)
                        }else{
                            addSearch.Value.splice(0, 1);
                            addSearch.Value.push(searchInputs)
                        }


                        // let newSettingsItem = {
                        //     Name : Name,
                        //     Value : [addSearch],
                        //     User : user,
                        //     ArmType : armType,
                        // };


                        addSearch.Value = JSON.stringify(addSearch.Value);
                        // for(let i=0; i<addSearch.Value.length; i++){
                        //      addSearch.Value[i] = JSON.stringify(addSearch.Value[i]);
                        //
                        // }



                        let request = new apiRequest(apiUrl.SETTINGS.UPDATE_USER, {

                            collection : [addSearch]
                        });
                        request.execute(function (data) {

                                let result = __.deepCopy(data);
                                result[0].Value = JSON.parse(result[0].Value);
                                allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                                let allUserSettingsFindIndex = allUserSettings.findIndex(allUserSettings => allUserSettings.Id === result[0].Id);

                                dispatch({type : SETTINGS_ACTIONS.UPDATE_USER_SETTINGS_SEARCH, payload : {
                                        item : result[0],
                                        index : allUserSettingsFindIndex
                                    }});

                                // следующее действие если есть цепочка
                                if(Next) {Next.action(Next.params);}
                            },
                            function (data) {
                                if(data.status === 401) {
                                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                                }
                                console.log("Ошибка обновления пользовательских настроек:", data);
                            });
                  }else{
                        let newSettingsItem = {
                            Name : Name,
                            Value : [searchInputs],
                            User : user,
                            ArmType : armType,
                        };

                        newSettingsItem.Value = JSON.stringify(newSettingsItem.Value);

                        let request = new apiRequest(apiUrl.SETTINGS.UPDATE_USER, {

                            collection : [newSettingsItem]
                        });
                        request.execute(function (data) {

                                let result = __.deepCopy(data);
                                result[0].Value = JSON.parse(result[0].Value);

                                allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                                let allUserSettingsFindIndex = allUserSettings.findIndex(allUserSettings => allUserSettings.Id === result[0].Id);

                                dispatch({type : SETTINGS_ACTIONS.UPDATE_USER_SETTINGS_SEARCH, payload : {
                                        item : result[0],
                                        index : allUserSettingsFindIndex
                                    }});

                                // следующее действие если есть цепочка
                                if(Next) {Next.action(Next.params);}
                            },
                            function (data) {
                                if(data.status === 401) {
                                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                                }
                                console.log("Ошибка обновления пользовательских настроек:", data);
                            });
                  }


                }





                // другие настройки
            }
            else if(Name === UserSettingsNames.DOCUMENT_CONFIG){
                let user=store.getState().globalState.user
                let armType=store.getState().globalState.auth.armType
                let filial=store.getState().globalState.filial.Active.Id
                let allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                Data.filial=filial;
                if(allUserSettings.findIndex(allUserSettings => allUserSettings.Name === Name)>=0){
                    let allUserSettingsFindIndex= allUserSettings.findIndex(allUserSettings => allUserSettings.Name === Name)
                    let addDoc= allUserSettings[allUserSettingsFindIndex]
                    if (!addDoc?.Value.find(document => document.IdDoc === Data.IdDoc)) {
                    if(addDoc.Value.length < 5){
                        addDoc.Value.push(Data)
                    }else{
                        addDoc.Value.splice(0, 1);
                        addDoc.Value.push(Data)
                    }

                    addDoc.Value = JSON.stringify(addDoc.Value);

                    let request = new apiRequest(apiUrl.SETTINGS.UPDATE_USER, {

                        collection : [addDoc]
                    });
                    request.execute(function (data) {

                            let result = __.deepCopy(data);
                            result[0].Value = JSON.parse(result[0].Value);
                            allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                            let allUserSettingsFindIndex = allUserSettings.findIndex(allUserSettings => allUserSettings.Id === result[0].Id);

                            dispatch({type : SETTINGS_ACTIONS.UPDATE_USER_SETTINGS_DOC, payload : {
                                    item : result[0],
                                    index : allUserSettingsFindIndex
                                }});

                            // следующее действие если есть цепочка
                            if(Next) {Next.action(Next.params);}
                        },
                        function (data) {
                            if(data.status === 401) {
                                dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                            }
                            console.log("Ошибка обновления пользовательских настроек:", data);
                        });
                    }
                }else{
                    let newSettingsItem = {
                        Name : Name,
                        Value : [Data],
                        User : user,
                        ArmType : armType,
                    };

                    newSettingsItem.Value = JSON.stringify(newSettingsItem.Value);

                    let request = new apiRequest(apiUrl.SETTINGS.UPDATE_USER, {

                        collection : [newSettingsItem]
                    });
                    request.execute(function (data) {

                            let result = __.deepCopy(data);
                            result[0].Value = JSON.parse(result[0].Value);

                            allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                            let allUserSettingsFindIndex = allUserSettings.findIndex(allUserSettings => allUserSettings.Id === result[0].Id);

                            dispatch({type : SETTINGS_ACTIONS.UPDATE_USER_SETTINGS_DOC, payload : {
                                    item : result[0],
                                    index : allUserSettingsFindIndex
                                }});

                            // следующее действие если есть цепочка
                            if(Next) {Next.action(Next.params);}
                        },
                        function (data) {
                            if(data.status === 401) {
                                dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                            }
                            console.log("Ошибка обновления пользовательских настроек:", data);
                        });
                }
            }
            else if(Name === UserSettingsNames.PACKAGE_TYPE_CONFIG){
                // TODO КН 17.04.24 изменил порядок действий - сейчас сначала сетаю в редакс, потом делаю запрос, чтобы пользователь не ждал пока сервер ответит. и только потом сетал в стор и далее в ui (думаю по хорошему везде в этой ф-ии надо так сделать)
                let user= store.getState().globalState.user
                let armType= store.getState().globalState.auth.armType
                let newSettingsItem = {
                    Name: Name,
                    Value: [Data],
                    User: user,
                    ArmType: armType,
                };
                const allUserSettings = store.getState().globalState.userSettings;
                const allUserSettingsFindIndex = allUserSettings.findIndex(setting => setting.Name === Name)
                const newUserSettings = __.replaceArrayElementByIndex(allUserSettings, allUserSettingsFindIndex, newSettingsItem)
                dispatch({
                    type : SETTINGS_ACTIONS.UPDATE_USER_SETTINGS_PACKAGE_TYPE,
                    payload : {
                        item : newUserSettings,
                    }
                });
                let request = new apiRequest(apiUrl.SETTINGS.UPDATE_USER, {
                    collection : [{
                        ...newSettingsItem,
                        Value: JSON.stringify(newSettingsItem.Value)
                    }]
                });
                request.execute(function () {
                    // следующее действие если есть цепочка
                    if(Next) {Next.action(Next.params);}
                }, function (data) {
                    if(data.status === 401) {
                        dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                    }
                    console.log("Ошибка обновления пользовательских настроек:", data);
                });
            }
            else if(Name === UserSettingsNames.PACKAGE_TYPE_CONFIG_ADDITIONAL){
                // let filial = store.getState().globalState.filial.Active;
                let user=store.getState().globalState.user
                let armType=store.getState().globalState.auth.armType
                let allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                // if(allUserSettings.findIndex(allUserSettings => allUserSettings.Name === Name)>0) {
                let newSettingsItem = {
                    Name: Name,
                    Value: [Data],
                    User: user,
                    ArmType: armType,
                };

                newSettingsItem.Value = JSON.stringify(newSettingsItem.Value);
                let request = new apiRequest(apiUrl.SETTINGS.UPDATE_USER, {

                    collection : [newSettingsItem]
                });
                request.execute(function (data) {

                        let result = __.deepCopy(data);
                        result[0].Value = JSON.parse(result[0].Value);
                        //console.log("result[0].Value",result[0].Value)
                        allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                        let allUserSettingsFindIndex = allUserSettings.findIndex(allUserSettings => allUserSettings.Id === result[0].Id);
                        // console.log("allUserSettingsFindIndex",allUserSettingsFindIndex)
                        // console.log("allUserSettings",allUserSettings)
                        // console.log("result[0]",result[0])
                    if(allUserSettingsFindIndex>-1){
                        allUserSettings[allUserSettingsFindIndex] = result[0];
                    }else{
                        allUserSettings.push(result[0]);
                    }
                        dispatch({type : SETTINGS_ACTIONS.UPDATE_USER_SETTINGS_PACKAGE_TYPE_ADDITIONAL, payload : {
                                item : allUserSettings,
                            }});

                        // следующее действие если есть цепочка
                        if(Next) {Next.action(Next.params);}
                    },
                    function (data) {
                        if(data.status === 401) {
                            dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                        }
                        console.log("Ошибка обновления пользовательских настроек:", data);
                    });
                // }
            }
            else if(Name === UserSettingsNames.FILIAL_CONFIG){
                let filial = store.getState().globalState.filial.Active;
                let user=store.getState().globalState.user
                let armType=store.getState().globalState.auth.armType
                // let allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                // if(allUserSettings.findIndex(allUserSettings => allUserSettings.Name === Name)>0) {
                    let newSettingsItem = {
                        Name: Name,
                        Value: [filial],
                        User: user,
                        ArmType: armType,
                    };

                newSettingsItem.Value = JSON.stringify(newSettingsItem.Value);
                let request = new apiRequest(apiUrl.SETTINGS.UPDATE_USER, {

                    collection : [newSettingsItem]
                });
                request.execute(function (data) {

                        let result = __.deepCopy(data);
                        result[0].Value = JSON.parse(result[0].Value);
                        // console.log("result[0].Value",result[0].Value)
                        // allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                        // let allUserSettingsFindIndex = allUserSettings.findIndex(allUserSettings => allUserSettings.Id === result[0].Id);
                        //
                        // dispatch({type : SETTINGS_ACTIONS.UPDATE_USER_SETTINGS_SEARCH, payload : {
                        //         item : result[0],
                        //         index : allUserSettingsFindIndex
                        //     }});

                        // следующее действие если есть цепочка
                        if(Next) {Next.action(Next.params);}
                    },
                    function (data) {
                        if(data.status === 401) {
                            dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                        }
                        console.log("Ошибка обновления пользовательских настроек:", data);
                    });
                // }
            }
            else if(Name === UserSettingsNames.PACKAGE_CONFIG) {

                //let searchInputs = store.getState().search.mainTable.inputField
                let user=store.getState().globalState.user
                let armType=store.getState().globalState.auth.armType
                let filial=store.getState().globalState.filial.Active.Id
                let allUserSettings = __.deepCopy(store.getState().globalState.userSettings);

                // let test = new Set([])

                Data.filial=filial;

                if(allUserSettings.findIndex(allUserSettings => allUserSettings.Name === Name)>=0){

                    let allUserSettingsFindIndex= allUserSettings.findIndex(allUserSettings => allUserSettings.Name === Name)
                    if(allUserSettings[allUserSettingsFindIndex].Value.findIndex(Value=>Value.Id===Data.Id)>=0){
                        return
                    }
                    let addDoc= allUserSettings[allUserSettingsFindIndex]
                    // console.log("addSearch1",addDoc)
                    if(addDoc.Value.length < 5){
                        addDoc.Value.push(Data)
                    }else{
                        addDoc.Value.splice(0, 1);
                        addDoc.Value.push(Data)
                    }
                    // console.log("addSearch2",addDoc)

                    // let newSettingsItem = {
                    //     Name : Name,
                    //     Value : [addDoc],
                    //     User : user,
                    //     ArmType : armType,
                    // };

                    // console.log("addSearch_Start",addDoc)
                    addDoc.Value = JSON.stringify(addDoc.Value);
                    // for(let i=0; i<addSearch.Value.length; i++){
                    //     console.log("addSearch.Value[i]",addSearch.Value[i])
                    //      addSearch.Value[i] = JSON.stringify(addSearch.Value[i]);
                    //
                    // }

                    // console.log("addSearch_Final",addDoc)

                    let request = new apiRequest(apiUrl.SETTINGS.UPDATE_USER, {

                        collection : [addDoc]
                    });
                    request.execute(function (data) {

                            let result = __.deepCopy(data);
                            result[0].Value = JSON.parse(result[0].Value);
                            console.log("result[0].Value",result[0].Value)
                            allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                            let allUserSettingsFindIndex = allUserSettings.findIndex(allUserSettings => allUserSettings.Id === result[0].Id);

                            dispatch({type : SETTINGS_ACTIONS.UPDATE_USER_SETTINGS_PACKAGE, payload : {
                                    item : result[0],
                                    index : allUserSettingsFindIndex
                                }});

                            // следующее действие если есть цепочка
                            if(Next) {Next.action(Next.params);}
                        },
                        function (data) {
                            if(data.status === 401) {
                                dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                            }
                            console.log("Ошибка обновления пользовательских настроек:", data);
                        });
                }else{
                    let newSettingsItem = {
                        Name : Name,
                        Value : [Data],
                        User : user,
                        ArmType : armType,
                    };

                    newSettingsItem.Value = JSON.stringify(newSettingsItem.Value);

                    let request = new apiRequest(apiUrl.SETTINGS.UPDATE_USER, {

                        collection : [newSettingsItem]
                    });
                    request.execute(function (data) {

                            let result = __.deepCopy(data);
                            result[0].Value = JSON.parse(result[0].Value);

                            allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                            let allUserSettingsFindIndex = allUserSettings.findIndex(allUserSettings => allUserSettings.Id === result[0].Id);

                            dispatch({type : SETTINGS_ACTIONS.UPDATE_USER_SETTINGS_PACKAGE, payload : {
                                    item : result[0],
                                    index : allUserSettingsFindIndex
                                }});

                            // следующее действие если есть цепочка
                            if(Next) {Next.action(Next.params);}
                        },
                        function (data) {
                            if(data.status === 401) {
                                dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                            }
                            console.log("Ошибка обновления пользовательских настроек:", data);
                        });
                }

                console.log("Data",Data)
            }
            else if(Name === UserSettingsNames.THEME_CONFIG){
                let filial = store.getState().globalState.filial.Active;
                let user=store.getState().globalState.user;
                let armType=store.getState().globalState.auth.armType;
                if(!armType) return;
                Data.filial=filial;
                // let allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                // if(allUserSettings.findIndex(allUserSettings => allUserSettings.Name === Name)>0) {
                let newSettingsItem = {
                    Name: Name,
                    Value: [Data.selectedTheme],
                    User: user,
                    ArmType: armType,
                };

                newSettingsItem.Value = JSON.stringify(newSettingsItem.Value);
                //{lThemeName: "default_theme", lThemeType: "light"}
                let request = new apiRequest(apiUrl.SETTINGS.UPDATE_USER, {

                    collection : [newSettingsItem]
                });
                request.execute(function (data) {

                        let result = __.deepCopy(data);
                        result[0].Value = JSON.parse(result[0].Value);
                        // allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                        // let allUserSettingsFindIndex = allUserSettings.findIndex(allUserSettings => allUserSettings.Id === result[0].Id);
                        //
                        // dispatch({type : SETTINGS_ACTIONS.UPDATE_USER_SETTINGS_SEARCH, payload : {
                        //         item : result[0],
                        //         index : allUserSettingsFindIndex
                        //     }});

                        let lTheme = Data.selectedTheme
                        if (!lTheme) lTheme = {lThemeName: "default_theme", lThemeType: "light"}

                        dispatch({type : SETTINGS_ACTIONS.SET_THEME, payload : lTheme});

                        // следующее действие если есть цепочка
                        if(Next) {Next.action(Next.params);}
                    },
                    function (data) {
                        if(data.status === 401) {
                            dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                        }
                        console.log("Ошибка обновления пользовательских настроек:", data);
                    });
                // }
            }
            else if(Name === UserSettingsNames.GENERAL_CONFIG) {
                if(Action === UserSettingsActionsTypes.ToggleIncreaseScope) {
                    let generalSettings = __.deepCopy(HelpFunctions.FilterLoadSettings({name : Name}));
                    // если generalSettings найдена, значит обновляем ее
                    if(generalSettings) {
                        if(Data.hasOwnProperty("documentIncreaseScope")) {
                            generalSettings.Value = { ...generalSettings.Value, documentIncreaseScope: Data.documentIncreaseScope}
                        }

                        if(Data.hasOwnProperty("packageIsIncreaseScope")) {
                            generalSettings.Value = { ...generalSettings.Value, packageIsIncreaseScope: Data.packageIsIncreaseScope }
                        }
                    } else {
                        let newValue = {};
                        if(Data.hasOwnProperty("documentIncreaseScope")) {
                            newValue = { documentIncreaseScope: Data.documentIncreaseScope}
                        }

                        if(Data.hasOwnProperty("packageIsIncreaseScope")) {
                            newValue = { packageIsIncreaseScope: Data.packageIsIncreaseScope }
                        }

                        generalSettings = {
                            Name : Name,
                            Value : newValue,
                            User : store.getState().globalState.user,
                            ArmType : store.getState().globalState.settings.ArmType,
                        };
                    }

                    generalSettings.Value = JSON.stringify(generalSettings.Value);
                    let request = new apiRequest(apiUrl.SETTINGS.UPDATE_USER, {
                        collection : [generalSettings]
                    });

                        request.execute(function (data) {
                                // возвращена обновленная пользовательская настройка
                                let result = __.deepCopy(data);
                                result[0].Value = JSON.parse(result[0].Value);

                                let allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                                // узнаем index данной настройки среди всех существующих для ее обновления локально
                                let allUserSettingsFindIndex = allUserSettings.findIndex(allUserSettings => allUserSettings.Id === result[0].Id);

                                dispatch({type : SETTINGS_ACTIONS.UPDATE_USER_SETTINGS_GENERAL, payload : {
                                        // новое значение
                                        item : result[0],
                                        // index значения для обновления
                                        index : allUserSettingsFindIndex
                                    }});

                                // следующее действие если есть цепочка
                                if(Next) {Next.action(Next.params);}
                            },
                            function (data) {
                                if(data.status === 401) {
                                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                                }
                                console.log("Ошибка обновления пользовательских настроек:", data);
                            });

                    }
            }
            else if(Name === UserSettingsNames.MESSENGER) {
                let user = store.getState().globalState.user
                let receiverActive = store.getState().messenger.message.activeReceiver
                let armType = store.getState().globalState.auth.armType
                let allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                let buddies = __.deepCopy((store.getState().messenger.message.myBuddies))
                let isExistedBuddy = false
                let users=__.deepCopy(store.getState().globalState.AllUsers.Records)
                let receiver=users.find(user=>user.Id===receiverActive.Id)

                let nameArr=user.Name.toUpperCase().split(' ')
                let name='messenger'+nameArr.join('_')
                let colleagueMessage={
                    Name : name,
                    Value:[],
                    User : receiver,
                    ArmType : armType,
                };

                if (allUserSettings.findIndex(allUserSettings => allUserSettings.Name === Data.SettingName) >= 0) {

                    let allUserSettingsFindIndex = allUserSettings.findIndex(allUserSettings => allUserSettings.Name === Data.SettingName)
                    let sentMessage = allUserSettings[allUserSettingsFindIndex];

                    if(Data.Message || Data.AttachedData || Data.Files) {
                        let messageIsPushed=false
                        for (let i = 0; i < sentMessage.Value.length; i++) {
                            if (sentMessage.Value[i].Colleague.Id === receiver.Id) {
                                sentMessage.Value[i].Message.push({
                                    Author:user,
                                    Text: Data.Message,
                                    Date: Data.Date,
                                    AttachedData:Data.AttachedData,
                                    MessageType: Data.MessageType,
                                    isRead: false,
                                    Files: Data.Files,
                                })
                                sentMessage.Value[i].MsgAmount=__.deepCopy(sentMessage.Value[i].Message).length

                                colleagueMessage.Value.push({
                                    Colleague:user,
                                    Message: __.deepCopy(sentMessage.Value[i].Message),
                                    MsgAmount: __.deepCopy(sentMessage.Value[i].Message).length,
                                })
                                messageIsPushed=true
                                break;
                            }
                          }
                        //если у нас новый получатель
                        if(!messageIsPushed){

                            sentMessage.Value.push({
                                Colleague:{...receiver},
                                Message:[{
                                    Author:user,
                                    Text: Data.Message,
                                    Date: Data.Date,
                                    AttachedData: Data.AttachedData,
                                    MessageType: Data.MessageType,
                                    isRead: false,
                                    Files: Data.Files,
                                }],
                                MsgAmount: __.deepCopy(sentMessage.Value[0].Message).length,
                            })
                            colleagueMessage.Value.push({
                                Colleague:{...receiver},
                                Message:[{
                                    Author:user,
                                    Text: Data.Message,
                                    Date: Data.Date,
                                    AttachedData: Data.AttachedData,
                                    MessageType: Data.MessageType,
                                    isRead: false,
                                    Files: Data.Files,
                                }],
                                MsgAmount: __.deepCopy(sentMessage.Value[0].Message).length,
                            })
                        }

                         // sentMessage.Value.splice(0,sentMessage.Value.length)
                         // colleagueMessage.Value.splice(0,colleagueMessage.Value.length)

                        sentMessage.Value = JSON.stringify(sentMessage.Value);
                        colleagueMessage.Value=JSON.stringify(colleagueMessage.Value);

                        //удалить setting
                        // allUserSettings[0].Value=JSON.stringify(allUserSettings[0].Value);
                        // let requestToDelete = new apiRequest(apiUrl.SETTINGS.DELETE_USER, {
                        //     collection : [allUserSettings[0]]
                        // });
                        //
                        // requestToDelete.execute(function (data) {
                        //         // следующее действие если есть цепочка
                        //         if (Next) {
                        //             Next.action(Next.params);
                        //         }
                        //     },
                        //     function (data) {
                        //         if (data.status === 401) {
                        //             dispatch({ type : AUTH_ACTIONS.IS_AUTH, payload : false });
                        //         }
                        //         console.log("Ошибка обновления пользовательских настроек:", data);
                        //     });


                        let request = new apiRequest(apiUrl.SETTINGS.UPDATE_USER, {
                            collection: [sentMessage]
                        });
                        request.execute(function (data) {

                                let result = __.deepCopy(data);
                                result[0].Value = JSON.parse(result[0].Value);

                                allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                                let allUserSettingsFindIndex = allUserSettings.findIndex(allUserSettings => allUserSettings.Id === result[0].Id);

                                dispatch({
                                    type: SETTINGS_ACTIONS.UPDATE_USER_SETTINGS_MESSENGER, payload: {
                                        item: result[0],
                                        index: allUserSettingsFindIndex
                                    }
                                });

                                dispatch({
                                    type :  MESSENGER_ACTION.ADD_TO_BUDDIES, payload : {
                                        item:{...receiver}
                                    }
                                });


                                let requestColleague = new apiRequest(apiUrl.SETTINGS.UPDATE_USER, {
                                    collection : [colleagueMessage]
                                });
                                requestColleague.execute(function (data) {
                                        // // следующее действие если есть цепочка
                                        // if (Next) {
                                        //     Next.action(Next.params);
                                        // }
                                    },
                                    function (data) {
                                        if (data.status === 401) {
                                            dispatch({ type : AUTH_ACTIONS.IS_AUTH, payload : false });
                                        }
                                        console.log("Ошибка обновления пользовательских настроек:", data);
                                    });

                                // следующее действие если есть цепочка
                                if (Next) {
                                    Next.action(Next.params);
                                }
                            },
                            function (data) {
                                if (data.status === 401) {
                                    dispatch({type: AUTH_ACTIONS.IS_AUTH, payload: false});
                                }
                                console.log("Ошибка обновления пользовательских настроек:", data);
                                if (Next?.actionIfError) {
                                    Next.actionIfError();
                                }
                            });
                    }
                }else{

                    let newSettingsItem = {
                            Name : Data.SettingName,
                            Value:[],
                            User : user,
                            ArmType : armType,
                        };
                    let newSettingItemForColleague={
                        Name : name,
                        Value:[],
                        User : receiver,
                        ArmType : armType,
                    }
                       newSettingsItem.Value.push({
                           Colleague:receiver,
                           Message:[{
                               Author:user,
                               Text: Data.Message,
                               Date: Data.Date,
                               AttachedData: Data.AttachedData,
                               MessageType: Data.MessageType,
                               isRead: false,
                               Files: Data.Files,
                           }],
                           MsgAmount:1,
                       })

                        newSettingItemForColleague.Value.push({
                        Colleague:user,
                        Message:[{
                            Author:user,
                            Text: Data.Message,
                            Date: Data.Date,
                            AttachedData: Data.AttachedData,
                            MessageType: Data.MessageType,
                            isRead: false,
                            Files: Data.Files,
                        }],
                            MsgAmount:1,
                     })

                        newSettingsItem.Value= JSON.stringify(newSettingsItem.Value);
                        newSettingItemForColleague.Value=JSON.stringify(newSettingItemForColleague.Value)

                    // delete Setting
                    // allUserSettings[0].Value=JSON.stringify(allUserSettings[0].Value);
                    //
                    // let requestToDelete = new apiRequest(apiUrl.SETTINGS.DELETE_USER, {
                    //     collection : [allUserSettings[0]]
                    // });
                    //
                    // requestToDelete.execute(function (data) {
                    //         // следующее действие если есть цепочка
                    //         if (Next) {
                    //             Next.action(Next.params);
                    //         }
                    //     },
                    //     function (data) {
                    //         if (data.status === 401) {
                    //             dispatch({ type : AUTH_ACTIONS.IS_AUTH, payload : false });
                    //         }
                    //         console.log("Ошибка обновления пользовательских настроек:", data);
                    //     });



                        let request = new apiRequest(apiUrl.SETTINGS.UPDATE_USER, {
                            collection : [newSettingsItem]
                        });
                        request.execute(function (data) {
                                let result = __.deepCopy(data);
                                result[0].Value = JSON.parse(result[0].Value);

                                allUserSettings = __.deepCopy(store.getState().globalState.userSettings);
                                let allUserSettingsFindIndex = allUserSettings.findIndex(allUserSettings => allUserSettings.Id === result[0].Id);

                                dispatch({
                                    type : SETTINGS_ACTIONS.UPDATE_USER_SETTINGS_MESSENGER, payload : {
                                        item : result[0],
                                        index : allUserSettingsFindIndex
                                    }
                                });

                                dispatch({
                                    type :  MESSENGER_ACTION.ADD_TO_BUDDIES, payload : {
                                      item:{...receiver}
                                    }
                                });

                                let requestColleague = new apiRequest(apiUrl.SETTINGS.UPDATE_USER, {
                                    collection : [newSettingItemForColleague]
                                });
                                requestColleague.execute(function (data) {
                                        // // следующее действие если есть цепочка
                                        // if (Next) {
                                        //     Next.action(Next.params);
                                        // }
                                    },
                                    function (data) {
                                        if (data.status === 401) {
                                            dispatch({ type : AUTH_ACTIONS.IS_AUTH, payload : false });
                                        }
                                        console.log("Ошибка обновления пользовательских настроек:", data);
                                    });
                                // следующее действие если есть цепочка
                                if (Next) {
                                    Next.action(Next.params);
                                }
                            },
                            function (data) {
                                if (data.status === 401) {
                                    dispatch({ type : AUTH_ACTIONS.IS_AUTH, payload : false });
                                }
                                console.log("Ошибка обновления пользовательских настроек:", data);
                                if (Next?.actionIfError) {
                                    Next.actionIfError();
                                }
                            });


                }

            }

        } catch (exception) {
            console.log("Ошибка обновления пользовательских настроек (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// сохраняем строку таблицы (документ) как активный
export const changeWrap = ({ Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let isWrap = !store.getState().globalState.app.isWrap;

            dispatch({type: SETTINGS_ACTIONS.CHANGE_WRAP, payload: isWrap});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const deleteMsgUserSettings = ({ Data = null,Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let allUserSettings = __.deepCopy(store.getState().globalState.userSettings);

            if (allUserSettings.findIndex(allUserSettings => allUserSettings.Name === Data.SettingName) >= 0) {

                let allUserSettingsFindIndex = allUserSettings.findIndex(allUserSettings => allUserSettings.Name === Data.SettingName)
                let sentMessage = allUserSettings[allUserSettingsFindIndex];

                //удалить setting
                sentMessage.Value = JSON.stringify(sentMessage.Value);

                let requestToDelete = new apiRequest(apiUrl.SETTINGS.DELETE_USER, {
                    collection : [sentMessage]
                });
                requestToDelete.execute(function (data) {

                        dispatch({
                            type : SETTINGS_ACTIONS.DELETE_USER_SETTING, payload : {
                                index : allUserSettingsFindIndex,
                            }
                        });
                        // следующее действие если есть цепочка
                        if (Next) {
                            Next.action(Next.params);
                        }
                    },
                    function (data) {
                        if (data.status === 401) {
                            dispatch({type: AUTH_ACTIONS.IS_AUTH, payload: false});
                        }
                        console.log("Ошибка удаления пользовательских настроек:", data);
                });
            }
        } catch (exception) {
            console.log("Ошибка удаления пользовательской настройки (catch): ", exception);
            if (Error) {
                Error.action(Error.params);
            } // если ошибка колбек
        }
    }
}



