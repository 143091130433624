import {store} from "../../../index";
import {MESSENGER_ACTION} from "../messengerActionList";
import {__} from "../../../tools/HelpFunctions";

export const makeReceiverActive = ({User = null, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            await dispatch({type: MESSENGER_ACTION.MAKE_RECEIVER_ACTIVE, payload: User})
            if (Next) {
                Next.action(Next.params);
            }// следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка получения активного пользователя(кому отсылать сообщение) (catch): ", exception);
            if (Error) {
                Error.action(Error.params);
            } // если ошибка колбек
        }
    }
}

export const readMessage = ({Date = null}) => {
    return async dispatch => {
        try {
            let messengerSetting = store.getState().globalState.userSettings.find(item => item.Name === 'messenger')
            let messengers = messengerSetting?.Value
            let receiverActive = store.getState().messenger.message.activeReceiver
            let users = __.deepCopy(store.getState().globalState.AllUsers.Records)
            let receiver = users.find(user => user.Id === receiverActive.Id)
            let currentMsgr = messengers.find(item => item.Colleague.Id === receiver.Id)

            for (let i = 0; i < currentMsgr.Message.length; i++) {
                if (currentMsgr.Message[i].Date < Date) {
                    currentMsgr.Message[i].IsRead = true;
                }
            }


        } catch (exception) {
            console.log("Ошибка получения даты прочтения сообщения (catch): ", exception);
        }
    }
}

export const addToBuddies = ({User = null}) => {
    return async dispatch => {
        try {
            await dispatch({type: MESSENGER_ACTION.ADD_TO_BUDDIES, payload: User})
        } catch (exception) {
            console.log("Ошибка добавления пользователя к друзья по переписке (catch): ", exception);
        }
    }
}

export const setReadMsgs = ({User = null, MsgAmount = null, MsgrUsers = [], Next = null, Error = null}) => {
    return async dispatch => {
        try {
            if (!User) {
                let array = store.getState().globalState.userSettings.filter(item => item.Name.includes('messenger'));
                for (let i = 0; i < array.length; i++) {
                    array[i] = {User: array[i].Value[0].Colleague, MsgAmount: array[i].Value[0].MsgAmount}
                }
                await dispatch({type: MESSENGER_ACTION.SET_READ_MSGS, payload: array})
            } else if (!MsgAmount) {
                let msgSettings = store.getState().globalState.userSettings?.filter(item => item.Name.includes('messenger'));
                let curReceiverSetting = msgSettings?.find(item => item.Value[0].Colleague?.Id === store.getState().messenger.message.activeReceiver?.Id)

                await dispatch({
                    type: MESSENGER_ACTION.SET_READ_MSGS,
                    payload: {User: User, MsgAmount: curReceiverSetting?.Value[0].MsgAmount}
                })
            } else {
                await dispatch({
                    type: MESSENGER_ACTION.SET_READ_MSGS, payload: {User: User, MsgAmount: MsgAmount}
                })
            }
            if (Next) {
                Next.action(Next.params);
            }// следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка установки прочитанных сообщений (catch): ", exception);
            if (Error) {
                Error.action(Error.params);
            } // если ошибка колбек

        }
    }
}

export const setNumberNewMsg = ({Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let currentMsg = __.deepCopy(store.getState().globalState.userSettings).filter(item => item.Name.includes('messenger'))
            let previousMsg = __.deepCopy(store.getState().messenger.message.readMsgs)
            let newMsgNumber = 0;

            for (let i = 0; i < currentMsg.length; i++) {
                let isExisted = previousMsg && Array.from(previousMsg)?.find(item => item.User.Id === currentMsg[i].Value[0].Colleague.Id)

                if (isExisted) {
                    if (isExisted.MsgAmount < currentMsg[i].Value[0]?.MsgAmount)
                        newMsgNumber += currentMsg[i].Value[0]?.MsgAmount - isExisted.MsgAmount
                } else {
                    newMsgNumber += currentMsg[i].Value[0]?.MsgAmount
                }
            }
            await dispatch({type: MESSENGER_ACTION.SET_NUMBER_NEW_MSG, payload: {Number: newMsgNumber}})
            if (Next) {
                Next.action(Next.params);
            }// следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка установки кол-ва новых сообщений сообщений (catch): ", exception);
            if (Error) {
                Error.action(Error.params);
            } // если ошибка колбек
        }
    }
}
