import React from "react";
import {connect} from "react-redux";
import {contextMenuTypes} from "../../../tools/StaticTypes";
import {
    setContextMenuData, setLoaderModalData
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {
    changeSortDirection,
    PackagesPaginationMovePage
} from "../../../store/packages/actionCreators/package_MainTableActionCreator";
import {
    getPackageGroupsForTree,
    PackageCreateTree, SelectActivePackageNode
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {
    handlerSearchPackages,
    updateInputsFieldPackage, updateInputsFieldSortingPackage
} from "../../../store/packages/actionCreators/package_InputActionCreator";
import {__} from "../../../tools/HelpFunctions";

class ContextMainTablePackageTh extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            scrollY : window.scrollY, // TODO: пока оставил позицию окна при контексте но если не будем юзать -  удалить
            scrollX : window.scrollX,
            isHover : false
        };

        this.name = contextMenuTypes.MainTableTR;

        this.search = this.search.bind(this);
    }

    componentDidMount() {
        document.onscroll = (event) => { // скрытие контекстного меню при скроле документа
            if(this.props.contextMenu.name === contextMenuTypes.MainTablePackageTH) {
                this.props.setContextMenuData({});
            }
        };
        document.onclick = (event) => {
            if(!this.state.isHover) {
                this.props.setContextMenuData({});
            }
        };
    }

    // отписываемся от событий при удалении элемента
    componentWillUnmount() {
        document.onscroll = null;
        document.onclick = null;
    }

    getCurrentSearchInputs() {
        let searchInputs;

        if (this.props.contextMenu.data.tableRole === "main") {
            searchInputs = this.props.searchInputsMain;
        } else if (this.props.contextMenu.data.tableRole === "additional") {
            searchInputs = this.props.searchInputsAdditional
        } else if (this.props.contextMenu.data.tableRole === "download") {
            searchInputs = this.props.searchInputsDownload
        }

        return searchInputs;
    }

    async search(Value) {
        const {contextMenu} = this.props
        this.props.setLoaderModalData({
            data: {
                content: "Загрузка...",
                disableButton: true,
                fullBackground: true,
                gif: "packages",
                key: "ThMainTable6963"
            }
        });

        let SortingParam = contextMenu.data.th.Name !== "Version.ModifyTime" ? contextMenu.data.th.Name : "ModifyDate"
        this.props.updateInputsFieldSortingPackage({TableRole: contextMenu.data.tableRole, SortingParam, Direction: Value})
        this.props.changeSortDirection({dir: Value, sortField: contextMenu.data.th})

        let searchInputs = this.getCurrentSearchInputs()

        this.props.updateInputsFieldPackage({
            TableRole: contextMenu.data.tableRole,
            IsSearch: searchInputs.IsSearch,
            RegDateAsString: searchInputs.RegDateAsString ?? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
            RegDatePackAsString: searchInputs.RegDatePackAsString ?? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
            RegNumber: searchInputs.RegNumber ?? {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
            Name: searchInputs.Name ?? {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
            NamePack: searchInputs.NamePack ?? {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
            TypeName: searchInputs.TypeName ?? {In: ''},
            ModifyDate: searchInputs.ModifyDate ?? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
            ModifyDatePack: searchInputs.ModifyDatePack ?? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
            AppliedParams: searchInputs.AppliedParams,
            Next: {
                action: async () => {
                    await this.props.PackagesPaginationMovePage({
                        PageNumber: 1,
                        TableType: contextMenu.data.tableRole,
                        FieldSorting: SortingParam,
                        Direction: Value,
                        Next: {
                            action: async () => {
                                __.deleteDocFromLinkUsingActiveNode(this.props.tableRole);
                                this.props.setContextMenuData({
                                    Next: {
                                        action: () => {
                                            this.props.setLoaderModalData({keyDeleted: "ThMainTablePackage123"});
                                        },
                                        params: {}
                                    }
                                });
                            },
                            params: {}
                        }
                    });
                }
            }
        })
    }


    render() {
        let contextStyle = {
            position : "fixed",
            top :  this.props.contextMenu.position.y,
            left :  this.props.contextMenu.position.x
        };

        return (
            <nav id="context-menu" className="context-menu" style={contextStyle} onContextMenu={(event) => {event.preventDefault()}}
                 onScroll={()=>{ this.props.setContextMenuData({})}}
                 onMouseEnter={(event) => {this.setState({isHover : true})}}
                 onMouseLeave={(event) => {this.setState({isHover : false})}}>
                <ul className="context-menu__items">
                    <li className="context-menu__item" onClick={(event) => {this.search(2)}} style={{cursor : "pointer"}}>
                        <span className="context-menu__link up">
                            <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-3">
                                <i className="svg-icon svg-icon-lg icon-color-gray icon-Filter_up"> </i>
                            </span>
                            Сортировка А-Я
                        </span>
                    </li>
                    <li className="context-menu__item" onClick={(event) => {this.search(1)}} style={{cursor : "pointer"}}>
                        <span className="context-menu__link down">
                            <span className="svg-icon menu-icon nav-icon active svg-icon-success mr-3">
                                <i className="svg-icon svg-icon-lg icon-color-gray icon-Filter_down"> </i>
                            </span>
                            Сортировка Я-А
                        </span>
                    </li>
                </ul>
            </nav>
        );
    }
}

const  mapStateToProps = state => {
    return {
        contextMenu :  state.globalState.app.contextMenu,

        activeNodeId: state.packages.tree.activeNode.Node.Id,
        pageSize: state.packages.mainTable.paginationPageSize,
        searchInputsMain: state.packages.tree.searchInputs,
        searchInputsAdditional: state.packages.treeAdditional.searchInputs,
        searchInputsDownload: state.packages.treeDownload.searchInputs,

        activeNode: state.packages.tree.activeNode,
        activeNodeAdditional: state.packages.treeAdditional.activeNode,
        activeNodeDownload: state.packages.treeDownload.activeNode,
        changeViewModeSearch: state.document.rootState.viewModeSearch
    }
}

const  mapDispatchToProps = {
    changeSortDirection,
    handlerSearchPackages,
    PackageCreateTree,
    PackagesPaginationMovePage,
    updateInputsFieldPackage,
    updateInputsFieldSortingPackage,
    setLoaderModalData,
    getPackageGroupsForTree,
    SelectActivePackageNode,
    setContextMenuData
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextMainTablePackageTh);

