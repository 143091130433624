import {apiRequest} from "../../../tools/API/apiRequest";
import {apiUrl} from "../../../tools/API/apiUrl";
import {TreeCombine} from "../../../tools/TreeCombine";
import {MAIN_TABLE_ACTIONS, TREE_ACTIONS} from "../documentActionsList";
import {AUTH_ACTIONS} from "../../globalState/globalStateActionsList";
import {EntityFilters, Filter, FiltersType, Sorting} from "../../../tools/Filters";
import {__, HelpFunctions} from "../../../tools/HelpFunctions";
import {store} from "../../../index";
import {AdminLevelEnum} from "../../../tools/StaticTypes";

// загрузить документы для отображения в таблице;
// ActiveNode -> выбранный узел дерева;
// PaginationPageSize -> кол-во строк отображаемых в таблице;
// PaginationPageNumber -> номер страницы пагинации;
// Filial -> активный филиал;
export const mainTableDownloadDocCollection = ({id=null,ActiveNode = null, PaginationPageSize = null, PaginationPageNumber = null, Filial = null, Next = null, Error = null}) =>  {
    return async dispatch => {
        try {
            // Node.parent.info.Id -> это IdDocGroup
            // Node.info.Id -> это PropertyName: "Type.Id"

            let Node = null;
            if(ActiveNode != null) {
                Node = {...ActiveNode};
                //TODO aa 04.12.2023 Задача № 24716 добавлен await
                Node.parent = await TreeCombine.searchByTreeId({treeId : Node.parent});
            } else {

                Node = {...store.getState().document.tree.activeNode};
                if (!Node.parent)  Node = __.deepCopy(store.getState().document.tree.docs.find(item => id === item.Id))
                //TODO aa 04.12.2023 Задача № 24716 добавлен await
                Node.parent = await TreeCombine.searchByTreeId({treeId : Node.parent});
            }
            if(PaginationPageSize == null) {
                PaginationPageSize = store.getState().document.mainTable.paginationPageSize;
            }

            if(PaginationPageNumber == null) {
                PaginationPageNumber = store.getState().document.mainTable.paginationPageNumber;
            }

            if(Filial == null) {
                Filial =  {...store.getState().globalState.filial.Active};
            }
            let request
            if(id){
                 request = new apiRequest(apiUrl.DOCUMENTS.DOC_COLLECTION, {
                    filter : new Filter(FiltersType.ENTITY)
                        .addAttribute({Name: "IdDocGroup", Value: Node.parent.info.Id})
                        .addAttribute({Name: "PagingFilter", Value: {PageSize : PaginationPageSize, PageOffset : (PaginationPageNumber - 1) * PaginationPageSize}})
                        .add(
                            new EntityFilters().setEntityName("DocExtended")
                                .add({Name: "Type.Id", Value: Node.info.Id})
                                .add({Name: "IdFilial", Value: Filial.Id}).get()
                        ).get(),
                    id:id,
                    sorting : new Sorting()
                        .addAttribute({Name: "IdAttributeName", Value: "-1"})
                        .add({PropertyName: "RegDate", Direction: 2})
                        .add({PropertyName: "RegNumber", Direction: 1})
                        .add({PropertyName: "Id", Direction: 1})
                        .get()
                });
            } else {
                request = new apiRequest(apiUrl.DOCUMENTS.DOC_COLLECTION, {
                    filter : new Filter(FiltersType.ENTITY)
                        .addAttribute({Name: "IdDocGroup", Value: Node.parent.info.Id})
                        .addAttribute({Name: "PagingFilter", Value: {PageSize : PaginationPageSize, PageOffset : (PaginationPageNumber - 1) * PaginationPageSize}})
                        .add(
                            new EntityFilters().setEntityName("DocExtended")
                                .add({Name: "Type.Id", Value: Node.info.Id})
                                .add({Name: "IdFilial", Value: Filial.Id}).get()
                        ).get(),

                    sorting : new Sorting()
                        .addAttribute({Name: "IdAttributeName", Value: "-1"})
                        .add({PropertyName: "RegDate", Direction: 2})
                        .add({PropertyName: "RegNumber", Direction: 1})
                        .add({PropertyName: "Id", Direction: 1})
                        .get()
                });
            }


            await request.execute(async function (data) {

                if(id) {
                    await dispatch(setPaginationPageNumber({PaginationPageNumber : Math.ceil((data.RecordsOffset + PaginationPageSize) / PaginationPageSize)}));
                }

                await dispatch({
                    type: MAIN_TABLE_ACTIONS.DOWNLOAD_DOC_COLLECTION, payload: {
                        docs: data.Records,
                        recordsOffset:data.RecordsOffset,
                        totalInServer: data.TotalRecordsCount,
                    }
                });

                if (Next) {
                    Next.action(Next.params);
                } // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                    //TODO Задача № 23189
                    if (store.getState().document.tree.isLoading)
                        dispatch({type: TREE_ACTIONS.TOGGLE_IS_LOADING})
                }
                console.log("Ошибка получения коллекции документов (post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения коллекции документов (catch): ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}
export const testPackage = ({ Next = null, Error = null})=>{
    return async dispatch => {
        try {
            let docs = store.getState().document.mainTable.collection.docs;
            let docsIds=[];
            for (let i = 0; i < docs.length; i++) {
                docsIds.push(docs[i].Id)
            }
            let request = new apiRequest(apiUrl.PACKAGE.LOAD, {
                filter : new Filter(FiltersType.ENTITY)
                    .add(
                        new EntityFilters().setEntityName("PackageDocRef")
                            .add({Name: "IdDoc", Value: docsIds, Operator: 8})
                            .addAttribute({Name : "ReferenceProperty", Value : "IdPackage"})
                            .addAttribute({Name : "RootEntityProperty", Value : "Id"})
                            .get()
                    ).add(new EntityFilters().setEntityName("DocPackage").get()).get(),
                sorting : null
            });
            request.execute(function (data){
            })
        }catch (exception) {
            console.log("Ошибка объединения атрибутов таблицы (catch): ", exception);
            // если ошибка колбек
            if(Error) {Error.action(Error.params);}
        }
    }
}

// сборка данных для отображения таблицы;
// ActiveNode - выбранный узел дерева;
// Filial - выбранный филиал
export const combineMainTableAttributes = ({ActiveNode = null, Filial = null, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            // Node.parent.info.Id -> это IdDocGroup
            // Node.info.Id -> это PropertyName: "Type.Id"

            let Node = null;
            if(ActiveNode != null) Node = {...ActiveNode};
            else Node = {...store.getState().document.tree.activeNode};
            //TODO aa 04.12.2023 Задача № 24716 добавлен await
            Node.parent = await TreeCombine.searchByTreeId({treeId : Node.parent});

            if(Filial == null) Filial =  {...store.getState().globalState.filial.Active};

            // вызываем store так как если передавать через очередь, то данные будут не обновлены
            let UserSettings = store.getState().globalState.userSettings;
            let Docs = store.getState().document.mainTable.collection.docs;

            // проверяем есть ли пользовательские настройки для данной таблицы
            let UserSettingsTable = HelpFunctions.CheckUserSettingForTable({
                userSettings : UserSettings,
                IdDocGroup: Node.parent.info.id,
                TypeId: Node.info.Id,
                IdFilial: Filial.Id,
            });

            // заголовки таблицы
            let HeaderColumns = [];
            // строки таблицы
            let BodyColumns = [];
            // аттрибуты все которые пришли
            // let AllAttributes = [];

            //TODO Задача № 21790
            let TypeAttributes = __.deepCopy(Node).info.TypeAttributes.sort((a, b) =>parseFloat(a.FieldOrder) - parseFloat(b.FieldOrder));

            HeaderColumns.push({Name :  "Version.Version", Value : "Версия", IsConstant : true, UserVisible : true});
            HeaderColumns.push({Name :  "RegDate", Value : "Дата", IsConstant : true, UserVisible : true});
            HeaderColumns.push({Name :  "RegNumber", Value : "Номер", IsConstant : true, UserVisible : true});
            HeaderColumns.push({Name :  "Name", Value : "Наименование", IsConstant : true, UserVisible : true});

            // для необязательных атрибутов
            for (let i = 0; i < TypeAttributes.length; i++) {
                let lUserVisible = TypeAttributes[i].IsVisibleInRegistry
                if (TypeAttributes[i].IsInvisible) {
                    // TODO SS!! поменять ">" на "<"
                        if (store.getState().globalState.user.AdminLevel < AdminLevelEnum.Verifier) {
                            lUserVisible = false;
                    }
                }
                // if(TypeAttributes[i].IsVisibleInRegistry) {
                    HeaderColumns.push({
                        Name : "PropertyName",
                        Value : TypeAttributes[i].AttributeName.Name,
                        IdAttributeName : TypeAttributes[i].AttributeName.Id,
                        IdAttributeType : TypeAttributes[i].AttributeName.IdAttributeType,
                        AttributeName : TypeAttributes[i].AttributeName,
                        IsConstant : false,
                        UserVisible : lUserVisible
                    });
                // }

                // AllAttributes.push({
                //     Name : "PropertyName",
                //     Value : TypeAttributes[i].AttributeName.Name,
                //     IdAttributeName : TypeAttributes[i].AttributeName.Id,
                //     AttributeName : TypeAttributes[i].AttributeName,
                //     InRegistry : lUserVisible
                // });
            }
            HeaderColumns.push({Name :  "Version.ModifyTime", Value : "Последнее изменение", IsConstant : true, UserVisible : true});

            for (let i = 0; i < Docs.length; i++) {
                let BodyColumnsTR = [];

                BodyColumnsTR.push({Name : "Version.Version", Value : Docs[i].Version.Version, IsConstant : true, UserVisible : true});
                BodyColumnsTR.push({Name : "RegDate", Value : Docs[i].RegDate, IsConstant : true, UserVisible : true});
                BodyColumnsTR.push({Name : "RegNumber", Value : Docs[i].RegNumber, IsConstant : true, UserVisible : true});
                BodyColumnsTR.push({Name : "Name", Value : Docs[i].Name, IsConstant : true, UserVisible : true});

                const additionalAttributes = __.transformAttributesData({
                    attributesData: Docs[i].Attributes,
                    docTypeId: Node.info.Id,
                    newValueFieldName: 'Value',
                    needAllAttributesOnType: true,
                })
                // TODO КН 10.04.24 Унифицирую здесь, а не у компоненты, т.к. уже здесь заполняется таблица
                const unifiedAttributes = __.convertAttributesArrayToUnique(additionalAttributes, 'Value')
                BodyColumnsTR = BodyColumnsTR.concat(unifiedAttributes)
                BodyColumnsTR.push({Name :  "Version.ModifyTime", Value : Docs[i].Version.ModifyTime, IsConstant : true, UserVisible : true});

                // TODO TP № 22175
                // собираем все атрибуты для дополнительных секций
                // let AllAttributesValue = [];
                // AllAttributes.forEach(attr => {
                //     const foundAttribute = Docs[i].Attributes && Docs[i].Attributes.filter(item => item.AttributeName.Id === attr.AttributeName.Id);
                //     if (foundAttribute && foundAttribute.length > 0) {
                //         for (let index in foundAttribute) {
                //             AllAttributesValue.push({
                //                 Name: "PropertyName",
                //                 Value: foundAttribute[index].AttributeValue,
                //                 IdAttributeName: foundAttribute[index].AttributeName.Id, // ?? attr.AttributeName.Id
                //                 IdAttributeType: foundAttribute[index].AttributeName.IdAttributeType, // ?? attr.AttributeName.IdAttributeType
                //                 // TODO КН 25.03.24 Добавил AttributeName т.к. оно есть в аналогичном коде Пакетов
                //                 AttributeName : foundAttribute[index].AttributeName,
                //                 ColumnName: foundAttribute[index].AttributeName.Name, // ?? attr.AttributeName.Name
                //                 IsConstant : false
                //             });
                //         }
                //     }
                // })

                BodyColumns.push({
                    tr : BodyColumnsTR,
                    Version : Docs[i].Version,
                    Type : Docs[i].Type,
                    // AllAttributes : AllAttributesValue,
                    Info: Docs[i],
                });
            }
            // применяем полученные настройки для заголовков
            if(UserSettingsTable.isFind) {
                let SortedHeaderColumns = [];

                let index = 0;
                for(let i = 0; i < UserSettingsTable.data.columns.length; i++) {
                    for(let j = 0; j < HeaderColumns.length; j++) {
                        if(HeaderColumns[j].Name === UserSettingsTable.data.columns[i].Name) {
                            if(HeaderColumns[j].Value === UserSettingsTable.data.columns[i].Value) {
                                SortedHeaderColumns.push(HeaderColumns[j]);
                                SortedHeaderColumns[index].UserVisible = UserSettingsTable.data.columns[i].Visible;
                                index++;
                                break;
                            }
                        }
                    }
                }
                HeaderColumns = SortedHeaderColumns;

                let NewBodyDefinition = HelpFunctions.sortTableBodyByHeaders({
                    ColumnDefinition : HeaderColumns,
                    BodyDefinition : BodyColumns
                });
                await dispatch({

                    type : MAIN_TABLE_ACTIONS.COMBINE_ATTRIBUTE,
                    payload :
                        {
                            Headers : HeaderColumns,
                            Body : NewBodyDefinition,
                        }
                });

            } else {
                await dispatch({
                    type : MAIN_TABLE_ACTIONS.COMBINE_ATTRIBUTE,
                    payload :
                        {
                            Headers : HeaderColumns,
                            Body : BodyColumns,
                        }
                });
            }

            // следующее действие если есть цепочка
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка объединения атрибутов таблицы (catch): ", exception);
            // если ошибка колбек
            if(Error) {Error.action(Error.params);}
        }
    }
}

// сброс таблицы перед ее обновлением
// сбрасываем все кроме настроек
// TODO: не знаю нужна ли эта функция вообще
export const resetMainTableForUpdate = ({lObj = null, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            // TODO tp если применены фильтры, то не сбрасывать, если вызовет проблемы убрать
            // if(store.getState().document.search.isSearch) {
            //     return
            // }
            let settings = {...store.getState().document.mainTable.settings};
            if (lObj) {
                const lPageNum = (Number(lObj.newSize) != 0 && lObj.newSize) ? Math.ceil((((lObj.activePage-1) * lObj.paginationPageSize)+1) / Number(lObj.newSize)) : 1;

                if (lPageNum >= 1)
                    settings = {
                        ...store.getState().document.mainTable.settings,
                        lRecordsOffset: lPageNum
                };
            } else {
                settings = {
                    ...store.getState().document.mainTable.settings,
                    lRecordsOffset: 1
                };
            }

            await dispatch({type : MAIN_TABLE_ACTIONS.RESET_FOR_UPDATE, payload : {settings}});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка сброса таблицы для обновления (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


// установить кол-во строк в таблице для отображения
export const setPaginationPageSize = ({PaginationPageSize, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : MAIN_TABLE_ACTIONS.SET_PAGINATION_ON_PAGE_SIZE, payload : Number.parseInt(PaginationPageSize)});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка изменения кол-ва строк в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// установить номер страницы пагинации в таблице
export const setPaginationPageNumber = ({PaginationPageNumber, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : MAIN_TABLE_ACTIONS.SET_PAGINATION_PAGE_NUMBER, payload : Number.parseInt(PaginationPageNumber)});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка изменения страницы пагинации в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

//  сохраняем документ (строку в таблице) как отмеченную
export const addRowToChecked = ({IdDoc, Next = null, Error = null}) => {
    return async dispatch => {
        try {

            let checkedFields = store.getState().document.mainTable.checkedFields;
            let result =  Array.from(new Set(checkedFields).add(IdDoc));

            dispatch({type : MAIN_TABLE_ACTIONS.ADD_ROW_TO_CHECKED, payload : result});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка добавления строки к отмеченным (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


// удаляем документ (строку в таблице) из отмеченных
export const deleteRowFromChecked = ({IdDoc, Next = null, Error = null}) => {
    return async dispatch => {
        try {

            let checkedFields = store.getState().document.mainTable.checkedFields;
            let checkedFields_Set = new Set(checkedFields);
            checkedFields_Set.delete(IdDoc);
            let result = Array.from(checkedFields_Set);

            dispatch({type : MAIN_TABLE_ACTIONS.DELETE_ROW_FROM_CHECKED, payload : result});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// direction
export const changeSortDirection = ({dir, sortField, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : MAIN_TABLE_ACTIONS.CHANGE_SORT_DIRECTION, payload : {dir, sortField}});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}
// добавляем все документы (строки в таблице) к отмеченным
// или снимаем выделение со всех строк
// если указать Check (true/false), то насильно отметим все строки
// или снимем выделение со всех строк,
// если не указать, то будет проверка на то, отмечены
// ли все строки или нет
export const checkAllRowsOnPage = ({Check = null,Next = null, Error = null}) => {
    return async dispatch => {
        try {

            let checkedFields = [...store.getState().document.mainTable.checkedFields];
            let body = [...store.getState().document.mainTable.body];


            let countIsDelete = body.filter(item => item.Info.IsMarkedForDelete);
            countIsDelete = countIsDelete?.length ? countIsDelete.length : 0;

            if(Check == null) {
                // если отмечены все строки
                if(checkedFields.length + countIsDelete >= body.length) {
                    dispatch({type : MAIN_TABLE_ACTIONS.CHECK_ALL_ROWS_ON_PAGE, payload : []});
                } else {
                    let newCheckedFields = new Set([]);
                    for(let i = 0; i < body.length; i++) {
                        if(!body[i].Info.IsMarkedForDelete) {
                            newCheckedFields.add(body[i].Version.IdDoc);
                        }
                    }
                    dispatch({type : MAIN_TABLE_ACTIONS.CHECK_ALL_ROWS_ON_PAGE, payload : Array.from(newCheckedFields)});
                }
            } else {
                if (Check) {
                    let newCheckedFields = new Set([]);
                    for(let i = 0; i < body.length; i++) {
                        if(!body[i].Info.IsMarkedForDelete) {
                            newCheckedFields.add(body[i].Version.IdDoc);
                        }
                    }
                    dispatch({type : MAIN_TABLE_ACTIONS.CHECK_ALL_ROWS_ON_PAGE, payload : Array.from(newCheckedFields)});
                } else {
                    dispatch({type: MAIN_TABLE_ACTIONS.CHECK_ALL_ROWS_ON_PAGE, payload: []});
                }
            }

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const setCloseOpenedTabsFlag = ({flag = false, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : MAIN_TABLE_ACTIONS.CLOSE_OPENED_TABS, payload : flag});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка установки флага на удаление вкладок (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


// сохраняем строку таблицы (документ) как активный
export const selectActiveRow = ({RowInfo = null, IdDoc = null, Next = null, Error = null}) => {
    return async dispatch => {
        try {

            if(IdDoc && !RowInfo) {
                let foundRow = store.getState().document.mainTable.body.find(item => item.Info.Id == IdDoc);
                if(foundRow) {
                    dispatch({type: MAIN_TABLE_ACTIONS.SELECT_ACTIVE_ROW, payload: foundRow});
                }
            }
            else {
                dispatch({type: MAIN_TABLE_ACTIONS.SELECT_ACTIVE_ROW, payload: RowInfo});
            }

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаления строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}



// поиск по документам;
// ActiveNode -> выбранный узел дерева;
// PaginationPageSize -> кол-во строк отображаемых в таблице;
// PaginationPageNumber -> номер страницы пагинации;
// Filial -> активный филиал;
export const mainTableSearchDoc = ({FieldSorting=null,
                                       Direction=null,
                                       PaginationPageNumber=null,
                                       PaginationPageSize=null,
                                       Filial = null,
                                       Next = null,
                                       Error = null}) => {
    return async dispatch => {
        try {
            dispatch(setPaginationPageNumber({
                PaginationPageNumber : PaginationPageNumber ? PaginationPageNumber : 1
            }));


            if(Direction !== null) {
                if(Direction.indexOf('icon-Filter_up') >= 0){
                    Direction = 1
                } else {
                    Direction = 2
                }
            }
            if(Filial == null) {
                Filial =  {...store.getState().globalState.filial.Active};
            }
            if(PaginationPageSize == null) {
                PaginationPageSize = store.getState().document.mainTable.paginationPageSize;
            }

            if(PaginationPageNumber == null) {
                PaginationPageNumber = store.getState().document.mainTable.paginationPageNumber;
            }

            let Type_id = store.getState().document.tree.activeNode.info.Id;
            let inputs = store.getState().document.search.searchInputs;
            let filter = new Filter(FiltersType.ENTITY)
            filter.add(
                new EntityFilters().setEntityName("DocExtended")
                    .add({Name: "Type.Id", Value: Type_id})
                    .add({Name: "IdFilial", Value: Filial.Id})
                    .get()
            )
            for(let i = 0; i < inputs.length; i++) {
                if (typeof inputs[i].Value !== "string") {

                    //TODO aa 17.03.2023 без этого не работает сортировка когда открыты фильтры
                    let lProperties = Object.values(inputs[i].Value);
                    if (lProperties && lProperties.length > 0) {
                        let lContinue = true;
                        for (let j = 0; j < lProperties.length; j++) {
                           if (lProperties[j] != "")
                            {
                                lContinue = false;
                                break;
                            }
                        }
                        if (lContinue)
                            continue;
                    }

                    let entity;
                    entity = __.setEntityFilters(inputs[i]);
                    if(!inputs[i].isConstant) {
                        entity.add({Name: "AttributeName.Id", Value: inputs[i].Attribute.IdAttributeName})
                            .addAttribute({Name : "ReferenceProperty",Value:"IdDoc"})
                            .addAttribute({Name : "RootEntityProperty",Value:"Id"});
                    }
                    filter.add(entity.get());
                } else {
                if (inputs[i].isConstant && inputs[i].Value !== "") {
                    if (inputs[i].Attribute === "Наименование" || inputs[i].Attribute === "Name") {
                        filter.add(
                            new EntityFilters().setEntityName("DocExtended")
                                .add({Name: "Name", Value: inputs[i].Value, Operator: 2})
                                .get()
                        )
                    } else if (inputs[i].Attribute === "Номер" || inputs[i].Attribute === "RegNumber") {

                        filter.add(
                            new EntityFilters().setEntityName("DocExtended")
                                .add({Name: "RegNumber", Value: inputs[i].Value, Operator: 2})
                                .get()
                        )
                    } else if (inputs[i].Attribute === "Дата" || inputs[i].Attribute === "RegDate") {

                        filter.add(
                            new EntityFilters().setEntityName("DocExtended")
                                .add({Name: "RegDate", Value: inputs[i].Value, Operator: 2})
                                .get()
                        )
                    } else if (inputs[i].Attribute === "Последнее изменение" || inputs[i].Attribute === "Version.ModifyTime") {

                        filter.add(
                            new EntityFilters().setEntityName("DocExtended")
                                .add({Name: "Version.ModifyTime", Value: inputs[i].Value, Operator: 2})
                                .get()
                        )
                    }

                } else if (!inputs[i].isConstant && inputs[i].Value !== "") {
                    filter.add(
                        new EntityFilters().setEntityName("DocAttribute")
                            .add({Name: "AttributeName.Id", Value: inputs[i].Attribute.IdAttributeName})
                            .add({Name: "AttributeValue", Value: inputs[i].Value, Operator: 2})
                            .addAttribute({Name: "ReferenceProperty", Value: "IdDoc"})
                            .addAttribute({Name: "RootEntityProperty", Value: "Id"})
                            .get()
                    )
                }
            }

            }
            filter.addAttribute({Name: "PagingFilter", Value: {PageSize : PaginationPageSize, PageOffset : (PaginationPageNumber - 1) * PaginationPageSize}})

            let idDocGroup = store.getState().router.location.query.g;
            // TODO MS: НЕ ТРОГАТЬ!
            filter.addAttribute({Name: "IdDocGroup", Value: idDocGroup});


            let sorting = new Sorting();
            if(FieldSorting==null || FieldSorting == ""){
                sorting.addAttribute({Name: "IdAttributeName", Value: "-1"})
                sorting.add({PropertyName: "RegDate", Direction: 2})
                sorting.add({PropertyName: "RegNumber", Direction: 1})
                sorting.add({PropertyName: "Id", Direction: 1})
            }else {
                if(FieldSorting.isConstant || FieldSorting.IsConstant){
                    sorting.addAttribute({Name: "IdAttributeName", Value: "-1"})
                    if(FieldSorting.Attribute=="Наименование" || FieldSorting.Value==="Наименование"){
                        sorting.add({PropertyName: "Name", Direction: Direction})
                    }else if(FieldSorting.Attribute=="Номер" || FieldSorting.Value==="Номер"){
                        sorting.add({PropertyName: "RegNumber", Direction: Direction})
                    }else if(FieldSorting.Attribute=="Дата"|| FieldSorting.Value==="Дата"){
                        sorting.add({PropertyName: "RegDate", Direction: Direction})
                    }
                    else if(FieldSorting.Attribute=="Последнее изменение"|| FieldSorting.Value==="Последнее изменение"){
                        sorting.add({PropertyName: "Version.ModifyTime", Direction: Direction})
                    }

                }else{

                    if(FieldSorting.IdAttributeName){
                        sorting.addAttribute({Name:"IdAttributeName",Value:FieldSorting.IdAttributeName})

                    }else{
                        sorting.addAttribute({Name:"IdAttributeName",Value:FieldSorting.Attribute.IdAttributeName})
                    }
                    // sorting.addAttribute({Name:"IdAttributeName",Value:FieldSorting.Attribute.IdAttributeName})
                    sorting.add({PropertyName: "SortingAttributeValue", Direction: Direction})
                }

            }


            filter = filter.get();
            sorting = sorting.get();
            let request = new apiRequest(apiUrl.DOCUMENTS.DOC_COLLECTION,{
                filter,
                sorting
            });


            request.execute(async function(data) {
                await dispatch({type : MAIN_TABLE_ACTIONS.DOWNLOAD_DOC_COLLECTION, payload : {
                        docs : data.Records,
                        totalInServer: data.TotalRecordsCount,
                    }});
                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения коллекции документов (post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения коллекции документов (catch): ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const setDefaultWidthOfColumn = ({updatedHeaders = [], DefaultWidth = 0,  Next = null, Error = null}) => {
    return async dispatch => {
        try {
            if(updatedHeaders){
                dispatch({type: MAIN_TABLE_ACTIONS.SET_DEFAULT_WIDTH_OF_COLUMN, payload: updatedHeaders});
            }

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка присваивания изначальной ширины столбца (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const getActiveDoc=({Id=null})=>{
    return async dispatch=>{
        try{
            let fullInfoDoc = await __.CheckDocumentExistOrAccess(Id)
            if (!fullInfoDoc || fullInfoDoc?.errorMessage) {
                throw (fullInfoDoc?.errorMessage)
            }
            let request = new apiRequest(apiUrl.DOCUMENTS.DOC_COLLECTION, {
                filter : new Filter(FiltersType.ENTITY)
                    .addAttribute({Name: "IdDocGroup", Value: fullInfoDoc.IdDocGroup})
                    .addAttribute({Name: "PagingFilter", Value: {PageSize : 1, PageOffset : 0}})
                    .add(
                        new EntityFilters().setEntityName("DocExtended")
                            .add({Name: "Type.Id", Value: fullInfoDoc.IdDocType})
                            .add({Name: "IdFilial", Value: fullInfoDoc.IdFilial}).get()
                    ).get(),
                id:Id,
                sorting : new Sorting()
                    .addAttribute({Name: "IdAttributeName", Value: "-1"})
                    .add({PropertyName: "RegDate", Direction: 2})
                    .add({PropertyName: "RegNumber", Direction: 1})
                    .add({PropertyName: "Id", Direction: 1})
                    .get()
            });
            await request.execute(async function (data) {
               // console.log("dataDocCollection",data)
               dispatch({type: MAIN_TABLE_ACTIONS.GET_ACTIVE_DOC, payload: {Doc:data.Records.at(0)}})
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка получения коллекции документов (post): ", data);
            });

        }catch(exception){
            console.log("Ошибка присваивания изначальной ширины столбца (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}




