import React from "react";
import {connect} from "react-redux";
import {setModalData, setUserModalShow} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import TreeFilialFolder from "../../tree/filifal/TreeFilialFolder";
import TreeSearchInPackagesRight from "../../tree/packages/right/TreeSearchInPackagesRight";
import {ActionQueue} from "../../../store/rootReducer";
import TreeDocsPackagesRight from "../../tree/packages/right/TreeDocsPackagesRight";
import {
    changeViewFilter,
    copyDocumentTreeForPackages
} from "../../../store/packages/actionCreators/package_MainTableActionCreator";
import {AUTH_logout} from "../../../store/globalState/actionCreators/globalState_AuthActionCreator";
import InputSearchDocPackageDocumentsRight from "../../inputSearch/inputSearchDocPackageDocumentsRight";
import {
    createTree_packagesDocumentRight,
    getDocumentGroupsForTree_packagesDocumentRight,
    getDocumentsForTree_packagesDocumentRight
} from "../../../store/packages/actionCreators/package_DocumentRightActionCreator";
import TreePackages from "../../tree/packages/TreePackages";
import TreePackageSearchDownload from "../../tree/packagesDownload/TreePackageSearchDownload";
import TreePackagesDownload from "../../tree/packagesDownload/TreePackagesDownload";
import {setFilial} from "../../../store/globalState/actionCreators/globalState_UserActionCreator";
import {updateUserSettings} from "../../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import {
    ModalTypes,
    PackagesViewFilters,
    TableTypesEnum,
    UserSettingsActionsTypes,
    UserSettingsNames
} from "../../../tools/StaticTypes";
import {treeSearchFilial} from "../../../store/filials/actionCreators/filial_TreeActionCreator";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import {API} from "../../../tools/API_NEW/API";
import {Routing} from "../../../tools/routing/Routing";
import {ColorTheme, history, store} from "../../../index";
import Avatar  from 'react-avatar';
import {MyTooltip} from "../tooltip/MyTooltip";
import InputSearchPackage from "../../inputSearch/inputSearchPackage";
import Accordion from 'react-bootstrap/Accordion';
import TreeSearchPackagesRight from "../../tree/packages/right/TreeSearchPackagesRight";
import TreePackagesFolderSearch from "../../mainTable/Package/TreePackagesFolderSearch";

// modal.data.content => обязательно
class UserModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contentOnHover : false,
            isSettingsShow: false,

            isPasswordUpdateShow : false,
            changeColorTooltipShow : false,
            settingsTooltipShow : false,
            documentIncreaseScope: false,
            packageIsIncreaseScope: false,

            isShowInputOldPass: false,
            isShowInputNewPass: false,
            isShowInputRepeatPass: false,

            treeSearchInPackagesRightValue: "",
            treeSearchInPackagesDownload: "",
            treeSearchInDocsRightValue: ""
        };

        this.hide = this.hide.bind(this);
        this.renderByUserType = this.renderByUserType.bind(this);
        this.renderByTreeDocumentsSearch = this.renderByTreeDocumentsSearch.bind(this);
        this.renderByTreeDownloadPackages = this.renderByTreeDownloadPackages.bind(this);

        this.setFilial = this.setFilial.bind(this);
        this.inputOnChange = this.inputOnChange.bind(this);
        this.startSearch = this.startSearch.bind(this);
        this.clearInput = this.clearInput.bind(this);
        this.updatePassword = this.updatePassword.bind(this);

        this.oldPasswordRef = React.createRef();
        this.newPasswordRef = React.createRef();
        this.repeatPasswordRef = React.createRef();
        this.changeColorRef = React.createRef();
        //this.changePasswordRef = React.createRef();
        this.settingsRef = React.createRef();
    }

    // скрывать по кнопке
    hide(lRefreshPackages) {
       this.props.setUserModalShow({
           isShow : false
       });

        //TODO - отжатие кнопки Не показывать пакеты
        if (lRefreshPackages)
        {
            let lFlagSearch = false;

            let lTree = store.getState().packages.treeAdditional;
            if (this.props.userModalTableRole == "additional")
                lTree = store.getState().packages.treeAdditional
            else if (this.props.userModalTableRole == "download")
                lTree = store.getState().packages.treeDownload

            lFlagSearch = lTree.isSearch;
            if (!lFlagSearch)
                this.props.changeViewFilter({
                    Type: "packages",
                    TableType : this.props.userModalTableRole,
                    lStoreValue : PackagesViewFilters.OnlyDocuments,
                });
        }
    }

    componentDidMount() {
        if(!this.props.tree.isLoaded) { // проверить загружены ли данные, чтобы предотвратить повторную загрузку
            // this.props.ActionQueue({ // загружаем необходимые для работы приложения данные
            //     List : [
            //         {action : this.props.getDocumentsForTree_packagesDocumentRight, params : {filial : this.props.filial}, name : "getDocumentsForTree_packagesDocumentRight"},
            //         {action : this.props.getDocumentGroupsForTree_packagesDocumentRight, params : {filial : this.props.filial}, name : "getDocumentGroupsForTree_packagesDocumentRight"},
            //         {action : this.props.createTree_packagesDocumentRight, params : {}, name : "createTree_packagesDocumentRight"},
            //     ],
            //     debug : true,
            // });
        } else if(!this.props.tree.isLoaded) {
            this.props.copyDocumentTreeForPackages({});
        }

        const { documentIncreaseScope, packageIsIncreaseScope } = this.props;
        this.setState({
            documentIncreaseScope: documentIncreaseScope ? JSON.parse(documentIncreaseScope) : false,
            packageIsIncreaseScope: packageIsIncreaseScope ? JSON.parse(packageIsIncreaseScope) : false
        });

    }

    inputOnChange(event) {
        this.setState((state) => {
            this.startSearch(event.target.value,true);
            return { TreeSearch: event.target.value };
        });
    }


    startSearch(SearchText,IsSearch) {
        if(SearchText === "") {
            this.setState((state) => {
                this.props.treeSearchFilial({
                    SearchText : "",
                    IsSearch : false,
                });
                return { TreeSearch: "" };
            });
        }
        else {
            this.props.treeSearchFilial({
                SearchText : SearchText,
                IsSearch : IsSearch,
            });
        }
    }

    //для поиска
    setFilial(data) {
        //TODO Для АСВ раскомментить
        // if(data.Id === 1) {
        //     return;
        // }

        let isContinue = true;
        if(this.props.redactorData) {
            isContinue = window.confirm("Возможно имеются несохраненные данные. Продолжить?");
        }

        if(!isContinue) {
            return;
        }

        if(isContinue && this.props.redactorData) {
            window.onbeforeunload = null;
            window.addEventListener("popstate", null);
        }
        this.props.setFilial({
            Filial:data,
            Next : {
                action : () => {
                    this.props.updateUserSettings({
                        Name : UserSettingsNames.FILIAL_CONFIG,
                        Action : UserSettingsActionsTypes.Update,
                        Next : {
                            action : () => {
                                let query = this.props.router.location.query;
                                let queryLink = "";

                                if(this.props.router.location.pathname === "/documents") {
                                    queryLink = Routing.CreateRef({
                                        oldQuery : query,
                                        newQuery : {},
                                        excludedKeys : ["c", "d", "f"]
                                    });
                                }
                                else if(this.props.router.location.pathname === "/packages") {
                                    queryLink = Routing.CreateRef({
                                        oldQuery : query,
                                        newQuery : {p : 2},
                                        excludedKeys : ["c", "d", "p2", "d2", "dwp", "dwd", "dg2", "dt2", "dd2", "f"]
                                    });
                                }
                                let link = `${this.props.router.location.pathname}${queryLink}`;


                                history.push(link);
                                window.location.reload();
                            },
                            params : {}
                        }
                    })
                },
                params: {}
            }
        });

    }

    // сбрасываем поле ввода
    clearInput() {
        this.setState((state) => {
            this.startSearch("",false);
            return { TreeSearch: "" };
        });
    }

    async updatePassword() {

        let oldPassword = this.oldPasswordRef.current.value;
        let newPassword = this.newPasswordRef.current.value;
        let repeatPassword = this.repeatPasswordRef.current.value;

        if(!oldPassword || !newPassword || !repeatPassword) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type : "fail", content : "Пароль не был изменен:<br/>Необходимо заполнить все поля."}
            });
            return;
        }

        if(newPassword !== repeatPassword) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type : "fail", content : "Пароль не был изменен:<br/>Введенные пароли не совпадают."}
            });
            return;
        }

        if(oldPassword === newPassword) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type : "fail", content : "Пароль не был изменен:<br/>Текущий и новый пароль не могут совпадать."}
            });
            return;
        }

        let result = await API.user().updatePassword({
           oldPassword : oldPassword,
           newPassword : newPassword,
           idUser : this.props.user.Id,
        });

        if(result?.errorCode) {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type : "fail", content : `Пароль не был изменен<br/>${result.message.Message}`}
            });
            return;
        }

        this.oldPasswordRef.current.value = '';
        this.newPasswordRef.current.value = '';
        this.repeatPasswordRef.current.value = '';

        this.props.setModalData({
            name: ModalTypes.app.info,
            data: {type : "success", content : `Пароль был успешно изменен.`}
        });
        this.setState({
            isPasswordUpdateShow : false
        });
    }

    increaseScopeToggle(event) {
        let data = {};
        if(event.target.id === "docs") {
            data = { documentIncreaseScope: !this.state.documentIncreaseScope };
            this.setState({ documentIncreaseScope: !this.state.documentIncreaseScope });
        } else if(event.target.id === "packs") {
            data = { packageIsIncreaseScope: !this.state.packageIsIncreaseScope };
            this.setState({ packageIsIncreaseScope: !this.state.packageIsIncreaseScope });
        }

        this.props.updateUserSettings({
            Name: UserSettingsNames.GENERAL_CONFIG,
            Action: UserSettingsActionsTypes.ToggleIncreaseScope,
            Data: data
        });
    }


    renderByUserType(ModalClass) {
        let searchIcon = "icon-Search ";
        if(this.state.clearHover) { // для отображения красного крестика
            searchIcon = "icon-Delete icon-color-red";
        }
        let isSearch = this.props.isSearch
        const { documentIncreaseScope, packageIsIncreaseScope, isShowInputOldPass, isShowInputNewPass, isShowInputRepeatPass } = this.state;

        return (
            <div className={ModalClass}>
                <div className="usermodal-header d-flex align-items-center justify-content-between pb-5 pt-10 pl-10 pr-10">
                    <h4 className="font-weight-bold m-0">Настройки пользователя</h4>
                    <span className="btn btn-icon btn-sm" onClick={this.hide}>
                        <i className="svg-icon icon-Delete icon-color-primary-exit"/>
                    </span>
                </div>

                <div className="usermodal-content pr-5 d-flex flex-column pl-10 pr-10 beautiful-scroll-5" style={{scrollbarGutter: 'stable'}}>
                    <div className="d-flex align-items-center my-5">
                        <div className="symbol symbol-100 mr-5 symbol-success">
                            <span className="symbol-label font-size-h5 font-weight-bold">
                                {/*TODO с этим кодом было ошибка на color*/}
                                {/*<Avatar name={this.props.user.Name} size="100" color={[""]}/>*/}
                                <Avatar name={this.props.user?.Name} size="100" color={"var(--originaltwo)"} fgColor={"var(--text-welcome)"} className="rounded"/>

                                {/*<img style={{borderRadius : "0.42rem"}}*/}
                                {/*     src={`https://eu.ui-avatars.com/api/?name=${this.props.user.Name.split(" ")[0]}+${this.props.user.Name.split(" ")[1]}&background=204ba000&color=ffffff&size=100`} alt="иконка пользователя"/>*/}
                            </span>
                            <i className="symbol-badge bg-success"/>
                        </div>
                        <div className="d-flex flex-column">
                            <span className="font-weight-bold font-size-h6 text-dark-75 text-hover-primary">
                                {this.props.user.Name}
                            </span>
                            <div className="text-muted mt-1">{this.props.filial?.Name}</div>
                            <div className="navi mt-2">

                                <span onClick={()=>{
                                    this.props.AUTH_logout({})
                                    window.location.reload()
                                }}
                                   className="btn btn-light-danger font-weight-bold py-2 px-5 mt-2">Выход</span>
                                {
                                    this.state.settingsTooltipShow &&
                                    <MyTooltip target={this.settingsRef}
                                               text={"Настройки"}
                                               show={this.state.settingsTooltipShow}
                                               placement={"top"} delay={500}
                                    />
                                }
                                {

                                    <span className="btn btn-icon btn-sm px-5 mt-2 py-2 ml-2 btn-light-success"
                                          ref={this.settingsRef}
                                          onClick={()=>{this.setState({isSettingsShow : !this.state.isSettingsShow})}}
                                          onMouseEnter={()=>{this.setState({settingsTooltipShow : true})}}
                                          onMouseLeave={()=>{this.setState({settingsTooltipShow : false})}}>
                                        <i className="svg-icon icon-Settings"/>
                                    </span>
                                }
                                {/*{*/}
                                {/*    this.state.changeColorTooltipShow &&*/}
                                {/*    <MyTooltip target={this.changeColorRef}*/}
                                {/*               text={"Сменить цветовую схему"}*/}
                                {/*               show={this.state.changeColorTooltipShow}*/}
                                {/*               placement={"top"} delay={500}*/}
                                {/*    />*/}
                                {/*}*/}
                                {/*{*/}
                                {/*    <span className="btn btn-icon btn-sm px-5 mt-2 py-2 ml-2 btn-light-success"*/}
                                {/*          ref={this.changeColorRef}*/}
                                {/*          onClick={()=>{ColorTheme.toggle()}}*/}
                                {/*          onMouseEnter={()=>{this.setState({changeColorTooltipShow : true})}}*/}
                                {/*          onMouseLeave={()=>{this.setState({changeColorTooltipShow : false})}}*/}
                                {/*    >*/}
                                {/*    <i className="svg-icon icon-Configure_archive"/>*/}
                                {/*</span>*/}
                                {/*}*/}

                            </div>
                        </div>
                    </div>

                    {
                        this.state.isSettingsShow &&
                        <>
                            <div className="separator separator-dashed my-5"/>
                            <h3>Настройки</h3>
                            <ul className="menu-nav" style={{listStyle: 'none', margin: 0, padding: "15px 0"}}>
                                <li className="menu-link-p menu-item">
                                    <Accordion>
                                        <Accordion.Toggle as={({children, onClick}) =>
                                            <span className="btn d-flex justify-content-between" onClick={onClick}>
                                                    <span><i className="svg-icon icon-password_change icon-color-primary"/>{children}</span>
                                                    <span><i style={{fontSize: "1rem"}} className="svg-icon icon-Edit icon-color-primary"/></span>
                                               </span>} eventKey="changePassword">
                                            Сменить пароль
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="changePassword">
                                            <div className="my-5">
                                                    <h5 className="mb-5">Изменить пароль</h5>
                                                    <div className="form-group">
                                                        <label>Текущий пароль
                                                            <span className="text-danger"> *</span></label>
                                                        <div className="form-group fv-plugins-icon-container input-icon input-icon-right">
                                                            <input type={`${isShowInputOldPass ? 'text' : 'password'}`} ref={this.oldPasswordRef} className="form-control" autoComplete="new-password" placeholder="Ваш текущий пароль"/>
                                                            <span className="svg-icon svg-icon-lg"
                                                                  style={{right: "10px"}}
                                                                  onClick={() => {this.setState({isShowInputOldPass: !isShowInputOldPass})}}>
                                                                <i className={`svg-icon svg-icon-lg icon-color-primary ${isShowInputOldPass ? 'icon-eye_closed' : 'icon-eye_open'}`}/>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Новый пароль
                                                            <span className="text-danger"> *</span></label>
                                                        <div className="form-group fv-plugins-icon-container input-icon input-icon-right">
                                                            <input type={`${isShowInputNewPass ? 'text' : 'password'}`} ref={this.newPasswordRef} className="form-control" autoComplete="new-password"  placeholder="Новый пароль"/>
                                                            <span className="svg-icon svg-icon-lg"
                                                                  style={{right: "10px"}}
                                                                  onClick={() => {this.setState({isShowInputNewPass: !isShowInputNewPass})}}>
                                                                <i className={`svg-icon svg-icon-lg icon-color-primary ${isShowInputNewPass ? 'icon-eye_closed' : 'icon-eye_open'}`}/>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Повторите новый пароль
                                                            <span className="text-danger"> *</span></label>
                                                        <div className="form-group fv-plugins-icon-container input-icon input-icon-right">
                                                            <input type={`${isShowInputRepeatPass ? 'text' : 'password'}`} ref={this.repeatPasswordRef} className="form-control" autoComplete="new-password" placeholder="Повторите новый пароль"/>
                                                            <span className="svg-icon svg-icon-lg"
                                                                  style={{right: "10px"}}
                                                                  onClick={() => {this.setState({isShowInputRepeatPass: !isShowInputRepeatPass})}}>
                                                                <i className={`svg-icon svg-icon-lg icon-color-primary ${isShowInputRepeatPass ? 'icon-eye_closed' : 'icon-eye_open'}`}/>
                                                            </span>
                                                        </div>
                                                    <div>
                                                <span className="btn btn-light-success font-weight-bold py-2 px-5 mt-2"
                                                      style={{width : "100%"}} onClick={this.updatePassword}>
                                                    Подтвердить
                                                </span>
                                                    </div>

                                                </div>
                                            </div>
                                            </Accordion.Collapse>
                                    </Accordion>
                                </li>
                                <li className="menu-link-p menu-item">
                                    {/*<span className="btn" onClick={() => {ColorTheme.toggle()}}>*/}
                                    {/*    <i className="svg-icon icon-Configure_archive"/>Переключить схему*/}
                                    {/*</span>*/}
                                    <Accordion>
                                        <Accordion.Toggle as={({children, onClick}) =>
                                                <span className="btn d-flex justify-content-between" onClick={onClick}>
                                                    <span><i className="svg-icon icon-Configure_archive icon-color-primary"/>{children}</span>
                                                    <span><i style={{fontSize: "1rem"}} className="svg-icon icon-Edit icon-color-primary"/></span>
                                               </span>} eventKey="changeColor">
                                            Изменить цветовую схему
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="changeColor" >
                                            <div className="my-5" style={{marginLeft:"40px"}}>
                                                <div className="form-group" style={{cursor:"pointer"}} onClick={()=>{
                                                    const isSameTheme = (this.props.theme == "lightBlue_theme")
                                                    if (!isSameTheme)
                                                        ColorTheme.toggle("lightBlue_theme")}}>
                                                    <label style={{cursor:"pointer"}}>
                                                        {
                                                            this.props.theme =="lightBlue_theme" ? <span className="text-color-secondary" style={{cursor:"pointer"}}> Основная тема</span> : <span style={{cursor:"pointer"}}> Основная тема</span>
                                                        }
                                                    </label>
                                                </div>
                                                <div className="form-group" style={{cursor:"pointer"}} onClick={()=>{
                                                    const isSameTheme = (this.props.theme == "darkBlue_theme")
                                                    if (!isSameTheme)
                                                        ColorTheme.toggle("darkBlue_theme")}}>
                                                    <label style={{cursor:"pointer"}}>
                                                        {
                                                            this.props.theme =="darkBlue_theme" ? <span className="text-color-secondary" style={{cursor:"pointer"}}> Синяя тема</span> : <span style={{cursor:"pointer"}}> Синяя тема</span>
                                                        }

                                                    </label>
                                                </div>
                                                <div className="form-group" style={{cursor:"pointer"}} onClick={()=>{
                                                    const isSameTheme = (this.props.theme == "red_theme")
                                                    if (!isSameTheme)
                                                        ColorTheme.toggle("red_theme")}}>
                                                    <label style={{cursor:"pointer"}}>
                                                        {
                                                            (this.props.theme == "red_theme") || (this.props.theme.lThemeName == "red_theme") ? <span className="text-color-secondary" style={{cursor:"pointer"}}> Красная тема</span> : <span style={{cursor:"pointer"}}> Красная тема</span>
                                                        }

                                                    </label>
                                                </div>
                                                <div className="form-group" style={{cursor:"pointer"}} onClick={()=>{
                                                    const isSameTheme = (this.props.theme == "yellow_theme")
                                                    if (!isSameTheme)
                                                        ColorTheme.toggle("yellow_theme")}}>
                                                    <label style={{cursor:"pointer"}}>
                                                        {
                                                            (this.props.theme == "yellow_theme") ? <span className="text-color-secondary" style={{cursor:"pointer"}}> Желтая тема</span> : <span style={{cursor:"pointer"}}> Желтая тема</span>
                                                        }

                                                    </label>
                                                </div>
                                                <div className="form-group" style={{cursor:"pointer"}} onClick={()=>{
                                                    const isSameTheme = (this.props.theme == "azure_theme")
                                                    if (!isSameTheme) ColorTheme.toggle("azure_theme")}}>
                                                    <label style={{cursor:"pointer"}}>
                                                        {
                                                            (this.props.theme == "azure_theme") ? <span className="text-color-secondary" style={{cursor:"pointer"}}> Темно-синяя тема</span> : <span style={{cursor:"pointer"}}> Темно-синяя тема</span>
                                                        }

                                                    </label>
                                                </div>

                                            </div>
                                        </Accordion.Collapse>
                                    </Accordion>
                                </li>
                                <li className="menu-link-p menu-item">
                                    <Accordion>
                                        <Accordion.Toggle as={({children, onClick}) =>
                                            <span className="btn d-flex justify-content-between" onClick={onClick}>
                                                <span><i className="svg-icon icon-Doc_view_2 icon-color-primary"/>{children}</span>
                                                <span><i style={{fontSize: "1rem"}} className="svg-icon icon-Edit icon-color-primary"/></span>
                                            </span>} eventKey="increaseScope">
                                            Увеличивать область дерева
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="increaseScope">
                                            <ul style={{paddingLeft: '1rem'}}>
                                                <li className="d-flex justify-content-between">
                                                    <span className="btn"><i className="svg-icon icon-Documents icon-color-primary"/>Раздел Документы</span>
                                                    <div>
                                                    <span className="switch switch-sm">
                                                        <label>
                                                            <input type="checkbox"
                                                                   id="docs"
                                                                   checked={documentIncreaseScope}
                                                                   name="scopeCheckbox"
                                                                   onChange={(event) => {this.increaseScopeToggle(event)}} />
                                                            <span/>
                                                        </label>
                                                    </span>
                                                    </div>
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <span className="btn"><i className="svg-icon icon-folder_16 icon-color-primary"/>Раздел Пакеты</span>
                                                    <div>
                                                    <span className="switch switch-sm">
                                                        <label>
                                                            <input type="checkbox"
                                                                   id="packs"
                                                                   checked={packageIsIncreaseScope}
                                                                   name="scopeCheckbox"
                                                                   onChange={(event) => {this.increaseScopeToggle(event)}}/>
                                                            <span/>
                                                        </label>
                                                    </span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Accordion.Collapse>
                                    </Accordion>
                                </li>
                            </ul>
                            <div className="separator separator-dashed my-5"/>
                        </>
                    }


                    <div className="mt-5">
                        {/*<h5 className="mb-5">{this.props.globalSettings.ChangeOrganizationTooltip}</h5>*/}
                        <h5 className="mb-5">Сменить {store.getState().globalState.settings.Content?.FilialCaption ?? 'филиал'}</h5>

                        <div className="input-icon h-40px">
                            <input type="text" className="form-control form-control-lg form-control-solid h-40px"
                                   placeholder="Поиск по Организациям"
                                   value={this.state.TreeSearch}
                                   onChange={this.inputOnChange}
                            />
                            <span>
                        <span className="svg-icon svg-icon-lg"
                              onMouseEnter={()=>{this.setState({clearHover : true})}}
                              onMouseLeave={()=>{this.setState({clearHover : false})}}
                              onClick={this.clearInput}
                        >
                            <i className={`svg-icon svg-icon-lg ${searchIcon}`}/>
                        </span>
                    </span>
                        </div>

                        <div className="asidef-menu-wrapper flex-column-fluid px-2 py-2" style={{height:"auto"}}>
                            <div className="asidef-menu min-h-lg-400px">
                                <ul className="menu-nav">
                                    {
                                        isSearch
                                            ?
                                            <>
                                                {
                                                    this.props.foundFilial.map((item, index) =>{

                                                        let iconColor = "icon-color-primary-dark";
                                                        let textColor = "text-color-primary-dark";
                                                        if(item.Id === this.props.activeFilialId) {
                                                            iconColor = "icon-color-secondary";
                                                            textColor = "text-color-secondary";
                                                        }


                                                        // if(item.Id === 1) {
                                                        //     iconColor = "icon-color-grey";
                                                        //     textColor = "text-color-grey";
                                                        // }

                                                        return(
                                                            <li className="menu-link-p menu-item" key={createUniqueIdString(6)}>
                                                                <span className="menu-link menu-toggle" onClick={() => {this.setFilial(item)}}>
                                                                    <span className="svg-icon menu-icon">
                                                                        <i className={`icon-demo_filial_2 fsic4 ${iconColor}`}/>
                                                                    </span>
                                                                    <span className={`menu-text ${textColor}`}>{item.Name}
                                                                    </span>
                                                                </span>
                                                            </li>

                                                        );
                                                    })
                                                }
                                            </>
                                            :
                                            <>
                                                {
                                                    this.props.render.map((item) => {
                                                        if ((item.children && item.children.length > 0) || (item.type === "filial" && !item.Group)) {
                                                            return (
                                                                <TreeFilialFolder key={createUniqueIdString(6)}
                                                                                  activeFilialId={this.props.activeFilialId}
                                                                                  data={item}
                                                                />
                                                            );
                                                        }
                                                        return null
                                                    })
                                                }
                                            </>
                                    }
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        );
    }

    renderByTreeDocuments(ModalClass) {
        return (
            <div className={ModalClass}>
                <div className="usermodal-header d-flex align-items-center justify-content-between pb-5 pt-10 pl-10 pr-10">
                    <h3 className="text-color-primary font-weight-bolder m-0">Документы</h3>
                    <span className="btn btn-icon btn-sm" onClick={() => this.hide()}>
                        <i className="svg-icon icon-Delete icon-color-primary-exit" />
                    </span>
                </div>
                <div className="usermodal-content pr-5 d-flex flex-column pl-10 pr-10 beautiful-scroll-5">
                    <div className="tab-pane fade show active">
                        <div className="aside-menu-wrapper flex-column-fluid px-2 py-2">
                            <div className="aside-menu min-h-lg-850px">
                                <TreeSearchInPackagesRight hide={this.hide}
                                                           value={this.state.treeSearchInDocsRightValue}
                                                           changeValue={(value) => this.setState({ treeSearchInDocsRightValue: value})}/>
                                <TreeDocsPackagesRight/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderByTreeDocumentsSearch(ModalClass) {
        return (
            <div className={ModalClass}>
                <div className="usermodal-header d-flex align-items-center justify-content-between pb-5 pt-10 pl-10 pr-10">
                    <h3 className="text-color-primary font-weight-bolder m-0">Документы</h3>
                    <span className="btn btn-icon btn-sm" onClick={this.hide}>
                        <i className="svg-icon icon-Delete icon-color-primary-exit"/>
                    </span>
                </div>
                <div className="usermodal-content pr-5 d-flex flex-column pl-10 pr-10 beautiful-scroll-5">
                    <div className="tab-pane fade show active">
                        <div className="aside-menu-wrapper flex-column-fluid px-2 py-2">
                            <div className="aside-menu min-h-lg-850px">
                                <InputSearchDocPackageDocumentsRight/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    //TODO aa 20.10.2022
    renderByTreePackagesSearch(ModalClass) {
        return (
            <div className={ModalClass}>
                <div className="usermodal-header d-flex align-items-center justify-content-between pb-5 pt-10 pl-10 pr-10">
                    <h3 className="text-color-primary font-weight-bolder m-0">Пакеты дополнительной таблицы</h3>
                    <span className="btn btn-icon btn-sm" onClick={() => this.hide(true)}>
                         <i className="svg-icon icon-Delete icon-color-primary-exit"/>
                    </span>
                </div>
                <div className="usermodal-content pr-5 d-flex flex-column pl-10 pr-10 beautiful-scroll-5">
                    <div className="tab-pane fade show active">
                        <div className="aside-menu-wrapper flex-column-fluid px-2 py-2">
                            <div className="aside-menu min-h-lg-850px">
                                <InputSearchPackage tableRole={this.props.userModalTableRole} captionUnvisible={true}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderByTreeDownloadPackages() {
        let ModalClass = this.props.userModalShow
            ? "usermodal usermodal-right d-flex flex-column overflow-hidden usermodal-on"
            : "usermodal usermodal-right d-flex flex-column overflow-hidden";

       return (
           <div className={ModalClass}>
               <div className="usermodal-header d-flex align-items-center justify-content-between pb-5 pt-10 pl-10 pr-10">
                   <h3 className="text-color-primary font-weight-bolder m-0">Пакеты Загрузки</h3>
                   <span className="btn btn-icon btn-sm" onClick={this.hide}>
                       <i className="svg-icon icon-Delete icon-color-primary-exit"/>
                   </span>
               </div>

               <div className="usermodal-content pr-5 d-flex flex-column pl-10 pr-10 beautiful-scroll-5">
                   <div className="tab-pane fade show active">
                       <div className="aside-menu-wrapper flex-column-fluid">
                           <div className="aside-menu min-h-lg-850px">
                               <div className="tab-content">
                                   <div className="tab-pane fade show active">
                                       <TreePackageSearchDownload value={this.state.treeSearchInPackagesDownload}
                                                                   changeValue={(value) => this.setState({treeSearchInPackagesDownload: value})}
                                       />
                                       <div className="aside-menu-wrapper flex-column-fluid px-2 py-2">
                                            <div className="aside-menu">
                                                <TreePackagesDownload/>
                                            </div>
                                       </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    //Дерево Пакетов
    renderByTreePackages(ModalClass) {
        return (
            <div className={ModalClass}>
                <div className="usermodal-header d-flex align-items-center justify-content-between pb-5 pt-10 pl-10 pr-10">
                    <h3 className="text-color-primary font-weight-bolder m-0">Пакеты</h3>
                    <span className="btn btn-icon btn-sm" onClick={this.hide}>
                         <i className="svg-icon icon-Delete icon-color-primary-exit"/>
                    </span>
                </div>
                <div className="usermodal-content pr-5 d-flex flex-column pl-10 pr-10 beautiful-scroll-5">
                    <div className="tab-pane fade show active">
                        <div className="aside-menu-wrapper flex-column-fluid px-2 py-2">
                            <div className="aside-menu min-h-lg-850px">
                                <TreeSearchPackagesRight tableRole={"additional"}
                                                         hide={this.hide}
                                                         value={this.state.treeSearchInPackagesRightValue}
                                                         changeValue={(value) => this.setState({ treeSearchInPackagesRightValue: value})}/>
                                {
                                    this.props.isSearchPackagesRightTree ?
                                        <TreePackagesFolderSearch tableRole={TableTypesEnum.Additional}/>
                                        :
                                        <TreePackages isAdditional={true} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }


    render() {
        let ModalClass = this.props.userModalShow
            ? "usermodal usermodal-right d-flex flex-column overflow-hidden usermodal-on"
            : "usermodal usermodal-right d-flex flex-column overflow-hidden";


        if (this.props.userModalType === "treeDocuments") {

            if (this.props.tree.render) {
                return this.renderByTreeDocuments(ModalClass);
            } else return null;

        } else if (this.props.userModalType === "treeDocumentsSearch") {

            if (this.props.tree.render) {
                return this.renderByTreeDocumentsSearch(ModalClass);
            } else return null;
        } else if (this.props.userModalType === "treePackagesSearch") {
            if (this.props.tree.render) {
                return this.renderByTreePackagesSearch(ModalClass);
            } else return null;
        } else if (this.props.userModalType === "treeDownload") {
            return this.renderByTreeDownloadPackages();
        } else if (this.props.userModalType === "treePackages") {
            return this.renderByTreePackages(ModalClass);
        } else {
            return this.renderByUserType(ModalClass);
        }


    }
}


const  mapStateToProps = state => {
    return {
        theme: state.globalState.theme?.selectedTheme.lThemeName,
        userModalShow : state.globalState.app.userModalShow,
        userModalType : state.globalState.app.userModalType,
        userModalTableRole : state.globalState.app.userModalTableRole,
        render : state.globalState.filial.Render,
        user : state.globalState.user,
        rootState : state.document.rootState,
        filial : state.globalState.filial.Active,
        AllFilial : state.globalState.filial.All,
        tree : state.packages.treeForDocuments,

        isSearch: state.filial.tree.isSearch,
        foundFilial: state.filial.tree.foundFilial,
        activeFilialId : state.globalState.filial?.Active?.Id,
        globalSettings : state.globalState.settings.Content,
        router : state.router,
        redactorData : state.document.cards.redactorData,
        documentIncreaseScope: state.globalState.userSettings.find(item => item.Name === UserSettingsNames.GENERAL_CONFIG)?.Value?.documentIncreaseScope,
        packageIsIncreaseScope: state.globalState.userSettings.find(item => item.Name === UserSettingsNames.GENERAL_CONFIG)?.Value?.packageIsIncreaseScope,
        isSearchPackagesRightTree: state.packages.treeAdditional.isSearch
    }
}

const  mapDispatchToProps = {
    setUserModalShow,
    treeSearchFilial,
    ActionQueue,
    getDocumentsForTree_packagesDocumentRight,
    getDocumentGroupsForTree_packagesDocumentRight,
    createTree_packagesDocumentRight,
    copyDocumentTreeForPackages,
    AUTH_logout,
    setFilial,
    updateUserSettings,
    setModalData,
    changeViewFilter,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserModal);
