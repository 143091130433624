
export const MESSENGER_ROOT_STATE_ACTIONS = {
    // изменить режим отображения
    TOGGLE_SHOW_ASIDE_MENU: "MESSENGER | ROOT_STATE | TOGGLE_SHOW_ASIDE_MENU"
};

export const    MESSENGER_ACTION={
    MAKE_RECEIVER_ACTIVE:"MESSENGER | ACTION | MAKE_RECEIVER_ACTIVE",
    ADD_TO_BUDDIES:"MESSENGER | ACTION | ADD_TO_BUDDIES",
    UPDATE_BUDDIES:"MESSENGER | ACTION | UPDATE_BUDDIES",
    READ_MESSAGE:'MESSENGER | ACTION | READ_MESSAGE',
    SET_READ_MSGS:"MESSENGER | ACTION | SET_READ_MSGS",
    SET_NUMBER_NEW_MSG:'MESSENGER | ACTION | SET_NUMBER_NEW_MSG',
}