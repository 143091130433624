import React from "react";
import {connect} from "react-redux";
import {deleteModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {ActionQueue} from "../../../store/rootReducer";
import {
    collectDataForAdditionalSections_Normal,
    createPreviewBySelectDocument,
    resetDocumentPreview
} from "../../../store/documents/actionCreators/document_PreviewActionCreator";
import {
    combineMainTableAttributes,
    mainTableDownloadDocCollection,
    resetMainTableForUpdate, selectActiveRow, setPaginationPageNumber
} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {changeDocumentViewMode} from "../../../store/documents/actionCreators/document_RootStateActionCreator";
import AttributeTableBody from "../../mainTable/AttributeTableByJson/AttributeTableBody";
import {__, HelpFunctions, validateAttributeJSONByTable} from "../../../tools/HelpFunctions";
import Select from "react-select";
import {createUniqueIdNumber} from "../../../tools/CreateUniqueId";
import {typeOptionsForAttributeJSON} from "../../../tools/consts";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/webpack-resolver";

const emptyHeaderValue = {
    renderValue: "",
    value: "",
    type: "string",
    id: null,
    order: 1,
    isShow: true
};

const ViewModeAttributeTable = {
    jsonRedactor: "jsonRedactor",
    tableConstructor: "tableConstructor"
}

class ModalAttributeTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            table: {
                headers: [],
                rows: []
            },
            isNestedTable: false,
            createRows: [],
            isEditable: false,
            isCreateHeaders: false,
            createHeaders: [],
            newHeaderValue: emptyHeaderValue,
            shortDescription: "",
            longRenderValue: "",
            viewMode: ViewModeAttributeTable.tableConstructor,
            jsonString: "",
            isJsonRedactorError: false,
            jsonRedactorErrorMessage: "",
        };

        this.saveValue = this.saveValue.bind(this);
        this.hide = this.hide.bind(this);
        this.hideOutSide = this.hideOutSide.bind(this);
    }

    componentDidMount() {
        const {Attribute: {Value, JsonSchema}} = this.props.modal.data;


        if(HelpFunctions.isJSON(Value) || HelpFunctions.isJSON(JsonSchema)) {
            const jsonValue = HelpFunctions.isJSON(Value) ? JSON.parse(Value) : JSON.parse(JsonSchema);
            let headers = jsonValue.headers ?? [];
            if(HelpFunctions.isJSON(JsonSchema)) {
                headers = headers.map(h => ({...h, IsConst: true}));
            }

            this.setState({
                table: {
                    headers: headers,
                    rows: jsonValue.rows ?? []
                },
                shortDescription: jsonValue ? jsonValue.shortDescription : "",
                jsonString: JSON.stringify(jsonValue, null, 2)
            });
        }
    }

    // скрывать по кнопке
    hide() {
        if(this.state.longRenderValue) {
            this.setState({ longRenderValue: ""});
        } else {
            if(this.state.isNestedTable) {
                this.setState({
                    table: JSON.parse(this.props.modal.data.Attribute.Value),
                    isNestedTable: false
                });
            } else {
                this.props.deleteModalData({modalId: this.props.modal.id})
            }
        }
    }

    hideOutSide() { // скрывать при клике вне окна
        if(!this.state.contentOnHover) {
             this.props.deleteModalData({modalId: this.props.modal.id})
        }
    }

    openNestedTable(headers, rows) {
       // меняем отображаемые данные таблицы
        this.setState({table: {headers: headers ?? [], rows: rows ??  []}, isNestedTable: true});
    }

    openEditableRows() {
        const rows = this.state.table?.headers.map(item => {
            return {
                renderValue: "",
                value: "",
                type: item.type,
                idHeader: item.id,
            }
        });

        const createdRows = {
          id: __.createUniqueIdString(6),
          cells: rows
        };

        this.setState({
            createRows: createdRows,
            isEditable: true,
        });
    }

    onChangeRowValue(value, id) {
        let rows = __.deepCopy(this.state.createRows);
        rows = {
            ...rows,
            cells: rows.cells.map(item => (item.idHeader === id ? {...item, renderValue: value, value: value} : item))
        }

        this.setState({createRows: rows});
    }

    saveValue() {
        let jsonObject = {};
        if(this.state.viewMode === ViewModeAttributeTable.jsonRedactor) {
            jsonObject = this.state.jsonString;
        } else {
            const json = {
                type: "table",
                shortDescription: this.state.shortDescription,
                headers: this.state.table.headers,
                rows: this.state.table.rows
            };

            jsonObject = JSON.stringify(json);
        }
        this.validateJsonObject(jsonObject, () => {
            if(!this.state.isJsonRedactorError) {
                if (this.props.modal.data.callback) this.props.modal.data.callback(jsonObject);
                this.hide();
            }
        });


    }

    createNewRowInTable() {
        const { isCreateHeaders, newHeaderValue, isEditable, viewMode } = this.state;
        if(isEditable) {
            if (isCreateHeaders) {
                if (newHeaderValue.value !== "") {
                    const rowValue = {
                        renderValue: newHeaderValue.type === "checkbox" ? false : "",
                        value:  newHeaderValue.type === "checkbox" ? false : "",
                        type: newHeaderValue.type,
                        idHeader: newHeaderValue.id,
                    }
                    let rows = __.deepCopy(this.state.table.rows);
                    let headers = [...this.state.table.headers, newHeaderValue];

                    if (rows.length > 0) {
                        rows = rows.map((item) => ({...item, cells: [...item.cells, rowValue]}))
                    } else {
                        if(headers.length > rows.length) {
                            rows = [...rows, {
                                id: __.createUniqueIdString(6),
                                cells: headers.map((item) => ({...rowValue}))
                            }]
                        } else {
                            rows = [...rows, {id: __.createUniqueIdString(6), cells: [rowValue]}];
                        }
                    }

                    this.setState({
                        table: {
                            ...this.state.table,
                            headers: headers,
                            rows: rows
                        },
                        isEditable: false,
                        isCreateHeaders: false,
                        newHeaderValue: emptyHeaderValue
                    }, () => {

                    });
                } else {
                    this.setState({
                        isEditable: false,
                        isCreateHeaders: false,
                        newHeaderValue: emptyHeaderValue
                    });
                }
            }
            else {
                this.setState({
                    table: {
                        ...this.state.table,
                        rows: [...this.state.table.rows, this.state.createRows]
                    },
                    isEditable: false,
                });
            }
        }
        else {
            this.saveValue();
        }
    }

    createHeaders() {
        const { newHeaderValue: {id, value, type, order, renderValue, isShow}} = this.state;
        if(!id) {
            this.setState({
                newHeaderValue: {...this.state.newHeaderValue, id: createUniqueIdNumber(), order: this.state.table.headers.length + 1}
            });
        }

        return (
            <table className={`table table-bordered table-striped m-0`}>
                <thead>
                <tr>
                    <th className="col-6">Атрибут</th>
                    <th className="col-6">Значение</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <span className="ellipsis-custom textItem">ID</span>
                    </td>
                    <td>
                        <input type={"number"} className="form-control" disabled
                               value={id}
                               title={id}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                              <span className="ellipsis-custom textItem">Тип данных</span>
                    </td>
                    <td>
                            <Select
                                closeMenuOnSelect
                                defaultValue={typeOptionsForAttributeJSON[0]}
                                onChange={option => {
                                    this.setState({
                                    newHeaderValue: {
                                        ...this.state.newHeaderValue,
                                        type: option.type
                                    }})
                                }}
                                options={typeOptionsForAttributeJSON}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        text: 'var(--hover-primary)',
                                        primary25: 'var(--light-primary)',
                                        primary50: 'var(--hover-primary)',
                                        primary: 'var(--originaltwo)',
                                    },
                                })}
                            />
                    </td>
                </tr>
                <tr>
                    <td>
                              <span className="ellipsis-custom textItem">
                                    Порядок
                              </span>
                    </td>
                    <td>
                            <input type={"number"} className="form-control"
                                   title={order}
                                   value={order}
                                   onChange={(event)=> {
                                       this.setState({
                                           newHeaderValue: {...this.state.newHeaderValue, order: event.target.value}
                                       });
                                   }}
                            />
                    </td>
                </tr>
                <tr>
                    <td>
                        <span className="ellipsis-custom textItem">
                            Видимость в таблице
                        </span>
                    </td>
                    <td>
                            <input type="checkbox" checked={isShow} onChange={(event)=> {
                                this.setState({
                                    newHeaderValue: {...this.state.newHeaderValue, isShow: !isShow}
                                });
                            }}/>
                    </td>
                </tr>
                <tr>
                    <td>
                              <span className="ellipsis-custom textItem">
                                    Значение
                              </span>
                    </td>
                    <td>
                            <input type={"string"} className="form-control"
                                   title={value}
                                   value={value}
                                   onChange={(event)=> {
                                       this.setState({
                                           newHeaderValue: {...this.state.newHeaderValue, value: event.target.value}
                                       });
                                   }}
                            />
                    </td>
                </tr>
                <tr>
                    <td>
                              <span className="ellipsis-custom textItem">
                                   Отображаемое значение
                              </span>
                    </td>
                    <td>
                            <input type={"string"} className="form-control"
                                   title={renderValue}
                                   value={renderValue}
                                   onChange={(event)=> {
                                       this.setState({
                                           newHeaderValue: {...this.state.newHeaderValue, renderValue: event.target.value}
                                       });
                                   }}
                            />
                    </td>
                </tr>
                </tbody>
            </table>
        )
    }

    createRows(headers) {
        return (
            <table className={`table table-bordered table-striped m-0`}>
                <thead>
                <tr>
                    <th className="col-6">Заголовок</th>
                    <th className="col-6">Значение</th>
                </tr>
                </thead>
                <tbody>
                {
                    this.state.createRows.cells.map((row, index) => {
                        const isCheckbox = row.type === 'checkbox';
                        return (
                            <tr>
                                <td>
                                    <span className="ellipsis-custom textItem">{headers.find(item => item.id === row.idHeader)?.renderValue ?? 'Значение'}</span>
                                </td>
                                <td className="d-flex justify-content-center">
                                    {
                                        isCheckbox ? <label className="checkbox checkbox-lg checkbox-inline mb-1">
                                                <input type={'checkbox'} className="form-control"
                                                       title={row.renderValue.toString()}
                                                       checked={row.renderValue}
                                                       onChange={(event) => {
                                                           this.onChangeRowValue(!row.renderValue, row.idHeader)
                                                       }}
                                                /><span
                                                className={"row-checked mr-1"}> </span>
                                            </label>
                                            :
                                            <input type={row.type} className="form-control"
                                                   value={row.renderValue}
                                                   onChange={(event) => {
                                                       this.onChangeRowValue(event.target.value, row.idHeader)
                                                   }}
                                            />
                                    }
                                </td>
                            </tr>
                        )
                    })
                }

                </tbody>
            </table>
        )
    }

    editHandler(value, cell, rowId) {
        let editedRows = __.deepCopy(this.state.table.rows);
        editedRows = editedRows.map(row => {
            if(row.id === rowId) {
                return {
                    ...row,
                    cells: row.cells.map(item => ((cell.idHeader === item.idHeader) ? {...item, value: value, renderValue: value} : item))
                }
            } else {
                return row
            }

        })
        this.setState({
            table: {
                ...this.state.table,
                rows: editedRows
            }
        });
    }

    deleteRow(rowId) {
        let currentRows = __.deepCopy(this.state.table.rows);
        if(currentRows.length === 1) {
            currentRows = currentRows.map(row => ({...row, cells: row.cells.map(item => ({...item, value: "", renderValue: ""}))}))
        } else {
            currentRows = currentRows.filter((row) => row.id !== rowId);
        }


        this.setState({
            table: {
              ...this.state.table,
                 rows: currentRows
            }
        });
    }

    deleteHeaderRow(idHeader) {
        let currentRows = __.deepCopy(this.state.table.rows);
        let currentHeader = __.deepCopy(this.state.table.headers);

        currentHeader = currentHeader.filter(h => h.id !== idHeader);
        currentRows = currentHeader.length === 0 ? [] : currentRows.map(row => ({...row, cells: row.cells.filter(item => item.idHeader !== idHeader)}));

        this.setState({
            table: {
                ...this.state.table,
                rows: currentRows,
                headers: currentHeader
            }
        });
    }

    onCancelHandler() {
        if(!this.state.isEditable) this.hide();
        this.setState({
            isEditable: false,
            isCreateHeaders: false,
            newHeaderValue: emptyHeaderValue
        });
    }

    showLongRenderValue(value) {
        this.setState({longRenderValue: value});
    }

    onChangeJsonHandler(value) {
      this.setState({jsonString: value});
    }

    onChangeViewModeHandler(viewMode) {
        if(this.state.viewMode === ViewModeAttributeTable.jsonRedactor) {
            const jsonValue = JSON.parse(this.state.jsonString);
            this.setState({
                shortDescription: jsonValue.shortDescription,
                table: {
                    headers: jsonValue.headers,
                    rows: jsonValue.rows
                }
            });
        } else {
            const jsonObject = {
                type: "table",
                shortDescription: this.state.shortDescription,
                headers: this.state.table.headers,
                rows: this.state.table.rows
            };

            this.setState({
                jsonString: JSON.stringify(jsonObject, null, 2)
            });
        }

        this.setState({viewMode: viewMode});
    }

    validateJsonObject(jsonObject, callback) {
        const validResult = validateAttributeJSONByTable(jsonObject);

        this.setState({
            isJsonRedactorError: !validResult.valid,
            jsonRedactorErrorMessage: "Неверная структура JSON-объекта"
        }, () => {
            callback();
        });
    }

    onChangeRenderValueHeader(value, id) {
        let editedHeaders = __.deepCopy(this.state.table.headers);
        editedHeaders = editedHeaders.map(header => {
            if(header.id === id) {
                return {
                    ...header,
                    renderValue: value,
                    value: value
                }
            } else {
                return header
            }

        })
        this.setState({
            table: {
                ...this.state.table,
                headers: editedHeaders
            }
        });
    }

    setVisibleHeader(idHeader) {
        let editedHeaders = __.deepCopy(this.state.table.headers);

        editedHeaders = editedHeaders.map(header => {
            if(header.id === idHeader) {
                return {
                    ...header,
                    isShow: !header.isShow
                }
            } else {
                return header
            }

        })
        this.setState({
            table: {
                ...this.state.table,
                headers: editedHeaders
            }
        });
    }

    render() {
        const {Attribute, IsRedactorMode} = this.props.modal.data;
        const {table, isEditable, createRows, isCreateHeaders, longRenderValue, viewMode} = this.state;
        const tableData = table;

        const headers = tableData ? tableData["headers"].sort((a, b) => a.order - b.order) : [];
        let rows = tableData ? tableData["rows"] : [];


        let HeaderText = isEditable ?
                `Создание значения для атрибута "${Attribute.Name}"`
                :
                `Значение атрибута "${Attribute?.Name}"`;

        let modalStyle = {
            display : "block",
            backgroundColor: "#061c3e66",
            zIndex: 800
        };

        return (
            <div className="modal fade show" style={modalStyle} onClick={this.hideOutSide}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
                    <div className="modal-content"
                         onMouseEnter={() => {this.setState({ contentOnHover : true})}}
                         onMouseLeave={() => {this.setState({ contentOnHover : false})}}>

                        <div className="modal-header">
                            <h5 className="modal-title" title={HeaderText}>{HeaderText}</h5>
                        </div>
                        <div className="modal-body beautiful-scroll-5">
                            <ul className="nav nav-tabs nav-bold nav-tabs-line mb-3">
                                <li className="nav-item">
                                    <span className={`nav-link ${viewMode === ViewModeAttributeTable.tableConstructor ? 'active' : ''}`}>
                                        <span className="nav-text"
                                              onClick={() => this.onChangeViewModeHandler(ViewModeAttributeTable.tableConstructor)}>
                                        Таблица
                                    </span>
                                    </span>

                                </li>
                                <li className="nav-item">
                                    <span className={`nav-link ${viewMode === ViewModeAttributeTable.jsonRedactor ? 'active' : ''}`}>
                                        <span className="nav-text"
                                              onClick={() => this.onChangeViewModeHandler(ViewModeAttributeTable.jsonRedactor)}>
                                        JSON-схема
                                    </span>
                                    </span>
                                </li>
                            </ul>
                            {
                                viewMode === ViewModeAttributeTable.tableConstructor &&
                                <>
                                    {longRenderValue.length > 0 ?
                                        <div>{longRenderValue}</div>
                                        :
                                        <>
                                            <div className="form-group py-2">
                                                <label>Краткое описание таблицы:
                                                </label>
                                                <input type={"text"} className="form-control"
                                                       title={this.state.shortDescription}
                                                       value={this.state.shortDescription}
                                                       disabled={!IsRedactorMode}
                                                       onChange={(event)=>{this.setState({shortDescription: event.target.value})}}
                                                />
                                            </div>

                                            {IsRedactorMode &&
                                                <>
                                                    {isEditable ?
                                                        <>
                                                            {isCreateHeaders ?
                                                                this.createHeaders()
                                                                :
                                                                this.createRows(headers)
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            <button type="button" className={`btn btn-primary font-weight-bold mr-2 mb-2`} disabled={headers.findIndex(h => h.IsConst) !== -1}
                                                                    onClick={() => this.setState({
                                                                        isCreateHeaders: true,
                                                                        isEditable: true
                                                                    })}>
                                                                <i className={`svg-icon svg-icon-xl icon-add_16`}/>Создать заголовки для
                                                                таблицы
                                                            </button>
                                                            {headers && headers.length > 0 &&
                                                                <button type="button" className={`btn btn-primary font-weight-bold mb-2`}
                                                                        onClick={() => this.openEditableRows()}>
                                                                    <i className={`svg-icon svg-icon-xl icon-add_16`}/>Создать значение
                                                                    для таблицы
                                                                </button>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }

                                            {
                                                !isEditable &&
                                                <table className={`table table-head-custom table-vertical-center table-hover table-striped mb-0 unselectable `} style={{ tableLayout : "fixed"}}>
                                                    <thead>
                                                        <tr>
                                                            {IsRedactorMode && rows && rows.length > 0 && <td style={{width: '50px'}}></td>}
                                                            {
                                                                headers && headers.length > 0 && headers.map(item => {
                                                                    if(!item.isShow && !IsRedactorMode) return null
                                                                    return  (
                                                                        <th style={{textTransform: 'none'}} key={item.id}>
                                                                            <div className={"d-flex justify-content-center align-items-center"}>
                                                                                {IsRedactorMode && !item.IsConst && <span className="svg-icon svg-icon-lg mr-1"
                                                                                onClick={() => this.setVisibleHeader(item.id)}><i className={`svg-icon svg-icon-lg ${item.isShow ? ' icon-eye_open' : 'icon-eye_closed'}`}/></span>}
                                                                                {IsRedactorMode && !item.IsConst ?
                                                                                    <input type="text"
                                                                                           className="form-control"
                                                                                           value={item.renderValue}
                                                                                           onChange={(ev) => this.onChangeRenderValueHeader(ev.target.value, item.id)}
                                                                                    />
                                                                                :
                                                                                    item.renderValue
                                                                                }
                                                                                {IsRedactorMode && !item.IsConst && <span onClick={() => this.deleteHeaderRow(item.id)}><i className="ml-1 svg-icon svg-icon-sm icon-Delete icon-color-red"/></span>}
                                                                            </div>
                                                                        </th>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <AttributeTableBody
                                                        headers={headers}
                                                        rows={rows}
                                                        attribute={Attribute}
                                                        IsRedactorMode={IsRedactorMode}
                                                        editHandler={(value, cell, rowId) => this.editHandler(value, cell, rowId)}
                                                        openTable={(headers, rows) => this.openNestedTable(headers, rows)}
                                                        deleteRow={(rowId) => this.deleteRow(rowId)}
                                                        showLongRenderValue={(value) => this.showLongRenderValue(value)}
                                                    />
                                                </table>
                                            }
                                        </>
                                    }
                                </>

                            }

                            {
                                viewMode === ViewModeAttributeTable.jsonRedactor &&
                                <>
                                    {
                                        this.state.isJsonRedactorError &&
                                        <label>
                                                    <span
                                                        className="label label-inline label-light-danger font-weight-bolder mb-2">
                                                        {this.state.jsonRedactorErrorMessage}
                                                    </span>
                                        </label>

                                    }
                                    <AceEditor
                                        mode="json"
                                        theme={'xcode'}
                                        name="content"
                                        readOnly={!IsRedactorMode}
                                        value={this.state.jsonString}
                                        onChange={(newValue) => this.onChangeJsonHandler(newValue)}
                                        editorProps={{$blockScrolling: true}}
                                        width={"100%"}
                                        height={"250px"}
                                        setOptions={{
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                        }}
                                        style={{
                                            boxShadow: "0px 0px 5px 1px grey"
                                        }}
                                    />
                                </>

                            }



                        </div>

                        <div className="modal-footer">
                            { !longRenderValue && IsRedactorMode ?
                                <>
                                    <button type="button" className="btn btn-primary font-weight-bold" style={{marginRight: "auto"}} onClick={()=>{this.createNewRowInTable()}}>Сохранить</button>
                                    <button type="button" className="btn btn-light font-weight-bold" onClick={() => {this.onCancelHandler()}}>
                                        Отменить
                                    </button>
                                </>
                            :
                                <button type="button" className="btn btn-light font-weight-bold" onClick={this.hide}>
                                    Закрыть
                                </button>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );

    }
}


const  mapStateToProps = state => {
    return {
        activeNode : state.document.tree.activeNode,
        activeRow : state.document.mainTable.activeRow,
        pageNumber: state.document.mainTable.paginationPageNumber
    }
}

const  mapDispatchToProps = {
    deleteModalData,
    ActionQueue,
    resetMainTableForUpdate,
    mainTableDownloadDocCollection,
    combineMainTableAttributes,
    selectActiveRow,
    resetDocumentPreview,
    createPreviewBySelectDocument,
    collectDataForAdditionalSections_Normal,
    changeDocumentViewMode,
    setPaginationPageNumber
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAttributeTable);
