import {store} from "../../../index";
import {MESSENGER_ROOT_STATE_ACTIONS} from "../messengerActionList";

// изменяем отображение
export const messengerToggleShowAsideMenu = ({isOpen = null}) => {
    return async dispatch => {
        try {
            if (isOpen === null) {
                isOpen = store.getState().messenger.rootState.isOpenAsideMenu;
            }
            await dispatch({type: MESSENGER_ROOT_STATE_ACTIONS.TOGGLE_SHOW_ASIDE_MENU, payload: !isOpen});
        } catch (exception) {
            console.log("Ошибка изменения видимости меню (catch): ", exception);
        }
    }
}

