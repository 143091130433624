import React from "react";

export const DataTypesList = {
    String: "String",
    Number: "Number",
    Boolean: "Boolean",
    Date: "Date",
    JSON: "JSON"
}

class AttributeTableCellByInputType extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: ""
        };

        this.editHandler = this.editHandler.bind(this);
    }

    // обработка изменения атрибутов
    editHandler(value, idHeader, rowId) {
        if(this.props.editHandler) {
            this.props.editHandler(value);
        }
    }


    render() {
        const {cell, rowId} = this.props;

        return (
            <td>
                {cell.type === DataTypesList.JSON ?
                    <span onClick={() => this.props.openNestedTable}><i className="svg-icon svg-icon-sm icon-more"/></span>
                    :
                    cell.type === "checkbox" ?
                        <label><input type={"checkbox"} checked={cell.renderValue}
                                   onChange={(event) => {
                                       this.editHandler(!cell.renderValue, cell.idHeader, rowId)
                                   }}
                            /><span/>
                        </label>
                            :
                            <input className="form-control w-100" type={cell.type} value={cell.renderValue} title={this.state.value}
                                   onChange={(event) => {
                                       this.editHandler(event.target.value, cell.idHeader, rowId)
                                   }}
                            />
                }
            </td>
        )
    }
}

export default AttributeTableCellByInputType;
