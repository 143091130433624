import React from "react";
import {connect} from "react-redux";
import {
    attributesSectionsTypes, DataTypes,
    RootComponentsStateViewModeTypes,
    ModalTypes,
} from "../../tools/StaticTypes";
import {
    createRedactorData,
    toggleAdditionalSectionsOpen,
    toggleAdditionalSectionsOpen_Cards, updateAttributesRedactorData
} from "../../store/documents/actionCreators/document_PreviewActionCreator";
import {__} from "../../tools/HelpFunctions";
import parse from "html-react-parser";
import {searchByParams, updateInputsFieldSearch} from "../../store/search/actionCreators/search_Inputs";
import {history, store} from "../../index";
import {combineMainTableAttributesSearch} from "../../store/search/actionCreators/search_table";
import {changeSearchViewMode} from "../../store/search/actionCreators/search_RootStateActionCreator";
import sanitizeHtml from "sanitize-html";
import {ActionQueue} from "../../store/rootReducer";
import {
    setLoaderModalData,
    setModalData,
    setStateLeftAsideMenu
} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {MySelectNew} from "../tools/MySelect/MySelectNew";
import {DataTypesEnum} from "../../tools/API_NEW/ServerTypes";
import {setVersionIdForLocate} from "../../store/masterApp/actionCreator/masterAppActionCreator";
import DocVersionRef from "./docRefsComponents/DocVersionRef";
import DocRef from "./docRefsComponents/DocRef";
import MultipleAttributeTable from "../tools/MultipleAttributeTable/MultipleAttributeTable";
import JsonObjectFragment from "../root/JsonObjectFragment";
import MultipleDocRef from "./docRefsComponents/MultipleDocRef";
import MultipleDocVersionRef from "./docRefsComponents/MultipleDocVersionRef";
import AttributeWithDictionaries from "./docRefsComponents/AttributeWithDictionaries";

const createSelectStyles = {
    input: (css, state) => ({
        ...css,
        width: "100%",
        "> div": {
            width: "100%"
        },
        input: {
            width: `100% !important`,
            textAlign: "left"
        }
    })
}

const getIdAttributeTypeByItem = (item) => {
    return item.IdAttributeType || item.AttributeName.IdAttributeType
}

class AttributesSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            calendarShow: false,
            calendarItem: null,
            calendarIsHover: false,
        };

        this.sectionName = attributesSectionsTypes.attributesSection;


        this.toggle = this.toggle.bind(this);
        this.editHandler = this.editHandler.bind(this);
        this.search = this.search.bind(this);

        this.renderJsonObjectFragment = this.renderJsonObjectFragment.bind(this);

        this.deleteMultiplyRowHandler = this.deleteMultiplyRowHandler.bind(this);
        this.addMultiplyRowHandler = this.addMultiplyRowHandler.bind(this);
    }

    search(value, type = null) {
        const idSearch = __.createUniqueIdString(6);
        //TODO SS временно
        if (Array.isArray(value)){
            value = value.map(el=>`"${el.split('"').join('').split("'").join('')}"`).join(',')
        } else if (typeof value !== 'string') {
            return;
        } else {
            value = `"${value.split('"').join('').split("'").join('')}"`
        }
        //TODO SS 23683 избавляемся от кавычек в запросе для лучшего поиска
        if (type) {
            let RegDateAsString = null;
            let RegNumber = null;
            let Name = null;


            if (type === "RegDateAsString") RegDateAsString = value;
            else if (type === "RegNumber") RegNumber = value;
            else if (type === "Name") Name = value;

            this.props.ActionQueue({ // загружаем необходимые для работы приложения данные
                List: [
                    {
                        action: this.props.setLoaderModalData, params: {
                            data: {
                                content: "Загрузка...",
                                disableButton: true,
                                fullBackground: true,
                                gif: "search",
                                key: "AttributesSection62"
                            }
                        }, name: "setLoaderModalData"
                    },
                    {action: this.props.createRedactorData, params: {CardId: null}, name: "createRedactorData"},
                    {
                        action: this.props.updateInputsFieldSearch, params: {
                            RegDateAsString,
                            RegNumber,
                            Name,
                            Id: idSearch,
                        }, name: "updateInputsFieldSearch"
                    },
                    {action: this.props.searchByParams, params: {Id: idSearch}, name: "searchByParams"},
                    {
                        action: this.props.combineMainTableAttributesSearch,
                        params: {},
                        name: "combineMainTableAttributesSearch"
                    },
                    {
                        action: this.props.changeSearchViewMode,
                        params: {ViewMode: RootComponentsStateViewModeTypes.SearchApp.MainTableOnly},
                        name: "changeSearchViewMode"
                    },
                    {
                        action: this.props.setLoaderModalData,
                        params: {keyDeleted: "AttributesSection73"},
                        name: "setLoaderModalData"
                    },
                ],
                debug: false,
            });
            history.replace('/search')
        } else {
            this.props.ActionQueue({ // загружаем необходимые для работы приложения данные
                List: [
                    {
                        action: this.props.setLoaderModalData, params: {
                            data: {
                                content: "Загрузка...",
                                disableButton: true,
                                fullBackground: true,
                                gif: "search",
                                key: "AttributesSection62"
                            }
                        }, name: "setLoaderModalData"
                    },
                    {
                        action: this.props.updateInputsFieldSearch,
                        params: {AttributesText: value, Id: idSearch,},
                        name: "updateInputsFieldSearch"
                    },
                    {action: this.props.searchByParams, params: {Id: idSearch,}, name: "searchByParams"},
                    {
                        action: this.props.combineMainTableAttributesSearch,
                        params: {},
                        name: "combineMainTableAttributesSearch"
                    },
                    {
                        action: this.props.changeSearchViewMode,
                        params: {ViewMode: RootComponentsStateViewModeTypes.SearchApp.MainTableOnly},
                        name: "changeSearchViewMode"
                    },
                    {
                        action: this.props.setLoaderModalData,
                        params: {keyDeleted: "AttributesSection73"},
                        name: "setLoaderModalData"
                    },
                ],
                debug: false,
            });
            history.replace('/search')
        }
    }

    // открытье\закрытие раздела
    toggle() {
        if (this.props.viewMode === "normal") {
            this.props.toggleAdditionalSectionsOpen({
                Name: this.sectionName
            });
        } else if (this.props.viewMode === "card") {
            this.props.toggleAdditionalSectionsOpen_Cards({
                Name: this.sectionName
            });
        }
    }

    // обработка изменения атрибутов
    editHandler(event, item, IsMultipleAllowed = false) {
        let value= null;
        if(getIdAttributeTypeByItem(item) === DataTypes.Boolean){
            value = event?.target?.checked ? "1" : "0"
        }else{
            value = event?.target?.value ?? event?.value;
        }
        const isDate = getIdAttributeTypeByItem(item) === DataTypes.DataTime
        if (isDate && !item.IsConstant) {
            if (Array.isArray(value)) {
                value = value.map(el => __.getCorrectDate(el))
            } else {
                value = __.getCorrectDate(value);
            }
        }
        if (!IsMultipleAllowed) {
            if (item && getIdAttributeTypeByItem(item) === DataTypes.DocVersionRef) {
                if (event) {
                    value = event?.value?.toString();
                }
            }
            this.props.updateAttributesRedactorData({
                item: item,
                newValue: value,
            });
        } else {
            if (!(
                item && (getIdAttributeTypeByItem(item) === DataTypes.DocRef
                    || getIdAttributeTypeByItem(item) === DataTypes.DocVersionRef
                    || getIdAttributeTypeByItem(item) === DataTypes.JsonObject
                )
            )) {
                value = event
                if (isDate && !item.IsConstant) {
                    if (Array.isArray(value)) {
                        value = value.map(el => __.getCorrectDate(el))
                    } else {
                        value = __.getCorrectDate(value);
                    }
                }
            }
            //Фильтруем оригинальные значения по id атрибута, заменяем значение для id последовательно

            this.props.updateAttributesRedactorData({
                item: item,
                newValue: value,
            });
        }
    }

    renderJsonObjectFragment = (item, rowId, IsMultipleAllowed) => {
        let lInputValue = item.Value
        if (rowId) {
            if (item.Value && item.Value.length > 0 && (rowId - 1) >= 0 && (rowId - 1) < item.Value.length) {
                lInputValue = item.Value[rowId - 1];
            } else {
                lInputValue = null;
            }
        }
        return <JsonObjectFragment lInputValue={lInputValue}
                                   lAllInputValue={item.Value}
                                   isRedactorMode={this.props.redactorMode}
                                   rowId={rowId}
                                   item={item}
                                   IsMultipleAllowed
                                   inputHandler={(value, item) => this.editHandler(IsMultipleAllowed ? {value} : value, item, IsMultipleAllowed)}
        />
    }

    deleteMultiplyRowHandler(lIndex, item) {
        const value = Array.isArray(item.Value) ? item.Value : [item.Value]
        const newValue = [...value.slice(0, lIndex), ...value.slice(lIndex + 1, value.length)];
        this.editHandler({value: newValue}, item, true)
    }

    addMultiplyRowHandler(lIndex, item) {
        const currentValue = Array.isArray(item.Value) ? item.Value : [item.Value]
        let newValue;
        if (currentValue && currentValue.length > 0) {
            if (!((lIndex - 1 >= 0) && (lIndex - 1 < currentValue.length))) {
                const lExtensibleInputValue = __.deepCopy(currentValue);
                lExtensibleInputValue.splice(lIndex - 1, 0, null);
                newValue = lExtensibleInputValue
            }
        } else {
            let updatedData = [];
            updatedData[lIndex - 1] = null;
            newValue = updatedData
        }
        newValue && this.editHandler({value: newValue}, item, true);
    }

    render() {
        let sectionData;
        let isReady;
        let originalCard = null;
        let originalSectionData = null;
        if (this.props.viewMode === "normal") {
            sectionData = this.props.additionalSectionsData.state[this.sectionName];
            isReady = this.props.additionalSectionsData.isReady;
        } else if (this.props.viewMode === "card") {
            let card;
            if (this.props.redactorMode && this.props.cards.redactorData) {
                card = this.props.cards.redactorData;
                originalCard = this.props.cards.collection.find(card => card.Id === this.props.cards.activeCardId);
            } else {
                card = this.props.cards.collection.find(card => card.Id === this.props.cards.activeCardId);
            }
            if (originalCard) {
                originalSectionData = __.deepCopy(originalCard.additionalSections[this.sectionName]);
            }

            sectionData = __.deepCopy(card.additionalSections[this.sectionName]);
            sectionData.isOpen = this.props.cards.collection
                .find(card => card.Id === this.props.cards.activeCardId)
                .additionalSections[this.sectionName].isOpen;
            isReady = card.additionalSections.isReady;
        }

        let cardTitleClass = "card-title";
        let cardCollapseClass = "collapse show";
        if(!sectionData.isOpen) {
            cardTitleClass = "card-title collapsed";
            cardCollapseClass = "collapse";
        }

        const lDataAttributes = __.convertAttributesArrayToUnique(sectionData.attributes, 'Value');
        const lOriginalData = __.convertAttributesArrayToUnique(originalSectionData?.attributes, 'Value');

        return (
            <div className="card">
                <div className="card-header" onClick={this.toggle}>
                    <div className={cardTitleClass}>
                        Атрибуты документа
                    </div>
                </div>
                <div className={cardCollapseClass}>
                    <div className="card-body p-0">
                        <table className="table table-bordered table-striped m-0 w-100" style={{tableLayout: "fixed"}}>
                            <thead>
                            <tr>
                                <th className="col-lg-4">Атрибут</th>
                                <th className="col-lg-7">Значение</th>
                                {!this.props.redactorMode &&
                                    // todo кн пока оставлю через 5 rem чтобы не переносилась надпись, как сделать не через четкое значение пока не придумал
                                    <th className="col-lg-1" style={{width: '5rem'}}>
                                        Найти
                                    </th>
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                isReady && lDataAttributes.map((item, index) => {
                                    const IsMultipleAllowed = item.IsMultipleAllowed;
                                    if (this.props.redactorMode) {
                                        if (item.AttributeName.IdDictionaries) {
                                            return (
                                                <tr key={item.AttributeName.Id}>
                                                    {__.isAttributeValuesEquals(lOriginalData[index]?.Value, item.Value?.value) ?
                                                        (<td
                                                            style={{verticalAlign: "middle"}}>{item.AttributeName.Name}</td>)
                                                        :
                                                        (<td style={{verticalAlign: "middle", position: "relative"}}>
                                                            {item.IsRequired ? <b>{item.ColumnName}
                                                                <sup>*</sup></b> : <span>{item.ColumnName}</span>}
                                                            <span className="btn btn-icon btn-sm"
                                                                  style={{
                                                                      top: "13px",
                                                                      right: "0px",
                                                                      position: "absolute"
                                                                  }}
                                                                  title="Внесены изменения"
                                                            >
                                                                <i className="svg-icon icon-Edit icon-color-danger"/>
                                                            </span>
                                                        </td>)
                                                    }
                                                    <td>
                                                        <AttributeWithDictionaries
                                                            attributeValue={item.Value}
                                                            idDictionaryValue={item.IdDictionaryValue}
                                                            idDictionaries={item.AttributeName.IdDictionaries}
                                                            dictionaryFieldName={item.AttributeName.DictionaryFieldName}
                                                            externalDictionariesValues={this.props.externalDictionariesValues}
                                                            isRedactorMode={true}
                                                            isMultiply={IsMultipleAllowed}
                                                            setNewValues={(newValues) => {
                                                                this.props.updateAttributesRedactorData({
                                                                    item: item,
                                                                    newValue: newValues.value,
                                                                    newIdDictionaryValue: newValues.idDictionaryValue,
                                                                })
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        }
                                        if (item.AttributeName === "Version.ModifyTime") {
                                            return (
                                                <tr key={item.ColumnName}>
                                                    <td style={{verticalAlign: "middle"}}>{item.ColumnName}</td>
                                                    <td>
                                                        <input className="form-control"
                                                               type="datetime-local"
                                                               min="1900-01-01T00:00" max="3000-12-31T00:00"
                                                               disabled={true}
                                                               value={item.Value}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        } else if (item.AttributeName === "RegDate") {
                                            return (
                                                <tr key={item.ColumnName}>
                                                    {__.isAttributeValuesEquals(lOriginalData[index].Value, item.Value) ?
                                                        <td style={{verticalAlign: "middle"}}>
                                                            <b>
                                                                {item.ColumnName}
                                                                <sup>*</sup>
                                                            </b>
                                                        </td>
                                                        : <td style={{verticalAlign: "middle", position: "relative"}}>
                                                            <b>
                                                                {item.ColumnName}
                                                                <sup>*</sup>
                                                            </b>
                                                            <span className="btn btn-icon btn-sm"
                                                                  style={{top: "13px", right: "0px", position: "absolute"}}
                                                                  title="Внесены изменения"
                                                            >
                                                                <i className="svg-icon icon-Edit icon-color-danger"/>
                                                            </span>
                                                        </td>
                                                    }
                                                    <td>
                                                        <input className="form-control"
                                                               type={'date'}
                                                               min="1900-01-01" max="3000-12-31"
                                                               value={item.Value?.split('T')[0]}
                                                               onChange={(event) => {
                                                                   let newRegDateValue = event.target.value
                                                                   const todayDate = new Date()
                                                                   if (todayDate < new Date(newRegDateValue)) {
                                                                       newRegDateValue = todayDate.toLocaleDateString().split('.').reverse().join('-')
                                                                   }
                                                                   newRegDateValue = newRegDateValue + "T00:00:00"
                                                                   this.props.updateAttributesRedactorData({
                                                                       item: item,
                                                                       newValue: newRegDateValue,
                                                                   });
                                                               }}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        } else {
                                            if (__.isAttributeValuesEquals(lOriginalData[index]?.Value, item?.Value)) {
                                                return (
                                                    <tr key={item.ColumnName}>
                                                        {
                                                            // (__.isUniqueAttribute(item.IdAttributeName) || item?.IsRequired) &&
                                                            (item?.IsRequired || item?.AttributeName === 'RegNumber' || item?.AttributeName === 'Name') &&
                                                            <td style={{verticalAlign: "middle"}}>
                                                                <b>
                                                                    {item.ColumnName}
                                                                    <sup>*</sup>
                                                                </b>
                                                            </td>
                                                        }
                                                        {
                                                            (item.AttributeName !== 'RegNumber' && item.AttributeName !== 'Name')
                                                            && !item?.IsRequired
                                                            // (!__.isUniqueAttribute(item.IdAttributeName) && !item?.IsRequired) &&
                                                            &&
                                                            <td style={{verticalAlign: "middle"}}>{item.ColumnName}</td>
                                                        }
                                                        <td>
                                                            {
                                                                getIdAttributeTypeByItem(item) !== DataTypesEnum.DataTime ?
                                                                    (
                                                                        (IsMultipleAllowed && getIdAttributeTypeByItem(item) !== DataTypesEnum.Boolean) ?
                                                                            (getIdAttributeTypeByItem(item) === DataTypesEnum.DocRef ?
                                                                                    <MultipleDocRef
                                                                                        item={item}
                                                                                        valueFieldName={'Value'}
                                                                                        viewMode={this.props.viewMode}
                                                                                        isRedactorMode={true}
                                                                                        setNewItemValue={newValueOfItem => {
                                                                                            this.editHandler({value: newValueOfItem}, item, true)
                                                                                        }}
                                                                                        duplicateCallback={()=> {
                                                                                            store.dispatch(setModalData({
                                                                                                name: ModalTypes.app.alert,
                                                                                                data: {
                                                                                                    content: "Новое значение не уникально для атрибута!",
                                                                                                    disableButton: false
                                                                                                }
                                                                                            }))
                                                                                        }}
                                                                                    />
                                                                                    : (getIdAttributeTypeByItem(item) === DataTypesEnum.DocVersionRef ?
                                                                                            <MultipleDocVersionRef
                                                                                                item={item}
                                                                                                valueFieldName={'Value'}
                                                                                                viewMode={this.props.viewMode}
                                                                                                isRedactorMode={true}
                                                                                                setNewItemValue={newValueOfItem => {
                                                                                                    this.editHandler({value: newValueOfItem}, item, true)
                                                                                                }}
                                                                                                duplicateCallback={()=> {
                                                                                                    store.dispatch(setModalData({
                                                                                                        name: ModalTypes.app.alert,
                                                                                                        data: {
                                                                                                            content: "Новое значение не уникально для атрибута!",
                                                                                                            disableButton: false
                                                                                                        }
                                                                                                    }))
                                                                                                }}
                                                                                            />
                                                                                            : (getIdAttributeTypeByItem(item) === DataTypesEnum.JsonObject ?
                                                                                                    <MultipleAttributeTable
                                                                                                        deleteRow={(lIndex) => this.deleteMultiplyRowHandler(lIndex, item)}
                                                                                                        addRow={(lIndex) => this.addMultiplyRowHandler(lIndex, item)}
                                                                                                        item={{
                                                                                                            ...item,
                                                                                                            Value: __.getCorrectValueForMultiply(item.Value) || []
                                                                                                        }}
                                                                                                        contents={(item, rowId) => [{
                                                                                                            content: this.renderJsonObjectFragment(item, rowId, IsMultipleAllowed),
                                                                                                            style: {width: "100%"}
                                                                                                        }]}
                                                                                                    />
                                                                                                    : <MySelectNew
                                                                                                        returnFunc={(list) => {
                                                                                                            let result = list.map((item, index) => {
                                                                                                                return item;
                                                                                                            })
                                                                                                            this.editHandler(result, item, true)
                                                                                                        }
                                                                                                        }
                                                                                                        dataList={__.getCorrectValueForMultiply(item.Value)}
                                                                                                        type={getIdAttributeTypeByItem(item)}
                                                                                                        placeholder={`${item.ColumnName}...`}
                                                                                                        isMulti={true}
                                                                                                        // стили для отображения контекстного меню браузера как текстового поля (копировать/вставить и т.д.)
                                                                                                        styles={createSelectStyles}
                                                                                                        className={''}
                                                                                                        duplicateCallback={()=> {
                                                                                                            store.dispatch(setModalData({
                                                                                                                name: ModalTypes.app.alert,
                                                                                                                data: {
                                                                                                                    content: "Новое значение не уникально для атрибута!",
                                                                                                                    disableButton: false
                                                                                                                }
                                                                                                            }))
                                                                                                        }}
                                                                                                    />
                                                                                            )
                                                                                    )
                                                                            )
                                                                            : (
                                                                                getIdAttributeTypeByItem(item) === DataTypesEnum.DocRef ?
                                                                                    <DocRef viewMode={this.props.viewMode}
                                                                                            docIdValue={item.Value}
                                                                                            isRedactorMode={true}
                                                                                            shortDescription={item.ShortDescription}
                                                                                            setCurrentDocIdValue={(newDocIdValue) => {
                                                                                                this.editHandler({value: newDocIdValue?.toString()}, item)
                                                                                            }}
                                                                                    />
                                                                                    : (getIdAttributeTypeByItem(item) === DataTypesEnum.DocVersionRef ?
                                                                                            <DocVersionRef
                                                                                                viewMode={this.props.viewMode}
                                                                                                versionIdValue={item.Value}
                                                                                                shortDescription={item.ShortDescription}
                                                                                                isRedactorMode={true}
                                                                                                setCurrentVersionValue={(newVersionIdValue) => {
                                                                                                    this.editHandler({value: newVersionIdValue?.toString()}, item)
                                                                                                }}
                                                                                            />
                                                                                            :
                                                                                            (getIdAttributeTypeByItem(item) === DataTypesEnum.JsonObject ?
                                                                                                    this.renderJsonObjectFragment(item, null, IsMultipleAllowed)
                                                                                                    : (getIdAttributeTypeByItem(item) === DataTypesEnum.Boolean ?
                                                                                                            <input
                                                                                                                className="form-control"
                                                                                                                type={__.inputTypeByDataTypeId(getIdAttributeTypeByItem(item))}
                                                                                                                value={item.Value}
                                                                                                                checked={item.Value == 1 ? true : false}
                                                                                                                onChange={(event) => {
                                                                                                                    this.editHandler(event, item)
                                                                                                                }}
                                                                                                            />
                                                                                                            :
                                                                                                            <input
                                                                                                                className="form-control"
                                                                                                                type={__.inputTypeByDataTypeId(getIdAttributeTypeByItem(item))}
                                                                                                                value={item.Value}
                                                                                                                onChange={(event) => {
                                                                                                                    this.editHandler(event, item)
                                                                                                                }}
                                                                                                            />
                                                                                                    )

                                                                                            )
                                                                                    )
                                                                            )
                                                                    )
                                                                    :
                                                                    (IsMultipleAllowed ?
                                                                            <MySelectNew
                                                                                returnFunc={(list) => {
                                                                                    let result = list.map(item => {
                                                                                    return item;
                                                                                })
                                                                                this.editHandler(result, item, true)
                                                                            }}
                                                                            type={getIdAttributeTypeByItem(item)}
                                                                            placeholder={`${item.AttributeName.Name}...`}
                                                                            dataList={__.getCorrectValueForMultiply(item.Value)}
                                                                            isMulti={true}
                                                                            styles={createSelectStyles}
                                                                            className={''}
                                                                            duplicateCallback={()=> {
                                                                                store.dispatch(setModalData({
                                                                                    name: ModalTypes.app.alert,
                                                                                    data: {
                                                                                        content: "Новое значение не уникально для атрибута!",
                                                                                        disableButton: false
                                                                                    }
                                                                                }))
                                                                            }}
                                                                        />
                                                                        : <input className="form-control"
                                                                                 type={'date'}
                                                                                 min="1900-01-01" max="3000-12-31"
                                                                                 value={__.dateToServer(item.Value, false, true)}
                                                                                 onChange={(event) => {
                                                                                     this.editHandler(event, item)
                                                                                 }}
                                                                            />
                                                                    )
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            } else {
                                                return (
                                                    <tr key={item.ColumnName}>
                                                        {
                                                            __.isUniqueAttribute(item.IdAttributeName) &&
                                                            <td style={{verticalAlign: "middle", position: "relative"}}>
                                                                <b> {item.ColumnName}<sup>*</sup></b>
                                                                <span className="btn btn-icon btn-sm"
                                                                      style={{
                                                                          top: "13px",
                                                                          right: "0px",
                                                                          position: "absolute"
                                                                      }}
                                                                      title="Внесены изменения"
                                                                >
                                                                    <i className="svg-icon icon-Edit icon-color-danger"/>
                                                                </span>
                                                            </td>
                                                        }
                                                        {
                                                            !__.isUniqueAttribute(item.IdAttributeName) &&
                                                            <td style={{verticalAlign: "middle", position: "relative"}}>
                                                                {item.ColumnName}
                                                                <span className="btn btn-icon btn-sm"
                                                                      style={{
                                                                          top: "13px",
                                                                          right: "0px",
                                                                          position: "absolute"
                                                                      }}
                                                                      title="Внесены изменения"
                                                                >
                                                                    <i className="svg-icon icon-Edit icon-color-danger"/>
                                                                </span>
                                                            </td>
                                                        }
                                                        <td>
                                                            {getIdAttributeTypeByItem(item) === DataTypesEnum.DataTime ?
                                                                (IsMultipleAllowed ?
                                                                        <MySelectNew
                                                                            returnFunc={(list) => {
                                                                                let result = list.map(item => {
                                                                                    return item;
                                                                                })
                                                                                this.editHandler(result, item, true)
                                                                            }}
                                                                            type={getIdAttributeTypeByItem(item)}
                                                                            placeholder={`${item.AttributeName.Name}...`}
                                                                            dataList={__.getCorrectValueForMultiply(item.Value)}
                                                                            isMulti={true}
                                                                            styles={createSelectStyles}
                                                                            className={''}
                                                                            duplicateCallback={()=> {
                                                                                store.dispatch(setModalData({
                                                                                    name: ModalTypes.app.alert,
                                                                                    data: {
                                                                                        content: "Новое значение не уникально для атрибута!",
                                                                                        disableButton: false
                                                                                    }
                                                                                }))
                                                                            }}
                                                                        />
                                                                        : <input className="form-control"
                                                                           type={'date'}
                                                                           min="1900-01-01" max="3000-12-31"
                                                                           value={__.dateToServer(item.Value, false, true)}
                                                                           onChange={(event) => {
                                                                               this.editHandler(event, item)
                                                                           }}
                                                                        />
                                                                ) : (
                                                                    (IsMultipleAllowed && getIdAttributeTypeByItem(item) !== DataTypesEnum.Boolean) ?
                                                                        (getIdAttributeTypeByItem(item) === DataTypesEnum.DocRef ?
                                                                                <MultipleDocRef
                                                                                    item={item}
                                                                                    valueFieldName={'Value'}
                                                                                    viewMode={this.props.viewMode}
                                                                                    isRedactorMode={true}
                                                                                    setNewItemValue={newValueOfItem => {
                                                                                        this.editHandler({value: newValueOfItem}, item, true)
                                                                                    }}
                                                                                    duplicateCallback={()=> {
                                                                                        store.dispatch(setModalData({
                                                                                            name: ModalTypes.app.alert,
                                                                                            data: {
                                                                                                content: "Новое значение не уникально для атрибута!",
                                                                                                disableButton: false
                                                                                            }
                                                                                        }))
                                                                                    }}
                                                                                />
                                                                                : (getIdAttributeTypeByItem(item) === DataTypesEnum.DocVersionRef ?
                                                                                        <MultipleDocVersionRef
                                                                                            item={item}
                                                                                            valueFieldName={'Value'}
                                                                                            viewMode={this.props.viewMode}
                                                                                            isRedactorMode={true}
                                                                                            setNewItemValue={newValueOfItem => {
                                                                                                this.editHandler({value: newValueOfItem}, item, true)
                                                                                            }}
                                                                                            duplicateCallback={()=> {
                                                                                                store.dispatch(setModalData({
                                                                                                    name: ModalTypes.app.alert,
                                                                                                    data: {
                                                                                                        content: "Новое значение не уникально для атрибута!",
                                                                                                        disableButton: false
                                                                                                    }
                                                                                                }))
                                                                                            }}
                                                                                        />
                                                                                        : (getIdAttributeTypeByItem(item) === DataTypesEnum.JsonObject ?
                                                                                                <MultipleAttributeTable
                                                                                                    deleteRow={(lIndex) => this.deleteMultiplyRowHandler(lIndex, item)}
                                                                                                    addRow={(lIndex) => this.addMultiplyRowHandler(lIndex, item)}
                                                                                                    item={{
                                                                                                        ...item,
                                                                                                        Value: __.getCorrectValueForMultiply(item.Value) || []
                                                                                                    }}
                                                                                                    contents={(item, rowId) => [{
                                                                                                        content: this.renderJsonObjectFragment(item, rowId, IsMultipleAllowed),
                                                                                                        style: {width: "100%"}
                                                                                                    }]
                                                                                                    }
                                                                                                />
                                                                                                : <MySelectNew
                                                                                                    returnFunc={(list) => {
                                                                                                        let result = list.map((item, index) => {
                                                                                                            return item;
                                                                                                        })
                                                                                                        this.editHandler(result, item, true)
                                                                                                    }
                                                                                                    }
                                                                                                    dataList={__.getCorrectValueForMultiply(item.Value)}
                                                                                                    type={getIdAttributeTypeByItem(item)}
                                                                                                    placeholder={`${item.ColumnName}...`}
                                                                                                    isMulti={true}
                                                                                                    // стили для отображения контекстного меню браузера как текстового поля (копировать/вставить и т.д.)
                                                                                                    styles={createSelectStyles}
                                                                                                    className={''}
                                                                                                    duplicateCallback={()=> {
                                                                                                        store.dispatch(setModalData({
                                                                                                            name: ModalTypes.app.alert,
                                                                                                            data: {
                                                                                                                content: "Новое значение не уникально для атрибута!",
                                                                                                                disableButton: false
                                                                                                            }
                                                                                                        }))
                                                                                                    }}
                                                                                                />
                                                                                        )
                                                                                )
                                                                        )
                                                                        : (getIdAttributeTypeByItem(item) === DataTypesEnum.DocRef ?
                                                                                <DocRef viewMode={this.props.viewMode}
                                                                                        docIdValue={item.Value}
                                                                                        shortDescription={item.ShortDescription}
                                                                                        isRedactorMode={true}
                                                                                        setCurrentDocIdValue={(newDocIdValue) => {
                                                                                            this.editHandler({value: newDocIdValue?.toString()}, item)
                                                                                        }}
                                                                                />
                                                                                : (getIdAttributeTypeByItem(item) === DataTypesEnum.DocVersionRef ?
                                                                                        <DocVersionRef
                                                                                            viewMode={this.props.viewMode}
                                                                                            versionIdValue={item.Value}
                                                                                            shortDescription={item.ShortDescription}
                                                                                            isRedactorMode={true}
                                                                                            setCurrentVersionValue={(newVersionIdValue) => {
                                                                                                this.editHandler({value: newVersionIdValue?.toString()}, item)
                                                                                            }}
                                                                                        />
                                                                                        : (getIdAttributeTypeByItem(item) === DataTypesEnum.JsonObject ?
                                                                                                <>
                                                                                                    {this.renderJsonObjectFragment(item, null, false)}
                                                                                                </>
                                                                                                :
                                                                                                <input className="form-control"
                                                                                                       type={__.inputTypeByDataTypeId(getIdAttributeTypeByItem(item))}
                                                                                                       value={item.Value}
                                                                                                       onChange={(event) => {
                                                                                                           this.editHandler(event, item)
                                                                                                       }}
                                                                                                />
                                                                                        )
                                                                                )
                                                                        )
                                                                )
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        }
                                    } else {
                                        // !redactor mode
                                        let type = null;
                                        if (item.AttributeName === "Name") type = "Name";
                                        else if (item.AttributeName === "RegNumber") type = "RegNumber";
                                        else if (item.AttributeName === "RegDate") type = "RegDateAsString";

                                        if (getIdAttributeTypeByItem(item) === DataTypesEnum.Boolean) {
                                            return (
                                                <tr key={item.ColumnName}>
                                                    <td>{item.ColumnName}</td>
                                                    {
                                                        item.Value == 1 ?
                                                            // <td><input type={"checkbox"} checked disabled/></td>
                                                            <td>
                                                             <span className="btn btn-icon  btn-xs">
                                                                <i className="svg-icon icon-color-primary icon-checked"/>
                                                             </span>
                                                            </td>
                                                            :
                                                            <td><input type={"checkbox"} disabled/></td>
                                                    }

                                                    <td>
                                                        {
                                                            (
                                                                item.Value !== "null"
                                                                && item.Value !== null
                                                                && item.Value !== ''
                                                                && item.AttributeName !== "Version.ModifyTime"
                                                            ) &&
                                                            <span
                                                                className="btn btn-icon btn-outline-success btn-circle btn-xs"
                                                                onClick={() => {
                                                                    this.search(item.Value, type)
                                                                }}
                                                            >
                                                                <i className="svg-icon icon-Search"/>
                                                            </span>
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        } else {
                                            let valueForSanitize;
                                            if (item.Value === "null") {
                                                valueForSanitize = null
                                            } else {
                                                if (item.AttributeName === "RegDate" || item.AttributeName === "Version.ModifyTime") {
                                                    valueForSanitize = __.dateToRussia(item.Value);
                                                } else {
                                                    valueForSanitize = item.Value
                                                }
                                            }
                                            let secondTdContent = parse(sanitizeHtml(valueForSanitize));
                                            const attributeType = getIdAttributeTypeByItem(item)
                                            if (item.AttributeName.IdDictionaries) {
                                                secondTdContent =
                                                    <AttributeWithDictionaries
                                                        attributeValue={item.Value}
                                                        idDictionaryValue={item.IdDictionaryValue}
                                                        idDictionaries={item.AttributeName.IdDictionaries}
                                                        dictionaryFieldName={item.AttributeName.DictionaryFieldName}
                                                        externalDictionariesValues={this.props.externalDictionariesValues}
                                                        isRedactorMode={false}
                                                        isMultiply={IsMultipleAllowed}
                                                    />
                                            }
                                            else if (attributeType === DataTypesEnum.JsonObject) {
                                                const correctValue = !IsMultipleAllowed ? item.Value : __.getCorrectValueForMultiply(item.Value)
                                                secondTdContent = (IsMultipleAllowed && correctValue) ?
                                                    (
                                                        < div
                                                            className="d-flex flex-column beautiful-scroll-10"
                                                            style={
                                                                {
                                                                    maxHeight: '5rem',
                                                                    paddingRight:
                                                                        correctValue.length > 2 ? '0.75rem' : 0
                                                                }
                                                            }
                                                        >
                                                            {correctValue.map((lValue, lIndex) => {
                                                                return (
                                                                    <div
                                                                        className={`${lIndex !== correctValue.length - 1 ? 'mb-3' : ''}`}>
                                                                        {this.renderJsonObjectFragment({
                                                                            ...item,
                                                                            Value: lValue
                                                                        }, null, IsMultipleAllowed)}
                                                                    </div>
                                                                )

                                                            })
                                                            }
                                                        </div>
                                                    ) : this.renderJsonObjectFragment(item, null, IsMultipleAllowed)
                                            }
                                            else if (attributeType === DataTypesEnum.DocRef) {
                                                secondTdContent = IsMultipleAllowed ?
                                                    <MultipleDocRef
                                                        item={item}
                                                        valueFieldName={'Value'}
                                                        viewMode={this.props.viewMode}
                                                        isRedactorMode={false}
                                                    />
                                                    : <DocRef
                                                        viewMode={this.props.viewMode}
                                                        docIdValue={item.Value}
                                                        shortDescription={item.ShortDescription}
                                                        isRedactorMode={false}
                                                    />
                                            }
                                            else if (attributeType === DataTypesEnum.DocVersionRef) {
                                                secondTdContent = IsMultipleAllowed ?
                                                    <MultipleDocVersionRef
                                                        item={item}
                                                        valueFieldName={'Value'}
                                                        viewMode={this.props.viewMode}
                                                        isRedactorMode={false}
                                                    />
                                                    : <DocVersionRef viewMode={this.props.viewMode}
                                                                     versionIdValue={item.Value}
                                                                     shortDescription={item.ShortDescription}
                                                                     isRedactorMode={false}
                                                    />
                                            }
                                            return (
                                                <tr key={item.ColumnName}>
                                                    <td>{item.ColumnName}</td>
                                                    <td>{secondTdContent}</td>
                                                    <td>
                                                        {
                                                            (
                                                                item.Value !== "null"
                                                                && item.Value !== null
                                                                && item.Value !== ''
                                                                && item.AttributeName !== "Version.ModifyTime"
                                                                && attributeType !== DataTypesEnum.JsonObject
                                                                && attributeType !== DataTypesEnum.DocRef
                                                                && attributeType !== DataTypesEnum.DocVersionRef
                                                            ) &&
                                                            <span
                                                                className="btn btn-icon btn-outline-success btn-circle btn-xs"
                                                                onClick={() => {
                                                                    this.search(item.Value, type)
                                                                }}
                                                            >
                                                                <i className="svg-icon icon-Search"/>
                                                            </span>
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    }
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeRowTypeId: state.document.mainTable?.activeRow?.Type?.Id,
        additionalSectionsData: state.document.additionalSections,
        cards: state.document.cards,
        externalDictionariesValues: state.document.temporaryData.ExternalDictionariesValues,
        externalDictionary: state.document.temporaryData.ExternalDictionary,
    }
}

const mapDispatchToProps = {
    ActionQueue,
    changeSearchViewMode,
    combineMainTableAttributesSearch,
    createRedactorData,
    searchByParams,
    setLoaderModalData,
    toggleAdditionalSectionsOpen,
    toggleAdditionalSectionsOpen_Cards,
    updateAttributesRedactorData,
    updateInputsFieldSearch,
    setModalData,
    setStateLeftAsideMenu,
    setVersionIdForLocate,
}

export default connect(mapStateToProps, mapDispatchToProps)(AttributesSection);
