import { ActionTypes } from "../masterAppActionList";

export const setOtherFiles = (files) => {
    return {
        type: ActionTypes.SET_OTHER_FILES,
        payload: files,
    };
};

export const setOriginalFile = (file) => {
    return {
        type: ActionTypes.SET_ORIGINAL_FILE,
        payload: file,
    };
};

export const setShowBanner = (showBanner) => {
    return {
        type: ActionTypes.SET_SHOW_BANNER,
        payload: showBanner,
    };
};

export const setActiveIdFilial = (idFilial) => {
    return {
        type: ActionTypes.SET_ACTIVE_ID_FILIAL,
        payload: idFilial,
    };
};

export const setActiveType = (type) => {
    return {
        type: ActionTypes.SET_ACTIVE_TYPE,
        payload: type,
    };
};

export const setSelectedGroups = (groups) => {
    return {
        type: ActionTypes.SET_SELECTED_GROUPS,
        payload: groups,
    };
};

export const setLoadTypes = (value) => {
    return {
        type: ActionTypes.SET_LOAD_TYPES,
        payload: value,
    };
};

export const setLoadGroups = (value) => {
    return {
        type: ActionTypes.SET_LOAD_GROUPS,
        payload: value,
    };
};

export const setTypeValue = (value) => {
    return {
        type: ActionTypes.SET_TYPE_VALUE,
        payload: value,
    };
};

export const setDocName = (name) => {
    return {
        type: ActionTypes.SET_DOC_NAME,
        payload: name,
    };
};

export const setDocNameError = (error) => {
    return {
        type: ActionTypes.SET_DOC_NAME_ERROR,
        payload: error,
    };
};

export const setRegNumber = (number) => {
    return {
        type: ActionTypes.SET_REG_NUMBER,
        payload: number,
    };
};

export const setRegDate = (date) => {
    return {
        type: ActionTypes.SET_REG_DATE,
        payload: date,
    };
};

export const setActiveAttributes = (attributes) => {
    return {
        type: ActionTypes.SET_ACTIVE_ATTRIBUTES,
        payload: attributes,
    };
};

export const setPreviewFile = (file) => {
    return {
        type: ActionTypes.SET_PREVIEW_FILE,
        payload: file,
    };
};

export const setDisableBtnToDocument = (disable) => {
    return {
        type: ActionTypes.SET_DISABLE_BTN_TO_DOCUMENT,
        payload: disable,
    };
};

export const setComment = (comment) => {
    return {
        type: ActionTypes.SET_COMMENT,
        payload: comment,
    };
};

export const setContentText = (contentText) => {
    return {
        type: ActionTypes.SET_CONTENT_TEXT,
        payload: contentText,
    };
};

export const setVersionIdForLocate = (versionId) => {
    return {
        type: ActionTypes.SET_VERSION_ID_FOR_LOCATE,
        payload: versionId,
    };
};
