import React from "react";
import {connect} from "react-redux";
import {
    changeDocumentSearchInputs,
    handlerDocumentIsExtSearch,
} from "../../store/documents/actionCreators/document_SearchActionCreator";
import {
    combineMainTableAttributes, mainTableDownloadDocCollection,
    mainTableSearchDoc,
    selectActiveRow
} from "../../store/documents/actionCreators/document_MainTableActionCreator";
import {ActionQueue} from "../../store/rootReducer";
import {changeDocumentViewMode} from "../../store/documents/actionCreators/document_RootStateActionCreator";
import {ModalTypes, RootComponentsStateViewModeTypes} from "../../tools/StaticTypes";
import FiltersString from "./filtersByTypes/FiltersString";
import {DataTypesEnum} from "../../tools/API_NEW/ServerTypes";
import FiltersDate from "./filtersByTypes/FiltersDate";
import FiltersNumber from "./filtersByTypes/FiltersNumber";
import {setLoaderModalData, setModalData} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {store, history} from "../../index";
import {Routing} from "../../tools/routing/Routing";

class InputSearchDoc extends React.PureComponent  {

    constructor(props) {
        super(props);

        this.state = {
            checkboxOnHover : false,
            accordionShow2 : true,
            accordionShow3 : true,

            HoverIconName : "",
            currentFilter: null,
            extFilter: false
        };

        this.limitation = React.createRef();

        this.search = this.search.bind(this);
        this.accordion2 = this.accordion2.bind(this);
        this.accordion3 = this.accordion3.bind(this);
        this.inputClear = this.inputClear.bind(this);
        this.inputSearchHandler = this.inputSearchHandler.bind(this);

        this.toggleExtFilters = this.toggleExtFilters.bind(this);
        this.setExtSearch = this.setExtSearch.bind(this);
    }

    componentDidMount() {
        // TODO TP не убирать, при повторном открытии фильтра сбрасываются значения примененного фильтра

        // TODO SS баг если сначала отфильтровать поле а потом открыть фильтры
        // if(this.props.searchInputs.length > 0) {
        //     return;
        // }

        // this.props.changeDocumentSearchInputs({
        //     Delete: true
        // });

        if (!this.props.searchInputs.find(item => item.Attribute === "Дата"))
            this.props.changeDocumentSearchInputs({
                Attribute : "Дата",
                Value : {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                isConstant : true,
                IdAttributeType: DataTypesEnum.DataTime,
                Name: "RegDate", // PropertyName для фильтров
                UserVisible : this.props.allAttribute.find(item => item.Value === "Дата").UserVisible,
                IsShowExtFilters: false,
                IsApplied: false
            });
        if (!this.props.searchInputs.find(item => item.Attribute === "Номер"))
            this.props.changeDocumentSearchInputs({
                Attribute : "Номер",
                Value : {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                isConstant : true,
                IdAttributeType: DataTypesEnum.String,
                Name: "RegNumber", // PropertyName для фильтров
                UserVisible : this.props.allAttribute.find(item => item.Value === "Номер").UserVisible,
                IsShowExtFilters: false,
                IsApplied: false
            });
        if (!this.props.searchInputs.find(item => item.Attribute === "Наименование"))
            this.props.changeDocumentSearchInputs({
                Attribute : "Наименование",
                Value : {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                isConstant : true,
                IdAttributeType: DataTypesEnum.String, //определение типа для точных фильтров
                Name: "Name", // PropertyName для фильтров
                UserVisible : this.props.allAttribute.find(item => item.Value === "Наименование").UserVisible,
                IsShowExtFilters: false,
                IsApplied: false
            });

        for(let i = 0; i < this.props.allAttribute.length; i++) {
            const attribute = this.props.allAttribute[i];
            if (!(
                attribute.IsConstant
                || attribute.IdAttributeType === DataTypesEnum.DocRef
                || attribute.IdAttributeType === DataTypesEnum.DocVersionRef
                || attribute.IdAttributeType === DataTypesEnum.JsonObject
            )) {
                if (!this.props.searchInputs.find(item => item.Attribute?.Value === attribute.Value))
                    this.props.changeDocumentSearchInputs({
                        Attribute : attribute,
                        Value : attribute.IdAttributeType === 5
                            ? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''}
                            : attribute.IdAttributeType === 1 || attribute.IdAttributeType === 2
                                ? {Equal: '', LessOrEqual: '', MoreOrEqual: ''}
                                : {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                        isConstant : false,
                        Name: attribute.Name,
                        IdAttributeType: attribute.IdAttributeType,
                        IsShowExtFilters: false,
                        IsApplied: false
                    });
            }
        }
        if (!this.props.searchInputs.find(item => item.Attribute === "Последнее изменение"))
            this.props.changeDocumentSearchInputs({
                Attribute : "Последнее изменение",
                Value : {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                isConstant : true,
                IdAttributeType: DataTypesEnum.DataTime,
                Name: "Version.ModifyTime", // PropertyName для фильтров
                UserVisible : this.props.allAttribute.find(item => item.Value === "Последнее изменение").UserVisible,
                IsShowExtFilters: false,
                IsApplied: false
            });
    }

    componentDidUpdate(prevProps, prevState) {
        // для фокуса после удаления данных в поле
        this.props.searchInputs.forEach(item => {
            if(item.isFocus) {
                let Name = item.isConstant ? item.Attribute : item.Attribute.Value;
                const currentInput = document.getElementById(Name);
                if(currentInput) currentInput.focus();
            }
        })
    }

    accordion2() {
        this.setState({
            accordionShow2 : !this.state.accordionShow2
        });
    }

    toggleExtFilters(item) {
        this.props.changeDocumentSearchInputs({
            ...item,
            IsShowExtFilters: !item.IsShowExtFilters,
        })
    }

    accordion3() {
        this.setState({
            accordionShow3 : !this.state.accordionShow3
        });
    }


    async search({isClear = false, event = null, item = null, openModal = false}) {
        if (isClear) {
            await this.props.searchInputs.forEach(item => {
                this.props.changeDocumentSearchInputs({
                    ...item,
                    IsApplied: false,
                    Value: item.IdAttributeType === 5
                        ? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''}
                        : item.IdAttributeType === 1 || item.IdAttributeType === 2
                            ? {Equal: '', LessOrEqual: '', MoreOrEqual: ''}
                            : {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''}
                });
            });
            // TODO КН 31.01.24 activeRow и activeNode не приходят в компонент, но мб надо просто додумать закомменченную логику чтобы актив роу не сбрасывался
            // const {activeRow, activeNode} = this.props;
            // if(activeRow) {
            //     await this.props.ActionQueue({
            //         List: [
            //             {
            //                 action: this.props.setLoaderModalData, params: {
            //                     data: {
            //                         content: "Загрузка документов...",
            //                         disableButton: true,
            //                         fullBackground: true,
            //                         gif: "documents",
            //                         key: "inputSearchDoc215"
            //                     }
            //                 }, name: "setLoaderModalData"
            //             },
            //             {
            //                 action: this.props.mainTableDownloadDocCollection,
            //                 params: {id: activeRow.Info.Id},
            //                 name: "mainTableDownloadDocCollection"
            //             },
            //             {
            //                 action: this.props.combineMainTableAttributes,
            //                 params: {ActiveNode: activeNode},
            //                 name: "combineMainTableAttributes"
            //             },
            //             {action: this.props.selectActiveRow, params: {RowInfo: activeRow}, name: "selectActiveRow"},
            //             {action: this.props.resetDocumentPreview, params: {}, name: "resetDocumentPreview"},
            //             {
            //                 action: this.props.createPreviewBySelectDocument,
            //                 params: {},
            //                 name: "createPreviewBySelectDocument"
            //             },
            //             {
            //                 action: this.props.collectDataForAdditionalSections_Normal,
            //                 params: {},
            //                 name: "collectDataForAdditionalSections_Normal"
            //             },
            //             {action: this.props.changeDocumentViewMode, params: {ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.PreviewNormal}, name:"changeDocumentViewMode"},
            //             {
            //                 action: this.props.setLoaderModalData,
            //                 params: {keyDeleted: "inputSearchDoc228"},
            //                 name: "setLoaderModalData"
            //             }
            //         ],
            //         debug: true
            //     });
            // } else {
            this.search({});
            // }
        }
        else {
            if (this.props.searchInputs.every(item => Object.values(item.Value).every(elem => !elem)) && openModal) {
                this.props.setModalData({
                    name: ModalTypes.app.info,
                    data: {type: "fail", content: `Нужно заполнить хотя бы один атрибут`}
                });
                return
            }

            this.props.changeDocumentViewMode({
                ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly
            });

            if (!item) {
                // TODO КН 31.01.24 activeRow и activeNode не приходят в компонент, но мб надо просто додумать закомменченную логику чтобы актив роу не сбрасывался
                // const {activeRow, activeNode} = this.props;
                // if(activeRow) {
                //     await this.props.ActionQueue({
                //         List: [
                //             {action: this.props.setLoaderModalData, params: {data: {
                //                         content: "Загрузка документов...",
                //                         disableButton: true,
                //                         fullBackground: true,
                //                         gif: "documents",
                //                         key: "inputSearchDoc215"
                //                     }}, name:"setLoaderModalData"
                //             },
                //             {action: this.props.mainTableDownloadDocCollection, params: {id: activeRow.Info.Id}, name:"mainTableDownloadDocCollection"},
                //             {action: this.props.mainTableSearchDoc, params: {PaginationPageNumber: 1}, name:"mainTableSearchDoc"},
                //             {action: this.props.combineMainTableAttributes, params: {ActiveNode: activeNode}, name:"combineMainTableAttributes"},
                //             {action: this.props.selectActiveRow, params: {RowInfo: activeRow}, name: "selectActiveRow"},
                //             {action: this.props.resetDocumentPreview, params: {}, name:"resetDocumentPreview"},
                //             {action: this.props.createPreviewBySelectDocument, params: {}, name:"createPreviewBySelectDocument"},
                //             {action: this.props.collectDataForAdditionalSections_Normal, params: {}, name:"collectDataForAdditionalSections_Normal"},
                //             {action: this.props.changeDocumentViewMode, params: {ViewMode: RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly}, name:"changeDocumentViewMode"},
                //             {action: this.props.setLoaderModalData, params: {keyDeleted: "inputSearchDoc228"}, name:"setLoaderModalData"}
                //         ],
                //         debug: true
                //     });
                //
                // } else {
                await this.props.ActionQueue({
                    List: [
                        {
                            action: this.props.setLoaderModalData,
                            params: {
                                data: {
                                    content: "Загрузка документов...",
                                    disableButton: true,
                                    fullBackground: false,
                                    gif: "documents",
                                    key: "inputSearchDoc216"
                                }
                            },
                            name: "setLoaderModalData"
                        },
                        {
                            action: this.props.mainTableSearchDoc,
                            params: {PaginationPageNumber: 1},
                            name: "mainTableSearchDoc"
                        },
                        {action: this.props.combineMainTableAttributes, params: {}, name: "combineMainTableAttributes"},
                        // {action: this.props.setModalData, params: {}, name: "setModalData"},
                        //Новый прелоадер
                        {
                            action: this.props.setLoaderModalData,
                            params: {keyDeleted: "inputSearchDoc198"},
                            name: "setLoaderModalData"
                        },
                        {action: this.props.selectActiveRow, params: {}, name:"selectActiveRow"},

                    ],
                    debug: true
                });

                await (() => {
                    const query = store.getState().router.location.query
                    const queryLink = Routing.CreateRef({
                        oldQuery: query,
                        newQuery: {},
                        excludedKeys: ["d"]
                    });

                    let link = `${this.props.router.location.pathname}${queryLink}`;
                    history.replace(link)
                })
                // }
            } else {
                for (let item of this.props.searchInputs) {
                    if (item.DirectionDown.includes("icon-color-original") || item.DirectionUp.includes("icon-color-original"))
                        this.props.changeDocumentSearchInputs({
                            Direction: null,
                            Attribute: item.Attribute,
                            Value: item.Value,
                            IsApplied: item.IsApplied,
                            isConstant: item.isConstant,
                            isFocus: true,
                        })
                }
                this.props.ActionQueue({
                    List: [
                        {
                            action: this.props.setLoaderModalData,
                            params: {
                                data: {
                                    content: "Загрузка документов...",
                                    disableButton: true,
                                    fullBackground: false,
                                    gif: "documents",
                                    key: "inputSearchDoc261"
                                }
                            },
                            name: "setLoaderModalData"
                        },
                        {
                            action: this.props.mainTableSearchDoc,
                            params: {FieldSorting: item, Direction: event.target.className, PaginationPageNumber: 1},
                            name: "mainTableSearchDoc"
                        },
                        {
                            action: this.props.changeDocumentSearchInputs, params: {
                                Direction: event.target.className,
                                Attribute: item.Attribute,
                                Value: item.Value,
                                IsApplied: item.IsApplied,
                                isConstant: item.isConstant,
                                isFocus: true,
                            }, name: "changeDocumentSearchInputs"
                        },
                        {action: this.props.combineMainTableAttributes, params: {}, name: "combineMainTableAttributes"},
                        // {action: this.props.setModalData, params: {}, name: "setModalData"},
                        //Новый прелоадер
                        {
                            action: this.props.setLoaderModalData,
                            params: {keyDeleted: "inputSearchDoc235"},
                            name: "setLoaderModalData"
                        }

                    ],
                    debug: true
                });
            }
            setTimeout(() => {
                this.props.searchInputs.forEach(item => {
                    if (Object.values(item.Value).every(elem => !elem)) {
                        if (item.IsApplied) {
                            this.props.changeDocumentSearchInputs({
                                ...item,
                                IsApplied: false,
                            });
                        }
                    } else {
                        if (!item.IsApplied) {
                            this.props.changeDocumentSearchInputs({
                                ...item,
                                IsApplied: true,
                            });
                        }
                    }
                })
            }, 0)
        }
    }

    inputSearchHandler(event, item) {
        this.props.changeDocumentSearchInputs({
            Attribute : item.Attribute,
            Value : item.IdAttributeType === 5
                ? {ApproximatelyEqual: event.target.value, OnDate: '', DateStart: '', DateEnd: ''}
                : item.IdAttributeType === 1 || item.IdAttributeType === 2
                    ? {Equal: event.target.value, LessOrEqual: '', MoreOrEqual: ''}
                    : {ApproximatelyEqual: event.target.value, BeginningFrom: '', EndingOn: '', Equal: ''},
            isConstant : item.isConstant,
            IsApplied: item.IsApplied,
            Next : {
                action : ()=>{
                    if(event.target.value === "" && store.getState().document.search.searchInputs.every(inp => Object.values(inp.Value).every(elem => !elem))) {
                        this.props.searchInputs.forEach(item => {
                            for (let key in item.Value) {
                                if (item.Value[key])
                                    this.props.changeDocumentSearchInputs({
                                        ...item,
                                        Value : item.IdAttributeType === 5
                                            ? {ApproximatelyEqual: event.target.value, OnDate: '', DateStart: '', DateEnd: ''}
                                            : item.IdAttributeType === 1 || item.IdAttributeType === 2
                                                ? {Equal: event.target.value, LessOrEqual: '', MoreOrEqual: ''}
                                                : {ApproximatelyEqual: event.target.value, BeginningFrom: '', EndingOn: '', Equal: ''},
                                        IsApplied: false,
                                    });
                            }
                        })
                        // this.search({});
                        // this.props.setSearchStart({flag: false})
                    }
                },
                params : {}
            }
        });
    }

    inputClear(event, item, Name) {
        if(this.state.HoverIconName === Name) {
            this.props.changeDocumentSearchInputs({
                Attribute : item.Attribute,
                Value : item.IdAttributeType === 5
                    ? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''}
                    : item.IdAttributeType === 1 || item.IdAttributeType === 2
                        ? {Equal: '', LessOrEqual: '', MoreOrEqual: ''}
                        : {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                DirectionUp : "icon-color-gray icon-Filter_up",
                DirectionDown : "icon-color-gray icon-Filter_down",
                isConstant : item.isConstant,
                isFocus: true,
                IsApplied: false,
                Next : {
                    action : () => {
                        this.search({});
                    },
                    params : {}
                }
            });
        }
    }

    setExtFilters(ev, item) {
        this.setState({currentFilter: item, extFilter: true});
    }

    setExtSearch(ev) {
        const value = ev.target.checked
        this.props.handlerDocumentIsExtSearch({IsExtSearch: value})
    }
    render() {
        const {currentFilter} = this.state;
        let accordionShow2 = "";
        let accordionShowArrow2 = "";
        if(this.state.accordionShow2){
            accordionShow2 = "show"
            accordionShowArrow2 ="active"
        }
        return (
            <div>
                <div className="accordion accordion-light accordion-toggle-arrow mb-5">
                    <div className="card">
                        <div className="card-header">
                            <div className={"card-title " + accordionShowArrow2} onClick={this.accordion2}>
                                Фильтры
                            </div>
                        </div>
                        <div className="beautiful-scroll-5 py-2 pr-4 scrollbar-gutter-stable"
                             style={{maxHeight: "75vh", overflowX: "hidden", marginRight: -5}}>
                            <div>
                                <div className={"collapse " + accordionShow2}>
                                    <div className="row">
                                        <label className="col-9 col-form-label text-color-primary">
                                            Расширенные фильтры
                                        </label>
                                        <div className="col-3 d-flex align-items-center">
                                            <span className="switch switch-sm">
                                                <label>
                                                    <input type="checkbox"
                                                           name="extSearch"
                                                           checked={this.props.isExtSearch}
                                                           onChange={this.setExtSearch}/>
                                                    <span/>
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                    {!this.state.extFilter ?
                                        <>
                                            <div className="row mb-2">
                                                <div className="col-lg-9"
                                                     onClick={() => this.search({openModal: true})}>
                                                    <span className="btn btn-primary btn-lg btn-block my-3">
                                                        Применить
                                                    </span>
                                                </div>
                                                <div className="col-lg-3">
                                                    <span className="btn btn-outline-primary btn-icon btn-lg btn-block my-3"
                                                          title={"Очистить текущий фильтр"}
                                                          onClick={() => this.search({isClear: true})}>
                                                        <i id="disabled" className="svg-icon icon-Table_edit_8 fsic4-i"/>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                {this.props.searchInputs.map((item) => {
                                                    let fillingFieldsCount = 0;
                                                    if (typeof item.Value === "object") {
                                                        fillingFieldsCount = Object.values(item.Value).filter(Boolean).length;
                                                    }
                                                    let Name = "";
                                                    if (item.isConstant) Name = item.Attribute;
                                                    else Name = item.Attribute.Value;
                                                    let iconSearchStyle = "svg-icon svg-icon-lg icon-color-primary ";
                                                    if (Name === "Наименование") {
                                                        iconSearchStyle += "icon-name_search"
                                                    } else if (Name === "Номер") {
                                                        iconSearchStyle += "icon-number_search"
                                                    } else if (Name === "Дата" || Name === "Последнее изменение") {
                                                        iconSearchStyle += "icon-calendar_search"
                                                    } else {
                                                        iconSearchStyle += "icon-Search"
                                                    }
                                                    if (this.state.HoverIconName === Name) {
                                                        iconSearchStyle += " icon-color-red icon-Delete"
                                                    }
                                                    const inputValue = item.IdAttributeType === 5
                                                        ? item.Value.ApproximatelyEqual
                                                        : item.IdAttributeType === 1 || item.IdAttributeType === 2
                                                            ? item.Value.Equal
                                                            : item.Value.ApproximatelyEqual;
                                                    return (
                                                        <React.Fragment key={`${Name}_${item.Name}`}>
                                                            <div>
                                                                <div className="input-group h-40px my-4">
                                                                    <div className="input-group-prepend">
                                                                        <span
                                                                            role="button"
                                                                            onMouseEnter={() => {this.setState({HoverIconName: Name})}}
                                                                            onMouseLeave={() => {this.setState({HoverIconName: ""})}}
                                                                            onClick={(event) => {this.inputClear(event, item, Name)}}
                                                                            className="input-group-text-light br-left-5 line-height-0 py-0 svg-icon svg-icon-lg">
                                                                            <i className={iconSearchStyle}/>
                                                                        </span>
                                                                    </div>
                                                                    {/* Убран повторный компонент, т.к. отличается только свойство autoFocus,
                                                                            ему присвоено значение isFocus данного атрибута */}
                                                                    <input autoFocus={item.isFocus}
                                                                           type="text"
                                                                           className="form-control form-control-lg h-40px br-right-5 focus form-control-custom"
                                                                           placeholder={Name}
                                                                           title={Name}
                                                                           id={`${Name}_${item.Name}`}
                                                                           name="Name"
                                                                           onFocus={() => { // переключение фокуса, т.к. оставался фокус на поле, в котором было удаление данных
                                                                               this.props.changeDocumentSearchInputs({
                                                                                   ...item,
                                                                                   Attribute: item.Attribute,
                                                                                   isFocus: true,
                                                                               });
                                                                           }}
                                                                           onChange={(event) => {
                                                                               this.inputSearchHandler(event, item, Name)
                                                                           }}
                                                                           value={inputValue ? item.Value.ApproximatelyEqual : ""}
                                                                           onKeyDown={(event) => {
                                                                               if (event.code === "Enter" || event.code === "NumpadEnter") {
                                                                                   this.search({})
                                                                               }
                                                                           }}
                                                                    />
                                                                    <div className="input-group-prepend">
                                                                        <span onClick={(event) => {
                                                                            this.search({event: event, item: item})
                                                                        }} className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                                        >
                                                                            <i className={"svg-icon svg-icon-lg " + item.DirectionUp}/>
                                                                        </span>
                                                                        <span onClick={(event) => {
                                                                            this.search({event: event, item: item})
                                                                        }} className="input-group-text-light-append line-height-0 py-0 svg-icon svg-icon-lg"
                                                                        >
                                                                            <i className={"svg-icon svg-icon-lg " + item.DirectionDown}/>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    this.props.isExtSearch ?
                                                                        <div className="d-flex mt-4">
                                                                            <div className="col-lg-6">
                                                                                <a href="#"
                                                                                   className="btn btn-outline-secondary font-weight-bold"
                                                                                   style={{
                                                                                       color: 'var(--originaltwo)',
                                                                                       backgroundColor: 'var(--elem-bg)',
                                                                                       borderColor: '#ffffff'
                                                                                   }}
                                                                                   onClick={(ev) => {this.setExtFilters(ev, item)}}>
                                                                                    Настроить
                                                                                </a>
                                                                            </div>
                                                                            <div className="col-lg-7"
                                                                                 style={{
                                                                                     display: 'flex',
                                                                                     alignItems: 'center',
                                                                                     alignContent: 'center',
                                                                                     flexWrap: 'wrap',
                                                                                     justifyContent: 'center',
                                                                                     flexDirection: 'row'
                                                                                 }}>
                                                                                <p style={{marginBottom: 0}}>
                                                                                    Применено: {fillingFieldsCount}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                            {/*
                                                             {index !== this.props.searchInputs.length - 1 &&
                                                                <div className="separator separator-solid my-4"/>
                                                            }
                                                            */}
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </div>
                                        </>
                                        :
                                        currentFilter && <>
                                            {(currentFilter.IdAttributeType === DataTypesEnum.String
                                                    || currentFilter.IdAttributeType === DataTypesEnum.RowBlob
                                                    || currentFilter.IdAttributeType === DataTypesEnum.SearchBlob
                                                ) &&
                                                <FiltersString onApply={() => this.setState({extFilter: false})}
                                                               filterInfo={currentFilter}/>}
                                            {currentFilter.IdAttributeType === DataTypesEnum.DataTime &&
                                                <FiltersDate onApply={() => this.setState({extFilter: false})}
                                                             filterInfo={currentFilter}/>}
                                            {(currentFilter.IdAttributeType === DataTypesEnum.Integer || currentFilter.IdAttributeType === DataTypesEnum.Float) &&
                                                <FiltersNumber onApply={() => this.setState({extFilter: false})}
                                                               filterInfo={currentFilter}/>}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        allAttribute: state.document.mainTable.header,
        changeViewModeSearch:  state.document.rootState.viewModeSearch,
        router: state.router,
        searchInputs : state.document.search.searchInputs,
        isExtSearch: state.document.search.isExtSearch
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    changeDocumentSearchInputs,
    changeDocumentViewMode,
    combineMainTableAttributes,
    mainTableDownloadDocCollection,
    mainTableSearchDoc,
    selectActiveRow,
    setLoaderModalData,
    setModalData,
    handlerDocumentIsExtSearch,
}

export default connect(mapStateToProps,mapDispatchToProps)(InputSearchDoc);
