import React from "react";
import {connect} from "react-redux";
import {
    breadcrumbsMoveSelect,
    SelectActivePackageDocument, SelectActivePackageNode, togglePackagesFolders
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {Routing} from "../../../tools/routing/Routing";
import {Link} from "react-router-dom";
import {setViewModePreviewPackages} from "../../../store/packages/actionCreators/package_MainTableActionCreator";
import {setLoaderModalData, setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {updateInputsFieldPackage} from "../../../store/packages/actionCreators/package_InputActionCreator";
import {store} from "../../../index";
import {TableTypesEnum} from "../../../tools/StaticTypes";

class PackagesBreadcrumbs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }

        this.moveSelect = this.moveSelect.bind(this);
    }


    async moveSelect(NodeId) {
        if (this.props.tableRole === TableTypesEnum.Download) {
            //Новый прелоадер
            this.props.setLoaderModalData({
                data: {
                    content: "Загрузка...",
                    disableButton: true,
                    fullBackground: true,
                    gif: "packages",
                    key: "PackagesBreadcrumbs49"
                },
                Next: {
                    action: async () => {
                        await this.props.breadcrumbsMoveSelect({
                            NodeId,
                            TableType: this.props.tableRole,
                            IsDownload: true,
                            Next: {
                                action: () => {
                                    this.props.SelectActivePackageDocument({
                                        TableType: this.props.tableRole,
                                        Next: {
                                            action: async () => {
                                                for (const crumb of this.props.breadcrumbsDownload) {
                                                    // await this.props.PackageAddNodesTree({
                                                    //     Id: crumb.NodeId,
                                                    //     IsDownload: true
                                                    // })
                                                    // await this.props.SelectActivePackageNode({
                                                    //     Id: crumb.NodeId,
                                                    //     IsDownload: true,
                                                    // });
                                                    //
                                                    if (!store.getState().packages.treeDownload.openedFolders.includes(crumb.NodeId)) {
                                                        this.props.togglePackagesFolders({
                                                            IdFolder: crumb.NodeId,
                                                            TableType: this.props.tableRole,
                                                        });
                                                    }
                                                    // if (NodeId === crumb.NodeId) {
                                                    //     break
                                                    // }
                                                }
                                                this.props.setLoaderModalData({keyDeleted: "PackagesBreadcrumbs69"});
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    }
                }
            });
        } else {
            let breadcrumbs = this.props.tableRole === TableTypesEnum.Main
                ? this.props.breadcrumbs
                : this.props.breadcrumbsAdditional
            let openedFolders = this.props.tableRole === TableTypesEnum.Main
                ? this.props.openedFolders
                : this.props.openedFoldersAdditional

            // TODO КН 26.04.24 Мб стоит просто вызвать this.props.updateInputsFieldPackage({TableRole: this.props.tableRole})

            //Новый прелоадер
            this.props.setLoaderModalData({
                data: {content: "Загрузка...", disableButton: true, fullBackground: true, gif: "packages", key: "PackagesBreadcrumbs125"},
                Next: {
                    // action: async () => {
                            /* TODO КН 23.01.24 Прим.: Здесь сетался tree.render. Похоже это делалось для обновления
                                данных об узле, но фишка в том, что дерево обновлялось до более раннего узла
                                 и следующие узлы уже не существовали для стрелочек брэдкрамбсов */
                    //     this.props.PackageAddNodesTree({
                    //         Id: NodeId,
                    //         TableType: this.props.tableRole,
                    //         Next: {
                                action: async () => {
                                    // TODO КН 23.01.24 Прим.: Здесь внутри сетается tree.activeNode
                                    await this.props.breadcrumbsMoveSelect({
                                        NodeId,
                                        TableType: this.props.tableRole,
                                        Next: {
                                            action: () => {
                                                const condition = this.props.viewModePreview == "none" || this.props.viewModePreview == "normal"
                                                this.props.setViewModePreviewPackages({
                                                    ViewMode: condition ? "none" : this.props.viewModePreview,
                                                    Next: {
                                                        action: () => {
                                                            // TODO КН 23.01.24 Прим.: Здесь внутри tree.activeDocument сетается в null
                                                            this.props.SelectActivePackageDocument({
                                                                TableType: this.props.tableRole,
                                                                Next: {
                                                                    action: async () => {
                                                                        for (const crumb of (condition ? this.props.breadcrumbs : breadcrumbs)) {
                                                                            // TODO КН 23.01.24 Прим.: Здесь сетался tree.render, скорее всего для нижней SelectActivePackageNode, в которой использовалось это поле для построения дерева
                                                                            // await this.props.PackageAddNodesTree({
                                                                            //     Id: crumb.NodeId,
                                                                            //     TableType: this.props.tableRole,
                                                                            // })
                                                                            //
                                                                            // TODO КН 23.01.24 Прим.: Здесь сетался tree.activeNode; именно эта функция вызывалась внутри breadcrumbsMoveSelect
                                                                            // NodeId === crumb.NodeId && await this.props.SelectActivePackageNode({
                                                                            //     Id: crumb.NodeId,
                                                                            //     TableType: this.props.tableRole,
                                                                            // });
                                                                            //
                                                                            // TODO КН 23.01.24 Прим.: Возможно стоит вернуть break по условию NodeId === crumb.NodeId для этого действия, но вроде все и так норм работает
                                                                            if (!openedFolders.includes(crumb.NodeId)) {
                                                                                this.props.togglePackagesFolders({
                                                                                    IdFolder: crumb.NodeId,
                                                                                    TableType: this.props.tableRole,
                                                                                });
                                                                            }
                                                                        }
                                                                        this.props.setLoaderModalData({keyDeleted: "PackagesBreadcrumbs177"});
                                                                    }
                                                                }
                                                            });
                                                        }
                                                    }
                                                });
                                            }
                                        }
                                    });
                                }
                    //         }
                    //     })
                    // }
                }
            });
        }
    }

    render() {
        let breadcrumbs;
        if(this.props.tableRole === "main") breadcrumbs = this.props.breadcrumbs;
        else if(this.props.tableRole === "additional") breadcrumbs = this.props.breadcrumbsAdditional;
        else if(this.props.tableRole === "download") breadcrumbs = this.props.breadcrumbsDownload;

        return (
            <div className="ml-8 beautiful-scroll-5" style={{height : "57px", overflowY:"auto"}}>
                {/*<div className="ml-8 beautiful-scroll-5" >*/}
                <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold mt-4 mb-1 p-0 d-flex align-items-baseline " style={{whiteSpace : "nowrap",width:"5000px"}}>
                    {/*<ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold mt-4 mb-1 p-0 d-flex align-items-baseline ">*/}
                    {
                        breadcrumbs.length > 0 &&
                        breadcrumbs.map((item, index)=>{


                            let query = this.props.router.location.query;
                            let queryLink = "";

                            if(this.props.tableRole === "main") {
                                queryLink = Routing.CreateRef({
                                    oldQuery : query,
                                    newQuery : {p : item.NodeId},
                                    excludedKeys : ["d"]
                                });
                            }
                            else if(this.props.tableRole === "additional") {
                                queryLink = Routing.CreateRef({
                                    oldQuery : query,
                                    newQuery : {p2 : item.NodeId},
                                    excludedKeys : ["d2"]
                                });
                            }
                            else if(this.props.tableRole === "download") {
                                queryLink = Routing.CreateRef({
                                    oldQuery : query,
                                    newQuery : {dwp : item.NodeId},
                                    excludedKeys : ["dwd", "p2", "d2", "dd2", "dt2", "dg2"]
                                });
                            }

                            let link = `${this.props.router.location.pathname}${queryLink}`;
                            return (
                                <Link to={link} key={item.NodeId} className="breadcrumb-item" onClick={() => {this.moveSelect(item.NodeId)}}>
                                        <span className={`text-color-primary ${item.isCurrent ? "font-weight-bolder" : ""}`} title={item.Name}>
                                            {item.isCurrent ? <u>{item.Name}</u> : item.Name}
                                        </span>
                                </Link>
                            );
                            // if(this.props.tableRole === "download"){
                            //     if(item.isCurrent) {
                            //         return (
                            //             <Link to={link} key={item.NodeId} className="breadcrumb-item" onClick={()=>{this.moveSelect(item.NodeId)}}>
                            //                 <span className="text-color-primary font-weight-bolder" title={item.Name} ><u>{item.Name}</u></span>
                            //             </Link>
                            //         );
                            //     } else {
                            //         return (
                            //             <Link to={link} key={item.NodeId} className="breadcrumb-item" onClick={()=>{this.moveSelect(item.NodeId)}}>
                            //                 <span className="text-color-primary" title={item.Name}>{item.Name}</span>
                            //             </Link>
                            //         );
                            //     }
                            // }else{
                            //     if(item.isCurrent) {
                            //         return (
                            //             <Link to={link} key={item.NodeId} className="breadcrumb-item text-muted" onClick={()=>{this.moveSelect(item.NodeId)}}>
                            //                 <span style={{color : "#204BA0"}} title={item.Name} >{HelpFunctions.cutLongString(item.Name, 8, true)}</span>
                            //             </Link>
                            //         );
                            //     } else {
                            //         return (
                            //             <Link to={link} key={item.NodeId} className="breadcrumb-item text-muted" onClick={()=>{this.moveSelect(item.NodeId)}}>
                            //                 <span className="text-muted" title={item.Name}>{HelpFunctions.cutLongString(item.Name, 8, true)}</span>
                            //             </Link>
                            //         );
                            //     }
                            // }

                        })
                    }
                </ul>
            </div>
        );
    }
}


const  mapStateToProps = state => {
    return {
        breadcrumbs : state.packages.tree.breadcrumbs,
        breadcrumbsAdditional : state.packages.treeAdditional.breadcrumbs,
        breadcrumbsDownload : state.packages.treeDownload.breadcrumbs,
        openedFolders : state.packages.tree.openedFolders,
        openedFoldersAdditional : state.packages.treeAdditional.openedFolders,
        openedFoldersDownload : state.packages.treeDownload.openedFolders,
        router : state.router,
        viewModePreview : state.packages.rootState.viewModePreview,
    }
}

const  mapDispatchToProps = {
    breadcrumbsMoveSelect,
    togglePackagesFolders,
    SelectActivePackageDocument,
    SelectActivePackageNode,
    setViewModePreviewPackages,
    setLoaderModalData,
    setModalData,
    updateInputsFieldPackage
}



export default connect(mapStateToProps, mapDispatchToProps)(PackagesBreadcrumbs);
