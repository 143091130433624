import React from "react";
import {connect} from "react-redux";
import {setLoaderModalData,setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import Search from "../../messenger/Search"
import {MessageTypesEnum, UserSettingsActionsTypes, UserSettingsNames} from "../../../tools/StaticTypes";
import {getActiveDoc} from "../../../store/documents/actionCreators/document_MainTableActionCreator";
import {updateUserSettings} from "../../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import "../../../media/styles/messenger.css"
import {ModalTypes} from '../../../tools/StaticTypes'
import {makeReceiverActive, setReadMsgs} from '../../../store/messenger/actionCreators/messenger_ActionCreator'
class ShareModal extends React.Component{
    constructor(props) {
        super(props);

        this.state={
            contentOnHover : false,
            text:'',
            packageId:null,
            actvPack:this.props.actvPackInfo
        }
        this.hide = this.hide.bind(this);
        this.hideOutSide = this.hideOutSide.bind(this);
        this.sendMessage=this.sendMessage.bind(this)
        this.findHref=this.findHref.bind(this)
        this.getAttachedData=this.getAttachedData.bind(this)
        this.getPackageAttchdData=this.getPackageAttchdData.bind(this)
        this.showLoader=this.showLoader.bind(this)
    }

    componentDidMount() {
        this.props.makeReceiverActive({Id:'',Name:''})
    }

    hide() {
        this.props.setModalData({});
    }

    hideOutSide() { // скрывать при клике вне окна
        if(!this.state.contentOnHover) {
            this.props.setModalData({});
        }
    }
    showLoader(){
        this.props.setModalData({
            name:ModalTypes.messenger.sendMsgLoader,
            data:{}
        })
    }

    async getPackageAttchdData(){

        return{
            Id:this.props.actvPackInfo.Id,
            Name:this.props.actvPackInfo.Name,
            PathName:"/packages",
            FilialId:this.props.actvPackInfo.IdFilial,
        }
    }
    async getAttachedData(){
        await this.props.getActiveDoc({
            Id: Number(this.props.routeLocation.query.d)
        })

        return {
            Name:this.props.actvDocInfo.Name,
            ShortDescription:this.props.actvDocInfo.ShortDescription,
            Filial:this.props.actvDocInfo.IdFilial,
            Id:this.props.actvDocInfo.Id,
            Search:this.props.routeLocation.search,
            PathName:"/documents"
        }
    }

    getHrefType
    async sendMessage(){
        this.props.setLoaderModalData({
            data: {
                content: "Отправка...",
                disableButton: true,
                fullBackground: false,
                gif: "letter",
                key: "ShareModal 75"
            }
        })

        let href=this.findHref()
        let attchdData='';
        if(this.props.routeLocation.pathname==='/packages'){
            attchdData=await this.getPackageAttchdData()
        }else{
            attchdData= await this.getAttachedData()
        }
        let messageTxt=this.state.text

        if (href){
            if(href.includes('/documents?')){
                let startIndex=href.indexOf("d=")
                let docId=''
                for (let i = startIndex+2; i <href.length ; i++) {
                    docId+=href[i]
                }
                let searchIndex=href.indexOf("?g")
                let search=''
                for (let i = searchIndex; i < href.length; i++) {
                    search+=href[i]
                }
                docId=Number(docId)
                attchdData= await this.getAttachedData()

                let startIndexHref=messageTxt.indexOf(href)
                let hrefToDelete=messageTxt.substring(startIndexHref,startIndexHref+href.length+1)
                messageTxt=messageTxt.replace(hrefToDelete,'')

            }else if(href.includes('/packages?')){
                let startIndex=href.indexOf("p=")
                let pacId=''
                for (let i = startIndex+2; i <href.length ; i++) {
                    pacId+=href[i]
                }
                pacId=Number(pacId)
                this.setState({packageId:pacId})
                attchdData=await this.getPackageAttchdData()
            }
            else{
                messageTxt = messageTxt.replace(href, `<a href=${href} target='_blank'>${href}</a>`)
            }
        }
        let nameArr=this.props.activeReceiver.Name.toUpperCase().split(' ')
        let name='messenger'+nameArr.join('_')
        let msgSettings=this.props.userSettings?.filter(item=>item.Name.includes('messenger'))
        let curReceiverSetting=msgSettings?.find(item=>item.Value[0].Colleague.Id===this.props.activeReceiver.Id)


        this.props.updateUserSettings({
            Name: UserSettingsNames.MESSENGER,
            Action: UserSettingsActionsTypes.Update,
            Data: {
                Message: messageTxt??'',
                MessageType: MessageTypesEnum.General,
                Date: new Date(),
                AttachedData: attchdData,
                SettingName:name,
            },
            Next:{
                action:()=>{
                    this.props.setReadMsgs({
                        User:this.props.activeReceiver,
                    })
                }
            }
        })
        this.hide();
        this.props.setLoaderModalData({keyDeleted: "InputSearchPackage345"});

    }

    findHref(){
        let msg=this.state.text
        let href='';
        let startIndexOfHref

        if(msg.includes('http://')||msg.includes('https://')){
            startIndexOfHref=msg?.indexOf('http')
            for (let i = startIndexOfHref; i < msg.length; i++) {
                //console.log("msg[i]",msg[i])
                if (msg[i] !== ' ') {
                    href += msg[i]
                }
                else
                    break;
            }
        }

        if(href.length>0){
            return href
        }
        else{
            return null
        }
    }
    render(){
        let modalStyle = {
            display : "block",
            backgroundColor: "#061c3e66",
            zIndex : "99998",
        };

        return(
            <div className="modal fade show" style={modalStyle} onClick={this.hideOutSide}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm"
                     onMouseEnter={() => {this.setState({ contentOnHover : true})}}
                     onMouseLeave={() => {this.setState({ contentOnHover : false})}}>
                    <div className="modal-content" style={{ width: '600px',
                        height:'400px',}}>
                        <div className="modal-header"
                             style={{borderBottom:"none",
                                 justifyContent:"center",
                                 background:'var(--originaltwo)',
                             }}>
                            <h5 className="modal-title" style={{ color:'white',float:'left',width:'100%'}}>Поделиться</h5>
                            <div onClick={this.hide}>
                                <i className="bi bi-x msg-close" style={{}}></i>
                            </div>
                        </div>
                        <div className="modal-content-msg"
                             style={{display:'flex',
                                 margin:"10px 20px 2px 20px",
                                 flexDirection:'column',
                                 }}>
                            <Search/>
                            <div className="bg-white mb-3" style={{margin:'90px 0px 0px 0px',width:'100%'}}>
                                <div className="form-outline">
                                    <label className="form-label" style={{flexGrow:'1'}} >Ваше сообщение</label>
                                    <textarea className="form-control" id="textAreaExample2" rows="4" style={{flexGrow:'1'}}
                                              onChange={(e) => this.setState({text: e.target.value})}
                                              value={this.state.text}
                                    >
                                    </textarea>
                                </div>
                            </div>
                            <div className="footer-msg" style={{width:'100%',marginTop:'10px'}}>
                                <div className="btn btn-primary" style={{float:'right',display:this.props.activeReceiver ?'flex':'none'}} onClick={this.sendMessage}>Отправить</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}

const  mapStateToProps = state => {
    return {
        routeLocation:state.router.location,
        actvDocInfo:state.document.mainTable.activeDocInfo,
        activeReceiver:state.messenger.message.activeReceiver,
        actvPackInfo:state.packages.mainTable.rightClickPackage.Node,
        userSettings:state.globalState.userSettings

    }
}

const  mapDispatchToProps = {
    setLoaderModalData,
    setModalData,
    getActiveDoc,
    updateUserSettings,
    makeReceiverActive,
    setReadMsgs,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareModal);
