import produce from "immer";

import { ActionTypes } from "./masterAppActionList";

const masterApp = {
    OtherFiles: [],
    OriginalFile: null,
    PreviewFile: null,
    isShowBanner: true,
    ActiveIdFilial: null,
    ActiveType: null,
    SelectedGroups: [],
    typeValue: {
        value: null,
        label: "Не выбрано",
        isDisabled: true
    },
    LoadGroups: [],
    LoadTypes: [],
    DocName: "",
    RegNumber: "",
    RegDate: "",
    ActiveAttributes: [],
    disableBtnToDocument: true,
    Comment: "WebMaster 1.0",
    ContentText: "",
    VersionIdForLocate: null,
};

const InitialState = masterApp;

export const masterAppReducer = (state = InitialState, action) => {

    return produce(state, newState => {
        if (action.type === ActionTypes.SET_OTHER_FILES) {
            newState.OtherFiles = action.payload;
        } else if (action.type === ActionTypes.SET_ORIGINAL_FILE) {
            newState.OriginalFile = action.payload;
        } else if (action.type === ActionTypes.SET_SHOW_BANNER) {
            newState.isShowBanner = action.payload;
        } else if (action.type === ActionTypes.SET_ACTIVE_ID_FILIAL) {
            newState.ActiveIdFilial = action.payload;
        } else if (action.type === ActionTypes.SET_ACTIVE_TYPE) {
            newState.ActiveType = action.payload;
        } else if (action.type === ActionTypes.SET_SELECTED_GROUPS) {
            newState.SelectedGroups = action.payload;
        } else if (action.type === ActionTypes.SET_TYPE_VALUE) {
            newState.typeValue = action.payload;
        } else if (action.type === ActionTypes.SET_LOAD_TYPES) {
            newState.LoadTypes = action.payload;
        } else if (action.type === ActionTypes.SET_LOAD_GROUPS) {
            newState.LoadGroups = action.payload;
        } else if (action.type === ActionTypes.SET_DOC_NAME) {
            newState.DocName = action.payload;
        } else if (action.type === ActionTypes.SET_REG_NUMBER) {
            newState.RegNumber = action.payload;
        } else if (action.type === ActionTypes.SET_REG_DATE) {
            newState.RegDate = action.payload;
        } else if (action.type === ActionTypes.SET_ACTIVE_ATTRIBUTES) {
            newState.ActiveAttributes = action.payload;
        } else if (action.type === ActionTypes.SET_PREVIEW_FILE) {
            newState.PreviewFile = action.payload;
        } else if (action.type === ActionTypes.SET_DISABLE_BTN_TO_DOCUMENT) {
            newState.disableBtnToDocument = action.payload;
        } else if (action.type === ActionTypes.SET_COMMENT) {
            newState.Comment = action.payload;
        } else if (action.type === ActionTypes.SET_CONTENT_TEXT) {
            newState.ContentText = action.payload;
        } else if (action.type === ActionTypes.SET_VERSION_ID_FOR_LOCATE) {
            newState.VersionIdForLocate = action.payload;
        }
    })
};
