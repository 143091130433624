import React from "react";
import {connect} from "react-redux";
import {createUniqueIdString} from "../../tools/CreateUniqueId";
import {__, HelpFunctions} from "../../tools/HelpFunctions";
import {
    deleteFilesRedactorData,
    deleteNewAttachmentFilesRedactorData,
    SetMainFileRedactorData,
    toggleAdditionalSectionsOpen,
    toggleAdditionalSectionsOpen_Cards,
    updateFilesNameOrDescriptionRedactorData,
    selectActiveRow_filesSection,
    showSignatureForFile,
    deleteSignature,
    updateSignature,
    createDocumentCards,
    selectActiveDocumentCard,
    deleteDocumentCard,
    collectDataForAdditionalSections_Normal,
    createPreviewBySelectDocument
} from "../../store/documents/actionCreators/document_PreviewActionCreator";
import {AdminLevelEnum, attributesSectionsTypes, ModalTypes} from "../../tools/StaticTypes";
import {addToEndUrl, apiUrl} from "../../tools/API/apiUrl";
import {API} from "../../tools/API_NEW/API";
import {store} from "../../index";
import {EntityFilters, Filter, FiltersType} from "../../tools/Filters";
import {setLoaderModalData, setModalData} from "../../store/globalState/actionCreators/globalState_AppActionCreator";


class FilesSection extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            lFile : null,
            lFileError: false,
            UpdateCounter: 0,
            // uploadFileSignatureShow: false,
            // editFileSignatureShow: false,
            // deleteFileSignatureShow: false,
        };

        this.sectionName = attributesSectionsTypes.filesSection;
        // this.originalFileRef = React.createRef();
        // this.uploadFileSignatureRef = React.createRef();
        // this.editFileSignatureRef = React.createRef();
        // this.deleteFileSignatureRef = React.createRef();

        this.toggle = this.toggle.bind(this);
        this.updateName = this.updateName.bind(this);
        this.updateDescription = this.updateDescription.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.deleteNewFile = this.deleteNewFile.bind(this);
        this.changeRadioHandler = this.changeRadioHandler.bind(this);
        this.selectFile = this.selectFile.bind(this);
        this.deleteSignature = this.deleteSignature.bind(this);
        this.deleteSign = this.deleteSign.bind(this);
        this.showSignatureForFile = this.showSignatureForFile.bind(this);
        this.updateSignatures = this.updateSignatures.bind(this);
        this.signUpdateRef = React.createRef();
        this.conditionRef = React.createRef();
    }

    async showSignatureForFile(RowInfo) {
        let lParam = RowInfo;
        if (this.props.viewMode == "card") {
            lParam = {...RowInfo, activeCardId: this.props.cards.activeCardId}
        }

        await this.props.showSignatureForFile({RowInfo: lParam});
        // if (this.props.typeSection == "packages")
        if (this.props.typeSection)
            this.setState({UpdateCounter: this.state.UpdateCounter + 1});
    }


    componentDidMount() {
        //TODO a Задача № 22807 если раздел файлы открыт, то фиктивно нажимаем кнопку
        if(this.props.viewMode === "normal") {
            if (this.props.additionalSectionsData.state[this.sectionName].isOpen)
                // this.toggle(this.props.additionalSectionsData.state[this.sectionName])
                this.updateSignatures(this.props.additionalSectionsData.state[this.sectionName])
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.typeSection == "packages") {
            if (this.props.activeDocument?.additionalSections?.filesSection && this.conditionRef.current == "finish") {

                const sectionData = {};
                sectionData.files = this.props.activeDocument?.additionalSections.filesSection?.files.Records;

                if (prevProps.activeDocument?.TreeId != this.props.activeDocument?.TreeId || !sectionData?.files[0].signatures)
                    this.signUpdateRef.current = null;
                if (!this.signUpdateRef.current || !this.signUpdateRef.current[sectionData?.files[0].Id]) {
                    this.updateSignatures(sectionData)
                }
            }

        } else if (this.props.typeSection == "search") {
            const {activeTabSearch, searchResults} = this.props;
            const additionalSectionsData = searchResults.find(item => item.Id === activeTabSearch.Id)?.additionalSections;
            if (additionalSectionsData.filesSection && this.conditionRef.current == "finish") {
                const sectionData = {};
                sectionData.files = additionalSectionsData.filesSection?.files;

                if (!this.signUpdateRef.current || !this.signUpdateRef.current[sectionData?.files[0].Id]) {
                    this.updateSignatures(sectionData)
                }
            }
        } else {
            if (!this.props.cards.activeCardId) {
                //Только при открытом узле Файлы
                if (this.props.additionalSectionsData.state[this.sectionName].isOpen)
                    if ((!this.signUpdateRef.current || !this.signUpdateRef.current[this.props.additionalSectionsData.state[this.sectionName].files[0]?.id]) && (this.conditionRef.current == "finish" || !this.conditionRef.current)) {

                        let sectionData;
                        if (this.props.viewMode === "normal") {
                            sectionData = this.props.additionalSectionsData.state[this.sectionName];
                            this.updateSignatures(sectionData)
                        }
                    }
                if (this.signUpdateRef.current && this.signUpdateRef.current[this.props.additionalSectionsData.state[this.sectionName].files[0]?.id] && !this.props.additionalSectionsData.state[this.sectionName].files[0].signatures)
                {
                    let sectionData;
                    if (this.props.viewMode === "normal") {
                        sectionData = this.props.additionalSectionsData.state[this.sectionName];
                        this.updateSignatures(sectionData)
                    }
                }
            }
        }

    }

    async updateSignatures(sectionData) {
        //TODO Задача № 22790 добавлен цикл
        if (this.props.cards.activeCardId) {
            for (let i in sectionData.files) {
                if (sectionData.files[i].id) {
                    let lParam = {idDocFile: sectionData.files[i].id, activeCardId: this.props.cards.activeCardId}
                    this.props.updateSignature({
                        RowInfo: lParam
                    });
                }
            }
        } else {
            let lArrSignLoaded = {};
            this.conditionRef.current = "start";

            for (let i in sectionData.files) {
                const lIdFile = sectionData.files[i].id ?? sectionData.files[i].Id;
                if (lIdFile) {
                    lArrSignLoaded[lIdFile] = true;
                    await this.props.updateSignature({
                        RowInfo: {idDocFile: lIdFile}
                    });
                }
                if (i == sectionData.files.length-1)
                    this.conditionRef.current = "finish";
            }
            if (sectionData.files.length == 0)
                this.conditionRef.current = "finish";

            this.signUpdateRef.current = lArrSignLoaded;

            if (this.props.typeSection)
                this.setState({UpdateCounter: this.state.UpdateCounter + 1})
            else if (this.conditionRef.current == "finish")
                //Иначе не отрисовываются подписи
                this.setState({UpdateCounter: this.state.UpdateCounter + 1})
        }
    }

    // открытье\закрытие раздела
    async toggle(sectionData) {

        //TODO дубль загрузки подписей
        let lIsClose = true;

        // if (this.props.typeSection == "packages") {
        if (this.props.typeSection) {
            // lIsClose = this.props.activeDocument?.additionalSections.filesSection.isOpen;
            lIsClose = this.state.isOpen;
        } else {
            if (this.props.viewMode === "normal") {
                lIsClose = this.props.additionalSectionsData.state[this.sectionName].isOpen;
            } else if (this.props.viewMode === "card") {
                const card = this.props.cards.collection.find(card => card.Id === this.props.cards.activeCardId)
                lIsClose = card.additionalSections[this.sectionName].isOpen;
            }
        }

        if (!lIsClose) {
            await this.updateSignatures(sectionData);
        }

        // if (this.props.activeDocument?.additionalSections.filesSection) {
        if (this.props.typeSection) {
            this.setState({
                isOpen: !this.state.isOpen
            });
        } else {
            if (this.props.viewMode === "normal") {
                this.props.toggleAdditionalSectionsOpen({
                    Name: this.sectionName
                });
            } else if (this.props.viewMode === "card") {
                this.props.toggleAdditionalSectionsOpen_Cards({
                    Name: this.sectionName
                });
            }
        }
        if (this.props.typeSection)
            this.setState({UpdateCounter: this.state.UpdateCounter + 1});
    }

    updateName(event, item) {
        this.props.updateFilesNameOrDescriptionRedactorData({
            idFile : item.id,
            type : "name",
            value : event.target.value
        });
    }


    updateDescription(event, item) {
        this.props.updateFilesNameOrDescriptionRedactorData({
            idFile : item.id,
            type : "description",
            value : event.target.value
        });
    }

    // пометить файл на удаление или снять с удаления
    async deleteFile(item) {
        const { adminLevel, cards} = this.props;
        const isCommonUser = adminLevel === AdminLevelEnum.CommonUser;
        const card = cards.collection.find(card => card.Id === cards.activeCardId);
        const mainFile = card?.additionalSections[this.sectionName].files?.find(file => file.AttributeName.Id === 3);
        let idMainFile = isCommonUser ? mainFile.id : cards.redactorData
            .additionalSections[this.sectionName].idMainFile;

        if (item.id !== idMainFile) {
            if(isCommonUser) {
                this.props.setLoaderModalData({
                    data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "documents", key : "FilesSection250" }
                });
                await API.documents().deleteDocFile({id: item.id});
                await this.props.createDocumentCards({
                    IdDoc: card?.IdDoc,
                    IdVersion: card.IdLastVersion,
                    IsRedactorMode: true,
                    isUpdate: true,
                    Next: {
                        action: async () => {
                            await this.props.createPreviewBySelectDocument({});
                            await this.props.collectDataForAdditionalSections_Normal({
                                Next: {
                                    action: async () => {
                                        await this.props.selectActiveDocumentCard({
                                            CardId: store.getState().document.cards.collection.at(-1).Id
                                        });

                                        await this.props.deleteDocumentCard({CardId: card.Id});
                                        await this.updateSignatures({...card.additionalSections[this.sectionName]});

                                        this.props.setLoaderModalData({keyDeleted: "FilesSection267"});
                                    }
                                }});

                        }
                    }
                });
            } else {
                await this.props.deleteFilesRedactorData({
                    fileInfo: item
                });
            }
        }
        if (this.props.typeSection)
            this.setState({UpdateCounter: this.state.UpdateCounter + 1});
    }

    // удаление новых прикрепленных файлов
    deleteNewFile(event, item) {
        this.props.deleteNewAttachmentFilesRedactorData({
            fileInfo : item
        });
    }

    changeRadioHandler(event, item, isUpdated = false) {

        this.props.SetMainFileRedactorData({
            fileId : item.id,
            isUpdated : isUpdated
        });
    }

    // выбор файлов
    async selectFiles(event, lIdDocFile) {
        //Новый прелоадер
        this.props.setLoaderModalData({
            data : {content : "Загрузка ЭП...", disableButton : true, fullBackground : false, gif : "documents", key: "FilesSection262"}
        })
        this.setState({lFile : event.target.files[0], lFileError: event.target.files.length <= 0});

        let lData = new FormData();
        lData.append("description","Тест подписи");

        let files = event.target.files[0];
        files.id = createUniqueIdString(8);
        files.description = "Тест подписи";
        files.new_name = event.target.files[0].name;
        lData.append("signature", files, event.target.files[0].name);
        let requestFile = await API.signatures().attach({
            idDocFile: lIdDocFile,
            FormData: lData,
        });

        //TODO электронная подпись Запрос
        await API.signatures().load({
            filter : new Filter(FiltersType.ENTITY).add(
                new EntityFilters().setEntityName("DocFileSignature").add({
                    Name : "IdDocFile",
                    Value : lIdDocFile
                }).get()
            ).get(),
            sorting : null,
        });

        let lParam = {idDocFile: lIdDocFile};
        if (this.props.viewMode == "card") {
            lParam = {idDocFile: lIdDocFile, activeCardId: this.props.cards.activeCardId}
        }

        await this.props.updateSignature({
            RowInfo : lParam,
        });
        if (this.props.typeSection)
            this.setState({UpdateCounter: this.state.UpdateCounter + 1});
        // this.props.setModalData({});
        //Новый прелоадер
        this.props.setLoaderModalData({keyDeleted: "FileSection301"});
        if (requestFile?.errorCode) {
            this.props.setModalData({
                name : ModalTypes.app.info,
                data : {content : "Ошибка загрузки файла ЭП", type : "fail"}
            });
        } else {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type : "success", content : `Файл ЭП загружен!`}
            });
        }
    }

    // выбор файлов
    async updateSignature(event, lSignature) {
        this.setState({lFile : event.target.files[0], lFileError: event.target.files.length <= 0});

        //Новый прелоадер
        this.props.setLoaderModalData({
            data : {content : "Обновление ЭП...", disableButton : true, fullBackground : false, gif : "documents", key: "FilesSection311"}
        })
        let lData = new FormData();
        lData.append("description","Тест подписи");

        let files = event.target.files[0];
        files.id = createUniqueIdString(8);
        files.description = "Тест подписи";
        files.new_name = event.target.files[0].name;

        lData.append("signature", files, event.target.files[0].name);

        //TODO v1 new
        let response = await API.signatures().update({
            idSignature : lSignature.Id,
            FormData : lData
        });
        //TODO v2 old
        // let response = await new apiRequestNew({
        //     action : apiUrl.DOCUMENTS.UPDATE_SIG_FILE_FOR_CURRENT_FILE_DOC,
        //     endUrl : lSignature.SignatureFile.Id,
        //     headers : null,
        //     params : lData
        // }).isFileUpload(true).execute();

        let lParam = lSignature;
        if (this.props.viewMode == "card") {
            lParam = {...lSignature, activeCardId: this.props.cards.activeCardId}
        }
        this.props.updateSignature({
            RowInfo : lParam,
        });
        // this.props.setModalData({
        // })
        //Новый прелоадер
        this.props.setLoaderModalData({keyDeleted: "FilesSection346"});
        if (response?.errorCode) {
            this.props.setModalData({
                name : ModalTypes.app.info,
                data : {content : "Ошибка обновления файла ЭП", type : "fail"}
            });
        } else {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {type : "success", content : `Файл ЭП обновлен!`}
            });
        }
    }

    selectFile(event, item) {
        // this.props.selectActiveRow_filesSection({
        //     RowInfo : item,
        // });
        //     TODO: MS 01.03
    }

    async deleteSign(item) {
        //TODO электронная подпись Запрос
        //Новый прелоадер
        this.props.setLoaderModalData({
            data : {content : "Удаление ЭП...", disableButton : true, fullBackground : false, gif : "documents", key: "FileSection357"}
        })
        await API.signatures().delete({
            "id": item.Id
        });

        let lParam = item;
        if (this.props.viewMode == "card") {
            lParam = {...item, activeCardId: this.props.cards.activeCardId}
        }
        await this.props.deleteSignature({
            RowInfo : lParam,
        });
        if (this.props.typeSection)
            this.setState({UpdateCounter: this.state.UpdateCounter + 1});
        // this.props.setModalData({});
        //Новый прелоадер
        this.props.setLoaderModalData({keyDeleted: "FileSection372"});
        //Закрытие модалки подтверждения удаления
        this.props.setModalData({});
    }

    async deleteSignature(event, item) {
        // if (!window.confirm("Удалить файл подписи "+item.SignatureFileName+"?"))
        //     return;

        this.props.setModalData({
            name : ModalTypes.app.info,
            data : {
                type: "question",
                content: `Удалить файл подписи ${item.SignatureFileName}?`,
                disableButton: false,
                fullBackground: false,
                onClickContinue: async () => {await this.deleteSign(item)}
            }
        });
    }

    render() {
        let sectionData;
        let isReady;
        //Совмещение файлов с пакетами, отказ от отдельного компонента секции файлов
        if (this.props.typeSection == "packages")
        {
            if (this.props.activeDocument?.additionalSections.filesSection) {
                sectionData = {};
                sectionData.files = this.props.activeDocument?.additionalSections.filesSection?.files.Records;
                sectionData.isOpen = this.state.isOpen;
                isReady = this.props.activeDocument?.additionalSections.isReady;
                sectionData.activeNode = __.deepCopy(store.getState().document.tree.docs.find(item => {
                    if (this.props.activeDocument.Node)
                        return this.props.activeDocument.Node.Type.Id == item.Id
                    else
                        return this.props.activeDocument.Type.Id == item.Id
                }));
            }
        }
        else if (this.props.typeSection == "search") {
            const {activeTabSearch, searchResults} = this.props;
            const additionalSectionsData = searchResults.find(item => item.Id === activeTabSearch.Id)?.additionalSections;
            sectionData = {};
            sectionData = __.deepCopy(additionalSectionsData?.state[this.sectionName]);

            sectionData.files = additionalSectionsData?.state[this.sectionName].files;
            sectionData.isOpen = this.state.isOpen;

            isReady = additionalSectionsData?.isReady;
            const mainTable = searchResults.find(item => item.Id === activeTabSearch.Id)?.mainTable;
            const node = mainTable?.body?.find(item => item.info?.Id == mainTable?.activeRow?.info?.Id)

            if (!node) return null
            sectionData.activeNode = __.deepCopy(store.getState().document.tree.docs.find(item => (node.info?.IdType) == item.Id));
        }
        else
        {
            if(this.props.viewMode === "normal") {
                sectionData = __.deepCopy(this.props.additionalSectionsData.state[this.sectionName]);
                isReady = this.props.additionalSectionsData.isReady;
                sectionData.activeNode = this.props?.activeNode?.info;
            }
            else if(this.props.viewMode === "card") {
                let card;
                if(this.props.redactorMode && this.props.cards.redactorData) {
                    card = this.props.cards.redactorData;
                } else {
                    card = this.props.cards.collection.find(card => card.Id === this.props.cards.activeCardId);
                }
                sectionData = {...card.additionalSections[this.sectionName]};
                sectionData.isOpen = this.props.cards.collection
                    .find(card => card.Id === this.props.cards.activeCardId)
                    .additionalSections[this.sectionName].isOpen;
                isReady = card.additionalSections.isReady;
                sectionData.activeNode = this.props?.activeNode?.info;
            }
        }

        if (!sectionData)
            return null;

        let lFlagWithSignature = this.props.globalSettings.WithSignature === "1";

        //TODO aa Задача № 22807, непонятная ситуация COLLECT_DATA_FOR_ADDITIONAL_SECTIONS_NORMAL убирает подписи, меняет редакс, но didupdate не происходит, хотя props additionalSectionsData меняется
        // Причем, что еще более не понятно, как получается, что this.conditionRef?.current становится null, хотя компонент не перемонтируется
        // Перенесено на didMount, если будет глючить, то включить этот костыль
        // if (sectionData.isOpen)
        // {
        //     if (sectionData.files[0] && !sectionData.files[0].signatures && !this.conditionRef?.current) {
        //         this.signUpdateRef.current = null;
        //         this.conditionRef.current = "finish";
        //         this.setState({UpdateCounter: this.state.UpdateCounter + 1});
        //     }
        // }

        if(sectionData.idMainFile === null) {
            this.changeRadioHandler(null, sectionData.files.find(file => file.AttributeName.Id === 3));
        }

        let cardTitleClass = "card-title";
        let cardCollapseClass = "collapse show";
        if(!sectionData.isOpen) {
            cardTitleClass = "card-title collapsed";
            cardCollapseClass = "collapse";
        }

        let isUpdated = false;
        if(this.props.redactorMode && this.props.cards?.redactorData) {
            if(sectionData.deleteFiles.length > 0 || sectionData.newFiles.length > 0) {
                isUpdated = true;
            }
            for(let i = 0; i < sectionData.files.length; i++) {
                let newFile = sectionData.files[i];
                let oldFile = this.props.additionalSectionsData.state[this.sectionName].files[i];
                if(newFile?.name !==  oldFile?.name || newFile?.description !==  oldFile?.description) {
                    if(newFile.name === "" && oldFile.name === null)   continue;
                    if(newFile.description === "" && oldFile.description === null)  continue;
                    isUpdated = true;
                }
            }
        }

        //TODO вынести в customStyle.css --- сделано через className="bg-white"
        // let styleBackground = {backgroundColor : "white"};
        let arrRefs = [];
        return (

            <div className="card">
                <div className="card-header" onClick={() => this.toggle(sectionData)}>
                    <div className={cardTitleClass}>
                        Файлы
                        {
                            isUpdated &&
                            <span className="btn btn-icon btn-sm" title="Внесены изменения"
                                  style={{position: "absolute", top: "7px", left: "75px"}}>
                                <i className="svg-icon icon-Edit icon-color-danger"/>
                            </span>
                        }
                    </div>
                </div>
                <div className={cardCollapseClass}>
                    <div className="card-body p-0 beautiful-scroll-10 d-block" style={{overflowX : "auto"}}>
                        <table className="table table-bordered table-striped m-0">
                            <thead>
                            <tr>
                                {
                                    this.props.redactorMode &&
                                    <th rowSpan="2" className="col-lg-1">Основной</th>
                                }
                                <th rowSpan="2" className="col-lg-4" style={{verticalAlign: "middle"}}>Наименование</th>
                                <th rowSpan="2" style={{verticalAlign: "middle"}} className="col-lg-4">Описание</th>
                                <th rowSpan="2" style={{verticalAlign: "middle"}} className="col-lg-1">Размер</th>
                                {
                                    this.props.viewMode === "card" &&
                                    <th rowSpan="2" style={{verticalAlign: "middle"}} className="col-lg-2">Действия</th>
                                }
                                {
                                    !this.props.redactorMode &&
                                    <>
                                        { this.props.viewMode !== "card" &&
                                            <th rowSpan="2" style={{verticalAlign: "middle"}} className="col-lg-2">Скачать</th>
                                        }
                                        {
                                            !this.props.signatureNotEditable &&
                                            lFlagWithSignature &&
                                            <th colSpan="3" style={{verticalAlign: "middle"}}
                                                className="col-lg-2">Электронная подпись</th>
                                        }
                                    </>
                                }
                            </tr>
                            <tr>
                                {
                                    !this.props.redactorMode && !this.props.signatureNotEditable && lFlagWithSignature &&
                                    <>
                                        <th className="col-lg-2">Прикрепить</th>
                                        <th className="col-lg-2">Обновить</th>
                                        <th className="col-lg-2">Удалить</th>
                                    </>
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                isReady &&
                                sectionData.files.map((item, index)=>{
                                    if(!item) return null;

                                    if(this.props.redactorMode) {

                                        let fileColor = {};
                                        let inputDisabled = false;
                                        if(sectionData.deleteFiles.find(file => file.id === item.id)) {
                                            fileColor = {backgroundColor : "rgba(252,205,210,0.33)"};
                                            inputDisabled = true;
                                        }
                                        else {
                                            let oldFile = this.props.additionalSectionsData.state[this.sectionName].files.find(file => file.id === item.id);
                                            if(oldFile) {
                                                if((item.name === "" && oldFile.name === null) || (item.description === "" && oldFile.description === null)) {
                                                    fileColor = {};
                                                } else {
                                                    if(item.name !== oldFile.name || item.description !== oldFile.description) {
                                                        fileColor = {backgroundColor : "rgba(255,210,122,0.33)"};
                                                    }
                                                }
                                            }
                                        }
                                        return (
                                            <tr key={item.id} style={fileColor}>
                                                <td>
                                                    <input className="form-control height2"
                                                           style={{accentColor: `var(--success)`}}
                                                           type="radio" name="mainFile" disabled={inputDisabled}
                                                           onChange={(event)=>{this.changeRadioHandler(event, item, true)}}
                                                           checked={item.id === sectionData.idMainFile}/>
                                                </td>
                                                <td>
                                                    <input className="form-control" type="text"
                                                           onChange={(event)=>{this.updateName(event, item)}}
                                                           disabled={inputDisabled}
                                                           value={item.name ? item.name : ""}/>
                                                </td>
                                                <td>
                                                    <input className="form-control" type="text"
                                                           onChange={(event)=>{this.updateDescription(event, item)}}
                                                           disabled={inputDisabled}
                                                           value={item.description ? item.description : ""}/>
                                                </td>
                                                <td>{HelpFunctions.humanFileSize(item.size, true)}</td>
                                                <td>
                                                    <a style={{marginRight : "2px"}} title="Скачать"
                                                       href={item.link} download className="btn btn-icon btn-xs">
                                                        <i className="svg-icon icon-Doc_view_4 icon-color-primary"/>
                                                    </a>
                                                    {
                                                        sectionData.idMainFile !== item.id &&
                                                        sectionData.activeNode?.ModifyAccess &&
                                                        <span style={{marginRight : "2px"}}  title="Удалить"
                                                              onClick={()=>{this.deleteFile(item)}}
                                                              className="btn btn-icon  btn-xs">
                                                            <i className="svg-icon icon-Table_edit_12 icon-color-red"/>
                                                        </span>
                                                    }

                                                </td>




                                            </tr>
                                        );
                                    } else {
                                        //TODO Электронная подпись
                                        let trClass = "";
                                        if (store.getState().document.additionalSections.state.filesSection)
                                            if (store.getState().document.additionalSections.state.filesSection.activeRowFile)
                                                if((item.id) === store.getState().document.additionalSections.state.filesSection.activeRowFile.id) {
                                                    trClass = "tr-active";
                                                }

                                        arrRefs[item.id  ?? item.Id] = React.createRef();

                                        const card = this.props.cards.collection.find(card => card.Id === this.props.cards.activeCardId);
                                        const mainFile = card?.additionalSections[this.sectionName].files?.find(file => file.AttributeName.Id === 3);

                                        return (
                                            <React.Fragment key={createUniqueIdString(6)}>

                                                <tr className={trClass} key={createUniqueIdString(6)}>
                                                    <td style={{textAlign: "left", whiteSpace: "nowrap"}} onClick={(event) => {this.selectFile(event, item)}}>
                                                        {
                                                            lFlagWithSignature &&
                                                            item.signatures &&
                                                            item.signatures.length != 0 &&
                                                            <div style={{display: 'flex'}}>
                                                                <span className="text-dark-50 font-weight-bolder d-flex align-items-center font-size-lg" style={{minWidth: '2.6rem'}}>
                                                                    <i className={`menu-arrow ${item.showSignatures ? 'icon-expand_16' : 'icon-collapse_16'} icon-color-primary`}
                                                                       style={{color: '#204ba0', cursor: 'pointer'}}
                                                                       onClick={(event) => {
                                                                        this.showSignatureForFile(item);
                                                                    }}/>
                                                                </span>
                                                                <span  className="text-dark-50 font-weight-bolder font-size-lg" style={{whiteSpace: 'break-spaces'}} title={item.name ?? item.Name}>{item.name ?? item.Name}</span>
                                                            </div>
                                                        }
                                                        {
                                                            (!item.signatures || item.signatures.length == 0 || !lFlagWithSignature) &&
                                                            <b> <span title={item.name ?? item.Name}>{HelpFunctions.cutLongString(item.name ?? item.Name, 27, true)}</span> </b>
                                                        }
                                                    </td>
                                                    <td>{(item.description ?? item.Description) == null ? "" : <span title={item.description ?? item.Description}>{ HelpFunctions.cutLongString(item.description ?? item.Description, 10, true)}</span> }</td>
                                                    <td>{HelpFunctions.humanFileSize(item.size ?? item.Size, true)}</td>
                                                    {this.props.viewMode === "card" &&
                                                        <td>
                                                            <a style={{marginRight : "2px"}} title="Скачать"
                                                               href={item.link} download className="btn btn-icon btn-xs">
                                                                <i className="svg-icon icon-Doc_view_4 icon-color-primary"/>
                                                            </a>
                                                            {
                                                                mainFile.id !== item.id
                                                                && ((this.props.adminLevel > AdminLevelEnum.CommonUser && sectionData.activeNode?.ModifyAccess && this.props.redactorMode)
                                                                || (this.props.adminLevel === AdminLevelEnum.CommonUser && sectionData.activeNode?.ModifyAccess))
                                                                &&
                                                                <span style={{marginRight : "2px"}}  title="Удалить"
                                                                      onClick={()=>{this.deleteFile(item)}}
                                                                      className="btn btn-icon  btn-xs"
                                                                >
                                                                    <i className="svg-icon icon-Table_edit_12 icon-color-red"/>
                                                                </span>
                                                            }
                                                        </td>
                                                    }
                                                    { this.props.viewMode !== "card" &&
                                                        <td>
                                                            <a href={item.link} download className="btn btn-icon btn-xs">
                                                                <i className="svg-icon icon-Doc_view_4 icon-color-primary"/>
                                                            </a>
                                                        </td>
                                                    }

                                                    {
                                                        !this.props.signatureNotEditable && lFlagWithSignature &&
                                                        <>
                                                            <td>
                                                                {sectionData.activeNode?.ModifyAccess &&
                                                                    <div onClick={() => {
                                                                        // this.originalFileRef.current.click()}
                                                                        arrRefs[item.id ?? item.Id].current.click()
                                                                    }
                                                                    }
                                                                        // ref={this.uploadFileSignatureRef}
                                                                        // onMouseEnter={() => {
                                                                        //     this.setState({uploadFileSignatureShow: true})
                                                                        // }}
                                                                        // onMouseLeave={() => {
                                                                        //     this.setState({uploadFileSignatureShow: false})
                                                                        // }}
                                                                         title={"Загрузить новый файл подписи"}>
                                                                        <span className="btn btn-icon btn-sm">
                                                                            <i className="svg-icon icon-Signed1 icon-color-primary"/>
                                                                        </span>
                                                                        <input type="file" name={"original"}
                                                                               accept=".p7s, .sign, .sig, .sgn"
                                                                               onChange={(e) => {
                                                                                   this.selectFiles(e, item.id ?? item.Id)
                                                                               }}
                                                                            // ref={this.originalFileRef}
                                                                               ref={arrRefs[item.id ?? item.Id]}
                                                                               style={{display: "none"}}
                                                                        />
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                        </>
                                                    }
                                                </tr>
                                                {
                                                    lFlagWithSignature &&
                                                    item.showSignatures &&
                                                    item.signatures &&
                                                    item.signatures.length > 0 &&
                                                    <>
                                                        <tr
                                                            key={__.createUniqueIdString(6)}>
                                                            <td colSpan="8" className="bg-white"
                                                                style={{textAlign: "left"}}
                                                            >
                                                                <b>Электронная подпись</b>
                                                            </td>
                                                        </tr>
                                                        <tr style={{display: "none"}}></tr>
                                                        {

                                                            item.signatures.map((iSignature, index) => {
                                                                arrRefs[iSignature.Id] = React.createRef();

                                                                const certificateInfo = JSON.parse(iSignature.CertificateInfo);
                                                                const arrAllSubject = certificateInfo?.Subject ? certificateInfo?.Subject.split(", ") : "...";
                                                                    let arrSubjectValues = {};

                                                                    let lPrev = "";
                                                                    for (let i in arrAllSubject)
                                                                    {
                                                                        if (arrAllSubject[i].indexOf('=') != -1) {
                                                                            arrSubjectValues[arrAllSubject[i].slice(0, arrAllSubject[i].indexOf('='))] = arrAllSubject[i].slice(arrAllSubject[i].indexOf('=')+1, arrAllSubject[i].length)
                                                                            lPrev = arrAllSubject[i].slice(0, arrAllSubject[i].indexOf('='));
                                                                        }
                                                                        else {
                                                                            arrSubjectValues[lPrev] += ", "+ arrAllSubject[i]
                                                                        }
                                                                    }

                                                                    const arrMaskStampInfo = ["SN", "G", "O", "T"];
                                                                    let lStampInfo = "";


                                                                    arrMaskStampInfo.forEach((lItem) => {
                                                                        if (arrSubjectValues[lItem]) {
                                                                            if (lStampInfo == "" && lItem != "G")
                                                                                lStampInfo += arrSubjectValues[lItem]
                                                                            else if (lItem == "G")
                                                                                lStampInfo += " " + arrSubjectValues[lItem]
                                                                            else
                                                                                lStampInfo += ", " + arrSubjectValues[lItem]
                                                                        }
                                                                    })

                                                                    return (
                                                                        <tr
                                                                            // style={{display: "table", backgroundColor: "white"}}
                                                                            key={__.createUniqueIdString(6)}>
                                                                            <td className="bg-white">
                                                                                <div style={{
                                                                                    textAlign: 'left',
                                                                                    fontSize: 15,
                                                                                    display: 'flex',
                                                                                }}>
                                                                                    <div className="d-flex" style={{minWidth: '2.6rem'}}>
                                                                                        {
                                                                                            iSignature.IsValid === true ? <img src="/images/success.svg" alt="ЭП действительна" title="ЭП действительна" style={{width: '1.23rem'}}/> :
                                                                                                iSignature.IsValid === false ?  <img src="/images/unsuccessful.svg" alt="ЭП не действительна" title="ЭП не действительна" style={{width: '1.23rem'}}/> :
                                                                                                    <img src="/images/question.svg" alt="Актуальность ЭП не указана" title="Актуальность ЭП не указана" style={{width: '1.23rem'}}/>
                                                                                        }
                                                                                    </div>
                                                                                    <span title={iSignature.SignatureFileName}>
                                                                                        {HelpFunctions.cutLongString(iSignature.SignatureFileName, 16, true)}
                                                                                    </span>
                                                                                    {iSignature.SignatureFileName.length > 20 &&
                                                                                        <span className={'ml-1 cursor-pointer'}
                                                                                              onClick={() => {
                                                                                                  this.props.setModalData({
                                                                                                      name: ModalTypes.app.info,
                                                                                                      data : {content : `Наименование подписи: <br/> ${ iSignature.SignatureFileName}`, type: "getMoreInfo",}
                                                                                                  });
                                                                                              }}
                                                                                        >
                                                                                            <i className="svg-icon svg-icon-sm icon-more"/>
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td className="bg-white">
                                                                                <div>
                                                                                    <p style={{
                                                                                        textAlign: 'left',
                                                                                        fontSize: 15,
                                                                                        margin: 0,
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                    }}>
                                                                                        <>
                                                                                            {
                                                                                                // item.signatures.CertificateInfo &&
                                                                                                <>
                                                                                                    {/*<b>ФИО*/}
                                                                                                    {/*    владельца: </b>{"..."}<br/>*/}
                                                                                                    <span title={lStampInfo}>
                                                                                                        {HelpFunctions.cutLongString(lStampInfo, 30, true)}
                                                                                                    </span>
                                                                                                </>
                                                                                            }
                                                                                            {/*<b>Имя файла*/}
                                                                                            {/*    подписи: </b>{iSignature.SignatureFileName}<br/>*/}
                                                                                        </>
                                                                                        {(lStampInfo.trim() != "") &&
                                                                                            <span className={'d-flex align-items-center ml-1 cursor-pointer'}
                                                                                                  onClick={() => {
                                                                                                      this.props.setModalData({
                                                                                                          name: ModalTypes.app.signaturesInfo,
                                                                                                          data: {type: "success", content: "", signature: iSignature},
                                                                                                      });
                                                                                                  }}
                                                                                            >
                                                                                                <br/>
                                                                                                <i className="svg-icon svg-icon-sm icon-more"/>
                                                                                            </span>
                                                                                        }
                                                                                    </p>
                                                                                    {/*<br/>*/}
                                                                                </div>
                                                                            </td>
                                                                            <td className="bg-white">{HelpFunctions.humanFileSize(iSignature.SignatureFile.Size, true)}</td>

                                                                            <td className="bg-white">
                                                                                <a className="btn btn-icon btn-xs"
                                                                                   download
                                                                                   href={ addToEndUrl({
                                                                                       url : apiUrl.DOCUMENTS.DOWNLOAD_SIGNATURE.url,
                                                                                       collection : [
                                                                                            `?idSignature=${iSignature.Id}`
                                                                                            // "true" // false - для просмотра (true - для загрузки)
                                                                                        ]
                                                                                   })}
                                                                                   title={"Скачать файл электронной подписи"}
                                                                                >
                                                                                    <i className="svg-icon icon-Doc_view_4 icon-color-primary"/>
                                                                                </a>
                                                                            </td>

                                                                            {
                                                                                !this.props.signatureNotEditable && lFlagWithSignature &&
                                                                                <>
                                                                                    <td className="bg-white"></td>
                                                                                    <td className="bg-white">
                                                                                        {
                                                                                            sectionData.activeNode?.ModifyAccess &&
                                                                                            <>
                                                                                                <div title={"Обновить электронную подпись файла документа"}
                                                                                                     onClick={() => {
                                                                                                    arrRefs[iSignature.Id].current.click()
                                                                                                }}>
                                                                                                    <span className="btn btn-icon btn-sm">
                                                                                                       <i className="svg-icon icon-Edit icon-color-primary"/>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <input type="file"
                                                                                                       name={"original"}
                                                                                                       accept=".p7s, .sign, .sig, .sgn"
                                                                                                       onChange={(e) => {
                                                                                                           this.updateSignature(e, iSignature)
                                                                                                       }}
                                                                                                       ref={arrRefs[iSignature.Id]}
                                                                                                       style={{display: "none"}}
                                                                                                />
                                                                                            </>
                                                                                        }
                                                                                    </td>
                                                                                    <td className="bg-white">
                                                                                        {
                                                                                            sectionData.activeNode?.ModifyAccess &&
                                                                                            <div key={__.createUniqueIdString(6)}
                                                                                                 title={"Удалить электронную подпись файла документа"}
                                                                                                 onClick={ (event) => {
                                                                                                    this.deleteSignature(event, iSignature)
                                                                                                }
                                                                                            }>
                                                                                                <span className="btn btn-icon btn-sm">
                                                                                                   <i className="svg-icon icon-Delete icon-color-primary"/>
                                                                                                </span>
                                                                                            </div>
                                                                                        }
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                        </tr>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </>
                                                }
                                            </React.Fragment>
                                        );
                                    }
                                })
                            }
                            {
                                (isReady && this.props.redactorMode) &&
                                sectionData.newFiles.map((item, index)=>{
                                    if(!item) return null;
                                    return (
                                        <tr key={item.id} style={{backgroundColor : "rgba(0,189,151,0.33)"}}>
                                            <td>
                                                {/*<input className="form-control"*/}
                                                {/*       type="radio" name="mainFile"*/}
                                                {/*       defaultChecked={item.type === "main"}/>*/}
                                            </td>
                                            <td>
                                                <span>{item.name ? item.name : ""}</span>
                                            </td>
                                            <td>
                                                <span>{item.description ? item.description : ""}</span>
                                            </td>
                                            <td>{HelpFunctions.humanFileSize(item.file.size, true)}</td>
                                            <td>
                                            <span style={{marginRight : "2px"}}  title="Удалить"
                                                  onClick={(event)=>{this.deleteNewFile(event, item)}}
                                                  className="btn btn-icon  btn-xs">
                                                <i className="svg-icon icon-Table_edit_12 icon-color-red"/>
                                            </span>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const  mapStateToProps = state => {
    return {
        activeNode : state.document.tree.activeNode,
        additionalSectionsData : state.document.additionalSections,
        adminLevel : state.globalState.user.AdminLevel,
        cards : state.document.cards,
        globalSettings : state.globalState.settings.Content,
        activeTabSearch: state.search.rootState.activeTab,
        searchResults: state.search.searchState.searchResults,
    }
}

const  mapDispatchToProps = {
    collectDataForAdditionalSections_Normal,
    createDocumentCards,
    createPreviewBySelectDocument,
    deleteDocumentCard,
    deleteFilesRedactorData,
    deleteNewAttachmentFilesRedactorData,
    deleteSignature,
    selectActiveDocumentCard,
    selectActiveRow_filesSection,
    setLoaderModalData,
    SetMainFileRedactorData,
    setModalData,
    showSignatureForFile,
    toggleAdditionalSectionsOpen,
    toggleAdditionalSectionsOpen_Cards,
    updateFilesNameOrDescriptionRedactorData,
    updateSignature,
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesSection);
