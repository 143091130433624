import React from "react";
import {connect} from "react-redux";
import {createUniqueIdString} from "../../../../tools/CreateUniqueId";
import {ActionQueue} from "../../../../store/rootReducer";
// import {resetDocumentPreview} from "../../../../store/documents/actionCreators/document_PreviewActionCreator";
import {
    changeDocumentSearchInputs_packagesDocumentRight,
    combineMainTableAttributes_packagesDocumentRight, deleteRowFromChecked_packagesDocumentRight,
    mainTableDownloadDocCollection_packagesDocumentRight,
    treeSelectNode_packagesDocumentRight
} from "../../../../store/packages/actionCreators/package_DocumentRightActionCreator";
import {setViewModePreviewPackages} from "../../../../store/packages/actionCreators/package_MainTableActionCreator";
import {Routing} from "../../../../tools/routing/Routing";
import {history, store} from "../../../../index";

class TreeDocsDocumentPackagesRight extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }

        this.selectNode = this.selectNode.bind(this);
    }

    selectNode() {

        this.props.ActionQueue({
            List : [
                {action: this.props.treeSelectNode_packagesDocumentRight, params : { Node : this.props.data}, name: "treeSelectNode_packagesDocumentRight"},
                {action: this.props.mainTableDownloadDocCollection_packagesDocumentRight, params : {ActiveNode : this.props.data}, name: "mainTableDownloadDocCollection"},
                {action: this.props.combineMainTableAttributes_packagesDocumentRight , params : { ActiveNode : this.props.data}, name: "combineMainTableAttributes"},
                {action: this.props.setViewModePreviewPackages , params : { ViewMode : "right_document_table"}, name: "setViewModePreviewPackages"},
                {action : ()=> {
                    if(this.props.searchInputs.length > 0) {
                        this.props.changeDocumentSearchInputs_packagesDocumentRight({isDelete: true});
                    }

                        let query = this.props.router.location.query;
                        let queryLink = "";

                        let IdDocGroup = store.getState().packages.treeForDocuments.group.find(ground =>
                            ground.GroupTypeRefs.findIndex(item => item.IdDocType === this.props.data.info.Id) !== -1
                        ).Id;

                        queryLink = Routing.CreateRef({
                            oldQuery : query,
                            newQuery : {dt2 : this.props.data.info.Id, dg2 : IdDocGroup},
                            excludedKeys : ["dd2", "dwd", "p2", "d2", "dwp"]
                        });

                        let link = `${this.props.router.location.pathname}${queryLink}`;
                        history.push(link);
                }, params : {}}
            ],
            debug : false
        });

        if(this.props.checkedFields.length > 0) {
            this.props.deleteRowFromChecked_packagesDocumentRight({isClear: true});
        }
    }


    render() {
        let {data, activeNode, globalSettings} = this.props;

        let docStyle = "";
        if(activeNode !== null && activeNode.treeId === data.treeId) {
            docStyle = "menu-item menu-item-active";
        }
        else {
            docStyle = "menu-item";
        }

        let isEmptyStyle = {};

        let lFlagHighlight = true;

        if (globalSettings.NotHighlightContainsDocs)
            if (globalSettings.NotHighlightContainsDocs === "1")
                lFlagHighlight = false;

        //TODO подсветка типов без документов
        if (lFlagHighlight)
            if(!data.info.ContainsDocs) {
                isEmptyStyle = {
                    color : "#3f42546b"
                };
            }

        return(
            <li className={docStyle} key={createUniqueIdString(6)}>
                <span onClick={this.selectNode} className="menu-link">
                    <i className="menu-bullet menu-bullet-dot">
                        <span> </span>
                    </i>
                    <span className="menu-text" style={isEmptyStyle}>{data.info.Name}</span>
                </span>
            </li>
        );
    }

}


const  mapStateToProps = state => {
    return {
        activeNode : state.packages.treeForDocuments.activeNode,
        activeFilial : state.globalState.filial.Active,
        userSettings : state.globalState.userSettings,
        router : state.router,
        checkedFields: state.packages.mainTable.checkedFields,
        // TODO КН 26.04.24 на работу с таблицей документов в разделе пакетов пока закрыли глаза
        searchInputs : state.packages.search.searchInputs,
        globalSettings: state.globalState.settings.Content,
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    treeSelectNode_packagesDocumentRight,

    mainTableDownloadDocCollection_packagesDocumentRight,
    combineMainTableAttributes_packagesDocumentRight,
 //   resetDocumentPreview,
    setViewModePreviewPackages,
    deleteRowFromChecked_packagesDocumentRight,
    changeDocumentSearchInputs_packagesDocumentRight
}

export default connect(mapStateToProps, mapDispatchToProps)(TreeDocsDocumentPackagesRight);
