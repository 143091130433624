export const emptySearchFields = {
    FilialIds : [],
    SearchText : "",
    AttributesText : "",
    FullText : "",
    IsSearchInAttachments : false,
    IsSearchInVersions : false,
    IsSearchInMarkedForDelete : false,
    TypeIds : [],
    RegDateAsString : "",
    RegPeriodStart : "",
    RegPeriodFinish : "",
    Name : "",
    RegNumber : "",
    VersionNumber : "",
}

export const typeOptionsForAttributeJSON = [
    {
        label: 'String',
        value: 'String',
        type: 'text',
    },
    {
        label: 'Integer',
        value: 'Integer',
        type: 'number',
    },
    {
        label: 'Float',
        value: 'Float',
        type: 'number',
    },
    {
        label: 'Boolean',
        value: 'Boolean',
        type: 'checkbox',
    },
    {
        label: 'DataTime',
        value: 'DataTime',
        type: 'date',
    },
]

export const mainModalId = 1
