import React from "react";
import {connect} from "react-redux";
import {
    breadcrumbsAdd,
    GetPackageTypes,
    PackageAddNodesTree,
    PackageCreateTree, SelectActivePackageNode, selectViewActiveFileForPreview, togglePackagesFolders
} from "../../../store/packages/actionCreators/package_TreeActionCreator";
import {ActionQueue} from "../../../store/rootReducer";
import {setViewModePreviewPackages} from "../../../store/packages/actionCreators/package_MainTableActionCreator";
import {Routing} from "../../../tools/routing/Routing";
import {history, store} from "../../../index";
import {
    deleteRowFromChecked_packagesDocumentRight
} from "../../../store/packages/actionCreators/package_DocumentRightActionCreator";
import {
    handlerIsSearch,
    updateInputsFieldPackage,
    updateInputsFieldSortingPackage
} from "../../../store/packages/actionCreators/package_InputActionCreator";
import {setLoaderModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {
    ModalTypes,
    PackagesViewFilters, RootComponentsStateViewModeTypes,
    TableTypesEnum
} from "../../../tools/StaticTypes";
import {
    PACKAGE_ROOT_STATE_ACTIONS,
    PACKAGES_TABLE_ACTIONS,
    PACKAGES_TREE_ACTIONS
} from "../../../store/packages/packageActionsList";
import {TreeCombinePackage} from "../../../tools/TreeCombinePackage";
// import {combineMainTableAttributesPackages} from "../../../store/packages/actionCreators/package_MainTableActionCreator";


// Компонент создает свои же копии,
// поэтому необходимо передавать ему информацию от первого родителя и вниз по цепочке.
// Для этого был использован prevContext
// (кроме this.props.data и this.props.depth)
class TreePackageFolderDownload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //      IsOpen : false,
            curRef: null
        }


        this.switchFolderState = this.switchFolderState.bind(this);
        this.loadPackage = this.loadPackage.bind(this);
        this.selectNode = this.selectNode.bind(this);
        this.selectAndLoad = this.selectAndLoad.bind(this);
        this.rowRef = React.createRef();
        this.setRef = this.setRef.bind(this);
        this.load = this.load.bind(this);
    }

    async load(item) {
        let props = this.props.prevContext
            ? this.props.prevContext
            : this.props;


        store.dispatch(setLoaderModalData({
            data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents", key : "TreePackageFolder146" }
        }));
        try {
            let openedFolders = props.openedFolders;
            let activeNode = props.activeNode;

            const tableRole = TableTypesEnum.Download;

            await props.PackageAddNodesTree({
                Id: item.Node.Id,
                IsDownload: true,
                TableType: tableRole,
            });

            props.togglePackagesFolders({
                IdFolder: item.Node.Id,
                TableType: tableRole,
            });

            // если активная нода не добавлена в открытые папки после сворачивания
            // т.к. openedFolders собирается из breadcrumbs, а дерево пересобирается заново без children активной ноды
            // if(activeNode?.Node.Id !== 2 && !(openedFolders.includes(activeNode?.Node.Id))) {
            //     await props.PackageAddNodesTree({
            //         Id: activeNode.Node.Id,
            //         IsDownload: true,
            //         TableType: tableRole
            //     })
            // }
            // props.setModalData({});
            //Новый прелоадер
            props.setLoaderModalData({keyDeleted: "TreePackageFolder194"});
        } catch (e) {
            props.setModalData({
                name: ModalTypes.app.info,
                data: {type: "fail", content: `Ошибка загрузки: <br/>${e.message}`}
            });
        }
        //TODO aa чтобы не закрывалась модалка, иначе в модалке выходит из div
        if (this.props.backFocus)
            this.props.backFocus();
    }

    setActiveRef() {
        let props = this.props.prevContext
            ? this.props.prevContext
            : this.props;


        if (this.rowRef.current) {
            if (        // определяем активная строка или нет
                props.activeNode && this.props.data.TreeId === props.activeNode.TreeId
                // this.props.activeRow && this.props.activeRow.Version.IdDoc === this.props.data.Version.IdDoc
            ) {
                this.props.setRef(this.rowRef);

                // this.rowRef.current.focus();
            }
        }
    }

    componentDidMount() {
        this.setActiveRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setActiveRef();
    }

    // открытие или закрытие папки
    switchFolderState() {
        // this.setState({
        //     IsOpen : !this.state.IsOpen,
        // });
    }


    selectAndLoad() {

    }

    async selectNode(item) {

        let props = this.props.prevContext
            ? this.props.prevContext
            : this.props;

        await props.PackageAddNodesTree({
            Id: item.Node.Id,
            IsDownload: true,
            Next: {
                action: async () => {
                    props.handlerIsSearch({TableType: "download", IsSearch: false})
                    store.dispatch({
                        type : PACKAGES_TREE_ACTIONS.SEARCH_NODE,
                        payload : {
                            searchNode : [],
                            tableType : "download"
                        }
                    });
                    store.dispatch(updateInputsFieldPackage({TableRole: "download"}))
                    store.dispatch(updateInputsFieldSortingPackage({TableRole: "download"}))
                    store.dispatch({
                        type: PACKAGE_ROOT_STATE_ACTIONS.CHANGE_VIEW_MODE,
                        payload: RootComponentsStateViewModeTypes.PackagesApp.Tree
                    });
                    store.dispatch({
                        type: PACKAGES_TABLE_ACTIONS.CHANGE_VIEW_FILTER, payload: {
                            newValue: PackagesViewFilters.All,
                            tableType: "download",
                        }
                    });

                    const activeNode = new TreeCombinePackage(true, "download").searchById(item.Node.Id);
                    store.dispatch({
                        type: PACKAGES_TREE_ACTIONS.SELECT_NODE, payload: {
                            activeNode,
                            tableType: "download"
                        }
                    });
                    store.dispatch(selectViewActiveFileForPreview({TableType: "download", ResetView: true}));

                    if (!props.openedFolders.includes(item.Node.Id))
                        props.togglePackagesFolders({
                            IdFolder: item.Node.Id,
                            IsDownload: true
                        });

                    props.breadcrumbsAdd({
                        NodeId: item.Node.Id,
                        Name: item.Node.Name,
                        ParentId: item.Node.IdParent,
                        IsDownload: true
                    });
                    // TODO КН 23.04.24 Вырезал ф-ию GetPackageTypes из PackageAddNodesTree. Пока сделал так
                    await store.dispatch(GetPackageTypes({
                        idPackage: item.Node.Id, IsDownload: true,
                    }));
                    props.setViewModePreviewPackages({
                        ViewMode: "two_table_download"
                    });

                    let query = props.router.location.query;
                    let queryLink = "";

                    queryLink = Routing.CreateRef({
                        oldQuery: query,
                        newQuery: {dwp: item.Node.Id},
                        excludedKeys: ["dwd", "dt2", "dg2", "p2", "d2"]
                    });

                    let link = `${props.router.location.pathname}${queryLink}`;
                    history.push(link);

                    // очистить поля, которые были отмечены checked для переноса, после перехода в другую ноду
                    if (props.checkedFields.length > 0) {
                        props.deleteRowFromChecked_packagesDocumentRight({isClear: true});
                    }
                },
                params: {}
            }
        });

    }

    loadPackage() {

    }

    setRef = (lRef) => {
        if (lRef)
            this.props.setRef(lRef);
    }



    render() {

        let props = this.props.prevContext
            ? this.props.prevContext
            : this.props;

        let data = this.props.data; // значение текущего узла
        let depth = this.props.depth; // текущая глубина


        let subItemStyle = props.openedFolders.find(item => item === this.props.data.Node.Id) ? {} : {display: "none"};

        // выделение активного node
        let activeStyle = {};
        let activeItemStyleText = "";
        let activeItemStyleIcon = "icon-color-grey";
        // if(props.activeNode && this.props.data.TreeId === props.activeNode.TreeId) {
        if(props.activeNode && this.props.data.Node.Id === props.activeNode.Node.Id) {
            activeStyle = {"border": "2px var(--elem-bg) solid"};
            activeStyle.background = "var(--elem-bg)";
            activeStyle.transition = "background-color 0.3s";
            activeStyle.minHeight = "45px";
            activeStyle.borderRadius = "25px";

            activeItemStyleText = "text-color-primary-dark";
            activeItemStyleIcon = "icon-color-primary-dark";
        }
        activeStyle.marginTop =  "3px";
        activeStyle.marginBottom =  "3px";

        let arrowDirection = props.openedFolders.find(item => item === data.Node.Id) ? "arrow-bottom" : "arrow-right";
        let folderIcon = data.Node.ContainsChildren ? "icon-on1" : "icon-off1";
        const isContainsChild = data.Node.ContainsChildren;

        // чтобы не отображались документы в дереве
        if (this.props.data.Type !== "PackagesFolder") {
            return <></>
        }

        return(
            <React.Fragment>
                <li className={"menu-link-p menu-item "} ref={this.rowRef} tabIndex={this.props.data.Node.Id}>
                <span className="menu-link menu-toggle" style={activeStyle}>
                    <span className="svg-icon menu-icon" onClick={async () => {
                        await this.selectNode(data)
                    }}>
                        <i className={folderIcon + " " + activeItemStyleIcon} />
                    </span>
                    <span className={`menu-text noneFocus ${activeItemStyleText}`} onClick={async () => {
                        await this.selectNode(data)
                    }}>{data.Node.Name}</span>
                    {
                        isContainsChild && <i className={`arrow_m ${arrowDirection} ${activeItemStyleIcon} icon-Arrows_1`}
                                              onClick={() => {
                                                  this.load(data)
                                              }}/>
                    }
                </span>
                    <div className="menu-submenu menu-submenu-p" style={subItemStyle}>
                        <ul className="menu-subnav">
                            {
                                data.Children.map((item, index) => {
                                        if (item.Type === "PackagesFolder") {
                                            return (
                                                <TreePackageFolderDownload
                                                    setRef={(Item) => {
                                                        this.setRef(Item)
                                                    }}
                                                    key={item.TreeId}
                                                    data={item}
                                                    depth={depth + 1}
                                                    prevContext={this.props.prevContext ? this.props.prevContext : this.props} />
                                            );
                                        }
                                        else return  null;
                                    }
                                )
                            }
                        </ul>
                    </div>
                </li>

            </React.Fragment>
        );
    }
    // else return null;
    // }

}


const  mapStateToProps = state => {
    return {
        activeNode : state.packages.treeDownload.activeNode,
        breadcrumbs : state.packages.treeDownload.breadcrumbs,
        openedFolders : state.packages.treeDownload.openedFolders,
        router : state.router,
        checkedFields : state.packages.mainTable.checkedFields,
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    breadcrumbsAdd,
    deleteRowFromChecked_packagesDocumentRight,
    handlerIsSearch,
    PackageAddNodesTree,
    PackageCreateTree,
    SelectActivePackageNode,
    setLoaderModalData,
    setViewModePreviewPackages,
    togglePackagesFolders,
    updateInputsFieldPackage,
    updateInputsFieldSortingPackage,
}

export default connect(mapStateToProps, mapDispatchToProps)(TreePackageFolderDownload);
