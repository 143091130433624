import {store} from "../../../index";
import {SEARCH_INPUTS_ACTIONS, SEARCH_TABLE_ACTIONS} from "../searchActionsList";
import {apiRequest} from "../../../tools/API/apiRequest";
import {apiUrl} from "../../../tools/API/apiUrl";
import {AUTH_ACTIONS} from "../../globalState/globalStateActionsList";

export const combineMainTableAttributesSearch = ({ Filial = null, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            const {search: {rootState: {activeTab}, searchState: {searchResults}}} = store.getState();
            const inputDocIndex = searchResults.findIndex(item => item.Id === activeTab.Id);
            let Docs = searchResults[inputDocIndex].mainTable.collection.docs;
            let Filials = store.getState().globalState.filial.All
            let nameFilial= "";

            // заголовки таблицы
            let HeaderColumns = [];
            // строки таблицы
            let BodyColumns = [];
            // // аттрибуты все которые пришли
            // let AllAttributes = [];

            //   let TypeAttributes = Node.info.TypeAttributes;TypeName

            HeaderColumns.push({Name: "Version.Version", Value: "Версия", IsConstant: true, UserVisible: true});
            HeaderColumns.push({Name: "RegDate", Value: "Дата", IsConstant: true, UserVisible: true});
            HeaderColumns.push({Name: "RegNumber", Value: "Номер", IsConstant: true, UserVisible: true});
            HeaderColumns.push({Name: "Name", Value: "Наименование", IsConstant: true, UserVisible: true});
            HeaderColumns.push({Name: "IdFilial", Value: `${store.getState().globalState.settings.Content?.FilialCaption ?? 'филиал'}`, IsConstant: true, UserVisible: true});
            HeaderColumns.push({Name: "TypeName", Value: "Тип", IsConstant: true, UserVisible: true});
            HeaderColumns.push({Name: "Version.ModifyTime", Value: "Последнее изменение", IsConstant: true, UserVisible: true});


            for (let i = 0; i < Docs.length; i++) {
                let BodyColumnsTR = [];
                for (let j = 0; j <Filials.length ; j++) {
                    if(Docs[i].IdFilial == Filials[j].Id){
                        nameFilial=Filials[j].Name;
                    }
                }

                BodyColumnsTR.push({Name: "Version.Version", Value: Docs[i].Version.Version, IsConstant: true, UserVisible: true});
                BodyColumnsTR.push({Name: "RegDate", Value: Docs[i].FormattedRegDateForClient, IsConstant: true, UserVisible: true});
                BodyColumnsTR.push({Name: "RegNumber", Value: Docs[i].RegNumber, IsConstant: true, UserVisible: true});
                BodyColumnsTR.push({Name: "Name", Value: Docs[i].Name, IsConstant: true, UserVisible: true});
                BodyColumnsTR.push({Name: "IdFilial", Value: nameFilial, IsConstant: true, UserVisible: true});
                BodyColumnsTR.push({Name: "TypeName", Value: Docs[i].TypeName, IsConstant: true, UserVisible: true});


                BodyColumnsTR.push({Name: "Version.ModifyTime", Value: Docs[i].FormattedVersionTimeForClient, IsConstant: true, UserVisible: true});

                // TODO КН 25.03.24 закомментил т.к. этот кусоск кода используется для неконтстантныхз атрибутов
                // // собираем все атрибуты для дополнительных секций
                // let AllAttributesValue = [];
                // AllAttributes.forEach(attr => {
                //     const foundAttribute = Docs[i].Attributes && Docs[i].Attributes.filter(item => item.AttributeName.Id === attr.AttributeName.Id);
                //     if (foundAttribute && foundAttribute.length > 0) {
                //         for (let index in foundAttribute) {
                //             AllAttributesValue.push({
                //                 Name: "PropertyName",
                //                 Value: foundAttribute[index].AttributeValue,
                //                 IdAttributeName: foundAttribute[index].AttributeName.Id, // ?? attr.AttributeName.Id
                //                 IdAttributeType: foundAttribute[index].AttributeName.IdAttributeType, // ?? attr.AttributeName.IdAttributeType
                //                 AttributeName : "SortingAttributeValue", // TODO КН 25.03.24 мб все таки foundAttribute[index].AttributeName,
                //                 ColumnName: foundAttribute[index].AttributeName.Name, // ?? attr.AttributeName.Name
                //                 IsConstant : false
                //             });
                //         }
                //     }
                // })

                BodyColumns.push({
                    tr: BodyColumnsTR,
                    info : Docs[i],
                    // AllAttributes: [] // AllAttributesValue
                });
            }

            dispatch({
                type: SEARCH_TABLE_ACTIONS.COMBINE_ATTRIBUTE,
                payload:
                    {
                        Headers: HeaderColumns,
                        Body: BodyColumns,
                    }
            });
            // }

            // следующее действие если есть цепочка
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка объединения атрибутов таблицы (catch): ", exception);
            // если ошибка колбек
            if(Error) {Error.action(Error.params);}
        }
    }
}

// сохраняем строку таблицы (документ) как активный
export const selectActiveRowSearch = ({RowInfo = null, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            const activeTabId = store.getState().search.rootState.activeTab.Id;
            dispatch({type: SEARCH_TABLE_ACTIONS.SELECT_ACTIVE_ROW, payload:  { RowInfo, Id: activeTabId}});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const  searchResultHighlight = ({RowInfo,Next=null,Error=null})=>{
    return async dispatch =>{
        try {
            dispatch({type: SEARCH_TABLE_ACTIONS.SELECT_ACTIVE_ROW, payload: RowInfo});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        }catch (exception){
            console.log("Ошибка выделения результатов поиска (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// установить номер страницы пагинации в таблице
export const setPaginationPageNumberSearch = ({PaginationPageNumber, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : SEARCH_TABLE_ACTIONS.SET_PAGINATION_PAGE_NUMBER, payload : Number.parseInt(PaginationPageNumber)});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка изменения страницы пагинации в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}
// установить кол-во строк в таблице для отображения
export const setPaginationPageSizeSearch = ({PaginationPageSize, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : SEARCH_TABLE_ACTIONS.SET_PAGINATION_ON_PAGE_SIZE, payload : Number.parseInt(PaginationPageSize)});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка изменения кол-ва строк в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

//  сохраняем документ (строку в таблице) как отмеченную
export const addRowToCheckedSearch = ({IdDoc, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            const {search: {searchState: {searchResults}, rootState: {activeTab}}} = store.getState();
            const checkedFields = searchResults.find(res => res.Id === activeTab.Id)?.mainTable.checkedFields;
            let result =  Array.from(new Set(checkedFields).add(IdDoc));

            dispatch({type : SEARCH_TABLE_ACTIONS.ADD_ROW_TO_CHECKED, payload : result});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка добавления строки к отмеченным (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

// удаляем документ (строку в таблице) из отмеченных
export const deleteRowFromCheckedSearch = ({IdDoc, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            const {search: {searchState: {searchResults}, rootState: {activeTab}}} = store.getState();
            const checkedFields = searchResults.find(res => res.Id === activeTab.Id)?.mainTable.checkedFields;
            let checkedFields_Set = new Set(checkedFields);
            checkedFields_Set.delete(IdDoc);
            let result = Array.from(checkedFields_Set);

            dispatch({type : SEARCH_TABLE_ACTIONS.DELETE_ROW_FROM_CHECKED, payload : result});

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}
// добавляем все документы (строки в таблице) к отмеченным
// или снимаем выделение со всех строк
// если указать Check (true/false), то насильно отметим все строки
// или снимем выделение со всех строк,
// если не указать, то будет проверка на то, отмечены
// ли все строки или нет
export const checkAllRowsOnPageSearch = ({Check = null,Next = null, Error = null}) => {
    return async dispatch => {
        try {
            const {search: {searchState: {searchResults}, rootState: {activeTab}}} = store.getState();
            const checkedFields = searchResults.find(res => res.Id === activeTab.Id)?.mainTable.checkedFields;
            const body = searchResults.find(res => res.Id === activeTab.Id)?.mainTable.body;
            let checked = false;
            let count = 0;
            for (let i = 0; i < body.length; i++) {
                if(body[i].info.IsPermissionGranted) {
                    count++;
                }
            }
            if(checkedFields.length >= count) {
                checked = true;
            }
            if(Check == null) {
                // если отмечены все строки
                if(checked) {
                    dispatch({type : SEARCH_TABLE_ACTIONS.CHECK_ALL_ROWS_ON_PAGE, payload : []});
                } else {
                    let newCheckedFields = new Set([]);
                    for(let i = 0; i < body.length; i++) {
                        if(body[i].info.IsPermissionGranted) newCheckedFields.add(body[i].info.Id);
                    }
                    dispatch({type : SEARCH_TABLE_ACTIONS.CHECK_ALL_ROWS_ON_PAGE, payload : Array.from(newCheckedFields)});
                }
            } else {
                if (Check) {
                    let newCheckedFields = new Set([]);
                    for(let i = 0; i < body.length; i++) {
                        newCheckedFields.add(body[i].info.Id);
                    }
                    dispatch({type : SEARCH_TABLE_ACTIONS.CHECK_ALL_ROWS_ON_PAGE, payload : Array.from(newCheckedFields)});
                } else {
                    dispatch({type: SEARCH_TABLE_ACTIONS.CHECK_ALL_ROWS_ON_PAGE, payload: []});
                }
            }

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка удаление строки из отмеченных в таблице (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

//переход в реестр документов
export const verifyDocClientLinkIds = ({Next=null,Error=null})=>{
    return async dispatch =>{
        try {

            let request = new apiRequest(apiUrl.SEARCH.VERIFY_DOC_CLIENT_LINK_IDS, {
                linkIds :{
                    // IdDocGroup: store.getState().search.mainTable.activeRow.info.Id,
                    IdDocType : store.getState().search.mainTable.activeRow.info.IdType,
                    IdDoc: store.getState().search.mainTable.activeRow.info.Id ,
                    IdFilial:store.getState().globalState.filial.Active.Id
                }
            });
            await request.execute(function(data) {

                //dispatch({type : SEARCH_INPUTS_ACTIONS.GET_DOC, payload : data});
                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка проверки прав на переход в реестр (post): ", data);
            });
        }
        catch (exception) {
            console.log("Ошибка проверки прав на переход в реестр (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


//переход в реестр документов
export const verifyDocClientLinkIdsDocs = ({Id=null,Next=null,Error=null})=>{
    return async dispatch =>{
        try {

            let request = new apiRequest(apiUrl.SEARCH.VERIFY_DOC_CLIENT_LINK_IDS, {
                linkIds :{
                    // IdDocGroup: store.getState().search.mainTable.activeRow.info.Id,
                    ///IdDocType : store.getState().search.mainTable.activeRow.info.IdType,
                    IdDoc: Id,
                    IdFilial:store.getState().globalState.filial.Active.Id
                }
            });

            request.execute(function(data) {

                dispatch({type : SEARCH_INPUTS_ACTIONS.GET_DOC, payload : data});
                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
            },function(data) {
                if(data.status === 401) {
                    dispatch({type : AUTH_ACTIONS.IS_AUTH, payload : false});
                }
                console.log("Ошибка проверки прав на переход в реестр (post): ", data);
            });
        }
        catch (exception) {
            console.log("Ошибка проверки прав на переход в реестр (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const setDefaultWidthOfColumn = ({updatedHeaders = [], DefaultWidth = 0,  Next = null, Error = null}) => {
    return async dispatch => {
        try {
            if(updatedHeaders){
                dispatch({type: SEARCH_TABLE_ACTIONS.SET_DEFAULT_WIDTH_OF_COLUMN, payload: updatedHeaders});
            }

            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка присваивания изначальной ширины столбца (catch): ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}
