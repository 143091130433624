import React from "react";
import {connect} from "react-redux";
import {contextMenuTypes} from "../../../tools/StaticTypes";
import {setContextMenuData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import ContextMenuItem from "./ContextMenuItem";
import {__} from "../../../tools/HelpFunctions";

class ContextMenuDocRef extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isHover : false
        };
    }

    componentDidMount() {
        document.onscroll = (event) => { // скрытие контекстного меню при скроле документа
            if(this.props.contextMenu.name === contextMenuTypes.DocRef) {
                this.props.setContextMenuData({});
            }
        };
        document.onclick = (event) => {
            if(!this.state.isHover) {
                this.props.setContextMenuData({});
            }
        };
    }

    // отписываемся от событий при удалении элемента
    componentWillUnmount() {
        document.onscroll = null;
        document.onclick = null;
    }

    async onClickItem(ev, action) {
        switch (action) {
            case 'openInNewTab':
                let link;
                if (this.props.contextMenu.data.docRefInfo) {
                    link = __.getDocLinkByDocRefInfo({docRefInfo: this.props.contextMenu.data.docRefInfo})
                } else if (this.props.contextMenu.data.docVersionId && this.props.contextMenu.data.docId === undefined) {
                    link = await __.getDocLinkByDocVersionId({docVersionId: this.props.contextMenu.data.docVersionId})
                } else {
                    link = await __.getDocLinkByDocId({
                        // TODO КН 08.04.24 Если у вас нет даже docId, то по идее вы не должны были давать возможность вызывать это контекстное меню
                        docId: this.props.contextMenu.data.docId,
                        docVersionId: this.props.contextMenu.data.docVersionId,
                    })
                }
                if (link) {
                    window.open(link, "_blank")
                }
                break;
            default:
                break;
        }
        this.props.setContextMenuData({});
    }

    render() {
        let contextStyle = {
            position : "fixed",
            top :  this.props.contextMenu.position.y,
            left :  this.props.contextMenu.position.x,
            zIndex: 1100
        };

        return (
            <nav id="context-menu"
                 className="context-menu"
                 style={contextStyle}
                 onContextMenu={(event) => {event.preventDefault()}}
                 onMouseEnter={(event) => {this.setState({isHover : true})}}
                 onMouseLeave={(event) => {this.setState({isHover : false})}}
            >
                <ul className="context-menu__items">
                    <ContextMenuItem Name={'Открыть в новой вкладке'} Icon="icon-Doc_view_5" OnClick={(event) => this.onClickItem(event, 'openInNewTab')} />
                </ul>
            </nav>
        );
    }
}

const  mapStateToProps = state => {
    return {
        contextMenu: state.globalState.app.contextMenu,
    }
}

const  mapDispatchToProps = {
    setContextMenuData,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenuDocRef);
