import React from "react";
import {connect} from "react-redux";
import "moment/locale/ru";
import { ResizableBox  } from 'react-resizable';
import {changeDocumentViewMode} from "../../store/documents/actionCreators/document_RootStateActionCreator";
import {
    changeDocumentSearchInputs,
    changeDocumentSearchInputsContextMenu,
    changeViewMode
} from "../../store/documents/actionCreators/document_SearchActionCreator";
import {contextMenuTypes, RootComponentsStateViewModeTypes} from "../../tools/StaticTypes";
import {
    setContextMenuData, setLoaderModalData, setModalData,
    setStateLeftAsideMenu
} from "../../store/globalState/actionCreators/globalState_AppActionCreator";

import {
    changeSortDirection,
    combineMainTableAttributes,
    mainTableSearchDoc
} from "../../store/documents/actionCreators/document_MainTableActionCreator";
import {store} from "../../index";
import {DataTypesEnum} from "../../tools/API_NEW/ServerTypes";
import {ActionQueue} from "../../store/rootReducer";



class ThMainTable extends React.Component  {
    constructor(props) {
        super(props);

        this.state = {
            sortUp : false,
            defaultWidth : (this.props.data.Value.length * 13) + 70,
            //TODO aa - исправлен баг кнопки вписать
            width :  this.props.data.Size ? this.props.data.Size : (this.props.data.Value.length * 13) + 70,
        };

        this.onResize = this.onResize.bind(this);
        this.rightClick = this.rightClick.bind(this);
        this.search = this.search.bind(this);
        this.getIdAttributeType = this.getIdAttributeType.bind(this)
    }

    componentDidMount() {
        this.props.setWidth(0);
    }

    onResize = (event, {element, size, handle}) => {
        this.setState({width: size.width});
        // this.props.setItemSize(size.width);
        this.props.setWidth(size.width);
    };
    rightClick(event, item) {
        this.props.setContextMenuData({
            name : contextMenuTypes.MainTableTH,
            data : {
                item : item,
                th : this.props.data
            },
            position : {
                x : event.clientX,
                y : event.clientY}
        });
        event.preventDefault();
    }

    getIdAttributeType(data) {
        if (data.IdAttributeType) return data.IdAttributeType
        return (data.Name === "Name" || data.Name === "RegNumber") ? DataTypesEnum.String : DataTypesEnum.DataTime
    }
    async search(event) {
        if (event) {
            let Direction="";

            // TODO aa 17.03.2023 поменял местами, т.к. по умолчанию сортировка по дате 2
            if (this.props.sortDir) {
                Direction = "icon-Filter_down";
            } else {
                Direction = "icon-Filter_up";
            }

            const searchInputs = store.getState().document.search.searchInputs
            let param = null
            if (searchInputs.length !== 0)
                param = searchInputs.find(item => {
                    const itemValue = item.Attribute?.Value ? item.Attribute?.Value : item.Attribute
                    return itemValue === this.props.data.Value
                })
            let IdAttributeType = this.getIdAttributeType(this.props.data)

            this.props.ActionQueue({
                List : [
                    {action : this.props.setLoaderModalData,
                        params : {
                            data : {content : "Загрузка...", disableButton : true, fullBackground : false, gif : "documents", key : "ThMainTable83" }
                        },
                        name : "setLoaderModalData"
                    },
                    {action : this.props.changeDocumentViewMode, params : {ViewMode : RootComponentsStateViewModeTypes.DocumentsApp.MainTableOnly}, name : "changeDocumentViewMode"},
                    {action : this.props.changeSortDirection,
                        params : {dir: !this.props.sortDir, sortField: this.props.data},
                        name : "changeSortDirection"
                    },
                    {action : this.props.mainTableSearchDoc, params : {
                            FieldSorting: this.props.data,
                            Direction: Direction
                        }, name : "mainTableSearchDoc"},
                    {action : this.props.changeDocumentSearchInputs, params : {
                            TH: true,
                            Direction,
                            IdAttributeType,
                            Attribute: this.props.data,
                            Name: this.props.data.Name,
                            Value: this.props.data.IdAttributeType
                                ? {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''}
                                : this.props.data.IdAttributeType === 1 || this.props.data.IdAttributeType === 2
                                    ? {Equal: '', LessOrEqual: '', MoreOrEqual: ''}
                                    : {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                            IsApplied: param?.IsApplied,
                            isConstant: this.props.data.IsConstant,
                        }, name : "changeDocumentSearchInputs"},
                    {action : this.props.combineMainTableAttributes, params : {}, name : "combineMainTableAttributes"},
                    {action : this.props.setContextMenuData, params : {}, name : "setContextMenuData"},
                    {action : this.props.setLoaderModalData, params : {keyDeleted: "ThMainTable121"}, name : "setLoaderModalData"},
                ],
                debug : false,
            });
        }
    }


    render() {
        let width = {
            width : this.state.width + "px", // TODO: Подобрать норм размеры изначальные (тут пока на глаз сделал)
            position : "relative",
            paddingLeft: "0px",
        }
        if(this.props.isWrap) {
            width = {
                width : this.state.width + "px", // TODO: Подобрать норм размеры изначальные (тут пока на глаз сделал)
                position : "relative",
                paddingLeft: "0px",

                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace : "nowrap",
            }
        }



        let styleI ={
            verticalAlign: "middle",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor : "pointer",
        }
        let iconClass="icon-Filter fsic"

        let styleSpan ={
            marginRight:"20px",
        }

        if(this.props?.iconSorting
            .find(item => item.DirectionUp.indexOf("original") !== -1
                    || item.DirectionDown.indexOf("original") !== -1)
        ){
            for (let i = 0; i < this.props?.iconSorting.length; i++) {
                if(this.props?.iconSorting[i].DirectionDown.indexOf("icon-color-original")!==-1){
                    if(this.props.iconSorting[i].isConstant){
                        if(this.props.data.Value===this.props.iconSorting[i].Attribute){
                            iconClass="icon-Filter_up"
                        }
                    }else{
                        if(this.props.data.Value===this.props.iconSorting[i].Attribute.AttributeName.Name){
                            iconClass="icon-Filter_up"
                        }
                    }

                }else if(this.props?.iconSorting[i].DirectionUp.indexOf("icon-color-original")!==-1){
                    if(this.props.iconSorting[i].isConstant){
                        if(this.props.data.Value===this.props.iconSorting[i].Attribute){
                            iconClass="icon-Filter_down"
                        }
                    }else{
                        if(this.props.data.Value===this.props.iconSorting[i].Attribute.AttributeName.Name){
                            iconClass="icon-Filter_down"
                        }
                    }
                }

            }
        }

        let colorStyle = "icon-color-original";
        let colorText = "";
        let Name =  this.props.data.Value;
        let thisSearch;

        if(this.props?.searchInputs?.length > 0) {
            thisSearch = this.props.data.IsConstant
                ? this.props.searchInputs.find(s => s.isConstant && s.Attribute.toLowerCase() === Name.toLowerCase())
                : this.props.searchInputs.find(s => !s.isConstant && s.Attribute.Value.toLowerCase() === Name.toLowerCase())

            //проверка на объект, если точные фильтры, проверить на пустоту
            if (typeof thisSearch?.Value === "object") {
                if (thisSearch?.IsApplied) {
                    colorStyle = "icon-color-secondary";
                    colorText = "text-color-secondary";
                } else {
                    colorStyle = "icon-color-original";
                    colorText = "text-color-original";
                }
            } else {
                if (thisSearch?.Value) {
                    colorStyle = "icon-color-secondary";
                    colorText = "text-color-secondary";
                }
            }
        }



        if(this.props.data.Name !== "Version.Version" && this.props.data.UserVisible) {
            return (
                <th className="i2color"
                    style={width}
                    onContextMenu={(event) => {this.rightClick(event)}}
                    title={this.props.data.Value}>
                    <ResizableBox  style={{display: "inline-block"}}
                                   // width={this.state.width}
                                   width={this.state.width ? this.state.width : '100'}
                                   height={0}
                                   onResize={this.onResize}
                                   minConstraints={[100, 0]}
                                   // minConstraints={[this.state.defaultWidth / 2.5, 0]}
                                   maxConstraints={[this.state.defaultWidth * 3, 0]}
                                   axis={'x'}
                                   handleSize={[10, 0]}
                                   handle={(h, ref) => <span style={{cursor : "col-resize", position : "absolute", right : 0, width : "24px", background : "#fff", height : "24px"}} ref={ref}><i className={"svg-icon icon-Doc_view_2"}/></span>}
                    >
                        <span style={styleSpan} className={colorText}>
                            <span style={styleI}
                                  onClick={() => {this.search()}}
                            >
                                <i className={`svg-icon ${colorStyle} svg-icon-sm ${iconClass} `}/>
                                {/*<i className="svg-icon svg-icon-lg icon-color-original icon-Filter_down"/>*/}
                            </span>&nbsp;&nbsp;
                            <span
                                role="button"
                                onClick = {(event) => this.search(event)}
                            >{this.props.data.Value}</span>
                        </span>
                    </ResizableBox >
                </th>

            );
        } else return null;
    }
}


const  mapStateToProps = state => {
    return {
        isSearch:state.document.search.isSearch,
        //TODO объединить в один iconSorting и searchInputs
        iconSorting:state.document.search.searchInputs,
        searchInputs:state.document.search.searchInputs,
        isWrap:state.globalState.app.isWrap,
        sortDir:state.document.mainTable.sortDirection,
        sortField:state.document.mainTable.sortField,


    }
}

const  mapDispatchToProps = {
    ActionQueue,
    changeSortDirection,
    mainTableSearchDoc,
    changeDocumentViewMode,
    changeDocumentSearchInputs,
    combineMainTableAttributes,
    changeViewMode,
    setContextMenuData,
    changeDocumentSearchInputsContextMenu,
    setStateLeftAsideMenu,
    setModalData,
    setLoaderModalData
}

export default connect(mapStateToProps,mapDispatchToProps)(ThMainTable);
