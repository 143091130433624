import {
    APP_ACTIONS,
    AUTH_ACTIONS, SETTINGS_ACTIONS, USER_ACTIONS,
} from "./globalStateActionsList";
import produce from "immer";

// информация об авторизации (authAction)
const auth = {
    // тип доступа пользователя
    armType : 2,
    // произведена ли авторизация
    isAuth : false,
    // имя пользователя
    userName : null,
    // токен доступа
    token : {
        // значение токена
        value : null,
        // время создания токена
        createdAt : null
    }
};

// информация о текущем пользователе (userAction)
const user = {
    // id пользователя
    Id : null,
    AccountExpirationTime : null,
    AdDomain : null,
    AdLogin : null,
    AdminLevel : null,
    // изначальный филиал пользователя
    Department : {
        Id: null,
        IdParent: null,
        DepNumber: null,
        IdFilial: null,
        Name: null
    },
    IsActive: false,
    IsBoundToDepartments: false,
    Login: null,
    Name: null,
    UserMail: null,
    UserPhone: null,
};

// общие настройки приложения (settingAction)
const settings = {
    // тип доступа пользователя
    ArmType : null,
    // глобальные настройки полученные из xml
    Content : null,
    // описание
    Description : null,
    // id пользователя
    Id : null
};


// настройки для конкретного пользователя (settingAction)
const userSettings = [];

// информация об выбранном и доступных филиалах (userAction)
const filial = {
    // выбранные филиал
    Active : null,
    // список всех доступных для пользователя филиалов
    All : [],
    Groups:[],
    Render:[]
}

const departments = []

const theme={
    selectedTheme: "",
    modString: "default"
}
// данные актуальные для всех страниц приложения
// например : открыто ли левое меню с деревом
const app = {
    // открыто ли левое меню с деревом
    asideMenuIsClose : false,
    userModalShow : false,
    userModalType : "",
    // переносятся ли слова
    isWrap : true,
    // открытое модальное окно
    modal: [],
    // открытое модальное окно
    loaderModal : {
        // данные передаваемые в модальное окно
        data : {content : "Загрузка...", disableButton : true, fullBackground : true, gif : "packages", countOpenLoaders: 0, keys: [], keysDelete: []}
    },
    // открытое контекстное меню
    contextMenu : {
        // имя вызываемого контектного меню
        name : "",
        // произвольные данные передаваемые в контекстное меню
        data : {},
        // позиция контекстного меню
        position : {
            x : 0,
            y : 0
        }
    }
};

const initialState = {
    auth,
    user,
    settings,
    departments,
    userSettings,
    filial,
    theme,
    app
};



export const globalStateReducer = (state = initialState, action) => {
    return produce(state, newState => {

        // auth
        if(action.type === AUTH_ACTIONS.LOGIN) {
            newState.auth = {...action.payload, armType : 2};
        }
        else if(action.type === AUTH_ACTIONS.IS_AUTH) {
            newState.auth.isAuth = action.payload;
        }
        else if(action.type === AUTH_ACTIONS.LOGUOT) {
            newState.auth = auth;
        }

        // user
        if(action.type === USER_ACTIONS.GET_CURRENT) {
            newState.user = action.payload;
        }
        else if(action.type === USER_ACTIONS.GET_ALL) {
            newState.AllUsers = action.payload;
        }
        else if(action.type === USER_ACTIONS.GET_FILIAL) {
            newState.filial.All = action.payload;
            if(!state.filial.Active){
                let IdIndex;
                if(state.user.Department?.IdFilial) {
                    IdIndex = action.payload.findIndex(Filial => Filial.Id === state.user.Department.IdFilial);
                }
                else {
                    IdIndex = 0;
                }
                if(IdIndex === -1) IdIndex = 0;
                newState.filial.Active = action.payload[IdIndex];
            }

        }
        else if(action.type === USER_ACTIONS.GET_FILIAL_GROUPS) {
            newState.filial.Groups = action.payload;
        }
        else if(action.type === USER_ACTIONS.RENDER_FILIAL_TREE) {
            newState.filial.Render = action.payload;
        }
        else if(action.type === USER_ACTIONS.SET_FILIAL) {
            newState.filial.Active = action.payload;
        }

        // settings
        if(action.type === SETTINGS_ACTIONS.GET_GLOBAL_CLIENT_SETTINGS) {
            newState.settings = action.payload;
        }
        else if(action.type === SETTINGS_ACTIONS.GET_USER_SETTINGS) {
            newState.userSettings = action.payload;
        }
        else if(action.type === SETTINGS_ACTIONS.SET_FILIAL) {
            newState.filial.Active = action.payload;
        }
        else if(action.type === SETTINGS_ACTIONS.SET_THEME) {
            newState.theme.selectedTheme = action.payload;
            newState.theme.modString = action.payload.lThemeName.split("_theme")[0];
        }
        else if(action.type === SETTINGS_ACTIONS.UPDATE_USER_SETTINGS) {
            if(action.payload.index >=0) {
                newState.userSettings[action.payload.index] = action.payload.item;
            } else {
                newState.userSettings.push(action.payload.item);
            }
        }
        else if(action.type === SETTINGS_ACTIONS.UPDATE_USER_SETTINGS_SEARCH) {
            if(action.payload.index >=0) {

                if(newState.userSettings[action.payload.index].Value.length<5){
                    newState.userSettings[action.payload.index].Value = action.payload.item.Value;
                }else{
                    newState.userSettings[action.payload.index].Value.splice(0, 1);
                    newState.userSettings[action.payload.index].Value= action.payload.item.Value;
                }

            } else {
                newState.userSettings.push(action.payload.item);
            }
        }
        else if(action.type === SETTINGS_ACTIONS.UPDATE_USER_SETTINGS_DOC) {
            if(action.payload.index >=0) {

                if(newState.userSettings[action.payload.index].Value.length<5){
                    newState.userSettings[action.payload.index].Value = action.payload.item.Value;
                }else{
                    newState.userSettings[action.payload.index].Value.splice(0, 1);
                    newState.userSettings[action.payload.index].Value= action.payload.item.Value;
                }

            } else {
                newState.userSettings.push(action.payload.item);
            }
        }
        else if(action.type === SETTINGS_ACTIONS.UPDATE_USER_SETTINGS_PACKAGE) {
            if(action.payload.index >= 0) {

                if(newState.userSettings[action.payload.index].Value.length<5){
                    newState.userSettings[action.payload.index].Value = action.payload.item.Value;
                }else{
                    newState.userSettings[action.payload.index].Value.splice(0, 1);
                    newState.userSettings[action.payload.index].Value= action.payload.item.Value;
                }

            } else {
                newState.userSettings.push(action.payload.item);
            }
        }
        else if(action.type === SETTINGS_ACTIONS.UPDATE_USER_SETTINGS_GENERAL) {
            if(action.payload.index >= 0) {
                newState.userSettings[action.payload.index].Value = action.payload.item.Value;
            } else {
                newState.userSettings.push(action.payload.item);
            }
        }
        else if(action.type === SETTINGS_ACTIONS.UPDATE_USER_SETTINGS_PACKAGE_TYPE_ADDITIONAL) {
            newState.userSettings = action.payload.item;
        }
        else if(action.type === SETTINGS_ACTIONS.UPDATE_USER_SETTINGS_PACKAGE_TYPE) {
            newState.userSettings = action.payload.item;
        }

        else if(action.type === SETTINGS_ACTIONS.GET_DEPARTMENTS) {
            newState.departments = action.payload
        }
        else if(action.type ===SETTINGS_ACTIONS.UPDATE_USER_SETTINGS_MESSENGER) {
                if (action.payload.index >= 0) {
                    newState.userSettings[action.payload.index].Value = action.payload.item.Value;
                } else {
                    newState.userSettings.push(action.payload.item);
                }
        }
        else if(action.type===SETTINGS_ACTIONS.DELETE_USER_SETTING){
            if (action.payload.index >= 0) {
                newState.userSettings.splice(action.payload.index,1)
            }
        }



        // app
        if(action.type === APP_ACTIONS.SET_ASIDE_MENU_STATE) {
            newState.app.asideMenuIsClose = action.payload;
        }
        else if(action.type === APP_ACTIONS.SET_MODAL_DATA) {
            newState.app.modal[0] = action.payload;
        }
        else if(action.type === APP_ACTIONS.SET_MULTIPLE_MODAL_DATA) {
            newState.app.modal = action.payload;
        }
        else if(action.type === APP_ACTIONS.SET_LOADER_MODAL_DATA) {

            if (newState.app.loaderModal) {
                if (!action.payload.data || JSON.stringify(action.payload.data) === JSON.stringify({})) {
                    newState.app.loaderModal.data.countOpenLoaders -= 1;
                    if (action.payload.keyDeleted) {
                        if (!newState.app.loaderModal.data.keysDelete)
                            newState.app.loaderModal.data.keysDelete = [];
                        newState.app.loaderModal.data.keysDelete.push(action.payload.keyDeleted)
                    }
                }
                else
                    if (!newState.app.loaderModal.data.countOpenLoaders || (newState.app.loaderModal.data.countOpenLoaders && newState.app.loaderModal.data.countOpenLoaders <= 0)) {
                        newState.app.loaderModal = action.payload;
                        newState.app.loaderModal.data.countOpenLoaders = 1;
                        if (action.payload.data.key) {
                            newState.app.loaderModal.data.keys = [];
                            newState.app.loaderModal.data.keys.push(action.payload.data.key)
                        }
                    } else {
                        newState.app.loaderModal.data.countOpenLoaders += 1;
                        if (action.payload.data.key)
                            newState.app.loaderModal.data.keys.push(action.payload.data.key)
                    }
            }
            else {
                newState.app.loaderModal = action.payload;
                newState.app.loaderModal.data.countOpenLoaders = 1;
                if (action.payload.data.key) {
                    newState.app.loaderModal.data.keys = [];
                    newState.app.loaderModal.data.keys.push(action.payload.data.key)
                }
            }
        }
        else if(action.type === APP_ACTIONS.SET_CONTEXT_MENU_DATA) {
            newState.app.contextMenu = action.payload;
        }
        else if(action.type === APP_ACTIONS.SET_SHOW_USER_MODAL) {
            newState.app.userModalShow = action.payload.isShow;
            newState.app.userModalType = action.payload.Type;
            // для скрытия пакетов при закрытии модального окна с фильтрами в таблице пакетов загрузки
            newState.app.userModalTableRole = action.payload.TableRole;
        }
        else if(action.type === SETTINGS_ACTIONS.CHANGE_WRAP) {
            newState.app.isWrap = action.payload;
        }

    });

}

