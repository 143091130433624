import React from 'react';
import {connect} from "react-redux";
import MobileMenu from "../static/MobileMenu";
import LeftMenu from "../static/LeftMenu";
import TopMenu from "../static/TopMenu";
import {updateUserSettings} from "../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import "../../media/styles/messenger.css";
import {setStateLeftAsideMenu} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import MessageField from "../messenger/MessageField";
import Search from "../messenger/Search"
import MessageCard from "../messenger/MessageCard";
import {getActiveDoc} from "../../store/documents/actionCreators/document_MainTableActionCreator";
import {makeReceiverActive} from "../../store/messenger/actionCreators/messenger_ActionCreator";


class MessengerApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redactorMode: false,
            asideMenuOnHover: false,
        }
    }

    componentDidMount() {
        this.props.setStateLeftAsideMenu({
            isClose: false,
        });
        this.props.makeReceiverActive({})
    }

    render() {
        let messengers = [];
        for (const setting of this.props.userSettings) {
            if (setting.Name.includes('messenger'))
                messengers.push(setting)
        }
        let isShowAsideMenu = this.props.rootState.isOpenAsideMenu;

        let asideMobileStyle = "";
        if(isShowAsideMenu) {
            asideMobileStyle = "aside-on";
        }

        return (
            <div>
                <div
                    className={`header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled aside-left-menu ${!isShowAsideMenu ? 'aside-minimize' : ''}`}
                    style={{height: '100%'}}
                >
                    <MobileMenu rootName={"MessengerApp"}/>
                    <div className="d-flex flex-column flex-root" style={{height: '100vh'}}>
                        {/*<div className={`aside aside-left d-flex aside-fixed ${asideMobileStyle}`}>*/}
                        <div className={`aside aside-left d-flex aside-fixed ${asideMobileStyle}`}>
                            <LeftMenu rootName="MessengerApp"/>
                            {isShowAsideMenu && <div className="aside-secondary d-flex-none flex-row-fluid" style={{}}>
                                <div
                                    // className={`aside-workspace ${isIncreaseScope ? "aside-workspace-p" : ""} scroll scroll-push`}
                                    className={`aside-workspace scroll scroll-push`}
                                    onMouseEnter={() => {
                                        this.setState({asideMenuOnHover: true})
                                    }}
                                    onMouseLeave={() => {
                                        this.setState({asideMenuOnHover: false})
                                    }}>
                                    <div className="tab-content">
                                        <div className="tab-pane py-3 py-lg-5 fade show active">
                                            {/*<div className={`aside-menu-wrapper flex-column-fluid px-2 py-2 ${!isFiltersShow ? "beautiful-scroll-5" : ""}`} style={!isFiltersShow ? {overflowX: "hidden", marginRight : "-16px", height: '85vh'} : {}}>*/}
                                            {/*// TODO: BUG FIX MS #21543*/}
                                            <div className="aside-men d-flex flex-column overflow-hidden">
                                                <div className="d-flex px-7">
                                                    <div className="p-2 p-lg-2 my-1 my-lg-2">
                                                        <h3 className="text-color-primary font-weight-bolder"
                                                            // title={"Главная"}
                                                            // style={{cursor : "pointer"}}
                                                        >
                                                            Диалоги
                                                        </h3>

                                                        {/*<span>Основная директория</span>*/}
                                                        {/*<span className="text-muted mt-3 font-weight-bold font-size-sm">Основная директория</span>*/}
                                                    </div>
                                                </div>
                                                <div className="px-7">
                                                    <Search/>
                                                </div>
                                                {/*<div className="card">*/}
                                                {/*    <div className="card-body">*/}
                                                <ul className="list-unstyled mb-0 d-flex flex-column beautiful-scroll-5">
                                                    {
                                                        messengers && messengers.map((messengerItem, index) => {
                                                            let prevMsgItem = this.props.readMsg && Array.from(this.props.readMsg).find(item => item.User.Id === messengerItem.Value[0]?.Colleague.Id)
                                                            let difference = messengerItem.Value[0]?.MsgAmount - (prevMsgItem?.MsgAmount ?? 0)
                                                            const isActive = this.props.activeReceiver?.Id === messengerItem.Value[0]?.Colleague?.Id

                                                            return (
                                                                <li className={`py-4 px-7 messenger-item ${isActive ? 'active' : ''} ${difference > 0 ? 'newMsg' : ''}`} key={index}>
                                                                    <MessageCard
                                                                        messengerItem={messengerItem.Value[0]}
                                                                        key={index}
                                                                        difference={difference}
                                                                    />
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>

                        <div className="d-flex flex-column wrapper flex-grow-1">
                            <div className="content d-flex flex-column">
                                <TopMenu rootName="MessengerApp"/>
                            </div>
                            <div className="d-flex flex-column px-4 overflow-hidden flex-grow-1 mb-3 message">
                                <div className="container-new message d-flex flex-column overflow-hidden flex-grow-1">
                                    {/*    <div className="message-container " style={{}}>*/}
                                    <MessageField/>
                                    {/*    </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        rootState : state.messenger.rootState,
        asideMenuIsClose: state.globalState.app.asideMenuIsClose,
        userSettings: state.globalState.userSettings,
        activeReceiver: state.messenger.message.activeReceiver,
        readMsg: state.messenger.message.readMsgs
    }
}

const mapDispatchToProps = {
    updateUserSettings,
    setStateLeftAsideMenu,
    getActiveDoc,
    makeReceiverActive,
}
export default connect(mapStateToProps, mapDispatchToProps)(MessengerApp);
