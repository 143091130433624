import React from "react";
import {connect} from "react-redux";
import {ModalTypes} from "../../../tools/StaticTypes";
import {deleteModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {__, HelpFunctions, validateAttributeJSONByTree} from "../../../tools/HelpFunctions";
import Select from "react-select";
import {createUniqueIdNumber} from "../../../tools/CreateUniqueId";
import AceEditor from "react-ace";


// modal.data.content => обязательно
class ShowJsonTreeModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            viewMode: 'jsonConstructor',
            contentOnHover: false,
            jsonString: '',
            jsonError: false,
            initialContent: this.props.modal.data.content && this.props.modal.data.content !== '-'
                ? JSON.parse(HelpFunctions.isJSON(this.props.modal.data.content))
                : {type: 'tree', shortDescription: '', root: null},
            redactorContent: this.props.modal.data.content && this.props.modal.data.content !== '-'
                ? JSON.parse(HelpFunctions.isJSON(this.props.modal.data.content))
                : {type: 'tree', shortDescription: '', root: null},
            collapseIds: [],
            tempItem: null,
            idDragOver: null,
            isCreateNew: false,
            parentOnCreateNew: null,
            newItem: {
                id: null,
                type: 'string',
                value: 'newValue',
                renderValue: '',
            },
            isEdit: false,
            inputType: 'text',
            currentTypeOption: null
        };

        this.modalName = ModalTypes.app.show;

        this.hide = this.hide.bind(this);
        this.hideOutSide = this.hideOutSide.bind(this);
        this.save = this.save.bind(this);
        this.getRenderValue = this.getRenderValue.bind(this);
        this.changeObject = this.changeObject.bind(this);
        this.findObject = this.findObject.bind(this);
        this.onSwapIconClickHandler = this.onSwapIconClickHandler.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.createNewItem = this.createNewItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.findParent = this.findParent.bind(this);
        // this.checkIsJsonTree = this.checkIsJsonTree.bind(this);
    }

    // скрывать по кнопке
    hide() {
        this.props.deleteModalData({modalId: this.props.modal.id})
    }

    hideOutSide() { // скрывать при клике вне окна
        if (!this.state.contentOnHover) this.hide();
    }

    expand(parent) {
        if (this.state.collapseIds.includes(parent.id)) {
            this.setState({collapseIds: this.state.collapseIds.filter(id => id !== parent.id)})
        } else {
            this.setState({collapseIds: [...this.state.collapseIds, parent.id]})
        }
    }

    save() {
        let saveValue;
        // TODO SS если понадобится редактирование JSON через строку - раскомментить и доработать
        if (this.state.viewMode === 'jsonString') {
            if (!HelpFunctions.isJSON(this.state.jsonString)) {
                return;
            }
            saveValue = this.state.jsonString;
        } else {
        saveValue = this.state.redactorContent ? JSON.stringify(this.state.redactorContent) : '';
        }
            this.validateJsonTree(saveValue, () => {
                if(!this.state.isJsonRedactorError) {
                    if (this.props.modal.data.callback) this.props.modal.data.callback(saveValue);
                    this.hide();
                }
            })
    }

    changeObject(object, redactedObject) {
        object.id = redactedObject?.id ? redactedObject.id : object.id;
        object.type = redactedObject?.type ? redactedObject.type : object.type;
        object.value = redactedObject?.value ? redactedObject.value : object.value;

        // TODO SS* Чтобы корректно отрабатывалось если renderValue - булевский false
        if (redactedObject?.type === 'boolean') {
            object.renderValue = redactedObject.renderValue;
        } else {
            object.renderValue = redactedObject?.renderValue ? redactedObject.renderValue : object.renderValue;
        }
    }

    findObject(object, id) {
        let foundChild
        if (!object) return
        if (object.id === id) {
            return object
        }
        if (object.children && object.children.length > 0) {
            for (let i = 0; i < object.children.length; i++) {
                foundChild = this.findObject(object.children[i], id)
                if (foundChild) break
            }
        }
        return foundChild
    }

    onSwapIconClickHandler(parent) {
        if (!this.state.tempItem) {
            this.setState({tempItem: {...parent}});
        } else {
            const root = __.deepCopy(this.state.redactorContent?.root);

            const foundFirstElement = this.findObject(root, this.state.tempItem.id)
            const foundSecondElement = this.findObject(root, parent.id)

            this.changeObject(foundFirstElement, {...parent});
            this.changeObject(foundSecondElement, {...this.state.tempItem});

            this.setState({redactorContent: {...this.state.redactorContent, root}, tempItem: null})
        }
    }

    getRenderValue(parent) {
        const redactorMode = this.props.modal.data.redactorMode;
        let renderValue = parent.renderValue;
        if (parent.type === 'boolean') {
            renderValue = renderValue ? 'Да' : 'Нет'
        }
        if (redactorMode && parent) {
            return <input
                className={`form-control json-modal-input d-inline w-auto ${redactorMode && (this.getIsRedacted(parent) || this.state.idDragOver === parent.id) && 'bg-warning'}`}
                value={renderValue}
                disabled
            />
        } else {
            return parent.children
                ? <b>{renderValue}</b>
                : renderValue
        }
    }


     validateJsonTree (jsonObject, callback) {
    const validResult = validateAttributeJSONByTree(jsonObject);

         this.setState({
             isJsonRedactorError: !validResult.valid,
             jsonRedactorErrorMessage: "Неверная структура JSON-объекта"
         }, () => {
             callback();
         });
}

    getIsRedacted(element) {
        const redactedChild = this.findObject(this.state.redactorContent.root, element.id);
        const initialChild = this.findObject(this.state.initialContent.root, element.id);

        if (!redactedChild || !initialChild) return false
        return (redactedChild.renderValue !== initialChild.renderValue
            || redactedChild.value !== initialChild.value
            || redactedChild.type !== initialChild.type)
    }

    onDragStart = data => event => {
        this.setState({tempItem: null});
        let fromItem = JSON.stringify({id: data.id});
        event.dataTransfer.setData("dragContent", fromItem);
    };

    onDragOver = data => event => {
        this.setState({idDragOver: data.id});
        event.preventDefault();
        return false;
    };

    onDrop = data => event => {
        event.preventDefault();
        this.setState({idDragOver: null});

        let fromItem = JSON.parse(event.dataTransfer.getData("dragContent"));
        let toItem = {id: data.id};

        this.swapItems(fromItem.id, toItem.id);
        return false;
    };

    swapItems(fromItemId, toItemId) {
        const root = __.deepCopy(this.state.redactorContent.root);
        let fromItem = this.findObject(root, fromItemId);
        let toItem = this.findObject(root, toItemId);

        const temp = __.deepCopy(fromItem);
        this.changeObject(fromItem, toItem);
        this.changeObject(toItem, temp)

        this.setState({redactorContent: {...this.state.redactorContent, root}})
    };

    createNewItem(parent = null, isEdit = false) {
        this.setState({isCreateNew: true, parentOnCreateNew: parent, isEdit});
    }

    onApply(newItem) {
        let root = __.deepCopy(this.state.redactorContent.root);
        if (!root) root = newItem
        else {
            let parent = this.findObject(root, this.state.parentOnCreateNew.id);
            if (newItem.id === parent.id) {
                this.changeObject(parent, newItem)
            } else if (parent.children) {
                parent.children.push(newItem);
            } else {
                parent.children = [newItem]
            }
        }

        this.setState({
            redactorContent: {...this.state.redactorContent, root},
            jsonString: '',
            isCreateNew: false,
            parentOnCreateNew: null,
            currentTypeOption: null,
            newItem: {
                id: null,
                type: 'string',
                value: 'newValue',
                renderValue: '',
            }
        })
    }

    findParent(object, childId) {
        let foundParent
        if (object.children) {
            if (object.children.find(item => item.id === childId)) {
                return object
            }
            if (object.children.length > 0) {
                for (let i = 0; i < object.children.length; i++) {
                    foundParent = this.findParent(object.children[i], childId)
                    if (foundParent) break
                }
            }
        }
        return foundParent
    }

    deleteItem(item) {
        const root = __.deepCopy(this.state.redactorContent.root);

        let parent = this.findParent(root, item.id);
        if (!parent) {
            this.setState({redactorContent: {...this.state.redactorContent, root: null}})
            return
        }

        parent.children = parent.children.filter(elem => elem.id !== item.id)

        this.setState({redactorContent: {...this.state.redactorContent, root}})
    }

    render() {
        const redactorMode = this.props.modal.data.redactorMode;
        let modalStyle = {
            display: "block",
            backgroundColor: "#061c3e66",
        };

        const typeOptions = [
            {
                label: 'String',
                value: 'string',
                type: 'text',
            },
            {
                label: 'Integer',
                value: 'integer',
                type: 'number',
            },
            {
                label: 'Float',
                value: 'float',
                type: 'number',
            },
            {
                label: 'Boolean',
                value: 'boolean',
                type: 'checkbox',
            },
            {
                label: 'DataTime',
                value: 'date',
                type: 'date',
            },
        ]

        const currentTypeOption =
            typeOptions.find(item => item.value === this.state.newItem.type)
            ?? typeOptions[0];
        if (currentTypeOption?.value !== this.state.currentTypeOption?.value) this.setState({currentTypeOption});

        const createNewModal = () => {
            const root = __.deepCopy(this.state.redactorContent.root);

            let id;
            do {
                id = createUniqueIdNumber();
            } while (root && this.findObject(root, id))

            if (this.state.isEdit) {
                this.setState({newItem: this.state.parentOnCreateNew, isEdit: false})
            } else if (!this.state.newItem.id) {
                this.setState({newItem: {...this.state.newItem, id}})
            }

            return <div className="modal fade show modal-custom" style={modalStyle}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-l">
                    <div className="modal-content"
                         onClick={(e) => e.stopPropagation()}
                    >
                        <div className="modal-header p-4 border-bottom-0">
                            <h5 className="modal-title text-success font-weight-bolder ">
                                Создание нового JSON-объекта
                            </h5>
                        </div>

                        <table className="table table-bordered table-striped m-0">
                            <thead>
                            <tr>
                                <th className="col-lg-5">Атрибут</th>
                                <th className="col-lg-7">Значение</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td style={{verticalAlign: "middle"}}>
                                    <span>Идентификатор</span>
                                </td>
                                <td style={{verticalAlign: "middle"}}>
                                    <input className="form-control"
                                           value={this.state.newItem.id}
                                           disabled
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={{verticalAlign: "middle"}}>
                                    <span>Тип значения</span>
                                </td>
                                <td style={{verticalAlign: "middle"}}>
                                    <Select
                                        closeMenuOnSelect
                                        value={this.state.currentTypeOption}
                                        onChange={option => {
                                            this.setState({
                                                newItem: {
                                                    ...this.state.newItem,
                                                    type: option.value,
                                                    renderValue: option.value !== 'boolean'
                                                        ? this.state.newItem.renderValue
                                                        : false
                                                }
                                            })
                                        }}
                                        options={typeOptions}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                text: 'var(--hover-primary)',
                                                primary25: 'var(--light-primary)',
                                                primary50: 'var(--hover-primary)',
                                                primary: 'var(--originaltwo)',
                                            },
                                        })}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={{verticalAlign: "middle"}}>
                                    <span>Значение</span>
                                </td>
                                <td style={{verticalAlign: "middle"}}>
                                    <input className="form-control"
                                           value={this.state.newItem.value}
                                           onChange={e => this.setState({
                                               newItem: {
                                                   ...this.state.newItem,
                                                   value: e.target.value
                                               }
                                           })}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={{verticalAlign: "middle"}}>
                                    <span>Отображаемое значение</span>
                                </td>
                                <td style={{verticalAlign: "middle"}}>
                                    {currentTypeOption.type !== 'checkbox'
                                        ?
                                        <input className="form-control"
                                               type={currentTypeOption.type}
                                               placeholder={"Новое значение"}
                                               value={this.state.newItem.renderValue}
                                               autoFocus
                                               onChange={e => {
                                                   const value = e.target.value
                                                   this.setState({
                                                       newItem: {
                                                           ...this.state.newItem,
                                                           renderValue: value
                                                       }
                                                   })
                                               }}
                                        />
                                        :
                                        <label className="checkbox checkbox-lg checkbox-inline mr-2">
                                            <input type={"checkbox"}
                                                   onChange={e => {
                                                       const value = e.target.checked
                                                       this.setState({
                                                           newItem: {
                                                               ...this.state.newItem,
                                                               renderValue: value
                                                           }
                                                       })
                                                   }}
                                                   checked={!!this.state.newItem.renderValue}
                                            />
                                            <span className={"row-checked"}/>
                                        </label>
                                    }
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="modal-footer align-content-center text-center justify-content-center">
                            <button type="button"
                                    className="btn btn-lg btn-light-success"
                                    onClick={() => this.setState({
                                        isCreateNew: false,
                                        newItem: {
                                            id: null,
                                            type: 'string',
                                            value: 'newValue',
                                            renderValue: '',
                                        },
                                    })}
                            >
                                Отмена
                            </button>
                            <button type="button"
                                    className="btn btn-lg btn-primary"
                                    onClick={() => this.onApply(this.state.newItem)}
                            >
                                Применить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        }
        const parentComponent = ({parent}) => {
            const children = parent?.children;
            const redactorMode = this.props.modal.data.redactorMode;
            return parent ?
                <li key={parent.id} className={`li-podr`}>
                    <div className={'d-flex align-items-center'}>
                        <div className={'d-flex align-items-center'}
                             onDragStart={this.onDragStart({id: parent.id})}
                             onDragOver={this.onDragOver({id: parent.id})}
                             onDrop={this.onDrop({id: parent.id})}
                             draggable={redactorMode}
                        >
                            <span className={`d-inline-block ${this.state.idDragOver === parent.id ? 'bg-warning' : ''}`}/>
                            <span onClick={() => this.onSwapIconClickHandler(parent)}>
                            <i className={`svg-icon svg-icon-xs mr-1 ml-1
                            ${redactorMode ? 'icon-Grid_card text-muted' : 'icon-filial_24 text-success'}
                            ${this.state.tempItem?.id === parent.id && 'text-color-orange'}`}
                               style={{cursor: `${redactorMode ? "pointer" : "initial"}`}}
                            />
                        </span>
                            <span className={'mx-4'}>
                            {this.getRenderValue(parent)}
                        </span>
                        </div>
                        {redactorMode &&
                            <>
                            <span onClick={() => this.createNewItem(parent)}>
                                <i className={`svg-icon svg-icon-xs mx-1 icon-Plus text-success`}
                                   style={{cursor: "pointer"}}
                                />
                            </span>
                                <span onClick={() => this.createNewItem(parent, true)}>
                                <i className={`svg-icon svg-icon-xs mx-1 icon-Table_edit_1 text-success`}
                                   style={{cursor: "pointer"}}
                                />
                            </span>
                                <span onClick={() => this.deleteItem(parent)}>
                                <i className={`svg-icon svg-icon-xs mx-1 icon-Delete text-color-red`}
                                   style={{cursor: "pointer"}}
                                />
                            </span>
                            </>
                        }
                        {children && children.length > 0 &&
                            <span className={`ml-2 ${this.state.collapseIds.includes(parent.id) ? "icon-Arrows_3" : "icon-Arrows_2"}`}
                                  onClick={() => this.expand(parent)}
                                  role="button"
                            />
                        }
                    </div>
                    {children && children.length > 0 && !this.state.collapseIds.includes(parent.id) &&
                        <ul className={"pl-8"}>
                            {children && children.map(item => parentComponent({parent: item}))}
                        </ul>
                    }
                </li>
                :
                // JSON textarea
                redactorMode ?
                    <div className={'d-flex flex-column'}>
                        <div className={'d-flex align-items-center'} style={{cursor: "pointer"}}
                             onClick={this.createNewItem}
                        >
                            Создать новый объект
                            <span className={'d-flex'} onClick={this.createNewItem}>
                                <i className={`svg-icon svg-icon-xs mr-1 ml-1 icon-Plus text-success`}/>
                            </span>
                        </div>
                    </div>
                    :
                    <li key={createUniqueIdNumber()} className={`li-podr`}>
                        Объект пуст
                    </li>
        }

        let renderValue = this.state.initialContent.root?.renderValue;
        if (this.state.initialContent.root?.type === 'boolean') {
            renderValue = renderValue ? 'Да' : 'Нет'
        }
        const shortDescription = this.state.initialContent?.shortDescription
            ? this.state.initialContent.shortDescription
            : renderValue
                ? `Объект: ${renderValue}`
                : 'Описание отсутствует'
        return (
            <>
                <div className="modal fade show modal-custom" style={modalStyle} onClick={this.hideOutSide}>
                    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-l"
                         style={{minWidth: '500px', width: 'fit-content'}}
                    >
                        <div className="modal-content  modal-content-json"
                             onMouseEnter={() => this.setState({contentOnHover: true})}
                             onMouseLeave={() => this.setState({contentOnHover: false})}
                             onClick={(e) => e.stopPropagation()}
                        >
                            <div className="modal-header p-4 border-bottom-0">
                                <h5 className="modal-title text-success font-weight-bolder ">
                                    Описание JSON-объекта
                                </h5>
                            </div>
                            <div className={'ml-4 d-flex'}>
                                <div role={'button'}
                                     className={`mr-4 modal-json-tab ${this.state.viewMode === 'jsonConstructor' && 'active'}`}
                                     onClick={() => this.setState({viewMode: 'jsonConstructor'})}
                                >
                                    Конструктор
                                </div>
                                <div role={'button'}
                                     className={`mr-4 modal-json-tab ${this.state.viewMode === 'jsonString' && 'active'}`}
                                     onClick={() => {
                                         this.setState({
                                             viewMode: 'jsonString',
                                             jsonString: JSON.stringify(this.state.redactorContent, undefined, 4)
                                         })
                                     }}
                                >
                                    Строка
                                </div>
                            </div>

                            <div className={'d-flex flex-column justify-content-center my-3 mx-7'}
                                 style={{gap: '1rem', marginBottom: '1rem'}}
                            >
                                <span className={'font-weight-bolder'}>Краткое описание дерева объектов:</span>
                                {redactorMode
                                    ?
                                    <input className={`form-control json-modal-input d-inline w-auto`}
                                           value={this.state.redactorContent?.shortDescription}
                                           onChange={(e) => this.setState({
                                               redactorContent: {
                                                   ...this.state.redactorContent,
                                                   shortDescription: e.target.value
                                               }
                                           })}
                                    />
                                    :
                                    <span>{shortDescription}</span>
                                }
                            </div>
                            <div className={`modal-body beautiful-scroll-10 modal-body-json`}>
                                {this.state.viewMode === 'jsonConstructor' &&
                                    <>
                                        {this.state.redactorContent?.root &&
                                            <div className={'mb-4 font-weight-bolder'}>Содержимое объекта:</div>
                                        }
                                        <ul className={"pl-0"}
                                            style={{boxShadow: '-10px 0 13px -16px #888'}}
                                        >
                                            {parentComponent({parent: this.state.redactorContent?.root})}
                                        </ul>
                                    </>
                                }

                                {this.state.viewMode === 'jsonString' &&
                                    <>
                                        <div>
                                            <div className={'mb-4 font-weight-bolder'}>Содержимое объекта:</div>
                                            {
                                                this.state.isJsonRedactorError &&
                                                <label>
                                                    <span
                                                        className="label label-inline label-light-danger font-weight-bolder mb-2">
                                                        {this.state.jsonRedactorErrorMessage}
                                                    </span>
                                                </label>

                                            }
                                            <AceEditor
                                                mode="json"
                                                theme={'xcode'}
                                                name="content"
                                                readOnly={!this.props.modal.data.redactorMode}
                                                value={this.state.jsonString}
                                                onChange={(newValue) => this.setState({jsonString: newValue})}
                                                editorProps={{$blockScrolling: true}}
                                                width={"100%"}
                                                height={"250px"}
                                                setOptions={{
                                                    enableBasicAutocompletion: true,
                                                    enableLiveAutocompletion: true,
                                                }}
                                                style={{
                                                    boxShadow: "0px 0px 5px 1px grey"
                                                }}
                                            />

                                            {/*<pre style={{*/}
                                            {/*    overflow: 'unset',*/}
                                            {/*    boxShadow: '-10px 0 13px -16px #888'*/}
                                            {/*}}>{this.state.jsonString}</pre>*/}
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="modal-footer align-content-center text-center justify-content-center">
                            <button type="button"
                                        className="btn btn-lg btn-light-success"
                                        onClick={this.hide}
                                >
                                    Закрыть
                                </button>
                                {this.props.modal.data.redactorMode &&
                                    <button type="button"
                                            className="btn btn-lg btn-primary"
                                            onClick={this.save}
                                    >
                                        Принять изменения
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isCreateNew && createNewModal()}
            </>
        )
    }
}


const mapStateToProps = state => {
    return {
        router: state.router,
    }
}

const mapDispatchToProps = {
    deleteModalData,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowJsonTreeModal);
