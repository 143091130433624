import React from 'react';
import {connect} from "react-redux";
import HistoryPackages from "../history/HistoryPackages";

// Компонент приветствующий пользователя при первом запуске приложения
export class PackagesWelcome extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {
        let mod = this.props.theme.modString;
        let packageHistory = this.props.userSettings.find((userSetting => userSetting.Name === "packageConfig"));

        return (

            <div className="row pt-4" style={{justifyContent : "center"}}>
                <div className="col-lg-12" style={{padding: "0"}}>
                    <div className="card card-custom bgi-no-repeat bgi-size-cover gutter-b bg-primary"
                         style={{height: "350px",backgroundImage: `url('./images/pacWelcome${mod}.svg')`}}>
                        <div className="card-body d-flex" style={{padding: "4rem 3.25rem"}}>
                            <div className="d-flex py-5 flex-column align-items-start flex-grow-1">
                                <div className="flex-grow-1">
                                    <span className="text-welcome font-weight-bolder font-size-h1"><b>Пакеты</b></span>
                                    <p className="text-welcome font-weight-bold mt-3">Загруженные документы можно<br/> объединять для комплексной работы
                                    </p>
                                </div>
                                {/*<Link to={"/help"} className="btn btn-light font-weight-bolder text-success btn-lg"*/}
                                {/*      style={{borderRadius: "0.8em"}}>*/}
                                {/*    Справка*/}
                                {/*</Link>*/}
                            </div>
                        </div>
                    </div>
                </div>
            {
                packageHistory
                ?
                <HistoryPackages/>
                :
                <div className="col-lg-12 row pt-4 text-center" style={{margin : "0 auto"}}>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                        <div className="card card-custom gutter-b card-stretch">
                            <div className="card-body pt-4">
                                <div className="d-flex flex-center mt-6 mb-7">
                                    <img src={`/images/pacCreate${mod}.svg`} alt=""/>
                                </div>
                                <div className="d-flex flex-column mb-12 mt-6">
                                    <span className="text-dark font-weight-bolder text-hover-primary font-size-h2 mb-0">
                                       Создание архива
                                    </span>
                                </div>
                                <p className="mb-12">Объединяйте ваши документы в пакеты, чтобы создать настоящий электронный архив из тысяч документов
                                </p>
                                {/*<span className="btn btn-block btn-sm btn-light-success font-weight-bolder py-4 mb-10"*/}
                                {/*      style={{borderRadius: "27px", background: "linear-gradient(45deg, #204ba0, #7993c6)", color: "#fff"}}>*/}
                                {/*    Приступить*/}
                                {/*</span>*/}

                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                        <div className="card card-custom gutter-b card-stretch">
                            <div className="card-body pt-4">
                                <div className="d-flex flex-center mt-6 mb-7">
                                    <img src={`/images/pacUpload${mod}.svg`} alt=""/>
                                </div>
                                <div className="d-flex flex-column mb-12 mt-6">
                                    <span className="text-dark font-weight-bolder text-hover-primary font-size-h2 mb-0">
                                        Выгрузка архива
                                    </span>
                                </div>
                                <p className="mb-12">Используйте возможности импорта, чтобы работать с десятками атрибутов в Excel

                                </p>
                                {/*<span className="btn btn-block btn-sm btn-light-success font-weight-bolder py-4 mb-10"*/}
                                {/*      style={{borderRadius: "27px", background: "linear-gradient(45deg, #204ba0, #7993c6)", color: "#fff"}}>*/}
                                {/*    Приступить*/}
                                {/*</span>*/}

                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                        <div className="card card-custom gutter-b card-stretch">
                            <div className="card-body pt-4">
                                <div className="d-flex flex-center mt-6 mb-7">
                                    <img src={`/images/pacShare${mod}.svg`} style={{width: '78px', height: 69}}  alt=""/>
                                </div>
                                <div className="d-flex flex-column mb-12 mt-6">
                                    <span className="text-dark font-weight-bolder text-hover-primary font-size-h2 mb-0">
                                       Делитесь с коллегами
                                    </span>
                                </div>
                                <p className="mb-12">Вы можете поделиться пакетом документов с коллегой, контрагентом или контролирующим органом</p>
                                {/*<span className="btn btn-block btn-sm btn-light-success font-weight-bolder py-4 mb-10"*/}
                                {/*      style={{borderRadius: "27px", background: "linear-gradient(45deg, #204ba0, #7993c6)", color: "#fff"}}>*/}
                                {/*   Приступить*/}
                                {/*</span>*/}
                            </div>
                        </div>
                    </div>
                </div>
            }





            </div>
        );
    }
}
const  mapStateToProps = state => {
    return {
        userSettings: state.globalState.userSettings,
        theme: state.globalState.theme,
    }
}

const  mapDispatchToProps = {

}


export default connect(mapStateToProps, mapDispatchToProps)(PackagesWelcome);
