import React, {Component} from "react";
import {MyTooltip} from "../../overPage/tooltip/MyTooltip";

class  MultipleAttributeTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            addTabTooltipShow: false,
        };
        this.addTabRef = React.createRef();

        this.addRow = this.addRow.bind(this)
        this.deleteRow = this.deleteRow.bind(this)
    }

    componentDidMount() {
        const {item} = this.props;
        let updatedData = [];
        if (item.Value && item.Value.length > 0) {
            updatedData = item.Value.map((row, index) => {
                // TODO КН 18.03.24 noNeedCreateValueField === true только в MasterApp
                // Зачем нужен value
                return {id: index + 1}
            });
        }

        this.setState({data: updatedData});
    }

    addRow = () => {
        const {data} = this.state;
        const lId = data.length + 1;
        this.setState({
            data: [...data, {id: lId}]
        });
        if (this.props.addRow) {
            this.props.addRow(lId);
        }
    };

    isElementExists(arr, index) {
        return index >= 0 && index < arr.length;
    }

    deleteRow = (id) => {
        const {item, deleteRow} = this.props;
        const {data} = this.state;
        if (item && item.Value && item.Value.length >0) {
            if (this.isElementExists(item.Value, id - 1)) {
                deleteRow(id - 1);
            }
        }

        const updatedData = data.filter((row) => row.id !== id);
        const lUpdatedData = updatedData.map((row, index) => {
            return {id: index + 1};
        });

        this.setState({data: lUpdatedData});
    };

    render() {
        const {data, addTabTooltipShow} = this.state;
        // hardDisabledEditing - disable редактирования
        const {item, contents, hardDisabledEditing} = this.props;

        return (
            <div>
                {data && data.length > 0 && (
                    <table>
                        <tbody>
                        {data.map((row) => (
                            <tr key={row.id}>
                                {contents(item, row.id).map((lContent) => (
                                    <td style={lContent.style}>
                                        {lContent.content}
                                    </td>
                                ))}
                                <td>
                                        <span
                                            className="btn btn-icon btn-sm"
                                            style={{cursor: !hardDisabledEditing ? 'pointer' : 'default'}}
                                            onClick={() => !hardDisabledEditing && this.deleteRow(row.id)}
                                        >
                                            <i className={`svg-icon svg-icon-sm icon-Table_edit_12 ${!hardDisabledEditing ? 'icon-color-red' : ''} fsic2`}/>
                                        </span>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
                <>
                    {addTabTooltipShow && (
                        <MyTooltip
                            target={this.addTabRef}
                            text={"Добавить"}
                            show={addTabTooltipShow}
                            placement={"bottom"}
                            delay={500}
                        />
                    )}
                    <span
                        className="btn btn-icon btn-sm"
                        style={{cursor: !hardDisabledEditing ? 'pointer' : 'default'}}
                        ref={this.addTabRef}
                        onMouseEnter={() => {
                            !hardDisabledEditing && this.setState({addTabTooltipShow: true});
                        }}
                        onMouseLeave={() => {
                            !hardDisabledEditing && this.setState({addTabTooltipShow: false});
                        }}
                        onClick={() => !hardDisabledEditing && this.addRow()}
                    >
                        <i
                            className={`svg-icon icon-add_16 ${
                                (!hardDisabledEditing && addTabTooltipShow) ? "icon-color-primary" : ""
                            }`}
                        />
                    </span>
                </>
            </div>
        );
    }
}

export default MultipleAttributeTable;
