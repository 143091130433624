import {store} from "../../../index";
import {PACKAGE_SEARCH, PACKAGES_TREE_ACTIONS} from "../packageActionsList";
import {PackagesDocument} from "../../../tools/classes/packages/PackagesDocument";
import {__} from "../../../tools/HelpFunctions";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import {getDocumentsForTreePackages} from "./package_TreeActionCreator";

export const updateInputsFieldPackage = ({
                                             IdFilial= 1,// TODO КН 26.04.24 заметка себе: не понимаю пока почему по дефолту в эту функцию обычно пробрасывается IdFilial: 1
                                             TableRole = null,
                                             SearchText = null,
                                             IsSearch = false,
                                             AttributesText = null,
                                             FullText = null,
                                             TypeIds = null,
                                             RegDateAsString = {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                                             RegDatePackAsString = {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                                             RegPeriodStart = null,
                                             RegPeriodFinish = null,
                                             Name = {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                                             NamePack = {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                                             RegNumber = {ApproximatelyEqual: '', BeginningFrom: '', EndingOn: '', Equal: ''},
                                             TypeName = {In: ''},
                                             ModifyDate = {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                                             ModifyDatePack = {ApproximatelyEqual: '', OnDate: '', DateStart: '', DateEnd: ''},
                                             AppliedParams = {RegDateAsString: false, Name: false, RegNumber: false, TypeName: false, ModifyDate: false, NamePack: false, ModifyDatePack: false, RegDatePackAsString: false},
                                             IsSearchInAttachments = false,
                                             IsSearchInVersions = false,
                                             IsSearchInMarkedForDelete = false,
                                             Next = null,
                                             Error = null}) => {
    //if(RegDateAsString!==null)RegDateAsString=RegDateAsString.toString().split("T")[0] + "T00:00:00";

    //TODO Задача № 20186

    // 2. В разделе Документы фильтр по данным полям в другом формате - текстовый, что позволяет вводить дату формата 05.2022 для отбора документов за май 2022. Необходимо использовать единый формат фильтров в системе.

    // if(RegPeriodStart!==null && RegPeriodStart !== "")RegPeriodStart=RegPeriodStart.toString().split("T")[0] + "T00:00:00";
    // if(RegPeriodFinish!==null && RegPeriodFinish !== "")RegPeriodFinish=RegPeriodFinish.toString().split("T")[0] + "T00:00:00";

    // if(RegPeriodStart!==null && RegPeriodStart !== "")RegPeriodStart=RegPeriodStart;
    // if(RegPeriodFinish!==null && RegPeriodStart !== "")RegPeriodFinish=RegPeriodFinish;

    //TODO в корректом запросе формат "dd.mm.yyyy" Фильтры в Пакетах. Не работает по "Последнему изменению" Задача № 20069
    // if(ModifyDate !== null && ModifyDate !== "") ModifyDate = ModifyDate.toString().split("T")[0] + "T00:00:00";
    //TODO Задача № 20069 3. Формат введения - дата. В документах данное поле имеет текстовый формат ввода, т.к. используется фильтрация формата 05.2022 для фильтрации документов за май 2022, данная практика используется по аналогии со старым архивом
    // if(ModifyDate !== null && ModifyDate !== "") ModifyDate = ModifyDate.toString().split("T")[0];

    // if(ModifyDate !== null && ModifyDate !== "") ModifyDate = ModifyDate;
    // if(RegDateAsString !== null && RegDateAsString !== "") RegDateAsString = RegDateAsString;

    //TODO Делал фильтр "С" "По"
    /// if(TypeIds.length==0)TypeIds=null;
    return async (dispatch) => {
        try {
            if (typeof RegDateAsString === "string") {
                const date = RegDateAsString
                RegDateAsString = {ApproximatelyEqual: date, OnDate: '', DateStart: '', DateEnd: ''}
            }
            if (typeof RegDatePackAsString === "string") {
                const date = RegDatePackAsString
                RegDatePackAsString = {ApproximatelyEqual: date, OnDate: '', DateStart: '', DateEnd: ''}
            }
            if (typeof Name === "string") {
                const name = Name
                Name = {ApproximatelyEqual: name, BeginningFrom: '', EndingOn: '', Equal: ''}
            }
            if (typeof NamePack === "string") {
                const name = NamePack
                NamePack = {ApproximatelyEqual: name, BeginningFrom: '', EndingOn: '', Equal: ''}
            }
            if (typeof RegNumber === "string") {
                const number = RegNumber
                RegNumber = {ApproximatelyEqual: number, BeginningFrom: '', EndingOn: '', Equal: ''}
            }
            if (typeof TypeName === "string") {
                const typeName = TypeName
                TypeName = {ApproximatelyEqual: typeName, BeginningFrom: '', EndingOn: '', Equal: ''}
            }
            if (typeof ModifyDate === "string") {
                const modDate = ModifyDate
                ModifyDate = {ApproximatelyEqual: modDate, OnDate: '', DateStart: '', DateEnd: ''}
            }
            if (typeof ModifyDatePack === "string") {
                const modDate = ModifyDatePack
                ModifyDatePack = {ApproximatelyEqual: modDate, OnDate: '', DateStart: '', DateEnd: ''}
            }
            dispatch({
                type : PACKAGE_SEARCH.UPDATE_INPUTS_FIELD,
                payload : {
                    TableRole,
                    IdFilial,
                    IsSearch,
                    SearchText,
                    AttributesText,
                    FullText,
                    IsSearchInAttachments,
                    IsSearchInVersions,
                    IsSearchInMarkedForDelete,
                    TypeIds,
                    RegDateAsString,
                    RegDatePackAsString,
                    RegPeriodStart,
                    RegPeriodFinish,
                    Name,
                    NamePack,
                    RegNumber,
                    TypeName,
                    ModifyDate,
                    ModifyDatePack,
                    AppliedParams,
                }});
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка сохранения параметров поиска (catch) ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const getAndSetAppliedParams = ({TableType = 'main', Next= null, Error = null}) => {
    return async (dispatch) => {
        try {
            let searchInputs
            if(TableType === "download") {
                searchInputs = store.getState().packages.treeDownload.searchInputs;
            } else if (TableType === "main") {
                searchInputs = store.getState().packages.tree.searchInputs;
            } else if (TableType === "additional") {
                searchInputs = store.getState().packages.treeAdditional.searchInputs;
            }
            const appliedParams = {
                RegDateAsString: false,
                Name: false,
                RegNumber: false,
                TypeName: false,
                ModifyDate: false,
                NamePack: false,
                ModifyDatePack: false,
                RegDatePackAsString: false,
            }
            if (Object.values(searchInputs.RegNumber).some(item => item)) {
                appliedParams.RegNumber = true
            }
            if (Object.values(searchInputs.Name).some(item => item)) {
                appliedParams.Name = true
            }
            if (Object.values(searchInputs.TypeName).some(item => item)) {
                appliedParams.TypeName = true
            }
            if (Object.values(searchInputs.ModifyDate).some(item => item)) {
                appliedParams.ModifyDate = true
            }
            if (Object.values(searchInputs.RegDateAsString).some(item => item)) {
                appliedParams.RegDateAsString = true
            }
            if (Object.values(searchInputs.NamePack).some(item => item)) {
                appliedParams.NamePack = true
            }
            if (Object.values(searchInputs.ModifyDatePack).some(item => item)) {
                appliedParams.ModifyDatePack = true
            }
            if (Object.values(searchInputs.RegDatePackAsString).some(item => item)) {
                appliedParams.RegDatePackAsString = true
            }
            dispatch(updateInputsFieldPackage({
                TableRole: TableType,
                IsSearch: searchInputs.IsSearch,
                RegDateAsString: searchInputs.RegDateAsString,
                RegDatePackAsString: searchInputs.RegDatePackAsString,
                RegNumber: searchInputs.RegNumber,
                Name: searchInputs.Name,
                NamePack: searchInputs.NamePack,
                TypeName: searchInputs.TypeName,
                ModifyDate: searchInputs.ModifyDate,
                ModifyDatePack: searchInputs.ModifyDatePack,
                AppliedParams: appliedParams,
                Next: {
                    action: async () => {
                        if (Next) Next.action(Next.params);
                    },
                    params: {}
                }
            }))
        } catch (exception) {
            console.log("Ошибка обновления принятых фильтров (catch) ", exception);
            if (Error) Error.action(Error.params);
        }
    }
}

export const updateInputsFieldSortingPackage = ({
                                                    TableRole = null,
                                                    SortingParam = null,
                                                    Direction = 0,
                                                    Next = null,
                                                    Error = null}) =>{
    return async (dispatch) => {
        try {
            let SortedParams = {
                RegDate: 0,
                Name: 0,
                RegNumber: 0,
                TypeName: 0,
                ModifyDate: 0,
            }
            if (SortingParam) {
                SortedParams[SortingParam] = Direction
            }
            dispatch({
                type : PACKAGE_SEARCH.UPDATE_INPUTS_FIELD_SORTING,
                payload : {
                    TableRole,
                    SortedParams
                }});
            if(Next) {Next.action(Next.params);}
        } catch (exception) {
            console.log("Ошибка сохранения параметров поиска (catch) ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

/* TODO КН 06.05.24 НЕ ИСПОЛЬЗУЙТЕ ЭТУ ФУНКЦИЮ!; используйте сразу getDocumentsForTreePackages().
    Не стер её, т.к. есть подобная ф-ия searchByParams() для документов - т.е. чтобы при поиске подобной ф-ии той вы натнкулись на эту.
 */
export const searchByParamsPackages = ({
                                           TableType,
                                           FieldSorting,
                                           Direction,
                                           IsClear,
                                           Next,
                                           Error
}) => {
    return async dispatch => {
        dispatch(getDocumentsForTreePackages({
            SearchByFilters: true,
            TableType,
            FieldSorting,
            Direction,
            IsClear,
            Next,
            Error
        }))
    }
}

export const handlerSearchPackages = ({TableType = "main",Next = null, Error = null}) => {
    return async dispatch => {
        try {
            let activeNode;
            let newChildren;
            if(TableType == "main"){
                 activeNode = __.deepCopy(store.getState().packages.tree.activeNode);
                 newChildren= store.getState().packages.temporaryData.documents;
            }else if(TableType == "additional"){
                activeNode = __.deepCopy(store.getState().packages.treeAdditional.activeNode);
                newChildren= store.getState().packages.temporaryData.documents;
            }
            else if(TableType == "download"){
                activeNode = __.deepCopy(store.getState().packages.treeDownload.activeNode);
                newChildren= store.getState().packages.temporaryData.documents;
            }

            activeNode.Children = activeNode.Children.filter(item => item.Type === "PackagesFolder");

            for(let i = 0; i < newChildren.length; i++) {
                let TreeId=createUniqueIdString(6);
                let Type="PackagesDocument";
                let Children=[];
                activeNode.Children.push(
                    {
                        TreeId,
                        Type,
                        Children,
                        Node: newChildren[i]
                    }
                )
            }
                dispatch({type : PACKAGES_TREE_ACTIONS.SEARCH_NODE, payload : {
                        searchNode : activeNode,
                        tableType : TableType
                    }});
                if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка

        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);

            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const handlerIsSearch = ({TableType = "main", IsSearch = false, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TREE_ACTIONS.IS_SEARCH, payload : {
                    tableType : TableType,
                    IsSearch
                }});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка

        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}

export const handlerPackagesIsExtSearch = ({TableType = "main", IsExtSearch= false, Next = null, Error = null}) => {
    return async dispatch => {
        try {
            dispatch({type : PACKAGES_TREE_ACTIONS.IS_EXT_SEARCH, payload : {
                    tableType : TableType,
                    IsExtSearch
                }});
            if(Next) {Next.action(Next.params);} // следующее действие если есть цепочка
        } catch (exception) {
            console.log("Ошибка переключения расширенных фильтров (catch) ", exception);
            if(Error) {Error.action(Error.params);} // если ошибка колбек
        }
    }
}


