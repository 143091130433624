import React from "react";
import {connect} from "react-redux";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import "moment/locale/ru";
import parse from "html-react-parser";
import {addRowToCheckedSearch, deleteRowFromCheckedSearch, selectActiveRowSearch} from "../../../store/search/actionCreators/search_table";
import {collectDataForAdditionalSectionsSearch_Normal, createPreviewBySelectDocumentSearch, resetDocumentPreviewSearch} from "../../../store/search/actionCreators/search_PreviewActionCreator";
import {contextMenuTypes, ModalTypes, RootComponentsStateViewModeTypes} from "../../../tools/StaticTypes";
import {changeSearchViewMode} from "../../../store/search/actionCreators/search_RootStateActionCreator";
import {
    setContextMenuData,
    setLoaderModalData, setModalData
} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";
import {ActionQueue} from "../../../store/rootReducer";

class TrMainTableSearch extends React.PureComponent  {
    constructor(props) {
        super(props);

        this.state = {
            checkboxOnHover : false
        };

        this.checkboxOnChange = this.checkboxOnChange.bind(this);
        this.checkboxOnHover = this.checkboxOnHover.bind(this);
        this.checkboxOffHover = this.checkboxOffHover.bind(this);
        this.rightClick = this.rightClick.bind(this);
        this.selectDocument = this.selectDocument.bind(this);
        this.rowRef = React.createRef();
    }

    componentDidMount() {
        let activeRow = this.props.searchResults.find(item => item.Id === this.props.activeTab.Id).mainTable.activeRow
        if (this.rowRef.current) {
            //TODO aa 08.11.2022 Задача № 20348 БАГ. Пролистывание при смене страницы в Пакете
            // if (this.props.lIndex == 0)
            //     this.props.setRef(this.rowRef)
            if (        // определяем активная строка или нет
                activeRow?.info.DocVersion.IdDoc === this.props.data.info.DocVersion.IdDoc
            ) {
                this.props.setRef(this.rowRef)
            }
        }
    }

    checkboxOnChange() {
        let checkedFieldsSearch = this.props.searchResults.find(item => item.Id === this.props.activeTab.Id).mainTable.checkedFields
        let {data} = this.props;

       if(new Set(checkedFieldsSearch).has(data.info.Id)) {
           this.props.deleteRowFromCheckedSearch({
               IdDoc : data.info.Id
           });
       } else if(data.info.IsPermissionGranted){
           this.props.addRowToCheckedSearch({
               IdDoc : data.info.Id
           });
       }
    }

    checkboxOnHover() {
        this.setState({
            checkboxOnHover : true
        });
    }

    checkboxOffHover() {
        this.setState({
            checkboxOnHover : false
        });
    }

    // обработка правого клика по строке, ячейке
    rightClick(event, item) {

        //TODO ?????? aa 24.03.2023 Почему-то было убрано

        this.props.setContextMenuData({
            name : contextMenuTypes.MainTableTR,
            data : {
                item : item,
                tr : this.props.data
            },
            position : {
                x : event.clientX,
                y : event.clientY}
        });
        event.preventDefault();
    }
    //
    // // загрузка выбранного документа;
    // // Обработка onClick по строке;
    selectDocument(event) {
        if(this.props.data.info.IsPermissionGranted) {
            const {rootState, activeTab} = this.props;
            const viewMode = rootState.tabs.find(tab => tab.Id === activeTab.Id)?.viewMode;
            this.props.ActionQueue({
                List: [
                    {
                        action: this.props.setLoaderModalData,
                        params: {
                            data: {
                                content: "Загрузка...",
                                disableButton: true,
                                fullBackground: false,
                                gif: "documents",
                                key: "TrMainTableSearch91"
                            }
                        },
                        name: "setLoaderModalData"
                    },
                    {
                        action: this.props.selectActiveRowSearch,
                        params: {RowInfo: this.props.data},
                        name: "selectActiveRowSearch"
                    },
                    {action: this.props.resetDocumentPreviewSearch, params: {}, name: "resetDocumentPreviewSearch"},
                    {
                        action: this.props.createPreviewBySelectDocumentSearch,
                        params: {},
                        name: "createPreviewBySelectDocumentSearch"
                    },
                    {
                        action: this.props.collectDataForAdditionalSectionsSearch_Normal,
                        params: {},
                        name: "collectDataForAdditionalSections_Normal"
                    },
                    {
                        action: this.props.setLoaderModalData,
                        params: {keyDeleted: "TrMainTableSearch96"},
                        name: "setLoaderModalData"
                    },
                ],
                debug: false,
            });

            if (viewMode === RootComponentsStateViewModeTypes.SearchApp.MainTableOnly) {
                this.props.changeSearchViewMode({
                    ViewMode: RootComponentsStateViewModeTypes.SearchApp.PreviewNormal
                });
            }
        }else{
            this.props.setModalData({
                name : ModalTypes.app.info,
                data : {content : "Отсутствуют права доступа к документу", type : "fail"}
            });
        }
    }




    render() {

        const {
            data,
            activeTab,
            searchResults,
            searchInputsList
        } = this.props;

        const activeRow = searchResults.find(res => res.Id === activeTab.Id)?.mainTable.activeRow;
        const checkedFields = searchResults.find(res => res.Id === activeTab.Id)?.mainTable.checkedFields;
        const IsSearchInVersions = searchInputsList.find(res => res.Id === activeTab.Id)?.IsSearchInVersions;

        // поиск того где находится версия
        //let Version = data.tr.find(tr => tr.Name === "Version.Version").Value;
        let Version = this.props.data.info.Version;

        // проверяем есть ли в отмеченных документах
        let isChecked = new Set(checkedFields).has(data.info.Id);//TODO доделать
        let checkboxContent;
        if((isChecked || this.state.checkboxOnHover)&& data.info.IsPermissionGranted) {
            checkboxContent = (
                <td className="py-6" key={createUniqueIdString(6)} onMouseLeave={this.checkboxOffHover}>
                    <label className="checkbox checkbox-lg checkbox-inline mb-1" >
                        <input type="checkbox" checked={isChecked} onChange={this.checkboxOnChange}/>
                        <span className={"row-checked"} />
                    </label>
                </td>
            );
        }
        else {

            let icon = "icon-Documents icolor";
            if(this.props.data.info.IsMarkedForDelete) {
                icon = "icon-Doc_view_9 icon-color-red";
            }

            checkboxContent = (
                <td className="pl-0" key={createUniqueIdString(6)}  onMouseEnter={this.checkboxOnHover}>
                    <a href="#" className="btn btn-icon btn-hover-text-primary btn-lg mb-1 position-relative">
                    <span className="svg-icon svg-icon-xxl">
                      <i className={`svg-icon ${icon}`}/>
                    </span>
                        <span className={`label label-sm label-light-success label-rounded font-weight-bolder position-absolute top-0 left-0 mt-1 mr-1 ${Version > 1 && "label-colored"}`}>
                            {parse(Version)}
                        </span>
                        {/* Так пока выглядит вывод версии (она стоит первая пока что) */}
                    </a>
                </td>
            );
        }

        let superStyle = {lineHeight: "20px"};
        if(this.props.isWrap) {
            superStyle = {
                overflow : "hidden",
                textOverflow: "ellipsis",
                whiteSpace : "nowrap",
            }
        }

        let trStyle = { // чтобы не дергалось при наддвернее на чекбокс
            lineHeight : "min(55px)"
        };
        let timeStyle = {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        };

        // если выбран поиск в версиях, то сравниваем кроме Id также их версии
        const isActiveRow = activeRow && (activeRow.info.Id === this.props.data.info.Id &&
            (IsSearchInVersions ? activeRow.info.Version === this.props.data.info.Version : true));

        // определяем активная строка или нет
        let trClass = isActiveRow
            ? "tablez2 tr-active noneFocus"
            : "tablez2 noneFocus";

        const lIndex = this.props.data.info.DocVersion.IdDoc;

        return (
            <tr ref={this.rowRef}
                tabIndex={lIndex}
                onMouseLeave={this.checkboxOffHover}
                className={trClass}
                style={trStyle}>
                {checkboxContent}
                {
                    data.tr.map((item, index) => {

                        if(item.Name === "Version.Version" || !item.UserVisible) {
                            return null;
                        }
                        else if(item.Name === "RegDate" || item.Name === "Version.ModifyTime" || item.Name === "FormattedRegDateForClient") {

                            return(
                                <td key={createUniqueIdString(6)}
                                    title={item.Value.replace('<span class=\'searchResultHighlight\'>', "").replace('</span>', "")}
                                    onClick={(event) => {this.selectDocument(event)}}
                                    onContextMenu={(event) => {this.rightClick(event, item)}}>
                                    <div className="text-primary font-weight-normal d-block font-size-lg" style={timeStyle}>
                                        {parse(item.Value)}
                                    </div>
                                </td>
                            );
                        }
                        else {
                            return(
                                <td key={createUniqueIdString(6)}
                                    onClick={(event) => {this.selectDocument(event)}}
                                    onContextMenu={(event) => {this.rightClick(event, item)}}>
                                    <div style={superStyle}
                                     title={item.Value.replace('<span class=\'searchResultHighlight\'>', "").replace('</span>', "")}
                                         className=" text-primary font-weight-normal d-block font-size-lg ">
                                        {parse(item.Value)}
                                    </div>
                                </td>
                            );
                        }

                    })
                }
            </tr>
        );
    }
}

const  mapStateToProps = state => {
    return {
        activeTab: state.search.rootState.activeTab,
        rootState : state.search.rootState,
        isWrap:state.globalState.app.isWrap,
        searchResults: state.search.searchState.searchResults,
        searchInputsList: state.search.searchState.searchInputsList
    }
}

const  mapDispatchToProps = {
    ActionQueue,
    addRowToCheckedSearch,
    changeSearchViewMode,
    collectDataForAdditionalSectionsSearch_Normal,
    deleteRowFromCheckedSearch,
    createPreviewBySelectDocumentSearch,
    resetDocumentPreviewSearch,
    selectActiveRowSearch,
    setContextMenuData,
    setLoaderModalData,
    setModalData
}

export default connect(mapStateToProps,mapDispatchToProps)(TrMainTableSearch);
