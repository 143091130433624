import React from 'react';
import "../../media/styles/messenger.css";
import {updateUserSettings} from "../../store/globalState/actionCreators/globalState_SettingsActionCreator";
import {connect} from "react-redux";
import {
    MessageTypesEnum, ModalTypes,
    UserSettingsActionsTypes,
    UserSettingsNames
} from "../../tools/StaticTypes";
import parse from "html-react-parser";
import {history, store} from "../../index";
import {setLoaderModalData, setModalData} from "../../store/globalState/actionCreators/globalState_AppActionCreator";
import {API} from "../../tools/API_NEW/API";
import {DOCUMENT_VERIFY} from "../../store/documents/documentActionsList";
import {getActiveDoc} from "../../store/documents/actionCreators/document_MainTableActionCreator";
import {apiRequest} from "../../tools/API/apiRequest";
import {apiUrl} from "../../tools/API/apiUrl";
import {setReadMsgs} from "../../store/messenger/actionCreators/messenger_ActionCreator";
import Avatar from "react-avatar";
import {HelpFunctions} from "../../tools/HelpFunctions";
import FileNameWithEllipsis from "../FileNameWithEllipsis";
import ImagePreviewWithLabel from "../imagePreview/ImagePreviewWithLabel";

const getIconByFileName = (fileName, CN) => {
    const ext = HelpFunctions.getFileExtensionByName(fileName)
    switch (ext) {
        case 'xlsx':
            return <i className={`svg-icon icon-Doc_view_11 ${CN}`} style={{color: '#7acc7a'}}/>
        case 'pdf':
            return <i className={`svg-icon icon-Doc_view_12 ${CN}`} style={{color: '#db4c4c'}}/>
        case 'docx':
            return <i className={`svg-icon icon-Doc_view_13 ${CN}`} style={{color: '#6fa8dc'}}/>
        case 'txt':
            return <i className={`svg-icon icon-Doc_view_14 ${CN}`} style={{color: '#c2c4c3'}}/>
        default:
            return <i className={`svg-icon icon-Doc_view_15 ${CN}`} style={{color: '#ffffff'}}/>
    }
}

class MessageField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            packageId: null,
            newMessageFiles: [],
            messageIsSending: false,
        }
        this.messageFileInputRef = React.createRef();

        this.sendMessage = this.sendMessage.bind(this)
        this.findHref = this.findHref.bind(this)
        this.goToDocuments = this.goToDocuments.bind(this)
        this.buildLink = this.buildLink.bind(this)
        this.getAttachedData = this.getAttachedData.bind(this)
        this.goToPackage = this.goToPackage.bind(this)
        this.getAtchdPackData = this.getAtchdPackData.bind(this)
        this.checkReadMsg = this.checkReadMsg.bind(this)
        this.getFormattedTime = this.getFormattedTime.bind(this)
        this.addFileToMessage = this.addFileToMessage.bind(this)
        this.onClickFile = this.onClickFile.bind(this)
        this.dropEndHandler = this.dropEndHandler.bind(this)
    }

    checkReadMsg() {
        this.props.setReadMsgs({
            User: this.props.activeReceiver
        })
    }

    async getAtchdPackData(packId) {
        let packInfo;

        try {
            let request = await new apiRequest(apiUrl.PACKAGE.GET_PACKAGE_PATH, {Id: packId});
            await request.execute(function (data) {
                packInfo = data.at(-1)
            }, function (data) {
                if (data.status === 401) {
                }
                console.log("Ошибка получения дерева группы документов (post post): ", data);
            });
        } catch (exception) {
            console.log("Ошибка получения дерева группы документов (catch) ", exception);
            if (Error) {
                Error.action(Error.params);
            } // если ошибка колбек
        }
        // let packages=this.props.allPackages
        //   let packInfo=packages.find(item=>item.Id===this.state.packageId)
        return {
            Id: packInfo.Id,
            Name: packInfo.Name,
            FilialId: packInfo.IdFilial,
            PathName: '/packages',
        }
    }

    async getAttachedData(docId, search) {
        await this.props.getActiveDoc({
            Id: docId
        })

        return {
            Name: this.props.activeDocInfo.Name,
            ShortDescription: this.props.activeDocInfo.ShortDescription,
            Filial: this.props.activeDocInfo.IdFilial,
            Id: this.props.activeDocInfo.Id,
            Search: search,
            PathName: '/documents',
        }
    }

    addFileToMessage(eventFiles) {
        const files = [...eventFiles];
        let errorText = '';
        const goodFiles = [];

        const bigSizeFiles = [];
        files.forEach(file => {
            if (!this.state.newMessageFiles.find(f=> f.name === file.name && f.size === file.size && f.type === file.type)) {
                if (file.size > 30*1024*1024) {
                    bigSizeFiles.push(file)
                } else {
                    goodFiles.push(file)
                }
            }
        })

        if (bigSizeFiles.length > 0) {
            const namesString = bigSizeFiles.map(el => el.name).join(', ')
            errorText = errorText + `Нельзя загрузить файлы размером более 30мб (${namesString}). <br/>`
        }

        if (errorText !== '') {
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {
                    type: "fail",
                    content: `Ошибка заполнения: <br/> ${errorText}`
                }
            });
        }
        this.setState({newMessageFiles: [...this.state.newMessageFiles, ...goodFiles]})
    }

    async sendMessage() {
        this.setState({text: '', newMessageFiles: [], messageIsSending: true})
        let attchdData
        let href = this.findHref()
        let messageTxt = this.state.text;

        const files = await Promise.all(this.state.newMessageFiles.map(async file => {
            const fileAsJSON = await HelpFunctions.blobToJSONString(file)
            return {
                fileName: file.name,
                fileAsJSON: fileAsJSON,
                fileType: file.type,
            }
        }))

        if (href) {
            if (href.includes('/documents?')) {
                let startIndex = href.indexOf("d=")
                let docId = ''
                for (let i = startIndex + 2; i < href.length; i++) {
                    docId += href[i]
                }
                let searchIndex = href.indexOf("?g")
                let search = ''
                for (let i = searchIndex; i < href.length; i++) {
                    search += href[i]
                }
                docId = Number(docId)
                attchdData = await this.getAttachedData(docId, search)
                let startIndexHref = messageTxt.indexOf(href)
                let hrefToDelete = messageTxt.substring(startIndexHref, startIndexHref + href.length + 1)
                messageTxt = messageTxt.replace(hrefToDelete, '')
            } else if (href.includes('/packages?')) {
                let startIndex = href.indexOf("p=")
                let pacId = ''

                for (let i = startIndex + 2; i < href.length; i++) {
                    pacId += href[i]
                }

                pacId = Number(pacId)
                this.setState({packageId: pacId})
                attchdData = await this.getAtchdPackData(pacId)

                let startIndexHref = messageTxt.indexOf(href)
                let hrefToDelete = messageTxt.substring(startIndexHref, startIndexHref + href.length + 1)
                messageTxt = messageTxt.replace(hrefToDelete, '')

            } else {
                messageTxt = messageTxt.replace(href, `<a href=${href} target="_blank">${href}</a>`)
            }
        }
        let nameArr = this.props.activeReceiver.Name.toUpperCase().split(' ')
        let name = 'messenger' + nameArr.join('_')
        // let msgSettings=this.props.userSettings?.filter(item=>item.Name.includes('messenger'))
        // let curReceiverSetting=msgSettings?.find(item=>item.Value[0].Colleague.Id===this.props.activeReceiver.Id)

        this.props.updateUserSettings({
            Name: UserSettingsNames.MESSENGER,
            Action: UserSettingsActionsTypes.Update,
            Data: {
                Files: files,
                Message: messageTxt ?? '',
                MessageType: MessageTypesEnum.General,
                Date: new Date(),
                AttachedData: attchdData,
                SettingName: name,
            },
            Next: {
                action: () => {
                    this.props.setReadMsgs({
                        User: this.props.activeReceiver,
                    })
                    this.setState({messageIsSending: false})
                },
                actionIfError: () => {
                    this.setState({messageIsSending: false})
                }
            }
        })
    }

    findHref() {
        let msg = this.state.text
        let href = '';
        let startIndexOfHref

        if (msg.includes('http://') || msg.includes('https://')) {
            startIndexOfHref = msg?.indexOf('http')
            for (let i = startIndexOfHref; i < msg.length; i++) {
                if (msg[i] === ' ') break;
                href += msg[i]
            }
        }

        if (href.length > 0) return href
        return null
    }

    async goToDocuments(item) {
        //Новый прелоадер
        store.dispatch(setLoaderModalData({
            data: {
                content: "Загрузка...",
                disableButton: true,
                fullBackground: true,
                gif: "documents",
                key: "HistoryHome265"
            }
        }));


        if (item.Filial !== this.props.activeFilialId) {
            let filialName = this.props.allFilials.find(filial => filial.Id === item.Filial).Name;
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {
                    type: "question",
                    content: `Данный документ был открыт в другом ${store.getState().globalState.settings.Content?.FilialCaption ?? 'филиале'}: <b>${filialName}</b> <br />Сменить ${store.getState().globalState.settings.Content?.FilialCaption ?? 'филиал'} и перейти к документу?`,
                    disableButton: false,
                    fullBackground: false,
                    onClickContinue: async () => {
                        await this.buildLink(item)
                    }
                }
            });
            this.props.setLoaderModalData({keyDeleted: "HistoryHome289"});
            return;
        }

        //TODO 289 и 293, добавлено, чтобы убрать бесконечный прелоадер при переходе с домашней страницы по документу
        this.props.setLoaderModalData({keyDeleted: "HistoryHome293"});
        await this.buildLink(item);
    }

    async buildLink(item) {

        //Задача № 22235
        let vFlagVerify = false;
        let docRefInfo = null;
        //Если документ уже верифицирован, то не делаем запрос
        if (this.props.verifyDocs) {
            if (this.props.verifyDocs[item.Id]) {
                vFlagVerify = true;
                docRefInfo = this.props.verifyDocs[item.Id][0];
            }
        }

        if (!vFlagVerify) {
            docRefInfo = await API.search().verifyDocClientLinkIds({
                linkIds: {
                    IdDoc: item.Id,
                    IdFilial: item.Filial
                }
            });
            // сохраняем в редакс
            store.dispatch({
                type: DOCUMENT_VERIFY.UPDATE_VERIFY_DOCS, payload: {
                    id: item.Id,
                    data: docRefInfo
                }
            });
        }


        if (docRefInfo.errorCode) {
            if (docRefInfo.message?.Message) {
                store.dispatch(setModalData({
                    name: ModalTypes.app.info,
                    data: {
                        content: docRefInfo.message.Message,
                        disableButton: false,
                        type: "fail"
                    }
                }));
            }
        } else {
            let link = `/documents`;
            let postfix = item.Search;
            link += postfix;

            if (item.Filial !== this.props.activeFilialId) {
                link = link.concat(`&f=${item.Filial}`);
            }

            history.replace(link);
        }
    }

    async goToPackage(item) {
        let link = `/packages?p=${item.Id}`;
        if (item.FilialId !== this.props.activeFilialId) {
            let filialName = this.props.allFilials.find(filial => filial.Id === item.FilialId).Name;
            this.props.setModalData({
                name: ModalTypes.app.info,
                data: {
                    type: "question",
                    content: `Данный пакет был открыт в другом ${store.getState().globalState.settings.Content?.FilialCaption ?? 'филиале'}: <b>${filialName}</b> <br />Сменить ${store.getState().globalState.settings.Content?.FilialCaption ?? 'филиал'} и перейти к пакету?`,
                    disableButton: false,
                    fullBackground: false,
                    onClickContinue: async () => {
                        history.replace(link.concat(`&f=${item.filial}`))
                    }
                }
            });
            return;
        }
        history.replace(link);
    }

    getFormattedTime(item) {
        const formatItem = (item) => {
            return item < 10 ? "0" + item : item
        }

        let formattedDate = "";
        let curDate = new Date();
        let itemDate = new Date(item.Date)

        let day = itemDate.getDate()
        day = formatItem(day)
        let month = itemDate.getMonth()
        month = formatItem(month)
        let year = itemDate.getFullYear()
        year = formatItem(year)
        let hours = itemDate.getHours()
        hours = formatItem(hours)
        let minutes = itemDate.getMinutes()
        minutes = formatItem(minutes)

        if (!(curDate.getDate() === itemDate.getDate()
            && curDate.getMonth() === itemDate.getMonth()
            && curDate.getFullYear() === itemDate.getFullYear())
        ) {
            formattedDate = `${day}.${month}.${year} `
        }
        formattedDate += `${hours}:${minutes}`
        return formattedDate
    }

    async onClickFile(fileObj) {
        const fileBlob = await HelpFunctions.blobFromJSONString(fileObj.fileAsJSON)
        HelpFunctions.onClickFile(fileBlob,fileObj.fileName)
    }

    dropEndHandler(event) {
        event.preventDefault();
        event.stopPropagation();
        const eventFiles = event.dataTransfer.files
        eventFiles?.length > 0 && this.addFileToMessage(event.dataTransfer.files)
    }

    render() {
        let name;
        let activeMessenger;
        let messengers = [];
        const allowSend = !this.state.messageIsSending && (this.state.text || (this.state.newMessageFiles.length > 0))

        for (const setting of this.props.userSettings) {
            if (setting.Name.includes('messenger'))
                messengers.push(setting)
        }

        if (this.props.activeReceiver) {
            name = this.props.activeReceiver.Name;
            activeMessenger = messengers?.find(item => item.Value[0]?.Colleague.Id === this.props.activeReceiver.Id)
        }

        return (
            <>
                {
                    name &&
                    <div className="row flex-column flex-grow-1">
                        {/*<div className="col-md-6 col-lg-7 col-xl-8" style={{justifyContent:'center'}}>*/}
                        <div className="card card-custom col-lg-12 p-0">
                            <div className="messenger-header name px-10 py-6">
                                <p className="fw-bold mb-0">{name}</p>
                            </div>
                            {/*TODO КН 04.12.23 За счет flex-column-reverse скролл автоматически опускается вниз, а key нужен, чтобы пролистав переписку вверх и переключившись на другую, скролл был снова снизу*/}
                            <div key={name} className='msg-field beautiful-scroll-5 flex-grow-1 px-10 flex-column-reverse' style={{height: '65vh'}}>
                                <ul className="list-message ">
                                    {activeMessenger && activeMessenger.Value[0]?.Message.map((item, index) =>
                                        (
                                            <li className={`d-flex flex-column my-3 ${item.Author.Id === this.props.activeReceiver.Id ? "receiver" : "sender"}`}
                                                key={index}
                                            >
                                                    <div className='msg-label d-flex align-items-center mb-4 max-w-50'>
                                                         <span className="msg-label-avatar symbol-label font-size-h5 font-weight-bold">
                                                            <Avatar name={item.Author.Name} size="40" color={"var(--originaltwo)"} fgColor={"var(--text-welcome)"} className="rounded"/>
                                                        </span>
                                                        <div className="msg-label-text d-flex align-items-end" style={{lineHeight: 1}}>
                                                            <span className="msg-label-name">{item.Author.Name}</span>
                                                            <span className="msg-label-time" style={{fontSize: '12px'}}>{this.getFormattedTime(item)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="card d-flex max-w-50" style={{backgroundColor: 'var(--light)', borderRadius: '30px', borderWidth: 0}}>
                                                        <div className="card-body flex-row message p-4">
                                                            <div className="d-flex flex-column flex-grow-1 m-2">
                                                                {item.Text && parse(item.Text) !== '' &&
                                                                    <div className="mb-0 msg-field__text text-break">
                                                                        {parse(item.Text)}
                                                                    </div>
                                                                }
                                                                {item.AttachedData &&
                                                                    <div>
                                                                        <div className="attachedData" onClick={() => item.AttachedData.PathName === '/documents'
                                                                            ? this.goToDocuments(item.AttachedData) : this.goToPackage(item.AttachedData)}>
                                                                            <div className="attachedData_icon mr-4 ml-2">
                                                                                {item.AttachedData.PathName === '/documents' ?
                                                                                    <i className={`icon-Documents icon-color-primary`}
                                                                                       style={{fontSize: '2rem'}}/>
                                                                                    :
                                                                                    <i className={`icon-pak_on icon-color-primary`}
                                                                                       style={{fontSize: "2rem"}}/>
                                                                                }
                                                                            </div>
                                                                            <div className={"d-flex flex-column justify-content-center"}>
                                                                                <div className="attachedData_Name">
                                                                                    {item.AttachedData.Name ?? ''}
                                                                                </div>
                                                                                {item.AttachedData.ShortDescription &&
                                                                                    <div className="attachedData_Description">
                                                                                        {item.AttachedData.ShortDescription}
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {item.Files?.length > 0 &&
                                                                    <div className="d-flex flex-column">
                                                                        {item.Files.filter(fileObj => fileObj.fileType.includes('image')).length > 0 &&
                                                                            <div className='d-flex flex-wrap' style={{marginBottom: '-0.5rem', marginRight: '-0.5rem'}}>
                                                                                {item.Files.filter(fileObj => fileObj.fileType.includes('image')).map(fileObj=>
                                                                                    <div key={`${fileObj.fileName} ${fileObj.fileType}`} className={'d-flex mr-2 mb-2 position-relative'} style={{width: '250px', height: '180px', background: 'rgba(0,0,0,0.1)'}}>
                                                                                        <ImagePreviewWithLabel fileObj={fileObj} />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        }
                                                                        <div className="messageFiles d-flex flex-wrap" style={{marginBottom: '-0.5rem'}}>
                                                                            {item.Files.filter(fileObj => !fileObj.fileType.includes('image')).map(fileObj=>
                                                                                <div
                                                                                    className={`btn btn-success flex-btn btn-sm py-3 px-6 text-nowrap mr-2 mb-2`}
                                                                                    onClick={() => this.onClickFile(fileObj)}
                                                                                    style={{maxWidth: '160px'}}
                                                                                    title={`Загрузить ${fileObj.fileName}`}
                                                                                    key={`${fileObj.fileName} ${fileObj.fileType}`}
                                                                                >
                                                                                    <FileNameWithEllipsis noTitle fileName={fileObj.fileName} />
                                                                                    {getIconByFileName(fileObj.fileName, 'icon-2x m-0 p-0 ml-4')}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            {item.Author.Id !== this.props.activeReceiver.Id &&
                                                                <div className='checked-icon'>
                                                                    <i className="bi bi-check2-all" />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                            <div className="p-3 messenger-input" onDrop={this.dropEndHandler}>
                                <div className="form-outline d-flex align-items-end">
                                    <div className="d-flex flex-column flex-grow-1 overflow-hidden">
                                        <textarea style={{resize: "none", border: "none"}}
                                                  className="form-control beautiful-scroll-10 mb-2" id="textAreaExample2" rows="2"
                                                  onChange={(e) => this.setState({text: e.target.value})}
                                                  value={this.state.text}
                                                  placeholder="Сообщение..."
                                                  onClick={this.checkReadMsg}
                                        />
                                        {this.state.newMessageFiles.length > 0 && <div className="d-flex flex-wrap mb-2">
                                            {this.state.newMessageFiles.map((file, index) => (
                                                <div key={`${file.name} ${file.type}`} className="d-flex align-items-center mr-2 py-2 px-3" style={{maxWidth: '160px', whiteSpace: 'nowrap', backgroundColor: 'var(--light)', borderRadius: '6px'}}>
                                                    <div className="overflow-hidden mr-2">
                                                        <FileNameWithEllipsis fileName={file.name}/>
                                                    </div>
                                                    <i className="icon-Delete icon-color-primary cursor-pointer" title={`Удалить ${file.name}`} onClick={() => {
                                                        this.setState({newMessageFiles: this.state.newMessageFiles.filter((f, i)=> index !== i)})
                                                    }}/>
                                                </div>
                                                ))
                                            }
                                        </div>}
                                        <div className="btn btn-icon btn-sm" onClick={()=>this.messageFileInputRef.current.click()}>
                                            <input type="file"
                                                   onChange={e=>this.addFileToMessage(e.target.files)}
                                                   ref={this.messageFileInputRef}
                                                   multiple
                                                   hidden
                                            />
                                            <i className="svg-icon icon-attach"/>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center my-2 ml-3" style={{height: '3.6rem', width: '10rem'}}>
                                        <div onClick={()=> allowSend && this.sendMessage()}
                                              className={`w-100 h-100 justify-content-center btn btn-success flex-btn btn-sm py-3 px-6 text-uppercase ${!allowSend && !this.state.messageIsSending ? "disabled" : ""}`}
                                              title="Отправить"
                                              style={{cursor: allowSend ? 'pointer' : 'default'}}
                                        >
                                            {!this.state.messageIsSending ? <span>Отправить</span> :
                                                <div className="spinner-border icon-color-white" style={{
                                                    width: '1.5rem',
                                                    height: '1.5rem',
                                                }} role="status"/>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*</div>*/}
                    </div>
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        activeReceiver: state.messenger.message.activeReceiver,
        userSettings: state.globalState.userSettings,
        user: state.globalState.user,
        activeFilialId: state.globalState.filial.Active.Id,
        allFilials: state.globalState.filial.All,
        verifyDocs: state.document.verifyDocs,
        activeDocInfo: state.document.mainTable.activeDocInfo,
        allPackages: state.packages.treeForDocuments.docs,
    }
}

const mapDispatchToProps = {
    updateUserSettings,
    setModalData,
    setLoaderModalData,
    getActiveDoc,
    setReadMsgs,
}
export default connect(mapStateToProps, mapDispatchToProps)(MessageField);
