import React from "react";
import {connect} from "react-redux";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";
import {
    setDefaultWidthOfColumn
} from "../../../store/search/actionCreators/search_table";
import ThMainTableSearch from "./ThMainTableSearch";
import {checkAllRowsOnPageSearch} from "../../../store/search/actionCreators/search_table";
import {__} from "../../../tools/HelpFunctions";
import {ModalTypes} from "../../../tools/StaticTypes";
import {setModalData} from "../../../store/globalState/actionCreators/globalState_AppActionCreator";


class TheadSearch extends  React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.checkboxOnChange = this.checkboxOnChange.bind(this);
        this.setItemSize = this.setItemSize.bind(this);
    }


    // выделение или снятие всех галок
    checkboxOnChange(count) {
      if(count===0 ) {
          this.props.setModalData({
              name : ModalTypes.app.info,
              data : {content : "Отсутствуют права доступа к документам", type : "fail"}
          });
      }else{
          this.props.checkAllRowsOnPageSearch({});
      }
    }


    componentDidMount() {
        if(this.props.header) {
            const copyHeaders = __.deepCopy(this.props.header)
            copyHeaders.map(item => {
                if (item.Name !== "Version.Version") item.SizeDefault = (item.Value.length * 13) + 70
                return item
            })
            this.props.setDefaultWidthOfColumn({updatedHeaders: copyHeaders})
        }

    }

    setItemSize = (lItem, lSize) => {
        //проверка от зацикливания
        if (lItem)
            lItem.Size = lSize;
    }

    render() {
        let {
            searchResults,
            activeTab
        } = this.props;

        const searchResultsIndex = searchResults?.findIndex(item => item.Id === activeTab.Id);
        const header = searchResults[searchResultsIndex]?.mainTable?.header;
        const body = searchResults[searchResultsIndex]?.mainTable?.body;
        const checkedFields = searchResults[searchResultsIndex]?.mainTable?.checkedFields;

        if(!header || header.length <= 0) {
            return  null;
        }
        let checked = false;
        let count = 0;
        for (let i = 0; i < body.length; i++) {
            if(body[i].info.IsPermissionGranted) {
                count++;
            }
        }
        if((checkedFields.length >= count) && count>0) {
            checked = true;
        }
        return (
            <thead>
            <tr className="text-uppercase tablez1" >
                <th style={{width:"35px"}}>
                    <label className="checkbox checkbox-lg checkbox-inline mr-2" >
                        <input type="checkbox" checked={checked} onChange={()=>{this.checkboxOnChange(count)}}/>
                        <span className={"row-checked"}> </span>
                    </label>
                </th>
                {
                    header.map((item, index) => {
                        return <ThMainTableSearch
                            key={item.Value + "-" + createUniqueIdString(3)}
                            data={item}
                            setItemSize={(ItemSize) => {
                                this.setItemSize(item, ItemSize)
                            }}
                            setWidth = {(itemSize) => this.props.setWidth(itemSize, item)}/>
                    })
                }
            </tr>
            </thead>
        );
    }
}


const  mapStateToProps = state => {
    return {
        activeTab: state.search.rootState.activeTab,
        searchResults: state.search.searchState.searchResults,
    }
}

const  mapDispatchToProps = {
    checkAllRowsOnPageSearch,
    setDefaultWidthOfColumn,
    setModalData
}

export default connect(mapStateToProps,mapDispatchToProps)(TheadSearch);
