import React from "react";
import TrMainTablePackage from "./TrMainTablePackage";
import {createUniqueIdString} from "../../../tools/CreateUniqueId";



class TbodyPackage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            curRef: {}
        };

        this.lCurRef = React.createRef();

    }

    setRef = (lRef) => {
        //проверка от зацикливания
        if (lRef)
            if (lRef.current) {
                if (lRef.current && this.lCurRef.current?.tabIndex !== lRef.current?.tabIndex) {
                    this.lCurRef.current = lRef.current;
                    this.lCurRef.current.focus();
                }
            }
    }

    render() {
        let data = this.props.data;

        return (
            <tbody className="beautiful-scroll-5">
            {
                data.map((item, index) => {
                    return (
                        <TrMainTablePackage
                            viewFilter={this.props.viewFilter}
                            key={createUniqueIdString(6)}
                            data={item}
                            selectFolder={this.props.selectFolder}
                            tableRole={this.props.tableRole}
                            lIndex = {index}
                            // Получаем от дочки только сам реф
                            setRef={(Item) => {
                                this.setRef(Item)
                            }}
                        />
                    );
                })
            }
            </tbody>
        );
    }
}

export default TbodyPackage;
